import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";

import ColorPicker from "./ColorPicker";
import style from "./manage-user-groups.scss";
import modalStyle from "./manage-user-groups.scss";
import ManageUserTable, { FetchedUserTableData } from "./ManageUserTable";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Heading from "components/typography/heading/Heading";
import { UserGroup } from "domain/userGroups";
import { Role } from "domain/users";
import { deriveTenantUuid } from "services/tenants/tenantCookieService";
import { tenantService } from "services/tenants/TenantService";
import { userGroupsService } from "services/user-groups/UserGroupsService";
import { userService } from "services/user/users/UserService";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface FetchFailure {
    failed: boolean;
    errorModalVisible: boolean;
}

interface Props {
    submitEventHandler: (values: FormValues) => Promise<void>;
    edit?: boolean;
    groupTuple?: UserGroup;
    setModalFormVisible?: (value: boolean) => void | undefined;
}

export interface Groups {
    name: string;
    uuid: string;
}

export interface FormValues {
    groupName: string;
    backgroundColor: string;
    textColor: string;
    roleUuid: string | undefined;
    usersList: string[];
}

export default function ManageUserGroupsForm(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const abortController = new AbortController();
    const [roles, setRoles] = React.useState<Role[]>();
    const [groups, setGroups] = React.useState<Groups[]>();
    const [groupsUsers, setGroupUsers] = React.useState<FetchedUserTableData[]>();
    const [fetchFailure, setFetchFailure] = React.useState<FetchFailure>({ failed: false, errorModalVisible: false });
    const [formLoader, setFormLoader] = React.useState<boolean>(false);

    const submitHandler: FormikConfig<FormValues>["onSubmit"] = async (values, { setErrors, setSubmitting }) => {
        setSubmitting(false);
        values.backgroundColor = hexCode;
        values.textColor = textColor;
        if (values.groupName != null) {
            const createOrChangeName = !props.edit || (props.edit && props.groupTuple?.name !== values.groupName);
            if (createOrChangeName) {
                setLoading(true);
                const matchingUserGroups = await userGroupsService.fetchGroups(abortController, "", values.groupName);
                if (matchingUserGroups.groups.length !== 0) {
                    setLoading(false);
                    setErrors({ groupName: t("UserGroups.manageUserGroupsView.groupNameAlreadyExists") });
                    return;
                }
            }
            setFormLoader(true);
        }
        await props.submitEventHandler(values);
        setSubmitting(true);
    };
    const [hexCode, setHexCode] = React.useState(props.groupTuple?.backgroundColor || "#3B5DAB");
    const [textColor, setTextColor] = React.useState(props.groupTuple?.textColor || "#FFFFFF");
    const [refreshCount, setRefreshCount] = React.useState<number>(0);

    React.useEffect(() => {
        setRefreshCount((prev) => ++prev);
        async function fetchDetails() {
            await Promise.allSettled([
                tenantService.fetchTenant(deriveTenantUuid()),
                userGroupsService.fetchAllGroups(abortController),
                props.edit
                    ? userService.fetchUsers(abortController, "", "", props.groupTuple?.uuid, undefined)
                    : Promise.reject(),
            ]).then(([tenantResult, groupResult, groupUsers]) => {
                if (tenantResult.status === "fulfilled") {
                    setRoles(tenantResult.value.roles);
                } else {
                    setFetchFailure({ failed: true, errorModalVisible: true });
                }
                if (groupResult.status === "fulfilled") {
                    setGroups(groupResult.value.groups);
                } else {
                    setFetchFailure({ failed: true, errorModalVisible: true });
                }

                if (groupUsers.status === "fulfilled") {
                    setGroupUsers(groupUsers.value.userTableData);
                }
            });
        }
        fetchDetails();
    }, []);
    const rolesFetched = roles != null && !fetchFailure.failed;
    if (formLoader) {
        return <LoadingIndicator />;
    }
    return !rolesFetched ? (
        <LoadingIndicator />
    ) : (
        <div className={modalStyle.fixedWidthModal}>
            <Formik
                initialValues={{
                    groupName: props.edit && props.groupTuple ? props.groupTuple.name : "",
                    backgroundColor: hexCode,
                    textColor: textColor,
                    roleUuid: props.edit && props.groupTuple ? props.groupTuple.roleUuid || undefined : undefined,
                    usersList: [],
                }}
                onSubmit={submitHandler}
                validationSchema={object().shape({
                    groupName: string().required(t("UserGroups.manageUserGroupsView.groupNameRequired")).min(2).max(32),
                })}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ values, handleChange, handleBlur, setFieldValue, isSubmitting }: FormikProps<FormValues>) => {
                    const loader = loading ? <LoadingIndicator small={true} /> : null;
                    return (
                        <Form>
                            <div className={form.formFields}>
                                <label htmlFor="groupName" className={form.label}>
                                    {t("UserGroups.manageUserGroupsView.groupName")}
                                </label>
                                <input
                                    id="groupName"
                                    className={classNames(form.input, form.fixedWidthInput)}
                                    maxLength={32}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.groupName}
                                    autoFocus
                                    data-testid={testIds.workArea.userGroup.manageUserGroupDialog.groupNameInput.itself}
                                />
                                <div className={style.loaderContainer}>{loader}</div>
                                <div
                                    className={form.error}
                                    data-testid={
                                        testIds.workArea.userGroup.manageUserGroupDialog.groupNameInput.errorLabel
                                    }
                                >
                                    <ErrorMessage name="groupName" />
                                </div>
                            </div>

                            <div className={style.wrapper}>
                                <div className={form.formFields}>
                                    <label htmlFor="color" className={form.label}>
                                        {t("UserGroups.manageUserGroupsView.groupColor")}
                                    </label>
                                    <ColorPicker setColor={setHexCode} selectedColor={hexCode} />
                                    <div className={form.error}>
                                        <ErrorMessage name="color" />
                                    </div>
                                </div>
                                <div className={classNames(form.formFields, style.flexPosition)}>
                                    <label htmlFor="color" className={form.label}>
                                        {t("UserGroups.manageUserGroupsView.textColor")}
                                    </label>
                                    <ColorPicker setColor={setTextColor} selectedColor={textColor} />
                                    <div className={form.error}>
                                        <ErrorMessage name="color" />
                                    </div>
                                </div>
                            </div>
                            <div className={form.formFields}>
                                <span className={form.optional}>{t("Common.optional")}</span>
                                <label htmlFor="roleUuid" className={form.label}>
                                    {t("UserGroups.manageUserGroupsView.groupRole")}
                                </label>
                                <select
                                    id={"roleUuid"}
                                    defaultValue={""}
                                    value={values.roleUuid}
                                    data-testid={
                                        testIds.workArea.userGroup.manageUserGroupDialog.groupRoleSelect.itself
                                    }
                                    onChange={handleChange}
                                    className={classNames(form.select, form.fixedWidthInput)}
                                >
                                    <option key={""} value={""}>
                                        {t("UserGroups.manageUserGroupsView.selectRole")}
                                    </option>
                                    {roles
                                        ? roles
                                              .sort((first, second) => first.name.localeCompare(second.name))
                                              .map((role) => (
                                                  <option key={role.uuid} value={role.uuid}>
                                                      {role.name}
                                                  </option>
                                              ))
                                        : ""}
                                </select>
                                {/*
                                It's impossible to get an error but the
                                element itself takes space in the UI. When
                                all form fields have it, they have
                                equivalent amount of space below them. Just
                                an easy and maintainable way to keep the
                                form field spacing consistent.
                            */}
                                <div
                                    className={form.error}
                                    data-testid={
                                        testIds.workArea.userGroup.manageUserGroupDialog.groupRoleSelect.errorLabel
                                    }
                                >
                                    <ErrorMessage name="roleUuid" />
                                </div>
                            </div>
                            <Heading tag="div" variant="SUBTITLE_1" disableBottomSpacing={true}>
                                <span className={form.optional}>{t("Common.optional")}</span>
                                {t("UserGroups.manageUserGroupsView.groupMembers")}
                            </Heading>
                            <ManageUserTable
                                count={refreshCount}
                                groups={groups}
                                setFieldValue={setFieldValue}
                                groupUsers={groupsUsers}
                                isFormSubmitting={isSubmitting || loading || formLoader}
                            />
                            <div className={style.buttonContainer}>
                                {props.edit && (
                                    <button
                                        className={classNames(
                                            buttons.secondaryButton,
                                            buttons.medium,
                                            form.submitButton
                                        )}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.setModalFormVisible ? props.setModalFormVisible(false) : {};
                                        }}
                                        data-testid={testIds.common.dialog.closeButton}
                                    >
                                        {t("Common.cancel")}
                                    </button>
                                )}

                                <button
                                    type="submit"
                                    disabled={isSubmitting || loading || formLoader}
                                    className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                                    data-testid={testIds.workArea.userGroup.manageUserGroupDialog.submitButton}
                                >
                                    {props.edit
                                        ? t("Common.save")
                                        : t("UserGroups.manageUserGroupsView.createUserGroupButton")}
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
