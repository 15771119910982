// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".h0ZXF-7Kr1VzRxhqzTyKg{text-align:center}.xSZUsKHm8q54_s1VdeVZK{display:flex;column-gap:15px;margin:20px 0;width:405px}._3OAVU87u-i9IZNnFeAsJHV{text-align:right;margin-top:30px}", ""]);
// Exports
exports.locals = {
	"banner": "h0ZXF-7Kr1VzRxhqzTyKg",
	"improvements": "xSZUsKHm8q54_s1VdeVZK",
	"actions": "_3OAVU87u-i9IZNnFeAsJHV"
};
module.exports = exports;
