import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

import style from "components/dashboard/dashboard.scss";
import ChartWidget from "components/dashboard/overview/ChartWidget";
import CreateChartWidget from "components/dashboard/overview/CreateChartWidget";
import OverviewDashboardWidgetMenuItems from "components/dashboard/overview/OverviewDashboardWidgetMenuItems";
import { WidgetContainer } from "components/dashboard/WidgetContainer";
import KebabMenu from "components/kebab-menu/KebabMenu";
import { CDW_NUMBER_OF_COLUMNS, DRAGGABLE_CARD_HANDLE_SELECTOR } from "domain/globalConstants";
import { OverviewDashboardData, Widget, widgetTypes } from "domain/overviewDashboard";

interface CardLayout {
    cols: Record<string, number>;
    rowHeight: number;
    isResizable: boolean;
    margin: [number, number];
    containerPadding: [number, number];
    isBounded: boolean;
}

interface Props {
    overviewDashboardData: OverviewDashboardData;
    onDashboardUpdated: (updatedDashboardIndex: number) => void;
    updatedDashboardIndex: number;
    widgets: Widget[];
}

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const OverviewDashboardContainer = (props: Props): JSX.Element => {
    const gridProperties: CardLayout = {
        cols: {
            lg: CDW_NUMBER_OF_COLUMNS,
            md: CDW_NUMBER_OF_COLUMNS,
            sm: CDW_NUMBER_OF_COLUMNS,
            xs: 1,
            xxs: 1,
        },
        rowHeight: 1,
        isResizable: false,
        margin: [40, 40],
        containerPadding: [0, 0],
        isBounded: true,
    };

    const generateWidget = (widget: Widget): JSX.Element => {
        if (!widgetTypes.includes(widget.type) || "CREATE_CHART" === widget.type) {
            return (
                <CreateChartWidget
                    overviewDashboardData={props.overviewDashboardData}
                    onDashboardUpdated={props.onDashboardUpdated}
                    updatedDashboardIndex={props.updatedDashboardIndex}
                    slotIndex={widget.slotIndex}
                />
            );
        } else {
            return <ChartWidget widget={widget} />;
        }
    };
    const grid = props.widgets.map((widget) => {
        return {
            w: widget.width,
            h: widget.height,
            x: widget.x ?? 0,
            y: widget.y ?? 0,
            i: widget.uuid,
        };
    });

    const smallResolutionGrid = props.widgets.map((widget, index) => {
        return {
            w: widget.width,
            h: widget.height,
            x: index,
            y: widget.height * index,
            i: widget.uuid,
        };
    });
    const defaultLayout = {
        lg: grid,
        md: grid,
        sm: grid,
        xs: smallResolutionGrid,
        xxs: smallResolutionGrid,
    };

    return (
        <div>
            <ResponsiveReactGridLayout
                layouts={defaultLayout}
                {...gridProperties}
                className={style.dashboardViewContainer}
                draggableHandle={DRAGGABLE_CARD_HANDLE_SELECTOR}
            >
                {props.widgets.map((widget) => {
                    const widgetContainer = {
                        key: widget.uuid,
                        title: widget.name,
                        children: generateWidget(widget),
                        description: widget.description,
                        hasInfoIcon: true,
                        type: widget.type,
                        actions: (
                            <KebabMenu>
                                <OverviewDashboardWidgetMenuItems
                                    onDashboardUpdated={props.onDashboardUpdated}
                                    updatedDashboardIndex={props.updatedDashboardIndex}
                                    dashboardData={props.overviewDashboardData}
                                    uuid={widget.uuid}
                                />
                            </KebabMenu>
                        ),
                    };
                    return (
                        <div key={widgetContainer.key} className={style.cardContainer}>
                            <WidgetContainer hideDragIcon={true} {...widgetContainer}>
                                {widgetContainer.children}
                            </WidgetContainer>
                        </div>
                    );
                })}
            </ResponsiveReactGridLayout>
        </div>
    );
};

export default OverviewDashboardContainer;
