import { TFunction } from "i18next";

export function generateCountries(t: TFunction): Array<CodedName> {
    const countries = getCountries(t);
    countries.sort((a, b) => (a.name > b.name ? 1 : -1));
    return countries;
}

export function getCountries(t: TFunction) {
    return [
        { name: t("Country.USA"), code: "US" },
        { name: t("Country.UnitedKingdom"), code: "UK" },
        { name: t("Country.France"), code: "FR" },
        { name: t("Country.Germany"), code: "DE" },
        { name: t("Country.Finland"), code: "FI" },
        { name: t("Country.Ireland"), code: "IE" },
        { name: t("Country.India"), code: "IN" },
        { name: t("Country.Japan"), code: "JP" },
        { name: t("Country.China"), code: "CN" },
        { name: t("Country.Brazil"), code: "BR" },
        { name: t("Country.SaudiArabia"), code: "SA" },
        { name: t("Country.Afghanistan"), code: "AF" },
        { name: t("Country.AlandIslands"), code: "AX" },
        { name: t("Country.Albania"), code: "AL" },
        { name: t("Country.Algeria"), code: "DZ" },
        { name: t("Country.AmericanSamoa"), code: "AS" },
        { name: t("Country.Andorra"), code: "AD" },
        { name: t("Country.Angola"), code: "AO" },
        { name: t("Country.Anguilla"), code: "AI" },
        { name: t("Country.Antarctica"), code: "AQ" },
        { name: t("Country.AntiguaandBarbuda"), code: "AG" },
        { name: t("Country.Argentina"), code: "AR" },
        { name: t("Country.Armenia"), code: "AM" },
        { name: t("Country.Aruba"), code: "AW" },
        { name: t("Country.Australia"), code: "AU" },
        { name: t("Country.Austria"), code: "AT" },
        { name: t("Country.Azerbaijan"), code: "AZ" },
        { name: t("Country.Bahamas"), code: "BS" },
        { name: t("Country.Bahrain"), code: "BH" },
        { name: t("Country.Bangladesh"), code: "BD" },
        { name: t("Country.Barbados"), code: "BB" },
        { name: t("Country.Belarus"), code: "BY" },
        { name: t("Country.Belgium"), code: "BE" },
        { name: t("Country.Belize"), code: "BZ" },
        { name: t("Country.Benin"), code: "BJ" },
        { name: t("Country.Bermuda"), code: "BM" },
        { name: t("Country.Bhutan"), code: "BT" },
        { name: t("Country.BoliviaPlurinationalStateof"), code: "BO" },
        { name: t("Country.BonaireSintEustatiusandSaba"), code: "BQ" },
        { name: t("Country.BosniaandHerzegovina"), code: "BA" },
        { name: t("Country.Botswana"), code: "BW" },
        { name: t("Country.BouvetIsland"), code: "BV" },
        { name: t("Country.BritishIndianOceanTerritory"), code: "IO" },
        { name: t("Country.BruneiDarussalam"), code: "BN" },
        { name: t("Country.Bulgaria"), code: "BG" },
        { name: t("Country.BurkinaFaso"), code: "BF" },
        { name: t("Country.Burundi"), code: "BI" },
        { name: t("Country.Cambodia"), code: "KH" },
        { name: t("Country.Cameroon"), code: "CM" },
        { name: t("Country.Canada"), code: "CA" },
        { name: t("Country.CapeVerde"), code: "CV" },
        { name: t("Country.CaymanIslands"), code: "KY" },
        { name: t("Country.CentralAfricanRepublic"), code: "CF" },
        { name: t("Country.Chad"), code: "TD" },
        { name: t("Country.Chile"), code: "CL" },
        { name: t("Country.ChristmasIsland"), code: "CX" },
        { name: t("Country.CocosKeelingIslands"), code: "CC" },
        { name: t("Country.Colombia"), code: "CO" },
        { name: t("Country.Comoros"), code: "KM" },
        { name: t("Country.Congo"), code: "CG" },
        { name: t("Country.CongotheDemocraticRepublicofthe"), code: "CD" },
        { name: t("Country.CookIslands"), code: "CK" },
        { name: t("Country.CostaRica"), code: "CR" },
        { name: t("Country.CotedIvoire"), code: "CI" },
        { name: t("Country.Croatia"), code: "HR" },
        { name: t("Country.Cuba"), code: "CU" },
        { name: t("Country.Curaçao"), code: "CW" },
        { name: t("Country.Cyprus"), code: "CY" },
        { name: t("Country.CzechRepublic"), code: "CZ" },
        { name: t("Country.Denmark"), code: "DK" },
        { name: t("Country.Djibouti"), code: "DJ" },
        { name: t("Country.Dominica"), code: "DM" },
        { name: t("Country.DominicanRepublic"), code: "DO" },
        { name: t("Country.Ecuador"), code: "EC" },
        { name: t("Country.Egypt"), code: "EG" },
        { name: t("Country.ElSalvador"), code: "SV" },
        { name: t("Country.EquatorialGuinea"), code: "GQ" },
        { name: t("Country.Eritrea"), code: "ER" },
        { name: t("Country.Estonia"), code: "EE" },
        { name: t("Country.Ethiopia"), code: "ET" },
        { name: t("Country.FalklandIslandsMalvinas"), code: "FK" },
        { name: t("Country.FaroeIslands"), code: "FO" },
        { name: t("Country.Fiji"), code: "FJ" },
        { name: t("Country.FrenchGuiana"), code: "GF" },
        { name: t("Country.FrenchPolynesia"), code: "PF" },
        { name: t("Country.FrenchSouthernTerritories"), code: "TF" },
        { name: t("Country.Gabon"), code: "GA" },
        { name: t("Country.Gambia"), code: "GM" },
        { name: t("Country.Georgia"), code: "GE" },
        { name: t("Country.Ghana"), code: "GH" },
        { name: t("Country.Gibraltar"), code: "GI" },
        { name: t("Country.Greece"), code: "GR" },
        { name: t("Country.Greenland"), code: "GL" },
        { name: t("Country.Grenada"), code: "GD" },
        { name: t("Country.Guadeloupe"), code: "GP" },
        { name: t("Country.Guam"), code: "GU" },
        { name: t("Country.Guatemala"), code: "GT" },
        { name: t("Country.Guernsey"), code: "GG" },
        { name: t("Country.Guinea"), code: "GN" },
        { name: t("Country.GuineaBissau"), code: "GW" },
        { name: t("Country.Guyana"), code: "GY" },
        { name: t("Country.Haiti"), code: "HT" },
        { name: t("Country.HeardIslandandMcDonaldIslands"), code: "HM" },
        { name: t("Country.HolySeeVaticanCityState"), code: "VA" },
        { name: t("Country.Honduras"), code: "HN" },
        { name: t("Country.HongKong"), code: "HK" },
        { name: t("Country.Hungary"), code: "HU" },
        { name: t("Country.Iceland"), code: "IS" },
        { name: t("Country.Indonesia"), code: "ID" },
        { name: t("Country.IranIslamicRepublicof"), code: "IR" },
        { name: t("Country.Iraq"), code: "IQ" },
        { name: t("Country.IsleofMan"), code: "IM" },
        { name: t("Country.Israel"), code: "IL" },
        { name: t("Country.Italy"), code: "IT" },
        { name: t("Country.Jamaica"), code: "JM" },
        { name: t("Country.Jersey"), code: "JE" },
        { name: t("Country.Jordan"), code: "JO" },
        { name: t("Country.Kazakhstan"), code: "KZ" },
        { name: t("Country.Kenya"), code: "KE" },
        { name: t("Country.Kiribati"), code: "KI" },
        { name: t("Country.KoreaDemocraticPeoplesRepublicof"), code: "KP" },
        { name: t("Country.KoreaRepublicof"), code: "KR" },
        { name: t("Country.Kuwait"), code: "KW" },
        { name: t("Country.Kyrgyzstan"), code: "KG" },
        { name: t("Country.LaoPeoplesDemocraticRepublic"), code: "LA" },
        { name: t("Country.Latvia"), code: "LV" },
        { name: t("Country.Lebanon"), code: "LB" },
        { name: t("Country.Lesotho"), code: "LS" },
        { name: t("Country.Liberia"), code: "LR" },
        { name: t("Country.Libya"), code: "LY" },
        { name: t("Country.Liechtenstein"), code: "LI" },
        { name: t("Country.Lithuania"), code: "LT" },
        { name: t("Country.Luxembourg"), code: "LU" },
        { name: t("Country.Macao"), code: "MO" },
        { name: t("Country.Madagascar"), code: "MG" },
        { name: t("Country.Malawi"), code: "MW" },
        { name: t("Country.Malaysia"), code: "MY" },
        { name: t("Country.Maldives"), code: "MV" },
        { name: t("Country.Mali"), code: "ML" },
        { name: t("Country.Malta"), code: "MT" },
        { name: t("Country.MarshallIslands"), code: "MH" },
        { name: t("Country.Martinique"), code: "MQ" },
        { name: t("Country.Mauritania"), code: "MR" },
        { name: t("Country.Mauritius"), code: "MU" },
        { name: t("Country.Mayotte"), code: "YT" },
        { name: t("Country.Mexico"), code: "MX" },
        { name: t("Country.MicronesiaFederatedStatesof"), code: "FSM" },
        { name: t("Country.MoldovaRepublicof"), code: "MD" },
        { name: t("Country.Monaco"), code: "MC" },
        { name: t("Country.Mongolia"), code: "MN" },
        { name: t("Country.Montenegro"), code: "ME" },
        { name: t("Country.Montserrat"), code: "MS" },
        { name: t("Country.Morocco"), code: "MA" },
        { name: t("Country.Mozambique"), code: "MZ" },
        { name: t("Country.Myanmar"), code: "MM" },
        { name: t("Country.Namibia"), code: "NA" },
        { name: t("Country.Nauru"), code: "NR" },
        { name: t("Country.Nepal"), code: "NP" },
        { name: t("Country.Netherlands"), code: "NL" },
        { name: t("Country.NetherlandsAntilles"), code: "ANT" },
        { name: t("Country.NewCaledonia"), code: "NC" },
        { name: t("Country.NewZealand"), code: "NZ" },
        { name: t("Country.Nicaragua"), code: "NI" },
        { name: t("Country.Niger"), code: "NE" },
        { name: t("Country.Nigeria"), code: "NG" },
        { name: t("Country.Niue"), code: "NU" },
        { name: t("Country.NorfolkIsland"), code: "NF" },
        { name: t("Country.NorthernMarianaIslands"), code: "MP" },
        { name: t("Country.NorthMacedonia"), code: "MK" },
        { name: t("Country.Norway"), code: "NO" },
        { name: t("Country.Oman"), code: "OM" },
        { name: t("Country.Pakistan"), code: "PK" },
        { name: t("Country.Palau"), code: "PLW" },
        { name: t("Country.PalestinianTerritoryOccupied"), code: "PS" },
        { name: t("Country.Panama"), code: "PA" },
        { name: t("Country.PapuaNewGuinea"), code: "PG" },
        { name: t("Country.Paraguay"), code: "PY" },
        { name: t("Country.Peru"), code: "PE" },
        { name: t("Country.Philippines"), code: "PH" },
        { name: t("Country.Pitcairn"), code: "PN" },
        { name: t("Country.Poland"), code: "PL" },
        { name: t("Country.Portugal"), code: "PT" },
        { name: t("Country.PuertoRico"), code: "PR" },
        { name: t("Country.Qatar"), code: "QA" },
        { name: t("Country.Reunion"), code: "RE" },
        { name: t("Country.Romania"), code: "RO" },
        { name: t("Country.RussianFederation"), code: "RU" },
        { name: t("Country.Rwanda"), code: "RW" },
        { name: t("Country.SaintBarthélemy"), code: "BL" },
        { name: t("Country.SaintHelenaAscensionandTristandaCunha"), code: "SH" },
        { name: t("Country.SaintKittsandNevis"), code: "KN" },
        { name: t("Country.SaintLucia"), code: "LC" },
        { name: t("Country.SaintMartinFrenchpart"), code: "MF" },
        { name: t("Country.SaintPierreandMiquelon"), code: "PM" },
        { name: t("Country.SaintVincentandtheGrenadines"), code: "VC" },
        { name: t("Country.Samoa"), code: "WS" },
        { name: t("Country.SanMarino"), code: "SM" },
        { name: t("Country.SaoTomeandPrincipe"), code: "ST" },
        { name: t("Country.Senegal"), code: "SN" },
        { name: t("Country.Serbia"), code: "RS" },
        { name: t("Country.SerbiaandMontenegro"), code: "CS" },
        { name: t("Country.Seychelles"), code: "SC" },
        { name: t("Country.SierraLeone"), code: "SL" },
        { name: t("Country.Singapore"), code: "SG" },
        { name: t("Country.SintMaartenDutchpart"), code: "SX" },
        { name: t("Country.Slovakia"), code: "SK" },
        { name: t("Country.Slovenia"), code: "SI" },
        { name: t("Country.SolomonIslands"), code: "SB" },
        { name: t("Country.Somalia"), code: "SO" },
        { name: t("Country.SouthAfrica"), code: "ZA" },
        { name: t("Country.SouthGeorgiaandtheSouthSandwichIslands"), code: "GS" },
        { name: t("Country.SouthSudan"), code: "SS" },
        { name: t("Country.Spain"), code: "ES" },
        { name: t("Country.SriLanka"), code: "LK" },
        { name: t("Country.Sudan"), code: "SD" },
        { name: t("Country.Suriname"), code: "SR" },
        { name: t("Country.SvalbardandJanMayen"), code: "SJ" },
        { name: t("Country.Swaziland"), code: "SZ" },
        { name: t("Country.Sweden"), code: "SE" },
        { name: t("Country.Switzerland"), code: "CH" },
        { name: t("Country.SyrianArabRepublic"), code: "SY" },
        { name: t("Country.Taiwan"), code: "TW" },
        { name: t("Country.Tajikistan"), code: "TJ" },
        { name: t("Country.TanzaniaUnitedRepublicof"), code: "TZ" },
        { name: t("Country.Thailand"), code: "TH" },
        { name: t("Country.TimorLeste"), code: "TL" },
        { name: t("Country.Togo"), code: "TG" },
        { name: t("Country.Tokelau"), code: "TK" },
        { name: t("Country.Tonga"), code: "TO" },
        { name: t("Country.TrinidadandTobago"), code: "TT" },
        { name: t("Country.Tunisia"), code: "TN" },
        { name: t("Country.Turkey"), code: "TR" },
        { name: t("Country.Turkmenistan"), code: "TM" },
        { name: t("Country.TurksandCaicosIslands"), code: "TC" },
        { name: t("Country.Tuvalu"), code: "TV" },
        { name: t("Country.Uganda"), code: "UG" },
        { name: t("Country.Ukraine"), code: "UA" },
        { name: t("Country.UnitedArabEmirates"), code: "AE" },
        { name: t("Country.UnitedStatesMinorOutlyingIslands"), code: "UM" },
        { name: t("Country.Uruguay"), code: "UY" },
        { name: t("Country.Uzbekistan"), code: "UZ" },
        { name: t("Country.Vanuatu"), code: "VU" },
        { name: t("Country.VenezuelaBolivarianRepublicof"), code: "VE" },
        { name: t("Country.Vietnam"), code: "VN" },
        { name: t("Country.VirginIslandsBritish"), code: "VG" },
        { name: t("Country.VirginIslandsUnitedStates"), code: "USVI" },
        { name: t("Country.WallisandFutuna"), code: "WF" },
        { name: t("Country.WesternSahara"), code: "EH" },
        { name: t("Country.Yemen"), code: "YE" },
        { name: t("Country.Zambia"), code: "ZM" },
        { name: t("Country.Zimbabwe"), code: "ZW" },
    ];
}

export interface CodedName {
    name: string;
    code: string;
}
