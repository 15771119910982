import * as React from "react";

const ToggleFullscreen = (props: { color: string; fullscreen: boolean }): JSX.Element => {
    return props.fullscreen ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.918" height="13.918" viewBox="0 0 13.918 13.918">
            <g id="icon_minimise" transform="translate(1.061 1.061)">
                <path
                    id="Path_5158"
                    data-name="Path 5158"
                    d="M15,3h3.932V6.932"
                    transform="translate(-14.136 4)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_5159"
                    data-name="Path 5159"
                    d="M6.932,18.932H3V15"
                    transform="translate(4 -14.136)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_1559"
                    data-name="Line 1559"
                    x1="4"
                    y2="4"
                    transform="translate(0 7.797)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_1560"
                    data-name="Line 1560"
                    x1="4"
                    y2="4"
                    transform="translate(11.796 4) rotate(180)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.296" height="13.297" viewBox="0 0 13.296 13.297">
            <g id="icon_maximise" transform="translate(0.75 0.75)">
                <path
                    id="Path_5156"
                    data-name="Path 5156"
                    d="M15,3h3.932V6.932"
                    transform="translate(-7.136 -3)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_5157"
                    data-name="Path 5157"
                    d="M6.932,18.932H3V15"
                    transform="translate(-3 -7.136)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_1555"
                    data-name="Line 1555"
                    x1="4"
                    y2="4"
                    transform="translate(7 0.797)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_1556"
                    data-name="Line 1556"
                    x1="4"
                    y2="4"
                    transform="translate(4.796 11) rotate(180)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </svg>
    );
};

export default ToggleFullscreen;
