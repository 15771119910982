import classNames from "classnames";
import { useFeature } from "flagged";
import React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import style from "./mobile-navigation.scss";
import NavigationTogglePanel from "./NavigationTogglePanel";
import sideNavStyle from "./side-navigation.scss";
import PremiumFeatureIcon from "components/icons/PremiumFeatureIcon";
import {
    API_GUIDE_ROUTE,
    API_KEYS_ROUTE,
    DASHBOARD_ROUTE,
    LICENSES_ROUTE,
    OUR_IMPACT_DASHBOARD_ROUTE,
    REPORT_PATHS_ROUTE,
    REPORTS_OUR_VIEWS_ROUTE,
    REPORTS_ROUTE,
    RouteDefinition,
    SUPPORT_AND_HELP_ROUTE,
    SUPPORT_ROUTE,
    SUSTAINABILITY_DASHBOARD_ROUTE,
    TENANTS_ROUTE,
    USERS_ROUTE,
    WORKFLOWS_ROUTE,
} from "components/router/Routes";
import Tooltip from "components/tooltip/Tooltip";
import { FLAG_NEW_NAVIGATION } from "services/feature/FeatureFlagService";
import { StoreState } from "store";

import iconPrivateView from "assets/images/icons/icon_private_view.svg";

const MobileNavigation = () => {
    const { t } = useTranslation();
    const user = useSelector((state: StoreState) => state.userReducer.user);
    const tenantDetails = useSelector(
        (state: StoreState) => state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1]
    );
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const getTooltip = (route: RouteDefinition, disabled: boolean): JSX.Element => {
        const routes = new Map([
            [
                OUR_IMPACT_DASHBOARD_ROUTE,
                {
                    paid: t("Routes.ourImpact.tooltip.paid"),
                    title: t("Routes.ourImpact.tooltip.unpaid.title"),
                    body: t("Routes.ourImpact.tooltip.unpaid.calculate"),
                    footer: t("Routes.ourImpact.tooltip.unpaid.contactBlancco"),
                },
            ],
            [
                WORKFLOWS_ROUTE,
                {
                    paid: t("Routes.workflowsMenu.paidWorkflowTooltip"),
                    title: t("Routes.workflowsMenu.unpaidTitle"),
                    body: t("Routes.workflowsMenu.automateWorkflowsAndSimplifyCompliance"),
                    footer: t("Routes.workflowsMenu.contactBlancco"),
                },
            ],
        ]);
        const dto = routes.get(route);
        if (dto == null) {
            return <></>;
        }
        if (!disabled) {
            return <div className={style.tooltip}>{dto.paid}</div>;
        }
        return (
            <div className={style.tooltip}>
                <div className={style.title}>{dto.title}</div>
                <div className={style.content}>{dto.body}</div>
                <div>{dto.footer}</div>
            </div>
        );
    };
    interface MobileMenuItems {
        title: string;
        childRoutes: RouteDefinition[];
    }

    const mobileMenuItems: MobileMenuItems[] = [
        {
            title: t("Routes.reporting"),
            childRoutes: [DASHBOARD_ROUTE, REPORTS_ROUTE, SUSTAINABILITY_DASHBOARD_ROUTE],
        },
        {
            title: t("Routes.management"),
            childRoutes: [LICENSES_ROUTE, USERS_ROUTE, TENANTS_ROUTE, WORKFLOWS_ROUTE],
        },
        {
            title: SUPPORT_ROUTE.title,
            childRoutes: [SUPPORT_AND_HELP_ROUTE, API_GUIDE_ROUTE, API_KEYS_ROUTE, REPORT_PATHS_ROUTE],
        },
    ];

    const createMenuItems = (route: RouteDefinition, index: number) => {
        let disabled = false;
        const flag = route.getFeatureFlag();
        if (flag != null && !useFeature(flag)) {
            return null;
        }
        if (!route.isAccessibleTo(tenantDetails)) {
            if (!route.advertised) {
                return null;
            }
            disabled = true;
        }
        if (!route.isValidUser(user)) {
            return null;
        }
        if ((route.childRoutes.length > 0 && !disabled) || REPORTS_OUR_VIEWS_ROUTE.path === route.path) {
            return <li style={{ "--animation-delay": index } as React.CSSProperties}>{createMenuStructure(route)}</li>;
        }

        return (
            <li key={route.path} data-index={index} style={{ "--animation-delay": index } as React.CSSProperties}>
                {disabled ? (
                    <div className={classNames(style.navItem, style.inActive)} data-testid={route.testId}>
                        <div>{t(route.title)}</div>
                        <Tooltip maxWidth={218} content={getTooltip(route, disabled)} placement={"top-start"}>
                            <div className={style.icon}>
                                <PremiumFeatureIcon color={theme.iconFillColor} />
                            </div>
                        </Tooltip>
                    </div>
                ) : (
                    <NavLink to={route.path} data-testid={route.testId} className={style.navItem}>
                        {t(route.title)}
                        {route.advertised && (
                            <Tooltip content={getTooltip(route, disabled)} placement={"top"}>
                                <div className={style.icon}>
                                    <div className={style.purchased} />
                                </div>
                            </Tooltip>
                        )}
                        {route.isShared == false && (
                            <Tooltip content={t("CreateReportView.privateView.title")} placement={"top"}>
                                <div className={style.icon}>
                                    <img
                                        className={sideNavStyle.iconPrivateView}
                                        src={iconPrivateView}
                                        alt={t("AltText.privateView")}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </NavLink>
                )}
            </li>
        );
    };

    const createMenuStructure = (route: RouteDefinition | MobileMenuItems) => {
        return (
            <ul key={route.title}>
                <li>
                    <NavigationTogglePanel
                        isOpen={false}
                        id={route.title}
                        title={t(route.title)}
                        className={classNames({ [style.navItems]: useFeature(FLAG_NEW_NAVIGATION) === true })}
                    >
                        <Menu className={style.menu}>
                            <MenuItem className={style.menuItem}>
                                <ul>
                                    {route.childRoutes.map((childRoute, index) => createMenuItems(childRoute, index))}
                                </ul>
                            </MenuItem>
                        </Menu>
                    </NavigationTogglePanel>
                </li>
            </ul>
        );
    };

    return (
        <nav
            className={classNames(style.mobileNavigation, {
                [style.mainNavigation]: useFeature(FLAG_NEW_NAVIGATION) === true,
            })}
        >
            {mobileMenuItems.map((item) => createMenuStructure(item))}
        </nav>
    );
};

export default MobileNavigation;
