import classNames from "classnames";
import * as React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { connect, ConnectedProps } from "react-redux";

import style from "./tenant-hierarchy.scss";
import ErrorMessage from "components/error-message/ErrorMessage";
import ZoomInIcon from "components/icons/ZoomInIcon";
import ZoomOutIcon from "components/icons/ZoomOutIcon";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import SearchView from "components/search/SearchView";
import { TenantHierarchyResponse, tenantService } from "services/tenants/TenantService";
import { StoreState } from "store";
import formStyle from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    uuid: string;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const ViewTenantHierarchy = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    const [tenantHierarchy, setTenantHierarchy] = React.useState<TenantHierarchyResponse>();
    const [loading, setLoading] = React.useState(true);
    const [searchQuery, setSearchQuery] = React.useState<string>();
    const [requestFailed, setRequestFailed] = React.useState<boolean>(false);
    const hierarchyRef = React.useRef<HTMLDivElement>(null);
    const [hierarchySize, setHierarchySize] = React.useState({
        height: 600,
        width: 1000,
    });

    const zoomingScale = 50;

    React.useEffect(() => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .fetchHierarchy(props.uuid, abortController)
            .then((response) => {
                setTenantHierarchy(response);
            })
            .catch(() => {
                setRequestFailed(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    React.useEffect(() => {
        return () => abortControllers.filter((a) => !a.signal.aborted).forEach((a) => a.abort());
    });

    const highlightTenantName = (tenantName: string): boolean => {
        if (typeof searchQuery === "undefined" || searchQuery.trim() === "") {
            return false;
        }
        return tenantName.toLowerCase().includes(searchQuery.toLowerCase());
    };

    const addStyle = (tenantName?: string) => {
        if (typeof tenantName === "undefined") {
            return style.tenantName;
        }
        return classNames(style.tenantName, {
            [style.highlightTenant]: highlightTenantName(tenantName),
        });
    };

    const createHierarchy = (tenants?: TenantHierarchyResponse[]) => {
        if (typeof tenants == "undefined") {
            return null;
        }
        return tenants.map((each) => {
            return (
                <TreeNode
                    label={
                        <div className={addStyle(each.name)} data-value={each.name}>
                            {each.name}
                        </div>
                    }
                    key={each.uuid}
                >
                    {createHierarchy(each.tenants)}
                </TreeNode>
            );
        });
    };

    const handleZoomIn = () => {
        setHierarchySize({
            height: hierarchySize.height + zoomingScale,
            width: hierarchySize.width + zoomingScale,
        });
    };

    const handleZoomOut = () => {
        setHierarchySize({
            height: hierarchySize.height - zoomingScale,
            width: hierarchySize.width - zoomingScale,
        });
    };

    const fetchAllTenantNames = (names: string[], tenants: TenantHierarchyResponse[]) => {
        if (typeof tenantHierarchy === "undefined") {
            return;
        }
        tenants.forEach((each) => {
            names.push(each.name);
            fetchAllTenantNames(names, each.tenants);
        });
    };

    const moveFocusOnFoundItem = () => {
        if (typeof tenantHierarchy === "undefined") {
            return;
        }
        const allTenantNames: string[] = [tenantHierarchy.name];
        fetchAllTenantNames(allTenantNames, tenantHierarchy.tenants);
        const firstFoundItem = allTenantNames.filter((each) => highlightTenantName(each))[0];
        if (hierarchyRef.current !== null) {
            const foundElement = hierarchyRef.current.querySelector('[data-value="' + firstFoundItem + '"]');
            if (foundElement) {
                foundElement.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
            }
        }
    };

    React.useEffect(() => {
        if (typeof searchQuery !== "undefined") {
            moveFocusOnFoundItem();
        }
    }, [searchQuery]);

    return (
        <>
            {loading ? (
                <LoadingIndicator />
            ) : requestFailed ? (
                <ErrorMessage />
            ) : (
                <div
                    data-testid={testIds.workArea.tenant.hierarchyDialog.itself}
                    data-value={JSON.stringify(tenantHierarchy)}
                >
                    <div>
                        <div className={formStyle.search}>
                            <SearchView setSearch={setSearchQuery} searchInProgress={loading} />
                        </div>
                        <div className={style.hierarchySize} ref={hierarchyRef}>
                            <div style={{ height: hierarchySize.height, width: hierarchySize.width }}>
                                <Tree
                                    lineColor={props.theme.contentBorderColor}
                                    label={
                                        <div
                                            className={addStyle(tenantHierarchy?.name)}
                                            data-value={tenantHierarchy?.name}
                                        >
                                            {tenantHierarchy?.name}
                                        </div>
                                    }
                                >
                                    {createHierarchy(tenantHierarchy?.tenants)}
                                </Tree>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button
                            data-testid={testIds.workArea.tenant.hierarchyDialog.zoomInButton}
                            onClick={handleZoomIn}
                        >
                            <ZoomInIcon color={props.theme.iconFillColor} />
                        </button>
                        <button
                            data-testid={testIds.workArea.tenant.hierarchyDialog.zoomOutButton}
                            onClick={handleZoomOut}
                        >
                            <ZoomOutIcon color={props.theme.iconFillColor} />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default connector(ViewTenantHierarchy);
