import classNames from "classnames";
import * as React from "react";
import { Placement } from "tippy.js";

import style from "./icon-button.scss";
import Tooltip from "components/tooltip/Tooltip";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    testId: string;
    tooltipText?: string;
    tooltipPlacement?: Placement;
    disabled?: boolean;
}

const IconButton = React.forwardRef<HTMLButtonElement, Props>(function IconButton(props, ref) {
    const { testId, tooltipText, ...buttonProps } = props;

    const buttonClasses = classNames(style.iconButton);

    return tooltipText ? (
        <Tooltip content={props.tooltipText} placement={props.tooltipPlacement}>
            <button {...buttonProps} ref={ref} className={buttonClasses} data-testid={testId} type="button">
                {buttonProps.children}
            </button>
        </Tooltip>
    ) : (
        <button {...buttonProps} ref={ref} className={buttonClasses} data-testid={testId} type="button">
            {buttonProps.children}
        </button>
    );
});

export default IconButton;
