import * as React from "react";

import TextWithTooltip from "./TextWithTooltip";
import style from "./uuid-link-cell.scss";

const UuidLinkCell = (props: {
    value: string;
    handler: (event: React.MouseEvent<Element, MouseEvent>) => void;
    tooltip?: boolean;
}): JSX.Element => (
    <button className={style.link} onClick={(event) => props.handler(event)}>
        {props.tooltip ? <TextWithTooltip text={props.value.toLowerCase()} /> : props.value.toLowerCase()}
    </button>
);

export default UuidLinkCell;
