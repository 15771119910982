import React from "react";
import { useTranslation } from "react-i18next";

import style from "./error-message.scss";
import { SUPPORT_EMAIL } from "domain/globalConstants";

import testIds from "testIds.json";

const ErrorMessage = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={style.errorMessage} data-testid={testIds.common.errorMessage.itself}>
            <span data-testid={testIds.common.errorMessage.messageLabel}>{t("Common.errorHasOccurredMessage")}</span>{" "}
            <a href={`mailto:${SUPPORT_EMAIL}`} data-testid={testIds.common.errorMessage.supportLink}>
                {SUPPORT_EMAIL}
            </a>
            {"."}
        </div>
    );
};

export default ErrorMessage;
