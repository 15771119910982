import React from "react";

export const SuccessIcon = (props: { successClass?: string; color: string; text?: string }): JSX.Element => {
    return (
        <div className={props.successClass}>
            <div className={props.successClass}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    style={{ marginRight: "16px" }}
                >
                    <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        fill="#00983D"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.6402 8.2318C17.0645 8.58537 17.1218 9.21593 16.7682 9.64021L11.7682 15.6402C11.5884 15.856 11.326 15.9863 11.0453 15.999C10.7646 16.0117 10.4916 15.9058 10.2929 15.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929L10.9328 13.5186L15.2318 8.35984C15.5853 7.93556 16.2159 7.87824 16.6402 8.2318Z"
                        fill={props.color}
                    />
                </svg>
            </div>
            {props.text}
        </div>
    );
};
