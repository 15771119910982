import classNames from "classnames";
import React from "react";
import { Menu } from "react-aria-menubutton";

import style from "components/dropdown-menu/dropdown-menu.scss";

interface Props {
    menuItems: JSX.Element[];
    /** Positions dropdown in relation to its parent element. */
    position?: Position;
}

type Position = "LEFT" | "RIGHT" | "AUTO";

export const DropdownMenu = (props: Props) => {
    const dropdownMenuClasses = classNames(style.dropdownMenu, {
        [style.positionLeft]: props.position === "LEFT",
        [style.positionAuto]: props.position === "AUTO",
    });

    return (
        <Menu className={dropdownMenuClasses}>
            <ul>
                {props.menuItems.map((item, key) => (
                    <li className={style.dropdownMenuItem} key={key}>
                        {item}
                    </li>
                ))}
            </ul>
        </Menu>
    );
};
