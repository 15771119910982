import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./logout.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { userSessionService } from "services/user/UserSessionService";
import { clearAllTenantDetails } from "store/tenantDetails";
import { logger } from "utils/logging";

const MAP_STATE = () => ({});
const MAP_DISPATCH = { clearAllTenantDetails };
const CONNECTOR = connect(MAP_STATE, MAP_DISPATCH);

const LogoutView = (props: ConnectedProps<typeof CONNECTOR>): JSX.Element => {
    const { t } = useTranslation();
    const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);

    React.useEffect(() => {
        props.clearAllTenantDetails();
        userSessionService
            .logout()
            .then(() => {
                window.location.replace("/login");
            })
            .catch((e) => {
                setShowErrorMessage(true);
                logger.error("Failed to logout", e);
            });
    }, []);

    return showErrorMessage ? (
        <div className={style.errorMessage}>{t("Logout.errorMessage")}</div>
    ) : (
        <LoadingIndicator />
    );
};

export default CONNECTOR(LogoutView);
