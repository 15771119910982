// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2XtCBRtX8-FyAB7viV4yN7{display:flex;width:100%;height:100%}", ""]);
// Exports
exports.locals = {
	"editorCanvas": "_2XtCBRtX8-FyAB7viV4yN7"
};
module.exports = exports;
