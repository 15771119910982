import * as React from "react";

const Edit = (props: { color: string; width?: string; height?: string }): JSX.Element => {
    return (
        <svg
            width={props.width ? props.width : "35.818"}
            height={props.height ? props.height : "47.32"}
            viewBox="0 0 48.32 48.32"
        >
            <defs>
                <filter id="Path_1173" x="0" y="0" width="47.32" height="47.32" filterUnits="userSpaceOnUse">
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="editIcon" transform="translate(4.5 2.5)">
                <g id="Group_2719" data-name="Group 2719" transform="translate(-641 -580)">
                    <g id="Group_2701" data-name="Group 2701" transform="translate(-76.5 367.5)">
                        <g transform="matrix(1, 0, 0, 1, 713, 210)" filter="url(#Path_1173)">
                            <path
                                id="Path_1173-2"
                                data-name="Path 1173"
                                d="M172.02,212.13a19.16,19.16,0,1,1-19.16,19.16A19.16,19.16,0,0,1,172.02,212.13Z"
                                transform="translate(-148.36 -209.63)"
                                fill={props.color}
                            />
                        </g>
                    </g>
                    <path
                        id="edit-2_1_"
                        data-name="edit-2 (1)"
                        d="M12.839,2.77a2.044,2.044,0,0,1,2.89,2.89L5.974,15.416,2,16.5l1.084-3.974Z"
                        transform="translate(650.996 589.824)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Edit;
