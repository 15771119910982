import * as React from "react";

const LineChartIcon = (props: { borderColor: string; fillColor: string }): JSX.Element => {
    return (
        <svg id="chart_line" xmlns="http://www.w3.org/2000/svg" width="72" height="48" viewBox="0 0 72 48">
            <g
                id="Rectangle_1520"
                data-name="Rectangle 1520"
                fill={props.fillColor}
                stroke={props.borderColor}
                strokeWidth="1"
            >
                <rect width="72" height="48" rx="6" stroke="none" />
                <rect x="0.5" y="0.5" width="71" height="47" rx="5.5" fill="none" />
            </g>
            <g id="icons8-line-chart" transform="translate(20 8)">
                <path
                    id="Path_1938"
                    data-name="Path 1938"
                    d="M1.28,15.3a1.231,1.231,0,1,0,0,2.46,1.231,1.231,0,1,0,0-2.46Zm8.32-3.69a1.231,1.231,0,1,0,1.28,1.23A1.256,1.256,0,0,0,9.6,11.611ZM16,6.69a1.231,1.231,0,1,0,1.28,1.23A1.256,1.256,0,0,0,16,6.69Zm6.4,3.69a1.231,1.231,0,1,0,1.28,1.23A1.256,1.256,0,0,0,22.4,10.381ZM30.72,3a1.256,1.256,0,0,0-1.28,1.23,1.281,1.281,0,0,0,2.56,0A1.256,1.256,0,0,0,30.72,3ZM1.28,23.913a1.231,1.231,0,1,0,0,2.46,1.231,1.231,0,1,0,0-2.46Zm8.32,1.23a1.231,1.231,0,1,0,1.28,1.23A1.256,1.256,0,0,0,9.6,25.143ZM16,20.222a1.231,1.231,0,1,0,1.28,1.23A1.256,1.256,0,0,0,16,20.222Zm6.4,6.151a1.231,1.231,0,1,0,1.28,1.23A1.256,1.256,0,0,0,22.4,26.373Zm8.32-7.381A1.231,1.231,0,1,0,32,20.222,1.256,1.256,0,0,0,30.72,18.992Z"
                    fill={props.fillColor}
                />
                <path
                    id="Path_1939"
                    data-name="Path 1939"
                    d="M2,17.331l8.364-3.7L16.8,8.7l6.434,3.7L31.6,5M2,25.963,10.364,27.2,16.8,22.264l6.434,6.166,8.364-7.4"
                    transform="translate(-0.798 -0.798)"
                    fill={props.fillColor}
                    stroke={props.borderColor}
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
            </g>
        </svg>
    );
};

export default LineChartIcon;
