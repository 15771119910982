import * as React from "react";

const Delete = (props: {
    color?: string;
    strokeColor?: string;
    width?: string;
    height?: string;
    noBackground?: boolean;
}): JSX.Element => {
    return props.noBackground ? (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 4.75C9.30964 4.75 8.75 5.30964 8.75 6V6.25H15.25V6C15.25 5.30964 14.6904 4.75 14 4.75H10ZM16.75 6.25V6C16.75 4.48122 15.5188 3.25 14 3.25H10C8.48122 3.25 7.25 4.48122 7.25 6V6.25H4C3.58579 6.25 3.25 6.58579 3.25 7C3.25 7.41421 3.58579 7.75 4 7.75H5.25V18C5.25 19.5188 6.48122 20.75 8 20.75H16C17.5188 20.75 18.75 19.5188 18.75 18V7.75H20C20.4142 7.75 20.75 7.41421 20.75 7C20.75 6.58579 20.4142 6.25 20 6.25H16.75ZM17.25 7.75H6.75V18C6.75 18.6904 7.30964 19.25 8 19.25H16C16.6904 19.25 17.25 18.6904 17.25 18V7.75ZM10 10.25C10.4142 10.25 10.75 10.5858 10.75 11V16C10.75 16.4142 10.4142 16.75 10 16.75C9.58579 16.75 9.25 16.4142 9.25 16V11C9.25 10.5858 9.58579 10.25 10 10.25ZM14 10.25C14.4142 10.25 14.75 10.5858 14.75 11V16C14.75 16.4142 14.4142 16.75 14 16.75C13.5858 16.75 13.25 16.4142 13.25 16V11C13.25 10.5858 13.5858 10.25 14 10.25Z"
                fill={props.strokeColor}
            />
        </svg>
    ) : (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : "35.818"}
            height={props.height ? props.height : "48.818"}
            viewBox="0 0 48.818 48.818"
        >
            <defs>
                <filter id="Path_1173" x="0" y="0" width="47.318" height="47.318" filterUnits="userSpaceOnUse">
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="delete_bin" transform="translate(4.5 2.5)">
                <g id="Group_3526" data-name="Group 3526" transform="translate(-633 -570)">
                    <g id="Group_2701" data-name="Group 2701" transform="translate(-84.5 357.5)">
                        <g transform="matrix(1, 0, 0, 1, 713, 210)" filter="url(#Path_1173)">
                            <path
                                id="Path_1173-2"
                                data-name="Path 1173"
                                d="M172.019,212.13a19.159,19.159,0,1,1-19.159,19.159,19.159,19.159,0,0,1,19.159-19.159Z"
                                transform="translate(-148.36 -209.63)"
                                fill={props.color ? props.color : "none"}
                            />
                        </g>
                    </g>
                </g>
                <g id="trash-2" transform="translate(7.999 9)">
                    <path
                        id="Path_1885"
                        data-name="Path 1885"
                        d="M3,6H18.7"
                        transform="translate(0 -0.8)"
                        fill="none"
                        stroke={props.strokeColor ? props.strokeColor : "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_1886"
                        data-name="Path 1886"
                        d="M17.7,5.2V16.4A1.717,1.717,0,0,1,15.886,18H6.814A1.717,1.717,0,0,1,5,16.4V5.2m2.721,0V3.6A1.717,1.717,0,0,1,9.536,2h3.629a1.717,1.717,0,0,1,1.814,1.6V5.2"
                        transform="translate(-0.387)"
                        fill="none"
                        stroke={props.strokeColor ? props.strokeColor : "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <line
                        id="Line_750"
                        data-name="Line 750"
                        y2="5"
                        transform="translate(9.487 9.048)"
                        fill="none"
                        stroke={props.strokeColor ? props.strokeColor : "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <line
                        id="Line_751"
                        data-name="Line 751"
                        y2="5"
                        transform="translate(12.731 9.048)"
                        fill="none"
                        stroke={props.strokeColor ? props.strokeColor : "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Delete;
