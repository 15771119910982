import classNames from "classnames";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { object, string } from "yup";

import { PoolDetails } from "../LicensePoolKebabMenu";
import style from "./manage-license-pool.scss";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import Info from "components/icons/Info";
import { SuccessIcon } from "components/icons/SuccessIcon";
import layoutStyle from "components/layout/layout.scss";
import { License } from "components/licenses/delivery-history/DeliveryFormContent";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import StaticTable from "components/support/api-guide/StaticTable";
import Tooltip from "components/tooltip/Tooltip";
import { MAX_DATE } from "domain/licenses";
import {
    LicensePoolList,
    LicensePoolListResponse,
    licensePoolService,
    Licenses,
    MoveLicensesDataDto,
} from "services/licenses/LicensePoolService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { formatDateWithoutTime } from "utils/format";

import alert from "assets/images/icons/alertIcon.svg";

import testIds from "testIds.json";

interface Props {
    poolDetails: PoolDetails;
    availableLicenses: License[];
    setMoveLicensesDialog: (value: React.SetStateAction<boolean>) => void;
    isEdit?: (value: boolean) => void;
    hideDialogView?: (value: boolean) => void;
    edit: boolean;
    setRefreshCount?: React.Dispatch<React.SetStateAction<number>>;
}

interface MoveLicenses {
    productName: string;
    type: string;
    amount: number;
}
export interface FormValues {
    poolUuid: string;
    licenses: MoveLicenses[];
}

interface ShowConfirmationDialog {
    poolUuid: string;
    poolName: string;
    show: boolean;
}

const MoveLicensesDialog = (props: Props) => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [confirmationLoading, setConfirmationLoading] = React.useState<boolean>(false);
    const [licensePoolsList, setLicensePoolsList] = React.useState<LicensePoolList[]>([]);
    const [moveLicenses, setMoveLicenses] = React.useState<MoveLicenses[]>([]);
    const [showConfirmationDialog, setShowConfirmationDialogg] = React.useState<ShowConfirmationDialog>({
        poolUuid: "",
        poolName: "",
        show: false,
    });

    const deduceSelectedLicenses = (): License[] => {
        const deducedLicenses: License[] = [];
        props.availableLicenses.map((each) => {
            const foundLicense = props.poolDetails.licenses.find((item) => item.licenseId == each.licenseType);
            if (foundLicense) {
                each["licensesToAdd"] = foundLicense ? foundLicense?.amount : 0;
                deducedLicenses.push(each);
            }
        });
        return deducedLicenses;
    };

    const fetchLicensePoolsList = () => {
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licensePoolService
            .fetchLicensePoolsList(abortController)
            .then((result: LicensePoolListResponse) => {
                setLicensePoolsList(result.pools);
                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                }
            })
            .finally(() => setLoading(false));
    };

    const submitHandler: FormikConfig<FormValues>["onSubmit"] = (values, { setErrors, setSubmitting }) => {
        setSubmitting(false);
        if (moveLicenses.length === 0) {
            setErrors({
                licenses: t("LicensePools.move.table.errorMessage"),
            });
            return;
        } else {
            const selectedPool = licensePoolsList.find((each) => each.poolUuid == values.poolUuid);

            setShowConfirmationDialogg({
                poolName: selectedPool ? selectedPool.poolName : "",
                show: true,
                poolUuid: values.poolUuid,
            });
        }
    };

    const moveLicenseSubmitHandler = (poolUuid: string) => {
        setConfirmationLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const licenses: Licenses[] = [];
        moveLicenses.map((each) =>
            licenses.push({
                type: each.type,
                amount: each.amount,
            })
        );
        const data: MoveLicensesDataDto = {
            poolUuid: poolUuid,
            licenses: licenses,
        };
        licensePoolService.moveLicenses(props.poolDetails.uuid, data, abortController).then(() => {
            setMoveLicenses([]);
            toast(
                <SuccessIcon
                    successClass={layoutStyle.customToastSuccessIcon}
                    color={theme.contentBackgroundColor}
                    text={t("LicensePools.move.success")}
                />,
                {
                    closeButton: (closeToastProps) => (
                        <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                    ),
                    className: layoutStyle.customToastSuccessMessage,
                }
            );
            setConfirmationLoading(false);
            setShowConfirmationDialogg({
                poolUuid: "",
                poolName: "",
                show: false,
            });
            props.setMoveLicensesDialog(false);
            props.hideDialogView ? props.hideDialogView(false) : undefined;
            props.isEdit ? props.isEdit(false) : undefined;
            props.setRefreshCount ? props.setRefreshCount((prev) => ++prev) : undefined;
        });
    };

    React.useEffect(() => {
        fetchLicensePoolsList();
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    return loading ? (
        <LoadingIndicator />
    ) : (
        <Formik
            initialValues={{ poolUuid: "", licenses: [] }}
            onSubmit={submitHandler}
            validationSchema={object().shape({
                poolUuid: string().required(t("LicensePools.validation.poolName")),
            })}
            validateOnChange={false}
        >
            {({ values, handleChange, errors, isSubmitting }: FormikProps<FormValues>) => {
                return (
                    <Form>
                        <div className={style.licensePool}>
                            <label
                                data-testid={testIds.workArea.license.licensePools.moveLicensesDialog.targetPool.label}
                            >
                                {t("LicensePools.move.selectLicensePoolLabel", { poolName: props.poolDetails.name })}{" "}
                            </label>
                            <select
                                name="poolUuid"
                                onChange={handleChange}
                                defaultValue={values.poolUuid}
                                className={classNames(
                                    form.select,
                                    style.fixedWidthInput,
                                    style.ellipsis,
                                    style.selectArrow,
                                    {
                                        [form.inputError]: errors.poolUuid,
                                    }
                                )}
                                data-testid={testIds.workArea.license.licensePools.moveLicensesDialog.targetPool.itself}
                            >
                                <option value={""}>{t("LicensePools.move.selectLicensePool")}</option>
                                {licensePoolsList
                                    .filter((each) => each.poolName != props.poolDetails.name)
                                    .map((each) => {
                                        return (
                                            <option key={each.poolUuid} value={each.poolUuid}>
                                                {each.poolName}
                                            </option>
                                        );
                                    })}
                            </select>
                            <div
                                className={form.error}
                                data-testid={
                                    testIds.workArea.license.licensePools.moveLicensesDialog.targetPool.errorLabel
                                }
                            >
                                {errors.poolUuid}
                            </div>
                        </div>
                        <StaticTable
                            tableClass={classNames(style.moveLicensesTable, {
                                [style.inputError]: errors.licenses,
                            })}
                            testId={testIds.workArea.license.licensePools.moveLicensesDialog.table.itself}
                            headers={[
                                {
                                    value: t("LicensePools.move.table.licenseType"),
                                },
                                {
                                    value: t("LicensePools.move.table.from"),
                                },
                                {
                                    value: t("LicensePools.move.table.to"),
                                },
                                {
                                    value: (
                                        <Tooltip content={t("LicensePools.view.licenses.table.tooltip")}>
                                            <span className={style.info}>
                                                <div className={style.infoText}>
                                                    {t("LicensePools.move.table.expirationDate")}
                                                </div>
                                                <Info
                                                    borderColor={theme.contentBackgroundColor}
                                                    color={theme.iconFillColor}
                                                />
                                            </span>
                                        </Tooltip>
                                    ),
                                },
                            ]}
                            cells={deduceSelectedLicenses().map((each) => {
                                return [
                                    <div
                                        key={each.licenseType}
                                        data-testid={
                                            testIds.workArea.license.licensePools.moveLicensesDialog.table
                                                .licenseTypeLabel
                                        }
                                    >
                                        {each.productName}
                                    </div>,
                                    <div
                                        key={each.licenseType}
                                        data-testid={
                                            testIds.workArea.license.licensePools.moveLicensesDialog.table
                                                .availableLabel
                                        }
                                    >
                                        {each.licensesToAdd}
                                    </div>,
                                    <div key={each.licenseType}>
                                        <input
                                            className={classNames(form.input, style.inputWidth)}
                                            key={"move" + each.index}
                                            id={"move" + each.index}
                                            type={"number"}
                                            max={each.licensesToAdd}
                                            min={0}
                                            defaultValue={"0"}
                                            onChange={(e) => {
                                                const amount = Math.abs(parseInt(e.target.value));
                                                if (amount > 0) {
                                                    setMoveLicenses((prev) => [
                                                        ...prev.filter((license) => license.type != each.licenseType),
                                                        {
                                                            productName: each.productName,
                                                            type: each.licenseType,
                                                            amount: amount,
                                                        },
                                                    ]);
                                                } else {
                                                    setMoveLicenses((prev) => [
                                                        ...prev.filter((license) => license.type !== each.licenseType),
                                                    ]);
                                                }
                                            }}
                                            data-testid={
                                                testIds.workArea.license.licensePools.moveLicensesDialog.table
                                                    .assignedAmountInput
                                            }
                                        />
                                    </div>,
                                    <div key={each.licenseType}>
                                        <div
                                            key={"expirationDate" + each.index}
                                            className={style.gridRows}
                                            data-testid={
                                                testIds.workArea.license.licensePools.moveLicensesDialog.table
                                                    .expirationDateLabel
                                            }
                                        >
                                            {each.licenseType != "default"
                                                ? formatDateWithoutTime(each.expirationDate)
                                                : formatDateWithoutTime(MAX_DATE.toString())}
                                        </div>
                                    </div>,
                                ];
                            })}
                        />
                        <div
                            className={classNames(
                                errors.licenses != undefined ? style.moveLicensesInputError : form.error
                            )}
                            data-testid={testIds.workArea.license.licensePools.moveLicensesDialog.table.errorLabel}
                        >
                            <img className={style.alertIcon} src={errors.licenses ? alert : ""} />
                            <span className={style.moveLicensesInputErrorMessage}>{errors.licenses}</span>
                        </div>
                        <div className={form.buttonContainer}>
                            <button
                                className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                                disabled={isSubmitting}
                                type="submit"
                                data-testid={testIds.workArea.license.licensePools.moveLicensesDialog.moveButton}
                            >
                                {t("LicensePools.move.moveButton")}
                            </button>
                            <button
                                className={classNames(
                                    buttons.primaryButton,
                                    buttons.secondaryButton,
                                    buttons.medium,
                                    form.submitButton
                                )}
                                disabled={isSubmitting}
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.setMoveLicensesDialog(false);
                                }}
                                data-testid={testIds.common.dialog.closeButton}
                            >
                                {t("LicensePools.move.cancelButton")}
                            </button>
                        </div>
                        <Modal
                            isOpen={showConfirmationDialog.show}
                            hideModal={() =>
                                setShowConfirmationDialogg({
                                    poolUuid: "",
                                    poolName: "",
                                    show: false,
                                })
                            }
                            modalTitle={t("LicensePools.move.confirmationDialog.title", {
                                poolName: showConfirmationDialog.poolName,
                            })}
                        >
                            {confirmationLoading ? (
                                <LoadingIndicator />
                            ) : (
                                <div>
                                    <label
                                        data-testid={
                                            testIds.workArea.license.licensePools.moveLicensesDialog.confirmationDialog
                                                .label
                                        }
                                    >
                                        <b>{t("LicensePools.move.confirmationDialog.message")}</b>
                                    </label>
                                    <div
                                        data-testid={
                                            testIds.workArea.license.licensePools.moveLicensesDialog.confirmationDialog
                                                .licenses
                                        }
                                    >
                                        {moveLicenses.map((each) => {
                                            return <div key={each.type}>{each.productName + " - " + each.amount}</div>;
                                        })}
                                    </div>
                                    <div className={form.buttonContainer}>
                                        <button
                                            className={classNames(
                                                buttons.primaryButton,
                                                buttons.medium,
                                                form.submitButton
                                            )}
                                            disabled={isSubmitting}
                                            type="button"
                                            onClick={() => moveLicenseSubmitHandler(showConfirmationDialog.poolUuid)}
                                            data-testid={testIds.common.confirmationDialog.confirmButton}
                                        >
                                            {t("LicensePools.move.confirmButton")}
                                        </button>
                                        <button
                                            className={classNames(
                                                buttons.primaryButton,
                                                buttons.secondaryButton,
                                                buttons.medium,
                                                form.submitButton
                                            )}
                                            disabled={isSubmitting}
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowConfirmationDialogg({
                                                    poolUuid: "",
                                                    poolName: "",
                                                    show: false,
                                                });
                                            }}
                                            data-testid={testIds.common.dialog.closeButton}
                                        >
                                            {t("LicensePools.move.cancelButton")}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Modal>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default MoveLicensesDialog;
