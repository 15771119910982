import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import SelectSearch, { SelectedOptionValue } from "react-select-search";
import { toast } from "react-toastify";
import { object, string } from "yup";

import style from "./manage-license-alert.scss";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { DeleteIcon } from "components/icons/DeleteIcon";
import LeftArrow from "components/icons/LeftArrow";
import { SuccessIcon } from "components/icons/SuccessIcon";
import Warning from "components/icons/Warning";
import layoutStyle from "components/layout/layout.scss";
import { addMoreLicenses } from "components/licenses/delivery-history/DeliveryFormContent";
import { createProductsMap } from "components/licenses/delivery-history/ViewDeliveryHistory";
import { DEFAULT_LICENSE } from "components/licenses/licenses-pool/manage-license-pools/ManageLicensePoolView";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import StaticTable from "components/support/api-guide/StaticTable";
import Heading from "components/typography/heading/Heading";
import { AUTH_LICENSE_VIEW, AUTH_TENANT_VIEW } from "domain/authority";
import { LicenseData, LicensePoolLicense } from "domain/licenses";
import { Tenant } from "domain/tenants";
import {
    Alert,
    LicenseAlertDetails,
    LicenseAlertResult,
    licenseAlertsService,
} from "services/licenses/LicenseAlertService";
import { LicensePoolList, licensePoolService } from "services/licenses/LicensePoolService";
import { LicenseResponse, licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { createFilterOptions } from "utils/commonFunctions";
import { formatDateWithoutTime, formatIsoDate } from "utils/format";

import testIds from "testIds.json";

interface Props {
    hideDialogView: (value: boolean) => void;
    initialTenantData?: Tenant[];
    edit: boolean;
    alertData: LicenseAlertValues;
    setEditView: (value: boolean) => void;
    tenantsView?: boolean;
    tenantName: string;
    tenantUuid: string;
}

export interface LicenseAlertData {
    type: string;
    expirationDate: string;
    assigned?: number;
    amountThreshold: number;
    expirationThreshold: number;
    triggered: boolean;
    remainingLicenses?: number;
    overallRemaining?: number;
    amountInputVisibility: boolean;
    daysInputVisibility: boolean;
}
export interface LicenseAlertValues {
    alertUuid: string;
    alertName: string;
    tenantName: string;
    tenantUuid: string;
    expirationDate: string;
    availableLicenses: LicenseAlertData[];
    poolName: string;
    poolUuid: string;
}

const MIN_LICENSE_ALERT_NAME = 2;
const MAX_LICENSE_ALERT_NAME = 32;
const EXPIRATION_THRESHOLD = "EXPIRATION";
const AMOUNT_THRESHOLD = "AMOUNT";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const ManageLicenseAlertView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const selectSearchComponent: React.RefObject<React.Component> = React.createRef();
    const [tenantUuid, setTenantUuid] = React.useState<string>("");
    const abortController = new AbortController();
    const _1_000_000 = 1000000;
    const _365 = 365;
    const defaultSelectedLicense: LicenseAlertData = {
        amountThreshold: Number.NaN,
        expirationThreshold: Number.NaN,
        triggered: false,
        type: DEFAULT_LICENSE,
        expirationDate: formatIsoDate(new Date()),
        amountInputVisibility: false,
        daysInputVisibility: false,
    };

    const [originalLicenses, setOriginalLicenses] = React.useState<LicenseData[]>([]);
    const [licenses, setLicenses] = React.useState<LicenseAlertData[]>([]);
    const [licensesEdit, setLicensesEdit] = React.useState<LicenseAlertData[]>([defaultSelectedLicense]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showFailureDialog, setShowFailureDialog] = React.useState<boolean>(false);
    const [tenantName, setTenantName] = React.useState<string>(props.tenantName);
    const [poolName, setPoolName] = React.useState<string>("");
    const [hideLoader, setHideLoader] = React.useState<boolean>(false);
    const [licenseLoader, setLicenseLoader] = React.useState<boolean>(false);
    const [poolLoader, setPoolLoader] = React.useState<boolean>(false);
    const [selectedPoolNames, setSelectedPoolNames] = React.useState<LicensePoolList[]>([]);
    const [poolUuid, setPoolUuid] = React.useState<string>("");
    const addRow = () => {
        const newAdded: LicenseAlertData = {
            expirationDate: licenses[licenses.length - 1].expirationDate,
            expirationThreshold: defaultSelectedLicense.expirationThreshold,
            amountThreshold: defaultSelectedLicense.amountThreshold,
            type: defaultSelectedLicense.type,
            remainingLicenses: licenses[licenses.length - 1].assigned,
            amountInputVisibility: false,
            daysInputVisibility: false,
            triggered: false,
        };
        if (props.edit) {
            const newLicenses = licensesEdit.concat([newAdded]);
            setLicensesEdit(newLicenses);
        } else {
            const newLicenses = licenses.concat([newAdded]);
            setLicenses(newLicenses);
        }
    };

    function validateExpiryDate(license: LicenseAlertData) {
        return Math.floor(new Date(license.expirationDate).getTime() / 1000) >= Math.floor(new Date().getTime() / 1000);
    }

    function disableShowInput(license: LicenseAlertData) {
        return !validateExpiryDate(license) || license.remainingLicenses == 0;
    }

    const updateInputVisibility = (
        onAmount: boolean,
        license: LicenseAlertData,
        disableExpiredValueLicense: boolean
    ) => {
        if (props.edit) {
            setLicensesEdit(
                licensesEdit.map((each) => {
                    if (each.type === license.type) {
                        if (disableExpiredValueLicense) {
                            each.amountInputVisibility = false;
                            each.daysInputVisibility = false;
                        } else {
                            if (onAmount) {
                                each.amountInputVisibility = true;
                            } else {
                                each.daysInputVisibility = true;
                            }
                        }
                    }
                    return each;
                })
            );
        } else {
            setLicenses(
                licenses.map((each) => {
                    if (each.type === license.type) {
                        if (disableExpiredValueLicense) {
                            if (each.remainingLicenses == 0) {
                                each.amountInputVisibility = false;
                                if (!onAmount) {
                                    each.daysInputVisibility = true;
                                }
                            } else if (!validateExpiryDate(license)) {
                                if (!onAmount) {
                                    each.amountInputVisibility = true;
                                }
                                each.daysInputVisibility = false;
                            }
                        } else {
                            if (onAmount) {
                                each.amountInputVisibility = true;
                            } else {
                                each.daysInputVisibility = true;
                            }
                        }
                    }
                    return each;
                })
            );
        }
    };
    const showInput = (onAmount: boolean, license: LicenseAlertData) => {
        return (
            <div
                key={"button" + license.type}
                onClick={() => {
                    updateInputVisibility(onAmount, license, disableShowInput(license));
                }}
                className={
                    props.edit
                        ? disableShowInput(license)
                            ? classNames(
                                  style.disabledButtonForegroundColor,
                                  buttons.disabledTextColor,
                                  style.formFields
                              )
                            : classNames(style.linkColor, buttons.textButton, style.formFields)
                        : classNames(style.linkColor, buttons.textButton, style.formFields)
                }
            >
                {onAmount ? t("LicenseAlerts.setAmount") : t("LicenseAlerts.setDays")}
            </div>
        );
    };
    const openAmountInput = (license: LicenseAlertData) => {
        return (
            <div>
                {license.amountInputVisibility || license.amountThreshold > 0 ? (
                    <div key={"amountLeft" + license}>
                        <input
                            autoFocus
                            className={classNames(form.input, style.inputWidth)}
                            key={"left" + license}
                            id={"left" + license}
                            type={"number"}
                            disabled={props.edit ? disableShowInput(license) : license.remainingLicenses == 0}
                            max={
                                license.remainingLicenses == undefined
                                    ? license.overallRemaining
                                    : license.remainingLicenses
                            }
                            onChange={(e) => {
                                if (
                                    Number(e.target.value) < 0 ||
                                    Number(e.currentTarget.value) >
                                        Number(
                                            license.remainingLicenses == undefined
                                                ? license.overallRemaining
                                                : license.remainingLicenses
                                        )
                                ) {
                                    return 0;
                                }
                                if (Number(e.target.value) > _1_000_000) {
                                    return _1_000_000;
                                }
                                const targetValue =
                                    e.target.value == "" || isNaN(Number(e.target.value))
                                        ? 0
                                        : parseInt(e.target.value);
                                props.edit
                                    ? setLicensesEdit(deduceInputValue(license, targetValue, AMOUNT_THRESHOLD))
                                    : setLicenses(deduceInputValue(license, targetValue, AMOUNT_THRESHOLD));
                            }}
                            value={isNaN(license.amountThreshold) ? 0 : license.amountThreshold}
                            data-testid={
                                testIds.workArea.license.licenseAlerts.manageLicenseAlertView.table.amountThresholdInput
                                    .itself
                            }
                        />
                    </div>
                ) : (
                    showInput(true, license)
                )}
            </div>
        );
    };

    const openDaysInput = (license: LicenseAlertData) => {
        return (
            <div>
                {license.daysInputVisibility || license.expirationThreshold > 0 ? (
                    <div key={"daysLeft" + license}>
                        <input
                            autoFocus
                            className={classNames(form.input, style.inputWidth)}
                            key={"added" + license}
                            id={"added" + license}
                            type={"number"}
                            disabled={props.edit ? disableShowInput(license) : !validateExpiryDate(license)}
                            max={calculateDays(license.expirationDate)}
                            onChange={(e) => {
                                if (
                                    Number(e.target.value) < 0 ||
                                    Number(e.currentTarget.value) > calculateDays(license.expirationDate)
                                ) {
                                    return 0;
                                }
                                if (Number(e.target.value) > _365) {
                                    return _365;
                                }
                                const targetValue =
                                    e.target.value == "" || isNaN(Number(e.target.value))
                                        ? 0
                                        : parseInt(e.target.value);
                                props.edit
                                    ? setLicensesEdit(deduceInputValue(license, targetValue, EXPIRATION_THRESHOLD))
                                    : setLicenses(deduceInputValue(license, targetValue, EXPIRATION_THRESHOLD));
                            }}
                            data-testid={
                                testIds.workArea.license.licenseAlerts.manageLicenseAlertView.table
                                    .expirationThresholdInput.itself
                            }
                            value={isNaN(license.expirationThreshold) ? 0 : license.expirationThreshold}
                        />
                    </div>
                ) : (
                    showInput(false, license)
                )}
            </div>
        );
    };
    const ALL_PRODUCTS = createProductsMap();
    const deduceSelectedLicenses = (): LicenseAlertData[] => {
        return [defaultSelectedLicense];
    };

    const convertToLicenseAlertData = (license: LicenseData): LicenseAlertData => {
        return {
            expirationDate: license.expirationDate,
            type: license.type,
            remainingLicenses: license.overallRemaining ? license.overallRemaining : license.available,
            amountInputVisibility: false,
            triggered: false,
            daysInputVisibility: false,
            amountThreshold: Number.NaN,
            expirationThreshold: Number.NaN,
        };
    };

    function foundOriginalLicenses(foundLicenses: LicenseData[]) {
        const extractLicenses: LicenseData[] = [];
        foundLicenses.map((each) => {
            if (props.edit) {
                extractLicenses.push(each);
            } else {
                if (
                    Math.floor(new Date(each.expirationDate).getTime() / 1000) >=
                    Math.floor(new Date().getTime() / 1000)
                ) {
                    extractLicenses.push(each);
                }
            }
        });
        setOriginalLicenses(extractLicenses);
    }

    function deduceLicensesForAlert(selectedLicenses: LicenseAlertData[]): LicenseAlertDetails[] {
        return selectedLicenses.map((each) => {
            return {
                product: each.type,
                amount_threshold: each.amountThreshold,
                expiration_threshold: each.expirationThreshold,
                triggered: each.triggered,
            };
        });
    }

    const submitHandler: FormikConfig<LicenseAlertValues>["onSubmit"] = async (
        values,
        { setSubmitting, setErrors }
    ) => {
        setSubmitting(false);
        if (values.alertName !== null && props.alertData.alertName != values.alertName) {
            setHideLoader(false);
            setLoading(true);
            try {
                const licenseAlerts = await licenseAlertsService.fetchAllLicenseAlerts(
                    abortController,
                    "",
                    "",
                    values.alertName
                );
                if (licenseAlerts.alerts.length > 0) {
                    setLoading(false);
                    setHideLoader(true);
                    setErrors({
                        alertName: t("LicenseAlerts.alreadyExists"),
                    });
                    return;
                }
            } catch (e) {
                setLoading(false);
            }
        }
        values.availableLicenses = licenses;
        const createRequest = {
            alertName: values.alertName,
            tenantUuid: tenantUuid,
            poolUuid: poolUuid !== "" ? poolUuid : null,
            licenses: deduceLicensesForAlert(values.availableLicenses),
        };
        const editRequest = {
            alertName: values.alertName,
            licenses: deduceLicensesForAlert(
                licensesEdit.filter(
                    (eachLicense) =>
                        eachLicense.expirationThreshold !== 0 ||
                        eachLicense.expirationThreshold == null ||
                        eachLicense.amountThreshold !== 0 ||
                        eachLicense.amountThreshold == null
                )
            ),
            tenantUuid: tenantUuid,
            poolUuid: "",
        };

        abortControllers.push(abortController);
        const { signal } = abortController;

        props.edit
            ? licenseAlertsService
                  .editLicenseAlerts(props.alertData.alertUuid, editRequest, abortController)
                  .then(() => {
                      toast(
                          <SuccessIcon
                              successClass={layoutStyle.customToastSuccessIcon}
                              color={theme.contentBackgroundColor}
                              text={t("LicenseAlerts.edit.success")}
                          />,
                          {
                              closeButton: (closeToastProps) => (
                                  <CustomToastCloseButton
                                      closeToast={{ ...closeToastProps }}
                                      color={theme.iconFillColor}
                                  />
                              ),
                              className: layoutStyle.customToastSuccessMessage,
                          }
                      );
                      props.setEditView(false);
                      props.hideDialogView ? props.hideDialogView(false) : undefined;
                  })
                  .catch(() => {
                      if (!signal.aborted) {
                          props.setEditView(true);
                          props.hideDialogView ? props.hideDialogView(false) : undefined;
                          setShowFailureDialog(true);
                      }
                  })
                  .finally(() => {
                      setLoading(false);
                  })
            : licenseAlertsService
                  .createLicenseAlert(createRequest, abortController)
                  .then(() => {
                      setSubmitting(true);
                      props.hideDialogView ? props.hideDialogView(false) : undefined;
                      toast(
                          <SuccessIcon
                              successClass={layoutStyle.customToastSuccessIcon}
                              color={theme.contentBackgroundColor}
                              text={t("LicenseAlerts.success")}
                          />,
                          {
                              closeButton: (closeToastProps) => (
                                  <CustomToastCloseButton
                                      closeToast={{ ...closeToastProps }}
                                      color={theme.iconFillColor}
                                  />
                              ),
                              className: layoutStyle.customToastSuccessMessage,
                          }
                      );
                  })
                  .catch(() => {
                      if (!signal.aborted) {
                          setSubmitting(false);
                          setShowFailureDialog(true);
                      }
                  })
                  .finally(() => {
                      setLoading(false);
                  });
    };

    const tenantNames: string[] = [];
    React.useEffect(() => {
        if (props.edit) {
            if (props.alertData.poolUuid !== null) {
                fetchPoolUuid(props.alertData.poolName as unknown as SelectedOptionValue);
            } else {
                fetchTenantsLicenses(props.alertData.tenantName);
            }
        } else {
            fetchTenantsLicenses(props.tenantName, undefined);
            fetchLicensePools(props.tenantName);
        }
    }, []);

    React.useEffect(() => {
        if (props.edit && originalLicenses.length > 0) {
            fetchLicenseAlertDetails(props.alertData.alertUuid);
        }
    }, [originalLicenses]);

    React.useEffect(() => {
        setLicenses(deduceSelectedLicenses());
    }, []);

    const fetchTenantName = (): string[] => {
        if (props.initialTenantData !== undefined) {
            if (!props.tenantsView) {
                props.initialTenantData?.map((each) => {
                    if (each.status && !tenantNames.includes(each.name)) {
                        tenantNames.push(each.name);
                    }
                });
                if (!tenantNames.includes(props.tenantName)) {
                    tenantNames.push(props.tenantName);
                }
            }
        } else {
            if (!tenantNames.includes(props.tenantName)) {
                tenantNames.push(props.tenantName);
            }
        }
        return tenantNames;
    };

    const poolNames: LicensePoolList[] = [];
    const fetchLicensePools = async (tenantName: string | SelectedOptionValue | SelectedOptionValue[] | undefined) => {
        const selectedTenantUuid = fetchTenantUuid(tenantName as string | SelectedOptionValue | SelectedOptionValue[]);
        abortControllers.push(abortController);
        const poolsPromise = licensePoolService.fetchLicensePoolsList(abortController, selectedTenantUuid);
        await Promise.all([poolsPromise]).then(([pools]) => {
            pools.pools.map((each) => poolNames.push(each));
        });
        setSelectedPoolNames(poolNames);
    };

    const fetchPoolUuid = async (poolName: SelectedOptionValue) => {
        setPoolLoader(true);
        setLoading(true);
        setHideLoader(true);
        const licenses: LicensePoolLicense[][] = [];
        let poolUuid = "";
        const tenantUuidFromProps = fetchTenantUuid(tenantName);
        if (!props.edit) {
            selectedPoolNames.map((item) => {
                if (item.poolName === (poolName as unknown as string)) {
                    poolUuid = item.poolUuid;
                    setPoolUuid(poolUuid);
                }
            });
        }
        const poolsPromise = licensePoolService.fetchPoolSpecificLicenses(
            abortController,
            tenantUuidFromProps,
            props.edit ? props.alertData.poolUuid : poolUuid
        );
        await Promise.all([poolsPromise]).then(([pools]) => {
            licenses.push(pools.allLicenses);
        });
        const tenantsLicenses: LicenseAlertData[] = [];
        const originalLicenses: LicenseData[] = [];
        licenses.map((license) => {
            license.map((each) => {
                if (props.edit) {
                    tenantsLicenses.push({
                        type: each.licenseId,
                        expirationDate: each.expirationDate,
                        remainingLicenses: each.amount,
                        amountThreshold: Number.NaN,
                        expirationThreshold: Number.NaN,
                        triggered: false,
                        amountInputVisibility: false,
                        daysInputVisibility: false,
                    });
                } else if (
                    Math.floor(new Date(each.expirationDate).getTime() / 1000) >=
                    Math.floor(new Date().getTime() / 1000)
                ) {
                    tenantsLicenses.push({
                        type: each.licenseId,
                        expirationDate: each.expirationDate,
                        remainingLicenses: each.amount,
                        amountThreshold: Number.NaN,
                        expirationThreshold: Number.NaN,
                        triggered: false,
                        amountInputVisibility: false,
                        daysInputVisibility: false,
                    });
                }
            });
            license.map((each) => {
                originalLicenses.push({
                    type: each.licenseId,
                    expirationDate: each.expirationDate,
                    product: each.product as string,
                    license: each.license as string,
                    available: each.amount,
                    total: 0,
                    licenseType: "",
                    isFeatureLicensePresent: false,
                });
            });
        });
        setLicenses(tenantsLicenses);
        foundOriginalLicenses(originalLicenses);
        setTenantUuid(tenantUuidFromProps);
        setPoolLoader(false);
        setLoading(false);
    };

    function fetchTenantUuid(tenantName: string | SelectedOptionValue | SelectedOptionValue[]) {
        let tenantUuid = "";
        if (props.initialTenantData !== undefined) {
            props.initialTenantData?.map((each) => {
                if (each.name === tenantName) {
                    tenantUuid = each.uuid;
                }
            });
            if (tenantUuid == "") {
                tenantUuid = userSessionService.currentUserDetails()?.tenantUuid as string;
            }
        } else {
            tenantUuid = props.tenantUuid;
        }
        return tenantUuid;
    }

    const fetchTenantsLicenses = (
        tenantName: string | SelectedOptionValue | SelectedOptionValue[],
        poolUuid?: string
    ) => {
        setLicenseLoader(true);
        setLoading(true);
        setHideLoader(true);
        const tenantUuid = fetchTenantUuid(tenantName);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        let licensesFetchPromise: Promise<LicenseResponse>;
        if (!userSessionService.userHasAllAuthorities([AUTH_LICENSE_VIEW])) {
            licensesFetchPromise = licenseService.fetchLicenses(abortController, undefined, true);
        } else {
            licensesFetchPromise = licenseService.fetchLicenses(
                abortController,
                undefined,
                false,
                tenantUuid == userSessionService.currentUserDetails()?.tenantUuid ? "" : tenantUuid,
                poolUuid
            );
        }
        licensesFetchPromise.then((data) => {
            const tenantsLicenses: LicenseAlertData[] = [];
            data.licenses.map((each) => {
                if (props.edit) {
                    tenantsLicenses.push({
                        type: each.type,
                        expirationDate: each.expirationDate,
                        remainingLicenses: each.overallRemaining ? each.overallRemaining : each.available,
                        amountThreshold: Number.NaN,
                        expirationThreshold: Number.NaN,
                        triggered: false,
                        amountInputVisibility: false,
                        daysInputVisibility: false,
                    });
                } else if (
                    Math.floor(new Date(each.expirationDate).getTime() / 1000) >=
                    Math.floor(new Date().getTime() / 1000)
                ) {
                    tenantsLicenses.push({
                        type: each.type,
                        expirationDate: each.expirationDate,
                        remainingLicenses: each.overallRemaining ? each.overallRemaining : each.available,
                        amountThreshold: Number.NaN,
                        expirationThreshold: Number.NaN,
                        triggered: false,
                        amountInputVisibility: false,
                        daysInputVisibility: false,
                    });
                }
            });
            setLicenses(tenantsLicenses);
            foundOriginalLicenses(data.licenses);
            setTenantUuid(tenantUuid);
            setLoading(false);
            setLicenseLoader(false);
        });
    };

    const removeRow = (checkedIndex: number) => {
        props.edit
            ? setLicensesEdit(licensesEdit.filter((_each, index) => index !== checkedIndex))
            : setLicenses(licenses.filter((_each, index) => index !== checkedIndex));
    };

    const handleRemovedLicenses = (
        eachLicense: LicenseAlertData,
        currentLicense: LicenseAlertData,
        oldLicenses: LicenseData[] | LicenseAlertData[]
    ): boolean => {
        return eachLicense.type !== currentLicense.type && oldLicenses.some((item) => item.type === eachLicense.type);
    };

    const handleOnChange = (license: LicenseAlertData, selected: LicenseAlertData) => {
        if (props.edit) {
            setLicensesEdit(
                licensesEdit.map((each) => {
                    if (licensesEdit.indexOf(license) === licensesEdit.indexOf(each)) {
                        each = selected;
                    }
                    return each;
                })
            );
        } else {
            setLicenses(
                licenses.map((each) => {
                    if (licenses.indexOf(license) === licenses.indexOf(each)) {
                        each = selected;
                    }
                    return each;
                })
            );
        }
    };

    const deduceInputValue = (license: LicenseAlertData, e: number, threshold: string) => {
        if (props.edit) {
            return licensesEdit.map((each) => {
                if (each.type === license.type) {
                    threshold === EXPIRATION_THRESHOLD
                        ? (each.expirationThreshold = Math.abs(e))
                        : (each.amountThreshold = Math.abs(e));
                }
                return each;
            });
        } else {
            return licenses.map((each) => {
                if (each.type === license.type) {
                    threshold === EXPIRATION_THRESHOLD
                        ? (each.expirationThreshold = Math.abs(e))
                        : (each.amountThreshold = Math.abs(e));
                }
                return each;
            });
        }
    };

    function fetchLicenseAlertDetails(alertUuid: string) {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseAlertsService.fetchLicenseAlertByUuid(alertUuid, abortController).then((data: LicenseAlertResult) => {
            constructLicensesForAlert(data.alert);
        });
    }

    function constructLicensesForAlert(selectedAlert: Alert) {
        const licenseAlertData: LicenseAlertData[] = [];
        selectedAlert.licenses.map((each) => {
            const foundLicense = originalLicenses.filter((license) => license.type === each.type)[0];
            licenseAlertData.push({
                amountThreshold: each.amountThreshold,
                expirationThreshold: each.expirationThreshold,
                triggered: each.triggered,
                type: foundLicense.type,
                expirationDate: foundLicense.expirationDate,
                remainingLicenses: foundLicense.overallRemaining
                    ? foundLicense.overallRemaining
                    : foundLicense.available,
                amountInputVisibility: !isNaN(each.amountThreshold),
                daysInputVisibility: !isNaN(each.expirationThreshold),
            });
        });
        setLicensesEdit(licenseAlertData);

        const licensesData: LicenseAlertData[] = [];
        licenses.map((each) => {
            const eachLicenseData = licenseAlertData.some((fd) => {
                return fd.type === each.type;
            });
            licensesData.push({
                amountThreshold: eachLicenseData
                    ? licenseAlertData.filter((fd) => {
                          return fd.type === each.type;
                      })[0].amountThreshold
                    : 0,
                expirationThreshold: eachLicenseData
                    ? licenseAlertData.filter((fd) => {
                          return fd.type === each.type;
                      })[0].expirationThreshold
                    : 0,
                triggered: eachLicenseData
                    ? licenseAlertData.filter((fd) => {
                          return fd.type === each.type;
                      })[0].triggered
                    : false,
                type: each.type,
                expirationDate: each.expirationDate,
                remainingLicenses: originalLicenses.filter((license) => license.type === each.type)[0].overallRemaining
                    ? originalLicenses.filter((license) => license.type === each.type)[0].overallRemaining
                    : originalLicenses.filter((license) => license.type === each.type)[0].available,
                amountInputVisibility: !isNaN(each.amountThreshold),
                daysInputVisibility: !isNaN(each.expirationThreshold),
            });
        });
        setLicenses(licensesData);
    }

    const isAddMoreButtonHidden = (): boolean => {
        if (props.edit) {
            return licensesEdit.length === originalLicenses.length || originalLicenses.length === 0;
        } else {
            return licenses.length === originalLicenses.length || originalLicenses.length === 0;
        }
    };

    const calculateDays = (expirationDate: string): number => {
        const date1 = new Date(formatDateWithoutTime(expirationDate));
        const date2 = new Date();
        const diffTime = date1.getTime() - date2.getTime();
        const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays < 1) {
            return 0;
        }
        return diffDays;
    };

    const showLicenseData = (licensesListToLoad: LicenseAlertData[]) =>
        licensesListToLoad.map((license) => {
            return [
                <select
                    className={classNames(form.select, style.fixedWidthInput, style.ellipsis, style.selectArrow)}
                    id={"licenseType" + license.type}
                    name={"licenseType" + license.type}
                    key={"licenseType" + license}
                    onChange={(e) => {
                        if (props.edit) {
                            const selected = licenses.find((item) => item.type === e.target.value);
                            if (selected != undefined) {
                                handleOnChange(license, selected);
                            }
                        } else {
                            const selected = originalLicenses.find((item) => item.type === e.target.value);
                            if (selected != undefined) {
                                handleOnChange(license, convertToLicenseAlertData(selected));
                            }
                        }
                    }}
                    value={license.type}
                    data-testid={
                        testIds.workArea.license.licenseAlerts.manageLicenseAlertView.table.licenseTypeSelect.itself
                    }
                >
                    {props.edit ? (
                        <option
                            hidden={handleRemovedLicenses(license, license, licensesListToLoad)}
                            defaultValue={"default"}
                        >
                            {t("LicensePools.view.licenses.table.defaultLicenses")}
                        </option>
                    ) : (
                        <option value={"default"}>{t("LicensePools.view.licenses.table.defaultLicenses")}</option>
                    )}
                    {props.edit
                        ? licenses.map((each) => (
                              <option
                                  key={each.type}
                                  value={each.type}
                                  hidden={handleRemovedLicenses(each, license, licensesListToLoad)}
                                  defaultValue={license.type}
                              >
                                  {ALL_PRODUCTS.get(each.type)}
                              </option>
                          ))
                        : originalLicenses.map((each) => (
                              <option
                                  key={each.type}
                                  value={each.type}
                                  hidden={handleRemovedLicenses(
                                      convertToLicenseAlertData(each),
                                      license,
                                      licensesListToLoad
                                  )}
                                  defaultValue={license.type}
                              >
                                  {each.license != "" ? each.product + " - " + each.license : each.product}
                              </option>
                          ))}
                </select>,
                openDaysInput(license),
                <div key={"expirationDate" + license} className={style.gridColumns}>
                    <div>
                        <div
                            key={"expirationDate" + license}
                            className={classNames(style.formFields, style.gridRows, {
                                [form.error]: !validateExpiryDate(license),
                            })}
                        >
                            {license.type != DEFAULT_LICENSE ? formatDateWithoutTime(license.expirationDate) : ""}
                        </div>

                        <div
                            className={classNames(form.error)}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.opportunityIdInput
                                    .errorLabel
                            }
                        >
                            <ErrorMessage name="expirationDate" />
                        </div>
                    </div>
                </div>,
                openAmountInput(license),
                <div
                    key={"remainingLicenses" + license}
                    className={classNames(style.gridColumns, {
                        [form.error]: license.remainingLicenses == 0 || license.overallRemaining == 0,
                    })}
                >
                    <div>
                        <div
                            key={"remainingLicenses" + license}
                            className={classNames(style.formFields, style.gridRows)}
                            data-testid={
                                testIds.workArea.license.licenseAlerts.manageLicenseAlertView.table.remainingLicenses
                            }
                        >
                            {license.type != DEFAULT_LICENSE
                                ? license.assigned == undefined || license.assigned == 0
                                    ? license.remainingLicenses
                                    : license.overallRemaining
                                : ""}
                        </div>
                        <div
                            className={classNames(form.error)}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.opportunityIdInput
                                    .errorLabel
                            }
                        >
                            <ErrorMessage name="remainingLicenses" />
                        </div>
                    </div>
                </div>,
                <div key={"triggered" + license} className={style.gridRows}>
                    <div
                        key={"triggered" + license}
                        className={classNames(style.margin, style.gridRows)}
                        data-testid={
                            testIds.workArea.license.licenseAlerts.manageLicenseAlertView.table.triggeredLicense
                        }
                    >
                        {license.triggered ? (
                            <StatusBadge
                                key={"triggered" + license}
                                values={[
                                    {
                                        status: Status.INFO,
                                        title: t("LicenseAlerts.reminderSent"),
                                    },
                                ]}
                                tooltip={true}
                            />
                        ) : null}
                    </div>
                </div>,
                <div key={"deleteIcon" + license} className={style.gridRows}>
                    {licenses.length > 1 ? (
                        <div
                            key={"deleteRow" + license}
                            className={classNames(style.margin, style.gridRows)}
                            onClick={() => {
                                usageStatisticsService.sendEvent({
                                    category: Category.LICENSE_ALERTS,
                                    action: Action.REMOVE_LICENSE,
                                });
                                if (props.edit) {
                                    removeRow(licensesEdit.indexOf(license));
                                } else {
                                    license.amountThreshold = 0;
                                    license.expirationThreshold = 0;
                                    removeRow(licenses.indexOf(license));
                                }
                            }}
                            data-testid={
                                testIds.workArea.license.licenseAlerts.manageLicenseAlertView.table.removeRowButton
                            }
                        >
                            <DeleteIcon
                                color={props.theme.contentBackgroundColor}
                                linecolor={props.theme.iconFillColor}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </div>,
            ];
        });

    const failureDialog = () => {
        return (
            <>
                <Modal isOpen={showFailureDialog} hideModal={() => setShowFailureDialog(false)}>
                    <div className={style.modalTitle}>
                        <Warning color={theme.errorBackgroundColor} />
                    </div>
                    <div className={style.modalTitle}>
                        <Heading tag="h2">
                            {props.edit ? t("LicenseAlerts.edit.failure.title") : t("LicenseAlerts.failure.title")}
                        </Heading>
                    </div>
                    <div className={style.resultContainer}>{t("LicensePools.supportMessage")}</div>
                    <div className={style.errorDialogButtons}>
                        <button
                            type={"button"}
                            className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                            onClick={() => {
                                props.hideDialogView ? props.hideDialogView(true) : undefined;
                                setShowFailureDialog(false);
                                setLoading(false);
                            }}
                            data-testid={testIds.common.dialog.retryButton}
                        >
                            {props.edit ? t("LicenseAlerts.edit.failure.button") : t("LicenseAlerts.failure.button")}
                        </button>
                        <button
                            className={classNames(
                                buttons.primaryButton,
                                buttons.secondaryButton,
                                buttons.medium,
                                form.submitButton
                            )}
                            data-testid={testIds.common.dialog.closeButton}
                            onClick={() => {
                                props.hideDialogView ? props.hideDialogView(false) : undefined;
                                setShowFailureDialog(false);
                                setLoading(false);
                            }}
                        >
                            {t("Common.cancel")}
                        </button>
                    </div>
                </Modal>
            </>
        );
    };

    function isCreateButtonDisabled(licensesToCheck: LicenseAlertData[]) {
        return (
            licensesToCheck.length < 1 ||
            licensesToCheck.some((item) => item.type === "default") ||
            licensesToCheck.some(
                (item) => item.type != "default" && isNaN(item.amountThreshold) && isNaN(item.expirationThreshold)
            ) ||
            licensesToCheck.some(
                (item) => item.type != "default" && item.amountThreshold == 0 && item.expirationThreshold == 0
            ) ||
            licensesToCheck.some(
                (item) => item.type != "default" && isNaN(item.expirationThreshold) && item.amountThreshold == 0
            ) ||
            licensesToCheck.some(
                (item) => item.type != "default" && isNaN(item.amountThreshold) && item.expirationThreshold == 0
            )
        );
    }

    useEffect(() => {
        if (selectSearchComponent.current != null) {
            let selectSearchElement: HTMLElement | null;
            let searchInput: HTMLInputElement | null;
            try {
                selectSearchElement = selectSearchComponent.current as unknown as HTMLElement;
                searchInput = selectSearchElement.querySelector("input");
            } catch (error) {
                return;
            }
            selectSearchElement.dataset.testid = testIds.common.searchSelectContainer.itself;

            if (searchInput != null) {
                searchInput.dataset.testid = testIds.common.searchSelectContainer.searchInput.itself;
            }
        }
    });

    return (
        <>
            <Formik
                validateOnBlur={true}
                initialValues={
                    props.edit
                        ? {
                              alertUuid: props.alertData.alertUuid,
                              alertName: props.alertData.alertName,
                              tenantName: props.alertData.tenantName,
                              tenantUuid: props.alertData.tenantUuid,
                              expirationDate: props.alertData.expirationDate,
                              availableLicenses: licensesEdit,
                              poolName: props.alertData.poolName,
                              poolUuid: props.alertData.poolUuid,
                          }
                        : {
                              alertUuid: "",
                              alertName: "",
                              tenantName: "",
                              availableLicenses: [deduceSelectedLicenses],
                              tenantUuid: "",
                              expirationDate: "",
                              poolName: "",
                          }
                }
                onSubmit={submitHandler}
                validationSchema={object().shape({
                    alertName: string()
                        .required(t("LicenseAlerts.validation.alertName"))
                        .min(
                            MIN_LICENSE_ALERT_NAME,
                            t("LicenseAlerts.validation.alertNameMinLength", {
                                length: MIN_LICENSE_ALERT_NAME,
                            })
                        )
                        .max(
                            MAX_LICENSE_ALERT_NAME,
                            t("LicenseAlerts.validation.alertNameMaxLength", {
                                length: MAX_LICENSE_ALERT_NAME,
                            })
                        ),
                })}
                validateOnChange={false}
            >
                {({ values, errors, handleChange }: FormikProps<LicenseAlertValues>) => {
                    const loader = loading ? (
                        <div className={style.loaderContainer}>
                            <LoadingIndicator small={true} />
                        </div>
                    ) : null;
                    return (
                        <Form className={style.form}>
                            {!showFailureDialog ? (
                                <>
                                    <div className={style.container}>
                                        <div className={style.scroller}>
                                            <div className={style.viewTitle}>
                                                <div className={style.backArrow}>
                                                    <button
                                                        onClick={(e) => {
                                                            props.hideDialogView(false);
                                                            props.setEditView(false);
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        <LeftArrow color={props.theme.activeForegroundColor} />
                                                    </button>
                                                </div>
                                                <div className={style.heading}>
                                                    <Heading tag={"h1"}>
                                                        {props.edit
                                                            ? t("LicenseAlerts.edit.title")
                                                            : t("LicenseAlerts.create")}
                                                    </Heading>
                                                </div>
                                            </div>
                                            <div className={form.formFields}>
                                                <label htmlFor="alertName" className={form.label}>
                                                    {t("LicenseAlerts.name")}
                                                </label>
                                                <input
                                                    id="alertName"
                                                    className={classNames(form.input, form.fixedWidthInput, {
                                                        [form.inputError]: errors.alertName,
                                                    })}
                                                    onChange={handleChange}
                                                    value={values.alertName}
                                                    data-testid={
                                                        testIds.workArea.license.licenseAlerts.manageLicenseAlertView
                                                            .alertNameInput.itself
                                                    }
                                                />
                                                {hideLoader ? null : loader}
                                                <div className={classNames(form.error, style.errorMessage)}>
                                                    <ErrorMessage name="alertName" />
                                                </div>
                                            </div>
                                            {
                                                <div className={form.formFields}>
                                                    <label htmlFor="searchTenant" className={form.label}>
                                                        {t("LicenseAlerts.assign")}
                                                    </label>
                                                    <SelectSearch
                                                        ref={selectSearchComponent}
                                                        id="tenantName"
                                                        options={fetchTenantName().map((name) => ({
                                                            value: name,
                                                            name: name,
                                                        }))}
                                                        filterOptions={createFilterOptions}
                                                        onChange={(event: SelectedOptionValue) => {
                                                            fetchTenantsLicenses(event);
                                                            fetchLicensePools(event as unknown as string);
                                                            setTenantName(event as unknown as string);
                                                            handleChange(event as unknown as string);
                                                        }}
                                                        search={true}
                                                        disabled={
                                                            props.edit ||
                                                            props.tenantsView ||
                                                            !(
                                                                userSessionService.userHasAllAuthorities([
                                                                    AUTH_TENANT_VIEW,
                                                                ]) &&
                                                                userSessionService.hasFeatureLicense(
                                                                    "FEATURE_TENANT_MANAGEMENT"
                                                                )
                                                            )
                                                        }
                                                        placeholder={t("LicenseAlerts.search")}
                                                        className={classNames(form.input, style.select)}
                                                        value={props.edit ? values.tenantName : tenantName}
                                                        closeOnSelect={true}
                                                        data-testid={
                                                            testIds.common.searchSelectContainer.searchInput.itself
                                                        }
                                                    />
                                                    {licenseLoader ? loader : undefined}
                                                </div>
                                            }
                                            <div className={form.formFields}>
                                                <label htmlFor="searchPool" className={form.label}>
                                                    {t("LicenseAlerts.assignPool")}
                                                </label>
                                                <SelectSearch
                                                    ref={selectSearchComponent}
                                                    id="poolName"
                                                    options={selectedPoolNames.map((name) => ({
                                                        value: name.poolName,
                                                        name: name.poolName,
                                                    }))}
                                                    filterOptions={createFilterOptions}
                                                    search={true}
                                                    disabled={props.edit}
                                                    placeholder={
                                                        props.edit ? values.poolName : t("LicenseAlerts.searchPool")
                                                    }
                                                    onChange={(event: SelectedOptionValue) => {
                                                        fetchPoolUuid(event);
                                                        setPoolName(event as unknown as string);
                                                        handleChange(event as unknown as string);
                                                    }}
                                                    className={classNames(form.input, style.select)}
                                                    value={props.edit ? values.poolName : poolName}
                                                    closeOnSelect={true}
                                                    data-testid={
                                                        testIds.common.searchSelectContainer.searchInput.itself
                                                    }
                                                />
                                                {poolLoader ? loader : null}
                                            </div>
                                            <div className={classNames(form.formFields)}>
                                                <label htmlFor="licenses" className={form.label}>
                                                    {t("LicenseAlerts.licenses")}
                                                </label>
                                                <div className={classNames(style.licensesTable, style.staticTable)}>
                                                    <div>
                                                        <StaticTable
                                                            headers={[
                                                                {
                                                                    value: t("LicenseAlerts.licenseType"),
                                                                },
                                                                {
                                                                    value: t("LicenseAlerts.notifyDays"),
                                                                },
                                                                {
                                                                    value: t("LicenseAlerts.expiration"),
                                                                },
                                                                {
                                                                    value: t("LicenseAlerts.notifyAmount"),
                                                                },
                                                                {
                                                                    value: t("LicenseAlerts.remainingLicenses"),
                                                                },
                                                                {
                                                                    value: "",
                                                                },
                                                                {
                                                                    value: "",
                                                                },
                                                                {
                                                                    value: "",
                                                                },
                                                            ]}
                                                            cells={
                                                                props.edit
                                                                    ? showLicenseData(licensesEdit)
                                                                    : showLicenseData(licenses)
                                                            }
                                                        />
                                                        <span className={classNames(style.addMoreLicenses)}>
                                                            <div>
                                                                <button
                                                                    onClick={addRow}
                                                                    className={classNames(
                                                                        style.linkAddMoreButton,
                                                                        buttons.textButton,
                                                                        style.linkColor
                                                                    )}
                                                                    type="button"
                                                                    hidden={isAddMoreButtonHidden()}
                                                                    data-testid={
                                                                        testIds.workArea.license.licenseAlerts
                                                                            .manageLicenseAlertView.table
                                                                            .addLicenseButton
                                                                    }
                                                                >
                                                                    {addMoreLicenses(
                                                                        props.theme.contentBackgroundColor,
                                                                        props.theme.iconFillColor,
                                                                        t("Common.addLicenseAlert")
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.buttonContainer}>
                                        <button
                                            className={
                                                isCreateButtonDisabled(props.edit ? licensesEdit : licenses)
                                                    ? classNames(buttons.disabledButton, form.submitButton)
                                                    : classNames(
                                                          buttons.primaryButton,
                                                          buttons.medium,
                                                          style.okButton,
                                                          style.buttons
                                                      )
                                            }
                                            disabled={isCreateButtonDisabled(props.edit ? licensesEdit : licenses)}
                                            type="submit"
                                            data-testid={testIds.common.confirmationDialog.confirmButton}
                                        >
                                            {props.edit ? t("Common.edit") : t("Common.create")}
                                        </button>
                                        <button
                                            className={classNames(
                                                buttons.secondaryButton,
                                                buttons.medium,
                                                style.okButton,
                                                style.buttons,
                                                style.cancelButton
                                            )}
                                            type="reset"
                                            data-testid={testIds.common.dialog.closeButton}
                                            onClick={(e) => {
                                                props.setEditView(false);
                                                props.hideDialogView(false);
                                                e.preventDefault();
                                            }}
                                        >
                                            {t("Common.cancel")}
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>{failureDialog()}</>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default connector(ManageLicenseAlertView);
