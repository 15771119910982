import * as React from "react";

const Download = (props: { color: string }): JSX.Element => {
    return (
        <svg width="35.818" height="47.32" viewBox="0 0 48.32 48.32">
            <defs>
                <filter id="Path_1173" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" in="SourceAlpha" />
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="icon_download" transform="translate(4.5 2.5)">
                <g id="Group_4942" data-name="Group 4942" transform="translate(-717.501 -212.5)">
                    <g transform="matrix(1, 0, 0, 1, 713, 210)" filter="url(#Path_1173)">
                        <path
                            id="Path_1173-2"
                            data-name="Path 1173"
                            d="M171.86,212.13a19,19,0,1,1-19,19,19,19,0,0,1,19-19Z"
                            transform="translate(-148.36 -209.63)"
                            fill={props.color}
                        />
                    </g>
                </g>
                <g id="exportCloud" transform="translate(9 11)">
                    <path
                        id="Path_1880"
                        data-name="Path 1880"
                        d="M8,17l4,4,4-4"
                        transform="translate(-1.998 -4.635)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <line
                        id="Line_750"
                        data-name="Line 750"
                        y2="9"
                        transform="translate(10.002 7.365)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_1881"
                        data-name="Path 1881"
                        d="M19.061,16.443a4.408,4.408,0,0,0,1.717-4.989,4.534,4.534,0,0,0-4.334-3.109H15.3A7.225,7.225,0,0,0,9.091,3.035,7.3,7.3,0,0,0,1.814,6.821a7.019,7.019,0,0,0,1,8.018"
                        transform="translate(-0.991 -2.988)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Download;
