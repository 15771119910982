let debugEnabled = (() => {
    const stanUrl: string = process.env.STAN_URL ?? "";
    return stanUrl.includes("dev.blancco") || stanUrl.includes("qa.blancco");
})();

/**
 * Wrapper for some console methods in order to customize logging.
 */
export class Logger {
    private readonly id: string;

    constructor(id: string) {
        this.id = id;
    }

    // Here and in methods to follow use of any is justified because we're
    // duplicating console method signatures and they use any.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public debug(message?: any, ...optionalParams: any[]): void {
        if (!debugEnabled) {
            return;
        }
        // eslint-disable-next-line no-console
        console.debug(this.createTimestamp(), this.id, message, ...optionalParams);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public trace(message?: any, ...optionalParams: any[]): void {
        if (!debugEnabled) {
            return;
        }
        // eslint-disable-next-line no-console
        console.trace(this.createTimestamp(), this.id, message, ...optionalParams);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public error(message?: any, ...optionalParams: any[]): void {
        console.error(this.createTimestamp(), this.id, message, ...optionalParams);
    }

    private createTimestamp(): string {
        return new Date().toISOString();
    }

    public isDebugEnabled(): boolean {
        return debugEnabled;
    }

    public setDebugEnabled(enabled: boolean): void {
        debugEnabled = enabled;
    }
}

declare global {
    interface Window {
        bmp: Record<string, () => void>;
    }
}

// For now window is always defined both when running in browser and when
// running unit tests with Jest because we use jsdom. This will fail if
// we stop using jsdom.
window.bmp = window.bmp || {
    enableDebugLogging: () => logger.setDebugEnabled(true),
    disableDebugLogging: () => logger.setDebugEnabled(false),
};

/**
 * Centralized logger so we can filter and possibly send logs to the backend.
 */
const logger = new Logger("root");
export { logger };
