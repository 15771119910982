import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./report-template-form.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Heading from "components/typography/heading/Heading";
import { ReportTemplate } from "domain/reports";
import { apiGatewayService } from "services/api/ApiGatewayService";
import { getLanguage } from "services/language/languageRepository";
import { getOliverUrl } from "services/login/endpointRepository";
import { toReportTemplateDto } from "services/report/erasure/ReportTemplateService";

const ReportTemplatePreviewModal = (props: {
    previewCounter: number;
    templateDetails: ReportTemplate;
}): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const iframe = React.useRef(null);
    const [failed, setFailed] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const previewTemplate = () => {
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const url = getOliverUrl() + "/api/report-templates/preview?language=" + getLanguage().code;
        apiGatewayService
            .fetch(url, {
                credentials: "include",
                method: "POST",
                mode: "cors",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(toReportTemplateDto(props.templateDetails)),
            })
            .then((response) => response.text())
            .then((body: string) => {
                const current = iframe.current;
                if (current == null) {
                    return;
                }
                const iframeDocument = (current as HTMLIFrameElement).contentDocument;
                if (iframeDocument == null) {
                    return;
                }
                iframeDocument.write(body);
                iframeDocument.close();
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setFailed(true);
                }
            })
            .finally(() => setLoading(false));
    };
    React.useEffect(() => {
        previewTemplate();
    }, [props.previewCounter]);
    React.useEffect(() => {
        return () => {
            abortControllers.forEach((controller) => controller.abort());
        };
    }, []);
    return (
        <>
            <Heading tag="div" variant="SUBTITLE_1" disableTopSpacing={true}>
                {t("ReportTemplate.previewForm.title")}
            </Heading>
            <div hidden={!loading} className={style.loadContainer}>
                {loading ? <LoadingIndicator /> : null}
            </div>
            <div hidden={loading}>
                {failed ? (
                    t("ReportTemplate.previewForm.failureMessage")
                ) : (
                    <iframe ref={iframe} src={"about:blank"} className={style.reportContainer} />
                )}
            </div>
        </>
    );
};

export default ReportTemplatePreviewModal;
