import { ChartOptions } from "chart.js";
import * as React from "react";
import { Line } from "react-chartjs-2";
import { TFunction, Translation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./chart.scss";
import { commonFunctions, Units } from "./CommonFunctions";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { DashboardDataList, DeviceType } from "domain/dashboard";
import { StoreState } from "store";

interface ChartProps {
    displayLegend?: boolean;
    widgetData: DashboardDataList[];
    dataFetched: boolean;
    convertToTonnes: boolean;
}

interface ChartState {
    data: [];
    displayLegend?: boolean;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

type Props = ChartProps & ConnectedProps<typeof connector>;

class EmissionsPreventedChart extends React.Component<Props, ChartState> {
    public static defaultProps = {
        displayLegend: false,
        convertToTonnes: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            data: [],
            displayLegend: this.props.displayLegend,
        };
    }

    computeAverageCo2ProcessPerDay(): number {
        return parseInt(String(commonFunctions.computeTotalOfCo2EmissionsPrevented(this.props.widgetData) / 365));
    }

    fetchCo2Emissions = (deviceType: DeviceType) => {
        const co2Emissions: number[] = [];
        Object.values(this.props.widgetData).forEach((deviceData) => {
            Object.entries(deviceData.content).forEach(([key, value]) => {
                if (deviceType === key) {
                    co2Emissions.push(this.props.convertToTonnes ? value.co2 / 1000 : value.co2);
                }
            });
        });
        return co2Emissions;
    };

    public render(): JSX.Element {
        if (!this.props.dataFetched) {
            return (
                <div className={style.loaderContainer}>
                    <LoadingIndicator />
                </div>
            );
        }
        const displayedValuesAndUnits = commonFunctions.getDisplayedValueAndUnit(
            commonFunctions.computeTotalOfCo2EmissionsPrevented(this.props.widgetData),
            this.computeAverageCo2ProcessPerDay()
        );

        return (
            <Translation>
                {(t) => (
                    <div className={style.chartContainer}>
                        <div className={style.topSummaryContainer}>
                            <TextBlock>
                                <span className={style.summaryCount}>
                                    {t("EsgDashboardView.co2EmissionsPreventedChart.totalOfCo2EmissionsPrevented", {
                                        totalEmissionsPrevented: displayedValuesAndUnits.total.toFixed(),
                                        unit: t(displayedValuesAndUnits.totalUnit),
                                    })}
                                </span>
                                <br />
                                {t("EsgDashboardView.referencedMonths", {
                                    firstMonth: commonFunctions.computeFirstMonth(this.props.widgetData),
                                    lastMonth: commonFunctions.computeLastMonth(this.props.widgetData),
                                })}
                                <br />
                                {t("EsgDashboardView.averagePerDay", {
                                    averagePerDay: displayedValuesAndUnits.average?.toFixed(2),
                                    unit: displayedValuesAndUnits.averageUnit
                                        ? t(displayedValuesAndUnits.averageUnit)
                                        : "",
                                    numberOfMonths: commonFunctions.fetchMonths(this.props.widgetData).length,
                                })}
                            </TextBlock>
                        </div>
                        <Line
                            data={commonFunctions.getChartData(t, this.fetchCo2Emissions, this.props.widgetData)}
                            options={getChartOptions(
                                this.props.theme.contentBorderColor,
                                this.props.theme.textColor,
                                this.props.convertToTonnes,
                                displayedValuesAndUnits.totalUnit,
                                t
                            )}
                        />
                    </div>
                )}
            </Translation>
        );
    }
}

function getChartOptions(
    gridLineColor: string,
    textColor: string,
    convert: boolean,
    unit: Units,
    t: TFunction
): ChartOptions {
    return {
        legend: {
            display: true,
            position: "bottom",
            labels: {
                usePointStyle: true,
                fontColor: textColor,
            },
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontColor: textColor,
                    },
                },
            ],
            yAxes: [
                {
                    display: true,
                    gridLines: {
                        color: gridLineColor,
                        zeroLineColor: gridLineColor,
                    },
                    ticks: {
                        beginAtZero: true,
                        callback(n: number) {
                            return commonFunctions.toYAxisValueWithUnit(n, convert, unit, t(unit));
                        },
                        maxTicksLimit: 5,
                        fontColor: textColor,
                    },
                    stacked: true,
                },
            ],
        },
        elements: {
            line: {
                tension: 0,
            },
            point: {
                radius: 1,
            },
        },
        plugins: {
            labels: {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                render: () => {},
            },
        },
        tooltips: {
            enabled: true,
        },
        responsive: true,
        maintainAspectRatio: false,
    };
}

export default connector(EmissionsPreventedChart);
