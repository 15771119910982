import React from "react";

const Sparkle = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.003" height="15" viewBox="0 0 15.003 15">
        <g id="noun-diamond-1852308" transform="translate(-106.64 -32.41)">
            <path
                id="Path_5335"
                data-name="Path 5335"
                d="M121.643,39.91q-6.126-2.274-7.5-7.5-1.375,5.225-7.5,7.5,6.126,2.274,7.5,7.5Q115.517,42.184,121.643,39.91Z"
                transform="translate(0)"
                fill="#c4874a"
                fillRule="evenodd"
            />
        </g>
    </svg>
);

export default Sparkle;
