import classNames from "classnames";
import * as React from "react";
import { ReactElement, useEffect, useState } from "react";
import { Button, closeMenu, openMenu, Wrapper } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./navigation-toggle-panel.scss";
import IconButton from "components/icon-button/IconButton";
import AddNewIcon from "components/icons/AddNewIcon";
import Chevron from "components/icons/Chevron";
import Import from "components/icons/Import";
import { subpageContext } from "components/layout/ApplicationLayout";
import ImportReportViews from "components/reports/ImportReportView";
import LoadReportPathsModal from "components/reports/LoadReportPathsModal";
import Tooltip from "components/tooltip/Tooltip";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";

import testIds from "testIds.json";

interface NavigationToggleableProps {
    title: string;
    isOpen: boolean;
    id: string;
    openId?: string;
    closeId?: string;
    children: ReactElement;
    className?: string;
}

const connector = connect((state: StoreState) => ({
    user: state.userReducer.user,
    theme: state.themeReducer.theme,
}));

const NavigationTogglePanel = (props: NavigationToggleableProps & ConnectedProps<typeof connector>): JSX.Element => {
    const [menuExpanded, toggleMenu] = useState<boolean>(props.isOpen);
    const [showImportModal, setShowImportModal] = useState<boolean>(false);
    const openClass: string = menuExpanded ? style.activeLeftTitle : style.leftTitle;
    const iconColor: string = menuExpanded ? props.theme.primaryButtonForegroundColor : props.theme.linkTextColor;
    const chevronClass: string = classNames(style.arrow, { [style.down]: menuExpanded });
    const { t } = useTranslation();

    const allowedToCreateCustomViews = userSessionService.permittedToCreateCustomViews(props.user?.featureLicenses);
    const context = React.useContext(subpageContext);

    useEffect(() => {
        if (props.openId !== undefined) {
            openMenu(props.openId as string);
        }
        if (props.closeId !== undefined) {
            closeMenu(props.closeId as string);
        }
    }, []);

    return (
        <>
            <Wrapper
                id={props.id}
                className={classNames(openClass, props.className)}
                onMenuToggle={(state) => {
                    toggleMenu(state.isOpen);
                }}
                closeOnSelection={false}
                closeOnBlur={false}
            >
                {allowedToCreateCustomViews && props.title === t("Routes.ourViews") ? (
                    <div className={classNames(style.button, style.buttonBackground)}>
                        <div className={style.wrapIcon}>
                            <span>{props.title}</span>
                            <Tooltip content={t("Common.createNewView")} placement={"top"}>
                                <IconButton
                                    type={"button"}
                                    onClick={() => {
                                        usageStatisticsService.sendEvent({
                                            category: Category.REPORT_VIEW,
                                            action: Action.ADD_REPORT_VIEW,
                                        });
                                        context?.setSubpage(
                                            <LoadReportPathsModal setVisible={() => true} visible={true} />
                                        );
                                    }}
                                    testId={testIds.workArea.report.sideMenu.createReportViewButton}
                                >
                                    <AddNewIcon color={iconColor} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip content={t("ImportReportView.tooltip")} placement={"top"}>
                                <IconButton
                                    onClick={(event) => {
                                        usageStatisticsService.sendEvent({
                                            category: Category.REPORT_VIEW,
                                            action: Action.IMPORT_REPORT_VIEW,
                                        });
                                        setShowImportModal(true);
                                        // The focus stays in the button, which results to an open tooltip when the view is rendered.
                                        // Removing focus manually solves this.
                                        event.currentTarget.blur();
                                    }}
                                    testId={testIds.workArea.report.manageReportViewDialog.importReportViewButton}
                                >
                                    <Import color={iconColor} />
                                </IconButton>
                            </Tooltip>
                            <ImportReportViews modalVisible={showImportModal} setModalVisible={setShowImportModal} />
                        </div>
                        {props.children.props.children.props.children.length !== 0 && (
                            <Button data-testid={testIds.navigation.togglePanel.button}>
                                <Chevron color={iconColor} className={chevronClass} />
                            </Button>
                        )}
                    </div>
                ) : (
                    <Button className={classNames(style.button, style.buttonBackground)}>
                        <span>{props.title}</span>
                        <Chevron color={iconColor} className={chevronClass} />
                    </Button>
                )}
                {props.children}
            </Wrapper>
        </>
    );
};

export default connector(NavigationTogglePanel);
