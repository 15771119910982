import { ProductManualLanguageDetails } from "domain/language";

const LANGUAGES: ProductManualLanguageDetails[] = [
    { code: "en_US", title: "English" },
    { code: "fr_FR", title: "Français" },
    { code: "de_DE", title: "Deutsch" },
    { code: "ja_JP", title: "日本語" },
    { code: "es_ES", title: "Español" },
    { code: "pt_BR", title: "Português" },
    { code: "zh_CN", title: "中国人" },
    { code: "zh_TW", title: "中國人" },
    { code: "zh_CH", title: "中國人" },
    { code: "ru_RU", title: "русский" },
    { code: "it_IT", title: "Italiano" },
    { code: "sk_SK", title: "slovenský" },
    { code: "ko_KR", title: "한국인" },
];

export function getProductManualLanguage(code: string): ProductManualLanguageDetails {
    const DEFAULT_LANGUAGE = { code: code, title: code };
    return LANGUAGES.find((language) => language.code === code) || DEFAULT_LANGUAGE;
}
