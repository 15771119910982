import * as React from "react";
import { useTranslation } from "react-i18next";

import AddEntitlementView from "./add-entitlement/AddEntitlementView";
import EntitlementTable from "./EntitlementTable";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { LICENSES_ROUTE } from "components/router/Routes";
import { AUTH_EMS_CREATE_ENTITLEMENT } from "domain/authority";
import { Container, ContainerType, EmsConfiguration, LicenseData } from "domain/licenses";
import { userSessionService } from "services/user/UserSessionService";

export default function EntitlementView(): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = React.useState({ count: 0 });
    const [emsConfiguration, setEmsConfiguration] = React.useState<EmsConfiguration>({
        emsId: "",
        hlEntitlements: false,
        slEntitlements: false,
        availableSlActivations: 0,
        usedSlActivations: 0,
        lastSynced: "",
    });

    const [availableLicenses, setAvailableLicenses] = React.useState<LicenseData[]>([]);
    const [containers, setContainers] = React.useState<Container[]>([]);
    function updateState() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    return (
        <ApplicationLayout
            viewTitle={t("Entitlements.title")}
            view={
                <EntitlementTable
                    count={state.count}
                    onUpdate={updateState}
                    onFetchEmsConfiguration={setEmsConfiguration}
                    onFetchAvailableLicenses={setAvailableLicenses}
                    onFetchContainers={setContainers}
                    containers={containers}
                />
            }
            mainRoute={LICENSES_ROUTE}
            viewActions={
                userSessionService.userHasAllAuthorities([AUTH_EMS_CREATE_ENTITLEMENT]) &&
                (emsConfiguration.hlEntitlements || emsConfiguration.slEntitlements) &&
                availableLicenses.length > 0 ? (
                    <AddEntitlementView
                        emsConfiguration={emsConfiguration}
                        availableLicenses={availableLicenses}
                        slContainers={
                            emsConfiguration.slEntitlements
                                ? containers.filter((container) =>
                                      [
                                          ContainerType.SL_ADMIN_MODE,
                                          ContainerType.SL_CREATE,
                                          ContainerType.SL_UPDATE,
                                      ].includes(container.type)
                                  )
                                : []
                        }
                        onUpdate={updateState}
                    />
                ) : (
                    <></>
                )
            }
        />
    );
}
