import * as React from "react";
import { useTranslation } from "react-i18next";

import OverviewDashboard from "./OverviewDashboard";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { DASHBOARD_ROUTE } from "components/router/Routes";

export default function CustomDashboard(): JSX.Element {
    const { t } = useTranslation();
    return (
        <ApplicationLayout
            viewTitle={t("Overview.title")}
            view={<OverviewDashboard />}
            viewContainer={false}
            mainRoute={DASHBOARD_ROUTE}
        />
    );
}
