import classNames from "classnames";
import * as React from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { connect, ConnectedProps } from "react-redux";

import style from "./flexible-modal.scss";
import modalStyle from "./modal.scss";
import Exit from "components/icons/Exit";
import LeftArrow from "components/icons/LeftArrow";
import ToggleFullscreen from "components/icons/ToggleFullscreen";
import Heading from "components/typography/heading/Heading";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    isOpen: boolean;
    hideModal: () => void;
    onToggleFullscreen: () => void;
    title: string;
    children: React.ReactNode;
    dynamicSize: boolean;
    fullscreenIcon?: boolean;
    fullScreen?: boolean;
    showHeader?: boolean;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const FlexibleModal = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const [fullscreen, setFullscreen] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const [output, setOutput] = React.useState("");

    const toggleFullscreen = () => {
        setFullscreen(!fullscreen);
        props.onToggleFullscreen();
    };

    React.useEffect(() => {
        setFullscreen(props.fullScreen == true);
    }, [props.fullScreen]);

    const hotkeys = props.showHeader ? (
        <GlobalHotKeys
            keyMap={{ SHIFT: "shift", SHIFT_F: "shift+f" }}
            handlers={{
                SHIFT: () => {
                    setOutput(t("ManageWorkflowDialog.hotkey"));
                },
                SHIFT_F: toggleFullscreen,
            }}
            allowChanges={true}
        />
    ) : (
        ""
    );

    const hideModal = () => {
        props.hideModal();
        setOutput("");
    };

    return (
        <>
            <ReactModal
                ariaHideApp={false}
                isOpen={props.isOpen}
                onRequestClose={props.hideModal}
                className={{
                    base: classNames(
                        fullscreen && !props.dynamicSize
                            ? style.baseFullscreen
                            : props.dynamicSize || props.fullscreenIcon
                            ? style.baseInitial
                            : style.baseWindowed,
                        fullscreen && !props.dynamicSize && "flexibleModalFullscreenOpen",
                        props.fullScreen && "test",
                        props.fullScreen && style.baseFixedFullscreen
                    ),
                    afterOpen: style.afterOpen,
                    beforeClose: style.beforeClose,
                }}
                overlayClassName={{
                    base: modalStyle.overlay,
                    afterOpen: "",
                    beforeClose: modalStyle.overlayBeforeClose,
                }}
                shouldCloseOnOverlayClick={false}
                closeTimeoutMS={parseInt(style.animationDuration, 10)}
            >
                {props.showHeader ? (
                    <div className={style.rowFlexContainer}>
                        <div className={style.title}>
                            <Heading tag="h2">{props.title ? props.title : ""}</Heading>
                        </div>
                        <div className={style.iconContainer}>
                            {!props.dynamicSize && (
                                <div
                                    className={style.fullScreenContainer}
                                    data-testid={testIds.common.dialog.toggleFullscreenButton}
                                >
                                    <button onClick={toggleFullscreen} className={style.button}>
                                        <ToggleFullscreen color={props.theme.iconFillColor} fullscreen={fullscreen} />
                                    </button>

                                    <span className={style.hotkey}>{output}</span>
                                </div>
                            )}

                            <button
                                role="close"
                                onClick={hideModal}
                                className={style.button}
                                data-testid={testIds.common.dialog.xButton}
                            >
                                <Exit color={props.theme.iconFillColor} />
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className={style.backButton}>
                        <button
                            role="close"
                            onClick={hideModal}
                            className={style.button}
                            data-testid={testIds.common.dialog.xButton}
                        >
                            <LeftArrow color={props.theme.activeForegroundColor} />
                        </button>
                    </div>
                )}

                <div className={classNames(style.columnFlexContainer, style.centeredContent)}>{props.children}</div>
                {hotkeys}
            </ReactModal>
        </>
    );
};

FlexibleModal.defaultProps = {
    showHeader: true,
};

export default connector(FlexibleModal);
