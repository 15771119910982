import * as React from "react";
import { useTranslation } from "react-i18next";

import ContainersTable from "./ContainersTable";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { LICENSES_ROUTE } from "components/router/Routes";

function ContainersView(): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = React.useState({ count: 0 });

    function updateState() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    return (
        <ApplicationLayout
            viewTitle={t("Containers.title")}
            view={<ContainersTable onUpdate={updateState} count={state.count} />}
            mainRoute={LICENSES_ROUTE}
        />
    );
}

export default ContainersView;
