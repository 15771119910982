import * as React from "react";

export const MoveIcon = (props: { linecolor: string }): JSX.Element => {
    return (
        <svg id="move_icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="Group_3547" data-name="Group 3547" style={{ isolation: "isolate" }}>
                <g id="trash-2">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 1.75C2.30964 1.75 1.75 2.30964 1.75 3V4C1.75 4.41421 1.41421 4.75 1 4.75C0.585786 4.75 0.25 4.41421 0.25 4V3C0.25 1.48122 1.48122 0.25 3 0.25H15C16.5188 0.25 17.75 1.48122 17.75 3V15C17.75 16.5188 16.5188 17.75 15 17.75H3C1.48122 17.75 0.25 16.5188 0.25 15V13C0.25 12.5858 0.585786 12.25 1 12.25C1.41421 12.25 1.75 12.5858 1.75 13V15C1.75 15.6904 2.30964 16.25 3 16.25H15C15.6904 16.25 16.25 15.6904 16.25 15V3C16.25 2.30964 15.6904 1.75 15 1.75H3ZM7.46967 5.46967C7.76256 5.17678 8.23743 5.17678 8.53033 5.46967L11.5303 8.46967C11.8232 8.76256 11.8232 9.23744 11.5303 9.53033L8.53033 12.5303C8.23743 12.8232 7.76256 12.8232 7.46967 12.5303C7.17677 12.2374 7.17677 11.7626 7.46967 11.4697L9.18934 9.75L1 9.75C0.585787 9.75 0.25 9.41422 0.25 9C0.25 8.58579 0.585786 8.25 1 8.25L9.18934 8.25L7.46967 6.53033C7.17677 6.23744 7.17677 5.76256 7.46967 5.46967Z"
                        fill={props.linecolor}
                    />
                </g>
            </g>
        </svg>
    );
};
