import * as React from "react";

interface Props {
    color: string;
    width?: number;
    height?: number;
    strokeColor?: string;
}

const Warning = (props: Props): JSX.Element => {
    return (
        <svg
            id="Warning_Icon_medium"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : "45"}
            height={props.height ? props.height : "45"}
            viewBox="0 0 45 45"
        >
            <g id="alert-triangle_2_" data-name="alert-triangle (2)" transform="translate(0 3)">
                <path
                    id="Path_2248"
                    data-name="Path 2248"
                    d="M20.369,4.971,2.129,35.434A4.309,4.309,0,0,0,5.812,41.9H42.292a4.309,4.309,0,0,0,3.683-6.463L27.735,4.971a4.306,4.306,0,0,0-7.365,0Z"
                    transform="translate(-1.552 -2.897)"
                    fill={props.color}
                />
            </g>
            <g id="Group_3471" data-name="Group 3471" transform="translate(20.461 13.702)">
                <line
                    id="Line_809"
                    data-name="Line 809"
                    y2="15.289"
                    transform="translate(2.039 0)"
                    fill="none"
                    stroke={props.strokeColor != undefined ? props.strokeColor : "#4C4C4C"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                />
                <path
                    id="Path_2250"
                    data-name="Path 2250"
                    d="M.01,0"
                    transform="translate(2.039 22.424)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2.5"
                />
                <circle
                    id="Ellipse_289"
                    data-name="Ellipse 289"
                    cx="2"
                    cy="2"
                    r="2"
                    transform="translate(0.039 20.298)"
                    fill={props.strokeColor != undefined ? props.strokeColor : "#4C4C4C"}
                />
            </g>
            <rect id="Rectangle_2415" data-name="Rectangle 2415" width="45" height="45" fill="none" />
        </svg>
    );
};

export default Warning;
