import { TFunction } from "i18next";
import * as React from "react";

import style from "./product-name-column.scss";
import TextWithTooltip from "components/table/TextWithTooltip";
import { EditorGeneration, Profile, profileToNameMap } from "services/workflows/WorkflowService";

const DOT = " • ";
const ProductNameColumn = (props: {
    profile: string;
    version: string;
    generation: EditorGeneration;
    t: TFunction;
}): JSX.Element => {
    const profile = props.profile as Profile;

    const createAdditionalInfoPart = () => {
        if (profile !== Profile.BMDE_DIAGNOSTIC) {
            return;
        }
        const content = profile === Profile.BMDE_DIAGNOSTIC && props.t("WorkflowsTable.diagnosticOnly");
        return DOT + content;
    };
    return (
        <div className={style.contentContainer}>
            <TextWithTooltip
                text={profileToNameMap.get(
                    props.profile === Profile.BMDE_DIAGNOSTIC ? Profile.BMDE : (props.profile as Profile)
                )}
            />
            {props.version && (
                <div className={style.versionContainer}>
                    <span>
                        {props.version} {createAdditionalInfoPart()}{" "}
                    </span>
                </div>
            )}
        </div>
    );
};

export default ProductNameColumn;
