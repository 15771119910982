import classNames from "classnames";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import ErrorMessage from "components/error-message/ErrorMessage";
import IconButton from "components/icon-button/IconButton";
import LeftArrow from "components/icons/LeftArrow";
import { subpageContext } from "components/layout/ApplicationLayout";
import style from "components/layout/subpage-layout/subpage-layout.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Heading from "components/typography/heading/Heading";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    visible: boolean;
    title: string;
    buttons?: React.ReactNode;
    children?: React.ReactNode;
    loading?: boolean;
}

export const SubpageLayout = (props: Props) => {
    const { t } = useTranslation();
    const context = React.useContext(subpageContext);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    const handleCloseClick = () => {
        context?.setSubpage(undefined);
    };

    return (
        <div className={style.subpageLayout}>
            {props.loading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <div className={classNames(style.layoutPadding, style.subpageContainer)}>
                        <div className={style.subpageHeader}>
                            <IconButton
                                onClick={handleCloseClick}
                                testId={testIds.common.subpageView.backToPrimaryViewButton}
                            >
                                <LeftArrow color={theme.activeForegroundColor} />
                            </IconButton>
                            <Heading tag="h1" variant="H1">
                                {props.title}
                            </Heading>
                        </div>
                        <div className={style.subpageContent}>
                            <ErrorBoundary FallbackComponent={ErrorMessage}>{props.children}</ErrorBoundary>
                        </div>
                    </div>
                    <div className={classNames(style.layoutPadding, style.subpageFooter)}>
                        <ButtonContainer alignment="LEFT" noSpacingTop={true}>
                            {props.buttons}
                            <Button
                                variant="SECONDARY"
                                type={"button"}
                                onClick={handleCloseClick}
                                testId={testIds.common.subpageView.cancelButton}
                            >
                                {t("Common.cancel")}
                            </Button>
                        </ButtonContainer>
                    </div>
                </>
            )}
        </div>
    );
};
