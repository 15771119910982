import * as React from "react";

const SetDefault = (props: { color: string }): JSX.Element => {
    return (
        <svg width="35.818" height="47.32" viewBox="0 0 48.32 48.32">
            <defs>
                <filter id="Path_1173" x="0" y="0" width="47" height="47" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="icon_set_default" transform="translate(4.5 2.5)">
                <g id="Group_3600" data-name="Group 3600" transform="translate(-717.501 -212.5)">
                    <g transform="matrix(1, 0, 0, 1, 713, 210)" filter="url(#Path_1173)">
                        <path
                            id="Path_1173-2"
                            data-name="Path 1173"
                            d="M171.86,212.13a19,19,0,1,1-19,19,19,19,0,0,1,19-19Z"
                            transform="translate(-148.36 -209.63)"
                            fill={props.color}
                        />
                    </g>
                </g>
                <g id="defaultWorkFlow" transform="translate(10.378 10.679)">
                    <path
                        id="star"
                        d="M10.621,2l2.664,5.478,5.957.884-4.311,4.261,1.017,6.02L10.621,15.8,5.293,18.643l1.017-6.02L2,8.361l5.957-.884Z"
                        transform="translate(-2 -2)"
                        fill="#fff"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </svg>
    );
};

export default SetDefault;
