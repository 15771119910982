import * as React from "react";

const DragDropCell = (props: { color: string }): JSX.Element => {
    return (
        <svg id="icon_drag_list" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <rect
                id="Rectangle_3015"
                data-name="Rectangle 3015"
                width="20"
                height="20"
                fill={"#d87proc7c"}
                opacity="0"
            />
            <g id="Group_7588" data-name="Group 7588">
                <line
                    id="Line_1567"
                    data-name="Line 1567"
                    x2="10"
                    transform="translate(5 7.5)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_1568"
                    data-name="Line 1568"
                    x2="10"
                    transform="translate(5 12.5)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};

export default DragDropCell;
