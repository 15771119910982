import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "components/import-file-dialog/import-file-dialog.scss";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    message: string | JSX.Element;
    reset: () => void;
    onClose: () => void;
    bodyContent?: JSX.Element;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const SucceededView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const successMessageElement =
        typeof props.message === "string" ? (
            <div className={style.introductionLabel}>{props.message}</div>
        ) : (
            props.message
        );
    return (
        <div className={style.leftAlignedWrapper}>
            {successMessageElement}
            <div className={style.successBody}>{props.bodyContent}</div>
            <div className={classNames(style.buttonContainer)}>
                <button
                    className={classNames(buttons.primaryButton, buttons.medium, style.button)}
                    data-testid={testIds.common.importFileDialog.uploadAnotherButton}
                    onClick={() => props.reset()}
                >
                    {t("Common.uploadAnotherFile")}
                </button>
                <button
                    className={classNames(buttons.secondaryButton, buttons.medium, style.button)}
                    data-testid={testIds.common.dialog.closeButton}
                    onClick={() => props.onClose()}
                >
                    {t("Common.ok")}
                </button>
            </div>
        </div>
    );
};

export default connector(SucceededView);
