import React from "react";

const StartedGreyIcon = (props: { pathColor: string; fillColor: string }): JSX.Element => {
    return (
        <svg
            id="Alert_icons"
            data-name="Alert icons"
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            viewBox="0 0 45 45"
        >
            <path
                id="Path_2249"
                data-name="Path 2249"
                d="M22.5,0A22.5,22.5,0,1,1,0,22.5,22.5,22.5,0,0,1,22.5,0Z"
                fill={props.fillColor}
            />
            <g id="Group_3900" data-name="Group 3900" transform="translate(2 -16.75)">
                <path
                    id="Path_2250"
                    data-name="Path 2250"
                    d="M.01,0"
                    transform="translate(21 33)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2.5"
                />
                <g id="Group_3484" data-name="Group 3484" transform="translate(0 2)">
                    <path
                        id="Path_2343"
                        data-name="Path 2343"
                        d="M0,0V15"
                        transform="translate(20.5 34)"
                        fill="none"
                        stroke={props.pathColor}
                        strokeLinecap="round"
                        strokeWidth="2.5"
                    />
                    <circle
                        id="Ellipse_289"
                        data-name="Ellipse 289"
                        cx="2"
                        cy="2"
                        r="2"
                        transform="translate(18.5 25.5)"
                        fill={props.pathColor}
                    />
                </g>
            </g>
            <rect id="Rectangle_2413" data-name="Rectangle 2413" width="45" height="45" fill="none" />
        </svg>
    );
};

export default StartedGreyIcon;
