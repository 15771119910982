import * as React from "react";

const ZoomOutIcon = (props: { color: string }): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="2" viewBox="0 0 16 2">
            <g id="zoom_out_icon" transform="translate(1 1)">
                <g id="plus" transform="translate(-5 -12)">
                    <line
                        id="Line_817"
                        data-name="Line 817"
                        x2="14"
                        transform="translate(5 12)"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </svg>
    );
};

export default ZoomOutIcon;
