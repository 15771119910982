import * as React from "react";

const GroupIcon = (props: {
    backgroundColor: string;
    textColor: string;
    groupInitials: string;
    className?: string;
    size?: number;
}): JSX.Element => {
    const iconSize = props.size ?? 28;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconSize}
            height={iconSize}
            viewBox="0 0 28 28"
            className={props.className}
        >
            <g id="Group_9275" data-name="Group 9275" transform="translate(2119 -640)">
                <circle
                    id="Ellipse_608"
                    data-name="Ellipse 608"
                    cx="14"
                    cy="14"
                    r="14"
                    transform="translate(-2119 640)"
                    fill={props.backgroundColor}
                />
                <text
                    id={props.groupInitials}
                    transform="translate(-2105 658)"
                    fill={props.textColor}
                    fontSize="11"
                    fontFamily="aktiv-grotesk,sans-serif"
                    fontWeight="700"
                    x="0"
                    y="0"
                    textAnchor="middle"
                >
                    {props.groupInitials}
                </text>
            </g>
        </svg>
    );
};

export default GroupIcon;
