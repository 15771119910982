import * as React from "react";

const DownArrow = (props: { color: string; className?: string }): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8.783" height="16" viewBox="0 0 8.783 16">
            <g id="arrow-down" transform="translate(1.414 1)">
                <g
                    id="arrow-down-2"
                    data-name="arrow-down"
                    transform="translate(5.954 14) rotate(180)"
                    className={props.className}
                >
                    <line
                        id="Line_816"
                        data-name="Line 816"
                        y1="14"
                        transform="translate(3)"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_4235"
                        data-name="Path 4235"
                        d="M5.954,2.977,2.977,0,0,2.977"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </svg>
    );
};

export default DownArrow;
