import { ChartData } from "chart.js";
import { TFunction } from "react-i18next";
import { useSelector } from "react-redux";

import { DashboardDataList, DeviceType } from "domain/dashboard";
import { getLanguage } from "services/language/languageRepository";
import { StoreState } from "store";

interface DisplayedValueAndUnit {
    total: number;
    average?: number;
    totalUnit: Units;
    averageUnit?: Units;
}

export enum Units {
    KILOGRAMS = "EsgViews.units.kilograms",
    TONNES = "EsgViews.units.tonnes",
    KILOTONNES = "EsgViews.units.kilotonnes",
    MEGATONNES = "EsgViews.units.megatonnes",
}

const CONVERSION_TO_TONNES_LIMIT = 10_000;
const ONE_THOUSAND = 1000;
const ONE_MILLION = 1e6;

function generateMonthNames(nameType: "long" | "short"): string[] {
    return Array.from({ length: 12 }).map((_, index) => {
        const date = new Date();
        date.setDate(1);
        date.setMonth(index);
        const lang = getLanguage().code.replace("_", "-");
        return date.toLocaleDateString(lang, { month: nameType });
    });
}

class CommonFunctions {
    public computeTotalNumberOfErasedDevices(data: DashboardDataList[]): number {
        if (data == null || data.length < 1) {
            return 0;
        }
        let sum = 0;
        Object.values(data).forEach((set) => {
            sum +=
                set.content.Laptop.weight +
                set.content.Desktop.weight +
                set.content.Server.weight +
                set.content.Tablet.weight +
                set.content.Smartphone.weight +
                set.content["Loose drive"].weight;
        });

        return sum;
    }

    public computeAverageWeightProcessPerDay(data: DashboardDataList[]): number {
        return this.computeTotalNumberOfErasedDevices(data) / 365;
    }

    public fetchMonths(data: DashboardDataList[]): string[] {
        if (data == null || data.length < 1) {
            return [];
        }
        const validMonths: string[] = [];
        Object.values(data).forEach((set) => {
            const [year, month] = set.month.split("-");
            validMonths.push(generateMonthNames("short")[Number(month) - 1] + " " + year.substring(2));
        });
        return validMonths;
    }

    public computeFirstMonth(data: DashboardDataList[]): string {
        if (data == null || data.length < 1) {
            return "";
        }
        const [shortFirstMonth, yearOfFirstMonth] = this.fetchMonths(data)[0].split(" ");
        for (const longMonth of generateMonthNames("long")) {
            if (longMonth.startsWith(shortFirstMonth)) {
                return longMonth + " 20" + yearOfFirstMonth;
            }
        }
        return "";
    }

    public computeLastMonth(data: DashboardDataList[]): string {
        if (data == null || data.length < 1) {
            return "";
        }
        const numberOfMonths = this.fetchMonths(data).length;
        const [shortLastMonth, yearOfFirstMonth] = this.fetchMonths(data)[numberOfMonths - 1].split(" ");
        for (const longMonth of generateMonthNames("long")) {
            if (longMonth.startsWith(shortLastMonth)) {
                return longMonth + " 20" + yearOfFirstMonth;
            }
        }
        return "";
    }

    public computeTotalOfCo2EmissionsPrevented(data: DashboardDataList[]): number {
        if (data == null || data.length < 1) {
            return 0;
        }
        let sum = 0;
        Object.values(data).forEach((set) => {
            sum +=
                set.content.Laptop["co2"] +
                set.content.Desktop["co2"] +
                set.content.Server["co2"] +
                set.content.Tablet["co2"] +
                set.content.Smartphone["co2"] +
                set.content["Loose drive"]["co2"];
        });
        return sum;
    }

    public needsToBeConvertedToTonnes(data: DashboardDataList[]): boolean {
        if (data == null || data.length < 1) {
            return false;
        }
        let monthMax = 0;
        Object.values(data).forEach((set) => {
            const monthTotal =
                set.content.Laptop["co2"] +
                set.content.Desktop["co2"] +
                set.content.Server["co2"] +
                set.content.Tablet["co2"] +
                set.content.Smartphone["co2"] +
                set.content["Loose drive"]["co2"];
            if (monthTotal > monthMax) {
                monthMax = monthTotal;
            }
        });
        return monthMax >= CONVERSION_TO_TONNES_LIMIT;
    }

    public getDisplayedValueAndUnit(total: number, average?: number): DisplayedValueAndUnit {
        let totalUnit = Units.KILOGRAMS;
        let averageUnit = Units.KILOGRAMS;
        const units = [Units.TONNES, Units.KILOTONNES, Units.MEGATONNES];

        const divide = (value: number) => value / 1000;

        units.forEach((unit: Units) => {
            if (total > ONE_THOUSAND) {
                total = divide(total);
                totalUnit = unit;
            }

            if (average !== undefined && average > ONE_THOUSAND) {
                average = divide(average);
                averageUnit = unit;
            }
        });

        return !average
            ? {
                  total: total,
                  totalUnit: totalUnit,
              }
            : {
                  total: total,
                  average: average,
                  totalUnit: totalUnit,
                  averageUnit: averageUnit,
              };
    }

    public toYAxisValueWithUnit(value: number, convert: boolean, unit: Units, unitName: string): string {
        let valueWithUnit = "";
        if (convert) {
            valueWithUnit = this.convertValueIntoUnit(value, unit, unitName);
        } else {
            valueWithUnit = value + " " + unitName;
        }

        return valueWithUnit;
    }

    public convertValueIntoUnit = (value: number, unit: Units, unitName: string): string => {
        let valueWithUnit = "";
        if (unit === Units.KILOGRAMS) {
            if (value < ONE_MILLION) {
                valueWithUnit = value + " " + unitName;
            } else {
                valueWithUnit = value / ONE_THOUSAND + " " + unitName;
            }
        } else if (unit === Units.TONNES) {
            if (value < ONE_MILLION) {
                valueWithUnit = value + " " + unitName;
            } else {
                valueWithUnit = value / ONE_MILLION + " mln. " + unitName;
            }
        } else if (unit === Units.KILOTONNES) {
            valueWithUnit = value / 1000 + " " + unitName;
        } else if (unit === Units.MEGATONNES) {
            valueWithUnit = value / 1000000 + " " + unitName;
        }
        return valueWithUnit;
    };

    public getChartData(
        t: TFunction,
        populateData: (deviceType: DeviceType) => number[],
        widgetData: DashboardDataList[]
    ): ChartData {
        const theme = useSelector((state: StoreState) => state.themeReducer.theme);
        interface Tuple {
            deviceType: DeviceType;
            label: string;
            backgroundColor: string;
        }
        return {
            datasets: [
                {
                    deviceType: "Laptop",
                    label: t("EsgViews.deviceTypes.laptop"),
                    backgroundColor: theme.chartFirstSet,
                },
                {
                    deviceType: "Desktop",
                    label: t("EsgViews.deviceTypes.desktop"),
                    backgroundColor: theme.chartSecondSet,
                },
                {
                    deviceType: "Server",
                    label: t("EsgViews.deviceTypes.server"),
                    backgroundColor: theme.chartThirdSet,
                },
                {
                    deviceType: "Loose drive",
                    label: t("EsgViews.deviceTypes.looseDrive"),
                    backgroundColor: theme.chartForthSet,
                },
                {
                    deviceType: "Smartphone",
                    label: t("EsgViews.deviceTypes.smartphone"),
                    backgroundColor: theme.chartFifthSet,
                },
                {
                    deviceType: "Tablet",
                    label: t("EsgViews.deviceTypes.tablet"),
                    backgroundColor: theme.chartSixthSet,
                },
            ].map((each: Tuple) => {
                return Object.assign({}, each, {
                    data: populateData(each["deviceType"]),
                    fill: true,
                });
            }),
            labels: this.fetchMonths(widgetData),
        };
    }
}

export const commonFunctions = new CommonFunctions();
