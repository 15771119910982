import { Layouts } from "react-grid-layout";

import { getObject, RepositoryKey, setObject } from "utils/repository";

export function getCardOrderLayout(): Layouts | null {
    return getObject(RepositoryKey.DASHBOARD_CARD_LAYOUT);
}

export function setCardOrderLayout(layouts: Layouts): void {
    setObject(RepositoryKey.DASHBOARD_CARD_LAYOUT, layouts);
}

//For ESG Dashboard

export function getESGCardOrderLayout(): Layouts | null {
    return getObject(RepositoryKey.ESG_DASHBOARD_CARD_LAYOUT);
}

export function setESGCardOrderLayout(layouts: Layouts): void {
    setObject(RepositoryKey.ESG_DASHBOARD_CARD_LAYOUT, layouts);
}

//For Customer ESG Dashboard

export function getCustomerESGCardOrderLayout(): Layouts | null {
    return getObject(RepositoryKey.CUSTOMER_ESG_DASHBOARD_CARD_LAYOUT);
}

export function setCustomerESGCardOrderLayout(layouts: Layouts): void {
    setObject(RepositoryKey.CUSTOMER_ESG_DASHBOARD_CARD_LAYOUT, layouts);
}

//For Support & Help Cards

export function getSupportAndHelpCardOrderLayout(): Layouts | null {
    return getObject(RepositoryKey.SUPPORT_AND_HELP_CARD_LAYOUT);
}

export function setSupportAndHelpCardOrderLayout(layouts: Layouts): void {
    setObject(RepositoryKey.SUPPORT_AND_HELP_CARD_LAYOUT, layouts);
}
