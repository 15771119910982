import * as LanguageRepository from "services/language/languageRepository";
import { logger } from "utils/logging";

const LANGUAGE_CODE = LanguageRepository.getLanguage().code;

export function initialize(): void {
    try {
        gtag("set", "language", LANGUAGE_CODE.toLowerCase());
    } catch (e) {
        logger.error("Exception occurred while initializing GA: ", e);
    }
}
