import { ProductManualCollection, ProductManualCollectionDto, ProductManualDto } from "domain/productManuals";
import { apiGatewayService } from "services/api/ApiGatewayService";
import { getObject, RepositoryKey } from "utils/repository";

function toProductManualCollection(dto: ProductManualCollectionDto): ProductManualCollection {
    const productManualCollection: ProductManualCollection = {
        productManuals: [],
    };
    dto.productManuals.map((each: ProductManualDto) => {
        productManualCollection.productManuals.push({
            productName: each.productName,
            licenseIds: each.licenseIds.map(String),
            versions: each.versions,
        });
    });
    return productManualCollection;
}

/**
 * Handles interactions with product manuals list.
 */
class ProductManualsService {
    public async fetchProductManuals(): Promise<ProductManualCollection> {
        const url: string = getObject(RepositoryKey.PRODUCT_MANUAL_URL) || "https://api.blancco.com/support/content";
        const promise = apiGatewayService.fetch(url);
        return promise.then((result) => result.json()).then((result) => toProductManualCollection(result));
    }
}

export const productManualsService = new ProductManualsService();
