import classNames from "classnames";
import { TFunction } from "i18next";
import * as React from "react";
import { DragDropContext, Draggable, Droppable, DroppableStateSnapshot, DropResult } from "react-beautiful-dnd";
import SelectSearch, { DomProps, SelectedOption, SelectSearchOption } from "react-select-search";

import style from "./create-report-view-modal.scss";
import { DeleteIcon } from "components/icons/DeleteIcon";
import DragDropCell from "components/icons/DragDropCell";
import staticTableStyle from "components/support/api-guide/static-table.scss";
import Tooltip from "components/tooltip/Tooltip";
import { Path } from "domain/reports";
import { deriveProductName } from "services/report/erasure/ReportService";
import { ReportPath } from "services/report/ReportViewService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import defaultColor from "styles/colors/default-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";
import formStyle from "styles/form.scss";
import { createFilterOptions } from "utils/commonFunctions";
import { logger } from "utils/logging";

import testIds from "testIds.json";

export interface PathSelection {
    available: SelectSearchOption[];
    selected: SelectSearchOption[];
}

interface SelectedColumns {
    column: SelectSearchOption;
    position: number;
}

function areOptionsEqual(alpha: SelectSearchOption[], omega: SelectSearchOption[]): boolean {
    if (alpha.length !== omega.length) {
        return false;
    }
    // Before every invocation, generate tuples of two from the two arrays.
    return [...new Array(alpha.length).keys()]
        .map((index) => [alpha[index], omega[index]])
        .every((each) => each[0].value === each[1].value);
}

function movePath(pathSelection: PathSelection, selectedValue: string, toSelected: boolean): PathSelection {
    const moved = (toSelected ? pathSelection.available : pathSelection.selected).find(
        (each) => each.value.toString() === selectedValue
    );
    if (moved == null) {
        throw Error("Can't find " + selectedValue);
    }
    const reduced = (toSelected ? pathSelection.available : pathSelection.selected).filter((each) => each !== moved);
    if (toSelected) {
        return {
            available: reduced,
            selected: [...pathSelection.selected, moved],
        };
    }
    return {
        available: [...pathSelection.available, moved],
        selected: reduced,
    };
}

export function movePathToSelected(pathSelection: PathSelection, selectedValue: string): PathSelection {
    return movePath(pathSelection, selectedValue, true);
}

export function movePathToAvailable(pathSelection: PathSelection, selectedValue: string): PathSelection {
    const selection = movePath(pathSelection, selectedValue, false);
    return {
        available: sortAvailable(selection.available),
        selected: selection.selected,
    };
}

export function moveAllSelectedToAvailable(pathSelection: PathSelection): PathSelection {
    let updatedPathSelection = pathSelection;
    for (const selectedFilter of updatedPathSelection.selected) {
        updatedPathSelection = movePath(updatedPathSelection, selectedFilter.value.toString(), false);
    }
    return {
        available: sortAvailable(updatedPathSelection.available),
        selected: updatedPathSelection.selected,
    };
}

function sortAvailable(available: SelectSearchOption[]): SelectSearchOption[] {
    return available.sort((first, second) => first.name.localeCompare(second.name));
}

const PRODUCT_SELECT_HTML_ID = "swapProductSelect";

interface Props {
    maximumSelectedCount: number;
    columns?: string[];
    onChange: (paths: string[]) => void;
    paths: ReportPath[];
    t: TFunction;
    theme: typeof defaultColor | typeof tenantColor;
    translatePath: (path: string) => string;
    create: boolean;
    edit?: boolean;
}

interface State {
    pathSelection: PathSelection;
    product: string;
}

export function createProductIdToPathMap(paths: ReportPath[]): Map<string, string[]> {
    return paths
        .map((each) => each.productIds.map((productId) => [productId.toString(), each.path]))
        .flat()
        .reduce<Map<string, string[]>>((accruing: Map<string, string[]>, [productId, path]: [string, string]) => {
            const paths =
                accruing.get(productId) ??
                (() => {
                    const productPaths: string[] = [];
                    accruing.set(productId, productPaths);
                    return productPaths;
                })();
            paths.push(path);
            return accruing;
        }, new Map<string, string[]>());
}

const NONE_PRODUCT_ID = "none";
const ALL_PRODUCT_ID = "all";

interface Product {
    id: string;
    name: string;
}

function createTooltip(visibleText: string, tooltipText: string): JSX.Element {
    // 350px maxWidth here is matched to 400px $selectWidth in
    // create-report-view-modal.scss. That is, tooltip should be at most a
    // little less wide than the dropdown or table. If it's wider, it'll
    // create a horizontal scrollbar.

    // Delay is 300 because the default value zero results in constant new
    // tooltip popups when you're scrolling through columns in available
    // columns dropdown. Default delay value for hiding the tooltip has also
    // been adjusted because the default value 500 merely meant that there
    // could be multiple visible tooltips at the same time.

    // Auto placement seems to work fairly well. Normally we show the tooltip
    // on top of the item but then it covers the previous column completely.
    // "Auto" strategy still does that if the tooltip is long enough but in
    // some cases it can show the tooltip on the right side of the visible
    // value. That's the best case because it's not in the way.

    // TODO BCC-2571 Use full path.
    return (
        <Tooltip content={tooltipText} maxWidth={350} delay={[300, 0]} placement={"auto"}>
            <span
                data-testid={testIds.workArea.report.columnSelector.selectedTable.selectedPathLabel}
                data-path={tooltipText}
            >
                {visibleText}
            </span>
        </Tooltip>
    );
}

export default class Swap extends React.Component<Props, State> {
    private readonly commonPaths: SelectSearchOption[];
    private readonly productIdToPaths: Map<string, string[]>;
    private readonly products: Product[];
    private selectSearchComponent: React.RefObject<React.Component>;

    constructor(props: Props) {
        super(props);
        const columns = props.columns ? props.columns : [];
        const postSelected = columns.map((each: string) => ({ name: props.translatePath(each), value: each }));
        this.commonPaths = [
            ...postSelected,
            { name: props.t("ErasureReportsTable.reportVerification"), value: Path.VERIFIED },
        ];

        this.productIdToPaths = createProductIdToPathMap(props.paths);
        this.products = Array.from(this.productIdToPaths.keys())
            .map((id) => ({ id, name: deriveProductName(id) }))
            .sort((first, second) => first.name.localeCompare(second.name));
        const mandatoryColumns = [
            "uuid",
            "product",
            Path.DATE,
            Path.PRODUCT_VERSION,
            Path.PRODUCT_REVISION,
            Path.PRODUCT_NAME,
            Path.REPORT_OWNER,
        ];
        const columnSet = new Set(columns);
        const mandatorySet = new Set(mandatoryColumns);
        const list = props.paths
            .map((each) => each.path)
            .filter((each) => !columnSet.has(each) && !mandatorySet.has(each))
            .filter((each) => (props.create ? each !== "date" : props.edit ? each !== "date" : each))
            .map((path) => ({
                name: props.translatePath(path),
                value: path,
            }));

        mandatoryColumns
            .filter((each) => !columnSet.has(each))
            .forEach((each) => {
                const path = { name: props.translatePath(each), value: each };
                list.push(path);
                this.commonPaths.push(path);
            });

        this.state = {
            pathSelection: {
                available: sortAvailable(list),
                selected: postSelected,
            },
            product: ALL_PRODUCT_ID,
        };

        this.selectSearchComponent = React.createRef();
    }

    render(): JSX.Element {
        const selectedColumns: SelectedColumns[] = this.state.pathSelection.selected.map((each, key) => ({
            column: each,
            position: key,
        }));
        const reorder = (list: SelectedColumns[], startIndex: number, endIndex: number) => {
            const result = list;
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };
        const onDragEnd = (result: DropResult) => {
            if (!result.destination) {
                return;
            }
            const sorted = reorder(selectedColumns, result.source.index, result.destination.index);

            this.setState((current) => ({
                pathSelection: {
                    available: current.pathSelection.available,
                    selected: sorted.map((each) => each.column),
                },
            }));
        };
        const selectedColumnsTable = (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"Table"}>
                    {(provided, snapshot: DroppableStateSnapshot) => (
                        <table
                            className={staticTableStyle.table}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            {...{ isDraggingOver: snapshot.isDraggingOver }}
                            data-testid={testIds.workArea.report.columnSelector.selectedTable.itself}
                        >
                            <thead>
                                <tr>
                                    <th>
                                        {this.props.t("CreateReportView.form.selectedColumns")}
                                        {`(${selectedColumns.length}/${this.props.maximumSelectedCount})`}
                                    </th>
                                </tr>
                            </thead>
                            {selectedColumns.length > 0 && (
                                <tbody>
                                    {selectedColumns.map((each, key) => {
                                        return (
                                            <Draggable
                                                key={each.position}
                                                draggableId={each.position.toString()}
                                                index={key}
                                            >
                                                {(provided, snapshot) => (
                                                    <tr ref={provided.innerRef}>
                                                        <td
                                                            key={each.column.name}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            {...{ isDragging: snapshot.isDragging }}
                                                            className={classNames({
                                                                [style.dragging]: snapshot.isDragging,
                                                            })}
                                                        >
                                                            <div key={each.column.value} className={style.tableRow}>
                                                                <div className={style.tableItem}>
                                                                    <DragDropCell
                                                                        color={this.props.theme.iconFillColor}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={classNames(
                                                                        style.tableItem,
                                                                        style.columnName
                                                                    )}
                                                                >
                                                                    {createTooltip(
                                                                        each.position + 1 + ". " + each.column.name,
                                                                        each.column.value.toString()
                                                                    )}
                                                                </div>
                                                                {this.state.pathSelection.selected.length > 1 && (
                                                                    <div
                                                                        className={classNames(
                                                                            style.tableItem,
                                                                            formStyle.pointer
                                                                        )}
                                                                        onClick={() => {
                                                                            this.setState((current) => ({
                                                                                pathSelection: movePathToAvailable(
                                                                                    current.pathSelection,
                                                                                    each.column.value.toString()
                                                                                ),
                                                                            }));
                                                                            usageStatisticsService.sendEvent({
                                                                                category: Category.REPORT_VIEW,
                                                                                action: Action.REMOVE_COLUMN,
                                                                            });
                                                                        }}
                                                                        data-testid={
                                                                            testIds.workArea.report.columnSelector
                                                                                .selectedTable.removeSelectedPathButton
                                                                        }
                                                                    >
                                                                        <DeleteIcon
                                                                            color={this.props.theme.iconFillColor}
                                                                            linecolor={
                                                                                this.props.theme.contentBackgroundColor
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </tbody>
                            )}
                        </table>
                    )}
                </Droppable>
            </DragDropContext>
        );
        const onProductChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            usageStatisticsService.sendEvent({
                category: Category.REPORT_VIEW,
                action: Action.CHANGE_PRODUCT,
            });
            const product = event.target.value;
            this.setState((previousState) => ({
                pathSelection: previousState.pathSelection,
                product,
            }));
        };
        const filterAvailableByProduct = (): SelectSearchOption[] => {
            const deriveAcceptablePaths = (): string[] => {
                const generateCommonPaths = () => this.commonPaths.map((each) => each.value.toString());
                switch (this.state.product) {
                    case NONE_PRODUCT_ID:
                        return generateCommonPaths();
                    case ALL_PRODUCT_ID:
                        return [...Array.from(this.productIdToPaths.values()).flat(), ...generateCommonPaths()];
                    default:
                        return (() => {
                            const paths = this.productIdToPaths.get(this.state.product);
                            if (paths == null) {
                                throw new Error("It should be impossible for acceptablePaths to be null");
                            }
                            return paths;
                        })();
                }
            };
            const acceptablePaths = new Set(deriveAcceptablePaths());
            return this.state.pathSelection.available.filter((each) => acceptablePaths.has(each.value.toString()));
        };
        return (
            <div className={style.dualBox}>
                <div>
                    <div className={formStyle.formFields}>
                        <div className={style.tableColumnLabel}>
                            <label
                                htmlFor={PRODUCT_SELECT_HTML_ID}
                                className={classNames(formStyle.label, style.label)}
                            >
                                {this.props.t("CreateReportView.form.availableColumns")}
                            </label>
                        </div>
                        <div className={style.allColumnLabel}>
                            <select
                                id={PRODUCT_SELECT_HTML_ID}
                                value={this.state.product}
                                onChange={onProductChange}
                                className={classNames(formStyle.select, style.wideSelect, style.selectArrow)}
                                data-testid={testIds.workArea.report.columnSelector.productSelect.itself}
                            >
                                <option value={ALL_PRODUCT_ID}>
                                    {this.props.t("CreateReportView.form.allColumns")}
                                </option>
                                <option value={NONE_PRODUCT_ID}>
                                    {this.props.t("CreateReportView.form.commonColumns")}
                                </option>
                                {this.products.map(({ id, name }) => (
                                    <option value={id} key={id}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className={formStyle.formFields}>
                        <SelectSearch
                            ref={this.selectSearchComponent}
                            closeOnSelect={false}
                            onChange={() => {
                                usageStatisticsService.sendEvent({
                                    category: Category.REPORT_VIEW,
                                    action: Action.ADD_COLUMN,
                                });
                            }}
                            filterOptions={createFilterOptions}
                            options={filterAvailableByProduct()}
                            disabled={this.state.pathSelection.selected.length >= this.props.maximumSelectedCount}
                            renderOption={(domProps: DomProps, option: SelectedOption): React.ReactNode => {
                                // Typing system claims that this is SelectedOption even thou it seems to be
                                // SelectedOptionValue. The latter contains the needed "name". So it seems we're once
                                // again forced to go around TypeScript's typing.
                                const { name } = option as unknown as { name: string };
                                return (
                                    <button
                                        type={"button"}
                                        className={"src-components-reports-create-report-view-modal__select__option"}
                                        tabIndex={parseInt(domProps.tabIndex, 10)}
                                        value={option.value}
                                        onClick={() => {
                                            this.setState((current) => ({
                                                pathSelection: movePathToSelected(current.pathSelection, option.value),
                                            }));
                                        }}
                                    >
                                        {createTooltip(name, option.value)}
                                    </button>
                                );
                            }}
                            search={true}
                            placeholder={this.props.t("Common.search")}
                            className={style.select}
                        />
                    </div>
                    {this.state.pathSelection.selected.length >= this.props.maximumSelectedCount && (
                        <div
                            data-testid={testIds.workArea.report.manageReportViewDialog.columnLimitReachedLabel}
                            className={style.maxColumnsWarning}
                        >
                            {this.props.t("CreateReportView.form.columnLimitReached")}
                        </div>
                    )}
                </div>
                <div>
                    <div className={style.selectedColumnsTable}>{selectedColumnsTable}</div>
                </div>
            </div>
        );
    }

    componentDidMount(): void {
        this.props.onChange(this.getPaths());
    }

    componentDidUpdate(_: Props, previousState: State): void {
        const getSelected = (state: State) => state.pathSelection.selected;
        if (!areOptionsEqual(getSelected(previousState), getSelected(this.state))) {
            this.props.onChange(this.getPaths());
        }

        if (this.selectSearchComponent.current != null) {
            let selectSearchElement: HTMLElement | null;
            let searchInput: HTMLInputElement | null;
            try {
                // This is hacky but so far I haven't found a typesafe way to
                // get access to the underlying HTML element. It works at
                // least for now. I.e. with React ^16.13.1. Can't let the
                // entire component fail if there's an issue with setting an
                // HTML test ID so we'll catch all exceptions, print the
                // console.error, and move on. Another option would be to not
                // do this at all and let test automation handle finding the
                // input on that side. At least for now we'll try to do our
                // part and lessen the work in test automation.
                selectSearchElement = this.selectSearchComponent.current as unknown as HTMLElement;
                searchInput = selectSearchElement.querySelector("input");
            } catch (error) {
                logger.error(
                    "Failed to find native input element when trying to set test ID for column search input.",
                    error
                );
                return;
            }
            if (selectSearchElement != null) {
                selectSearchElement.dataset.testid = testIds.common.searchSelectContainer.itself;
            }
            if (searchInput != null) {
                searchInput.dataset.testid = testIds.common.searchSelectContainer.searchInput.itself;
            }
        }
    }

    private getPaths(): string[] {
        return this.state.pathSelection.selected.map((each) => each.value.toString());
    }
}
