import React from "react";

import style from "./feature-update-modal.scss";
import Modal from "./Modal";

interface improvements {
    icon: JSX.Element;
    title: string;
    message: JSX.Element;
}

interface Props {
    open: boolean;
    hide: () => void;
    title: string;
    banner?: JSX.Element | undefined;
    improvements?: improvements[];
    actions?: JSX.Element;
    testId: string;
}

const FeatureUpdateModal = (props: Props) => {
    const banner = props.banner ? props.banner : <></>;
    return (
        <Modal isOpen={props.open} hideModal={props.hide} modalTitle={props.title}>
            <div data-testid={props.testId}>
                <div className={style.banner}>{banner}</div>
                {props.improvements?.map((each, index) => {
                    return (
                        <div key={index} className={style.improvements}>
                            <div>{each.icon}</div>
                            <div>
                                <div>
                                    <b>{each.title}</b>
                                </div>
                                <div>{each.message}</div>
                            </div>
                        </div>
                    );
                })}
                <div className={style.actions}>{props.actions}</div>
            </div>
        </Modal>
    );
};

export default FeatureUpdateModal;
