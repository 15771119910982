import * as React from "react";

const EmptyStateIcon = (props: {
    ellipseBackgroundColor: string;
    biggestCircleBackgroundColor: string;
    smallestCircleBackgroundColor: string;
    personBackgroundColor: string;
    personShirtColor: string;
    personSleeveColor: string;
    ellipseBackgroundOpacity: string;
    biggestCircleBackgroundOpacity: string;
    smallestCircleBackgroundOpacityFirst: string;
    smallestCircleBackgroundOpacitySecond: string;
    smallestCircleBackgroundOpacityThird: string;
}): JSX.Element => {
    return (
        <svg
            id="EmptyStateIllustrationNew"
            xmlns="http://www.w3.org/2000/svg"
            width="170"
            height="185"
            viewBox="0 0 170 185"
        >
            <ellipse
                id="Ellipse_356"
                data-name="Ellipse 356"
                cx="68.788"
                cy="12.152"
                rx="68.788"
                ry="12.152"
                transform="translate(0 160.696)"
                fill={props.ellipseBackgroundColor}
                opacity={props.ellipseBackgroundOpacity}
            />
            <circle
                id="Ellipse_357"
                data-name="Ellipse 357"
                cx="67"
                cy="67"
                r="67"
                transform="translate(36)"
                fill={props.biggestCircleBackgroundColor}
                opacity={props.biggestCircleBackgroundOpacity}
            />
            <circle
                id="Ellipse_358"
                data-name="Ellipse 358"
                cx="55.499"
                cy="55.499"
                r="55.499"
                transform="translate(47.579 11.729)"
                fill={props.smallestCircleBackgroundColor}
                opacity={props.smallestCircleBackgroundOpacityFirst}
            />
            <circle
                id="Ellipse_359"
                data-name="Ellipse 359"
                cx="45.486"
                cy="45.486"
                r="45.486"
                transform="translate(57.591 21.741)"
                fill={props.smallestCircleBackgroundColor}
                opacity={props.smallestCircleBackgroundOpacitySecond}
            />
            <circle
                id="Ellipse_360"
                data-name="Ellipse 360"
                cx="32.613"
                cy="32.613"
                r="32.613"
                transform="translate(70.621 34.307)"
                fill={props.smallestCircleBackgroundColor}
                opacity={props.smallestCircleBackgroundOpacityThird}
            />
            <path
                id="Path_4179"
                data-name="Path 4179"
                d="M386.091,361.232s-5.285,14.878-2.936,20.163a65.635,65.635,0,0,0,6.068,10.375S387.852,362.211,386.091,361.232Z"
                transform="translate(-342.244 -288.494)"
                fill={props.personSleeveColor}
            />
            <path
                id="Path_4180"
                data-name="Path 4180"
                d="M386.091,361.232s-5.285,14.878-2.936,20.163a65.635,65.635,0,0,0,6.068,10.375S387.852,362.211,386.091,361.232Z"
                transform="translate(-342.244 -288.494)"
                fill={props.personSleeveColor}
            />
            <path
                id="Path_4181"
                data-name="Path 4181"
                d="M390.47,514.383a31.726,31.726,0,0,1-.587,3.915c-.2.2.2.587,0,1.175s-.392,1.37,0,1.566-2.153,17.422-2.153,17.422-6.264,8.222-3.719,21.142l.783,13.116s6.068.392,6.068-1.762a35.869,35.869,0,0,1-.391-3.719c0-1.175.979-1.175.391-1.762s-.587-.979-.587-.979.979-.783.783-.979,1.762-14.094,1.762-14.094,2.153-2.153,2.153-3.328v-1.175s.979-2.545.979-2.741,5.285-12.137,5.285-12.137l2.153,8.613,2.349,12.333s1.175,11.158,3.524,15.465c0,0,4.111,14.094,4.111,13.7s6.852-1.37,6.656-3.132-4.111-26.427-4.111-26.427l.979-36.607Z"
                transform="translate(-342.931 -407.76)"
                fill={props.personBackgroundColor}
            />
            <path
                id="Path_4182"
                data-name="Path 4182"
                d="M381.036,764.792s-5.285,10.375-1.762,10.767,4.894.392,6.46-1.175a26.066,26.066,0,0,1,3.986-2.87,5.155,5.155,0,0,0,2.446-4.9c-.1-.958-.461-1.747-1.342-1.82-2.349-.2-5.09-2.349-5.09-2.349Z"
                transform="translate(-338.758 -600.813)"
                fill={props.personBackgroundColor}
            />
            <path
                id="Path_4183"
                data-name="Path 4183"
                d="M511.847,799.031s-5.285,10.375-1.762,10.767,4.894.391,6.46-1.175a26.064,26.064,0,0,1,3.986-2.87,5.154,5.154,0,0,0,2.446-4.9c-.1-.958-.461-1.747-1.342-1.82-2.349-.2-5.09-2.349-5.09-2.349Z"
                transform="translate(-440.49 -627.417)"
                fill={props.personBackgroundColor}
            />
            <ellipse
                id="Ellipse_361"
                data-name="Ellipse 361"
                cx="8.428"
                cy="9.052"
                rx="8.428"
                ry="9.052"
                transform="translate(57.572 39.553)"
                fill="#ffb8b8"
            />
            <path
                id="Path_4184"
                data-name="Path 4184"
                d="M451.258,260.308s-5.878,10.815-6.348,10.815,10.58,3.527,10.58,3.527,3.056-10.345,3.527-11.285Z"
                transform="translate(-390.733 -209.886)"
                fill="#ffb8b8"
            />
            <path
                id="Path_4185"
                data-name="Path 4185"
                d="M421.767,291.541s-11.745-6.46-12.92-6.264-13.7,11.158-13.507,15.661a96.751,96.751,0,0,0,1.762,11.941s.587,20.75,1.762,20.946-.2,3.719.2,3.719,27.406,0,27.6-.587S421.767,291.541,421.767,291.541Z"
                transform="translate(-352.208 -229.418)"
                fill={props.personShirtColor}
            />
            <path
                id="Path_4186"
                data-name="Path 4186"
                d="M529.634,521.892s3.719,11.354.587,10.962-4.5-9.788-4.5-9.788Z"
                transform="translate(-453.579 -413.494)"
                fill="#ffb8b8"
            />
            <path
                id="Path_4187"
                data-name="Path 4187"
                d="M476.907,310.243s-7.243,1.566-6.068,11.354,3.328,19.576,3.328,19.576l7.243,15.856.783,2.936,5.285-1.37-3.915-22.708s-1.37-24.274-3.132-25.057A7.581,7.581,0,0,0,476.907,310.243Z"
                transform="translate(-410.813 -248.84)"
                fill={props.personSleeveColor}
            />
            <path
                id="Path_4188"
                data-name="Path 4188"
                d="M277.5,411.953l9,16.052-7.587-16.914Z"
                transform="translate(-215.807 -320.009)"
                fill="#7993ce"
                opacity="0.1"
            />
            <path
                id="Path_4189"
                data-name="Path 4189"
                d="M470.475,211.223l.027-.629,1.251.311a1.4,1.4,0,0,0-.561-1.029l1.332-.074a14.377,14.377,0,0,0-9.616-5.943c-2.882-.418-6.091.187-8.068,2.326a9.722,9.722,0,0,0-1.99,3.7c-.789,2.479-.95,5.434.7,7.45,1.672,2.048,4.594,2.449,7.226,2.7a5.706,5.706,0,0,0,2.755-.187,6.627,6.627,0,0,0-.369-2.907,1.937,1.937,0,0,1-.2-.926c.117-.783,1.161-.98,1.946-.875s1.728.268,2.244-.333a2.665,2.665,0,0,0,.381-1.56C467.663,211.774,470.462,211.533,470.475,211.223Z"
                transform="translate(-396.48 -165.851)"
                fill="#4d4c5c"
            />
            <circle
                id="Ellipse_362"
                data-name="Ellipse 362"
                cx="9.588"
                cy="9.588"
                r="9.588"
                transform="translate(114.694 155.344)"
                fill={props.personSleeveColor}
            />
            <circle
                id="Ellipse_363"
                data-name="Ellipse 363"
                cx="9.588"
                cy="9.588"
                r="9.588"
                transform="translate(2.453 151.999)"
                fill={props.personSleeveColor}
            />
            <circle
                id="Ellipse_364"
                data-name="Ellipse 364"
                cx="6.912"
                cy="6.912"
                r="6.912"
                transform="translate(5.128 142.147)"
                fill={props.personSleeveColor}
            />
            <circle
                id="Ellipse_365"
                data-name="Ellipse 365"
                cx="4.905"
                cy="4.905"
                r="4.905"
                transform="translate(7.135 133.525)"
                fill={props.personSleeveColor}
            />
        </svg>
    );
};

export default EmptyStateIcon;
