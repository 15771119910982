import * as React from "react";

import { WorkflowService } from "services/workflows/WorkflowService";

export enum DialogState {
    SELECTING_FILE = "SELECTING_FILE",
    LOADING_FILE = "LOADING_FILE",
    LOADING_FILES = "LOADING_FILES",
    LOADING_FILE_FAILED = "LOADING_FILE_FAILED",
    IMPORTING_WORKFLOW = "IMPORTING_WORKFLOW",
    IMPORTING_SUCCEEDED = "IMPORTING_SUCCEEDED",
    IMPORTING_SUCCEEDED_MISSING_SUB_WORKFLOWS = "IMPORTING_SUCCEEDED_MISSING_SUB_WORKFLOWS",
    IMPORTING_FAILED = "IMPORTING_FAILED",
    IMPORTING_REPORT_VIEW = "IMPORTING_REPORT_VIEW",
    PREPARING_UPLOAD = "PREPARING_UPLOAD",
    PREPARING_UPLOAD_FAILED = "PREPARING_UPLOAD_FAILED",
    UPLOADING_REPORTS = "UPLOADING_REPORTS",
    UPLOADING_REPORTS_FAILED = "UPLOADING_REPORTS_FAILED",
    IMPORTING_REPORTS = "IMPORTING_REPORTS",
    VALIDATING_REPORTS = "VALIDATING_REPORTS",
    VALIDATED_REPORTS = "VALIDATED_REPORTS",
    VALIDATED_REPORTS_BULK = "VALIDATED_REPORTS_BULK",
}

interface Props {
    fileList?: File[];
    onClose: () => void;
    workflowService?: WorkflowService;
    stateDispatch: () => void;
    dialogLogic: () => void;
    stateToContent: Map<DialogState, JSX.Element>;
    dialogState: DialogState;
}

const ImportFileDialog = (props: Props): JSX.Element => {
    React.useEffect(() => {
        props.stateDispatch();
    }, []);

    React.useEffect(() => {
        props.dialogLogic();
    }, [props.dialogState]);

    const content = props.stateToContent.get(props.dialogState);
    if (!content) {
        throw new Error("Unimplemented import dialog state: " + props.dialogState.toString());
    }
    return content;
};
export default ImportFileDialog;
