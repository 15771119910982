import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import ApiKeysKebabMenu from "./ApiKeysKebabMenu";
import style from "./general-setting-view.scss";
import { ApiKeyDetailView } from "components/api-keys-settings/api-key-detail/ApiKeyDetailView";
import KebabMenu from "components/kebab-menu/KebabMenu";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { ApiKey, ApiKeyCursor, ApiKeys } from "domain/apiKeys";
import { AUTH_API_KEY_DELETE_OWN, AUTH_API_KEY_EDIT_OWN } from "domain/authority";
import { apiKeysService } from "services/api-keys/ApiKeysService";
import { userSessionService } from "services/user/UserSessionService";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface TableState {
    apiKeysData: ApiKey[];
    cursor: ApiKeyCursor | null;
    scrollPosition?: number;
}
export function MyApiKeysTable(props: {
    scrollPosition?: number;
    initialApiKeyData: ApiKeys;
    onApiKeyDelete: () => void;
    onApiKeyEdit: () => void;
    count: number;
}): JSX.Element {
    const { t } = useTranslation();
    const [apiKeyProfileVisibility, setApiKeyProfileVisibility] = React.useState(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [search, setSearchQuery] = React.useState("");
    const showApiKeyProfile = (apiKeysdata: ApiKey) => {
        setApiKeyProfileVisibility(true);
        setActiveApiKeyDetails(apiKeysdata);
    };

    const hideApiKeyProfile = () => {
        setApiKeyProfileVisibility(false);
    };

    const columns: Array<Column<ApiKey>> = [
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.name")} key="1" />,
            accessor: "name",
            Cell: (cellInfo) => (
                <>
                    {userSessionService.userHasAnyAuthority([AUTH_API_KEY_DELETE_OWN, AUTH_API_KEY_EDIT_OWN]) && (
                        <KebabMenu>
                            <ApiKeysKebabMenu
                                uuid={cellInfo.cell.row.original.uuid}
                                name={cellInfo.cell.row.original.name}
                                description={cellInfo.cell.row.original.description}
                                onApiKeyDelete={props.onApiKeyDelete}
                                onApiKeyEdit={props.onApiKeyEdit}
                            />
                        </KebabMenu>
                    )}
                    <button
                        className={style.apiKeysNameCell}
                        onClick={() => {
                            showApiKeyProfile(cellInfo.cell.row.original);
                        }}
                    >
                        <TextWithTooltip text={cellInfo.value} />
                    </button>
                </>
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.description")} key="2" />,
            accessor: "description",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("Common.creationDate")} key="3" />,
            accessor: "created",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
        },
    ];

    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [tableState, setTableState] = React.useState<TableState>({
        apiKeysData: props.initialApiKeyData.api_keys,
        cursor: props.initialApiKeyData.cursor,
        scrollPosition: props.scrollPosition,
    });
    const [activeApiKeyDetails, setActiveApiKeyDetails] = React.useState<ApiKey>(props.initialApiKeyData.api_keys[0]);
    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        apiKeysService
            .fetchApiKeys(true, abortController, initialLoading ? "" : tableState.cursor?.uuid, search)
            .then((data) => {
                data.apiKeys.api_keys.sort(
                    (a, b) => a.name.localeCompare(b.name) || a.created.localeCompare(b.created)
                );
                setTableState((prevState) => ({
                    ...prevState,
                    apiKeysData: prevState.apiKeysData.concat(data.apiKeys.api_keys),
                    scrollPosition: prevState.apiKeysData.length - 1,
                    cursor: data.apiKeys.cursor,
                }));
                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("ApiKeys.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        setTableState({
            apiKeysData: [],
            cursor: { uuid: "" },
            scrollPosition: 0,
        });
        if (props.initialApiKeyData.api_keys.length > 0) {
            return;
        }
        const abortController = new AbortController();
        fetchData(true);

        return () => {
            abortController.abort();
        };
    }, [props.count, search]);

    const modal =
        activeApiKeyDetails != null ? (
            <Modal
                key={1}
                isOpen={apiKeyProfileVisibility}
                hideModal={hideApiKeyProfile}
                modalTitle={t("ApiKeysProfile.title", { apiKeyName: activeApiKeyDetails.name })}
            >
                <ApiKeyDetailView activeApiKey={activeApiKeyDetails} />
            </Modal>
        ) : null;

    let dataCount = null;
    if (tableState.apiKeysData.length > 0) {
        dataCount = t("Common.defaultSearchResultHint", { dataCount: tableState.apiKeysData.length });
    }

    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>{dataCount}</div>
                <div className={formStyle.search}>
                    <SearchView setSearch={setSearchQuery} searchInProgress={loading} />
                </div>
            </div>
            <div className={layoutStyle.tableWrapper}>
                <Table
                    tableIdentity={RepositoryKey.MY_API_KEYS_TABLE}
                    data={tableState.apiKeysData}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    emptyMessage={t("ApiKeys.emptyStateMessage")}
                />
            </div>
            {modal}
            {tableState.cursor != null &&
                tableState.apiKeysData.length >= 100 &&
                tableState.apiKeysData.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(buttonStyle.primaryButton, buttonStyle.loadMoreButton)}
                        onClick={() => {
                            fetchData(false);
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
        </>
    );
}
