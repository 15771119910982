import * as React from "react";
import { useTranslation } from "react-i18next";

import EsgCalculatorView from "./EsgCalculatorView";
import CustomerEsgDasboard from "components/dashboard/esg/CustomerEsgDashboard";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { SUSTAINABILITY_DASHBOARD_ROUTE } from "components/router/Routes";

export default function CustomerEsgDashboardView(): JSX.Element {
    const { t } = useTranslation();

    return (
        <ApplicationLayout
            viewTitle={t("EsgDashboardView.ourImpact")}
            view={<CustomerEsgDasboard />}
            viewContainer={false}
            mainRoute={SUSTAINABILITY_DASHBOARD_ROUTE}
            viewActions={<EsgCalculatorView />}
        />
    );
}
