import React from "react";
import { useTranslation } from "react-i18next";

import { ConditionAssessmentQuestions, InsuranceConfiguration, Test } from "./BmsCommonInterfaces";
import ConditionAssessmentQuestionsForm from "./diagnostics/ConditionAssessmentQuestionsForm";
import DiagnosticsTestsForm from "./diagnostics/DiagnosticsTestsForm";
import DeviceIdentifierCheckboxes from "./form-components/BmsDeviceIdentifierCheckboxComponent";
import BmsSectionHeader from "./form-components/BmsSectionHeader";
import InputComponent from "./form-components/BmsTextInputComponent";

interface FormValues {
    insuranceConfiguration: InsuranceConfiguration;
    platform: string;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const InsuranceConfigurationForm: React.FC<Props> = ({ formValues, setFormValues }) => {
    const { t } = useTranslation();

    const updateFormValues = (updateFn: (formValues: FormValues) => FormValues) => {
        setFormValues((previousFormValues) => updateFn({ ...previousFormValues }));
    };

    const handleCheckboxChange = (fieldName: string, isChecked: boolean) => {
        updateFormValues((previousFormValues) => {
            const { insuranceConfiguration } = previousFormValues;

            return {
                ...previousFormValues,
                insuranceConfiguration: { ...insuranceConfiguration, [fieldName]: isChecked },
            };
        });
    };

    const handleInputChange = (fieldName: string, value: string) => {
        updateFormValues((previousFormValues) => {
            const { insuranceConfiguration } = previousFormValues;

            return {
                ...previousFormValues,
                insuranceConfiguration: {
                    ...insuranceConfiguration,
                    pricing: {
                        ...insuranceConfiguration.pricing,
                        [fieldName]: value,
                    },
                },
            };
        });
    };

    const handleTestsChange = (tests: Test[]) => {
        updateFormValues((previousFormValues) => {
            const { insuranceConfiguration } = previousFormValues;

            return { ...previousFormValues, insuranceConfiguration: { ...insuranceConfiguration, tests: tests } };
        });
    };

    const handleQuestionsChange = (questions: ConditionAssessmentQuestions) => {
        updateFormValues((previousFormValues) => {
            const { insuranceConfiguration } = previousFormValues;
            const updatedProvider = { ...insuranceConfiguration.provider };

            updatedProvider.questions = questions;
            const updatedConfig = { ...insuranceConfiguration, provider: updatedProvider };

            return { ...previousFormValues, insuranceConfiguration: updatedConfig };
        });
    };

    return (
        <div>
            <BmsSectionHeader labelKey={t("Configuration.common.headers.defaultSettings")} />
            <DeviceIdentifierCheckboxes
                serialMandatory={formValues.insuranceConfiguration.serialMandatory}
                imeiMandatory={formValues.insuranceConfiguration.imeiMandatory}
                onChange={handleCheckboxChange}
            />

            <BmsSectionHeader labelKey={t("Configuration.insuranceConfigurationForm.headers.pricing")} />
            <InputComponent
                labelKey={t("Configuration.leaseConfigurationForm.endpoint")}
                toolTipContent={t(`Configuration.tooltips.common.endpoint`)}
                inputId={"apiUrl"}
                value={formValues.insuranceConfiguration.pricing.apiUrl}
                onChange={(newValue) => handleInputChange("apiUrl", newValue)}
            />
            <InputComponent
                labelKey={t("Configuration.common.apiUsername")}
                toolTipContent={t(`Configuration.tooltips.common.apiUsername`)}
                inputId={"apiUsername"}
                value={formValues.insuranceConfiguration.pricing.apiUsername}
                onChange={(newValue) => handleInputChange("apiUsername", newValue)}
            />
            <InputComponent
                labelKey={t("Configuration.common.apiPassword")}
                toolTipContent={t(`Configuration.tooltips.common.apiPassword`)}
                inputId={"apiPassword"}
                isPassword={true}
                value={formValues.insuranceConfiguration.pricing.apiPassword}
                onChange={(newValue) => handleInputChange("apiPassword", newValue)}
            />

            <BmsSectionHeader
                labelKey={t("Configuration.common.headers.conditionAssessmentQuestions")}
                toolTipContent={t("Configuration.tooltips.common.conditionAssessmentQuestions")}
            />
            <div>
                <ConditionAssessmentQuestionsForm
                    currentQuestions={formValues.insuranceConfiguration.provider?.questions}
                    onQuestionsChange={(questions) => handleQuestionsChange(questions)}
                />
            </div>

            <BmsSectionHeader
                labelKey={t("Configuration.common.headers.diagnosticsTests")}
                toolTipContent={t("Configuration.tooltips.common.diagnosticsTests")}
            />
            <div>
                <DiagnosticsTestsForm
                    currentEnabledTests={formValues.insuranceConfiguration.tests}
                    onTestChange={handleTestsChange}
                    platform={formValues.platform}
                />
            </div>
        </div>
    );
};

export default InsuranceConfigurationForm;
