import classNames from "classnames";
import React, { ChangeEvent } from "react";
import { Placement } from "react-bootstrap/types";
import { connect, ConnectedProps } from "react-redux";

import Info from "components/icons/Info";
import localStyle from "components/licenses/assign-bms-keys/form.scss";
import style from "components/licenses/license-configuration/bms/bms-config.scss";
import Tooltip from "components/tooltip/Tooltip";
import { StoreState } from "store";
import form from "styles/form.scss";

const mapState = (state: StoreState) => ({
    settings: state.reportViewSettings,
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

interface Props {
    labelKey: string;
    checkboxId: string;
    checked: boolean;
    disabled?: boolean;
    toolTipContent?: string;
    placement?: string;
    onChange: (newValue: boolean) => void;
    name?: string;
    className?: string;
}

const BmsCheckboxComponent = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const selectedPlacement: Placement = (props.placement as Placement) || "right-start";
    return (
        <>
            <div className={classNames(props.className ? props.className : style.checkBox)}>
                <span className={classNames(style.checkBoxLabel)}>{props.labelKey}</span>

                <label className={classNames(form.container)}>
                    <input
                        name={props.name}
                        type="checkbox"
                        id={props.checkboxId}
                        checked={props.checked}
                        disabled={props.disabled}
                        className={classNames(form.input)}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => props.onChange(event.target.checked)}
                    />

                    <span className={form.checkmark} />
                    <div className={style.toolTip}>
                        {props.toolTipContent && (
                            <Tooltip content={props.toolTipContent} placement={selectedPlacement}>
                                <div className={localStyle.info} tabIndex={0}>
                                    <Info
                                        borderColor={props.theme.contentBackgroundColor}
                                        color={props.theme.iconFillColor}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </label>
            </div>
        </>
    );
};

export default connector(BmsCheckboxComponent);
