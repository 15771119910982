import * as React from "react";

const BackToTop = (props: { color: string }): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="47"
            height="47"
            viewBox="0 0 47 47"
        >
            <defs>
                <filter id="Path_1173" x="0" y="0" width="47" height="47" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="backToTop" transform="translate(4.5 2.5)">
                <g id="Group_4940" data-name="Group 4940" transform="translate(-717.501 -212.5)">
                    <g transform="matrix(1, 0, 0, 1, 713, 210)" filter="url(#Path_1173)">
                        <path
                            id="Path_1173-2"
                            data-name="Path 1173"
                            d="M171.86,212.13a19,19,0,1,1-19,19,19,19,0,0,1,19-19Z"
                            transform="translate(-148.36 -209.63)"
                            fill={props.color}
                        />
                    </g>
                </g>
                <g id="dropdown" transform="translate(25.5 21.912) rotate(180)">
                    <g id="dropDown-2" data-name="dropDown">
                        <path
                            id="Path_1390"
                            data-name="Path 1390"
                            d="M1390,428.7l6.912,6.25L1390,441.2"
                            transform="translate(441.204 -1390) rotate(90)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="2"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default BackToTop;
