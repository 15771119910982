import classNames from "classnames";
import React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./overviewDashboardWidgetMenuItems.scss";
import ManageWidgetDialog from "components/dashboard/overview/ManageWidgetDialog";
import Delete from "components/icons/Delete";
import Edit from "components/icons/Edit";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import Tooltip from "components/tooltip/Tooltip";
import { OverviewDashboardData } from "domain/overviewDashboard";
import { overviewDashboardService } from "services/dashboard/OverviewDashboardService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

interface Result {
    title: string;
    message: string;
    resultVisible: boolean;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const OverviewDashboardWidgetMenuItems = (
    props: ConnectedProps<typeof connector> & {
        onDashboardUpdated: (updatedDashboardIndex: number) => void;
        updatedDashboardIndex: number;
        dashboardData: OverviewDashboardData;
        uuid: string;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const [deleteWidgetModalVisible, setDeleteWidgetModalVisible] = React.useState<boolean>(false);
    const [manageWidgetModalVisibility, setManageWidgetModalVisibility] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [result, setResult] = React.useState<Result>({
        title: "",
        message: "",
        resultVisible: false,
    });
    const [widgetName, setWidgetName] = React.useState<string>("");
    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    React.useEffect(() => {
        const widget = props.dashboardData.widgets.find((widget) => widget.uuid === props.uuid);
        setWidgetName(widget ? widget.name : "");
    }, []);

    const deleteWidget = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        setLoading(true);
        const dashboardData = {
            ...props.dashboardData,
            widgets: props.dashboardData.widgets.filter((widget) => widget.uuid != props.uuid),
        };
        overviewDashboardService
            .saveWidget(dashboardData, abortController)
            .then(() => {
                setResult({
                    title: t("Overview.deleteWidgetDialog.resultDialog.title"),
                    message: t("Overview.deleteWidgetDialog.resultDialog.message", {
                        widgetName: widgetName,
                    }),
                    resultVisible: true,
                });
            })
            .catch(() => {
                setResult({
                    title: t("Overview.deleteWidgetDialog.resultDialog.errorDialogTitle"),
                    message: t("Overview.deleteWidgetDialog.resultDialog.errorDialogDescription"),
                    resultVisible: true,
                });
            })
            .finally(() => {
                setDeleteWidgetModalVisible(false);
            });
    };

    const closeDeleteWidgetResultDialog = () => {
        setResult({ title: "", message: "", resultVisible: false });
        props.onDashboardUpdated(props.updatedDashboardIndex + 1);
    };

    return (
        <div className={style.widgetIcon}>
            <Menu className={style.kebabMenu}>
                <ul>
                    <li>
                        <Tooltip content={t("Common.delete")}>
                            <MenuItem
                                onClick={() => {
                                    setDeleteWidgetModalVisible(true);
                                }}
                            >
                                <Delete color={props.theme.iconFillColor} />
                            </MenuItem>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip content={t("Common.edit")}>
                            <MenuItem onClick={() => setManageWidgetModalVisibility(true)}>
                                <Edit color={props.theme.iconFillColor} />
                            </MenuItem>
                        </Tooltip>
                    </li>
                </ul>
            </Menu>
            <Modal
                isOpen={deleteWidgetModalVisible}
                hideModal={() => setDeleteWidgetModalVisible(false)}
                modalTitle={t("Overview.deleteWidgetDialog.title", { widgetName: widgetName })}
            >
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <div>
                            <div>{t("Overview.deleteWidgetDialog.message", { widgetName: widgetName })}</div>
                            <div>{t("Overview.deleteWidgetDialog.question")}</div>
                        </div>

                        <div className={style.deleteDialogButton}>
                            <button
                                className={classNames(buttons.secondaryButton, buttons.medium)}
                                onClick={() => setDeleteWidgetModalVisible(false)}
                            >
                                {t("Common.cancel")}
                            </button>
                            <button
                                className={classNames(buttons.secondaryButton, buttons.medium)}
                                onClick={deleteWidget}
                            >
                                {t("Overview.deleteWidgetDialog.delete")}
                            </button>
                        </div>
                    </>
                )}
            </Modal>
            <Modal isOpen={result.resultVisible} hideModal={closeDeleteWidgetResultDialog} modalTitle={result.title}>
                <div>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium)}
                        onClick={closeDeleteWidgetResultDialog}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
            <ManageWidgetDialog
                title={t("Overview.manageWidgetDialog.editWidgetTitle")}
                visible={manageWidgetModalVisibility}
                dashboardData={props.dashboardData}
                onShowModal={setManageWidgetModalVisibility}
                dashboardIndex={props.updatedDashboardIndex}
                onDashboardUpdated={props.onDashboardUpdated}
                widgetUuid={props.uuid}
            />
        </div>
    );
};

export default connector(OverviewDashboardWidgetMenuItems);
