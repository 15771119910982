import * as React from "react";
import { useTranslation } from "react-i18next";

import ApplicationLayout from "components/layout/ApplicationLayout";
import { SUPPORT_ROUTE } from "components/router/Routes";
import ApiGuideView from "components/support/api-guide/ApiGuideView";

export default function ApiDocumentationView(): JSX.Element {
    const { t } = useTranslation();
    return <ApplicationLayout viewTitle={t("Routes.apiGuide")} view={<ApiGuideView />} mainRoute={SUPPORT_ROUTE} />;
}
