import classNames from "classnames";
import * as React from "react";
import "react-grid-layout/css/styles.css";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import "react-resizable/css/styles.css";
import { v4 as uuid } from "uuid";

import style from "components/dashboard/overview/overviewDashboard.scss";
import OverviewDashboardContainer from "components/dashboard/overview/OverviewDashboardContainer";
import EmptyStateIcon from "components/icons/EmptyState";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { CDW_NUMBER_OF_COLUMNS, CDW_TOTAL_WIDGETS } from "domain/globalConstants";
import { OverviewDashboardData, Widget } from "domain/overviewDashboard";
import { overviewDashboardService } from "services/dashboard/OverviewDashboardService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const OverviewDashboard = (props: ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [dashboardData, setDashboardData] = React.useState<OverviewDashboardData>({} as OverviewDashboardData);
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [count, setCount] = React.useState(1);
    const [widgets, setWidgets] = React.useState<Widget[]>([]);

    const createWidgets = (dashboardData: OverviewDashboardData): Widget[] => {
        const widgets: Widget[] = [];
        let x;
        let y;
        const height = 10;
        if (dashboardData.widgets.length > 0) {
            const slotIndexes = Array.from(Array(CDW_TOTAL_WIDGETS).keys());
            dashboardData.widgets.map((widget) => {
                if (widget.slotIndex != undefined) {
                    slotIndexes.splice(slotIndexes.indexOf(widget.slotIndex), 1);
                }
            });
            dashboardData.widgets = dashboardData.widgets.map((widget) => {
                if (widget.slotIndex === undefined) {
                    const slotIndex = slotIndexes[0];
                    slotIndexes.splice(slotIndexes.indexOf(slotIndex), 1);
                    return { ...widget, slotIndex: slotIndex };
                } else {
                    return widget;
                }
            });
        }

        for (let i = 0; i < CDW_TOTAL_WIDGETS; i++) {
            x = i % CDW_NUMBER_OF_COLUMNS;
            y = Math.trunc(i / CDW_NUMBER_OF_COLUMNS);
            const widget = dashboardData.widgets.find((element) => element.slotIndex === i);
            if (widget === undefined) {
                widgets.push({
                    slotIndex: i,
                    uuid: uuid(),
                    name: "",
                    description: "",
                    type: "CREATE_CHART",
                    x: x,
                    y: y,
                    width: 1,
                    height: height,
                    aggregationQuery: {
                        type: "TIME_AGGREGATED_DOCUMENT_COUNT",
                        timeRangeType: "STATIC",
                        startTime: "",
                        endTime: "",
                        interval: "MONTH",
                        matchers: [
                            {
                                context: "ERASURE",
                                path: "",
                                value: "",
                            },
                        ],
                        scopeMatchers: [
                            {
                                context: "ERASURE",
                                path: "",
                                value: "",
                            },
                        ],
                    },
                });
            } else {
                widget && widgets.push({ ...widget, x: x, y: y });
            }
        }
        return widgets;
    };

    const fetchDashboardData = (abortController: AbortController) => {
        setLoading(true);
        overviewDashboardService
            .fetchDashboardData(abortController)
            .then((data) => {
                setWidgets(createWidgets(data));
                setDashboardData(data);
                setLoading(false);
            })
            .catch((error: Error) => {
                try {
                    const response = JSON.parse(error.message);
                    if (response.error.code === "NOT_FOUND") {
                        overviewDashboardService
                            .initializeOverviewDashboard()
                            .then((response) => {
                                setWidgets(createWidgets(response));
                                setDashboardData(response);
                            })
                            .catch(() => {
                                setErrorMessage(t("Overview.errorMessage"));
                            });
                    } else {
                        setErrorMessage(t("Overview.errorMessage"));
                    }
                } catch {
                    setErrorMessage(t("Overview.errorMessage"));
                }
                setLoading(false);
            });
    };

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchDashboardData(abortController);

        return () => {
            abortController.abort();
        };
    }, [count]);
    return errorMessage ? (
        <div className={style.errorMessage}>{errorMessage}</div>
    ) : loading ? (
        <LoadingIndicator />
    ) : (
        <div>
            {Object.keys(dashboardData).length == 0 || widgets.length == 0 ? (
                <div className={style.emptyStateMessage}>
                    <EmptyStateIcon
                        ellipseBackgroundColor={props.theme.emptyStateEllipseBackgroundColor}
                        biggestCircleBackgroundColor={props.theme.emptyStateBiggestCircleBackgroundColor}
                        smallestCircleBackgroundColor={props.theme.emptyStateSmallestCircleBackgroundColor}
                        personBackgroundColor={props.theme.emptyStatePersonColor}
                        personShirtColor={props.theme.emptyStatePersonShirtColor}
                        personSleeveColor={props.theme.emptyStatePersonShirtColor}
                        ellipseBackgroundOpacity={props.theme.emptyStateEllipseBackgroundOpacity}
                        biggestCircleBackgroundOpacity={props.theme.emptyStateBiggestCircleBackgroundOpacity}
                        smallestCircleBackgroundOpacityFirst={
                            props.theme.emptyStateSmallestCircleBackgroundOpacityFirst
                        }
                        smallestCircleBackgroundOpacitySecond={
                            props.theme.emptyStateSmallestCircleBackgroundOpacitySecond
                        }
                        smallestCircleBackgroundOpacityThird={
                            props.theme.emptyStateSmallestCircleBackgroundOpacityThird
                        }
                    />
                    <div>{t("Overview.emptyStateMessage")}</div>
                    <button type="button" className={classNames(buttons.primaryButton)} hidden={true}>
                        {t("Overview.createChart")}
                    </button>
                </div>
            ) : (
                <>
                    <div>
                        <OverviewDashboardContainer
                            overviewDashboardData={dashboardData}
                            onDashboardUpdated={setCount}
                            updatedDashboardIndex={count}
                            widgets={widgets}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default connector(OverviewDashboard);
