import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import EsgCalculatorView from "./EsgCalculatorView";
import EsgDashboard from "components/dashboard/esg/EsgDashboard";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { OUR_IMPACT_DASHBOARD_ROUTE, SUSTAINABILITY_DASHBOARD_ROUTE } from "components/router/Routes";
import { StoreState } from "store";

const connector = connect((state: StoreState) => ({
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
    // lang redux state here is used to provide info to dashboard widgets about language being changed on UI,
    // so they will re-render to translate months using "generateMonthNames"-function in CommonFunctions.ts
    lang: state.languageReducer.lang,
}));

export function EsgDashboardView(props: ConnectedProps<typeof connector>): JSX.Element {
    const { t } = useTranslation();
    const viewActions = OUR_IMPACT_DASHBOARD_ROUTE.isAccessibleTo(props.tenantDetails) ? (
        <EsgCalculatorView />
    ) : undefined;

    return (
        <ApplicationLayout
            viewTitle={t("EsgDashboardView.blanccosImpact")}
            view={<EsgDashboard />}
            viewContainer={false}
            mainRoute={SUSTAINABILITY_DASHBOARD_ROUTE}
            viewActions={viewActions}
        />
    );
}

export default connector(EsgDashboardView);
