import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import { ErrorModal } from "./ErrorModal";
import { ForgotPasswordModal } from "./ForgotPasswordModal";
import loginStyle from "./login.scss";
import LoginForm, { FormValues, hidePasswordLoginSection } from "./LoginForm";
import Export from "components/icons/Export";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import modalStyle from "components/modal/modal.scss";
import { DASHBOARD_ROUTE } from "components/router/Routes";
import Heading from "components/typography/heading/Heading";
import { LoginMethod } from "domain/tenants";
import { UserDetails } from "domain/user";
import { apiGatewayService } from "services/api/ApiGatewayService";
import { setDataCollection } from "services/settings/settingsRepository";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { clearAllTenantDetails, pushTenantDetails } from "store/tenantDetails";
import { setUser } from "store/user";
import buttonsStyle from "styles/buttons.scss";
import form from "styles/form.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

const MAP_STATE = (state: StoreState) => ({
    authenticated: state.userReducer.user,
    theme: state.themeReducer.theme,
});
const MAP_DISPATCH = { setUser, pushTenantDetails, clearAllTenantDetails };
const CONNECTOR = connect(MAP_STATE, MAP_DISPATCH);
const USER_DISABLED_MESSAGE = "User disabled";
const ACCOUNT_LOCKOUT_MESSAGE = "Error account lockout";
const UNABLE_TO_FETCH = "Unable to retrieve user data";

enum ViewState {
    SHOW_LOGIN_FORM,
    SHOW_LOADING_INDICATOR,
    REQUIRE_EULA_ACCEPTANCE,
    REDIRECT_TO_APPLICATION,
}

interface LoginDetailsDto {
    login_url: string;
    login_method: LoginMethod;
}

export interface LoginDetails {
    loginUrl: string;
    loginMethod: LoginMethod;
}

function toLoginDetails(dto: LoginDetailsDto): LoginDetails {
    return {
        loginUrl: dto.login_url,
        loginMethod: dto.login_method,
    };
}

const LoginView = (props: ConnectedProps<typeof CONNECTOR>) => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    // The user might be already authenticated when entering this view, if that is the case then redirects directly
    // to the application. Otherwise goes through the normal login flow.
    const [viewState, setViewState] = React.useState<ViewState>(
        props.authenticated ? ViewState.REDIRECT_TO_APPLICATION : ViewState.SHOW_LOGIN_FORM
    );
    const [scrolledToBottom, setScrollToBottom] = React.useState<boolean>(false);
    const [acceptEula, setAcceptEula] = React.useState<boolean>(false);
    const [userDetails, setUserDetails] = React.useState<UserDetails | null>(null);
    const iframeRef = React.useRef<HTMLIFrameElement>(null);
    const query = new URLSearchParams(useLocation().search);
    const ssoAuthorizationCode = query.get("code");
    const [forgotPasswordLinkHidden, setForgotPasswordLinkHidden] = React.useState<boolean>(false);
    const handleAuthenticationResponse = (user: UserDetails) => {
        setUserDetails(user);
        setViewState(
            user.eulaAcceptedDate &&
                Date.parse(user.eulaAcceptedDate) >= Date.parse(process.env.EULA_RELEASE_DATE as string)
                ? ViewState.REDIRECT_TO_APPLICATION
                : ViewState.REQUIRE_EULA_ACCEPTANCE
        );
        setDataCollection(user.usageStatistics);
    };

    const handleAuthenticationResponseError = (message: string) => {
        if (message === USER_DISABLED_MESSAGE) {
            setUserDisabledVisible(true);
        } else if (message === UNABLE_TO_FETCH) {
            setGenericErrorVisible(true);
        } else if (message === ACCOUNT_LOCKOUT_MESSAGE) {
            setErrorMessage(t("LoginForm.accountLockoutError"));
        } else {
            setErrorMessage(t("LoginForm.incorrectCredentials"));
        }
    };

    const loginSubmitHandler = (values: FormValues) => {
        setViewState(ViewState.SHOW_LOADING_INDICATOR);
        userSessionService
            .login(values.email, values.password)
            .then((user: UserDetails) => {
                handleAuthenticationResponse(user);
            })
            .catch((e) => {
                let error_message = e;
                try {
                    const response = JSON.parse(e.message);
                    logger.error("Authentication failed", values.email, response.error);
                    error_message = response.error.message;
                } finally {
                    handleAuthenticationResponseError(error_message);
                    setViewState(ViewState.SHOW_LOGIN_FORM);
                }
            });
    };
    const ssoConfigurationCheckHandler = (email: FormValues["email"]) => {
        return apiGatewayService
            .invokeApi("/authentication/sso/check?username=" + encodeURIComponent(email), "GET")
            .then((responseDto: LoginDetailsDto) => {
                const response = toLoginDetails(responseDto);
                setForgotPasswordLinkHidden(hidePasswordLoginSection(response));
                return response;
            });
    };
    const onAcceptEulaClicked = () => {
        setAcceptEula(!acceptEula);
    };
    const onContinueClicked = () => {
        if (acceptEula) {
            setViewState(ViewState.REDIRECT_TO_APPLICATION);
            // Fire-and-forget style invocation. Doesn't keep the user from logging in even if this call fails.
            apiGatewayService.invokeApi("/eula/accept", "post").then(() => {
                userSessionService.fetchUserDetails().then((user: UserDetails) => {
                    storeAndUpdateUserDetails(user);
                });
            });
        }
    };

    const storeAndUpdateUserDetails = (user: UserDetails) => {
        userSessionService.storeUser(user);
        props.setUser(user);
        props.clearAllTenantDetails();
        props.pushTenantDetails({
            uuid: user.tenantUuid,
            featureLicenses: user.featureLicenses,
            type: user.tenantType,
            region: user.region,
            tenantName: user.username.split("@")[0],
            loginMethod: user.tenantLoginMethod,
            tenantTier: user.tier,
            licensingModel: user.licensingModel,
        });
    };
    const handleSsoAuthentication = () => {
        if (ssoAuthorizationCode && ssoAuthorizationCode.trim() !== "") {
            setViewState(ViewState.SHOW_LOADING_INDICATOR);
            userSessionService
                .ssoLogin(ssoAuthorizationCode)
                .then((user: UserDetails) => {
                    handleAuthenticationResponse(user);
                })
                .catch((e) => {
                    logger.error("SSO authentication failed", e.message);
                    try {
                        const response = JSON.parse(e.message);
                        handleAuthenticationResponseError(response.error.message);
                    } catch (e) {
                        setGenericErrorVisible(true);
                    }
                    setViewState(ViewState.SHOW_LOGIN_FORM);
                });
        }
    };

    React.useEffect(() => {
        if (userDetails) {
            if (viewState === ViewState.REDIRECT_TO_APPLICATION) {
                storeAndUpdateUserDetails(userDetails);
            }
        }
    }, [viewState]);

    React.useEffect(() => {
        handleSsoAuthentication();
    }, []);

    React.useEffect(() => {
        if (viewState !== ViewState.REQUIRE_EULA_ACCEPTANCE && iframeRef.current == null) {
            return;
        }

        const handleScrolling = () => {
            const iframeDocument = iframeRef.current?.contentWindow?.document.documentElement;

            if (
                iframeDocument?.scrollHeight == null ||
                iframeDocument?.scrollTop == null ||
                iframeDocument?.clientHeight == null
            ) {
                return;
            }
            const atBottom = iframeDocument.scrollHeight - iframeDocument.scrollTop - iframeDocument.clientHeight <= 10;

            if (!scrolledToBottom) {
                setScrollToBottom(atBottom);
            }
        };

        iframeRef.current?.contentWindow?.addEventListener("scroll", handleScrolling);

        return () => {
            iframeRef.current?.contentWindow?.removeEventListener("scroll", handleScrolling);
        };
    }, [viewState]);

    const [forgotVisible, setForgotVisible] = React.useState<boolean>(false);
    const [userDisabledVisible, setUserDisabledVisible] = React.useState<boolean>(false);
    const [genericErrorVisible, setGenericErrorVisible] = React.useState<boolean>(false);
    switch (viewState) {
        case ViewState.SHOW_LOGIN_FORM:
            return (
                <div className={loginStyle.viewContainer}>
                    <div className={loginStyle.formContainer}>
                        <div className={loginStyle.formPanel}>
                            <div className={loginStyle.logo} />
                            <Heading tag="h1" className={loginStyle.header}>
                                {t("Common.loginTitle")}
                            </Heading>
                            <LoginForm
                                submitHandler={loginSubmitHandler}
                                loginErrorMessage={errorMessage}
                                ssoConfigurationCheckHandler={ssoConfigurationCheckHandler}
                            />
                            <div className={loginStyle.forgotPasswordLinkContainer} hidden={forgotPasswordLinkHidden}>
                                <a
                                    onClick={() => setForgotVisible(true)}
                                    className={loginStyle.forgotPasswordLink}
                                    data-testid={testIds.login.forgotPassword.forgotPasswordButton}
                                >
                                    {t("Login.forgotPasswordLink")}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={loginStyle.imageContainer}>
                        <div className={loginStyle.downloadMobileAppContainer}>
                            <h1>{t("Login.downloadMobileApp.title")}</h1>
                            <div>
                                <div className={loginStyle.downloadQrCode} />
                                <div className={loginStyle.downloadMobileAppDescriptionAndLinks}>
                                    <div>{t("Login.downloadMobileApp.description")}</div>
                                    <div className={loginStyle.storeLinks}>
                                        <div>
                                            <a
                                                href="https://apps.apple.com/us/app/blancco-management-portal/id1633229601"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1663113600&h=35000566bea0a33996078ade9becc199"
                                                    alt="Download on the App Store"
                                                    className={loginStyle.appStoreLink}
                                                />
                                            </a>
                                        </div>
                                        <div>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.blancco.android.portal"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    alt="Get it on Google Play"
                                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                                    className={loginStyle.googlePlayLink}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ForgotPasswordModal hide={() => setForgotVisible(false)} visible={forgotVisible} />
                    <ErrorModal
                        hide={() => setUserDisabledVisible(false)}
                        visible={userDisabledVisible}
                        title={"Login.userDisabledModal.title"}
                        description={"Login.userDisabledModal.description"}
                    />
                    <ErrorModal
                        hide={() => setGenericErrorVisible(false)}
                        visible={genericErrorVisible}
                        title={"Login.failureModal.title"}
                        description={"Login.failureModal.description"}
                    />
                </div>
            );
        case ViewState.SHOW_LOADING_INDICATOR:
            return <LoadingIndicator />;
        case ViewState.REQUIRE_EULA_ACCEPTANCE:
            return (
                <div className={loginStyle.eulaBackgroundImage}>
                    <div className={loginStyle.blanccoLogo} />
                    <div className={classNames(loginStyle.eulaModalWrapper, modalStyle.modalAfterOpen)}>
                        <label>{t("Login.acceptEulaModal.title")}</label>
                        <div className={loginStyle.eulaActionsContainer}>
                            <Export color={props.theme.linkTextColor} />
                            <a
                                href="/public/eula/eula.pdf"
                                target="_blank"
                                data-testid={testIds.login.eula.downloadPdfLink}
                            >
                                {t("Login.acceptEulaModal.downloadPdf")}
                            </a>
                        </div>
                        <div className={loginStyle.eulaDivContainer}>
                            <iframe
                                ref={iframeRef}
                                src="/public/eula/eula.html"
                                scrolling="yes"
                                className={loginStyle.eulaContainer}
                                data-testid={testIds.login.eula.contentIframe}
                            />
                        </div>
                        <div className={loginStyle.acceptEulaCheckbox}>
                            <label className={form.container}>
                                <input
                                    type="checkbox"
                                    checked={acceptEula}
                                    className={form.input}
                                    onClick={onAcceptEulaClicked}
                                    data-testid={testIds.login.eula.acceptEulaCheckbox}
                                />
                                <span className={form.checkmark} />
                            </label>
                            <label>{t("Login.acceptEulaModal.agreeLabel")}</label>
                        </div>
                        <div>
                            <button
                                className={
                                    acceptEula && scrolledToBottom
                                        ? buttonsStyle.primaryButton
                                        : buttonsStyle.disabledButton
                                }
                                disabled={!acceptEula || !scrolledToBottom}
                                onClick={onContinueClicked}
                                data-testid={testIds.login.eula.continueButton}
                            >
                                {t("Login.acceptEulaModal.continueButton")}
                            </button>
                        </div>
                    </div>
                </div>
            );
        case ViewState.REDIRECT_TO_APPLICATION:
            return (
                <Redirect
                    to={
                        userDetails?.default_views.default_view
                            ? userDetails?.default_views.default_view
                            : DASHBOARD_ROUTE.path
                    }
                />
            );
    }
};

export default CONNECTOR(LoginView);
