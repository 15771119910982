// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!lato-font/css/lato-font.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "._3cJRelMfsTtPAHEhfmeiPX{height:100%;width:100%}._3cJRelMfsTtPAHEhfmeiPX ._3NmS_2OsFk71bm3oznbonM{width:90%;text-align:center}._3cJRelMfsTtPAHEhfmeiPX ._3NmS_2OsFk71bm3oznbonM ._1wVhuAJQGvMQuercm7-rRm{padding:40px 0 10px 40px;line-height:40px;font-size:32px;font-weight:900;text-align:center}._3cJRelMfsTtPAHEhfmeiPX ._3NmS_2OsFk71bm3oznbonM .xEFsmqMqCq8kl-w5fvGG9{padding:10px 10px 0px 40px;line-height:28px;font-size:20px;text-align:center}._3cJRelMfsTtPAHEhfmeiPX ._3NmS_2OsFk71bm3oznbonM .qMFex34ovz0yyVylb4PIs{padding:10px 10px 0px 10px;line-height:28px;font-size:20px;font-weight:600;text-align:center}._3cJRelMfsTtPAHEhfmeiPX ._3NmS_2OsFk71bm3oznbonM ._3wHUgla5xXvPdIKwZpVk6p{padding:0 0 0 20px;line-height:30px;font-size:18px;text-align:left}._3cJRelMfsTtPAHEhfmeiPX ._3NmS_2OsFk71bm3oznbonM ._3oMCeCRzQ_nTRTGieJXeNn{padding:10px 0 0 40px;line-height:20px;font-size:12px;text-align:center}._3cJRelMfsTtPAHEhfmeiPX ._3NmS_2OsFk71bm3oznbonM ._3fRkszki9hplNmKu4JTy0C{background-color:#f7f7f7;padding:20px;margin:20px 0 20px 40px;border-radius:5px}._3cJRelMfsTtPAHEhfmeiPX ._3NmS_2OsFk71bm3oznbonM ._3fRkszki9hplNmKu4JTy0C text{text-align:center;line-height:30px;font-size:18px}._3cJRelMfsTtPAHEhfmeiPX ._3NmS_2OsFk71bm3oznbonM ._1mraZ1fFPVZRfPPTy3NGgA{padding:40px 0 20px 40px;font-size:24px;font-weight:600;text-align:left;color:#385cad}._3cJRelMfsTtPAHEhfmeiPX ._3NmS_2OsFk71bm3oznbonM ._1WaCarMnjWG2icS5cmmhjA{margin-bottom:20px;position:absolute;bottom:0;width:90%}._3cJRelMfsTtPAHEhfmeiPX ._3NmS_2OsFk71bm3oznbonM ._1WaCarMnjWG2icS5cmmhjA button{width:100%;max-width:380px;background-color:#3b5dab}._3cJRelMfsTtPAHEhfmeiPX .a7YHO1BlgSphxcTdhqIX_{font-family:\"Lato Medium\",Arial,Helvetica,sans-serif;font-size:12px;line-height:18px;position:absolute;bottom:0;text-align:center;width:100%;height:40px;color:#000}._2yCpliqSkL1cEgyYHHfaqH{margin:20px;font-size:22px;font-family:\"Roboto Medium\";line-height:36px;color:#000;text-align:center}", ""]);
// Exports
exports.locals = {
	"breakpointSm": "580px",
	"breakpointMd": "768px",
	"breakpointLg": "992px",
	"breakpointXl": "1200px",
	"breakpointXxl": "1600px",
	"whiteColor": "#fff",
	"activeBackgroundColor": "#476c86",
	"activeForegroundColor": "#476c86",
	"primaryButtonBackgroundColor": "#476c86",
	"secondaryButtonForegroundColor": "#91b9f0",
	"bannerBackgroundColor": "rgba(71,108,134,.5)",
	"linkTextColor": "#5f96bc",
	"primaryButtonBackgroundHoverColor": "#5f96bc",
	"secondaryButtonBackgroundHoverColor": "#4c4c4c",
	"destructiveButtonBackgroundHoverColor": "#f46464",
	"primaryButtonBackgroundActiveColor": "#222",
	"secondaryButtonBackgroundActiveColor": "#222",
	"destructiveButtonBackgroundColor": "#f09191",
	"chartNegativeColor": "#f09191",
	"chartPositiveColor": "#5fe3a1",
	"chartNeutralColor": "#cecece",
	"errorBackgroundColor": "#f46464",
	"errorBorderColor": "#f46464",
	"errorTextColor": "#f46464",
	"errorWrapperBackgroundColor": "#f2c5c5",
	"warningBackgroundColor": "#e47530",
	"warningBorderColor": "#e47530",
	"warningTextColor": "#e47530",
	"secondaryWarningBackgroundColor": "#ffc915",
	"secondaryWarningBorderColor": "#ffc915",
	"secondaryWarningTextColor": "#ffc915",
	"successBorderColor": "#5fe3a1",
	"successTextColor": "#5fe3a1",
	"pageBackgroundColor": "#222",
	"wrapperBackgroundColor": "#dfe4f2",
	"wrapperBorderColor": "#a1b0d5",
	"modelBoxBackgroundColor": "#4c4c4c",
	"contentBackgroundColor": "#333",
	"secondaryButtonBackgroundColor": "#333",
	"textColor": "#fff",
	"primaryButtonForegroundColor": "#fff",
	"bannerForegroundColor": "#4c4c4c",
	"chartOutlineColor": "#222",
	"modalOverlayColor": "rgba(34,34,34,.4)",
	"contentDividerColor": "#111",
	"contentBorderColor": "#111",
	"nestedContentBorderColor": "#cecece",
	"disabledButtonBackgroundColor": "#cecece",
	"disabledButtonForegroundColor": "#4c4c4c",
	"inputHintColor": "#cecece",
	"inputBackgroundColor": "#4c4c4c",
	"hoverBackgroundColor": "#111",
	"toggleCheckedBackgroundColor": "#91b9f0",
	"toggleUncheckedBackgroundColor": "#cecece",
	"toggleForegroundColor": "#fff",
	"mapForegroundColor": "#f0f0f0",
	"mapBackgroundColor": "#222",
	"emptyStateEllipseBackgroundColor": "#494949",
	"emptyStateBiggestCircleBackgroundColor": "#484848",
	"emptyStateSmallestCircleBackgroundColor": "#4b4b4b",
	"emptyStatePersonColor": "#4a4a52",
	"emptyStatePersonShirtColor": "#91b9f0",
	"emptyStatePersonSleeveColor": "#3b5dab",
	"emptyStateEllipseBackgroundOpacity": ".599",
	"emptyStateBiggestCircleBackgroundOpacity": ".197",
	"emptyStateSmallestCircleBackgroundOpacityFirst": ".401",
	"emptyStateSmallestCircleBackgroundOpacitySecond": ".602",
	"emptyStateSmallestCircleBackgroundOpacityThird": ".846",
	"chartFirstSet": "#708add",
	"chartSecondSet": "#dd75a0",
	"chartThirdSet": "#4a7ab6",
	"chartForthSet": "#5f96bc",
	"chartFifthSet": "#c4874a",
	"chartSixthSet": "#b63e5c",
	"chartSeventhSet": "#6c8fa9",
	"chartEighthSet": "#f3a14e",
	"chartNinthSet": "#88bbdb",
	"chartTenthSet": "#e2afc3",
	"chartEleventhSet": "#b1bfde",
	"chartTwelfthSet": "#facf96",
	"optional": "#fff",
	"disabledTextColor": "#4c4c4c",
	"colorPickerBlack": "#000",
	"iconFillColor": "#91b9f0",
	"sortIconRegularColor": "#cecece",
	"sortIconHoverColor": "#5f96bc",
	"sortIconActiveColor": "#fff",
	"calendarRangeBackgroundColor": "#91b9f0",
	"selectedTabColor": "#91b9f0",
	"basicTierBannerColor": "rgba(188,132,74,.0980392157)",
	"basicTierBannerBorderColor": "#9d6f3f",
	"premiumTierBannerColor": "rgba(107,149,184,.0980392157)",
	"premiumTierBannerBorderColor": "#6b95b8",
	"diamondTierBannerColor": "rgba(181,78,119,.0980392157)",
	"diamondTierBannerBorderColor": "#b54e77",
	"starTierBannerBorderColor": "#8f61ca",
	"chartSuccessColor": "#009e57",
	"chartFailColor": "#db3737",
	"notVerified": "#708add",
	"textFieldColor": "#111",
	"focusOutlineColorInput": "#3b5dab",
	"focusOutlineColorButton": "#d661e5",
	"tooltipTextColor": "#4c4c4c",
	"tooltipBackgroundColor": "#f0f0f0",
	"chipBackgroundColor": "#4c4c4c",
	"chipContentColor": "#fff",
	"chipUserIconColor": "#111",
	"successIconColor": "#5fe3a1",
	"errorIconColor": "#f09191",
	"localBannerBackgroundColor": "#33405f",
	"localBannerBorderColor": "#2d2d2d",
	"errorBannerBackgroundColor": "#fdf4f4",
	"localBannerTextColor": "#fff",
	"successBadgeBackgroundColor": "#006f1f",
	"successBadgeTextColor": "#e5f2e9",
	"warningBadgeBackgroundColor": "#ffda5c",
	"warningBadgeTextColor": "#715907",
	"errorBadgeBackgroundColor": "#9c2a2a",
	"errorBadgeTextColor": "#fff",
	"infoBadgeBackgroundColor": "#374f86",
	"infoBadgeTextColor": "#fff",
	"infoIconBackgroundColor": "#f0f0f0",
	"neutralBadgeBackgroundColor": "#606060",
	"neutralBadgeTextColor": "#fff",
	"strokeColor": "#364c7d",
	"textHighlightForegroundColor": "linear-gradient(0deg, rgba(255, 201, 21, 0.3) 0%, rgba(255, 201, 21, 0.3) 100%)",
	"textHighlightBackgroundColor": "#fff",
	"skeletonLoaderBackgroundColor": "#222",
	"firstLevelTierBackgroundColor": "#fff6ee",
	"secondLevelTierBackgroundColor": "#f2faff",
	"thirdLevelTierBackgroundColor": "#fff8fb",
	"fourthLevelTierBackgroundColor": "#8f61ca",
	"defaultStarColor": "#007b22",
	"userDefaultStarColor": "#cecece",
	"addOnsCheckmarkColor": "#5fe3a1",
	"warningBannerBackgroundColor": "#ffd95b",
	"warningBannerBorderColor": "#fff1c1",
	"warningBannerTextColor": "#111",
	"warningIconBackgroundColor": "#111",
	"warningIconExclamationColor": "#ffc915",
	"hoverColor": "rgba(71,108,134,.3)",
	"conditionToggleButtonBackgroundColor": "#222",
	"conditionToggleButtonBorderColor": "#4c4c4c",
	"conditionTableBackgroundColor": "#222",
	"conditionTableBorderColor": "#4c4c4c",
	"conditionTableNestedBackgroundColor": "#111",
	"defaultTheme": "defaultTheme",
	"tenantTheme": "tenantTheme",
	"defaultDarkTheme": "defaultDarkTheme",
	"tenantDarkTheme": "tenantDarkTheme",
	"subTenantTheme": "subTenantTheme",
	"subTenantDarkTheme": "subTenantDarkTheme",
	"iosPage": "_3cJRelMfsTtPAHEhfmeiPX",
	"container": "_3NmS_2OsFk71bm3oznbonM",
	"title": "_1wVhuAJQGvMQuercm7-rRm",
	"message": "xEFsmqMqCq8kl-w5fvGG9",
	"validation": "qMFex34ovz0yyVylb4PIs",
	"steps": "_3wHUgla5xXvPdIKwZpVk6p",
	"note": "_3oMCeCRzQ_nTRTGieJXeNn",
	"missing": "_3fRkszki9hplNmKu4JTy0C",
	"installMessage": "_1mraZ1fFPVZRfPPTy3NGgA",
	"buttonContainer": "_1WaCarMnjWG2icS5cmmhjA",
	"footer": "a7YHO1BlgSphxcTdhqIX_",
	"error": "_2yCpliqSkL1cEgyYHHfaqH"
};
module.exports = exports;
