import * as React from "react";

const Exit = (props: { color: string }): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.142" height="14.142" viewBox="0 0 14.142 14.142">
            <g id="ExitDarkMode" transform="translate(1.414 1.414)">
                <line
                    id="Line_17"
                    data-name="Line 17"
                    y2="16"
                    transform="translate(0 0) rotate(-45)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_18"
                    data-name="Line 18"
                    y2="16"
                    transform="translate(11.314 0) rotate(45)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};

export default Exit;
