// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1NqHTaifjVjjrwjARNBqme{min-width:460px;max-width:900px;min-height:100px;display:flex;flex-direction:column;align-content:stretch;margin-top:20px}._1NqHTaifjVjjrwjARNBqme ._1h9Rrr4fGTpefHTinEFbsv{margin-bottom:20px}._1NqHTaifjVjjrwjARNBqme ._1h9Rrr4fGTpefHTinEFbsv div{display:inline;margin-right:5px}._1NqHTaifjVjjrwjARNBqme ._3y9xUpOWCskvgU8uP_0LYc{min-width:88px;margin-top:20px}", ""]);
// Exports
exports.locals = {
	"breakpointSm": "580px",
	"breakpointMd": "768px",
	"breakpointLg": "992px",
	"breakpointXl": "1200px",
	"breakpointXxl": "1600px",
	"failedFilesViewWrapper": "_1NqHTaifjVjjrwjARNBqme",
	"introductionLabel": "_1h9Rrr4fGTpefHTinEFbsv",
	"button": "_3y9xUpOWCskvgU8uP_0LYc"
};
module.exports = exports;
