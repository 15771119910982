import { CustomReportViewFilter, CustomReportViewFilterGroup, isFilterGroup } from "domain/reports";
import { isStartsWithOperator } from "services/report/ReportViewService";

export type FilterValidationError = "EMPTY_NAME" | "EMPTY_VALUE" | "CONTAINS_WILDCARD";

export function validateFilter(first: boolean, filter: CustomReportViewFilter): FilterValidationError | undefined {
    if (filter.pathType.trim() === "") {
        throw new Error(`Filter's path type is empty: ${filter}.`);
    }
    if (filter.name.trim() === "") {
        return "EMPTY_NAME";
    }
    if (filter.value.trim() === "") {
        return "EMPTY_VALUE";
    }
    if (isStartsWithOperator(filter.operator)) {
        for (const each of ["*", "%"]) {
            if (filter.value.includes(each)) {
                return "CONTAINS_WILDCARD";
            }
        }
    }
    if (filter.logicOperator == null && !first) {
        throw new Error(`Filter's logic operator isn't set: ${filter}.`);
    }
}

export function validateFilterGroup(first: boolean, filterGroup?: CustomReportViewFilterGroup): boolean {
    if (filterGroup == null) {
        return true;
    }
    if (filterGroup.filters == null) {
        return true;
    }
    if (!first && filterGroup.logicOperator == null) {
        return false;
    }
    // First in a filter group doesn't need to have defined logicOperator,
    // regardless if it's a filter group or a filter.
    let internalFirst = true;
    for (const each of filterGroup.filters) {
        if (isFilterGroup(each)) {
            if (!validateFilterGroup(internalFirst, each)) {
                return false;
            }
        } else if (validateFilter(internalFirst, each) != null) {
            return false;
        }
        internalFirst = false;
    }
    return true;
}
