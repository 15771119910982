import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { PoolDetails } from "./LicensePoolKebabMenu";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { License } from "components/licenses/delivery-history/DeliveryFormContent";
import AddLicensePools from "components/licenses/licenses-pool/AddLicensePools";
import LicensePoolsTable from "components/licenses/licenses-pool/LicensePoolsTable";
import ManageLicensePoolView from "components/licenses/licenses-pool/manage-license-pools/ManageLicensePoolView";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { LICENSES_ROUTE } from "components/router/Routes";
import { AUTH_LICENSE_POOL_CREATE } from "domain/authority";
import { toLicenseInPool } from "services/licenses/LicensePoolService";
import { userSessionService } from "services/user/UserSessionService";

export default function AllLicensePoolsView(): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = useState({ count: 0 });
    const [show, setShow] = React.useState<boolean>(false);
    const [edit, setEdit] = React.useState<boolean>(false);
    const [failedToFetchLicenses, setFailedToFetchLicenses] = React.useState(false);
    const [loadingOnFetch, setLoadingOnFetch] = React.useState(false);
    const [licenses, setLicenses] = React.useState<License[]>([]);
    const [poolDetails, setPoolDetails] = React.useState<PoolDetails>({
        uuid: "",
        name: "",
        assignedUsers: 0,
        licenses: [],
        notes: "",
    });

    const viewActions = userSessionService.userHasAllAuthorities([AUTH_LICENSE_POOL_CREATE]) ? (
        <AddLicensePools
            fetchLicenses={setLicenses}
            licenseFetchFailed={setFailedToFetchLicenses}
            loading={setLoadingOnFetch}
            showForm={setShow}
        />
    ) : undefined;

    function onLicensePoolDelete() {
        return setState((prevState) => ({ count: prevState.count - 1 }));
    }

    return (
        <>
            <ApplicationLayout
                viewTitle={!show ? t("Licenses.allLicensesPools") : undefined}
                view={
                    !show ? (
                        <LicensePoolsTable
                            count={state.count}
                            onLicensePoolDelete={onLicensePoolDelete}
                            showForm={setShow}
                            cellDetails={setPoolDetails}
                            isEdit={setEdit}
                            licenseFetchFailed={setFailedToFetchLicenses}
                            setAllLicenses={setLicenses}
                            loading={setLoadingOnFetch}
                            hideDialogView={setShow}
                        />
                    ) : loadingOnFetch ? (
                        <LoadingIndicator />
                    ) : failedToFetchLicenses ? (
                        t("DeliveryHistory.addLicenseDelivery.failedToLoadLicenses")
                    ) : (
                        <ManageLicensePoolView
                            availableLicenses={licenses.map(toLicenseInPool)}
                            hideDialogView={setShow}
                            isEdit={setEdit}
                            poolDetails={poolDetails}
                            edit={edit}
                            onLicensePoolDelete={onLicensePoolDelete}
                        />
                    )
                }
                mainRoute={LICENSES_ROUTE}
                viewActions={!show ? viewActions : undefined}
                onDialog={show}
            />
        </>
    );
}
