import classNames from "classnames";
import { useFeature } from "flagged";
import { ErrorMessage, Field, Formik, FormikErrors, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { DatePicker } from "rsuite";
import { isAfter, isBefore } from "rsuite/esm/utils/dateUtils";
import { number, object, string } from "yup";

import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import Info from "components/icons/Info";
import QuestionMark from "components/icons/QuestionMark";
import {
    BUNDLES_ADD_ONS,
    ChannelBundle,
    CORE_TIER,
    CorporateBundle,
    ENTERPRISE_TIER,
    ESSENTIALS_TIER,
    POWER_TIER,
    PRO_SCALE_TIER,
    PRO_TIER,
    ProcessorBundle,
    SMB_SELECT_PLUS,
    SmbBundle,
} from "components/licenses/bundles";
import {
    BLANCCO_TOKEN_ID,
    createLicenseTypes,
    FEATURE_LICENSES,
    FEATURE_TENANT_MANAGEMENT,
    isAmountAvailable,
    isExpirationDateValid,
    NON_DELIVERABLE_FEATURE_LICENSES,
    showOnlyTokenLicense,
} from "components/licenses/common";
import {
    createDefaultSelectedLicense,
    DeliveryForm,
    License,
    MAX_CASE_NUMBER_LENGTH,
    MAX_OPPORTUNITY_ID_LENGTH,
} from "components/licenses/delivery-history/DeliveryFormContent";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import style from "components/tenants/add-tenant/add-tenant.scss";
import { DeliveryTab, populateWithLicenses } from "components/tenants/DeliveryTab";
import PricingTierView from "components/tenants/PricingTierView";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { getCountries } from "domain/countries";
import { EMAIL_MAX_LENGTH, NAME_MAX_LENGTH, NOTES_MAX_LENGTH } from "domain/globalConstants";
import { EmsConfiguration, LicenseData, MAX_DATE } from "domain/licenses";
import { CombinedTier, LicensingModel, OldLicensingModelTier, TenantType } from "domain/tenants";
import { generateTierToTranslationMap } from "domain/tierRelatedMaps";
import { CheckEmailAvailability } from "domain/users";
import { FLAG_BLANCCO_TOKEN } from "services/feature/FeatureFlagService";
import { extractLatestLicenseRateUuid, licenseService, ProductToRateList } from "services/licenses/LicenseService";
import {
    getCurrentTenantDetails,
    hasSubTenantCookie,
    hasTenantCookie,
    isUserParentInternal,
    showAllowedTierList,
} from "services/tenants/tenantCookieService";
import { userService } from "services/user/users/UserService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import defaultColor from "styles/colors/default-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";
import form from "styles/form.scss";
import { formatDateWithoutTime, MONTHS } from "utils/format";

import testIds from "testIds.json";

interface Props {
    submitEventHandler: (values: FormValues, selectedLicenses: License[]) => void;
    closeHandler: () => void;
    regionsList: string[];
    theme: typeof defaultColor | typeof tenantColor;
    tenantLicenses?: LicenseData[];
    parentExpirationDate: Date | undefined;
    parentHierarchyLevel: number;
    isUserDetailsOptional: boolean;
    tenantType: TenantType;
    tenantTier: CombinedTier;
    licensingModel: LicensingModel;
    rateVersions?: ProductToRateList[];
}

interface CodedName {
    name: string;
    code: string;
}

export interface GeneralRequiredFormValues {
    customerName: string;
    region: string;
    country: string;
    mainContactName: string;
    email: string;
    salesforceAccountId: string;
}

export interface DeliveryDetailsFormValues {
    deliveryDetails: DeliveryForm;
}

export interface SettingsFormValues {
    ems: EmsConfiguration;
}

export interface FormValues extends GeneralRequiredFormValues, DeliveryDetailsFormValues, SettingsFormValues {
    type: TenantType;
    tier: CombinedTier;
    notes: string;
    expirationDate?: Date;
    licensingModel: LicensingModel;
}

function findTierLicenses(
    tierLicenses: string[],
    allLicenses: { productName: string; productId: string }[],
    selectedTier: string
): { productName: string; productId: string }[] {
    const foundLicenses: { productName: string; productId: string }[] = [];
    allLicenses.forEach((eachInAllLicenses) => {
        tierLicenses.forEach((eachInTier) => {
            if (eachInTier == eachInAllLicenses.productId) {
                foundLicenses.push(eachInAllLicenses);
            }
        });
    });
    if (selectedTier === ProcessorBundle.PRO || selectedTier === CorporateBundle.POWER) {
        const allTierLicenses = createLicenseTypes(true);
        allTierLicenses.forEach((e) => {
            if (e.productId == FEATURE_TENANT_MANAGEMENT) {
                foundLicenses.push({
                    productName: e.productName,
                    productId: e.productId,
                });
            }
        });
    }
    return foundLicenses;
}

export function isTokenLicense(productId: string): boolean {
    return productId === BLANCCO_TOKEN_ID;
}

export function generateSelectableLicenses(
    dropdownLicenses: { productName: string; productId: string }[],
    selectedTier: CombinedTier,
    licensingModel: LicensingModel
) {
    const deliverableLicenses: { productName: string; productId: string }[] = [];
    const allAvailableLicenses = createLicenseTypes(true);
    const tokenInUse = showOnlyTokenLicense(licensingModel);
    if (licensingModel === LicensingModel.OLD_MODEL) {
        dropdownLicenses.push(
            ...allAvailableLicenses.filter((element) => !NON_DELIVERABLE_FEATURE_LICENSES.includes(element.productId))
        );
        return;
    }
    allAvailableLicenses.forEach((eachInAllLicenses) => {
        if (!tokenInUse && isTokenLicense(eachInAllLicenses.productId)) {
            return;
        }
        if (!FEATURE_LICENSES.includes(eachInAllLicenses.productId)) {
            deliverableLicenses.push(eachInAllLicenses);
        }
    });
    const tierLicenses = deduceTierLicenses(selectedTier, false);

    if (tierLicenses) {
        const filteredTierLicenses = findTierLicenses(tierLicenses, deliverableLicenses, selectedTier);
        if (tokenInUse) {
            dropdownLicenses.push(...extractAddOns(filteredTierLicenses));
            dropdownLicenses.push(...deliverableLicenses.filter((each) => isTokenLicense(each.productId)));
        } else {
            dropdownLicenses.push(...filteredTierLicenses);
        }
    }
}

export function deduceTierLicenses(tier: CombinedTier, withoutAddOns: boolean, withoutFeatureLicenses?: boolean) {
    const tierToLicenses = new Map<CombinedTier, string[]>([
        [CorporateBundle.ESSENTIALS, Object.values(ESSENTIALS_TIER)],
        [CorporateBundle.POWER, Object.values(POWER_TIER)],
        [CorporateBundle.ENTERPRISE, Object.values(ENTERPRISE_TIER)],
        [ProcessorBundle.CORE, Object.values(CORE_TIER)],
        [ProcessorBundle.PRO, Object.values(PRO_TIER)],
        [ProcessorBundle.PRO_SCALE, Object.values(PRO_SCALE_TIER)],
        [ChannelBundle.PRO_SCALE, Object.values(PRO_SCALE_TIER)],
        [SmbBundle.SELECT_50, Object.values(SMB_SELECT_PLUS)],
        [SmbBundle.SELECT_100, Object.values(SMB_SELECT_PLUS)],
        [SmbBundle.SELECT_PLUS_250, Object.values(SMB_SELECT_PLUS)],
        [SmbBundle.SELECT_PLUS_500, Object.values(SMB_SELECT_PLUS)],
    ]);
    if (withoutAddOns && !withoutFeatureLicenses) {
        return tierToLicenses.get(tier)?.filter((each) => !BUNDLES_ADD_ONS.includes(each));
    }
    if (withoutAddOns && withoutFeatureLicenses) {
        return tierToLicenses
            .get(tier)
            ?.filter((each) => !FEATURE_LICENSES.includes(each) && !BUNDLES_ADD_ONS.includes(each));
    }
    return tierToLicenses.get(tier);
}

function extractAddOns(availableLicenses: { productName: string; productId: string }[]) {
    return availableLicenses.filter((each) => isProductAddOn(each.productId));
}

export function isProductAddOn(productId: string) {
    return BUNDLES_ADD_ONS.includes(productId);
}

export const AddTenantForm = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const defaultValues = deduceDefaultTenantOptions(props.tenantTier, props.tenantType, props.licensingModel);
    const currentTenantDetails = getCurrentTenantDetails();
    const countries = getCountries(t).sort((a, b) => (a.name > b.name ? 1 : -1));
    const regions = generateRegions();
    const [selectedExpirationDate, setSelectedExpirationDate] = React.useState<Date | undefined>(
        props.parentExpirationDate
    );
    const [state, setState] = React.useState({
        miniLoader: false,
        notesCharactersLeft: NOTES_MAX_LENGTH,
        emsIdUseDefault: true,
        formValues: {
            customerName: "",
            type: defaultValues.defaultType,
            region: currentTenantDetails.region || "",
            tier: defaultValues.defaultTier,
            country: "",
            notes: "",
            mainContactName: "",
            email: "",
            salesforceAccountId: "",
            expirationDate: selectedExpirationDate,
            ems: {
                emsId: "",
                hlEntitlements: false,
                slEntitlements: false,
                availableSlActivations: 0,
                usedSlActivations: 0,
                lastSynced: "",
            },
            deliveryDetails: {
                deliveryType: "",
                caseNumber: "",
                opportunityId: "",
                notes: "",
                amount: null,
                expirationDate: "",
                tokenRateVersion: extractLatestLicenseRateUuid(props.rateVersions),
            },
            licensingModel: defaultValues.defaultModel,
        },
        selectedLicenses: populateWithLicenses(
            defaultValues.defaultTier,
            defaultValues.defaultModel,
            props.tenantLicenses,
            t
        ),
    });

    function deduceDefaultTenantOptions(
        parentTier: CombinedTier,
        parentType: TenantType,
        parentModel: LicensingModel
    ): { defaultType: TenantType; defaultTier: CombinedTier; defaultModel: LicensingModel } {
        if (["CORPORATE", "PROCESSOR", "CUSTOMER"].includes(parentType)) {
            return {
                defaultType: parentType,
                defaultTier: parentTier,
                defaultModel: parentModel,
            };
        }
        if (
            parentType === "INTERNAL" ||
            (parentModel === LicensingModel.BUNDLE_WITH_TOKEN && parentType === "CHANNEL")
        ) {
            return {
                defaultType: "CORPORATE",
                defaultTier: CorporateBundle.ESSENTIALS,
                defaultModel: LicensingModel.BUNDLE_WITH_TOKEN,
            };
        }
        return {
            defaultType: "CUSTOMER",
            defaultTier: OldLicensingModelTier.STANDARD,
            defaultModel: parentModel,
        };
    }

    function generateRegions(): Array<CodedName> {
        const awsRegions = [
            { name: t("AddCustomerForm.UsEastNorthVirginia"), code: "us-east-1" },
            { name: t("AddCustomerForm.UsEastOhio"), code: "us-east-2" },
            { name: t("AddCustomerForm.UsWestOregon"), code: "us-west-2" },
            { name: t("AddCustomerForm.UsWestNorthernCalifornia"), code: "us-west-1" },
            { name: t("AddCustomerForm.SouthAmericaSaoPaulo"), code: "sa-east-1" },
            { name: t("AddCustomerForm.EuropeFrankfurt"), code: "eu-central-1" },
            { name: t("AddCustomerForm.EuropeLondon"), code: "eu-west-2" },
            { name: t("AddCustomerForm.EuropeParis"), code: "eu-west-3" },
            { name: t("AddCustomerForm.MiddleEastBahrain"), code: "me-south-1" },
            { name: t("AddCustomerForm.EuropeIreland"), code: "eu-west-1" },
            { name: t("AddCustomerForm.EuropeMilan"), code: "eu-south-1" },
            { name: t("AddCustomerForm.EuropeStockholm"), code: "eu-north-1" },
            { name: t("AddCustomerForm.AwsAfricaCapeTown"), code: "af-south-1" },
            { name: t("AddCustomerForm.MainlandChinaBeijing"), code: "cn-north-1" },
            { name: t("AddCustomerForm.MainlandChinaNingxia"), code: "cn-northwest-1" },
            { name: t("AddCustomerForm.AsiaPacificSydney"), code: "ap-southeast-2" },
            { name: t("AddCustomerForm.AsiaPacificOsaka"), code: "ap-northeast-3" },
            { name: t("AddCustomerForm.AsiaPacificTokyo"), code: "ap-northeast-1" },
            { name: t("AddCustomerForm.AsiaPacificMumbai"), code: "ap-south-1" },
            { name: t("AddCustomerForm.AsiaPacificSeoul"), code: "ap-northeast-2" },
            { name: t("AddCustomerForm.AsiaPacificHongKong"), code: "ap-east-1" },
        ];

        if (props.regionsList) {
            return awsRegions
                .filter((region) => props.regionsList.some((reg: string) => region.code === reg))
                .sort((a, b) => (a.name > b.name ? 1 : -1));
        } else {
            return [{ name: t("AddCustomerForm.EuropeIreland"), code: "eu-west-1" }];
        }
    }

    function createField(
        id: keyof EmsConfiguration,
        label: string,
        testId: string,
        value: string | boolean | number,
        type: "text" | "checkbox" | "number" | "label",
        inErrorState: boolean,
        required: boolean,
        handleChange?: (event: React.ChangeEvent) => void,
        errorTestId?: string,
        disabled?: boolean
    ): JSX.Element {
        const className = classNames(form.input, {
            [form.fixedWidthInput]: ["text", "number"].includes(type),
            [form.inputError]: inErrorState,
        });
        const valueAttribute = type === "checkbox" ? "checked" : "value";
        const attributes = Object.assign(
            {
                id,
                name: "ems." + id,
                className,
                "data-testid": testId,
                onChange: handleChange,
                type,
                [valueAttribute]: value,
                disabled,
            },
            type !== "number" ? {} : { min: 0 }
        );
        const element =
            type === "label" ? (
                <span className={form.fixedWidthInput} data-testid={testId}>
                    {value}
                </span>
            ) : (
                <Field {...attributes} />
            );

        const labels =
            type === "checkbox" ? (
                <label className={form.container}>
                    {element}
                    <span className={form.checkmark} />
                </label>
            ) : (
                <>{element}</>
            );

        return (
            <div className={form.formFields}>
                <span className={form.optional}>{required ? "" : t("Common.optional")}</span>
                <label htmlFor={id} className={form.label}>
                    {label}
                </label>
                {labels}
                <div className={form.error} data-testid={errorTestId}>
                    <ErrorMessage component="div" name={attributes.name} className={form.error} />
                </div>
            </div>
        );
    }

    function createCustomerNameSection(formikProps: FormikProps<FormValues>) {
        return (
            <div className={classNames(form.formFields, style.topMarginGap)}>
                <label htmlFor="customerName" className={form.label}>
                    {t("Common.customerName")}
                </label>
                <input
                    id="customerName"
                    className={classNames(form.input, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.customerName,
                    })}
                    maxLength={NAME_MAX_LENGTH}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.nameInput.itself}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.customerName}
                />
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.nameInput.errorLabel}
                >
                    <ErrorMessage name="customerName" />
                </div>
            </div>
        );
    }

    function updateSelectedLicenses(tier: CombinedTier, licensingModel: LicensingModel) {
        if (licensingModel === LicensingModel.OLD_MODEL) {
            setState((prevState) => ({
                ...prevState,
                selectedLicenses: [createDefaultSelectedLicense(t)],
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                selectedLicenses: populateWithLicenses(tier, licensingModel, props.tenantLicenses, t),
            }));
        }
    }

    function generateTenantOptions(parentType: TenantType, selectedModel: LicensingModel): TenantType[] {
        if (parentType === "INTERNAL") {
            if (selectedModel === LicensingModel.OLD_MODEL) {
                return ["CHANNEL", "CUSTOMER"];
            }
            return ["CORPORATE", "PROCESSOR", "SMB", "CHANNEL"];
        }
        if (parentType === "CHANNEL") {
            if (selectedModel === LicensingModel.OLD_MODEL) {
                return ["CHANNEL", "CUSTOMER"];
            }
            return ["CORPORATE", "PROCESSOR", "SMB", "CHANNEL"];
        }
        return [parentType];
    }

    function createCustomerTypeSection(formikProps: FormikProps<FormValues>) {
        const tenantToTranslationKeyMap = new Map<TenantType, string>([
            ["CHANNEL", t("Common.channel")],
            ["CORPORATE", t("Common.corporate")],
            ["PROCESSOR", t("Common.processor")],
            ["CUSTOMER", t("Common.tenant")],
            ["SMB", t("Common.smb")],
        ]);
        const validTenantTypes: TenantType[] = generateTenantOptions(
            props.tenantType,
            formikProps.values.licensingModel
        );

        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    <label htmlFor="type" className={classNames(form.label)}>
                        {t("AddCustomerForm.type")}
                    </label>
                    <select
                        id="type"
                        className={classNames(form.select, form.fixedWidthInput)}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.typeSelect.itself}
                        onChange={(event) => {
                            formikProps.handleChange(event);
                            const oldModel = formikProps.values.licensingModel == LicensingModel.OLD_MODEL;
                            const preselectedTier = showAllowedTierList(
                                "",
                                event.target.value as TenantType,
                                props.tenantTier,
                                oldModel
                            )[0];
                            formikProps.setFieldValue("tier", preselectedTier);

                            updateSelectedLicenses(preselectedTier, formikProps.values.licensingModel);
                        }}
                        value={formikProps.values.type}
                    >
                        {validTenantTypes.map((value, index) => (
                            <option key={index} value={value}>
                                {tenantToTranslationKeyMap.get(value)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    }

    function createRegionSection(formikProps: FormikProps<FormValues>) {
        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    <label htmlFor="region" className={form.label}>
                        {t("AddCustomerForm.customerFormRegion")}:
                    </label>
                    <select
                        id="region"
                        className={classNames(form.select, form.fixedWidthInput, {
                            [form.inputError]: formikProps.errors.region,
                        })}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.regionSelect.itself}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.region}
                    >
                        {regions &&
                            regions.map((value, index) => (
                                <option key={index} value={value.code}>
                                    {value.name}
                                </option>
                            ))}
                    </select>
                </div>
                <Tooltip
                    content={
                        <div className={style.tooltipOpen}>
                            {t("AddCustomerForm.tooltip")}
                            <br />
                            <a
                                href="https://aws.amazon.com/about-aws/global-infrastructure/regions_az/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                {t("AddCustomerForm.tooltipOpen")}
                            </a>
                        </div>
                    }
                    placement={"top"}
                >
                    <a
                        href="https://aws.amazon.com/about-aws/global-infrastructure/regions_az/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <div className={style.info} tabIndex={0}>
                            <Info borderColor={props.theme.contentBackgroundColor} color={props.theme.iconFillColor} />
                        </div>
                    </a>
                </Tooltip>
            </div>
        );
    }

    function createLicensingModelSection(formikProps: FormikProps<FormValues>) {
        const licensingModelToTranslationKey = new Map<string, string>([
            [LicensingModel.OLD_MODEL, t("AddCustomerForm.licensingModel.old")],
            [LicensingModel.BUNDLE, t("AddCustomerForm.licensingModel.bundles")],
            [LicensingModel.BUNDLE_WITH_TOKEN, t("AddCustomerForm.licensingModel.bundlesWithToken")],
        ]);
        const licensingModelOptions: string[] = [];
        switch (props.tenantType) {
            case "INTERNAL":
                if (useFeature(FLAG_BLANCCO_TOKEN)) {
                    licensingModelOptions.push(...[LicensingModel.OLD_MODEL, LicensingModel.BUNDLE_WITH_TOKEN]);
                } else {
                    licensingModelOptions.push(...[LicensingModel.OLD_MODEL, LicensingModel.BUNDLE]);
                }
                break;
            case "CUSTOMER":
                licensingModelOptions.push(LicensingModel.OLD_MODEL);
                break;
            default:
                licensingModelOptions.push(props.licensingModel);
                break;
        }
        return (
            <>
                <div className={form.formFields}>
                    <label htmlFor="licensingModel" className={classNames(form.label)}>
                        {t("AddCustomerForm.licensingModel.label")}
                    </label>
                    <select
                        id={"licensingModel"}
                        className={classNames(form.select, form.fixedWidthInput)}
                        onChange={(event) => {
                            formikProps.handleChange(event);
                            const selectedLicensingModel = event.target.value as LicensingModel;
                            const oldModel = selectedLicensingModel == LicensingModel.OLD_MODEL;
                            const selectedTenantType = oldModel ? "CUSTOMER" : "CORPORATE";
                            formikProps.setFieldValue("type", selectedTenantType);
                            const preselectedTier = showAllowedTierList(
                                "",
                                selectedTenantType,
                                props.tenantTier,
                                oldModel
                            )[0];
                            formikProps.setFieldValue("tier", preselectedTier);
                            updateSelectedLicenses(preselectedTier, selectedLicensingModel);
                        }}
                        value={formikProps.values.licensingModel}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.licensingModelSelect.itself}
                    >
                        {licensingModelOptions.map((value, index) => (
                            <option key={index} value={value}>
                                {licensingModelToTranslationKey.get(value)}
                            </option>
                        ))}
                    </select>
                </div>
            </>
        );
    }

    function createTierSection(formikProps: FormikProps<FormValues>) {
        const [pricingTierModalVisible, setPricingTierModalVisible] = React.useState(false);
        const theme = useSelector((state: StoreState) => state.themeReducer.theme);
        const oldLicensingModel = formikProps.values.licensingModel == LicensingModel.OLD_MODEL;
        const allowedTiers = [];
        if (props.tenantType === "INTERNAL" || props.tenantType === "CUSTOMER" || props.tenantType === "CHANNEL") {
            allowedTiers.push(...showAllowedTierList("", formikProps.values.type, props.tenantTier, oldLicensingModel));
        } else {
            allowedTiers.push(props.tenantTier);
        }

        return (
            <>
                <div className={style.regionAndInfoContainer}>
                    <div className={form.formFields}>
                        <label htmlFor="tier" className={classNames(form.label)}>
                            {oldLicensingModel ? t("AddCustomerForm.tier") : t("AddCustomerForm.blanccoBundles")}
                        </label>
                        <select
                            id="tier"
                            className={classNames(form.select, form.fixedWidthInput)}
                            data-testid={testIds.workArea.tenant.manageTenantDialog.details.tierSelect.itself}
                            onChange={(event) => {
                                formikProps.handleChange(event);
                                updateSelectedLicenses(
                                    event.target.value as CombinedTier,
                                    formikProps.values.licensingModel
                                );
                            }}
                            value={formikProps.values.tier}
                        >
                            {allowedTiers.map((value, index) => (
                                <option key={index} value={value}>
                                    {generateTierToTranslationMap(t).get(value)}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button
                        className={style.tooltipPostion}
                        onClick={() => setPricingTierModalVisible(true)}
                        type="button"
                    >
                        <QuestionMark color={theme.iconFillColor} />
                    </button>
                </div>
                <PricingTierView
                    tenantType={formikProps.values.type}
                    visibility={pricingTierModalVisible}
                    setVisibility={setPricingTierModalVisible}
                    selectedTier={formikProps.values.tier}
                    tenantModal={formikProps.values.licensingModel}
                />
            </>
        );
    }

    function createCountrySection(formikProps: FormikProps<FormValues>) {
        return (
            <div className={form.formFields}>
                <label htmlFor="country" className={form.label}>
                    {t("Common.country")}:
                </label>
                <select
                    id="country"
                    className={classNames(form.select, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.country,
                    })}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.countrySelect.itself}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.country}
                >
                    <option>{t("AddCustomerForm.SelectCountry")}</option>
                    {countries.map((value, index) => (
                        <option key={index} value={value.code}>
                            {value.name}
                        </option>
                    ))}
                </select>
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.countrySelect.errorLabel}
                >
                    <ErrorMessage name="country" />
                </div>
            </div>
        );
    }

    function createNotesSection(formikProps: FormikProps<FormValues>) {
        const notesChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setState((prevState) => ({
                ...prevState,
                notesCharactersLeft: event.target.maxLength - event.target.value.length,
            }));
        };

        return (
            <div className={classNames(form.formFields, form.formFieldsFlex)}>
                <div className={form.formFieldsAlignItemsTop}>
                    <span className={form.optional}>{t("Common.optional")}</span>
                    <label htmlFor="notes" className={form.label}>
                        {t("Common.notes")}:
                    </label>
                </div>
                <div className={style.notesContainer}>
                    <textarea
                        id="notes"
                        className={classNames(
                            form.input,
                            form.fixedWidthInput,
                            { [form.inputError]: formikProps.errors.notes },
                            style.notesHeight
                        )}
                        maxLength={NOTES_MAX_LENGTH}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.noteTextArea.itself}
                        onChange={(e) => {
                            formikProps.handleChange(e);
                            notesChangeHandler(e);
                        }}
                        value={formikProps.values.notes}
                    />
                    <span className={form.optional}>
                        {t("AddCustomerForm.charactersLeft", {
                            remainingCharacters: state.notesCharactersLeft.toString(),
                            notesMaxLength: NOTES_MAX_LENGTH.toString(),
                        })}
                    </span>
                </div>
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.noteTextArea.errorLabel}
                >
                    <ErrorMessage name="notes" />
                </div>
            </div>
        );
    }

    function createMainContactSection(formikProps: FormikProps<FormValues>) {
        return (
            <div className={classNames(form.formFields, style.topMarginGap)}>
                <label htmlFor="mainContactName" className={form.label}>
                    {t("AddCustomerForm.contactName")}
                </label>
                <input
                    id="mainContactName"
                    className={classNames(form.input, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.mainContactName,
                    })}
                    maxLength={NAME_MAX_LENGTH}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.contactNameInput.itself}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.mainContactName}
                />
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.contactNameInput.errorLabel}
                >
                    <ErrorMessage name="mainContactName" />
                </div>
            </div>
        );
    }

    function createEmailSection(formikProps: FormikProps<FormValues>) {
        const loader = state.miniLoader && (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        );
        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    <label htmlFor="email" className={form.label}>
                        {t("Common.email")}:
                    </label>
                    <input
                        id="email"
                        className={classNames(form.input, form.fixedWidthInput, {
                            [form.inputError]: formikProps.errors.email,
                        })}
                        maxLength={EMAIL_MAX_LENGTH}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.contactEmailInput.itself}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.email}
                    />
                    {loader}
                    <div
                        className={form.error}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.contactEmailInput.errorLabel}
                    >
                        <ErrorMessage name="email" />
                    </div>
                </div>
                <Tooltip
                    content={<div className={style.tooltipOpen}>{t("AddCustomerForm.contactEmailInfoToolTip")}</div>}
                    placement={"top"}
                >
                    <div className={style.info} />
                </Tooltip>
            </div>
        );
    }

    function createAccessExpirySection(formikProps: FormikProps<FormValues>): JSX.Element {
        function formatDate(date: Date): string {
            return date.getDate().toString() + " " + MONTHS[date.getMonth()] + " " + date.getFullYear();
        }
        const [selectedDateAsString, setSelectedDateAsString] = React.useState<string>(t("Common.never"));
        const handleOkClick = (date: Date) => {
            setSelectedExpirationDate(date);
            setSelectedDateAsString(formatDate(date));
            formikProps.setFieldValue("expirationDate", date);
        };
        const handleClean = () => {
            if (props.parentExpirationDate) {
                setSelectedDateAsString(formatDate(props.parentExpirationDate));
                setSelectedExpirationDate(props.parentExpirationDate);
                formikProps.setFieldValue("expirationDate", props.parentExpirationDate);
            } else {
                setSelectedDateAsString(t("Common.never"));
                setSelectedExpirationDate(undefined);
                formikProps.setFieldValue("expirationDate", null);
            }
        };
        const createYesterday = () => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date;
        };
        const setDefaultDate = () => {
            if (selectedExpirationDate == null || props.parentExpirationDate == null) {
                return null;
            }
            if (selectedExpirationDate && props.parentExpirationDate > new Date(selectedExpirationDate)) {
                return selectedExpirationDate;
            }
            setSelectedExpirationDate(props.parentExpirationDate);
            return props.parentExpirationDate;
        };

        const isDateDisabled = (date: Date) => {
            if (props.parentExpirationDate == null) {
                return isBefore(date, createYesterday());
            }

            date.setHours(0, 0, 0, 0);
            return isBefore(date, createYesterday()) || isAfter(date, props.parentExpirationDate);
        };

        const calendar = document.getElementsByClassName("rs-picker-toggle-caret rs-icon")[0] as HTMLElement;
        if (calendar) {
            calendar.setAttribute("aria-label", t("AltText.calendar"));
        }
        return (
            <>
                {
                    <>
                        <span className={form.optional}>{t("Common.optional")}</span>
                        <label htmlFor="expirationDate" className={form.label}>
                            {t("Common.accessExpiry")}:
                        </label>
                        <DatePicker
                            id="expirationDate"
                            disabledDate={isDateDisabled}
                            ranges={[
                                {
                                    label: "Today",
                                    value: new Date(),
                                    closeOverlay: true,
                                },
                            ]}
                            title={t("AltText.calendar")}
                            placeholder={selectedDateAsString}
                            onSelect={handleOkClick}
                            onClean={handleClean}
                            placement={"top"}
                            className={form.dateRange}
                            renderValue={(value: Date) => {
                                return selectedExpirationDate == null
                                    ? t("Common.never")
                                    : `${formatDateWithoutTime(value.toString())}`;
                            }}
                            value={setDefaultDate()}
                        />
                    </>
                }
            </>
        );
    }

    function createSalesforceAccountId(formikProps: FormikProps<FormValues>, mandatoryField: boolean) {
        const loader = state.miniLoader && (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        );
        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    {mandatoryField ? null : <span className={form.optional}>{t("Common.optional")}</span>}
                    <label htmlFor="salesforceAccountId" className={form.label}>
                        {t("AddCustomerForm.accountId")}
                    </label>
                    <input
                        id="salesforceAccountId"
                        className={classNames(form.input, form.fixedWidthInput, {
                            [form.inputError]: formikProps.errors.salesforceAccountId,
                        })}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.salesforceAccountId.itself}
                        onChange={(event) => {
                            formikProps.handleChange(event);
                            if (state.emsIdUseDefault) {
                                formikProps.setFieldValue("ems.emsId", event.target.value);
                            }
                        }}
                        value={formikProps.values.salesforceAccountId}
                    />
                    {loader}
                    <div
                        className={form.error}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.salesforceAccountId.errorLabel}
                    >
                        <ErrorMessage name="salesforceAccountId" />
                    </div>
                </div>
                <Tooltip
                    content={<div className={style.tooltipOpen}>{t("AddCustomerForm.accountIdTooltip")}</div>}
                    placement={"top"}
                >
                    <div className={style.info} />
                </Tooltip>
            </div>
        );
    }

    function emsIdUseDefaultCheckbox(formikProps: FormikProps<FormValues>) {
        const loader = state.miniLoader && (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        );
        const handleToggle = (): void => {
            setState((prevState) => ({
                ...prevState,
                emsIdUseDefault: !prevState.emsIdUseDefault,
            }));
            if (state.emsIdUseDefault) {
                formikProps.setFieldValue("ems.emsId", formikProps.values.salesforceAccountId);
            }
        };
        return (
            <div className={classNames(form.formFields, style.defaultValueCheckbox)}>
                {loader}
                <label className={classNames(form.container, style.defaultCheckboxAlignment)}>
                    <input
                        type="checkbox"
                        id="emsIdUseDefault"
                        className={classNames(form.input)}
                        defaultChecked={state.emsIdUseDefault}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.settings.emsIdUseDefaultCheckbox}
                        onChange={() => {
                            handleToggle();
                        }}
                    />
                    <span className={form.checkmark} />
                </label>
                <label htmlFor="emsIdUseDefault" className={classNames(form.label)}>
                    {t("AddCustomerForm.useDefaultValue")}
                </label>
            </div>
        );
    }

    function showOptionalFields() {
        return props.isUserDetailsOptional || hasTenantCookie() || hasSubTenantCookie();
    }

    function createLicenceTable(formikProps: FormikProps<FormValues>) {
        return (
            <DeliveryTab
                defaultTenantLicenses={props.tenantLicenses}
                tier={formikProps.values.tier}
                licensingModel={formikProps.values.licensingModel}
                selectedLicenses={state.selectedLicenses}
                setSelectedLicenses={(licenses) =>
                    setState((prevState) => ({
                        ...prevState,
                        selectedLicenses: licenses,
                    }))
                }
                deliveryDetails={formikProps.values.deliveryDetails}
                errors={formikProps.errors}
                handleChange={formikProps.handleChange}
                inEdit={false}
                allTenantLicenses={[]}
                rateVersions={props.rateVersions}
            />
        );
    }

    function updateMiniLoader(miniLoader: boolean): void {
        setState((prevState) => ({
            ...prevState,
            miniLoader: miniLoader,
        }));
    }

    const getSubmitHandler = (values: FormValues) => {
        setState((prevState) => {
            return {
                ...prevState,
                formValues: {
                    ...prevState.formValues,
                    expirationDate: values.expirationDate,
                },
            };
        });

        props.submitEventHandler(values, state.selectedLicenses);
    };

    const isAddButtonDisabled = (parentLicenseExpirationDate: string) => {
        return state.selectedLicenses.some(
            (item) =>
                isNaN(item.licensesToAdd) ||
                item.totalOfLicenses < 0 ||
                !isExpirationDateValid(
                    item.expirationDate,
                    item.parentLicenseExpirationDate
                        ? new Date(item.parentLicenseExpirationDate)
                        : parentLicenseExpirationDate
                ) ||
                !isAmountAvailable(item)
        );
    };

    const isUserInternal = () => {
        return !props.isUserDetailsOptional && isUserParentInternal() && !hasTenantCookie() && !hasSubTenantCookie();
    };

    // The following arrays (generalInputLabels, settingsInputLabels and licenseDeliveryInputLabels) contain the required form fields and other fields that needs to be validated.
    // These field names are derived from the key names of their respective interfaces.
    // Whenever a new required field or other fields that need to pass validation is added to these interfaces, these arrays need to be updated as well.
    const generalInputLabels: (keyof GeneralRequiredFormValues)[] = [
        "email",
        "country",
        "customerName",
        "mainContactName",
        "salesforceAccountId",
        "region",
    ];

    const settingsInputLabels: (keyof SettingsFormValues)[] = ["ems"];

    const licenseDeliveryInputLabels: (keyof DeliveryDetailsFormValues)[] = ["deliveryDetails"];

    const highlightTabWithError = (errors: FormikErrors<FormValues>, inputLabels: string[], text: string) => {
        let foundErrors = false;

        for (const each of Object.keys(errors)) {
            if (inputLabels.includes(each)) {
                foundErrors = true;
                break;
            }
        }
        return (
            <div className={style.gridColumns}>
                <div
                    className={classNames({
                        [form.inputError]: foundErrors,
                    })}
                >
                    {foundErrors ? (
                        <FailedRedNotificationIcon
                            backgroundColor={props.theme.errorIconColor}
                            iconColor={props.theme.contentBackgroundColor}
                        />
                    ) : null}
                </div>
                <div
                    className={classNames({
                        [style.tabTitle]: foundErrors,
                    })}
                >
                    {text}
                </div>
            </div>
        );
    };

    return (
        <div className={style.formContainer}>
            <Formik
                initialValues={state.formValues}
                onSubmit={(values: FormValues) => getSubmitHandler(values)}
                validationSchema={object().shape({
                    customerName: string().required(t("AddCustomerForm.nameRequired")).max(NAME_MAX_LENGTH),
                    region: string().required(t("AddCustomerForm.regionRequired")),
                    country: string().required(t("AddCustomerForm.countryRequired")),
                    notes: string().max(NOTES_MAX_LENGTH),
                    mainContactName: isUserInternal()
                        ? string().required(t("AddCustomerForm.contactNameRequired")).max(NAME_MAX_LENGTH)
                        : string().max(NAME_MAX_LENGTH),
                    email: isUserInternal()
                        ? string()
                              .email(t("Common.invalidEmail"))
                              .required(t("AddCustomerForm.contactEmailRequired"))
                              .max(EMAIL_MAX_LENGTH)
                              .test("exists", t("Common.emailNotAvailable"), function (value) {
                                  updateMiniLoader(true);
                                  if (value == null) {
                                      return Promise.resolve(true);
                                  }

                                  const emailToBeValidated = value.trim();
                                  const abortController = new AbortController();
                                  return userService
                                      .checkEmailAvailability(emailToBeValidated, abortController)
                                      .then((value: CheckEmailAvailability) => {
                                          if (value.emailIsUndeliverable) {
                                              return this.createError({
                                                  message: t("Common." + value.errorMessage, {
                                                      domain_name: value.domainName,
                                                  }),
                                              });
                                          }
                                          return value.emailIsAvailable;
                                      })
                                      .finally(() => updateMiniLoader(false));
                              })
                        : string()
                              .email(t("Common.invalidEmail"))
                              .max(EMAIL_MAX_LENGTH)
                              .test("exists", t("Common.emailNotAvailable"), function (value) {
                                  updateMiniLoader(true);
                                  if (value == null) {
                                      return Promise.resolve(true);
                                  }

                                  const emailToBeValidated = value.trim();
                                  const abortController = new AbortController();
                                  return userService
                                      .checkEmailAvailability(emailToBeValidated, abortController)
                                      .then((value: CheckEmailAvailability) => {
                                          if (value.emailIsUndeliverable) {
                                              return this.createError({
                                                  message: t("Common." + value.errorMessage, {
                                                      domain_name: value.domainName,
                                                  }),
                                              });
                                          }
                                          return value.emailIsAvailable;
                                      })
                                      .finally(() => updateMiniLoader(false));
                              }),
                    expirationDate: string().default("AddCustomerForm.defaultValueSent"),
                    salesforceAccountId: isUserInternal()
                        ? string().required(t("AddCustomerForm.accountIdRequired"))
                        : string(),
                    ems: object().shape({
                        availableSlActivations: number().min(0),
                        emsId: string().test(
                            "exists",
                            t("EditCustomerView.emsIdDoesNotExist"),
                            (value: string): Promise<boolean> => {
                                updateMiniLoader(true);
                                if (value == null) {
                                    updateMiniLoader(false);
                                    return Promise.resolve(true);
                                }
                                const trimmed = value.trim();
                                const abortController = new AbortController();
                                return licenseService
                                    .checkEmsCustomerIdExistence(trimmed, abortController)
                                    .finally(() => updateMiniLoader(false));
                            }
                        ),
                    }),
                    deliveryDetails: object().shape({
                        caseNumber:
                            !showOptionalFields() &&
                            isUserParentInternal() &&
                            state.selectedLicenses[0].licenseType != "default"
                                ? string()
                                      .required(t("AddCustomerForm.caseNumberRequired"))
                                      .typeError(t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType"))
                                      .max(
                                          MAX_CASE_NUMBER_LENGTH,
                                          t("DeliveryHistory.addLicenseDelivery.validation.caseNumberLength", {
                                              length: MAX_CASE_NUMBER_LENGTH,
                                          })
                                      )
                                      .matches(
                                          /^\d+$/,
                                          t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                      )
                                : string()
                                      .typeError(t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType"))
                                      .max(
                                          MAX_CASE_NUMBER_LENGTH,
                                          t("DeliveryHistory.addLicenseDelivery.validation.caseNumberLength", {
                                              length: MAX_CASE_NUMBER_LENGTH,
                                          })
                                      )
                                      .matches(
                                          /^\d+$/,
                                          t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                      ),
                        opportunityId:
                            !showOptionalFields() &&
                            isUserParentInternal() &&
                            state.selectedLicenses[0].licenseType != "default"
                                ? string()
                                      .required(t("AddCustomerForm.opportunityIdRequired"))
                                      .max(
                                          MAX_OPPORTUNITY_ID_LENGTH,
                                          t("DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength", {
                                              length: MAX_OPPORTUNITY_ID_LENGTH,
                                          })
                                      )
                                : string().max(
                                      MAX_OPPORTUNITY_ID_LENGTH,
                                      t("DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength", {
                                          length: MAX_OPPORTUNITY_ID_LENGTH,
                                      })
                                  ),
                    }),
                })}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {(formikProps) => (
                    <form onSubmit={formikProps.handleSubmit}>
                        <Tabs>
                            <TabList>
                                <Tab data-testid={testIds.workArea.tenant.manageTenantDialog.details.tab}>
                                    {highlightTabWithError(
                                        formikProps.errors,
                                        generalInputLabels,
                                        t("AddCustomerForm.generic")
                                    )}
                                </Tab>
                                <Tab data-testid={testIds.workArea.tenant.manageTenantDialog.settings.tab}>
                                    {highlightTabWithError(
                                        formikProps.errors,
                                        settingsInputLabels,
                                        t("Common.settings")
                                    )}
                                </Tab>
                                <Tab data-testid={testIds.workArea.tenant.manageTenantDialog.delivery.tab}>
                                    {highlightTabWithError(
                                        formikProps.errors,
                                        licenseDeliveryInputLabels,
                                        t("AddCustomerForm.deliveryDetails")
                                    )}
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <Heading tag={"div"} variant="SUBTITLE_1">
                                    {t("AddCustomerForm.generic")}
                                </Heading>
                                {createCustomerNameSection(formikProps)}
                                {createLicensingModelSection(formikProps)}
                                {createCustomerTypeSection(formikProps)}
                                {createTierSection(formikProps)}
                                {createRegionSection(formikProps)}
                                {createCountrySection(formikProps)}
                                {createNotesSection(formikProps)}
                                <br />
                                <Heading tag={"div"} variant="SUBTITLE_1">
                                    {t("AddCustomerForm.details")}
                                </Heading>
                                {createMainContactSection(formikProps)}
                                {createEmailSection(formikProps)}
                                {isUserParentInternal() ? (
                                    <>
                                        <br />
                                        <Heading tag={"div"} variant="SUBTITLE_1">
                                            {t("AddCustomerForm.salesforceAccount")}
                                        </Heading>
                                        {createSalesforceAccountId(formikProps, isUserInternal())}
                                    </>
                                ) : null}
                            </TabPanel>
                            <TabPanel>
                                {isUserParentInternal() ? (
                                    <>
                                        <Heading tag={"div"} variant="SUBTITLE_1">
                                            {t("EditCustomerView.entitlementSettings")}
                                        </Heading>
                                        <div className={style.formFieldGroup}>
                                            {createField(
                                                "emsId",
                                                t("AddCustomerForm.accountId"),
                                                testIds.workArea.tenant.manageTenantDialog.settings.accountIdInput
                                                    .itself,
                                                formikProps.values.ems.emsId,
                                                "text",
                                                formikProps.errors.ems?.emsId != null,
                                                false,
                                                formikProps.handleChange,
                                                testIds.workArea.tenant.manageTenantDialog.settings.accountIdInput
                                                    .errorLabel,
                                                state.emsIdUseDefault
                                            )}
                                            {emsIdUseDefaultCheckbox(formikProps)}
                                        </div>
                                        {createField(
                                            "hlEntitlements",
                                            t("EditCustomerView.hlEntitlements"),
                                            testIds.workArea.tenant.manageTenantDialog.settings.hlEntitlementsCheckbox,
                                            formikProps.values.ems.hlEntitlements,
                                            "checkbox",
                                            false,
                                            false,
                                            formikProps.handleChange
                                        )}
                                        {createField(
                                            "slEntitlements",
                                            t("EditCustomerView.slEntitlements"),
                                            testIds.workArea.tenant.manageTenantDialog.settings.slEntitlementsCheckbox,
                                            formikProps.values.ems.slEntitlements,
                                            "checkbox",
                                            false,
                                            false,
                                            formikProps.handleChange
                                        )}
                                        {createField(
                                            "availableSlActivations",
                                            t("EditCustomerView.availableSlActivations"),
                                            testIds.workArea.tenant.manageTenantDialog.settings
                                                .availableSlActivationsInput.itself,
                                            formikProps.values.ems.availableSlActivations,
                                            "number",
                                            formikProps.errors.ems?.availableSlActivations != null,
                                            false,
                                            formikProps.handleChange,
                                            testIds.workArea.tenant.manageTenantDialog.settings
                                                .availableSlActivationsInput.errorLabel
                                        )}
                                        <br />
                                    </>
                                ) : null}
                                {
                                    <Heading tag={"div"} variant="SUBTITLE_1">
                                        {t("AddCustomerForm.status")}
                                    </Heading>
                                }
                                {createAccessExpirySection(formikProps)}
                            </TabPanel>
                            <TabPanel>{createLicenceTable(formikProps)}</TabPanel>
                        </Tabs>

                        <div className={form.buttonContainer}>
                            <button
                                type="submit"
                                className={
                                    isAddButtonDisabled(MAX_DATE.toLocaleDateString())
                                        ? classNames(buttons.disabledButton, form.submitButton)
                                        : classNames(buttons.primaryButton, buttons.medium, form.submitButton)
                                }
                                disabled={
                                    formikProps.isSubmitting || isAddButtonDisabled(MAX_DATE.toLocaleDateString())
                                }
                                data-testid={testIds.workArea.tenant.manageTenantDialog.submitButton}
                            >
                                {t("AddCustomerView.addCustomer")}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};
