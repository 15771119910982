import * as React from "react";
import { useTranslation } from "react-i18next";

import ApplicationLayout from "components/layout/ApplicationLayout";
import AddReportTemplateView from "components/reports/report-template/AddReportTemplateView";
import ReportTemplatesTable from "components/reports/report-template/ReportTemplatesTable";
import { REPORTS_ROUTE } from "components/router/Routes";
import { AUTH_REPORT_TEMPLATE_CREATE } from "domain/authority";
import { TemplateTableData } from "domain/reports";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";

export default function ReportTemplatesView(): JSX.Element {
    const { t } = useTranslation();

    const [counter, setCounter] = React.useState({ count: 0 });
    const [templateLoading, setTemplateLoading] = React.useState(false);
    const [templates, setTemplates] = React.useState<TemplateTableData[]>([]);
    const onUpdate = () => {
        return setCounter((prevState) => ({ count: prevState.count + 1 }));
    };
    function onDelete() {
        return setCounter((prevState) => ({ count: prevState.count - 1 }));
    }
    return (
        <ApplicationLayout
            mainRoute={REPORTS_ROUTE}
            view={
                <ReportTemplatesTable
                    count={counter.count}
                    allLoaded={templateLoading}
                    setTemplatesFetched={setTemplateLoading}
                    onTemplatesFetched={setTemplates}
                    onTemplateDelete={onDelete}
                    onTemplateUpdate={onUpdate}
                    onTemplateSetDefault={onUpdate}
                />
            }
            viewTitle={t("ReportTemplate.title")}
            viewActions={
                !hasTenantCookie() && userSessionService.userHasAllAuthorities([AUTH_REPORT_TEMPLATE_CREATE]) ? (
                    <AddReportTemplateView onTemplateAdded={onUpdate} templates={templates} />
                ) : undefined
            }
        />
    );
}
