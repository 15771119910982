import { apiGatewayService } from "services/api/ApiGatewayService";
import { getObject, RepositoryKey } from "utils/repository";

type Language = "en_us";
interface CommonSupportVideo {
    date: string;
    title: Record<Language, string>;
    url: string;
}
export interface SupportVideo extends CommonSupportVideo {
    licenseIds: number[];
}
interface SupportVideoDto extends CommonSupportVideo {
    license_ids: number[];
}

class VideosService {
    public async fetchVideoInformationList(): Promise<SupportVideo[]> {
        const url: string = getObject(RepositoryKey.VIDEOS_URL) || "https://api.blancco.com/support/video";
        const promise = apiGatewayService.fetch(url);
        return promise
            .then((result) => result.json() as unknown as { videos: SupportVideoDto[] })
            .then((wrapper) => wrapper.videos)
            .then((result) =>
                result.map((dto) => ({
                    date: dto.date,
                    licenseIds: dto.license_ids ?? [],
                    title: dto.title,
                    url: dto.url,
                }))
            );
    }
}

export const videosService = new VideosService();
