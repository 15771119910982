import * as React from "react";

const StarGreenIcon = (props: { color: string }): JSX.Element => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.9995 4.68571L14.26 9.50045L19.3143 10.2806L15.6574 14.0265L16.5194 19.3143L11.9995 16.8147L7.47959 19.3143L8.34262 14.0226L4.68573 10.2767L9.73907 9.49655L11.9995 4.68571Z"
            fill={props.color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9996 4C12.2654 4.00004 12.5073 4.15369 12.6202 4.3943L14.7255 8.87848L19.4189 9.60295C19.6727 9.64214 19.8834 9.81991 19.9647 10.0636C20.046 10.3072 19.9844 10.5759 19.8049 10.7596L16.3899 14.2578L17.1962 19.2039C17.2385 19.4631 17.129 19.7236 16.9143 19.8749C16.6996 20.0261 16.4174 20.0414 16.1876 19.9143L11.9995 17.5983L7.81144 19.9143C7.58162 20.0414 7.29941 20.0261 7.08472 19.8749C6.87002 19.7236 6.76055 19.4631 6.80282 19.2039L7.61013 14.2539L4.19506 10.7557C4.01564 10.572 3.95397 10.3033 4.03529 10.0597C4.1166 9.81602 4.32726 9.63825 4.5811 9.59906L9.27368 8.87457L11.3789 4.39411C11.4919 4.15353 11.7338 3.99996 11.9996 4Z"
            fill={props.color}
        />
    </svg>
);

export default StarGreenIcon;
