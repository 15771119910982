import classNames from "classnames";
import React from "react";
import { SketchPicker } from "react-color";

import styles from "./manage-user-groups.scss";
import Chevron from "components/icons/Chevron";

interface ChangeColor {
    hex: string;
}

interface Props {
    setColor: (hexCode: string) => void;
    selectedColor: string;
}

export function ColorPicker(props: Props): JSX.Element {
    const [displayColor, setDisplayColor] = React.useState(false);
    const [colorPickerDisplay, setColorPickerDisplay] = React.useState(false);
    const iconColor = "#3B5DAB";
    const chevronClass: string = classNames(styles.arrow, { [styles.down]: colorPickerDisplay });

    const handleClick = () => {
        setDisplayColor(!displayColor);
        setColorPickerDisplay(!colorPickerDisplay);
    };

    const handleClose = () => {
        setDisplayColor(false);
        setColorPickerDisplay(!colorPickerDisplay);
    };

    const handleChange = (newColor: ChangeColor) => {
        props.setColor(newColor.hex);
    };

    return (
        <div className={styles.formColor}>
            <div className={styles.swatch} onClick={handleClick}>
                <div className={styles.color} style={{ background: props.selectedColor }} />
                <Chevron color={iconColor} className={chevronClass} height={20} width={20} />
            </div>
            {displayColor ? (
                <div className={styles.popover}>
                    <div className={styles.cover} onClick={handleClose} />
                    <SketchPicker color={props.selectedColor} onChange={handleChange} />
                </div>
            ) : null}
        </div>
    );
}

export default ColorPicker;
