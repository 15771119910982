import * as React from "react";

const ZoomInIcon = (props: { color: string }): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
            <g id="zoom_in_icon" transform="translate(1 1)">
                <g id="plus" transform="translate(-0.5)">
                    <g id="Group_8006" data-name="Group 8006">
                        <line
                            id="Line_816"
                            data-name="Line 816"
                            y2="15"
                            transform="translate(8)"
                            fill="none"
                            stroke={props.color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                        <line
                            id="Line_817"
                            data-name="Line 817"
                            x2="15"
                            transform="translate(0.5 7.5)"
                            fill="none"
                            stroke={props.color}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ZoomInIcon;
