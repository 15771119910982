import classNames from "classnames";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Label } from "reactstrap";

import Info from "components/icons/Info";
import localStyle from "components/licenses/assign-bms-keys/form.scss";
import styles from "components/licenses/license-configuration/bms/bms-config.scss";
import Tooltip from "components/tooltip/Tooltip";
import { StoreState } from "store";
import form from "styles/form.scss";

interface Options {
    value: string;
    label: string;
}

interface Props {
    labelKey?: string;
    value: string | number;
    toolTipContent?: string;
    onChange: (newValue: string) => void;
    options: Options[];
}

const mapState = (state: StoreState) => ({
    settings: state.reportViewSettings,
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const BmsOptionsInputComponent = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    return (
        <span className={classNames(styles.inputContainer)}>
            {props.labelKey && (
                <Label htmlFor={"options"} className={classNames(styles.bmsLabel)}>
                    {props.labelKey}
                </Label>
            )}
            <select
                className={classNames(form.select, styles.brandInput)}
                id="options"
                value={props.value}
                onChange={(event) => props.onChange(event.target.value)}
            >
                {props.options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            {props.toolTipContent && (
                <Tooltip content={props.toolTipContent}>
                    <div className={localStyle.info} tabIndex={0}>
                        <Info borderColor={props.theme.contentBackgroundColor} color={props.theme.iconFillColor} />
                    </div>
                </Tooltip>
            )}
        </span>
    );
};

export default connector(BmsOptionsInputComponent);
