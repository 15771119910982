import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import DeleteConfirmationDialog from "components/confirmation/DeleteConfirmationDialog";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { SuccessIcon } from "components/icons/SuccessIcon";
import layoutStyle from "components/layout/layout.scss";
import style from "components/reports/report.scss";
import DeleteItemStatusDialog from "components/status-dialog/DeleteItemStatusDialog";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { reportDeletionService } from "services/report/ReportDeletionService";
import { StoreState } from "store";

import FailedRedNotificationIcon from "assets/images/icons/alertIcon.svg";

interface Result {
    title: string;
    message: string | JSX.Element;
    image?: string | JSX.Element;
}
const SingleReportDeletion = (props: {
    reportUuid: string;
    deletionInvoked: boolean;
    onDeletionInvoked: (invoked: boolean) => void;
    onReportDeleted: () => void;
}): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [result, setResult] = React.useState<Result>({ title: "", message: "", image: "" });
    const [loading, setLoading] = React.useState(false);
    const [reportNotDeleted, setReportNotDeleted] = React.useState<boolean>(false);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
    };

    const handleReportDelete = () => {
        deleteReportEventHandler();
        setReportNotDeleted(false);
    };

    const deleteReportEventHandler = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        reportDeletionService
            .deleteReport(props.reportUuid, abortController)
            .then(() => {
                props.onReportDeleted();
                toast(
                    <SuccessIcon
                        successClass={layoutStyle.customToastSuccessIcon}
                        color={theme.contentBackgroundColor}
                        text={t("DeleteReport.deleteReport.reportDeleted.reportDeletedTitle", {
                            reportUuid: props.reportUuid,
                        })}
                    />,
                    {
                        closeButton: (closeToastProps) => (
                            <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                        ),
                        className: layoutStyle.customToastSuccessMessage,
                    }
                );
            })
            .catch(() => {
                if (!signal.aborted) {
                    const mailToLink = `mailto:${SUPPORT_EMAIL}`;
                    showResult({
                        title: t("DeleteReport.deleteReport.reportDeleted.reportNotDeletedTitle"),
                        message: (
                            <span>
                                {t("DeleteReport.deleteReport.reportDeleted.failureMessage")}
                                <a href={mailToLink}>{t("DeleteReport.deleteReport.reportDeleted.supportMessage")} </a>
                            </span>
                        ),
                        image: <img className={style.image} src={FailedRedNotificationIcon} />,
                    });
                }
                setReportNotDeleted(true);
            })
            .finally(() => {
                setLoading(false);
                props.onDeletionInvoked(false);
            });
    };

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    }, []);

    return (
        <>
            <DeleteItemStatusDialog
                title={result.title}
                modalDisplayed={reportNotDeleted}
                setModalDisplayed={() => setReportNotDeleted(false)}
                handleCommand={deleteReportEventHandler}
                reportUuid={props.reportUuid}
                ditchLink={false}
            />
            <DeleteConfirmationDialog
                modalDisplayed={props.deletionInvoked}
                introductionMessage={t("DeleteReport.deleteReport.introductionMessage", {
                    reportUuid: props.reportUuid,
                })}
                confirmationMessage={t("DeleteReport.deleteMultipleReportsConfirmation")}
                handleCommand={handleReportDelete}
                setModalDisplayed={props.onDeletionInvoked}
                title={t("DeleteReport.deleteMultipleReportsTitle")}
                loading={loading}
            />
        </>
    );
};

export default SingleReportDeletion;
