import * as React from "react";

const Unchecked = (props: { borderColor: string; backgroundColor: string }): JSX.Element => (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.7353 1H4.26471C2.18552 1 0.5 2.68552 0.5 4.76471V13.2353C0.5 15.3145 2.18552 17 4.26471 17H12.7353C14.8145 17 16.5 15.3145 16.5 13.2353V4.76471C16.5 2.68552 14.8145 1 12.7353 1Z"
            fill={props.backgroundColor}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.26482 1.97058C2.72167 1.97058 1.4707 3.22155 1.4707 4.7647V13.2353C1.4707 14.7784 2.72167 16.0294 4.26482 16.0294H12.7354C14.2786 16.0294 15.5295 14.7784 15.5295 13.2353V4.7647C15.5295 3.22155 14.2786 1.97058 12.7354 1.97058H4.26482ZM0.470703 4.7647C0.470703 2.66927 2.16939 0.970581 4.26482 0.970581H12.7354C14.8308 0.970581 16.5295 2.66927 16.5295 4.7647V13.2353C16.5295 15.3307 14.8308 17.0294 12.7354 17.0294H4.26482C2.16939 17.0294 0.470703 15.3307 0.470703 13.2353V4.7647Z"
            fill={props.borderColor}
        />
    </svg>
);

export default Unchecked;
