import * as React from "react";

const UserIcon = (props: { color: string }): JSX.Element => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_397_911)">
                <path
                    d="M8.6 8.00119H7.4C5.96829 8.0027 4.59566 8.57211 3.58329 9.58448C2.57092 10.5968 2.00151 11.9695 2 13.4012C2 13.5603 2.06321 13.7129 2.17574 13.8255C2.28826 13.938 2.44087 14.0012 2.6 14.0012H13.4C13.5591 14.0012 13.7117 13.938 13.8243 13.8255C13.9368 13.7129 14 13.5603 14 13.4012C13.9985 11.9695 13.4291 10.5968 12.4167 9.58448C11.4043 8.57211 10.0317 8.0027 8.6 8.00119Z"
                    fill={props.color}
                />
                <path
                    d="M7.8305 7.661C9.39374 7.661 10.661 6.39374 10.661 4.8305C10.661 3.26726 9.39374 2 7.8305 2C6.26726 2 5 3.26726 5 4.8305C5 6.39374 6.26726 7.661 7.8305 7.661Z"
                    fill={props.color}
                />
            </g>
            <defs>
                <clipPath id="clip0_397_911">
                    <rect width="12" height="12" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default UserIcon;
