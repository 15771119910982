import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "components/licenses/license-configuration/bms/bms-config.scss";
import { NtfConfiguration, Threshold } from "components/licenses/license-configuration/bms/BmsCommonInterfaces";
import InputComponent from "components/licenses/license-configuration/bms/form-components/BmsTextInputComponent";
import { StoreState } from "store";

interface FormValues {
    ntfConfiguration: NtfConfiguration;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const NtfThresholdsForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();

    const updateFormValues = (updateFn: (formValues: FormValues) => FormValues) => {
        props.setFormValues((previousFormValues) => updateFn({ ...previousFormValues }));
    };

    const handleThresholdValueChange = (index: number, fieldName: string, fieldValue: string | boolean) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { thresholds } = ntfConfiguration;
            const updatedThresholds = [...thresholds];
            updatedThresholds[index] = { ...updatedThresholds[index], [fieldName]: fieldValue };

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, thresholds: updatedThresholds } };
        });
    };

    return (
        <>
            <table className={classNames(style.ntfThresholdTable)}>
                <thead>
                    <tr>
                        <th>{t("Common.name")}</th>
                        <th>{t("Common.value")}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.formValues.ntfConfiguration.thresholds.map(
                        (threshold: Threshold, thresholdIndex: number) => (
                            <tr key={threshold.name}>
                                <td className={style.ntfThresholdTableLabel}>
                                    {t(`Configuration.ntfConfigurationForm.${threshold.name.toLowerCase()}`)}
                                </td>
                                <td>
                                    <InputComponent
                                        inputId={"value"}
                                        toolTipContent={t(
                                            `Configuration.tooltips.ntfConfigurationForm.${threshold.name.toLowerCase()}`
                                        )}
                                        value={threshold.value}
                                        onChange={(newValue) =>
                                            handleThresholdValueChange(thresholdIndex, "value", newValue)
                                        }
                                    ></InputComponent>
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </>
    );
};

export default connector(NtfThresholdsForm);
