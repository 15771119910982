import { TFunction } from "i18next";

import {
    CORE_TIER,
    CorporateBundle,
    ENTERPRISE_TIER,
    ESSENTIALS_TIER,
    POWER_TIER,
    PRO_SCALE_TIER,
    PRO_TIER,
    ProcessorBundle,
    SMB_SELECT_PLUS,
    SmbBundle,
} from "components/licenses/bundles";
import { CombinedTier, OldLicensingModelTier, TenantType } from "domain/tenants";

export function generateTenantTypeToTranslationMap(t: TFunction) {
    return new Map<TenantType, string>([
        ["INTERNAL", t("Common.internal")],
        ["CUSTOMER", t("Common.tenant")],
        ["CHANNEL", t("Common.channel")],
        ["CORPORATE", t("Common.corporate")],
        ["PROCESSOR", t("Common.processor")],
        ["SMB", t("Common.smb")],
    ]);
}

export function generateTierToTranslationMap(t: TFunction) {
    return new Map<CombinedTier, string>([
        [OldLicensingModelTier.BASIC, t("AddCustomerForm.pricingTierDialog.header.basic")],
        [OldLicensingModelTier.STANDARD, t("AddCustomerForm.pricingTierDialog.header.standard")],
        [OldLicensingModelTier.ADVANCED, t("AddCustomerForm.pricingTierDialog.header.advanced")],
        [CorporateBundle.ESSENTIALS, t("AddCustomerForm.pricingTierDialog.header.essentials")],
        [CorporateBundle.POWER, t("AddCustomerForm.pricingTierDialog.header.power")],
        [CorporateBundle.ENTERPRISE, t("AddCustomerForm.pricingTierDialog.header.enterprise")],
        [ProcessorBundle.CORE, t("AddCustomerForm.pricingTierDialog.header.core")],
        [ProcessorBundle.PRO, t("AddCustomerForm.pricingTierDialog.header.pro")],
        [ProcessorBundle.PRO_SCALE, t("AddCustomerForm.pricingTierDialog.header.proScale")],
        [SmbBundle.SELECT_50, t("AddCustomerForm.pricingTierDialog.header.select_50")],
        [SmbBundle.SELECT_100, t("AddCustomerForm.pricingTierDialog.header.select_100")],
        [SmbBundle.SELECT_PLUS_250, t("AddCustomerForm.pricingTierDialog.header.select_plus_250")],
        [SmbBundle.SELECT_PLUS_500, t("AddCustomerForm.pricingTierDialog.header.select_plus_500")],
    ]);
}

export const TIER_TO_LICENSES_MAP = new Map<CombinedTier, string[]>([
    ["ESSENTIALS", Object.values(ESSENTIALS_TIER)],
    ["POWER", Object.values(POWER_TIER)],
    ["ENTERPRISE", Object.values(ENTERPRISE_TIER)],
    ["SELECT_50", Object.values(SMB_SELECT_PLUS)],
    ["SELECT_100", Object.values(SMB_SELECT_PLUS)],
    ["SELECT_PLUS_250", Object.values(SMB_SELECT_PLUS)],
    ["SELECT_PLUS_500", Object.values(SMB_SELECT_PLUS)],
    ["CORE", Object.values(CORE_TIER)],
    ["PRO", Object.values(PRO_TIER)],
    ["PRO_SCALE", Object.values(PRO_SCALE_TIER)],
]);
