import React from "react";

const Diamond = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14">
        <path
            id="Diamond"
            d="M5.436,6.017h4.692l-2.37,9.139Zm5.078-.884L12.571,2H8.192Zm4.741.214L13.11,2.335,11.126,5.348Zm-10.8,0L2.618,2.295.266,5.348Zm1.225,0H9.9L7.788,2.522ZM5.068,5.12,7.389,2H3.163Zm5.69.9L8.18,16l7.08-9.983Zm-5.96,0H.26l7.05,9.869Z"
            transform="translate(-0.26 -2)"
            fill="#b54e77"
        />
    </svg>
);

export default Diamond;
