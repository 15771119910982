import { useTranslation } from "react-i18next";

import { ReportSection } from "domain/reports";

import testIds from "testIds.json";

export function getSectionKeys(): Map<string, string> {
    const { t } = useTranslation();
    return new Map<ReportSection, string>([
        ["ERASURE_RESULT", t("ReportTemplate.form.erasureResults")],
        ["SOFTWARE_INFORMATION", t("ReportTemplate.form.softwareInformation")],
        ["REPORT_DETAILS", t("ReportTemplate.form.reportDetails")],
        ["HARDWARE_DETAILS", t("ReportTemplate.form.hardwareDetails")],
        ["CUSTOM_FIELDS", t("ReportTemplate.form.customFields")],
        ["REPORT_SIGNATURE", t("ReportTemplate.form.signature")],
    ]);
}

export function getSectionKeysTestIds(): Map<ReportSection, string> {
    return new Map<ReportSection, string>([
        ["CUSTOM_FIELDS", testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog.customFieldsCheckbox],
        ["ERASURE_RESULT", testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog.erasureResultsCheckbox],
        [
            "HARDWARE_DETAILS",
            testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog.hardwareDetailsCheckbox,
        ],
        [
            "SOFTWARE_INFORMATION",
            testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog.softwareInformationCheckbox,
        ],
        ["REPORT_DETAILS", testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog.reportDetailsCheckbox],
        [
            "REPORT_SIGNATURE",
            testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog.reportSignatureCheckbox,
        ],
    ]);
}

export function getHardwareSectionsKeys(): Map<ReportSection, string> {
    const { t } = useTranslation();

    return new Map<ReportSection, string>([
        ["BATTERY_INFORMATION", t("ReportTemplate.form.batteryInformation")],
        ["DEVICE_COMPONENTS", t("ReportTemplate.form.deviceComponents")],
        ["HARDWARE_FUNCTIONAL_CHECKS", t("ReportTemplate.form.hardwareFunctionalChecks")],
    ]);
}

export function getHardwareSectionsTestIds(): Map<ReportSection, string> {
    return new Map<ReportSection, string>([
        [
            "BATTERY_INFORMATION",
            testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog.batteryInformationCheckbox,
        ],
        [
            "DEVICE_COMPONENTS",
            testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog.deviceComponentsCheckbox,
        ],
        [
            "HARDWARE_FUNCTIONAL_CHECKS",
            testIds.workArea.report.erasure.reportTemplate.manageTemplateDialog.hardwareFunctionalChecksCheckbox,
        ],
    ]);
}
