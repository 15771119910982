import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

import EndpointsView from "./endpoints/EndpointsView";
import IntroductionView from "./introduction/IntroductionView";
import style from "./swagger.scss";
import BackToTop from "components/icons/BackToTop";
import { getPublicApiDocumentationUrl } from "services/login/endpointRepository";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const ApiGuideView = (props: ConnectedProps<typeof connector>): JSX.Element => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className={style.swaggerView}>
            <IntroductionView />
            <EndpointsView />
            <SwaggerUI url={getPublicApiDocumentationUrl()} />
            <div onClick={scrollToTop} className={style.backToTopButton}>
                <a>
                    Back to top <BackToTop color={props.theme.secondaryButtonForegroundColor} />
                </a>
            </div>
        </div>
    );
};

export default connector(ApiGuideView);
