import { apiGatewayService, ApiType } from "../api/ApiGatewayService";
import {
    AllUserGroups,
    AllUserGroupsResponseDto,
    GroupsTable,
    toAllUserGroupData,
    toUserGroupData,
    UserGroupsResponseDto,
} from "domain/userGroups";
import { ParameterQuery } from "utils/commonFunctions";

const PATH_GROUPS = "/api/v1/user-groups";

export interface CreateGroupRequest {
    name: string;
    background_color: string;
    text_color: string;
    role_uuid?: string;
    users?: string[];
}

export interface EditGroupRequest extends CreateGroupRequest {
    uuid: string;
}

export interface CreateGroupDto {
    name: string;
    backgroundColor: string;
    textColor: string;
    abortController: AbortController;
    roleUuid?: string;
    usersList?: string[];
}
export interface EditGroupDto extends CreateGroupDto {
    uuid: string;
}
class UserGroupsService {
    public async fetchGroups(
        abortController: AbortController,
        cursor?: string,
        name?: string | undefined,
        search?: string | undefined
    ): Promise<GroupsTable> {
        return await apiGatewayService
            .invokeApi(
                PATH_GROUPS +
                    new ParameterQuery()
                        .add("search", search)
                        .add("name", name)
                        .add("cursor", cursor)
                        .createQueryString(),
                "GET",
                null,
                {
                    abortController: abortController,
                    apiType: ApiType.LAUREL,
                }
            )
            .then((dto: UserGroupsResponseDto) => {
                return {
                    cursor: dto.cursor,
                    groups: dto.user_groups.map(toUserGroupData),
                };
            })
            .catch(() => {
                return Promise.reject("Failed to fetch user groups.");
            });
    }

    public async fetchAllGroups(abortController: AbortController): Promise<AllUserGroups> {
        return await apiGatewayService
            .invokeApi(PATH_GROUPS + new ParameterQuery().add("all", "true").createQueryString(), "GET", null, {
                abortController: abortController,
                apiType: ApiType.LAUREL,
            })
            .then((dto: AllUserGroupsResponseDto) => {
                return {
                    cursor: dto.cursor,
                    groups: dto.user_groups.map(toAllUserGroupData),
                };
            })
            .catch(() => {
                return Promise.reject("Failed to fetch user groups.");
            });
    }

    public async createGroup(createGroup: CreateGroupDto): Promise<void> {
        const postData: CreateGroupRequest = {
            name: createGroup.name,
            background_color: createGroup.backgroundColor,
            text_color: createGroup.textColor,
        };

        if (createGroup.roleUuid) {
            postData.role_uuid = createGroup.roleUuid;
        }
        if (createGroup.usersList) {
            postData.users = createGroup.usersList;
        }
        return apiGatewayService.invokeApi(PATH_GROUPS, "POST", postData, {
            abortController: createGroup.abortController,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }

    public async editGroup(editGroup: EditGroupDto): Promise<void> {
        const postData: EditGroupRequest = {
            uuid: editGroup.uuid,
            name: editGroup.name,
            background_color: editGroup.backgroundColor,
            text_color: editGroup.textColor,
        };

        if (editGroup.roleUuid) {
            postData.role_uuid = editGroup.roleUuid;
        }
        if (editGroup.usersList) {
            postData.users = editGroup.usersList;
        }
        return apiGatewayService.invokeApi(PATH_GROUPS + "/" + editGroup.uuid, "POST", postData, {
            abortController: editGroup.abortController,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }

    public deleteUserGroup(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(PATH_GROUPS + "/" + uuid, "DELETE", null, {
            abortController: abortController,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }
}

export const userGroupsService = new UserGroupsService();
