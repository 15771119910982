import * as React from "react";
import { FunctionComponent } from "react";
import { Redirect, Route } from "react-router-dom";

import { LOGIN_ROUTE } from "./Routes";

export const SecuredRoute = (props: {
    component: FunctionComponent;
    authenticated: boolean;
    secured: boolean;
    path: string;
    key: number;
}): JSX.Element => {
    return (
        <Route
            exact={true}
            path={props.path}
            key={props.key}
            render={({ location }) =>
                props.authenticated || !props.secured ? (
                    <>{React.createElement(props.component)}</>
                ) : (
                    <Redirect
                        to={{
                            pathname: LOGIN_ROUTE.path,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};
