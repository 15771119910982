import { ChartData } from "chart.js";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import BarChartWidget from "./BarChartWidget";
import widgetStyle from "./chartWidget.scss";
import DoughnutChartWidget from "./DoughnutChartWidget";
import LineChartWidget from "./LineChartWidget";
import style from "./overviewDashboard.scss";
import SingleValueWidget from "./SingleValueWidget";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Tooltip from "components/tooltip/Tooltip";
import { DataAvailability, DataSet, Widget } from "domain/overviewDashboard";
import { overviewDashboardService } from "services/dashboard/OverviewDashboardService";
import { StoreState } from "store";
import { logger } from "utils/logging";

import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

interface ChartProps {
    widget: Widget;
}

interface Legend {
    value: string;
    color: string;
}

type Props = ChartProps & ConnectedProps<typeof connector>;

const ChartWidget = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [chartData, setChartData] = React.useState<ChartData>({});
    const [doughnutChartData, setDoughnutChartData] = React.useState<ChartData>({});
    const [singleValueWidgetData, setSingleValueWidgetData] = React.useState<number | undefined>();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState("");
    const retryWaitDelays = [500, 5000, 300_000];
    const colors: string[] = [
        props.theme.chartFirstSet,
        props.theme.chartSecondSet,
        props.theme.chartThirdSet,
        props.theme.chartForthSet,
        props.theme.chartFifthSet,
        props.theme.chartSixthSet,
        props.theme.chartSeventhSet,
        props.theme.chartEighthSet,
        props.theme.chartNinthSet,
        props.theme.chartTenthSet,
        props.theme.chartEleventhSet,
        props.theme.chartTwelfthSet,
        props.theme.chartSuccessColor,
        props.theme.chartFailColor,
    ];
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [legendLabels, setLegendLabels] = React.useState<Legend[]>();
    const MAX_NUMBER_VALUES = 12;

    function waitFor(milliseconds: number) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }

    function fetchAggregatedData(retries = 0) {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        overviewDashboardService
            .fetchAggregatedData(props.widget.aggregationQuery, abortController)
            .then(async (result) => {
                if (DataAvailability.PENDING == result.dataAvailability && retries < retryWaitDelays.length) {
                    await waitFor(retryWaitDelays[retries]);
                    await fetchAggregatedData(retries + 1);
                } else {
                    if (props.widget.type == "SINGLE_VALUE_CHART") {
                        if (props.widget.aggregationQuery.type === "TIME_AGGREGATED_DOCUMENT_COUNT") {
                            setSingleValueWidgetData(result.dataSets[0].values[0]);
                        }
                    } else if (props.widget.type == "DOUGHNUT_CHART") {
                        setDoughnutChartData(getDoughnutChartData(result.dataSets[0], result.labels));
                    } else {
                        setChartData(getChartData(result.dataSets, result.labels));
                    }
                    setLegendLabels(computeLegendLabels(result.dataSets));
                }

                setLoading(false);
            })
            .catch((e) => {
                logger.error(e);
                setError(t("Overview.widgetErrorMessage"));
                setLoading(false);
            });
    }

    React.useEffect(() => {
        fetchAggregatedData();
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
            retryWaitDelays.forEach((each) => clearTimeout(each));
        };
    }, []);

    const getFormattedLegends = (labels: string[]): string[] => {
        if (typeof labels === "undefined") {
            return [];
        }
        return labels.map((label) => {
            const labelToFormat = moment(label, "", true);
            if (labelToFormat.isValid()) {
                return labelToFormat.format("D MMM YYYY");
            } else {
                return label;
            }
        });
    };

    const getChartData = (dataSets: DataSet[], labels: string[]): ChartData => {
        const formattedLabels = getFormattedLegends(labels);
        const dataLabels: string[] = [];
        //when formattedLabels has values Successful / failed
        const labelValuesToCheck: string[] = ["successful", "passed", "failed"];

        dataSets.forEach((dataSet) => {
            if (dataSet.matcher.value !== undefined) {
                dataLabels.push(dataSet.matcher.value);
            }
        });
        switch (props.widget.aggregationQuery.type) {
            case "TIME_AGGREGATED_DOCUMENT_COUNT":
                return {
                    datasets: dataLabels.map((label, index) => {
                        let applyColorsToChart: string[] = [];
                        const chartLabel = dataSets[index].matcher.value;

                        // possible values "Successful", "failed", "cancelled", "Completed with exceptions"
                        //"2022-01-01T00:00:00Z", 11,50,.....
                        applyColorsToChart = [];
                        formattedLabels.forEach(() => {
                            if (
                                typeof chartLabel == "string" &&
                                labelValuesToCheck.includes(chartLabel.toLowerCase())
                            ) {
                                applyColorsToChart.push(applyChartColors(chartLabel.toLowerCase()));
                            } else {
                                applyColorsToChart.push(colors[index]);
                            }
                        });

                        return {
                            label: label,
                            backgroundColor: applyColorsToChart, //keep existing colors value if there is no success/fail
                            data: dataSets[index].values,
                            fill: false,
                            borderColor: colors[index],
                        };
                    }),
                    labels: formattedLabels,
                };
            case "TERM_AGGREGATED_DOCUMENT_COUNT":
                return {
                    datasets: dataSets.map((set, index) => {
                        const applyColorsToChart: string[] = [];
                        formattedLabels.forEach((value: string) => {
                            if (labelValuesToCheck.includes(value.toLowerCase())) {
                                applyColorsToChart.push(applyChartColors(value));
                            } else {
                                applyColorsToChart.push(colors[index]);
                            }
                        });
                        return {
                            backgroundColor: applyColorsToChart, //keep existing colors value if there is no success/fail
                            data: set.values,
                            fill: false,
                            borderColor: colors[index],
                        };
                    }),
                    labels: formattedLabels,
                };
        }
    };

    const getDoughnutChartData = (dataSet: DataSet, labels: string[]): ChartData => {
        let legendValueMap = new Map();
        const labelValuesToCheck: string[] = ["successful", "passed", "failed"];
        for (let i = 0; i < dataSet.values.length; i++) {
            legendValueMap.set(labels[i], dataSet.values[i]);
        }
        legendValueMap = new Map([...legendValueMap.entries()].sort((a, b) => b[1] - a[1]));
        const numberOfValues = Math.min(legendValueMap.size, MAX_NUMBER_VALUES);
        const reducer = (accumulator: number, current: number) => accumulator + current;
        const sumOfOthers = Array.from(legendValueMap.values())
            .slice(numberOfValues, legendValueMap.size)
            .reduce(reducer, 0);
        const finalLegendValueMap = new Map(Array.from(legendValueMap).slice(0, numberOfValues));
        if (legendValueMap.size > MAX_NUMBER_VALUES) {
            finalLegendValueMap.set(t("Overview.doughnutChartWidget.others"), sumOfOthers);
        }
        const isZero = (currentValue: number) => currentValue === 0;
        if (Array.from(finalLegendValueMap.values()).every(isZero)) {
            const label = t("Common.noDataToDisplay");
            return {
                labels: [label],
                datasets: [
                    {
                        backgroundColor: props.theme.chartNeutralColor,
                        data: [100],
                    },
                ],
            };
        } else {
            labels = Array.from(finalLegendValueMap.keys());
            const applyColorsToChart: string[] = [];
            labels.forEach((value: string) => {
                if (labelValuesToCheck.includes(value.toLowerCase())) {
                    applyColorsToChart.push(applyChartColors(value));
                } else if (labels.includes("cancelled") || labels.includes("Cancelled")) {
                    applyColorsToChart.push(props.theme.chartFirstSet);
                }
            });
            return {
                datasets: [
                    {
                        backgroundColor: applyColorsToChart.length == 0 ? colors : applyColorsToChart, //keep existing colors value if there is no success/fail
                        data: Array.from(finalLegendValueMap.values()),
                    },
                ],
                labels: getFormattedLegends(labels),
            };
        }
    };

    const applyChartColors = (label: string): string => {
        let returnColor = "";
        if (label.toLowerCase() == ("successful" || "passed")) {
            returnColor = props.theme.chartSuccessColor;
        } else if (label.toLowerCase() == "failed") {
            returnColor = props.theme.chartFailColor;
        }
        return returnColor;
    };

    const computeLegendLabels = (dataSets: DataSet[]): Legend[] => {
        const legends: Legend[] = [];
        dataSets.map((set, index: number) => {
            const legend = set.matcher.legend;
            if (typeof legend === "string" && legend.trim().length > 0) {
                legends.push({
                    value: legend,
                    color: colors[index],
                });
            }
        });
        return legends;
    };

    const legend = (
        <div className={widgetStyle.legend}>
            {legendLabels?.map((label, key) => (
                <div key={key} className={widgetStyle.label}>
                    <div className={widgetStyle.dot} style={{ backgroundColor: colors[key] }} />
                    <Tooltip content={label.value}>
                        <span className={widgetStyle.legendValue}>{label.value}</span>
                    </Tooltip>
                </div>
            ))}
        </div>
    );

    let chartComponent = <></>;
    switch (props.widget.type) {
        case "BAR_CHART":
            chartComponent = (
                <>
                    <BarChartWidget chartData={chartData} />
                    {legend}
                </>
            );
            break;
        case "LINE_CHART":
            chartComponent = (
                <>
                    <LineChartWidget chartData={chartData} />
                    {legend}
                </>
            );
            break;
        case "DOUGHNUT_CHART":
            chartComponent = <DoughnutChartWidget chartData={doughnutChartData} />;
            break;
        case "SINGLE_VALUE_CHART":
            chartComponent = <SingleValueWidget value={singleValueWidgetData} />;
            break;
    }

    return (
        <div className={style.widgetContainer} data-testid={testIds.workArea.dashboard.overview.widgetSlot.itself}>
            {error !== "" ? <div>{error}</div> : loading ? <LoadingIndicator /> : <>{chartComponent}</>}
        </div>
    );
};
export default connector(ChartWidget);
