import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import EditRecommendationForm from "./EditRecommedationForm";
import Delete from "components/icons/Delete";
import Edit from "components/icons/Edit";
import style from "components/licenses/license-configuration/bms/bms-config.scss";
import { FaultCode, FaultCodeRecommendation } from "components/licenses/license-configuration/bms/BmsCommonInterfaces";
import {
    getDefaultRecommendations,
    isDefaultRecommendation,
} from "components/licenses/license-configuration/bms/bmsUtils";
import Checkbox from "components/licenses/license-configuration/bms/form-components/BmsCheckboxComponent";
import Modal from "components/modal/Modal";
import { ICON_SIZE_SMALL } from "domain/globalConstants";
import { StoreState } from "store";

interface Props {
    faultCode: FaultCode;
    onFaultCodeChange: (onFaultCodeChange: FaultCode) => void;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const RecommendationForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [showRecommendationModal, setShowRecommendationModal] = useState(false);
    const [selectedRecommendation, setSelectedRecommendation] = useState<FaultCodeRecommendation>({
        key: "",
        text: "",
    });

    const defaultRecommendations = getDefaultRecommendations();

    const getAllRecommendations = () => {
        const allRecommendations = [...props.faultCode.recommendations, ...defaultRecommendations];
        const uniqueRecommendations = Array.from(new Set(allRecommendations.map(({ key }) => key)))
            .map((key) => allRecommendations.find((rec) => rec.key === key))
            .filter((rec): rec is FaultCodeRecommendation => rec !== undefined);

        return uniqueRecommendations.sort((a, b) => a.text.localeCompare(b.text));
    };

    const combinedRecommendations = getAllRecommendations();

    const handleRecommendationChange = (recommendation: FaultCodeRecommendation) => {
        const updatedRecommendations = isChecked(recommendation.key)
            ? props.faultCode.recommendations.filter(({ key }) => key !== recommendation.key)
            : [...props.faultCode.recommendations, recommendation];

        props.onFaultCodeChange({ ...props.faultCode, recommendations: updatedRecommendations });
    };

    const handleRecommendationEdit = (recommendation: FaultCodeRecommendation) => {
        const updatedRecommendations = props.faultCode.recommendations.some(({ key }) => key === recommendation.key)
            ? props.faultCode.recommendations.map((rec) => (rec.key === recommendation.key ? recommendation : rec))
            : [...props.faultCode.recommendations, recommendation];

        props.onFaultCodeChange({ ...props.faultCode, recommendations: updatedRecommendations });
        setSelectedRecommendation(recommendation);
        setShowRecommendationModal(false);
    };

    const handleDeleteRecommendation = (recommendation: FaultCodeRecommendation) => {
        const updatedRecommendations = props.faultCode.recommendations.filter(({ key }) => key !== recommendation.key);
        props.onFaultCodeChange({ ...props.faultCode, recommendations: updatedRecommendations });
    };

    const isChecked = (key: string) => props.faultCode.recommendations.some((rec) => rec.key === key);

    return (
        <div>
            <div className={classNames(style.sticky)}>
                <span>
                    <a
                        onClick={() => {
                            setSelectedRecommendation({ key: "", text: "" });
                            setShowRecommendationModal(true);
                        }}
                    >
                        <u>{t("Configuration.ntfConfigurationForm.addCustomRecommendation")}</u>
                    </a>
                </span>
            </div>
            <br />
            <table className={classNames(style.conditionalQuestionTable)}>
                <tbody>
                    {combinedRecommendations.map((recommendation) => (
                        <tr key={recommendation.key}>
                            <td>
                                <Checkbox
                                    labelKey=""
                                    checkboxId={recommendation.key}
                                    className={style.recommendationCheckbox}
                                    checked={isChecked(recommendation.key)}
                                    disabled={!isDefaultRecommendation(recommendation.key)}
                                    onChange={() => handleRecommendationChange(recommendation)}
                                />
                            </td>
                            <td>{recommendation.text}</td>
                            {!isDefaultRecommendation(recommendation.key) && (
                                <>
                                    <td>
                                        <span
                                            onClick={() => {
                                                setSelectedRecommendation(recommendation);
                                                setShowRecommendationModal(true);
                                            }}
                                            className={classNames(style.actionIcon)}
                                        >
                                            <Edit
                                                color={props.theme.iconFillColor}
                                                width={ICON_SIZE_SMALL}
                                                height={ICON_SIZE_SMALL}
                                            />
                                        </span>
                                    </td>
                                    <td>
                                        <span
                                            onClick={() => handleDeleteRecommendation(recommendation)}
                                            className={classNames(style.actionIcon)}
                                        >
                                            <Delete
                                                color={props.theme.iconFillColor}
                                                width={ICON_SIZE_SMALL}
                                                height={ICON_SIZE_SMALL}
                                            />
                                        </span>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal
                isOpen={showRecommendationModal}
                hideModal={() => setShowRecommendationModal(false)}
                modalTitle={t("Configuration.ntfConfigurationForm.headers.customRecommendation")}
            >
                <EditRecommendationForm
                    recommendation={selectedRecommendation}
                    onRecommendationChange={handleRecommendationEdit}
                    editMode={!!selectedRecommendation.key}
                    recommendationKeys={combinedRecommendations.map(({ key }) => key)}
                />
            </Modal>
        </div>
    );
};

export default connector(RecommendationForm);
