import * as React from "react";
import { useSelector } from "react-redux";

import FailedRedNotificationIcon from "./FailedRedNotificationIcon";
import PassedGreenNotificationIcon from "./PassedGreenNotificationIcon";
import style from "./title-icon.scss";
import WarningYellowNotificationIcon from "./WarningYellowNotificationIcon";
import { StoreState } from "store";

interface Props {
    iconType: TitleIconType;
}

const ICON_SIZE = 24;

export type TitleIconType = "WARNING" | "SUCCESS" | "ERROR";

export const TitleIcon = (props: Props) => {
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    let result;
    switch (props.iconType) {
        case "SUCCESS":
            result = (
                <PassedGreenNotificationIcon
                    backgroundColor={theme.successIconColor}
                    iconColor={theme.contentBackgroundColor}
                    size={ICON_SIZE}
                />
            );
            break;
        case "WARNING":
            result = <WarningYellowNotificationIcon size={ICON_SIZE} />;
            break;
        case "ERROR":
            result = (
                <FailedRedNotificationIcon
                    backgroundColor={theme.errorIconColor}
                    iconColor={theme.contentBackgroundColor}
                    size={ICON_SIZE}
                />
            );
            break;
    }
    return <div className={style.iconContainer}>{result}</div>;
};
