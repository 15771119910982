import * as React from "react";
import { useTranslation } from "react-i18next";

import { AllApiKeysTable } from "./AllApiKeysTable";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { SUPPORT_ROUTE } from "components/router/Routes";

export default function AllApiKeysView(): JSX.Element {
    const { t } = useTranslation();

    return (
        <ApplicationLayout
            viewTitle={t("Common.allApiKeys")}
            view={<AllApiKeysTable initialApiKeyData={{ api_keys: [], cursor: null }} />}
            mainRoute={SUPPORT_ROUTE}
        />
    );
}
