import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./pricing-tier-view.scss";
import { CHECKMARK_SIZE, STROKE_WIDTH, updateSelectedTierTitle } from "./PricingTierView";
import Checkmark from "components/icons/Checkmark";
import Diamond from "components/icons/Diamond";
import Premium from "components/icons/Premium";
import Sparkle from "components/icons/Sparkle";
import StaticTable from "components/support/api-guide/StaticTable";
import { CombinedTier, OldLicensingModelTier } from "domain/tenants";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    selectedTier?: CombinedTier;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const OldModelPricingTierView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const cellData = [
        {
            feature: "Blancco license view and entitlements",
            basic: true,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "Product downloads",
            basic: true,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "Knowledge base",
            basic: true,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "Technical support",
            basic: true,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "Key value store",
            basic: true,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "License alerts",
            basic: true,
            standard: true,
            advanced: true,
            addOns: true,
        },
        {
            feature: "License consumption",
            basic: false,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "BMP APIs",
            basic: false,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "Overview dashboard",
            basic: false,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "Report template library",
            basic: false,
            standard: false,
            advanced: true,
            addOns: true,
        },
        {
            feature: "Reporting (Custom views)",
            basic: false,
            standard: true,
            advanced: true,
            addOns: true,
        },
        {
            feature: "Sustainability dashboard",
            basic: false,
            standard: false,
            advanced: true,
            addOns: true,
        },
        {
            feature: "Intelligent business routing (IBR / Workflows)",
            basic: false,
            standard: false,
            advanced: false,
            addOns: true,
        },
        {
            feature: "Single Sign-On (SSO)",
            basic: false,
            standard: false,
            advanced: false,
            addOns: true,
        },
        {
            feature: "Tenant management",
            basic: false,
            standard: false,
            advanced: false,
            addOns: true,
        },
        {
            feature: "License pools",
            basic: false,
            standard: false,
            advanced: true,
            addOns: true,
        },
        {
            feature: "User groups",
            basic: false,
            standard: true,
            advanced: true,
            addOns: false,
        },
    ];

    return (
        <div className={style.tableContainer}>
            <StaticTable
                headers={[
                    {
                        className: style.firstColumnHeader,
                        value: t("AddCustomerForm.pricingTierDialog.header.feature"),
                    },
                    {
                        className: style.nthColumnHeader,
                        value: (
                            <div className={style.headerContaner}>
                                <div className={style.headerIcon}>
                                    <Sparkle />
                                </div>
                                {updateSelectedTierTitle(
                                    props.selectedTier === OldLicensingModelTier.BASIC,
                                    t("AddCustomerForm.pricingTierDialog.header.basic"),
                                    OldLicensingModelTier.BASIC,
                                    t
                                )}
                            </div>
                        ),
                    },
                    {
                        className: style.nthColumnHeader,
                        value: (
                            <div className={style.headerContaner}>
                                <div className={style.secondHeaderIcon}>
                                    <Premium />
                                </div>
                                {updateSelectedTierTitle(
                                    props.selectedTier === OldLicensingModelTier.STANDARD,
                                    t("AddCustomerForm.pricingTierDialog.header.standard"),
                                    OldLicensingModelTier.STANDARD,
                                    t
                                )}
                            </div>
                        ),
                    },
                    {
                        className: style.nthColumnHeader,
                        value: (
                            <div className={style.headerContaner}>
                                <div className={style.headerIcon}>
                                    <Diamond />
                                </div>
                                {updateSelectedTierTitle(
                                    props.selectedTier === OldLicensingModelTier.ADVANCED,
                                    t("AddCustomerForm.pricingTierDialog.header.advanced"),
                                    OldLicensingModelTier.ADVANCED,
                                    t
                                )}
                            </div>
                        ),
                    },
                    {
                        className: style.nthColumnHeader,
                        value: (
                            <div className={style.headerContaner}>
                                {t("AddCustomerForm.pricingTierDialog.header.addOns")}
                            </div>
                        ),
                    },
                ]}
                styledCells={cellData.map((data, key) => {
                    return [
                        <td key={"feature" + key}>
                            <div>{data.feature}</div>
                        </td>,
                        <td
                            key={"basic" + key}
                            className={classNames({
                                [style.tierFirstLevelColor]: props.selectedTier === OldLicensingModelTier.BASIC,
                            })}
                        >
                            <div className={style.checkMarkCenter}>
                                {data.basic ? (
                                    <Checkmark
                                        color={props.theme.basicTierBannerBorderColor}
                                        size={CHECKMARK_SIZE}
                                        strokeWidth={STROKE_WIDTH}
                                    />
                                ) : null}
                            </div>
                        </td>,
                        <td
                            key={"standard" + key}
                            className={classNames({
                                [style.tierSecondLevelColor]: props.selectedTier === OldLicensingModelTier.STANDARD,
                            })}
                        >
                            <div className={style.checkMarkCenter}>
                                {data.standard ? (
                                    <Checkmark
                                        color={props.theme.premiumTierBannerBorderColor}
                                        size={CHECKMARK_SIZE}
                                        strokeWidth={STROKE_WIDTH}
                                    />
                                ) : null}
                            </div>
                        </td>,
                        <td
                            key={"advanced" + key}
                            className={classNames({
                                [style.tierThirdLevelColor]: props.selectedTier === OldLicensingModelTier.ADVANCED,
                            })}
                        >
                            <div key={"advanced" + key} className={style.checkMarkCenter}>
                                {data.advanced ? (
                                    <Checkmark
                                        color={props.theme.diamondTierBannerBorderColor}
                                        size={CHECKMARK_SIZE}
                                        strokeWidth={STROKE_WIDTH}
                                    />
                                ) : null}
                            </div>
                        </td>,
                        <td key={"addOns" + key}>
                            <div className={style.checkMarkCenter}>
                                {data.addOns ? (
                                    <Checkmark
                                        color={props.theme.addOnsCheckmarkColor}
                                        size={CHECKMARK_SIZE}
                                        strokeWidth={STROKE_WIDTH}
                                    />
                                ) : null}
                            </div>
                        </td>,
                    ];
                })}
                tableClass={style.table}
                testId={testIds.workArea.tenant.pricingTierDialog.table.itself}
            />
        </div>
    );
};
export default connector(OldModelPricingTierView);
