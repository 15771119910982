import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Cross from "components/icons/Cross";
import SearchIcon from "components/icons/Search";
import style from "components/licenses/license-configuration/bms/bms-config.scss";
import { TestConfiguration } from "components/licenses/license-configuration/bms/BmsCommonInterfaces";
import { getPlatformTests } from "components/licenses/license-configuration/bms/bmsUtils";
import searchView from "components/search/search-view.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    onTestSelect: (test: TestConfiguration) => void;
    platform: string;
}

const DiagnosticTestsSearchForm: React.FC<Props> = ({ onTestSelect, platform }) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(0);

    const listRef = useRef<HTMLUListElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const isClearButtonVisible = searchTerm !== "";
    const platformTests = getPlatformTests(platform);
    const filteredTests = platformTests.filter((test) => {
        const testName = t("DiagnosticReportsTable.diagnosticSummary.tests." + test.name).toLowerCase();
        return (
            testName.includes(searchTerm.toLowerCase()) || test.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const handleTestSelection = useCallback(
        (test: TestConfiguration) => {
            onTestSelect(test);
            setSearchTerm("");
            setSelectedItemIndex(null);
        },
        [onTestSelect]
    );

    const handleSearchTermChange = useCallback((newSearchTerm: string) => {
        setSearchTerm(newSearchTerm);
        setSelectedItemIndex(0);
    }, []);

    useEffect(() => {
        if (containerRef.current && selectedItemIndex !== null) {
            const selectedListItem = containerRef.current.querySelector(
                `.listItem:nth-child(${selectedItemIndex + 1})`
            );
            if (selectedListItem) {
                selectedListItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
            }
        }
    }, [selectedItemIndex, containerRef, filteredTests.length]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "ArrowUp" && selectedItemIndex !== null) {
            setSelectedItemIndex(Math.max(selectedItemIndex - 1, 0));
        } else if (e.key === "ArrowDown" && selectedItemIndex !== null) {
            setSelectedItemIndex(Math.min(selectedItemIndex + 1, filteredTests.length - 1));
        } else if (e.key === "Enter" && selectedItemIndex !== null) {
            handleTestSelection(filteredTests[selectedItemIndex]);
        }
    };

    return (
        <div className={classNames(style.searchBarResults)} ref={containerRef}>
            <div className={form.search}>
                <div className={searchView.inputWithButton}>
                    <div className={searchView.searchIcon}>
                        <SearchIcon color={style.iconFillColor} />
                    </div>
                    <input
                        ref={inputRef}
                        type="text"
                        placeholder={t("Common.search")}
                        value={searchTerm}
                        onChange={(e) => handleSearchTermChange(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    {searchTerm && (
                        <ul ref={listRef}>
                            {filteredTests.map((test, index) => (
                                <li
                                    key={test.name}
                                    onClick={() => handleTestSelection(test)}
                                    className={`listItem ${selectedItemIndex === index ? style.selectedItem : ""}`}
                                >
                                    {t("DiagnosticReportsTable.diagnosticSummary.tests." + test.name)}
                                </li>
                            ))}
                        </ul>
                    )}
                    <div
                        data-testid={testIds.common.searchInput.clearButton}
                        className={classNames(
                            isClearButtonVisible ? searchView.clearButton : searchView.hideClearButton
                        )}
                        onClick={() => {
                            setSearchTerm("");
                            setSelectedItemIndex(null);
                        }}
                    >
                        <Cross color={style.secondaryButtonBackgroundColor} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiagnosticTestsSearchForm;
