import * as React from "react";

const TenantAccessIcon = (props: { color: string }): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35.818" height="48.818" viewBox="0 0 48.818 48.818">
            <defs>
                <filter id="Path_1173" x="0" y="0" width="48.818" height="48.818" filterUnits="userSpaceOnUse">
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="tenantAccess" transform="translate(5.25 3.25)">
                <g id="Group_2771" data-name="Group 2771" transform="translate(-633 -570)">
                    <g id="Group_2701" data-name="Group 2701" transform="translate(-84.5 357.5)">
                        <g transform="matrix(1, 0, 0, 1, 712.25, 209.25)" filter="url(#Path_1173)">
                            <path
                                id="Path_1173-2"
                                data-name="Path 1173"
                                d="M172.019,212.13a19.159,19.159,0,1,1-19.159,19.159,19.159,19.159,0,0,1,19.159-19.159Z"
                                transform="translate(-147.61 -208.88)"
                                fill={props.color}
                                stroke={props.color}
                                strokeWidth="1.5"
                            />
                        </g>
                    </g>
                    <g id="log-in_2_" data-name="log-in (2)" transform="translate(639 577)">
                        <path
                            id="Path_1883"
                            data-name="Path 1883"
                            d="M15,3h4a2,2,0,0,1,2,2V19a2,2,0,0,1-2,2H15"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                        <path
                            id="Path_1884"
                            data-name="Path 1884"
                            d="M10,17l5-5L10,7"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                        <line
                            id="Line_749"
                            data-name="Line 749"
                            x1="12"
                            transform="translate(3 12)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default TenantAccessIcon;
