import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { v4 as uuid } from "uuid";

import Delete from "components/icons/Delete";
import DragDropCell from "components/icons/DragDropCell";
import Edit from "components/icons/Edit";
import style from "components/licenses/license-configuration/bms/bms-config.scss";
import { FaultCode, NtfConfiguration } from "components/licenses/license-configuration/bms/BmsCommonInterfaces";
import {
    getDefaultLanguageFaultcodes,
    getDefaultRecommendation,
    isDefaultRecommendation,
    selectLanguageForFaultCodes,
} from "components/licenses/license-configuration/bms/bmsUtils";
import Checkbox from "components/licenses/license-configuration/bms/form-components/BmsCheckboxComponent";
import FaultCodeForm from "components/licenses/license-configuration/bms/ntf/FaultCodeForm";
import defaultConfiguration from "components/licenses/license-configuration/bms/UiConfiguration.json";
import Modal from "components/modal/Modal";
import { ICON_SIZE_SMALL } from "domain/globalConstants";
import * as LanguageRepository from "services/language/languageRepository";
import { StoreState } from "store";
import { getFaultCodeTranslation } from "utils/commonFunctions";

interface FormValues {
    ntfConfiguration: NtfConfiguration;
    platform: string;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const NtfGroupedTestsForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const languages = Object.keys(defaultConfiguration.fault_codes);
    const selectedLanguage = selectLanguageForFaultCodes(LanguageRepository.getLanguage().locale);
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragging, setDragging] = useState<boolean>(false);
    const [faultCodes, setFaultCodes] = useState<FaultCode[]>(
        props.formValues.ntfConfiguration.fault_codes[selectedLanguage] ||
            defaultConfiguration.fault_codes[selectedLanguage]
    );

    const [showAllFaultCodesVisible, setShowAllFaultCodesVisible] = useState<boolean>(false);
    const [showFaultcode, setShowFaultcode] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const new_faultcode: FaultCode = {
        key: "fault_check__custom_faultcode_" + uuid(),
        text: "",
        recommendations: [],
        tests: [],
    };
    const [selectedFaultCode, setSelectedFaultCode] = useState<FaultCode>(new_faultcode);

    const defaultFaultCodes = defaultConfiguration.fault_codes[selectedLanguage];
    const faultCodeKeys = faultCodes.map((faultCode) => faultCode.key);

    const updateFormValues = (updateFn: (formValues: FormValues) => FormValues) => {
        props.setFormValues((previousFormValues) => updateFn({ ...previousFormValues }));
    };

    const handleFaultCodesChange = (faultCodes: FaultCode[]) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const selectedLanguage = selectLanguageForFaultCodes(LanguageRepository.getLanguage().locale);

            const updatedFaultCodes = {
                ...ntfConfiguration.fault_codes,
                [selectedLanguage]: faultCodes,
            };

            return {
                ...previousFormValues,
                ntfConfiguration: {
                    ...ntfConfiguration,
                    fault_codes: updatedFaultCodes,
                },
            };
        });
    };

    const handleFaultCodeCheckboxChange = (key: string, isChecked: boolean) => {
        const selectedFaultCode = defaultFaultCodes.find((faultCode) => faultCode.key === key);

        if (!selectedFaultCode) {
            return;
        }

        const updatedFaultCodesByLanguage = languages.reduce(
            (faultCodesByLanguage: { [key: string]: FaultCode[] }, language) => {
                const currentFaultCodes = props.formValues.ntfConfiguration.fault_codes[language] || [];

                if (isChecked) {
                    const isAlreadyPresent = currentFaultCodes.some((faultCode) => faultCode.key === key);
                    if (!isAlreadyPresent) {
                        const currentLanguageFaultcode = getDefaultLanguageFaultcodes(language).find(
                            (faultCode) => faultCode.key === key
                        );
                        if (currentLanguageFaultcode) {
                            faultCodesByLanguage[language] = [...currentFaultCodes, currentLanguageFaultcode];
                        }
                    } else {
                        faultCodesByLanguage[language] = currentFaultCodes;
                    }
                } else {
                    faultCodesByLanguage[language] = currentFaultCodes.filter((faultCode) => faultCode.key !== key);
                }

                return faultCodesByLanguage;
            },
            {}
        );

        setFaultCodes(updatedFaultCodesByLanguage[selectedLanguage]);

        updateFormValues((previousFormValues) => ({
            ...previousFormValues,
            ntfConfiguration: {
                ...previousFormValues.ntfConfiguration,
                fault_codes: updatedFaultCodesByLanguage,
            },
        }));
    };

    const handleUpdatingFaultCodes = (faultCode: FaultCode) => {
        setFaultCodes((prevFaultCodes) => {
            const index = prevFaultCodes.findIndex((fc) => fc.key === faultCode.key);
            const updatedFaultCodes = [...prevFaultCodes];
            updatedFaultCodes[index] = faultCode;
            updateAllLanguageWithTestsAndRecommendations(faultCode);
            return updatedFaultCodes;
        });

        setShowFaultcode(false);
    };

    const updateAllLanguageWithTestsAndRecommendations = (faultCode: FaultCode) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;

            const updatedFaultCodesByLanguage = languages.reduce((acc: { [key: string]: FaultCode[] }, language) => {
                const currentFaultCodes = ntfConfiguration.fault_codes[language] || [];

                // Update each fault code in the language
                const updatedFaultCodes = currentFaultCodes.map((existingFaultCode) => {
                    if (existingFaultCode.key === faultCode.key) {
                        // Create a new array for updated recommendations
                        const updatedRecommendations = faultCode.recommendations.map((recommendation) => {
                            if (isDefaultRecommendation(recommendation.key)) {
                                // If it's a default recommendation, get the default one for the current language
                                return getDefaultRecommendation(language, recommendation.key) || recommendation;
                            }

                            return (
                                existingFaultCode.recommendations.find((item) => item.key === recommendation.key) ||
                                recommendation
                            );
                        });

                        // Update the fault code with the new recommendations and tests
                        const updatedFaultCode: FaultCode = {
                            ...existingFaultCode,
                            tests: faultCode.tests,
                            recommendations: updatedRecommendations,
                        };

                        return updatedFaultCode;
                    }
                    return existingFaultCode;
                });

                return {
                    ...acc,
                    [language]: updatedFaultCodes,
                };
            }, {});

            return {
                ...previousFormValues,
                ntfConfiguration: {
                    ...ntfConfiguration,
                    fault_codes: updatedFaultCodesByLanguage,
                },
            };
        });
    };

    const handleNewFaultCodes = (faultCode: FaultCode) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const updatedFaultCodesByLanguage = languages.reduce((acc: { [key: string]: FaultCode[] }, language) => {
                const currentFaultCodes = props.formValues.ntfConfiguration.fault_codes[language] || [];
                return {
                    ...acc,
                    [language]: [...currentFaultCodes, faultCode],
                };
            }, {});

            return {
                ...previousFormValues,
                ntfConfiguration: {
                    ...ntfConfiguration,
                    fault_codes: updatedFaultCodesByLanguage,
                },
            };
        });
        setFaultCodes((prevFaultCodes) => [...prevFaultCodes, faultCode]);
        setShowFaultcode(false);
    };

    const handleDragStart = (index: number) => {
        setDraggedIndex(index);
        setDragging(true);
    };

    const handleDragOver = (index: number, event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (draggedIndex !== null && draggedIndex !== index) {
            const updatedFaultCodes = [...faultCodes];
            const [draggedFaultCode] = updatedFaultCodes.splice(draggedIndex, 1);
            updatedFaultCodes.splice(index, 0, draggedFaultCode);
            setFaultCodes(updatedFaultCodes);
            setDraggedIndex(index);
            handleFaultCodesChange(updatedFaultCodes);
        }
    };

    const handleDrop = () => {
        setDraggedIndex(null);
        setDragging(false);
    };

    const handleDeleteFaultCode = (index: number) => {
        const updatedFaultCodes = [...faultCodes];
        const faultCodeToDelete = updatedFaultCodes[index].key;

        updatedFaultCodes.splice(index, 1);

        setFaultCodes(updatedFaultCodes);
        handleFaultCodesChange(updatedFaultCodes);

        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const updatedFaultCodesByLanguage = { ...ntfConfiguration.fault_codes };

            languages.forEach((language) => {
                const faultCodesForLanguage =
                    updatedFaultCodesByLanguage[language] || defaultConfiguration.fault_codes[selectedLanguage];

                updatedFaultCodesByLanguage[language] = faultCodesForLanguage.filter(
                    (faultCode) => faultCode.key !== faultCodeToDelete
                );
            });

            return {
                ...previousFormValues,
                ntfConfiguration: {
                    ...ntfConfiguration,
                    fault_codes: updatedFaultCodesByLanguage,
                },
            };
        });
    };

    const handleEditFaultCode = (faultCode: FaultCode) => {
        setSelectedFaultCode(faultCode);
        setEditMode(true);
        setShowFaultcode(true);
    };

    const hideShowFaultcode = () => {
        setShowFaultcode(false);
    };

    const showAllFaultCodesModal = () => {
        setShowAllFaultCodesVisible(true);
    };

    const hideAllFaultCodesModal = () => {
        setShowAllFaultCodesVisible(false);
    };

    const showCustomFaultCodesModal = () => {
        setSelectedFaultCode(new_faultcode);
        setShowFaultcode(true);
    };

    const isSelected = (key: string) => {
        return faultCodes.some((faultCode) => faultCode.key === key);
    };

    const handleFaultCodesUpdate = () => {
        if (handleFaultCodesChange) {
            handleFaultCodesChange(faultCodes);
        }
    };

    const createNewFaultCode = () => {
        setSelectedFaultCode(new_faultcode);
        setEditMode(false);
        showCustomFaultCodesModal();
    };

    useEffect(() => {
        handleFaultCodesUpdate();
    }, [faultCodes]);

    return (
        <div>
            <table className={classNames(style.conditionalQuestionTable)}>
                <tbody>
                    {faultCodes.map((faultCode: FaultCode, index: number) => (
                        <tr
                            key={faultCode.key}
                            draggable
                            onDragStart={() => handleDragStart(index)}
                            onDragOver={(e) => handleDragOver(index, e)}
                            onDrop={handleDrop}
                            className={dragging && draggedIndex === index ? style.draggedRow : ""}
                        >
                            <td className={classNames(style.grab)}>
                                <DragDropCell color={props.theme.iconFillColor} />
                            </td>
                            <td> {faultCode.text ? faultCode.text : t(getFaultCodeTranslation(faultCode.key))} </td>
                            <td>
                                <span
                                    onClick={() => handleEditFaultCode(faultCode)}
                                    className={classNames(style.actionIcon)}
                                >
                                    <Edit
                                        color={props.theme.iconFillColor}
                                        width={ICON_SIZE_SMALL}
                                        height={ICON_SIZE_SMALL}
                                    />
                                </span>
                            </td>
                            <td>
                                <span
                                    onClick={() => handleDeleteFaultCode(index)}
                                    className={classNames(style.actionIcon)}
                                >
                                    <Delete
                                        color={props.theme.iconFillColor}
                                        width={ICON_SIZE_SMALL}
                                        height={ICON_SIZE_SMALL}
                                    />
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <span>
                <a onClick={showAllFaultCodesModal}>
                    <u>{t("Configuration.common.showAllLink")}</u>
                </a>
            </span>
            <span className={classNames(style.addCustomConfigurationButton)}>
                <a onClick={createNewFaultCode}>
                    <u>{t("Configuration.ntfConfigurationForm.addCustomFaultCode")}</u>
                </a>
            </span>

            <Modal
                isOpen={showAllFaultCodesVisible}
                hideModal={hideAllFaultCodesModal}
                modalTitle={t("Configuration.ntfConfigurationForm.allFaultCodesModalTitle")}
            >
                <div className={classNames(style.allFaultCodes)}>
                    {defaultFaultCodes.map((faultCode: FaultCode) => (
                        <Checkbox
                            labelKey={t(getFaultCodeTranslation(faultCode.key))}
                            key={faultCode.key}
                            checkboxId={faultCode.key}
                            checked={isSelected(faultCode.key)}
                            onChange={(newValue) => handleFaultCodeCheckboxChange(faultCode.key, newValue)}
                        />
                    ))}
                </div>
            </Modal>
            <Modal
                isOpen={showFaultcode}
                hideModal={hideShowFaultcode}
                modalTitle={t("Configuration.ntfConfigurationForm.allFaultTestsModalTitle")}
            >
                <FaultCodeForm
                    platform={props.formValues.platform}
                    faultCode={selectedFaultCode}
                    faultCodeKeys={faultCodeKeys}
                    editMode={editMode}
                    faultCodeHandler={editMode ? handleUpdatingFaultCodes : handleNewFaultCodes}
                    ntfConfiguration={props.formValues.ntfConfiguration}
                />
            </Modal>
        </div>
    );
};

export default connector(NtfGroupedTestsForm);
