import React from "react";

const Globe = (props: { className: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className={props.className}>
            <g id="globe_icon_small_screen_language" transform="translate(1 1)">
                <ellipse
                    id="Ellipse_407"
                    data-name="Ellipse 407"
                    cx="8"
                    cy="7.996"
                    rx="8"
                    ry="7.996"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_1511"
                    data-name="Line 1511"
                    x2="16"
                    transform="translate(0 8.529)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_5008"
                    data-name="Path 5008"
                    d="M11.209,2a12.221,12.221,0,0,1,3.209,8,12.221,12.221,0,0,1-3.209,8A12.221,12.221,0,0,1,8,10a12.221,12.221,0,0,1,3.209-8Z"
                    transform="translate(-3.205 -2)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};

export default Globe;
