import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UserDetails } from "domain/user";

export interface UserState {
    user: UserDetails | null;
}

const INITIAL_STATE: UserState = {
    user: null,
};

const USER_SLICE = createSlice({
    name: "userStore",
    initialState: INITIAL_STATE,
    reducers: {
        setUser(state, action: PayloadAction<UserDetails>) {
            state.user = action.payload;
        },
        removeUser(state) {
            state.user = null;
        },
    },
});

export const { setUser, removeUser } = USER_SLICE.actions;

export default USER_SLICE.reducer;
