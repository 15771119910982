import React from "react";
import { useTranslation } from "react-i18next";

import style from "./hamburger-menu-icon.scss";

interface Props {
    isOpen: boolean;
}

const HamburgerMenuIcon = (props: Props) => {
    const { t } = useTranslation();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="19"
            viewBox="0 0 31 19"
            className={props.isOpen ? style.hamburgerMenuOpen : ""}
        >
            <title>{props.isOpen ? t("AltText.closeMobileNavigation") : t("AltText.openMobileNavigation")}</title>
            <g id="hamburger_menu_ios" transform="translate(1.5 1.5)">
                <line
                    id="Line_51"
                    data-name="Line 51"
                    x2="28"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="3"
                />
                <line
                    id="Line_51-2"
                    data-name="Line 51"
                    x2="28"
                    transform="translate(0 8)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="3"
                />
                <line
                    id="Line_51-3"
                    data-name="Line 51"
                    x2="28"
                    transform="translate(0 16)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="3"
                />
            </g>
        </svg>
    );
};

export default HamburgerMenuIcon;
