import * as React from "react";

const Chevron = (props: { color: string; className: string; height?: number; width?: number }): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 24}
            height={props.height ? props.height : 24}
            viewBox="0 0 24 24"
            fill="none"
            stroke={props.color}
            className={props.className}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <polyline points="6 9 12 15 18 9" />
        </svg>
    );
};

export default Chevron;
