import { getObject, remove, RepositoryKey, setObject } from "utils/repository";

export function hasAnyBmsLicenses(): boolean {
    const bmsLicense: boolean | null = getObject(RepositoryKey.BMS_LICENSES);
    if (bmsLicense == null) {
        return false;
    }
    return bmsLicense;
}

export function setBmsLicenses(bmsLicense: boolean): void {
    setObject(RepositoryKey.BMS_LICENSES, bmsLicense);
}

export function hasOnlyBms(): boolean {
    const onlyBms: boolean | null = getObject(RepositoryKey.ONLY_BMS);
    if (onlyBms == null) {
        return false;
    }
    return onlyBms;
}
export function setOnlyBms(onlyBmsLicense: boolean): void {
    setObject(RepositoryKey.ONLY_BMS, onlyBmsLicense);
}

export function clear(): void {
    remove(RepositoryKey.BMS_LICENSES);
    remove(RepositoryKey.ONLY_BMS);
}

export function setHasEmsId(emsId: boolean) {
    setObject(RepositoryKey.EMS_ID, emsId);
}

export function hasEmsId(): boolean {
    const emsId: boolean | null = getObject(RepositoryKey.EMS_ID);
    if (emsId == null) {
        return false;
    }
    return emsId;
}
