import * as React from "react";

const DragDrop = (): JSX.Element => {
    return (
        <svg
            id="Component_202_1"
            data-name="Component 202 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="89.576"
            height="59.717"
            viewBox="0 0 89.576 59.717"
        >
            <path
                id="Path_4184"
                data-name="Path 4184"
                d="M393.55,155.576a27.948,27.948,0,0,0-52.253-7.465,22.368,22.368,0,0,0,2.427,44.606h48.52a18.718,18.718,0,0,0,18.662-18.662A18.434,18.434,0,0,0,393.55,155.576Zm-21.462,11.006v14.183H360.145V166.582h-12.69l18.662-18.653,18.662,18.662Z"
                transform="translate(-321.33 -133)"
                fill="#fff"
            />
        </svg>
    );
};

export default DragDrop;
