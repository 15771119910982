import classNames from "classnames";
import React from "react";
import { connect, ConnectedProps } from "react-redux";

import Info from "components/icons/Info";
import localStyle from "components/licenses/assign-bms-keys/form.scss";
import style from "components/licenses/license-configuration/bms/bms-config.scss";
import Tooltip from "components/tooltip/Tooltip";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    settings: state.reportViewSettings,
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

interface Props {
    labelKey: string;
    toolTipContent?: string;
}

const BmsSectionHeader = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    return (
        <div className={classNames(style.header)}>
            {props.labelKey}
            {props.toolTipContent && (
                <Tooltip content={props.toolTipContent} placement={"right-start"}>
                    <span className={localStyle.info} tabIndex={0}>
                        <Info borderColor={props.theme.contentBackgroundColor} color={props.theme.iconFillColor} />
                    </span>
                </Tooltip>
            )}
        </div>
    );
};

export default connector(BmsSectionHeader);
