import classNames from "classnames";
import * as React from "react";

import style from "./static-table.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";

interface Header {
    className?: string;
    value: string | JSX.Element;
}

interface Props {
    headers: Array<Header>;
    cells?: Array<Array<string | JSX.Element | null>>;
    loading?: boolean;
    tableClass?: string;
    testId?: string;
    emptyStateMessage?: string;
    styledCells?: Array<Array<string | JSX.Element | null>>;
}

export default function StaticTable(props: Props): JSX.Element {
    const headers = props.headers.map((each, index) => {
        return (
            <th className={each.className} key={index}>
                {each.value}
            </th>
        );
    });

    const rows =
        typeof props.styledCells !== "undefined"
            ? props.styledCells.map((row, rowIndex) => {
                  return <tr key={rowIndex}>{row}</tr>;
              })
            : props.cells?.map((row, rowIndex) => {
                  const cells = row.map((each, cellIndex) => <td key={cellIndex}>{each}</td>);
                  return <tr key={rowIndex}>{cells}</tr>;
              });

    return props.loading ? (
        <LoadingIndicator />
    ) : (
        <table data-testid={props.testId} className={classNames(style.table, props.tableClass)}>
            <thead>
                <tr>{headers}</tr>
            </thead>
            <tbody>
                {rows?.length === 0 && props.emptyStateMessage ? (
                    <tr>
                        <td className={style.emptyStateMessage} colSpan={100}>
                            {props.emptyStateMessage}
                        </td>
                    </tr>
                ) : (
                    rows
                )}
            </tbody>
        </table>
    );
}
