import * as React from "react";

const BarChartIcon = (props: { borderColor: string; fillColor: string }): JSX.Element => {
    return (
        <svg id="chart_bar" xmlns="http://www.w3.org/2000/svg" width="72" height="48" viewBox="0 0 72 48">
            <g
                id="Rectangle_1712"
                data-name="Rectangle 1712"
                fill={props.fillColor}
                stroke={props.borderColor}
                strokeWidth="1"
            >
                <rect width="72" height="48" rx="6" stroke="none" />
                <rect x="0.5" y="0.5" width="71" height="47" rx="5.5" fill="none" />
            </g>
            <path
                id="Path_2253"
                data-name="Path 2253"
                d="M17.286,23.091h4.429V42H17.286Zm7.643,3.939h4.429V42H24.929ZM9.643,31.394h4.429V42H9.643ZM32.571,16H37V42H32.571Z"
                transform="translate(12.5 -5)"
                fill={props.fillColor}
                stroke={props.borderColor}
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
            />
        </svg>
    );
};

export default BarChartIcon;
