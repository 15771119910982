import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import ApplicationLayout from "components/layout/ApplicationLayout";
import { WORKFLOWS_ROUTE } from "components/router/Routes";
import AllWorkflowsTable from "components/workflows/AllWorkflowsTable";
import CreateWorkflowView from "components/workflows/CreateWorkflowView";
import ImportWorkflowView from "components/workflows/ImportWorkflowView";
import { AUTH_WORKFLOW_CREATE } from "domain/authority";
import { FilterState } from "domain/workflows";
import { userSessionService } from "services/user/UserSessionService";
import { workflowService } from "services/workflows/WorkflowService";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    user: state.userReducer.user,
});
const connector = connect(mapState);

const AllWorkflowsView = (props: ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [state, setState] = React.useState({ count: 0 });

    function updateState() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }
    const [filterState, setFilterState] = React.useState<FilterState>({
        from: "",
        to: "",
    });

    const viewActions = userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_CREATE]) ? (
        <>
            <ImportWorkflowView user={props.user} onUpdate={updateState} />
            <CreateWorkflowView user={props.user} onUpdate={updateState} />
        </>
    ) : undefined;

    const layoutProps = {
        viewTitle: t("Common.workflowsTitle"),
        view: (
            <AllWorkflowsTable
                filterState={filterState}
                setFilterState={setFilterState}
                workflowService={workflowService}
                onUpdate={updateState}
                count={state.count}
                search=""
            />
        ),
        mainRoute: WORKFLOWS_ROUTE,
        viewActions,
        enableRefresh: true,
        onHandleRefresh: updateState,
    };
    return <ApplicationLayout {...layoutProps} />;
};
export default connector(AllWorkflowsView);
