import React from "react";

export interface Props {
    backgroundColor: string;
    iconColor: string;
    size?: number;
}

const PassedGreenNotificationIcon = (props: Props): JSX.Element => {
    return (
        <svg
            width={props.size || 20}
            height={props.size || 20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icons/alert">
                <path
                    id="Ellipse"
                    d="M9.99984 18.3334C14.6022 18.3334 18.3332 14.6025 18.3332 10.0001C18.3332 5.39771 14.6022 1.66675 9.99984 1.66675C5.39746 1.66675 1.6665 5.39771 1.6665 10.0001C1.6665 14.6025 5.39746 18.3334 9.99984 18.3334Z"
                    fill={props.backgroundColor}
                />
                <path
                    id="Vector 7 (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.867 6.85992C14.2205 7.15455 14.2683 7.68003 13.9737 8.03359L9.80701 13.0336C9.65712 13.2135 9.43848 13.322 9.20459 13.3326C8.9707 13.3432 8.74313 13.2549 8.57757 13.0894L6.07757 10.5894C5.75214 10.2639 5.75214 9.73628 6.07757 9.41085C6.40301 9.08541 6.93065 9.08541 7.25609 9.41085L9.11083 11.2656L12.6933 6.96662C12.9879 6.61305 13.5134 6.56528 13.867 6.85992Z"
                    fill={props.iconColor}
                />
            </g>
        </svg>
    );
};
export default PassedGreenNotificationIcon;
