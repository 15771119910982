import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import BbtiProviderForm from "./bbti/BbtiProviderForm";
import { BBTIConfiguration, Brand, ComponentFeatures, ConditionAssessmentQuestions, Test } from "./BmsCommonInterfaces";
import ConditionAssessmentQuestionsForm from "./diagnostics/ConditionAssessmentQuestionsForm";
import DiagnosticsTestsForm from "./diagnostics/DiagnosticsTestsForm";
import DeviceIdentifierCheckboxes from "./form-components/BmsDeviceIdentifierCheckboxComponent";
import BmsSectionHeader from "./form-components/BmsSectionHeader";
import { StoreState } from "store";

interface FormValues {
    brand: Brand;
    platform: string;
    componentFeatures: ComponentFeatures;
    bbtiConfiguration: BBTIConfiguration;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const BbtiConfigurationForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();

    const updateFormValues = (updateFn: (formValues: FormValues) => FormValues) => {
        props.setFormValues((previousFormValues) => updateFn({ ...previousFormValues }));
    };

    const handleCheckboxChange = (fieldName: string, isChecked: boolean) => {
        updateFormValues((previousFormValues) => {
            const { bbtiConfiguration } = previousFormValues;

            return { ...previousFormValues, bbtiConfiguration: { ...bbtiConfiguration, [fieldName]: isChecked } };
        });
    };

    const handleProviderQuestionsChange = (providerIndex: number, questions: ConditionAssessmentQuestions) => {
        updateFormValues((previousFormValues) => {
            const { bbtiConfiguration } = previousFormValues;
            const { providers } = bbtiConfiguration;
            const updatedProviders = [...providers];
            updatedProviders[providerIndex] = { ...updatedProviders[providerIndex], questions };

            return { ...previousFormValues, bbtiConfiguration: { ...bbtiConfiguration, providers: updatedProviders } };
        });
    };

    const handleTestsChange = (tests: Test[]) => {
        updateFormValues((previousFormValues) => {
            const { bbtiConfiguration } = previousFormValues;

            return { ...previousFormValues, bbtiConfiguration: { ...bbtiConfiguration, tests: tests } };
        });
    };

    return (
        <div>
            <BmsSectionHeader labelKey={t("Configuration.common.headers.defaultSettings")} />
            <DeviceIdentifierCheckboxes
                serialMandatory={props.formValues.bbtiConfiguration.serialMandatory}
                imeiMandatory={props.formValues.bbtiConfiguration.imeiMandatory}
                onChange={handleCheckboxChange}
            />

            <BmsSectionHeader
                labelKey={t("Configuration.common.headers.conditionAssessmentQuestions")}
                toolTipContent={t("Configuration.tooltips.common.conditionAssessmentQuestions")}
            />
            {props.formValues.bbtiConfiguration.providers.map((provider, providerIndex) => (
                <div key={provider.name} id={"provider-container"}>
                    <ConditionAssessmentQuestionsForm
                        currentQuestions={props.formValues.bbtiConfiguration.providers[0].questions}
                        onQuestionsChange={(questions) => handleProviderQuestionsChange(providerIndex, questions)}
                    />
                </div>
            ))}

            <BmsSectionHeader
                labelKey={t("Configuration.common.headers.diagnosticsTests")}
                toolTipContent={t("Configuration.tooltips.common.diagnosticsTests")}
            />
            <DiagnosticsTestsForm
                currentEnabledTests={props.formValues.bbtiConfiguration.tests}
                onTestChange={handleTestsChange}
                platform={props.formValues.platform}
            />

            <BmsSectionHeader labelKey={t("Configuration.common.headers.provider")} />
            <BbtiProviderForm formValues={props.formValues} setFormValues={props.setFormValues} />
        </div>
    );
};

export default connector(BbtiConfigurationForm);
