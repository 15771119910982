import * as React from "react";
import { Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";

import Co2EquivalentsChart from "./Co2EquivalentsChart";
import { commonFunctions } from "./CommonFunctions";
import style from "components/dashboard/dashboard.scss";
import EmissionsPreventedChart from "components/dashboard/esg/EmissionsPreventedChart";
import LandfillWastePreventedChart from "components/dashboard/esg/LandfillWastePreventedChart";
import TotalProcessedDevicesChart from "components/dashboard/esg/TotalProcessedDevicesChart";
import { WidgetContainer } from "components/dashboard/WidgetContainer";
import { EsgData, WidgetDetails } from "domain/dashboard";
import { DRAGGABLE_CARD_HANDLE_SELECTOR } from "domain/globalConstants";
import * as DashboardRepository from "services/dashboard/dashboardRepository";
import { dashboardService } from "services/dashboard/DashboardService";

import testIds from "testIds.json";

interface CardLayout {
    cols: Record<string, number>;
    rowHeight: number;
    isResizable: boolean;
    margin: [number, number];
    isBounded: boolean;
}

interface State {
    dashboardData: EsgData;
    dataFetched: boolean;
}

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const Esg = (props: CardLayout): JSX.Element => {
    const { t } = useTranslation();

    const landfillWastePrevented = "landfillWastePrevented";
    const emissionsPrevented = "emissionsPrevented";
    const totalProcessedDevices = "totalProcessedDevices";
    const co2Equivalents = "co2Equivalents";
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [state, setState] = React.useState<State>({
        dashboardData: { months: [] },
        dataFetched: false,
    });
    const convertToTonnes = commonFunctions.needsToBeConvertedToTonnes(state.dashboardData.months);

    function fetchDashboardData() {
        const abortController = new AbortController();
        dashboardService
            .fetchDashboardData(abortController)
            .then((data) => {
                setState({ dashboardData: data, dataFetched: true });
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("EsgDashboardView.requestFailed"));
                }
            });
    }

    const widgets: WidgetDetails[] = [
        {
            key: landfillWastePrevented,
            title: "EsgDashboardView.landfillWastePreventedChart.landfillWastePrevented",
            children: (
                <LandfillWastePreventedChart
                    dataFetched={state.dataFetched}
                    widgetData={state.dashboardData.months}
                    convertToTonnes={convertToTonnes}
                />
            ),
            testId: testIds.workArea.dashboard.sustainability.cards.landfillWastePrevented.itself,
        },
        {
            key: emissionsPrevented,
            title: "EsgDashboardView.co2EmissionsPreventedChart.emissionsPrevented",
            children: (
                <EmissionsPreventedChart
                    dataFetched={state.dataFetched}
                    widgetData={state.dashboardData.months}
                    convertToTonnes={convertToTonnes}
                />
            ),
            testId: testIds.workArea.dashboard.sustainability.cards.co2EmissionsPrevented.itself,
        },
        {
            key: totalProcessedDevices,
            title: "EsgDashboardView.totalOfProcessedDevicesChart.totalProcessedDevices",
            children: (
                <TotalProcessedDevicesChart
                    dataFetched={state.dataFetched}
                    widgetData={state.dashboardData.months}
                    convertToTonnes={convertToTonnes}
                />
            ),
            testId: testIds.workArea.dashboard.sustainability.cards.totalManagedDevices.itself,
        },
        {
            key: co2Equivalents,
            title: "EsgDashboardView.co2EquivalentsChart.co2Equivalents",
            children: <Co2EquivalentsChart dataFetched={state.dataFetched} widgetData={state.dashboardData.months} />,
            testId: testIds.workArea.dashboard.sustainability.cards.co2Equivalents.itself,
        },
    ];

    const defaultLayout = {
        lg: [
            { w: 1, h: 17, x: 0, y: 0, i: landfillWastePrevented },
            { w: 1, h: 17, x: 1, y: 0, i: emissionsPrevented },
            { w: 1, h: 17, x: 0, y: 17, i: totalProcessedDevices },
            { w: 1, h: 17, x: 1, y: 35, i: co2Equivalents },
        ],
        md: [
            { w: 1, h: 17, x: 0, y: 0, i: landfillWastePrevented },
            { w: 1, h: 17, x: 1, y: 0, i: emissionsPrevented },
            { w: 1, h: 17, x: 0, y: 17, i: totalProcessedDevices },
            { w: 1, h: 17, x: 1, y: 35, i: co2Equivalents },
        ],
        sm: [
            { w: 1, h: 15, x: 0, y: 0, i: landfillWastePrevented },
            { w: 1, h: 15, x: 1, y: 0, i: emissionsPrevented },
            { w: 1, h: 15, x: 0, y: 15, i: totalProcessedDevices },
            { w: 1, h: 15, x: 1, y: 30, i: co2Equivalents },
        ],
        xs: [
            { w: 1, h: 15, x: 0, y: 0, i: landfillWastePrevented },
            { w: 1, h: 15, x: 1, y: 15, i: emissionsPrevented },
            { w: 1, h: 15, x: 2, y: 30, i: totalProcessedDevices },
            { w: 1, h: 15, x: 3, y: 45, i: co2Equivalents },
        ],
        xxs: [
            { w: 1, h: 15, x: 0, y: 0, i: landfillWastePrevented },
            { w: 1, h: 15, x: 1, y: 15, i: emissionsPrevented },
            { w: 1, h: 15, x: 2, y: 30, i: totalProcessedDevices },
            { w: 1, h: 15, x: 3, y: 45, i: co2Equivalents },
        ],
    };

    const layout: Layouts = DashboardRepository.getESGCardOrderLayout() || defaultLayout;
    React.useEffect(() => {
        fetchDashboardData();
    }, []);
    if (requestFailureMessage != "") {
        return <div className={style.failureMessage}>{requestFailureMessage}</div>;
    } else {
        return (
            <>
                <div className={style.dashboardViewDescription}>{t("EsgDashboardView.blanccosImpactDescriptions")}</div>
                <ResponsiveReactGridLayout
                    className={style.dashboardViewContainer}
                    draggableHandle={DRAGGABLE_CARD_HANDLE_SELECTOR}
                    layouts={layout}
                    onLayoutChange={(_layout, layouts) => DashboardRepository.setESGCardOrderLayout(layouts)}
                    {...props}
                >
                    {widgets.map((widget) => {
                        return (
                            <div key={widget.key} className={style.cardContainer} data-testid={widget.testId}>
                                <WidgetContainer {...widget}>{widget.children}</WidgetContainer>
                            </div>
                        );
                    })}
                </ResponsiveReactGridLayout>
            </>
        );
    }
};

Esg.defaultProps = {
    cols: { lg: 2, md: 2, sm: 2, xs: 1, xxs: 1 },
    margin: [40, 40],
    containerPadding: [0, 0],
    isBounded: true,
    rowHeight: 1,
    isResizable: false,
};

export default Esg;
