// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "body ._2BPzw7VJfA0eQ2-NnNs_2h{margin-top:0}body .Da2dskMYeg2CSSSGe1oj_{margin-bottom:0}body ._1qNFY133jEcLeXfCYn3Ar-{margin-top:16px}body .sPLEh5HWPSgoflpZ6uc2T{margin-top:20px}body ._2LCW9JhHqUCdL7O_hSfA2R{margin-top:32px}body ._1aNSkAvar5qSx1DrfxQLqG{margin-bottom:16px}body ._2ggyAxpzmMLUM0gEEeVsKI{margin-bottom:20px}body ._2F3yi-peDrQBs_8SQse27o{margin-bottom:32px}body ._30sqXsV4Amj3832PF6uaxS{justify-content:left}body ._272OuaPJbh4mjrB1XkiWrK{justify-content:right}body ._37I7-WdKMOp3jdKfXlhw1b{justify-content:center}body ._1Lq_crTg74_EMNi0YlO2u8{text-align:center}", ""]);
// Exports
exports.locals = {
	"breakpointSm": "580px",
	"breakpointMd": "768px",
	"breakpointLg": "992px",
	"breakpointXl": "1200px",
	"breakpointXxl": "1600px",
	"noSpacingTop": "_2BPzw7VJfA0eQ2-NnNs_2h",
	"noSpacingBottom": "Da2dskMYeg2CSSSGe1oj_",
	"spacingTopSm": "_1qNFY133jEcLeXfCYn3Ar-",
	"spacingTopMd": "sPLEh5HWPSgoflpZ6uc2T",
	"spacingTopLg": "_2LCW9JhHqUCdL7O_hSfA2R",
	"spacingBottomSm": "_1aNSkAvar5qSx1DrfxQLqG",
	"spacingBottomMd": "_2ggyAxpzmMLUM0gEEeVsKI",
	"spacingBottomLg": "_2F3yi-peDrQBs_8SQse27o",
	"justifyLeft": "_30sqXsV4Amj3832PF6uaxS",
	"justifyRight": "_272OuaPJbh4mjrB1XkiWrK",
	"justifyCenter": "_37I7-WdKMOp3jdKfXlhw1b",
	"textAlignCenter": "_1Lq_crTg74_EMNi0YlO2u8"
};
module.exports = exports;
