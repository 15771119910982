import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "components/import-file-dialog/import-file-dialog.scss";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

interface Props {
    failureMessage: string;
    onUploadAnotherClicked: () => void;
    onClose?: () => void;
}

const FailedView = (props: Props & ConnectedProps<typeof connector>) => {
    const { t } = useTranslation();

    const handleOkClick = () => {
        if (props.onClose) {
            props.onClose();
        }
    };
    return (
        <div className={style.leftAlignedWrapper}>
            <div className={style.introductionLabel}>{props.failureMessage}</div>
            <div className={classNames(style.buttonContainer)}>
                <button
                    className={classNames(buttons.primaryButton, buttons.medium, style.button)}
                    data-testid={testIds.common.importFileDialog.uploadAnotherButton}
                    onClick={() => props.onUploadAnotherClicked()}
                >
                    {t("Common.uploadAnotherFile")}
                </button>
                {props.onClose && (
                    <button
                        className={classNames(buttons.secondaryButton, buttons.medium, style.button)}
                        data-testid={testIds.common.dialog.closeButton}
                        onClick={() => handleOkClick()}
                    >
                        {t("Common.ok")}
                    </button>
                )}
            </div>
        </div>
    );
};

export default connector(FailedView);
