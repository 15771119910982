import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Label } from "reactstrap";

import { AddIcon } from "components/icons/AddIcon";
import { DeleteIcon } from "components/icons/DeleteIcon";
import style from "components/licenses/license-configuration/bms/bms-config.scss";
import { Answer, Question } from "components/licenses/license-configuration/bms/BmsCommonInterfaces";
import Checkbox from "components/licenses/license-configuration/bms/form-components/BmsCheckboxComponent";
import InputComponent from "components/licenses/license-configuration/bms/form-components/BmsTextInputComponent";
import BoxConnector from "components/licenses/license-configuration/bms/form-components/BoxConnector";
import tableStyle from "components/reports/erasure-reports-table.scss";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    minWidth?: number;
    questionHandler: (question: Question) => void;
    currentIds: string[];
    editMode: boolean;
    question: Question;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const CustomQuestionsForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [question, setQuestion] = useState<Question>(props.question);
    const [idInputError, setIdInputError] = useState<string>();
    const [answerIdInputError, setAnswerIdInputError] = useState<string>();

    const handleInputChange = (field: keyof Question, newValue: string) => {
        if (field === "id") {
            setIdInputError(
                props.currentIds.includes(newValue)
                    ? t("Configuration.questionsConfigurationForm.duplicateQuestionId")
                    : undefined
            );
        }

        setQuestion((prevState) => ({
            ...prevState,
            [field]: newValue,
        }));
    };

    const handleAnswerChange = (index: number, field: keyof Answer, newValue: string) => {
        setAnswerIdInputError(
            question.answers.map((answer) => answer.id).includes(newValue)
                ? t("Configuration.questionsConfigurationForm.duplicateAnswerId")
                : undefined
        );

        setQuestion((prevState) => {
            const newAnswers = [...prevState.answers];
            const updatedAnswer = { ...newAnswers[index], [field]: newValue };

            if (field === "id") {
                updatedAnswer.value = newValue;
            }

            newAnswers[index] = updatedAnswer;
            return { ...prevState, answers: newAnswers };
        });
    };

    const handleCheckboxChange = (index: number, newValue: boolean | undefined) => {
        setQuestion((prevState) => {
            const newAnswers = [...prevState.answers];
            newAnswers[index] = { ...newAnswers[index], positiveAnswer: newValue ?? false };
            return { ...prevState, answers: newAnswers };
        });
    };

    const addAnswerToQuestion = () => {
        setQuestion((prevState) => ({
            ...prevState,
            answers: [...prevState.answers, { id: "", label: "", value: "", positiveAnswer: false }],
        }));
    };

    const removeAnswerFromQuestion = (index: number) => {
        setQuestion((prevState) => {
            const newAnswers = prevState.answers.filter((_, i) => i !== index);
            return { ...prevState, answers: newAnswers };
        });
    };

    const handleSave = () => {
        props.questionHandler(question);
    };

    return (
        <div style={{ minWidth: props.minWidth ? props.minWidth : "" }}>
            <div className={classNames(style.boxed)}>
                <Label className={classNames(style.bmsProviderHeaderLabel)}>
                    {t("Configuration.questionsConfigurationForm.customQuestions.question")}
                </Label>
                <InputComponent
                    labelKey={t("Configuration.questionsConfigurationForm.customQuestions.id")}
                    toolTipContent={t("Configuration.tooltips.questionsConfigurationForm.questionId")}
                    inputId="id"
                    value={question.id}
                    disabled={props.editMode}
                    inputError={idInputError}
                    onChange={(newValue) => handleInputChange("id", newValue)}
                />
                <InputComponent
                    labelKey={t("Configuration.questionsConfigurationForm.customQuestions.category")}
                    toolTipContent={t("Configuration.tooltips.questionsConfigurationForm.category")}
                    inputId="category"
                    value={question.category}
                    onChange={(newValue) => handleInputChange("category", newValue)}
                />
                <InputComponent
                    labelKey={t("Configuration.questionsConfigurationForm.customQuestions.label")}
                    toolTipContent={t("Configuration.tooltips.questionsConfigurationForm.label")}
                    inputId="label"
                    value={question.label}
                    onChange={(newValue) => handleInputChange("label", newValue)}
                />
                <InputComponent
                    labelKey={t("Configuration.questionsConfigurationForm.customQuestions.notes")}
                    toolTipContent={t("Configuration.tooltips.questionsConfigurationForm.notes")}
                    inputId="notes"
                    value={question.notes}
                    onChange={(newValue) => handleInputChange("notes", newValue)}
                />
            </div>

            {question.answers.map((answer, index) => (
                <div key={index + question.id}>
                    <BoxConnector className={index > 0 ? style.lineConnectorMediumHeight : ""} />
                    <div className={classNames(style.childDiv)}>
                        <div className={classNames(style.boxed)}>
                            <Label className={classNames(style.bmsProviderHeaderLabel)}>
                                {t("Configuration.questionsConfigurationForm.customQuestions.answer")}
                                {index > 0 && (
                                    <button className={classNames(tableStyle.linkText)} onClick={addAnswerToQuestion}>
                                        <AddIcon
                                            color={props.theme.iconFillColor}
                                            linecolor={props.theme.contentBackgroundColor}
                                        />
                                    </button>
                                )}
                                {index > 1 && (
                                    <button
                                        className={classNames(tableStyle.linkText)}
                                        onClick={() => removeAnswerFromQuestion(index)}
                                    >
                                        <DeleteIcon
                                            color={props.theme.iconFillColor}
                                            linecolor={props.theme.contentBackgroundColor}
                                            width={20}
                                            height={20}
                                        />
                                    </button>
                                )}
                            </Label>

                            <div key={index}>
                                <InputComponent
                                    labelKey={t("Configuration.questionsConfigurationForm.customQuestions.id")}
                                    toolTipContent={t("Configuration.tooltips.questionsConfigurationForm.answerId")}
                                    inputId={`answerId-${index}`}
                                    value={answer.id}
                                    inputError={answerIdInputError}
                                    onChange={(newValue) => handleAnswerChange(index, "id", newValue)}
                                />
                                <InputComponent
                                    labelKey={t("Configuration.questionsConfigurationForm.customQuestions.label")}
                                    toolTipContent={t("Configuration.tooltips.questionsConfigurationForm.label")}
                                    inputId={`label-${index}`}
                                    value={answer.label}
                                    onChange={(newValue) => handleAnswerChange(index, "label", newValue)}
                                />
                                <Checkbox
                                    labelKey={t(
                                        "Configuration.questionsConfigurationForm.customQuestions.positiveAnswer"
                                    )}
                                    toolTipContent={t(
                                        "Configuration.tooltips.questionsConfigurationForm.positiveAnswer"
                                    )}
                                    checkboxId={answer.id}
                                    checked={answer.positiveAnswer ?? false}
                                    onChange={(newValue) => handleCheckboxChange(index, newValue)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <div className={form.buttonContainer}>
                <button
                    className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                    data-testid={testIds.workArea.license.configure.manageConfigurationDialog.saveButton}
                    onClick={handleSave}
                    disabled={!!idInputError || !!answerIdInputError || !question.id}
                >
                    {t("Configuration.editConfigureDialog.saveButton")}
                </button>
            </div>
        </div>
    );
};

export default connector(CustomQuestionsForm);
