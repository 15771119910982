import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as yup from "yup";

import style from "./manage-edit-report-view.scss";
import LeftArrow from "components/icons/LeftArrow";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import styleModel from "components/reports/erasure-reports-table.scss";
import Heading from "components/typography/heading/Heading";
import { CustomField, CustomFieldsData } from "domain/reports";
import { reportService } from "services/report/erasure/ReportService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import buttonStyle from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));
interface Props {
    reportUuid: string;
    hideDialogView?: (value: boolean) => void;
}
export interface FormValues {
    customFields: CustomField[];
}
export interface Result {
    title: string;
    message: string;
}
const ManageEditReportView = (props: Props): JSX.Element => {
    const { t } = useTranslation();

    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [customFieldsDetails, setCustomFieldsDetails] = React.useState<CustomFieldsData>({ customFields: [] });
    const [loading, setLoading] = React.useState<boolean>(true);

    const fetchData = () => {
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportService
            .fetchReportCustomFields(props.reportUuid, abortController)
            .then((data: CustomFieldsData) => {
                setCustomFieldsDetails(data);
                setLoading(false);
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                }
            });
    };
    const [visibleProcess, setVisibleProcess] = React.useState<boolean>(false);
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });

    const validationSchema = yup.object().shape(
        customFieldsDetails.customFields.reduce((schema, field) => {
            schema[field.name] = yup.mixed().test("no-whitespace", t("Common.whitespaceErrorMessage"), (value) => {
                if (value === "" || value === null) {
                    return true;
                }
                if (typeof value !== "string") {
                    return true;
                }
                return !/^\s|\s$/.test(value);
            });
            return schema;
        }, {} as { [key: string]: yup.MixedSchema })
    );

    const submitHandler: FormikConfig<FormValues>["onSubmit"] = async (values, { setSubmitting }) => {
        setSubmitting(false);
        const updatedFields =
            customFieldsDetails.customFields
                .map((each, index) => ({
                    name: each.name,
                    oldValue: each.value,
                    newValue: values.customFields[index].value.trim(),
                }))
                .filter((field) => field.oldValue !== field.newValue) || [];

        const postData = { fields: updatedFields };
        if (updatedFields.length > 0) {
            setLoading(true);
            const abortController = new AbortController();
            abortControllers.push(abortController);
            reportService
                .editReportByUuid(props.reportUuid, postData, abortController)
                .then(() => {
                    setSubmitting(true);
                    showResult({
                        title: t("ErasureReport.updateReportLabel"),
                        message: t("ErasureReport.reportMessage"),
                    });
                })
                .catch(() => {
                    if (!abortController.signal.aborted) {
                        setLoading(false);
                        showResult({
                            title: t("Common.resultErrorTitle"),
                            message: t("Common.resultErrorMessage"),
                        });
                    }
                });
        } else {
            setSubmitting(false);
        }
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setVisibleProcess(true);
    };
    const hideProcess = () => {
        setVisibleProcess(false);
    };
    React.useEffect(() => {
        fetchData();

        return () => {
            abortControllers.forEach((controller) => controller.abort());
        };
    }, []);

    return (
        <>
            {loading ? (
                <div className={style.loaderContainer}>
                    <LoadingIndicator />
                </div>
            ) : (
                <Formik
                    validateOnBlur={true}
                    initialValues={{ customFields: customFieldsDetails.customFields }}
                    validationSchema={validationSchema}
                    onSubmit={submitHandler}
                >
                    {({ dirty, handleChange, values }: FormikProps<FormValues>) => {
                        return (
                            <Form>
                                <>
                                    <div className={style.backArrow}>
                                        <button
                                            onClick={(e) => {
                                                props.hideDialogView ? props.hideDialogView(false) : e.preventDefault();
                                            }}
                                        >
                                            <LeftArrow color={"blue"} />
                                        </button>
                                    </div>
                                    <div className={style.heading}>
                                        <Heading tag={"h1"}>{t("Common.edit") + ` ${props.reportUuid}`}</Heading>
                                        {!dirty && values.customFields.length > 0 && (
                                            <span className={style.noChanges}>{t("LicensePools.edit.noChanges")}</span>
                                        )}
                                    </div>
                                    {customFieldsDetails.customFields.length > 0 ? (
                                        <div>
                                            <div className={form.formFields}>
                                                <label
                                                    htmlFor="customSection"
                                                    className={classNames(form.label, style.label)}
                                                >
                                                    {t("ManageRoleForm.reports.reportSectionsLabel")}
                                                </label>
                                                <label htmlFor="editCustomField" className={form.label}>
                                                    {t("ManageRoleForm.reports.reportCustomFields")}
                                                </label>
                                            </div>

                                            {values.customFields.map((aField: CustomField, index) => (
                                                <div key={index} className={form.formFields}>
                                                    <label htmlFor="editCustomField" className={form.label}>
                                                        {aField.name}
                                                    </label>
                                                    <input
                                                        id={aField.name}
                                                        className={classNames(form.input, form.fixedWidthInput)}
                                                        onChange={handleChange}
                                                        name={`customFields[${index}].value`}
                                                        value={aField.value}
                                                        data-testid={
                                                            testIds.workArea.report.erasure.editReport.customFiledInput
                                                                .itself
                                                        }
                                                    />
                                                    <div className={classNames(form.error, style.errorMessage)}>
                                                        <ErrorMessage name={aField.name} />
                                                    </div>
                                                </div>
                                            ))}

                                            <div className={form.buttonContainerLeftAligned}>
                                                <button
                                                    className={classNames(
                                                        props.reportUuid && !dirty
                                                            ? buttons.disabledButton
                                                            : buttons.primaryButton,
                                                        buttons.medium,
                                                        style.buttons
                                                    )}
                                                    type="submit"
                                                    data-testid={testIds.common.confirmationDialog.confirmButton}
                                                >
                                                    {t("Common.save")}
                                                </button>
                                                <button
                                                    className={classNames(
                                                        buttons.secondaryButton,
                                                        buttons.medium,
                                                        style.buttons,
                                                        style.cancelButton
                                                    )}
                                                    type="reset"
                                                    data-testid={testIds.common.dialog.closeButton}
                                                    onClick={(e) => {
                                                        props.hideDialogView
                                                            ? props.hideDialogView(false)
                                                            : e.preventDefault();
                                                    }}
                                                >
                                                    {t("Common.cancel")}
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={style.notFoundMessage}>
                                            {t("ManageRoleForm.reports.customFieldsNotFound")}
                                        </div>
                                    )}
                                </>
                            </Form>
                        );
                    }}
                </Formik>
            )}
            <Modal isOpen={visibleProcess} hideModal={hideProcess} modalTitle={result.title}>
                <div className={classNames(styleModel.processDialog, styleModel.filters)}>
                    <div>{result.message}</div>
                    <div className={classNames(styleModel.exportButtonContainer, styleModel.cancelButton)}>
                        <button
                            className={classNames(
                                buttonStyle.primaryButton,
                                buttonStyle.medium,
                                styleModel.exportButton
                            )}
                            data-testid={testIds.common.dialog.closeButton}
                            onClick={() => {
                                {
                                    hideProcess();
                                }
                                props.hideDialogView && props.hideDialogView(false);
                            }}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default connector(ManageEditReportView);
