import * as React from "react";
import { Layouts, Responsive, WidthProvider } from "react-grid-layout";

import ProductManuals from "./ProductManuals";
import SoftwarePackages from "./SoftwarePackages";
import Videos from "./Videos";
import style from "components/dashboard/dashboard.scss";
import { WidgetContainer } from "components/dashboard/WidgetContainer";
import { WidgetDetails } from "domain/dashboard";
import { DRAGGABLE_CARD_HANDLE_SELECTOR } from "domain/globalConstants";
import * as DashboardRepository from "services/dashboard/dashboardRepository";
import { LicenseResponse, licenseService } from "services/licenses/LicenseService";
import { productManualsService } from "services/product-manuals/ProductManualsService";
import { softwarePackagesService } from "services/software-packages/SoftwarePackagesService";
import { userSessionService } from "services/user/UserSessionService";
import { videosService } from "services/videos/VideosService";

import testIds from "testIds.json";

interface CardLayout {
    cols: Record<string, number>;
    rowHeight: number;
    isResizable: boolean;
    margin: [number, number];
    isBounded: boolean;
}

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class LicenseFetch {
    private promise: Promise<LicenseResponse> | null;

    constructor() {
        this.promise = null;
    }

    fetch(): Promise<LicenseResponse> {
        if (this.promise == null) {
            this.promise = licenseService.fetchLicenses(
                undefined,
                undefined,
                !userSessionService.userHasAllAuthorities(["AUTH_LICENSE_VIEW"])
            );
        }
        return this.promise;
    }
}

// SupportAndHelp component is often created multiple times in a row after
// login. That's why this object is created outside of the component. We only
// need to fetch the licenses once. This isn't a perfect solution because it
// deviates from how our components usually operate. Backend requests are
// performed inside the components and therefore they are made again when the
// components are created. In practical terms it means that end users are used
// to having refreshed views when they visit some other page and come back. In
// this case they probably won't notice because the list of available licenses
// doesn't change often. Still, licenses are fetched in quite a few places in
// the source code so some kind of client side caching with expiration could
// be in order.
const LICENSE_FETCH = new LicenseFetch();

const SupportAndHelp = (props: CardLayout): JSX.Element => {
    const softwarePackages = "softwarePackages";
    const productManuals = "productManuals";
    const videos = "videos";

    const widgets: WidgetDetails[] = [
        {
            key: softwarePackages,
            title: "Support.supportAndHelp.softwarePackages.cardTitle",
            children: (
                <SoftwarePackages
                    fetchSoftwarePackagesData={softwarePackagesService.fetchSoftwarePackages}
                    licensePromise={LICENSE_FETCH.fetch()}
                />
            ),
            testId: testIds.workArea.support.supportAndHelp.cards.software.itself,
        },
        {
            key: productManuals,
            title: "Support.supportAndHelp.productManuals.cardTitle",
            children: (
                <ProductManuals
                    fetchProductManuals={productManualsService.fetchProductManuals}
                    licensePromise={LICENSE_FETCH.fetch()}
                />
            ),
            testId: testIds.workArea.support.supportAndHelp.cards.manuals.itself,
        },
        {
            key: videos,
            title: "Support.supportAndHelp.videos.cardTitle",
            children: (
                <Videos
                    licensePromise={LICENSE_FETCH.fetch()}
                    fetchVideoInformationList={videosService.fetchVideoInformationList}
                />
            ),
            testId: testIds.workArea.support.supportAndHelp.cards.videos.itself,
        },
    ];

    const defaultLayout = {
        lg: [
            { w: 1, h: 17, x: 0, y: 0, i: softwarePackages },
            { w: 2, h: 17, x: 1, y: 0, i: productManuals },
            { w: 4, h: 14, x: 0, y: 1, i: videos },
        ],
        md: [
            { w: 1, h: 17, x: 0, y: 0, i: softwarePackages },
            { w: 2, h: 17, x: 1, y: 0, i: productManuals },
            { w: 4, h: 14, x: 0, y: 1, i: videos },
        ],
        sm: [
            { w: 1, h: 17, x: 0, y: 0, i: softwarePackages },
            { w: 2, h: 17, x: 1, y: 0, i: productManuals },
            { w: 4, h: 14, x: 0, y: 1, i: videos },
        ],
        xs: [
            { w: 1, h: 17, x: 0, y: 0, i: softwarePackages },
            { w: 2, h: 17, x: 1, y: 15, i: productManuals },
            { w: 4, h: 14, x: 0, y: 1, i: videos },
        ],
        xxs: [
            { w: 1, h: 17, x: 0, y: 0, i: softwarePackages },
            { w: 2, h: 17, x: 1, y: 15, i: productManuals },
            { w: 4, h: 14, x: 0, y: 1, i: videos },
        ],
    };

    const layout: Layouts = DashboardRepository.getSupportAndHelpCardOrderLayout() || defaultLayout;

    return (
        <>
            <ResponsiveReactGridLayout
                className={style.dashboardViewContainer}
                draggableHandle={DRAGGABLE_CARD_HANDLE_SELECTOR}
                layouts={layout}
                onLayoutChange={(_layout, layouts) => DashboardRepository.setSupportAndHelpCardOrderLayout(layouts)}
                {...props}
            >
                {widgets.map((widget) => {
                    return (
                        <div key={widget.key} className={style.cardContainer} data-testid={widget.testId}>
                            <WidgetContainer {...widget}>{widget.children}</WidgetContainer>
                        </div>
                    );
                })}
            </ResponsiveReactGridLayout>
        </>
    );
};

SupportAndHelp.defaultProps = {
    cols: { lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 },
    margin: [40, 40],
    containerPadding: [0, 0],
    isBounded: true,
    rowHeight: 1,
    isResizable: false,
};

export default SupportAndHelp;
