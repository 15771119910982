import { DownloadPageConfiguration } from "domain/downloadPage";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

/**
 * Handles interactions with get download page configuration API.
 */

class DownloadPageService {
    public async getConfiguration(licenseKey: string, deviceType: string): Promise<DownloadPageConfiguration | string> {
        return apiGatewayService.invokeApi("/client-configurations/download-app-page-configuration", "GET", null, {
            refreshSession: false,
            apiType: ApiType.GLOBAL_STAN,
            headers: { "License-Key": licenseKey, "Device-Type": deviceType },
            includeCredentials: false,
        });
    }
}

export const downloadPageService = new DownloadPageService();
