import classNames from "classnames";
import * as React from "react";

import style from "components/typography/textBlock/textBlock.scss";
import utils from "styles/utils.scss";

interface Props {
    children: React.ReactNode;
    testId?: string;
    disableBottomSpacing?: boolean;
    alignCenter?: boolean;
}

export const TextBlock = (props: Props) => {
    const textBlockStyles = () => {
        return classNames(style.textBlock, {
            [utils.noSpacingBottom]: props.disableBottomSpacing,
            [utils.textAlignCenter]: props.alignCenter,
        });
    };
    return (
        <p className={textBlockStyles()} data-testid={props.testId}>
            {props.children}
        </p>
    );
};
