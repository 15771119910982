import classNames from "classnames";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { object, string } from "yup";

import Cross from "components/icons/Cross";
import SearchIcon from "components/icons/Search";
import searchView from "components/search/search-view.scss";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";

export interface FormValues {
    searchQuery: string;
}

interface Props {
    setReportsSearch: (searchQuery: string) => void;
    searchInProgress: boolean;
    tableName: string;
    inputTestId: string;
    clearButtonTestId: string;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const ReportsSearchView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const searchInputMaxLength = 36;
    const [clearButtonVisibility, setClearButtonVisibility] = React.useState<boolean>(false);

    return (
        <Formik
            initialValues={{ searchQuery: "" }}
            onSubmit={(values, actions) => {
                actions.setSubmitting(false);
                props.setReportsSearch(values.searchQuery.trim());
            }}
            validationSchema={object().shape({
                searchQuery: string().max(searchInputMaxLength),
            })}
            validateOnChange={true}
        >
            {({ values, handleChange, resetForm }: FormikProps<FormValues>) => {
                return (
                    <Form>
                        <div
                            className={searchView.inputWithButton}
                            onClick={() => {
                                usageStatisticsService.sendEvent({
                                    category: Category.REPORTS,
                                    action: Action.EXECUTE_QUICK_SEARCH,
                                    label: Label.ALL,
                                });
                            }}
                        >
                            <div className={searchView.searchIcon}>
                                <SearchIcon color={props.theme.iconFillColor} />
                            </div>
                            <input
                                id="searchQuery"
                                type="text"
                                maxLength={searchInputMaxLength}
                                value={values.searchQuery}
                                autoComplete="off"
                                placeholder={t("Common.search")}
                                disabled={props.searchInProgress}
                                onChange={handleChange}
                                data-testid={props.inputTestId}
                                onInput={(e) => {
                                    if (e.currentTarget.value.length < 1) {
                                        setClearButtonVisibility(false);
                                    } else {
                                        setClearButtonVisibility(true);
                                    }
                                }}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        usageStatisticsService.sendEvent({
                                            category: Category.REPORTS,
                                            action: Action.EXECUTE_QUICK_SEARCH,
                                            label: props.tableName,
                                        });
                                    }
                                }}
                            />
                            <div
                                data-testid={props.clearButtonTestId}
                                className={classNames(
                                    clearButtonVisibility ? searchView.clearButton : searchView.hideClearButton,
                                    props.searchInProgress ? searchView.disabledClearButton : ""
                                )}
                                onClick={() => {
                                    resetForm();
                                    setClearButtonVisibility(false);
                                    props.setReportsSearch("");
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && !props.searchInProgress) {
                                        e.preventDefault();
                                        resetForm();
                                        setClearButtonVisibility(false);
                                        props.setReportsSearch("");
                                    }
                                }}
                                tabIndex={0}
                            >
                                <Cross color={props.theme.secondaryButtonBackgroundColor} />
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default connector(ReportsSearchView);
