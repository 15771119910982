import { logger } from "utils/logging";

export interface ReportImportFileEvent {
    files: File[];
}

interface Subscriber<E> {
    id: string;
    handler: (event: E) => Promise<void>;
}

class Task<E> {
    private subscribers: Subscriber<E>[] = [];
    private taskHolder: Subscriber<E>;

    public subscribe(subscriber: Subscriber<E>) {
        this.subscribers.push(subscriber);
        if (subscriber != null) {
            this.taskHolder = subscriber;
        }
        return () => {
            this.subscribers = this.subscribers.filter((each) => each !== subscriber);
        };
    }

    public publish(event: E) {
        this.taskHolder
            .handler(event)
            .catch((error) =>
                logger.error(
                    "Sending event to subscriber failed. Subscriber, event, error:",
                    this.taskHolder.id,
                    event,
                    error
                )
            );
    }
}

class EventBus {
    private readonly reportImportFileTopic = new Task<ReportImportFileEvent>();

    public getReportImportFileTopic(): Task<ReportImportFileEvent> {
        return this.reportImportFileTopic;
    }
}

const eventBus = new EventBus();
export { eventBus };
