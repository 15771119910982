import { TableData } from "./table";

export interface ApiKey extends CommonData {
    name: string;
    description: string;
    created: string;
    owner: string;
}

export interface CommonData extends TableData {
    uuid: string;
}

export interface ApiKeysDto {
    uuid: string;
    name: string;
    description: string;
    created: string;
    owner: string;
}

export interface ApiKeyCursor {
    uuid: string;
}

export interface ApiKeys extends TableData {
    api_keys: ApiKey[];
    cursor: ApiKeyCursor | null;
}

export interface ApiKeysData {
    apiKeys: ApiKeys;
}

export function toApiKey(dto: ApiKeysDto): ApiKey {
    return {
        uuid: dto.uuid,
        name: dto.name,
        description: dto.description,
        created: dto.created,
        owner: dto.owner,
    };
}

export interface ApiKeyDetail {
    uuid: string;
    name: string;
    description: string;
    created: string;
}

export interface GenerateApiKeyResponse {
    apiKey: string;
}
