// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3AHGlyb8HNbtR2aTYzuwCr{display:inline-block;margin-left:10px;padding:0;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"plainCopyIcon": "_3AHGlyb8HNbtR2aTYzuwCr"
};
module.exports = exports;
