import React from "react";
import { useTranslation } from "react-i18next";

import style from "./report-template.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import ReportTemplate from "components/reports/report-template/ReportTemplate";
import { BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID, TemplateTableData } from "domain/reports";
import { reportTemplateService } from "services/report/erasure/ReportTemplateService";

interface Props {
    uuid: string;
    onCancel: () => void;
    onEdit: (uuid: string) => void;
    defaultTemplate?: TemplateTableData;
    hasDynamicSize: (initial: boolean) => void;
}

const ReportTemplateView = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [templateState, setTemplateState] = React.useState<TemplateTableData>();

    const editTemplate = () => {
        props.onEdit(props.uuid);
    };

    const fetchData = (abortController: AbortController) => {
        setLoading(true);
        props.hasDynamicSize(true);
        if (props.uuid === BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID) {
            setTemplateState(props.defaultTemplate);
            setLoading(false);
            props.hasDynamicSize(false);
        } else {
            reportTemplateService
                .fetchTemplate(props.uuid, abortController)
                .then((data: TemplateTableData) => {
                    setTemplateState(data);
                })
                .catch(() => {
                    if (!abortController.signal.aborted) {
                        setRequestFailureMessage(t("viewTemplate.requestFailed"));
                    }
                })
                .finally(() => {
                    if (!abortController.signal.aborted) {
                        setLoading(false);
                        props.hasDynamicSize(false);
                    }
                });
        }
    };

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchData(abortController);

        return () => {
            abortController.abort();
        };
    }, []);

    return loading ? (
        <LoadingIndicator />
    ) : (
        <div className={style.templateContainer}>
            {requestFailureMessage ? (
                <div>{requestFailureMessage}</div>
            ) : (
                <ReportTemplate
                    uuid={props.uuid}
                    template={templateState}
                    onCancel={props.onCancel}
                    onEdit={editTemplate}
                />
            )}
        </div>
    );
};

export default ReportTemplateView;
