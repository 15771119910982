import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import { createProductIdToNameMap } from "components/licenses/common";
import SearchView from "components/search/SearchView";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { ReportPath, ReportPathFilters, reportViewService } from "services/report/ReportViewService";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import form from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

const INCLUDED_PATH_PREFIXES = [
    "user_data.",
    "blancco_erasure_report.",
    "blancco_hardware_report.",
    "blancco_software_report.",
];

const PRODUCT_ID_TO_NAME_MAP = createProductIdToNameMap();

interface Row {
    path: string;
    pathTranslation: string;
    typeTranslation: string;
    productIdTranslations: string[];
    productIds: string[];
    searchString: string;
}

const translateProductId = (productId: string): string => {
    const value = PRODUCT_ID_TO_NAME_MAP.get(productId);
    if (typeof value === "undefined") {
        return productId;
    }
    return value;
};

interface TableState {
    rows: Row[];
}

export interface Props {
    onCsvUrlUpdate: (csvUrl: string) => void;
}

const ALL_AVAILABLE_PRODUCTS = "all";

const ReportPathsTable = (props: Props): JSX.Element => {
    const { t, i18n } = useTranslation();

    const columns: Array<Column<Row>> = [
        {
            Header: () => <TextWithTooltip text={t("ReportPaths.table.name")} key="2" />,
            accessor: "pathTranslation",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("ReportPaths.table.products")} key="4" />,
            accessor: "productIdTranslations",
            Cell: ({ cell: { value } }) => {
                if (value.length === 0) {
                    return <></>;
                } else if (value.length === 1) {
                    return <TextWithTooltip text={value[0]} />;
                }
                const text = value.join("<br/>");
                return (
                    <TextWithTooltip text={text} multiline={true} content={t("ReportPaths.table.multipleProducts")} />
                );
            },
        },
        {
            Header: () => <TextWithTooltip text={t("ReportPaths.table.type")} key="3" />,
            accessor: "typeTranslation",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("ReportPaths.table.path")} key="1" />,
            accessor: "path",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
    ];

    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [failureMessage, setFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [tableState, setTableState] = React.useState<TableState>({
        rows: [],
    });
    const [reportPaths, setReportPaths] = React.useState<ReportPath[]>([]);
    const [availableProducts, setAvailableProducts] = React.useState<Map<string, string>>(new Map());
    const [selectedProductId, setSelectedProductId] = React.useState<string>(ALL_AVAILABLE_PRODUCTS);
    const [search, setSearch] = React.useState<string>("");

    const toRow = (reportPath: ReportPath): Row => {
        // TODO BCC-2571 Use full path after translation is handled. This ends up in the CSV as well.
        const pathTranslationKey = reportPath.path.split(".").join("_");
        const pathTranslationFullKey = `reportPaths:reportPaths.${pathTranslationKey}`;

        const typeTranslationKey = reportPath.pathType.toLowerCase();
        const typeTranslationFullKey = `ReportPaths.table.${typeTranslationKey}`;

        const row = {
            path: reportPath.path,
            pathTranslation: i18n.exists(pathTranslationFullKey) ? t(pathTranslationFullKey) : "",
            typeTranslation: i18n.exists(typeTranslationFullKey) ? t(typeTranslationFullKey) : "",
            productIdTranslations: reportPath.productIds.map((productId) => translateProductId(productId)),
            productIds: reportPath.productIds,
            searchString: "",
        };
        row.searchString = [row.path, row.pathTranslation, ...row.productIdTranslations].join(" ").toLowerCase();
        return row;
    };

    const refreshCsvData = (rows: Row[]) => {
        const headers = [
            t("ReportPaths.table.name"),
            t("ReportPaths.table.products"),
            t("ReportPaths.table.type"),
            t("ReportPaths.table.path"),
        ];
        let csv = headers.join(",") + "\n";
        for (const row of rows) {
            const columns = [
                row.pathTranslation,
                row.productIdTranslations.join(" | "),
                row.typeTranslation.toLowerCase(),
                row.path,
            ];
            csv += columns.join(",") + "\n";
        }
        props.onCsvUrlUpdate(window.URL.createObjectURL(new Blob([csv], { type: "text/csv" })));
    };

    const refreshTableData = (newReportPaths?: ReportPath[]): Row[] => {
        let currentReportPaths = reportPaths;
        if (typeof newReportPaths !== "undefined") {
            currentReportPaths = newReportPaths;
            setReportPaths(newReportPaths);
        }
        const availableProductIds: Set<string> = new Set();
        const rows = currentReportPaths
            .filter((reportPath) => {
                for (const prefix of INCLUDED_PATH_PREFIXES) {
                    if (reportPath.path.startsWith(prefix)) {
                        return true;
                    }
                }
                return false;
            })
            .map((reportPath) => toRow(reportPath))
            .filter((row) => {
                row.productIds.forEach((productId) => availableProductIds.add(productId));
                return true;
            })
            .map((row: Row) => {
                if (selectedProductId !== ALL_AVAILABLE_PRODUCTS) {
                    // When searching for a single product, hide other products where this path is present
                    row.productIdTranslations = [translateProductId(selectedProductId)];
                }
                return row;
            })
            .filter((row) => {
                if (search.length > 0) {
                    return row.searchString.includes(search.toLowerCase());
                }
                return true;
            });

        refreshCsvData(rows);

        const productIdToTranslationMap = new Map();
        availableProductIds.forEach((productId) =>
            productIdToTranslationMap.set(productId, translateProductId(productId))
        );
        const sortedByProductNameMap = new Map(
            Array.from(productIdToTranslationMap.entries()).sort((a, b) => a[1].localeCompare(b[1]))
        );
        setAvailableProducts(sortedByProductNameMap);
        return rows;
    };

    const fetchData = (initialLoading: boolean) => {
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const filters: ReportPathFilters = {};

        if (selectedProductId !== ALL_AVAILABLE_PRODUCTS) {
            filters["product_id"] = selectedProductId;
        }

        reportViewService
            .refreshPaths(abortController, filters)
            .then(() => {
                setTableState(() => ({
                    rows: refreshTableData(reportViewService.getPaths()),
                }));
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setFailureMessage(t("ReportPaths.table.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    const onProductSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProductId(event.target.value);
        usageStatisticsService.sendEvent({
            label: Label.REPORT_PATHS,
            action: Action.CHANGE_PRODUCT,
            category: Category.REPORT_PATHS,
        });
    };

    React.useEffect(() => {
        setTableState({
            rows: [],
        });
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [selectedProductId]);

    React.useEffect(() => {
        setTableState(() => ({
            rows: refreshTableData(),
        }));
    }, [search]);

    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>
                    {t("Common.recordsCount", { dataCount: tableState.rows.length })}
                </div>
                <select
                    value={selectedProductId}
                    className={classNames(form.select, form.fixedWidthInput)}
                    onChange={onProductSelected}
                    data-testid={testIds.workArea.reportPaths.productSelect}
                >
                    <option key={ALL_AVAILABLE_PRODUCTS} value={ALL_AVAILABLE_PRODUCTS}>
                        {t("ReportPaths.allAvailableProducts")}
                    </option>
                    {Array.from(availableProducts.entries()).map((entry) => (
                        <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                        </option>
                    ))}
                </select>
                <div className={form.search}>
                    <SearchView
                        setSearch={(search) => setSearch(search)}
                        searchInProgress={false}
                        placeholder={"ReportPaths.searchPlaceholder"}
                    />
                </div>
            </div>
            <div className={layoutStyle.tableWrapper}>
                <Table
                    tableIdentity={RepositoryKey.REPORT_PATHS_TABLE}
                    data={tableState.rows}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={failureMessage}
                    tooltips={true}
                    emptyMessage={t("ReportPaths.table.emptyStateMessage")}
                    testId={testIds.workArea.reportPaths.table}
                />
            </div>
        </>
    );
};

export default ReportPathsTable;
