import * as React from "react";

export const AddIcon = (props: { color: string; linecolor: string }): JSX.Element => {
    return (
        <svg id="plus_icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <rect id="Rectangle_3101" data-name="Rectangle 3101" width="20" height="20" fill="#9a4242" opacity="0" />
            <g id="Group_3627" data-name="Group 3627" transform="translate(-641 -580)" style={{ isolation: "isolate" }}>
                <g id="Group_3547" data-name="Group 3547">
                    <g id="Group_2701" data-name="Group 2701" transform="translate(641 580)">
                        <circle
                            cx="10"
                            cy="10"
                            r="9.25"
                            fill={props.color}
                            stroke={props.linecolor}
                            strokeWidth="1.5"
                        />
                    </g>
                    <g id="trash-2" transform="translate(646 595) rotate(-90)">
                        <path
                            d="M5.00004 9V1M9 5H1"
                            stroke={props.linecolor}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
