import { ThemeMode } from "domain/themeMode";
import { getObject, RepositoryKey, setObject } from "utils/repository";

export function getThemeMode(): ThemeMode {
    return getObject<ThemeMode>(RepositoryKey.MODE) ?? ThemeMode.LIGHT;
}

export function setThemeMode(mode: ThemeMode): void {
    setObject(RepositoryKey.MODE, mode);
}

export function getDataCollection(): boolean | null {
    return getObject<boolean>(RepositoryKey.DATA_COLLECTION);
}

export function setDataCollection(enabled: boolean): void {
    setObject(RepositoryKey.DATA_COLLECTION, enabled);
}
