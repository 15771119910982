import { getObject, remove, RepositoryKey, setObject } from "utils/repository";

export function getStanUrl(): string {
    return getObject(RepositoryKey.STAN_URL) || (process.env.STAN_URL as string);
}

export function getLaurelUrl(): string {
    return getObject(RepositoryKey.LAUREL_URL) || (process.env.LAUREL_URL as string);
}

export function getArthurUrl(): string {
    return getObject(RepositoryKey.ARTHUR_URL) || (process.env.ARTHUR_URL as string);
}

export function getOliverUrl(): string {
    return getObject(RepositoryKey.OLIVER_URL) || (process.env.OLIVER_URL as string);
}

export function getPublicApiUrl(): string {
    return getObject(RepositoryKey.PUBLIC_API_URL) || (process.env.PUBLIC_API_URL as string);
}

export function getPublicApiDocumentationUrl(): string | undefined {
    return getObject(RepositoryKey.PUBLIC_API_DOCUMENTATION_URL) || undefined;
}

export function setUrl(
    stanUrl: string,
    laurelUrl: string,
    arthurUrl: string,
    oliverUrl: string,
    publicUrl: string,
    publicApiDocumentationUrl: string
): void {
    const https = "https://";
    setObject(RepositoryKey.STAN_URL, https + stanUrl);
    setObject(RepositoryKey.LAUREL_URL, https + laurelUrl);
    setObject(RepositoryKey.ARTHUR_URL, https + arthurUrl);
    setObject(RepositoryKey.OLIVER_URL, https + oliverUrl);
    setObject(RepositoryKey.PUBLIC_API_URL, https + publicUrl);
    setObject(RepositoryKey.PUBLIC_API_DOCUMENTATION_URL, https + publicApiDocumentationUrl);
}

export function clear(): void {
    remove(RepositoryKey.STAN_URL);
    remove(RepositoryKey.LAUREL_URL);
    remove(RepositoryKey.ARTHUR_URL);
    remove(RepositoryKey.OLIVER_URL);
    remove(RepositoryKey.PUBLIC_API_URL);
    remove(RepositoryKey.PUBLIC_API_DOCUMENTATION_URL);
}

export function setTenantAccessUrls(
    stanUrl: string,
    laurelUrl: string,
    arthurUrl: string,
    oliverUrl: string,
    publicApiUrl: string,
    publicApiDocumentationUrl: string
): void {
    setObject(RepositoryKey.ORIGINAL_STAN_URL, getStanUrl());
    setObject(RepositoryKey.ORIGINAL_LAUREL_URL, getLaurelUrl());
    setObject(RepositoryKey.ORIGINAL_ARTHUR_URL, getArthurUrl());
    setObject(RepositoryKey.ORIGINAL_OLIVER_URL, getOliverUrl());
    setObject(RepositoryKey.ORIGINAL_PUBLIC_API_URL, getPublicApiUrl());
    setObject(RepositoryKey.ORIGINAL_PUBLIC_API_DOCUMENTATION_URL, getPublicApiDocumentationUrl());
    const https = "https://";
    setObject(RepositoryKey.STAN_URL, https + stanUrl);
    setObject(RepositoryKey.LAUREL_URL, https + laurelUrl);
    setObject(RepositoryKey.ARTHUR_URL, https + arthurUrl);
    setObject(RepositoryKey.OLIVER_URL, https + oliverUrl);
    setObject(RepositoryKey.PUBLIC_API_URL, https + publicApiUrl);
    setObject(RepositoryKey.PUBLIC_API_DOCUMENTATION_URL, https + publicApiDocumentationUrl);
}

export function restoreUrls(): void {
    const originalStanUrl = getObject(RepositoryKey.ORIGINAL_STAN_URL);
    const originalLaurelUrl = getObject(RepositoryKey.ORIGINAL_LAUREL_URL);
    const originalArthurUrl = getObject(RepositoryKey.ORIGINAL_ARTHUR_URL);
    const originalOliverUrl = getObject(RepositoryKey.ORIGINAL_OLIVER_URL);
    const originalPublicApiUrl = getObject(RepositoryKey.ORIGINAL_PUBLIC_API_URL);
    const originalPublicApiDocumentationUrl = getObject(RepositoryKey.ORIGINAL_PUBLIC_API_DOCUMENTATION_URL);

    if (
        originalStanUrl &&
        originalLaurelUrl &&
        originalArthurUrl &&
        originalOliverUrl &&
        originalPublicApiUrl &&
        originalPublicApiDocumentationUrl
    ) {
        setObject(RepositoryKey.STAN_URL, originalStanUrl);
        setObject(RepositoryKey.LAUREL_URL, originalLaurelUrl);
        setObject(RepositoryKey.ARTHUR_URL, originalArthurUrl);
        setObject(RepositoryKey.OLIVER_URL, originalOliverUrl);
        setObject(RepositoryKey.PUBLIC_API_URL, originalPublicApiUrl);
        setObject(RepositoryKey.PUBLIC_API_DOCUMENTATION_URL, originalPublicApiDocumentationUrl);
        remove(RepositoryKey.ORIGINAL_STAN_URL);
        remove(RepositoryKey.ORIGINAL_LAUREL_URL);
        remove(RepositoryKey.ORIGINAL_ARTHUR_URL);
        remove(RepositoryKey.ORIGINAL_OLIVER_URL);
        remove(RepositoryKey.ORIGINAL_PUBLIC_API_URL);
        remove(RepositoryKey.ORIGINAL_PUBLIC_API_DOCUMENTATION_URL);
    }
}
