export enum DiagnosticPath {
    AUDIO_EARPIECE = "audio-earpiece",
    AUDIO_HEADPHONE = "audio-headphone",
    AUDIO_HEADSET_MICROPHONE = "audio-headset-microphone",
    AUDIO_MICROPHONE = "audio-microphone",
    AUDIO_MICROPHONE_RECORDING = "audio-microphone-recording",
    AUDIO_NOISE_CANCELLATION = "audio-noise-cancellation",
    AUDIO_SPEAKER = "audio-speaker",
    AUDIO_SPEAKER_MICROPHONE = "audio-speaker-microphone",
    BATTERY_CHARGE = "battery-charge",
    BATTERY_CHARGE_HOLD = "battery-charge-hold",
    BATTERY_DRAIN = "battery-drain",
    BATTERY_HEALTH = "battery-health",
    BATTERY_TEMPERATURE = "battery-temperature",
    BUTTON_BACK = "button-back",
    BUTTON_HOME = "button-home",
    BUTTON_MUTE = "button-mute",
    BUTTON_POWER = "button-power",
    BUTTON_RECENT_APP = "button-recent-app",
    BUTTON_SIDE = "button-side",
    BUTTON_VOLUME_DOWN = "button-volume-down",
    BUTTON_VOLUME_UP = "button-volume-up",
    CAMERA_BACK = "camera-back",
    CAMERA_BACK_AUTO = "camera-back-auto",
    CAMERA_BACK_FLASH = "camera-back-flash",
    CAMERA_BACK_VIDEO = "camera-back-video",
    CAMERA_FRONT = "camera-front",
    CAMERA_FRONT_AUTO = "camera-front-auto",
    CAMERA_FRONT_FLASH = "camera-front-flash",
    CAMERA_FRONT_VIDEO = "camera-front-video",
    CAMERA_MULTI_CHECK = "camera-multi-check",
    CARRIER_SIGNAL_CHECK_ONE = "carrier-signal-check-one",
    CARRIER_SIGNAL_CHECK_TWO = "carrier-signal-check-two",
    DETECT_BLUETOOTH = "detect-bluetooth",
    DETECT_NFC = "detect-nfc",
    DETECT_SIM = "detect-sim",
    DEVICE_AUTO_ROTATE = "device-auto-rotate",
    DEVICE_FM_RADIO = "device-fm-radio",
    DEVICE_LCD_BACKLIGHT = "device-lcd-backlight",
    DEVICE_OTG = "device-otg",
    DEVICE_PERFORMANCE = "device-performance",
    DEVICE_SD_CARD = "device-sd-card",
    DEVICE_USB = "device-usb",
    DEVICE_VIBRATION = "device-vibration",
    DISPLAY_BLACK_INK_SPOTS = "display-black-ink-spots",
    DISPLAY_COLOURED_VERTICAL_LINES = "display-coloured-vertical-lines",
    DISPLAY_CRACK = "display-crack",
    DISPLAY_DRAG_GESTURE = "display-drag-gesture",
    DISPLAY_GHOST_IMAGES = "display-ghost-images",
    DISPLAY_LCD_COLOR = "display-lcd-color",
    DISPLAY_LCD_PINK = "display-lcd-pink",
    DISPLAY_LCD_STARRING = "display-lcd-starring",
    DISPLAY_MULTI_TOUCH = "display-multi-touch",
    DISPLAY_PIXEL = "display-pixel",
    DISPLAY_PRESSURE = "display-pressure",
    DISPLAY_PURPLE_INK_STAIN = "display-purple-ink-stain",
    DISPLAY_TOUCH = "display-touch",
    ENCLOSURE_BACK = "enclosure-back",
    FACE_ID = "face-id",
    JOURNEY_ID = "journeyId",
    JOURNEY_TYPE = "journeyType",
    LIVE_CALL = "live-call",
    LONG_ASSISSTED_QUICK_CHECK = "long-assissted-quick-check",
    LONG_GROUP_TEST_QUICK_CHECK = "long-group-test-quick-check",
    LONG_STRESS = "long-stress",
    MAKE = "asset_properties.make",
    MODEL = "asset_properties.model",
    MODEL_NAME = "asset_properties.modelName",
    NETWORK_CELLULAR = "network-cellular",
    NETWORK_WIFI = "network-wifi",
    PASSCODE_STATUS = "passcode-status",
    SENSOR_ACCELEROMETER = "sensor-accelerometer",
    SENSOR_ALTIMETER = "sensor-altimeter",
    SENSOR_BAROMETER = "sensor-barometer",
    SENSOR_COMPASS = "sensor-compass",
    SENSOR_FINGERPRINT = "sensor-fingerprint",
    SENSOR_GYROSCOPE = "sensor-gyroscope",
    SENSOR_HALL = "sensor-hall",
    SENSOR_HEART_RATE = "sensor-heart-rate",
    SENSOR_INFRARED = "sensor-infrared",
    SENSOR_IRIS = "sensor-iris",
    SENSOR_LIGHT = "sensor-light",
    SENSOR_MAGNETOMETER = "sensor-magnetometer",
    SENSOR_PROXIMITY = "sensor-proximity",
    SENSOR_THREE_DIMEN_TOUCH = "sensor-three-dimen-touch",
    SIGNAL_AGPS = "signal-agps",
    SIGNAL_GPS = "signal-gps",
    TEST_PASS_RATE = "testPassRate",
}
