import { ReactElement } from "react";

export interface WidgetDetails {
    title: string;
    key: string;
    testId?: string;
    children?: ReactElement;
    hasInfoIcon?: boolean;
    description?: string;
    type?: string;
    actions?: JSX.Element;
    hideDragIcon?: boolean;
}

export interface EsgData {
    months: DashboardDataList[];
}

export interface DashboardDataList {
    month: string;
    content: DashboardData;
}

export const deviceTypes = ["Laptop", "Desktop", "Server", "Loose drive", "Smartphone", "Tablet"] as const;
export type DeviceType = typeof deviceTypes[number];
export type DashboardData = Record<DeviceType, DataList>;

export interface DataList {
    weight: number;
    co2: number;
}

export interface Quote {
    date: string;
    tradeId: string;
    eligible?: boolean;
    price: number;
    expiration: string;
    currency: string;
    channel: string;
}

export enum DEVICE_CATEGORY {
    LAPTOP = "Laptop",
    DESKTOP = "Desktop",
    SERVER = "Server",
    LOOSE_DRIVE = "Loose drive",
    SMARTPHONE = "Smartphone",
    TABLET = "Tablet",
}
