import * as React from "react";
import { useTranslation } from "react-i18next";
import ReactJson, { InteractionProps } from "react-json-view";

import style from "./json-editor.scss";
import { isWorkflow, Workflow } from "domain/workflows";

interface Props {
    workflow?: Workflow;
    setWorkflow: (workflow: Workflow) => void;
}

const JsonEditorView: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const WORKFLOW_NAME_MAX_LENGTH = 255;
    const handleJsonChange = (event: InteractionProps) => {
        if (isWorkflow(event.updated_src)) {
            const workflow = event.updated_src as Workflow;
            if (workflow.name.length > WORKFLOW_NAME_MAX_LENGTH) {
                workflow.name = workflow.name.substring(0, WORKFLOW_NAME_MAX_LENGTH);
            }
            props.setWorkflow(workflow);
            return true;
        }
        return false;
    };

    return (
        <div className={style.editorContainer}>
            <div className={style.warningText}>{t("ManageWorkflowDialog.jsonEditor.warningLabel")}</div>
            <ReactJson
                name={null}
                src={props.workflow ?? {}}
                onAdd={handleJsonChange}
                onEdit={handleJsonChange}
                onDelete={handleJsonChange}
            />
        </div>
    );
};

export default JsonEditorView;
