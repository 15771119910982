import * as React from "react";
import { CloseButtonProps } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface CustomCloseButtonProps {
    closeToast: CloseButtonProps;
    color: string;
}
export const CustomToastCloseButton: React.FunctionComponent<CustomCloseButtonProps> = (
    props: CustomCloseButtonProps
) => (
    <button onClick={props.closeToast.closeToast}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.25435 4.02876C4.49843 3.78468 4.89415 3.78468 5.13823 4.02876L9.99959 8.89012L14.8609 4.02876C15.105 3.78468 15.5008 3.78468 15.7448 4.02876C15.9889 4.27284 15.9889 4.66857 15.7448 4.91265L10.8835 9.774L15.7448 14.6354C15.9889 14.8794 15.9889 15.2752 15.7448 15.5192C15.5008 15.7633 15.105 15.7633 14.8609 15.5192L9.99959 10.6579L5.13823 15.5192C4.89415 15.7633 4.49843 15.7633 4.25435 15.5192C4.01027 15.2752 4.01027 14.8794 4.25435 14.6354L9.11571 9.774L4.25435 4.91264C4.01027 4.66857 4.01027 4.27284 4.25435 4.02876Z"
                fill={props.color}
            />
        </svg>
    </button>
);
