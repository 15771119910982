import React from "react";

const PremiumFeatureIcon = (props: { color: string }): JSX.Element => {
    return (
        <svg id="premium_feature_icon" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
            <path
                id="Path_5288"
                data-name="Path 5288"
                d="M2,11.842H14V13H2ZM2,3.737l3.437,3.4L8,2l2.618,5.14L14,3.737v6.947H2Z"
                transform="translate(-2 -2)"
                fill={props.color}
            />
        </svg>
    );
};

export default PremiumFeatureIcon;
