import classNames from "classnames";
import * as React from "react";

import style from "components/licenses/license-configuration/bms/bms-config.scss";

const BoxConnector = (props: { className?: string }): JSX.Element => {
    return (
        <span className={classNames(style.lineConnectorContainer)}>
            <span className={classNames(style.lineConnectorVertical, props.className ? props.className : "")}>
                <span className={classNames(style.lineConnectorHorizontal)} />
            </span>
        </span>
    );
};

export default BoxConnector;
