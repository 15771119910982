import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DateRangeSelection } from "components/reports/ReportsDateRangeView";
import { getObject, RepositoryKey, setObject } from "utils/repository";

export interface ReportViewSettings {
    selectedRange: DateRangeSelection;
    startDate?: string;
    endDate?: string;
}

export interface ReportViewSettingsUpdate extends ReportViewSettings {
    identifier: string;
}

const INITIAL_STATE: Record<string, ReportViewSettings> = {};

const REPORT_VIEW_SETTINGS_SLICE = createSlice({
    name: "reportViewSettingsStore",
    initialState:
        (getObject(RepositoryKey.REPORT_VIEW_SETTINGS) as Record<string, ReportViewSettingsUpdate>) ?? INITIAL_STATE,
    reducers: {
        updateReportViewSettings(state, action: PayloadAction<ReportViewSettingsUpdate>) {
            state[action.payload.identifier] = Object.assign({}, state[action.payload.identifier], {
                selectedRange: action.payload.selectedRange,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
            });
            setObject(RepositoryKey.REPORT_VIEW_SETTINGS, state);
        },
    },
});

export const { updateReportViewSettings } = REPORT_VIEW_SETTINGS_SLICE.actions;

export default REPORT_VIEW_SETTINGS_SLICE.reducer;
