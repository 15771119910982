import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";

import Warning from "components/icons/Warning";
import Modal from "components/modal/Modal";
import style from "components/reports/report-view.scss";
import warningStyle from "components/tenants/tenants.scss";
import Heading from "components/typography/heading/Heading";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

export interface DeleteAgainProperties {
    modalDisplayed: boolean;
    setModalDisplayed: (display: boolean) => void;
    message?: string;
    supportMessage?: string;
    handleCommand: () => void;
    title: string;
    reportUuid?: string;
    ditchLink: boolean;
}

const DeleteItemStatusDialog = (props: DeleteAgainProperties & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    const subject = encodeURIComponent(
        "Blancco Management Portal - Unexpected Error while deleting report : " + props.reportUuid
    );
    const body = encodeURIComponent(
        "Hi there\n\n" +
            "I've experienced a persistent issue with deleting report: " +
            props.reportUuid +
            "\nWould you be able to assist?\n\n"
    );
    const mailToLink = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;

    return (
        <div>
            <Modal isOpen={props.modalDisplayed} hideModal={() => props.setModalDisplayed(false)}>
                <div className={warningStyle.warningContainer}>
                    <Warning color={theme.errorBackgroundColor} />
                </div>
                <div className={style.title}>
                    <Heading tag="h2">{props.title}</Heading>
                </div>
                <div className={warningStyle.warningContainer}>
                    {props.ditchLink ? (
                        <span>{t("LicensePools.deleteLicensePool.failureMessage")}</span>
                    ) : (
                        <>
                            {t("DeleteReport.deleteReport.reportDeleted.failureMessage")}
                            <span>
                                <a href={mailToLink}>{t("DeleteReport.deleteReport.reportDeleted.supportMessage")} </a>
                            </span>
                        </>
                    )}
                </div>
                <div className={warningStyle.warningContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                        onClick={() => props.handleCommand()}
                        data-testid={testIds.common.dialog.retryButton}
                    >
                        {t("Common.deleteAgain")}
                    </button>
                    <button
                        className={classNames(buttons.secondaryButton, buttons.medium, style.okButton)}
                        onClick={() => props.setModalDisplayed(false)}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.cancel")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};
export default connector(DeleteItemStatusDialog);
