import * as React from "react";

import style from "./plain-copy.scss";

// Copy icon with colored lines and no fill
const PlainCopy = (): JSX.Element => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.0557 4.11353C5.78257 4.11353 5.52063 4.22202 5.32751 4.41515C5.13438 4.60828 5.02588 4.87022 5.02588 5.14335V13.1525C5.02588 13.2878 5.05252 13.4217 5.10427 13.5466C5.15602 13.6716 5.23188 13.7851 5.32751 13.8807C5.42313 13.9764 5.53666 14.0522 5.6616 14.104C5.78655 14.1557 5.92046 14.1824 6.0557 14.1824H6.94561C7.35982 14.1824 7.69561 14.5181 7.69561 14.9324C7.69561 15.3466 7.35982 15.6824 6.94561 15.6824H6.0557C5.72348 15.6824 5.39451 15.6169 5.08758 15.4898C4.78065 15.3626 4.50176 15.1763 4.26685 14.9414C4.03193 14.7065 3.84559 14.4276 3.71845 14.1207C3.59131 13.8137 3.52588 13.4848 3.52588 13.1525V5.14335C3.52588 4.4724 3.79241 3.82893 4.26685 3.35449C4.74128 2.88006 5.38475 2.61353 6.0557 2.61353H14.0649C14.7358 2.61353 15.3793 2.88006 15.8537 3.35449C16.3282 3.82893 16.5947 4.4724 16.5947 5.14335V7.00055C16.5947 7.41476 16.2589 7.75055 15.8447 7.75055C15.4305 7.75055 15.0947 7.41476 15.0947 7.00055V5.14335C15.0947 4.87022 14.9862 4.60828 14.7931 4.41515C14.6 4.22202 14.338 4.11353 14.0649 4.11353H6.0557ZM11.0455 10.1812C10.3949 10.1812 9.86741 10.7087 9.86741 11.3593V19.0719C9.86741 19.7225 10.3949 20.25 11.0455 20.25H18.7581C19.4088 20.25 19.9362 19.7225 19.9362 19.0719V11.3593C19.9362 10.7087 19.4088 10.1812 18.7581 10.1812H11.0455ZM8.36741 11.3593C8.36741 9.88023 9.56645 8.68119 11.0455 8.68119H18.7581C20.2372 8.68119 21.4362 9.88023 21.4362 11.3593V19.0719C21.4362 20.551 20.2372 21.75 18.7581 21.75H11.0455C9.56645 21.75 8.36741 20.551 8.36741 19.0719V11.3593Z"
                className={style.shape}
            />
        </svg>
    );
};

export default PlainCopy;
