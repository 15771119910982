import * as React from "react";

const KebabIcon = (props: { color: string; className?: string }): JSX.Element => {
    return (
        <svg
            id="drag"
            xmlns="http://www.w3.org/2000/svg"
            width="3"
            height="17"
            viewBox="0 0 3 17"
            className={props.className}
        >
            <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.5" cy="1.5" r="1.5" fill={props.color} />
            <circle
                id="Ellipse_4"
                data-name="Ellipse 4"
                cx="1.5"
                cy="1.5"
                r="1.5"
                transform="translate(0 7)"
                fill={props.color}
            />
            <circle
                id="Ellipse_5"
                data-name="Ellipse 5"
                cx="1.5"
                cy="1.5"
                r="1.5"
                transform="translate(0 14)"
                fill={props.color}
            />
        </svg>
    );
};

export default KebabIcon;
