import * as React from "react";

const DoughnutChartIcon = (props: { borderColor: string; fillColor: string }): JSX.Element => {
    return (
        <svg id="chart_donut" xmlns="http://www.w3.org/2000/svg" width="72" height="48" viewBox="0 0 72 48">
            <g
                id="Rectangle_1521"
                data-name="Rectangle 1521"
                fill={props.fillColor}
                stroke={props.borderColor}
                strokeWidth="1"
            >
                <rect width="72" height="48" rx="6" stroke="none" />
                <rect x="0.5" y="0.5" width="71" height="47" rx="5.5" fill="none" />
            </g>
            <g id="icons8-doughnut-chart_2_" data-name="icons8-doughnut-chart (2)" transform="translate(20 8)">
                <path
                    id="Path_1916"
                    data-name="Path 1916"
                    d="M16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Z"
                    fill={props.fillColor}
                    stroke={props.borderColor}
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_1917"
                    data-name="Path 1917"
                    d="M16,12a4,4,0,1,0,4,4A4,4,0,0,0,16,12Zm0,0V4m2,15,6,6m-4-9h8"
                    fill={props.fillColor}
                    stroke={props.borderColor}
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
            </g>
        </svg>
    );
};

export default DoughnutChartIcon;
