import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Column } from "react-table";

import ContainerMenuItems from "./ContainerMenuItems";
import style from "./containers.scss";
import KebabMenu from "components/kebab-menu/KebabMenu";
import SyncContainer from "components/licenses/SyncContainer";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_EMS_CREATE_ENTITLEMENT } from "domain/authority";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { Container, ContainerCursor, Containers, ContainerType } from "domain/licenses";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { applyMode } from "store/theme";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { logger } from "utils/logging";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
    tenantUuid: state.userReducer.user?.tenantUuid,
    region: state.userReducer.user?.region,
});

interface TableState {
    containers: Container[];
    cursor: ContainerCursor | null;
    count?: number;
}

interface Props {
    initialContainer: Containers;
    requestFailureMessage: string;
    onUpdate: (isSync: boolean) => void;
    count: number;
}

const CONTAINER_TYPE_TO_LOCALIZATION_KEY = new Map([
    [ContainerType.HL, "Containers.type.hl"],
    [ContainerType.SL_ADMIN_MODE, "Containers.type.sl"],
    [ContainerType.SL_CREATE, "Containers.type.sl"],
    [ContainerType.SL_UPDATE, "Containers.type.sl"],
    [ContainerType.HL_UPDATE, "Containers.type.hlUpdate"],
]);

const connector = connect(mapState, { applyMode });
const ContainersTable = (props: ConnectedProps<typeof connector> & Props): JSX.Element => {
    const { t } = useTranslation();
    const tableContainerRef = React.useRef<HTMLDivElement>(null);

    const columns: Array<Column<Container>> = [
        {
            Header: () => <TextWithTooltip text={t("Containers.tableHeader.containerId")} key="container_id" />,
            accessor: "containerId",
            Cell: (cellInfo) => (
                <>
                    {userSessionService.userHasAllAuthorities([AUTH_EMS_CREATE_ENTITLEMENT]) && (
                        <KebabMenu>
                            <ContainerMenuItems
                                containerId={cellInfo.row.original.containerId}
                                name={cellInfo.row.original.name}
                                onUpdate={props.onUpdate}
                            />
                        </KebabMenu>
                    )}
                    <div className={style.containerDateCell}>
                        <TextWithTooltip text={cellInfo.cell.value} />
                    </div>
                </>
            ),
            width: deriveColumnWidth(20, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Common.creationDate")} key="date" />,
            accessor: "date",
            Cell: ({ cell: { value } }) => <DateCell value={value} tooltip={true} withoutTime={true} />,
            width: deriveColumnWidth(15, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Containers.tableHeader.type")} key="type" />,
            accessor: "type",
            Cell: ({ cell: { value } }) => (
                <TextWithTooltip text={t(CONTAINER_TYPE_TO_LOCALIZATION_KEY.get(value) ?? value)} />
            ),
            width: deriveColumnWidth(30, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Containers.tableHeader.name")} key="name" />,
            accessor: "name",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(33, tableContainerRef),
        },
    ];
    const [state, setState] = React.useState<TableState>({
        containers: props.initialContainer.containers,
        cursor: props.initialContainer.cursor,
        count: props.count,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>(props.requestFailureMessage);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [syncing, setSyncing] = React.useState<boolean>(false);
    const [syncSuccess, setSyncSuccess] = React.useState<boolean>(false);
    const [showSyncResult, setShowSyncResult] = React.useState<boolean>(false);
    const [lastSynced, setLastSynced] = React.useState<string | null>(null);
    const [loadMore, setLoadMore] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState("");
    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        setLoadMore(true);
        if (initialLoading) {
            state.cursor = null;
        }
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseService
            .fetchContainers(abortController, loadMore ? state.cursor : null, searchQuery)
            .then((data) => {
                setState((prevState) => ({
                    containers: loadMore
                        ? prevState.containers.concat(data.containers.containers)
                        : data.containers.containers,
                    count: prevState.containers.length - 1,
                    cursor: data.containers.cursor,
                }));
                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("Containers.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };
    if (typeof props.tenantUuid === "undefined") {
        logger.error("Unable to fetch EMS configuration. Tenant UUID is required.");
    } else {
        const abortControllerEmsConfiguration = new AbortController();
        abortControllers.push(abortControllerEmsConfiguration);
        licenseService
            .fetchEmsConfiguration(props.tenantUuid, abortControllerEmsConfiguration, props.region)
            .then((data) => {
                setLastSynced(data.lastSynced);
            })
            .catch(() => {
                logger.error("Fetching the EMS configuration has failed.");
            });
    }

    React.useEffect(() => {
        if (props.initialContainer.containers.length > 0) {
            return;
        }
        setState({
            containers: [],
            cursor: { lastContainerId: "", lastTenantUuid: "" },
            count: 0,
        });
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [props.count, searchQuery]);

    let dataCount = null;
    if (state.containers.length > 0) {
        dataCount = t("Common.defaultSearchResultHint", { dataCount: state.containers.length });
    }

    function onSyncClick() {
        setLoadMore(false);
        setSyncing(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        licenseService
            .synchronize(abortController)
            .then(() => {
                setSyncSuccess(true);
                props.onUpdate(true);
            })
            .catch(() => {
                if (!signal.aborted) {
                    setSyncSuccess(false);
                }
            })
            .finally(() => {
                if (!signal.aborted) {
                    setSyncing(false);
                    setShowSyncResult(true);
                }
            });
    }

    return (
        <>
            <div className={style.aboveTable}>
                <div className={style.recordCount}>
                    <SyncContainer
                        dataCount={dataCount ? dataCount : "0"}
                        onSyncClick={onSyncClick}
                        syncing={syncing}
                        success={syncSuccess}
                        showResult={showSyncResult}
                        lastSynced={lastSynced}
                        loading={loading}
                    />
                </div>
                <div className={classNames(formStyle.search, style.searchPadding)}>
                    <SearchView setSearch={setSearchQuery} searchInProgress={false} />
                </div>
            </div>

            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.CONTAINERS_TABLE}
                    data={state.containers}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    emptyMessage={t("Containers.emptyStateMessage", { email: SUPPORT_EMAIL })}
                    data-testid={testIds.workArea.license.containers.table}
                />
            </div>
            {state.cursor != null &&
                state.containers.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(buttonStyle.primaryButton, buttonStyle.loadMoreButton)}
                        onClick={() => {
                            fetchData(false);
                            setLoading(true);
                            usageStatisticsService.sendEvent({
                                label: Label.CONTAINER,
                                action: Action.LOAD_MORE,
                                category: Category.LICENSE,
                            });
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
        </>
    );
};

ContainersTable.defaultProps = {
    initialContainer: {
        containers: [],
        cursor: null,
    },
    requestFailureMessage: "",
};

export default connector(ContainersTable);
