import classNames from "classnames";
import { useFeature } from "flagged";
import * as React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";

import Delete from "components/icons/Delete";
import Edit from "components/icons/Edit";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import style from "components/reports/report.scss";
import SingleReportDeletion from "components/reports/SingleReportDeletion";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_REPORT_DELETE, AUTH_REPORT_EDIT } from "domain/authority";
import { FLAG_DELETE_REPORT } from "services/feature/FeatureFlagService";
import { ReportType } from "services/report/erasure/ReportService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    userDetail: state.userReducer.user,
});

const connector = connect(mapState);

const ReportKebabMenu = (
    props: ConnectedProps<typeof connector> & {
        reportUuid: string;
        hideDialogView?: (value: boolean) => void;
        onReportDeleted: () => void;
        showForm: (value: boolean) => void;
        editReportUuid: (value: string) => void;
        reportType: ReportType;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [deletionStarted, setDeletionStarted] = React.useState(false);
    const showManageView = () => {
        props.showForm(true);
        props.editReportUuid(props.reportUuid);
    };
    return (
        <>
            <div className={classNames(style.deleteIcon)}>
                <Menu className={style.kebabMenu} data-testid={testIds.common.primaryView.table.kebabMenu.itself}>
                    <ul>
                        {!hasTenantCookie() &&
                            userSessionService.userHasAllAuthorities([AUTH_REPORT_EDIT]) &&
                            props.reportType == "ERASURE" && (
                                <li>
                                    <TextWithTooltip text={t("Common.edit")}>
                                        <MenuItemButton
                                            onClick={() => {
                                                showManageView();
                                                usageStatisticsService.sendEvent({
                                                    category: Category.REPORTS,
                                                    action: Action.EDIT_SINGLE_REPORT,
                                                });
                                            }}
                                            data-testid={testIds.workArea.report.primaryView.table.kebabMenu.editButton}
                                        >
                                            <Edit color={theme.iconFillColor} />
                                        </MenuItemButton>
                                    </TextWithTooltip>
                                </li>
                            )}
                        {userSessionService.userHasAllAuthorities([AUTH_REPORT_DELETE]) &&
                            useFeature(FLAG_DELETE_REPORT) && (
                                <li>
                                    <TextWithTooltip text={t("Common.delete")}>
                                        <MenuItemButton
                                            onClick={() => {
                                                setDeletionStarted(true);
                                                usageStatisticsService.sendEvent({
                                                    category: Category.REPORTS,
                                                    action: Action.REMOVE_SINGLE_REPORT,
                                                });
                                            }}
                                            data-testid={
                                                testIds.workArea.report.primaryView.table.kebabMenu.deleteButton
                                            }
                                        >
                                            <Delete color={theme.linkTextColor} />
                                        </MenuItemButton>
                                    </TextWithTooltip>
                                </li>
                            )}
                    </ul>
                </Menu>

                <SingleReportDeletion
                    reportUuid={props.reportUuid}
                    deletionInvoked={deletionStarted}
                    onDeletionInvoked={setDeletionStarted}
                    onReportDeleted={props.onReportDeleted}
                />
            </div>
        </>
    );
};
export default connector(ReportKebabMenu);
