import React from "react";

import style from "./skeleton-loader.scss";

import testIds from "testIds.json";

interface Props {
    width?: number;
}

export const SkeletonLoader = (props: Props) => {
    return (
        <div
            className={style.skeletonLoader}
            style={{ width: props.width }}
            data-testid={testIds.common.skeletonLoaderElement}
        >
            <span></span>
        </div>
    );
};
