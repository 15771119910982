import * as React from "react";

const IndeterminateBox = (props: { lineColor: string; backgroundColor: string }): JSX.Element => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_798_5273)">
            <path
                d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
                fill={props.backgroundColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.25 8C3.25 7.58579 3.58579 7.25 4 7.25H12C12.4142 7.25 12.75 7.58579 12.75 8C12.75 8.41421 12.4142 8.75 12 8.75H4C3.58579 8.75 3.25 8.41421 3.25 8Z"
                fill={props.lineColor}
            />
        </g>
        <defs>
            <clipPath id="clip0_798_5273">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default IndeterminateBox;
