import classNames from "classnames";
import React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { availableLicenses } from "./AddLicensePools";
import { DEFAULT_LICENSE } from "./manage-license-pools/ManageLicensePoolView";
import MoveLicensesDialog from "./manage-license-pools/MoveLicensesDialog";
import DeleteConfirmationDialog from "components/confirmation/DeleteConfirmationDialog";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import Delete from "components/icons/Delete";
import Edit from "components/icons/Edit";
import Move from "components/icons/Move";
import { SuccessIcon } from "components/icons/SuccessIcon";
import layoutStyle from "components/layout/layout.scss";
import { FEATURE_LICENSES, isSubscriptionLicense } from "components/licenses/common";
import { License } from "components/licenses/delivery-history/DeliveryFormContent";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import style from "components/reports/report.scss";
import DeleteItemStatusDialog from "components/status-dialog/DeleteItemStatusDialog";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_LICENSE_POOL_DELETE, AUTH_LICENSE_POOL_EDIT } from "domain/authority";
import { LicensePoolLicense } from "domain/licenses";
import { licensePoolService } from "services/licenses/LicensePoolService";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";

import FailedRedNotificationIcon from "assets/images/icons/alertIcon.svg";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string | JSX.Element;
    image?: string | JSX.Element;
}

export interface PoolDetails {
    uuid: string;
    name: string;
    assignedUsers: number;
    licenses: LicensePoolLicense[];
    notes: string;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    userDetail: state.userReducer.user,
});

const connector = connect(mapState);

const LicensePoolKebabMenu = (props: {
    licensePoolName: string;
    licensePoolUuid: string;
    assignedUsers: number;
    licenses: LicensePoolLicense[];
    notes: string;
    setAllLicenses: (value: License[]) => void;
    licenseFetchFailed: (value: boolean) => void;
    loading: (value: boolean) => void;
    poolDetails: (value: PoolDetails) => void;
    showForm: (value: boolean) => void;
    edit: (value: boolean) => void;
    onLicensePoolDelete: () => void;
    setRefreshCount: React.Dispatch<React.SetStateAction<number>>;
}): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [result, setResult] = React.useState<Result>({ title: "", message: "", image: "" });
    const [deleteLicensePoolModalVisible, setDeleteLicensePoolModalVisible] = React.useState(false);
    const [licensePoolNotDeleted, setLicensePoolNotDeleted] = React.useState<boolean>(false);
    const [okClicked, setOkClicked] = React.useState<boolean>(false);
    const [moveLicensesDialog, setMoveLicensesDialog] = React.useState<boolean>(false);
    const [allAvailableLicenses, setAllAvailableLicenses] = React.useState<License[]>([]);
    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
    };
    const licenseWithAmount = props.licenses.filter((each) => each.licenseId !== DEFAULT_LICENSE && each.amount > 0);
    const checkForLicensePoolDeletion = props.assignedUsers < 1;

    const disableMoveButton = (): boolean => {
        let licenseAmount = 0;
        props.licenses.forEach(function (each) {
            if (each.amount == 0) {
                licenseAmount = licenseAmount + 1;
            }
        });
        return props.licenses.length == licenseAmount;
    };

    const showManageView = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        props.loading(true);
        props.showForm(true);
        licenseService
            .fetchLicenses(abortController)
            .then((data) => {
                const allAvailableLicenses = availableLicenses(data.licenses);
                props.setAllLicenses(
                    allAvailableLicenses.filter(
                        (each) =>
                            !(isSubscriptionLicense(each.licenseType) || FEATURE_LICENSES.includes(each.licenseType))
                    )
                );
            })
            .catch(() => {
                props.licenseFetchFailed(true);
            })
            .finally(() => {
                props.loading(false);
            });
    };

    const showMoveLicensesDialog = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseService
            .fetchLicenses(abortController)
            .then((data) => {
                const licensesData = availableLicenses(data.licenses).filter(
                    (each) => !(isSubscriptionLicense(each.licenseType) || FEATURE_LICENSES.includes(each.licenseType))
                );
                props.setAllLicenses(licensesData);
                setAllAvailableLicenses(licensesData);
            })
            .catch(() => {
                props.licenseFetchFailed(true);
            })
            .finally(() => {
                props.loading(false);
            });
    };

    const handleLicensePoolDelete = () => {
        deleteLicensePoolSubmitEventHandler();
        setLicensePoolNotDeleted(false);
    };
    const deleteLicensePoolSubmitEventHandler = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        licensePoolService
            .deleteLicensePool(props.licensePoolUuid, abortController)
            .then(() => {
                props.onLicensePoolDelete();
                toast(
                    <SuccessIcon
                        successClass={layoutStyle.customToastSuccessIcon}
                        color={theme.contentBackgroundColor}
                        text={t("LicensePools.deleteLicensePool.deletedMessage", {
                            poolName: props.licensePoolName,
                        })}
                    />,
                    {
                        closeButton: (closeToastProps) => (
                            <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                        ),
                        className: layoutStyle.customToastSuccessMessage,
                    }
                );
            })
            .catch(() => {
                if (!signal.aborted) {
                    showResult({
                        title: t("LicensePools.deleteLicensePool.licensePoolNotDeletedTitle"),
                        message: <span>{t("LicensePools.deleteLicensePool.failureMessage")}</span>,
                        image: <img className={style.image} src={FailedRedNotificationIcon} />,
                    });
                }
                setLicensePoolNotDeleted(true);
            })
            .finally(() => {
                setOkClicked(false);
                setDeleteLicensePoolModalVisible(false);
            });
    };

    return (
        <>
            <div className={classNames(style.deleteIcon)}>
                <Menu className={style.kebabMenu} data-testid={testIds.common.primaryView.table.kebabMenu.itself}>
                    <ul>
                        {
                            <>
                                <li>
                                    {userSessionService.userHasAnyAuthority([AUTH_LICENSE_POOL_EDIT]) && (
                                        <TextWithTooltip text={t("Common.edit")}>
                                            <MenuItemButton
                                                tag="button"
                                                onKeyDown={undefined}
                                                onClick={(e) => {
                                                    props.edit(true);
                                                    showManageView();
                                                    props.poolDetails({
                                                        uuid: props.licensePoolUuid,
                                                        name: props.licensePoolName,
                                                        assignedUsers: props.assignedUsers,
                                                        licenses: props.licenses,
                                                        notes: props.notes ? props.notes : "",
                                                    });
                                                    e.preventDefault();
                                                }}
                                                data-testid={
                                                    testIds.workArea.license.licensePools.table.kebabMenu.editButton
                                                }
                                            >
                                                <Edit color={theme.iconFillColor} />
                                            </MenuItemButton>
                                        </TextWithTooltip>
                                    )}
                                </li>
                                <li>
                                    {userSessionService.userHasAnyAuthority([AUTH_LICENSE_POOL_EDIT]) && (
                                        <TextWithTooltip text={t("LicensePools.move.moveButton")}>
                                            <MenuItemButton
                                                tag="button"
                                                onKeyDown={undefined}
                                                onClick={(e) => {
                                                    if (!disableMoveButton() || licenseWithAmount.length > 0) {
                                                        showMoveLicensesDialog();
                                                        props.poolDetails({
                                                            uuid: props.licensePoolUuid,
                                                            name: props.licensePoolName,
                                                            assignedUsers: props.assignedUsers,
                                                            licenses: props.licenses,
                                                            notes: props.notes ? props.notes : "",
                                                        });
                                                        setMoveLicensesDialog(true);
                                                        usageStatisticsService.sendEvent({
                                                            category: Category.LICENSE_POOLS,
                                                            action: Action.MOVE_LICENSES_BETWEEN_POOLS,
                                                        });
                                                    }
                                                    e.preventDefault();
                                                }}
                                                data-testid={
                                                    testIds.workArea.license.licensePools.table.kebabMenu.moveButton
                                                }
                                            >
                                                <Move
                                                    color={
                                                        !disableMoveButton() || licenseWithAmount.length > 0
                                                            ? theme.iconFillColor
                                                            : theme.disabledButtonBackgroundColor
                                                    }
                                                />
                                            </MenuItemButton>
                                        </TextWithTooltip>
                                    )}
                                </li>
                                <li>
                                    {userSessionService.userHasAnyAuthority([AUTH_LICENSE_POOL_DELETE]) && (
                                        <TextWithTooltip text={t("Common.delete")}>
                                            <MenuItemButton
                                                tag="button"
                                                onKeyDown={undefined}
                                                onClick={(e) => {
                                                    if (checkForLicensePoolDeletion) {
                                                        setDeleteLicensePoolModalVisible(true);
                                                        usageStatisticsService.sendEvent({
                                                            category: Category.LICENSE_POOLS,
                                                            action: Action.DELETE_LICENSE_POOL,
                                                        });
                                                    }
                                                    e.preventDefault();
                                                }}
                                                data-testid={
                                                    testIds.workArea.license.licensePools.table.kebabMenu.deleteButton
                                                }
                                            >
                                                <Delete
                                                    color={
                                                        checkForLicensePoolDeletion
                                                            ? theme.errorBackgroundColor
                                                            : theme.disabledButtonBackgroundColor
                                                    }
                                                />
                                            </MenuItemButton>
                                        </TextWithTooltip>
                                    )}
                                </li>
                            </>
                        }
                    </ul>
                </Menu>
                <DeleteItemStatusDialog
                    title={result.title}
                    modalDisplayed={licensePoolNotDeleted}
                    setModalDisplayed={() => setLicensePoolNotDeleted(false)}
                    handleCommand={deleteLicensePoolSubmitEventHandler}
                    ditchLink={true}
                />
                <DeleteConfirmationDialog
                    modalDisplayed={deleteLicensePoolModalVisible}
                    introductionMessage={
                        <div className={style.resultContainer}>
                            {t("LicensePools.deleteLicensePool.introductionMessage", {
                                poolName: props.licensePoolName,
                            })}
                        </div>
                    }
                    confirmationMessage={t("LicensePools.deleteLicensePool.confirmationMessage")}
                    handleCommand={handleLicensePoolDelete}
                    setModalDisplayed={() => setDeleteLicensePoolModalVisible(false)}
                    title={t("LicensePools.deleteLicensePool.title")}
                    loading={okClicked}
                />
            </div>
            <Modal
                isOpen={moveLicensesDialog}
                hideModal={() => setMoveLicensesDialog(false)}
                modalTitle={t("LicensePools.move.title")}
            >
                <MoveLicensesDialog
                    poolDetails={{
                        uuid: props.licensePoolUuid,
                        name: props.licensePoolName,
                        assignedUsers: props.assignedUsers,
                        licenses: props.licenses,
                        notes: props.notes ? props.notes : "",
                    }}
                    availableLicenses={allAvailableLicenses}
                    setMoveLicensesDialog={setMoveLicensesDialog}
                    edit={false}
                    setRefreshCount={props.setRefreshCount}
                />
            </Modal>
        </>
    );
};

export default connector(LicensePoolKebabMenu);
