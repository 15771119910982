import { FeatureLicenseType } from "./users";
import { CombinedTier, LicensingModel, LoginMethod, loginMethodList, TenantType } from "domain/tenants";

export interface CommonUserDetails {
    created: string;
    email: string;
    modified: string;
    name: string | undefined;
    username: string;
    uuid: string;
}

export interface Role {
    authorities: string[];
}

export interface DefaultViews {
    default_view: string;
    tenant_access_default_view: string;
}

export interface UserDetails extends CommonUserDetails {
    tenantName: string;
    tenantType: TenantType;
    tenantUuid: string;
    usageStatistics: boolean;
    erasureClientEndpoint: string;
    eulaAcceptedDate: string;
    featureLicenses: FeatureLicenseType[];
    region: string;
    role: Role;
    enabled?: boolean;
    updateWorkflow: boolean;
    userExpirationDate?: string;
    tenantExpirationDate?: string;
    tenantStatus?: boolean;
    tier: CombinedTier;
    default_views: DefaultViews;
    tenantLoginMethod?: LoginMethod;
    showWorkflowUpdateDialog: boolean;
    licensingModel: LicensingModel;
}
export const DEFAULT_LOGIN_METHOD = "DEFAULT";
export const userLoginMethodList = loginMethodList.concat(DEFAULT_LOGIN_METHOD);
export type UserLoginMethod = typeof userLoginMethodList[number];
export const STANDARD_USER_TYPE = "STANDARD";
export const INTEGRATION_USER_TYPE = "INTEGRATION";
export const MACHINE_USER_TYPE = "MACHINE";
export const userTypeList = [STANDARD_USER_TYPE, "INTEGRATION", "MACHINE"];
export type UserType = typeof userTypeList[number];
