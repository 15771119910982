import { UserDetails } from "domain/user";
import { getObject, remove, RepositoryKey, setObject } from "utils/repository";

export function getUser(): UserDetails | null {
    const user: UserDetails | null = getObject(RepositoryKey.USER_DETAILS);
    if (user == null) {
        return user;
    }
    return user;
}

export function setUser(userDetails: UserDetails): void {
    setObject(RepositoryKey.USER_DETAILS, userDetails);
}

export function clear(): void {
    remove(RepositoryKey.USER_DETAILS);
}
