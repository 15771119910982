import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LanguageDetails } from "domain/language";
import * as LanguageRepository from "services/language/languageRepository";

export interface LanguageState {
    lang: LanguageDetails;
}

const INITIAL_STATE: LanguageState = {
    lang: LanguageRepository.getLanguage(),
};

const languageSlice = createSlice({
    name: "languageStore",
    initialState: INITIAL_STATE,
    reducers: {
        setLanguage(state, action: PayloadAction<LanguageDetails>) {
            state.lang = action.payload;
        },
    },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
