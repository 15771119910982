import lottie from "lottie-web";
import * as React from "react";

import loadingIndicator from "./loading-indicator.json";
import style from "./loading-indicator.scss";

import testIds from "testIds.json";

export const LoadingIndicator = (props: { small?: boolean }): JSX.Element => {
    const divElement = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        if (divElement.current !== null) {
            const animation = lottie.loadAnimation({
                container: divElement.current,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: loadingIndicator,
            });

            return () => animation.stop();
        }
    }, []);
    return (
        <div
            className={props.small === true ? style.smallGrid : style.skCubeGrid}
            data-testid={testIds.common.inProgressElement}
        >
            <div ref={divElement} />
        </div>
    );
};
