export interface ReportPath {
    path: string;
    translation: string;
    context: string;
    suggestedValues: string[];
}
export const COMMON_REPORT_PATHS: ReportPath[] = [
    {
        path: "blancco_erasure_report.erasures.erasure.state",
        translation: "Overview.commonReportPaths.erasure.erasureState",
        context: "ERASURE",
        suggestedValues: ["Successful", "failed"],
    },
    {
        path: "blancco_hardware_report.system.market_name",
        translation: "Overview.commonReportPaths.erasure.marketName",
        context: "ERASURE",
        suggestedValues: [],
    },
    {
        path: "report.product_id",
        translation: "Overview.commonReportPaths.erasure.productName",
        context: "ERASURE",
        suggestedValues: [],
    },
];

export const getReportPaths = (context: string): ReportPath[] => {
    return COMMON_REPORT_PATHS.filter((path) => path.context === context);
};

export const getSuggestedValues = (context: string, path: string): string[] => {
    if (context === "" || path === "") {
        return [];
    }
    const reportPath = COMMON_REPORT_PATHS.filter((element) => element.context === context && element.path === path);
    return reportPath.length ? reportPath[0].suggestedValues : [];
};
