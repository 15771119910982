import { AssetData, AssetDataDto } from "domain/iosProfile";
import { apiGatewayService } from "services/api/ApiGatewayService";

function toProfileData(data: AssetDataDto): AssetData {
    return {
        assetProperties: data.asset_properties,
        assetId: data.asset_id,
        identifier: data.identifier,
        tenantIdentifier: data.tenant_identifier,
        url: data.url,
        imeiMandatory: data.imei_mandatory,
        serialMandatory: data.serial_mandatory,
        primary_color: data.primary_color,
    };
}

/**
 * Handles Asset information API call.
 */
class IosProfilePageService {
    public fetchAssetData(abortController: AbortController, journeyId: string, region: string): Promise<AssetData> {
        return apiGatewayService
            .invokeApi("/ios-profile-download/" + journeyId, "GET", null, {
                abortController,
                region,
            })
            .then((data: AssetDataDto) => toProfileData(data));
    }
}

export const iosProfilePageService = new IosProfilePageService();
