export interface OverviewDashboardData {
    version: number;
    widgets: Widget[];
}

export interface Widget {
    slotIndex: number;
    uuid: string;
    name: string;
    description: string;
    type: WidgetType;
    x?: number;
    y?: number;
    width: number;
    height: number;
    aggregationQuery: AggregatedDocumentCountQuery;
}

export interface AggregatedDocumentCountQuery {
    type: Type;
    interval?: Interval;
    timeRangeType: TimeRangeType;
    startTime: string;
    endTime: string;
    matchers: Matcher[];
    maximumNumberOfTerms?: number;
    scopeMatchers?: Matcher[];
}

export interface Matcher {
    path: string;
    value?: string;
    context: Context;
    legend?: string;
}

export const types = ["TIME_AGGREGATED_DOCUMENT_COUNT", "TERM_AGGREGATED_DOCUMENT_COUNT"] as const;
export type Type = typeof types[number];

export const intervals = ["ALL", "YEAR", "MONTH", "DAY"] as const;
export type Interval = typeof intervals[number];

export const widgetTypes = ["BAR_CHART", "LINE_CHART", "SINGLE_VALUE_CHART", "DOUGHNUT_CHART", "CREATE_CHART"] as const;
export type WidgetType = typeof widgetTypes[number];

export const timeRangeTypes = [
    "STATIC",
    "YESTERDAY",
    "LAST_7_DAYS",
    "LAST_30_DAYS",
    "LAST_WEEK",
    "LAST_MONTH",
    "CURRENT_WEEK",
    "CURRENT_MONTH",
] as const;
export type TimeRangeType = typeof timeRangeTypes[number];

export interface AggregatedDocumentCountData {
    dataAvailability: DataAvailability;
    created: string;
    type: Type;
    interval?: Interval;
    timeRangeType: TimeRangeType;
    startTime: string;
    endTime: string;
    labels: string[];
    dataSets: DataSet[];
}

export interface DataSet {
    matcher: Matcher;
    values: number[];
}

export const contexts = ["ERASURE", "DIAGNOSTIC"] as const;
export type Context = typeof contexts[number];

export enum DataAvailability {
    PENDING = "PENDING",
    AVAILABLE = "AVAILABLE",
}
