import * as React from "react";

const RightArrow = (props: { color: string }): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="12.303" viewBox="0 0 13.5 12.303">
            <g id="right-arrow" data-name="right-arrow" transform="translate(0.75 0.151)">
                <rect id="Rectangle_2372" data-name="Rectangle 2372" width="12" height="12" fill="none" />
                <g id="Group_6700" data-name="Group 6700" transform="translate(0 0.909)">
                    <path
                        id="Path_5031"
                        data-name="Path 5031"
                        d="M10.765,17.793l5.284-5.258L11,7.611"
                        transform="translate(-4.049 -7.611)"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <line
                        id="Line_1527"
                        data-name="Line 1527"
                        x1="11.536"
                        transform="translate(0 4.97)"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                </g>
            </g>
        </svg>
    );
};

export default RightArrow;
