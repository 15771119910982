export const EMAIL_MAX_LENGTH = 64;
export const NOTES_MAX_LENGTH = 1000;
export const NAME_MAX_LENGTH = 50;
export const TOOLTIP_DELAY = 500;
export const ESG_DATA_VERSION = "v1";
export const CDW_TOTAL_WIDGETS = 10;
export const CDW_NUMBER_OF_COLUMNS = 2;
export const SUPPORT_EMAIL = "support@blancco.com";
export const TABLE_PAGE_LIMIT = 100;
export const MANAGEMENT_PORTAL_PRODUCT_ID = "63";
export const MANAGEMENT_CONSOLE_PRODUCT_ID = "51";
export const DRAGGABLE_CARD_HANDLE_SELECTOR = ".drag-handle";
export const INTERNAL_TENANT_TYPE = "INTERNAL";
export const ICON_SIZE_SMALL = "30px";
