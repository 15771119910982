import * as React from "react";

const PreviewIcon = (props: { color: string }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.835938 10.0007C0.835938 10.0007 4.16927 3.33398 10.0026 3.33398C15.8359 3.33398 19.1693 10.0007 19.1693 10.0007C19.1693 10.0007 15.8359 16.6673 10.0026 16.6673C4.16927 16.6673 0.835938 10.0007 0.835938 10.0007Z"
                stroke={props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.0026 12.5007C11.3833 12.5007 12.5026 11.3814 12.5026 10.0007C12.5026 8.61994 11.3833 7.50065 10.0026 7.50065C8.62189 7.50065 7.5026 8.61994 7.5026 10.0007C7.5026 11.3814 8.62189 12.5007 10.0026 12.5007Z"
                stroke={props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default PreviewIcon;
