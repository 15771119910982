import { Notification } from "domain/notification";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { getObject, RepositoryKey, setObject } from "utils/repository";

export interface NotificationDto {
    uuid: string;
    timestamp: string;
    type: string;
    data: string;
}

function toNotification(dto: NotificationDto): Notification {
    return {
        uuid: dto.uuid,
        creationDate: dto.timestamp,
        type: dto.type,
        data: dto.data,
    };
}

class NotificationService {
    public fetchNotifications(abortController?: AbortController): Promise<Notification[]> {
        return apiGatewayService
            .invokeApi("/api/notifications", "GET", null, {
                abortController: abortController,
                apiType: ApiType.OLIVER,
            })
            .then((data: NotificationDto[]) => {
                return data.map(toNotification);
            });
    }

    public deleteNotification(notificationUuid: string): Promise<void> {
        return apiGatewayService.invokeApi(
            "/api/notification/" + encodeURIComponent(notificationUuid),
            "DELETE",
            null,
            {
                apiType: ApiType.OLIVER,
                emptyResponse: true,
            }
        );
    }

    public setSeenNotifications(notifications: Notification[]): void {
        const uuids = [];
        for (const index in notifications) {
            uuids.push(notifications[index].uuid);
        }
        setObject(RepositoryKey.SEEN_NOTIFICATIONS, uuids);
    }

    public getSeenNotifications(): string[] {
        return getObject(RepositoryKey.SEEN_NOTIFICATIONS) ?? [];
    }
}

export const notificationService = new NotificationService();
