import { License } from "components/licenses/delivery-history/DeliveryFormContent";
import { LicensePoolLicense } from "domain/licenses";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { ParameterQuery } from "utils/commonFunctions";

const PATH_LICENSE_POOLS_PREFIX = "/api/v1/license-pools";

export interface LicensePool {
    poolName: string;
    notes: string;
    licenses: LicensePoolLicense[];
    creationDate: string;
    creatorName: string;
    assignedUsers: number;
    editorUuid: string;
    editDate: string;
    poolUuid: string;
    defaultPool: boolean;
}

interface LicensePoolsDto {
    pool_name: string;
    notes: string;
    creation_date: string;
    creator_name: string;
    assigned_users: number;
    editor_uuid: string;
    edit_date: string;
    default_pool: boolean;
    licenses: {
        type: string;
        amount: number;
        expiration: string;
        assigned: number;
        used: number;
    }[];
    pool_uuid: string;
}

export interface LicensePoolDetails {
    type: string;
    amount: number;
}

export interface CreateLicensePoolDto {
    pool_name: string;
    notes: string;
    licenses: LicensePoolDetails[];
}

export interface EditLicensePoolDto extends CreateLicensePoolDto {
    pool_uuid: string;
}

export interface CreatePoolRequest {
    poolName: string;
    notes: string;
    licenses: LicensePoolDetails[];
}

export interface EditPoolRequest extends CreatePoolRequest {
    poolUuid: string;
}

export interface LicensePoolsResponse {
    licensePools: LicensePool[];
    cursor: string;
    count: number;
}

export interface LicensePoolLicensesResponse {
    allLicenses: LicensePoolLicense[];
}

export interface PoolLicensesResponse {
    licenses: LicensePool[];
}

interface LicensePoolsResponseDto {
    pools: LicensePoolsDto[];
    cursor: string;
    count: number;
}

export interface Licenses {
    type: string;
    amount: number;
}
export interface MoveLicensesDataDto {
    poolUuid: string;
    licenses: Licenses[];
}

export interface MoveLicensesData {
    destination_pool_uuid: string;
    licenses: Licenses[];
}

interface LicensePoolLicensesResponseDto {
    all_licenses: {
        type: string;
        amount: number;
        expiration: string;
        assigned: number;
        used: number;
        license: string;
        product: string;
    }[];
}

function toPoolsLicensesResponse(licenses: LicensePoolLicensesResponseDto) {
    return {
        allLicenses: licenses.all_licenses.map((each) => {
            return {
                licenseId: each.type,
                amount: each.amount,
                expirationDate: each.expiration,
                totalOfLicenses: each.assigned,
                used: each.used,
                license: each.license,
                product: each.product,
            };
        }),
    };
}

function toLicensePoolsDto(pools: LicensePoolsDto): LicensePool {
    return {
        notes: pools.notes,
        creationDate: pools.creation_date,
        creatorName: pools.creator_name,
        editorUuid: pools.editor_uuid,
        editDate: pools.edit_date,
        assignedUsers: pools.assigned_users,
        poolName: pools.pool_name,
        defaultPool: pools.default_pool,
        licenses: pools.licenses.map((each) => {
            return {
                licenseId: each.type,
                amount: each.amount,
                expirationDate: each.expiration,
                totalOfLicenses: each.assigned,
                used: each.used,
            };
        }),
        poolUuid: pools.pool_uuid,
    };
}

function toLicensePoolsResponse(dto: LicensePoolsResponseDto) {
    return {
        licensePools: dto.pools.map(toLicensePoolsDto),
        cursor: dto.cursor,
        count: dto.count,
    };
}

export interface LicensePoolList {
    poolUuid: string;
    poolName: string;
    defaultPool: boolean;
}

interface LicensePoolListDto {
    pool_uuid: string;
    pool_name: string;
    default_pool: boolean;
}

export interface LicensePoolListResponse {
    pools: LicensePoolList[];
}

interface LicensePoolListResponseDto {
    pools: LicensePoolListDto[];
}

function toLicensePoolList(pools: LicensePoolListDto): LicensePoolList {
    return {
        poolName: pools.pool_name,
        poolUuid: pools.pool_uuid,
        defaultPool: pools.default_pool,
    };
}

function toLicensePoolListResponse(dto: LicensePoolListResponseDto) {
    return {
        pools: dto.pools.map(toLicensePoolList),
    };
}

export interface LicenseInPool {
    index: number;
    licenseType: string;
    remaining: number;
    productName: string;
    available: number;
    assigned: number;
    expirationDate: string;
    licensesToAdd: number;
    amount: number;
    totalAvailable: number;
}

export function toLicenseInPool(license: License): LicenseInPool {
    return {
        index: license.index,
        licenseType: license.licenseType,
        remaining: license.assigned ?? 0,
        productName: license.productName,
        available: license.available,
        assigned: license.assigned ?? 0,
        expirationDate: license.expirationDate,
        licensesToAdd: license.totalOfLicenses,
        amount: license.assigned ?? 0,
        totalAvailable: license.available,
    };
}

export function toLicense(license: LicenseInPool): License {
    return {
        index: license.index,
        licenseType: license.licenseType,
        available: license.totalAvailable,
        licensesToAdd: license.licensesToAdd,
        totalOfLicenses: license.totalAvailable,
        expirationDate: license.expirationDate,
        productName: license.productName,
        parentAvailableAmount: 0,
        parentLicenseExpirationDate: null,
        assigned: license.assigned ?? 0,
    };
}

class LicensePoolService {
    public fetchLicensePools(
        abortController?: AbortController,
        name?: string,
        search?: string,
        cursor?: string
    ): Promise<LicensePoolsResponse> {
        return apiGatewayService
            .invokeApi(
                PATH_LICENSE_POOLS_PREFIX +
                    new ParameterQuery()
                        .add("name", name)
                        .add("search", search)
                        .add("cursor", cursor)
                        .createQueryString(),
                "GET",
                null,
                {
                    abortController: abortController,
                    apiType: ApiType.LAUREL,
                }
            )
            .then((dto: LicensePoolsResponseDto) => toLicensePoolsResponse(dto));
    }

    public async createLicensePool(createPool: CreatePoolRequest, abortController: AbortController): Promise<void> {
        const postData: CreateLicensePoolDto = {
            pool_name: createPool.poolName,
            notes: createPool.notes,
            licenses: createPool.licenses,
        };
        return apiGatewayService.invokeApi(PATH_LICENSE_POOLS_PREFIX, "POST", postData, {
            abortController: abortController,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }

    public async editLicensePool(editPool: EditPoolRequest, abortController: AbortController): Promise<void> {
        const postData: EditLicensePoolDto = {
            pool_uuid: editPool.poolUuid,
            pool_name: editPool.poolName,
            notes: editPool.notes,
            licenses: editPool.licenses,
        };
        return apiGatewayService.invokeApi(PATH_LICENSE_POOLS_PREFIX + "/" + editPool.poolUuid, "POST", postData, {
            abortController: abortController,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }

    public deleteLicensePool(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(PATH_LICENSE_POOLS_PREFIX + "/" + uuid, "DELETE", null, {
            abortController: abortController,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }

    public fetchLicensePoolsList(
        abortController?: AbortController,
        tenant_uuid?: string
    ): Promise<LicensePoolListResponse> {
        const query = new ParameterQuery().add("all", "true").add("tenant_uuid", tenant_uuid).createQueryString();
        return apiGatewayService
            .invokeApi(`${PATH_LICENSE_POOLS_PREFIX}${query}`, "GET", null, {
                abortController: abortController,
                apiType: ApiType.LAUREL,
            })
            .then((dto: LicensePoolListResponseDto) => toLicensePoolListResponse(dto));
    }

    public fetchPoolSpecificLicenses(
        abortController?: AbortController,
        tenant_uuid?: string,
        pool_uuid?: string
    ): Promise<LicensePoolLicensesResponse> {
        const query = new ParameterQuery().add("tenant_uuid", tenant_uuid).createQueryString();
        return apiGatewayService
            .invokeApi(`${PATH_LICENSE_POOLS_PREFIX + "/" + pool_uuid}${query}`, "GET", null, {
                abortController: abortController,
                apiType: ApiType.LAUREL,
            })
            .then((dto: LicensePoolLicensesResponseDto) => toPoolsLicensesResponse(dto));
    }

    public assignLicensePoolToUsers(
        pool_uuid: string | undefined,
        users: string[],
        abortController?: AbortController
    ): Promise<LicensePoolsResponse> {
        const url = PATH_LICENSE_POOLS_PREFIX + "/assign";
        return apiGatewayService.invokeApi(
            url,
            "POST",
            { users: users, pool_uuid: pool_uuid },
            {
                abortController: abortController,
                apiType: ApiType.LAUREL,
            }
        );
    }

    public async moveLicenses(
        poolUuid: string,
        data: MoveLicensesDataDto,
        abortController: AbortController
    ): Promise<void> {
        const postData: MoveLicensesData = {
            destination_pool_uuid: data.poolUuid,
            licenses: data.licenses,
        };
        return apiGatewayService.invokeApi(
            PATH_LICENSE_POOLS_PREFIX + "/" + poolUuid + "/?move=true",
            "POST",
            postData,
            {
                abortController: abortController,
                apiType: ApiType.LAUREL,
                emptyResponse: true,
            }
        );
    }
}
export const licensePoolService = new LicensePoolService();
