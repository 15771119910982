import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./introduction-view.scss";
import endpointStyle from "components/support/api-guide/endpoints/endpoints-view.scss";
import StaticTable from "components/support/api-guide/StaticTable";
import Heading from "components/typography/heading/Heading";
import { TextBlock } from "components/typography/textBlock/TextBlock";

import checkMark from "assets/images/icons/checkMark.svg";

const IntroductionView = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <>
            <Heading tag="div" variant="SUBTITLE_1" disableTopSpacing={true}>
                {t("Support.apiGuide.introduction.title")}
            </Heading>
            <div className={endpointStyle.textWidth}>
                <TextBlock>{t("Support.apiGuide.introduction.description")}</TextBlock>
                <TextBlock>{t("Support.apiGuide.introduction.examples")}</TextBlock>
            </div>
            <Heading tag="div" variant="SUBTITLE_1">
                {t("Support.apiGuide.authentication.title")}
            </Heading>
            <div className={endpointStyle.textWidth}>
                <TextBlock>{t("Support.apiGuide.authentication.info")}</TextBlock>
                <StaticTable
                    headers={[
                        {
                            className: style.firstColumnHeader,
                            value: t("Support.apiGuide.authentication.table.header"),
                        },
                        {
                            className: style.secondColumnHeader,
                            value: t("Support.apiGuide.authentication.table.required"),
                        },
                        { value: t("Support.apiGuide.authentication.table.description") },
                    ]}
                    cells={[
                        [
                            "X-BLANCCO-API-KEY",
                            <img
                                key="not-really-needed-but-compiler-demands"
                                src={checkMark}
                                alt={t("AltText.checkmark")}
                            />,
                            t("Support.apiGuide.authentication.apiKeyDescription"),
                        ],
                    ]}
                />
            </div>

            <Heading tag="div" variant="SUBTITLE_1">
                {t("Support.apiGuide.pagination.title")}
            </Heading>
            <div className={endpointStyle.textWidth}>
                <TextBlock>{t("Support.apiGuide.pagination.description")}</TextBlock>
                <Heading tag="div" variant="SUBTITLE_1">
                    {t("Support.apiGuide.errorResponse.title")}
                </Heading>
                <StaticTable
                    headers={[
                        { className: style.secondColumnHeader, value: t("Support.apiGuide.errorResponse.code") },
                        { value: t("Support.apiGuide.errorResponse.description") },
                    ]}
                    cells={[
                        ["400", t("Support.apiGuide.errorResponse.400")],
                        ["401", t("Support.apiGuide.errorResponse.401")],
                        ["403", t("Support.apiGuide.errorResponse.403")],
                        ["404", t("Support.apiGuide.errorResponse.404")],
                    ]}
                />
            </div>
        </>
    );
};

export default IntroductionView;
