import * as React from "react";

const Checked = (props: { backgroundColor: string; checkColor: string }): JSX.Element => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_799_16463)">
            <path
                d="M12.7353 0H4.26471C2.18552 0 0.5 1.68552 0.5 3.76471V12.2353C0.5 14.3145 2.18552 16 4.26471 16H12.7353C14.8145 16 16.5 14.3145 16.5 12.2353V3.76471C16.5 1.68552 14.8145 0 12.7353 0Z"
                fill={props.backgroundColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4802 4.42385C12.7984 4.68903 12.8414 5.16195 12.5762 5.48016L7.57619 11.4802C7.43294 11.6521 7.22043 11.751 6.99668 11.75C6.77292 11.749 6.5613 11.6482 6.4196 11.475L4.4196 9.031C4.15727 8.71044 4.20448 8.23792 4.52504 7.97559C4.8456 7.71327 5.31812 7.76048 5.58045 8.08104L7.00528 9.82218L11.4239 4.51988C11.689 4.20167 12.162 4.15868 12.4802 4.42385Z"
                fill={props.checkColor}
            />
        </g>
        <defs>
            <clipPath id="clip0_799_16463">
                <rect width="16" height="16" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
);

export default Checked;
