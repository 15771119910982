import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import style from "components/reports/erasure-reports-table.scss";
import { BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID, TemplateTableData } from "domain/reports";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    setTemplateUuid(uuid: string): void;
    templateUuid: string | null;
    templates: TemplateTableData[];
}

export default function TemplateSelection(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <div className={classNames(style.templates, { [style.flexDisplay]: props.templates.length <= 0 })}>
            <label htmlFor={"templates"} className={classNames(form.label, style.previewLabel)}>
                {t("ErasureReport.exportTemplate")}
            </label>
            <select
                id={"templates"}
                className={classNames(form.select, form.fixedWidthInput, style.selectArrow)}
                onChange={(event) => {
                    usageStatisticsService.sendEvent({
                        category: Category.REPORTS,
                        action: Action.PREVIEW_ERASURE_REPORT_WITH_TEMPLATE,
                    });
                    const template = props.templates.find((each) => each.uuid.toLowerCase() === event.target.value);
                    props.setTemplateUuid(template == null ? event.target.value : template.uuid);
                }}
                defaultValue={props.templateUuid || ""}
                data-testid={testIds.workArea.report.erasure.exportReportDialog.templateSelect}
            >
                <option key={BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID} value={BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID}>
                    {t("ErasureReport.default")}
                </option>
                {props.templates.map((each) => (
                    <option
                        key={each.uuid.toLowerCase()}
                        value={each.uuid.toLowerCase()}
                        selected={each.defaultReportTemplate.own}
                    >
                        {each.templateName} {each.defaultReportTemplate.own && t("ErasureReport.defaultTemplate")}
                    </option>
                ))}
            </select>
        </div>
    );
}
