import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import LocalSearch from "./LocalSearch";
import tableStyle from "./manage-user-groups.scss";
import { FetchedUserTableData } from "./ManageUserTable";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import style from "components/users/users.scss";
import { UserTableData } from "domain/users";
import form from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface TableState {
    usersData: UserTableData[];
    cursor: string;
    scrollPosition?: number;
}

interface Props {
    finalRightList: FetchedUserTableData[];
    loading: boolean;
    setSelectedUserListLoading: React.Dispatch<React.SetStateAction<boolean>>;
    removeUserFromSelectedUserList: (checked: boolean, uuid: string) => void;
    setSelectedUsersSearch: (value: string) => void;
    selectedUsersSearch: string;
    inputRef: React.RefObject<HTMLInputElement>;
    removeUsersUuids: string[];
    disabled: boolean;
}

const SelectedUsersList = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [tableState] = React.useState<TableState>({
        usersData: [],
        cursor: "",
        scrollPosition: 0,
    });
    const [search] = React.useState("");
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const [requestFailureMessage] = React.useState<string>("");
    const [finalRightList, setFinalRightList] = React.useState<FetchedUserTableData[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setData();
            if (props.removeUsersUuids.length == 0) {
                const element = document.getElementById("right_all") as HTMLInputElement;
                if (element) {
                    element.checked = false;
                }
            }
            props.setSelectedUserListLoading(false);
            setLoading(false);
        }, 200);
        if (props.finalRightList.length == 0) {
            const element = document.getElementById("right_all") as HTMLInputElement;
            if (element) {
                element.disabled = true;
            }
        }
        setFinalRightList(props.finalRightList);
    }, [props.finalRightList]);

    React.useEffect(() => {
        const checkAll = document.getElementById("right_all") as HTMLInputElement;
        if (checkAll) {
            checkAll.disabled = props.disabled;
        }
        props.finalRightList.map((each) => {
            const element = document.getElementById("right_" + each.uuid) as HTMLInputElement;
            if (element) {
                element.disabled = props.disabled;
            }
        });
    }, [props.disabled]);

    React.useEffect(() => {
        const checkAll = document.getElementById("right_all") as HTMLInputElement;
        if (props.removeUsersUuids.length == 0) {
            if (checkAll) {
                checkAll.checked = false;
            }
        }
        if (props.finalRightList.length == props.removeUsersUuids.length) {
            if (checkAll) {
                checkAll.checked = true;
            }
        }
    }, [props.removeUsersUuids]);

    const setData = () => {
        const filteredUsers: FetchedUserTableData[] = [];
        props.finalRightList.map((each) => {
            if (each.name.toLowerCase().startsWith(props.selectedUsersSearch.toLowerCase())) {
                filteredUsers.push(each);
            }
        });
        setFinalRightList(filteredUsers);
    };

    React.useEffect(() => {
        finalRightList.map((user) => {
            const element = document.getElementById("right_" + user.uuid) as HTMLInputElement;
            if (element) {
                element.checked = props.removeUsersUuids.includes(user.uuid);
            }
        });
    }, [finalRightList]);

    React.useEffect(() => {
        setData();
    }, [props.selectedUsersSearch]);

    React.useEffect(() => {
        if (props.finalRightList.length) {
            const checkAll = document.getElementById("right_all") as HTMLInputElement;
            if (checkAll && props.finalRightList.length == props.removeUsersUuids.length) {
                checkAll.checked = true;
            }
        }
    }, [props.selectedUsersSearch]);

    const onChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        if (value === "all") {
            const users: string[] = [];
            props.finalRightList
                .filter((each) => each.name.includes(search))
                .forEach((each) => {
                    const element = document.getElementById("right_" + each.uuid) as HTMLInputElement;
                    if (element && !props.removeUsersUuids.includes(each.uuid)) {
                        element.checked = checked;
                        users.push(each.uuid);
                    }
                });
        } else {
            if (!checked) {
                const checkAll = document.getElementById("right_all") as HTMLInputElement;
                if (checkAll) {
                    checkAll.checked = false;
                }
            }
        }
    };

    const searchInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeEventHandler(event);
        const { value, checked } = event.target;
        props.removeUserFromSelectedUserList(checked, value);
    };

    const createCheckbox = (checkboxValue: string, dataTestId: string) => {
        return (
            <>
                <div className={style.userNameCell}>
                    <label className={form.container}>
                        <input
                            key={"right_" + checkboxValue}
                            name={"selectedUsersList"}
                            id={"right_" + checkboxValue}
                            type="checkbox"
                            data-testid={dataTestId}
                            className={form.input}
                            value={checkboxValue}
                            onChange={searchInputChangeHandler}
                            disabled={props.disabled}
                        />
                        <span className={form.checkmark} />
                    </label>
                </div>
            </>
        );
    };

    const commonColumns: Array<Column<FetchedUserTableData>> = [
        {
            Header: () => <TextWithTooltip text={t("Common.name")} key="name" />,
            accessor: "name",
            Cell: ({ row }) => (
                <>
                    <label className={style.userNameCell} htmlFor={"right_" + row.original.uuid}>
                        <TextWithTooltip text={row.original.name} />
                    </label>
                </>
            ),
            width: deriveColumnWidth(25, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.userStatus.title")} key="status" />,
            accessor: "enabled",
            Cell: ({ cell: { value } }) => (
                <StatusBadge
                    values={[
                        value
                            ? {
                                  status: Status.SUCCESS,
                                  title: t("UsersTable.userStatus.confirmed"),
                              }
                            : {
                                  status: Status.ERROR,
                                  title: t("UsersTable.userStatus.forceChangePassword"),
                              },
                    ]}
                    tooltip={true}
                />
            ),
            width: deriveColumnWidth(20, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.expirationDate")} key="expirationDate" />,
            accessor: "expirationDate",
            Cell: ({ cell: { value } }) =>
                value ? <DateCell tooltip={true} value={value} withoutTime={true} /> : t("Common.never"),
            width: deriveColumnWidth(25, tableContainerRef),
        },
    ];
    const rightTableColumns: Array<Column<FetchedUserTableData>> = [
        {
            Header: () => (
                <>{createCheckbox("all", testIds.workArea.userGroup.manageUserGroupDialog.selectedTable.itself)}</>
            ),
            accessor: "uuid",
            Cell: (row) => (
                <>{createCheckbox(row.row.cells[0].value, testIds.workArea.user.addUserDialog.statusCheckbox.itself)}</>
            ),
            width: deriveColumnWidth(props.finalRightList.length != 0 ? 5 : 80, tableContainerRef),
        },
    ];

    if (props.finalRightList.length != 0) {
        rightTableColumns.push(...commonColumns);
    }

    const data = loading ? (
        <LoadingIndicator />
    ) : (
        <Table
            key={"right"}
            tableIdentity={RepositoryKey.SELECTED_GROUP_USERS_TABLE}
            data={finalRightList}
            columns={rightTableColumns}
            loaded={!props.loading}
            failureMessage={requestFailureMessage}
            inDialogBox={true}
            tooltips={true}
            scrollTo={tableState.scrollPosition}
            emptyMessage={
                <div className={tableStyle.emptyTableMessage}>
                    {t("UserGroups.manageUserGroupsView.emptyTableMessage")}
                </div>
            }
            testId={testIds.workArea.user.table}
            noAction={true}
        />
    );

    return (
        <div className={tableStyle.extendedSearch}>
            <div className={tableStyle.tableTop}>
                <div className={form.search}>
                    <LocalSearch
                        setSearch={props.setSelectedUsersSearch}
                        search={props.selectedUsersSearch}
                        searchInProgress={false}
                        keypressEvent={true}
                        setSearchOnKeyPress={props.setSelectedUsersSearch}
                        inputKey={"right"}
                        inputRef={props.inputRef}
                    />
                </div>
            </div>
            <div className={classNames(tableStyle.selectButton, tableStyle.selectedUsersCount)}>
                {finalRightList.length != 0
                    ? props.removeUsersUuids.length != 0
                        ? t("UserGroups.manageUserGroupsView.selectedUsers.count", {
                              usersCount: props.removeUsersUuids.length,
                          })
                        : t("UserGroups.manageUserGroupsView.selectedUsers.notSelected")
                    : ""}
            </div>
            <div className={classNames(layoutStyle.tableWrapper, tableStyle.border)} ref={tableContainerRef}>
                {data}
            </div>
        </div>
    );
};

export default SelectedUsersList;
