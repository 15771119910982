import classNames from "classnames";
import * as React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";

import ManageRoleForm, { FormValues } from "./manage-role/ManageRoleForm";
import style from "./user-roles.scss";
import Clone from "components/icons/Clone";
import Delete from "components/icons/Delete";
import Edit from "components/icons/Edit";
import SetDefault from "components/icons/SetDefault";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import TextWithTooltip from "components/table/TextWithTooltip";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { AUTH_ROLE_CREATE, AUTH_ROLE_DEFAULT, AUTH_ROLE_DELETE, AUTH_ROLE_EDIT } from "domain/authority";
import { Authority, Targets } from "domain/roles";
import { rolesService } from "services/roles/RolesService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

const mapState = () => ({});

interface Result {
    title: string;
    message: string;
}

const connector = connect(mapState);

const UserRolesKebabMenu = (
    props: ConnectedProps<typeof connector> & {
        roleUuid: string;
        name: string;
        description?: string;
        isManager: boolean;
        authorities: Authority[];
        targets?: Targets;
        onRoleEdit: () => void;
        isDefault: boolean;
        onRoleDelete: () => void;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [editRoleFormVisible, setEditRoleFormVisible] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const [resultVisible, setResultVisible] = React.useState<boolean>(false);
    const [cloneRoleFormVisible, setCloneRoleFormVisible] = React.useState<boolean>(false);
    const [deleteRoleFormVisible, setDeleteRoleFormVisible] = React.useState<boolean>(false);
    const [okClicked, setOkClicked] = React.useState<boolean>(false);
    const [confirmationState, setConfirmationState] = React.useState<boolean>(true);

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    }, []);

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };
    const hideResult = () => {
        setResultVisible(false);
        props.onRoleEdit();
    };

    const showCloneRoleForm = () => {
        usageStatisticsService.sendEvent({
            category: Category.ROLE,
            action: Action.CLONE_ROLE,
        });
        setCloneRoleFormVisible(true);
    };

    function handleConfirmation() {
        setConfirmationState(
            (document.getElementById("confirmInput") as HTMLInputElement).value !== t("Common.delete").toUpperCase()
        );
    }

    const hideResultAndRedirectToRoot = () => {
        setResultVisible(false);
        props.onRoleDelete();
    };

    const handleRoleDelete = () => {
        setOkClicked(true);
        deleteRoleSubmitEventHandler();
    };

    const editRoleSubmitEventHandler = async ({
        name,
        description,
        authorities,
        targets,
    }: FormValues): Promise<void> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            await rolesService.editRole(props.roleUuid, name, description, authorities, targets, abortController);
        } catch (e) {
            if (!signal.aborted) {
                setEditRoleFormVisible(false);
                showResult({
                    title: t("ManageRoleForm.edit.failure.title"),
                    message: t("ManageRoleForm.edit.failure.message"),
                });
                logger.error("Failed to edit a role:", e);
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setEditRoleFormVisible(false);
        showResult({
            title: t("ManageRoleForm.edit.success.title"),
            message: t("ManageRoleForm.edit.success.message"),
        });
    };

    const setDefaultRole = (uuid: string) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        rolesService.setDefault(uuid, abortController).then(() => {
            props.onRoleEdit();
        });
    };

    const editRoleModal = (
        <Modal
            isOpen={editRoleFormVisible}
            hideModal={() => setEditRoleFormVisible(false)}
            modalTitle={t("ManageRoleForm.edit.editRoleTitle")}
        >
            <div className={style.fixedWidthModal}>
                <TextBlock>{t("ManageRoleForm.edit.editRoleSummaryText")}</TextBlock>
                <ManageRoleForm
                    name={props.name}
                    description={props.description}
                    authorities={props.authorities}
                    targets={props.targets}
                    isManager={props.isManager}
                    isEditing={true}
                    submitEventHandler={editRoleSubmitEventHandler}
                />
            </div>
        </Modal>
    );

    const cloneRoleSubmitEventHandler = async ({
        name,
        description,
        authorities,
        targets,
    }: FormValues): Promise<void> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            await rolesService.generateRoles(name, description, authorities, targets, abortController);
        } catch (e) {
            if (!signal.aborted) {
                setCloneRoleFormVisible(false);
                showResult({
                    title: t("ManageRoleForm.failure.title"),
                    message: t("ManageRoleForm.failure.message"),
                });
                logger.error("Failed to clone a role:", e);
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setCloneRoleFormVisible(false);
        showResult({
            title: t("ManageRoleForm.success.title"),
            message: t("ManageRoleForm.success.message", { name: name }),
        });
    };

    const cloneRoleModal = (
        <Modal
            isOpen={cloneRoleFormVisible}
            hideModal={() => setCloneRoleFormVisible(false)}
            modalTitle={t("ManageRoleForm.createRoleTitle")}
        >
            <div className={style.fixedWidthModal}>
                <TextBlock>{t("ManageRoleForm.addUserRoleSummaryText")}</TextBlock>
                <ManageRoleForm
                    name={props.name + " Clone"}
                    authorities={props.authorities}
                    targets={props.targets}
                    isManager={false}
                    isEditing={false}
                    submitEventHandler={cloneRoleSubmitEventHandler}
                />
            </div>
        </Modal>
    );

    const deleteRoleSubmitEventHandler = async () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            await rolesService.deleteRole(props.roleUuid, abortController);
        } catch (e) {
            if (!signal.aborted) {
                setOkClicked(false);
                setDeleteRoleFormVisible(false);
                showResult({
                    title: t("ManageRoleForm.deleteRole.roleDeleted.roleNotDeletedTitle"),
                    message: t("ManageRoleForm.deleteRole.roleDeleted.failureMessage"),
                });
                logger.error("Failed to delete a role", e);
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setOkClicked(false);
        setDeleteRoleFormVisible(false);
        showResult({
            title: t("ManageRoleForm.deleteRole.roleDeleted.roleDeletedTitle"),
            message: t("ManageRoleForm.deleteRole.roleDeleted.successMessage", { name: props.name }),
        });
    };

    return (
        <>
            <div className={style.deleteIcon}>
                <Menu className={style.kebabMenu} data-testid={testIds.common.primaryView.table.kebabMenu.itself}>
                    <ul>
                        {userSessionService.userHasAllAuthorities([AUTH_ROLE_EDIT]) && (
                            <li>
                                <TextWithTooltip text={t("Common.edit")}>
                                    <MenuItem
                                        tag="button"
                                        onKeyDown={undefined}
                                        onClick={() => {
                                            setEditRoleFormVisible(true);
                                            usageStatisticsService.sendEvent({
                                                category: Category.ROLE,
                                                action: Action.EDIT_ROLE,
                                            });
                                            return;
                                        }}
                                        data-testid={testIds.workArea.roles.table.kebabMenu.editButton}
                                    >
                                        <Edit color={theme.iconFillColor} />
                                    </MenuItem>
                                </TextWithTooltip>
                            </li>
                        )}
                        {userSessionService.userHasAllAuthorities([AUTH_ROLE_DEFAULT]) && (
                            <li>
                                <TextWithTooltip
                                    text={props.isDefault ? t("UserRoles.table.itsDefault") : t("Common.setDefault")}
                                >
                                    <MenuItem
                                        tag="button"
                                        onKeyDown={undefined}
                                        onClick={(e) => {
                                            if (!props.isDefault) {
                                                usageStatisticsService.sendEvent({
                                                    category: Category.ROLE,
                                                    action: Action.SET_DEFAULT_ROLE,
                                                });
                                                setDefaultRole(props.roleUuid);
                                            }
                                            e.preventDefault();
                                        }}
                                        data-testid={testIds.workArea.roles.table.kebabMenu.setDefaultButton}
                                    >
                                        <SetDefault
                                            color={
                                                props.isDefault
                                                    ? theme.disabledButtonBackgroundColor
                                                    : theme.iconFillColor
                                            }
                                        />
                                    </MenuItem>
                                </TextWithTooltip>
                            </li>
                        )}
                        {userSessionService.userHasAllAuthorities([AUTH_ROLE_CREATE]) && (
                            <li>
                                <TextWithTooltip text={t("Common.clone")}>
                                    <MenuItem
                                        tag="button"
                                        onKeyDown={undefined}
                                        onClick={showCloneRoleForm}
                                        data-testid={testIds.workArea.roles.table.kebabMenu.cloneButton}
                                    >
                                        <Clone color={theme.iconFillColor} />
                                    </MenuItem>
                                </TextWithTooltip>
                            </li>
                        )}
                        {userSessionService.userHasAllAuthorities([AUTH_ROLE_DELETE]) && (
                            <li>
                                <TextWithTooltip
                                    text={
                                        props.isDefault
                                            ? t("UserRoles.table.canNotDeleteDefaultRole")
                                            : props.isManager
                                            ? t("UserRoles.table.canNotDeleteManagerRole")
                                            : t("Common.delete")
                                    }
                                >
                                    <MenuItem
                                        tag="button"
                                        onKeyDown={undefined}
                                        onClick={() => {
                                            if (!props.isManager && !props.isDefault) {
                                                setDeleteRoleFormVisible(true);
                                                usageStatisticsService.sendEvent({
                                                    category: Category.ROLE,
                                                    action: Action.DELETE_ROLE,
                                                });
                                            }
                                        }}
                                        data-testid={testIds.workArea.roles.table.kebabMenu.deleteButton}
                                    >
                                        <Delete
                                            color={
                                                props.isDefault || props.isManager
                                                    ? theme.disabledButtonBackgroundColor
                                                    : theme.iconFillColor
                                            }
                                        />
                                    </MenuItem>
                                </TextWithTooltip>
                            </li>
                        )}
                    </ul>
                </Menu>
                {editRoleModal}
                <Modal isOpen={resultVisible} hideModal={hideResult} modalTitle={result.title}>
                    <div className={style.resultContainer}>{result.message}</div>
                    <div className={style.okButtonContainer}>
                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                            onClick={hideResult}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </Modal>
                {cloneRoleModal}
                <Modal isOpen={resultVisible} hideModal={hideResult} modalTitle={result.title}>
                    <div className={style.resultContainer}>{result.message}</div>
                    <div className={style.okButtonContainer}>
                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                            onClick={hideResult}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </Modal>
                <Modal
                    isOpen={deleteRoleFormVisible}
                    hideModal={() => setDeleteRoleFormVisible(false)}
                    modalTitle={t("ManageRoleForm.deleteRole.title")}
                >
                    {okClicked ? (
                        <LoadingIndicator />
                    ) : (
                        <>
                            {deleteRoleFormVisible ? (
                                <>
                                    <div className={style.resultContainer}>
                                        {t("ManageRoleForm.deleteRole.introductionMessage", { name: props.name })}
                                    </div>
                                    <div className={style.resultContainer}>
                                        {t("ManageRoleForm.deleteRole.confirmationMessage", { name: props.name })}
                                    </div>
                                    <div className={style.resultContainer}>
                                        <input
                                            id="confirmInput"
                                            type="text"
                                            data-testid={testIds.common.confirmationDialog.confirmInput}
                                            placeholder={t("Common.delete").toUpperCase()}
                                            onChange={handleConfirmation}
                                        />
                                    </div>

                                    <div className={style.okButtonContainer}>
                                        <button
                                            className={classNames(
                                                buttons.secondaryButton,
                                                buttons.medium,
                                                style.okButton
                                            )}
                                            onClick={() => setDeleteRoleFormVisible(false)}
                                            data-testid={testIds.common.dialog.closeButton}
                                        >
                                            {t("Common.cancel")}
                                        </button>

                                        <button
                                            className={
                                                confirmationState
                                                    ? classNames(style.disabledButton, buttons.medium, style.okButton)
                                                    : classNames(
                                                          buttons.primaryButton,
                                                          style.deleteButton,
                                                          buttons.medium,
                                                          style.okButton
                                                      )
                                            }
                                            data-testid={testIds.common.confirmationDialog.confirmButton}
                                            onClick={handleRoleDelete}
                                            disabled={confirmationState}
                                        >
                                            {t("Common.delete")}
                                        </button>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </>
                    )}
                </Modal>
                <Modal isOpen={resultVisible} hideModal={hideResultAndRedirectToRoot} modalTitle={result.title}>
                    <div className={style.resultContainer}>{result.message}</div>
                    <div className={style.okButtonContainer}>
                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                            onClick={hideResultAndRedirectToRoot}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default connector(UserRolesKebabMenu);
