import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ViewUserDetails from "./user-details/ViewUserDetails";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { USERS_ROUTE } from "components/router/Routes";
import AddUserView from "components/users/add-user/AddUserView";
import UsersTable from "components/users/UsersTable";
import { AUTH_USER_CREATE } from "domain/authority";
import { userSessionService } from "services/user/UserSessionService";

/**
 * Shared DTO for indicating whether backend requests have finished between different views.
 */
interface UserLoading {
    users: boolean;
    roles: boolean;
}

export default function AllUsersView(): JSX.Element {
    const { t } = useTranslation();

    const [refreshCount, setRefreshCount] = useState<number>(0);
    const [userLoading, setUserLoading] = useState<UserLoading>({ users: false, roles: false });
    const [showDetailsView, setShowDetailsView] = React.useState<boolean>(false);
    const [selectedUser, setSelectedUser] = React.useState<string>("");

    const increaseRefreshCount = () => {
        setRefreshCount((prev) => ++prev);
    };

    const viewActions = userSessionService.userHasAllAuthorities([AUTH_USER_CREATE]) ? (
        <AddUserView
            onUserAdded={increaseRefreshCount}
            allLoaded={userLoading.users && userLoading.roles}
            setRolesFetched={(roles: boolean) =>
                setUserLoading((previous) => ({
                    users: previous.users,
                    roles,
                }))
            }
        />
    ) : undefined;

    return (
        <ApplicationLayout
            viewTitle={!showDetailsView ? t("Common.userTitle") : ""}
            view={
                !showDetailsView ? (
                    <UsersTable
                        count={refreshCount}
                        onUserDelete={increaseRefreshCount}
                        onUserEdit={increaseRefreshCount}
                        allLoaded={userLoading.users}
                        setUsersFetched={(users: boolean) =>
                            setUserLoading((previous) => ({
                                users,
                                roles: previous.roles,
                            }))
                        }
                        selectedUser={setSelectedUser}
                        showDetailsView={setShowDetailsView}
                    />
                ) : (
                    <ViewUserDetails selectedUser={selectedUser} showDetailsView={setShowDetailsView} />
                )
            }
            mainRoute={USERS_ROUTE}
            viewActions={!showDetailsView ? viewActions : undefined}
        />
    );
}
