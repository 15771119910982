import * as React from "react";

// Clone icon in circle of chosen fill color and white lines
const Clone = (props: { color: string }): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35.818" height="47.818" viewBox="0 0 47.318 47.318">
            <defs>
                <filter id="Path_1173" x="0" y="0" width="47" height="47" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="clone_icon" transform="translate(4.5 2.5)">
                <g id="Group_8231" data-name="Group 8231" transform="translate(-633 -570)">
                    <g id="Group_2701" data-name="Group 2701" transform="translate(633 570)">
                        <g transform="matrix(1, 0, 0, 1, -4.5, -2.5)" filter="url(#Path_1173)">
                            <path
                                id="Path_1173-2"
                                data-name="Path 1173"
                                d="M171.86,212.13a19,19,0,1,1-19,19,19,19,0,0,1,19-19Z"
                                transform="translate(-148.36 -209.63)"
                                fill={props.color}
                            />
                        </g>
                    </g>
                </g>
                <g id="copy" transform="translate(8 7.659)">
                    <rect
                        id="Rectangle_1753"
                        data-name="Rectangle 1753"
                        width="12"
                        height="12"
                        rx="2"
                        transform="translate(8 9)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_2254"
                        data-name="Path 2254"
                        d="M4.76,13.96H3.84A1.84,1.84,0,0,1,2,12.12V3.84A1.84,1.84,0,0,1,3.84,2h8.28a1.84,1.84,0,0,1,1.84,1.84V5.76"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
                <g id="arrow-up" transform="translate(26.032 21.845) rotate(135)">
                    <line
                        id="Line_816"
                        data-name="Line 816"
                        y1="14"
                        transform="translate(3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_4235"
                        data-name="Path 4235"
                        d="M10.954,14.977,7.977,12,5,14.977"
                        transform="translate(-5 -12)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Clone;
