import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./device-detail-view.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { DeviceDetail, DiagnosticReportResponse } from "domain/reports";

import testIds from "testIds.json";

export interface DeviceDetailsProps {
    diagnosticReportResponse: DiagnosticReportResponse;
}

function createMakeAndModel(deviceDetail: DeviceDetail) {
    const make = deviceDetail.make ?? "";
    if (deviceDetail.model == null) {
        return make;
    }
    return `${make} ${deviceDetail.modelName} (${deviceDetail.model})`;
}
function createDeviceDetails(label: string, deviceDetailTestId: string, deviceDetail: string | undefined) {
    if (deviceDetail == null || deviceDetail === "") {
        return null;
    }
    return (
        <>
            <div className={style.label}>{label}</div>
            <div data-testid={deviceDetailTestId}>{deviceDetail}</div>
        </>
    );
}

export default function DeviceDetailView(props: DeviceDetailsProps): JSX.Element {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [diagnosticReportData, setDiagnosticReportData] = React.useState<DiagnosticReportResponse>();

    React.useEffect(() => {
        setDiagnosticReportData(props.diagnosticReportResponse);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    });

    if (typeof diagnosticReportData === "undefined") {
        return <LoadingIndicator />;
    }

    return (
        <>
            <div className={style.userProfile}>
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.id"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.idLabel,
                    diagnosticReportData.deviceDetail.id
                )}
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.make"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.makeLabel,
                    createMakeAndModel(diagnosticReportData.deviceDetail)
                )}
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.color"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.colorLabel,
                    diagnosticReportData.deviceDetail.color
                )}
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.imei"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.imeiLabel,
                    diagnosticReportData.deviceDetail.imei
                )}
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.imei2"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.imeiLabel,
                    diagnosticReportData.deviceDetail.imei2
                )}
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.serialNumber"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.serialNumberLabel,
                    diagnosticReportData.deviceDetail.serialNumber
                )}
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.system"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.systemLabel,
                    diagnosticReportData.deviceDetail.operatingSystem
                )}
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.carrier"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.carrierLabel,
                    diagnosticReportData.deviceDetail.carrier
                )}
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.capacity"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.capacityLabel,
                    diagnosticReportData.deviceDetail.capacity
                )}
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.location"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.locationLabel,
                    diagnosticReportData.deviceDetail.location
                )}
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.timezone"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.timezoneLabel,
                    diagnosticReportData.deviceDetail.timezone
                )}
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.version"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.versionLabel,
                    diagnosticReportData.deviceDetail.softwareVersion
                )}
                {createDeviceDetails(
                    t("DiagnosticReportsTable.deviceDetail.tagCode"),
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.tagCodeLabel,
                    diagnosticReportData.deviceDetail.tagCode
                )}
            </div>
        </>
    );
}
