import classNames from "classnames";
import { useFeature } from "flagged";
import * as React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import NavigationTogglePanel from "./NavigationTogglePanel";
import style from "./side-navigation.scss";
import CollapsibleSideMenuIcon from "components/icons/CollapsibleSideMenuIcon";
import PremiumFeatureIcon from "components/icons/PremiumFeatureIcon";
import { OUR_IMPACT_DASHBOARD_ROUTE, REPORTS_OUR_VIEWS_ROUTE, RouteDefinition } from "components/router/Routes";
import Tooltip from "components/tooltip/Tooltip";
import { Breakpoint } from "domain/breakpoints";
import { StoreState } from "store";
import { getObject, RepositoryKey, setObject } from "utils/repository";

import iconPrivateView from "assets/images/icons/icon_private_view.svg";

interface Props {
    mainRoute: RouteDefinition;
    menuClass: string;
    currentItemClass: string;
    childItemClass?: string;
    routes: RouteDefinition[];
    appendChildRoutes: boolean;
}

const connector = connect((state: StoreState) => ({
    user: state.userReducer.user,
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
    theme: state.themeReducer.theme,
}));

export function deduceCreateViewButtonIndex(routes: RouteDefinition[]): number | null {
    let result = null;
    routes.forEach((route, index) => {
        if (route.path === REPORTS_OUR_VIEWS_ROUTE.path) {
            result = index;
        }
    });
    return result;
}

const SideNavigation = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const location = useLocation();

    // the size of the window in the begginning
    const [windowSize, setWindowSize] = React.useState({
        width: window.innerWidth,
    });

    const [showSideNavigation, setShowSideNavigation] = React.useState(
        getObject(RepositoryKey.SIDE_NAVIGATION) === true
    );
    const [collapsibleSideMenuIconColor, setCollapsibleSideMenuIconColor] = React.useState<string>(
        props.theme.primaryButtonBackgroundColor
    );

    let activeMenu: string;
    let openMenu = false;
    let inactiveMenu: string;

    const getTooltip = (route: RouteDefinition, disabled: boolean): JSX.Element => {
        if (route.path === OUR_IMPACT_DASHBOARD_ROUTE.path) {
            return !disabled ? (
                <div className={style.tooltip}>{t("Routes.ourImpact.tooltip.paid")}</div>
            ) : (
                <div className={style.tooltip}>
                    <div className={style.title}>{t("Routes.ourImpact.tooltip.unpaid.title")}</div>
                    <div className={style.content}>{t("Routes.ourImpact.tooltip.unpaid.calculate")}</div>
                    <div>{t("Routes.ourImpact.tooltip.unpaid.contactBlancco")}</div>
                </div>
            );
        }
        return <></>;
    };

    const menuItems: (JSX.Element | null)[] = [];
    props.routes.map((route, index) => {
        let disabled = false;
        const flag = route.getFeatureFlag();
        if (flag != null && !useFeature(flag)) {
            return null;
        }
        if (!route.isAccessibleTo(props.tenantDetails)) {
            if (route.advertised) {
                disabled = true;
            } else {
                return null;
            }
        }
        if (!route.isValidUser(props.user)) {
            return null;
        }
        const strIndex = index.toString();

        if (route.childRoutes.some((item) => location.pathname === item.path)) {
            activeMenu = strIndex;
            openMenu = true;
        } else {
            openMenu = false;
            if (route.childRoutes.length > 0) {
                inactiveMenu = strIndex;
            }
        }

        menuItems.push(
            route.childRoutes.length > 0 || REPORTS_OUR_VIEWS_ROUTE.path === route.path ? (
                <div key={index}>
                    <NavigationTogglePanel
                        isOpen={openMenu}
                        id={strIndex}
                        title={t(route.title)}
                        openId={activeMenu}
                        closeId={inactiveMenu}
                    >
                        <Menu className={style.menu}>
                            <MenuItem className={style.item} onKeyDown={undefined}>
                                {route.childRoutes
                                    .filter((childRoute) => {
                                        const flag = route.getFeatureFlag();
                                        return childRoute.isValidUser(props.user) && (flag == null || useFeature(flag));
                                    })
                                    .filter((childRoute) => childRoute.isAccessibleTo(props.tenantDetails))
                                    .map((childRoute) => {
                                        return (
                                            <li key={childRoute.path}>
                                                {childRoute.isShared === false ? (
                                                    <NavLink
                                                        activeClassName={style.activeChildLink}
                                                        className={props.childItemClass}
                                                        exact
                                                        to={childRoute.path}
                                                        data-testid={childRoute.testId}
                                                    >
                                                        {t(childRoute.title)}
                                                        <Tooltip
                                                            content={t("CreateReportView.privateView.title")}
                                                            placement={"top"}
                                                        >
                                                            <img
                                                                className={style.iconPrivateView}
                                                                src={iconPrivateView}
                                                                alt={t("AltText.privateView")}
                                                            />
                                                        </Tooltip>
                                                    </NavLink>
                                                ) : (
                                                    <NavLink
                                                        activeClassName={style.activeChildLink}
                                                        className={props.childItemClass}
                                                        exact
                                                        to={childRoute.path}
                                                        data-testid={childRoute.testId}
                                                    >
                                                        {t(childRoute.title)}
                                                    </NavLink>
                                                )}
                                            </li>
                                        );
                                    })}
                            </MenuItem>
                        </Menu>
                    </NavigationTogglePanel>
                </div>
            ) : (
                <li key={route.path}>
                    {disabled ? (
                        <div className={style.inActive} data-testid={route.testId}>
                            <div>{t(route.title)}</div>
                            <div className={style.icon}>
                                <Tooltip maxWidth={218} content={getTooltip(route, disabled)} placement={"top-start"}>
                                    <div>
                                        <PremiumFeatureIcon color={props.theme.iconFillColor} />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    ) : (
                        <NavLink activeClassName={props.currentItemClass} to={route.path} data-testid={route.testId}>
                            {t(route.title)}
                            {route.advertised && (
                                <div className={style.icon}>
                                    <Tooltip content={getTooltip(route, disabled)} placement={"top"}>
                                        <div className={style.purchased} />
                                    </Tooltip>
                                </div>
                            )}
                        </NavLink>
                    )}
                </li>
            )
        );
    });

    const toggleNavigation = () => {
        const toggle = !showSideNavigation;
        setObject(RepositoryKey.SIDE_NAVIGATION, toggle);
        setShowSideNavigation(toggle);
    };

    React.useEffect(() => {
        window.dispatchEvent(new Event("resize"));
    }, [showSideNavigation]);

    React.useEffect(() => {
        window.onresize = () => {
            setWindowSize({
                width: window.innerWidth,
            });
        };
        if (getObject(RepositoryKey.SIDE_NAVIGATION) != null) {
            if (getObject(RepositoryKey.SIDE_NAVIGATION) == true) {
                setShowSideNavigation(true);
            }
            if (getObject(RepositoryKey.SIDE_NAVIGATION) == false) {
                setShowSideNavigation(false);
            }
        } else {
            if (windowSize.width <= Breakpoint.XL) {
                setShowSideNavigation(false);
            } else {
                if (windowSize.width > Breakpoint.XL) {
                    setShowSideNavigation(true);
                }
            }
        }
    }, [windowSize.width]);

    return (
        <div
            className={classNames(
                style.sideNavigation,
                showSideNavigation ? style.navContainer : style.navHiddenContainer
            )}
        >
            {showSideNavigation && (
                <>
                    <nav className={props.menuClass}>
                        <ul>{menuItems}</ul>
                    </nav>
                </>
            )}

            <button
                className={classNames(style.toggleButton, style.tabIndex)}
                onClick={toggleNavigation}
                onMouseEnter={() => setCollapsibleSideMenuIconColor(props.theme.primaryButtonBackgroundHoverColor)}
                onMouseLeave={() => setCollapsibleSideMenuIconColor(props.theme.primaryButtonBackgroundColor)}
            >
                <CollapsibleSideMenuIcon
                    showSideNav={showSideNavigation}
                    color={
                        collapsibleSideMenuIconColor === props.theme.primaryButtonBackgroundColor ||
                        collapsibleSideMenuIconColor === props.theme.primaryButtonBackgroundHoverColor
                            ? collapsibleSideMenuIconColor
                            : props.theme.primaryButtonBackgroundColor
                    }
                />
            </button>
        </div>
    );
};

const connectedSideNavigation = connector(SideNavigation);
export { connectedSideNavigation as SideNavigation };
