import classNames from "classnames";
import * as React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ManageUserGroupsForm, { FormValues } from "./manage-user-group/ManageUserGroupsForm";
import style from "./user-groups.scss";
import DeleteConfirmationDialog from "components/confirmation/DeleteConfirmationDialog";
import Delete from "components/icons/Delete";
import Edit from "components/icons/Edit";
import Modal from "components/modal/Modal";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_USER_GROUP_DELETE, AUTH_USER_GROUP_EDIT } from "domain/authority";
import { UserGroup } from "domain/userGroups";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userGroupsService } from "services/user-groups/UserGroupsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
}

const UserGroupsKebabMenu = (props: {
    groupUuid: string;
    name: string;
    onGroupDelete: () => void;
    onGroupEdit: () => void;
    groupTuple: UserGroup;
}): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const [resultVisible, setResultVisible] = React.useState<boolean>(false);
    const [deleteGroupFormVisible, setDeleteGroupFormVisible] = React.useState<boolean>(false);
    const [okClicked, setOkClicked] = React.useState<boolean>(false);
    const [editGroupFormVisible, setEditGroupFormVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    }, []);

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const hideResultAndRedirectToRoot = () => {
        setResultVisible(false);
        props.onGroupDelete();
    };

    const handleGroupDelete = () => {
        deleteGroupSubmitEventHandler();
        setOkClicked(true);
    };

    const editUserGroupSubmitEventHandler = async ({
        groupName,
        backgroundColor,
        roleUuid,
        textColor,
        usersList,
    }: FormValues): Promise<void> => {
        const abortController = new AbortController();
        const editRequest = {
            uuid: props.groupTuple.uuid,
            name: groupName,
            backgroundColor: backgroundColor,
            textColor: textColor,
            abortController: abortController,
            roleUuid: roleUuid,
            usersList: usersList,
        };
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            await userGroupsService.editGroup(editRequest);
        } catch (e) {
            if (!signal.aborted) {
                setEditGroupFormVisible(false);
                showResult({
                    title: t("UserGroups.manageUserGroupsView.editUserGroup.editGroupFailure.title"),
                    message: t("UserGroups.manageUserGroupsView.editUserGroup.editGroupFailure.message"),
                });
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setEditGroupFormVisible(false);
        showResult({
            title: t("UserGroups.manageUserGroupsView.editUserGroup.resultTitle"),
            message: t("UserGroups.manageUserGroupsView.editUserGroup.resultMessage", { groupName: groupName }),
        });
    };
    const deleteGroupSubmitEventHandler = async () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            await userGroupsService.deleteUserGroup(props.groupUuid, abortController);
        } catch (e) {
            if (!signal.aborted) {
                setOkClicked(false);
                setDeleteGroupFormVisible(false);
                showResult({
                    title: t("UserGroups.manageUserGroupsView.deleteUserGroup.groupDeleted.groupNotDeletedTitle"),
                    message: t("UserGroups.manageUserGroupsView.deleteUserGroup.groupDeleted.failureMessage"),
                });
                logger.error("Failed to delete a user group", e);
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setOkClicked(false);
        setDeleteGroupFormVisible(false);
        showResult({
            title: t("UserGroups.manageUserGroupsView.deleteUserGroup.groupDeleted.groupDeletedTitle"),
            message: t("UserGroups.manageUserGroupsView.deleteUserGroup.groupDeleted.successMessage", {
                name: props.name,
            }),
        });
    };

    return (
        <>
            <div className={style.deleteIcon}>
                <Menu className={style.kebabMenu} data-testid={testIds.common.primaryView.table.kebabMenu.itself}>
                    <ul>
                        {userSessionService.userHasAllAuthorities([AUTH_USER_GROUP_DELETE]) && (
                            <li>
                                <TextWithTooltip text={t("Common.delete")}>
                                    <MenuItem
                                        tag="button"
                                        onKeyDown={undefined}
                                        onClick={() => {
                                            setDeleteGroupFormVisible(true);
                                            usageStatisticsService.sendEvent({
                                                category: Category.USER_GROUP,
                                                action: Action.REMOVE_USER_GROUP,
                                            });
                                        }}
                                        data-testid={testIds.workArea.userGroup.table.kebabMenu.deleteButton}
                                    >
                                        <Delete color={theme.iconFillColor} />
                                    </MenuItem>
                                </TextWithTooltip>
                            </li>
                        )}

                        {userSessionService.userHasAllAuthorities([AUTH_USER_GROUP_EDIT]) && (
                            <li>
                                <TextWithTooltip text={t("Common.edit")}>
                                    <MenuItem
                                        tag="button"
                                        onKeyDown={undefined}
                                        onClick={() => {
                                            setEditGroupFormVisible(true);
                                        }}
                                        data-testid={testIds.workArea.userGroup.table.kebabMenu.editButton}
                                    >
                                        <Edit color={theme.iconFillColor} />
                                    </MenuItem>
                                </TextWithTooltip>
                            </li>
                        )}
                    </ul>
                </Menu>
                <Modal
                    isOpen={editGroupFormVisible}
                    hideModal={() => setEditGroupFormVisible(false)}
                    modalTitle={t("UserGroups.manageUserGroupsView.editUserGroup.title")}
                >
                    <ManageUserGroupsForm
                        submitEventHandler={editUserGroupSubmitEventHandler}
                        edit={true}
                        groupTuple={props.groupTuple}
                        setModalFormVisible={setEditGroupFormVisible}
                    />
                </Modal>
                <DeleteConfirmationDialog
                    modalDisplayed={deleteGroupFormVisible}
                    introductionMessage={
                        <div className={style.resultContainer}>
                            {t("UserGroups.manageUserGroupsView.deleteUserGroup.introductionMessage", {
                                name: props.name,
                            })}
                        </div>
                    }
                    confirmationMessage={""}
                    handleCommand={handleGroupDelete}
                    setModalDisplayed={() => setDeleteGroupFormVisible(false)}
                    title={t("UserGroups.manageUserGroupsView.deleteUserGroup.title")}
                    loading={okClicked}
                />

                <Modal isOpen={resultVisible} hideModal={hideResultAndRedirectToRoot} modalTitle={result.title}>
                    <div className={style.resultContainer}>{result.message}</div>
                    <div className={style.okButtonContainer}>
                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                            onClick={hideResultAndRedirectToRoot}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default UserGroupsKebabMenu;
