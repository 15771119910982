import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import PlusIcon from "components/icons/PlusIcon";
import TextWithTooltip from "components/table/TextWithTooltip";
import ManageWorkflowWrapper from "components/workflows/manage-workflow-dialog/ManageWorkflowWrapper";
import { UserDetails } from "domain/user";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { workflowService } from "services/workflows/WorkflowService";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    user: UserDetails | null;
    onUpdate: () => void;
}

const CreateWorkflowView: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const [createWorkflowVisibility, setCreateWorkflowVisibility] = React.useState(false);

    const showCreateWorkflow = () => {
        usageStatisticsService.sendEvent({
            category: Category.WORKFLOW,
            action: Action.ADD_WORKFLOW,
        });
        setCreateWorkflowVisibility(true);
    };
    return (
        <>
            <TextWithTooltip
                text={t("WorkflowsTable.actions.create.tooltip", {
                    customerName: props.user?.tenantName,
                })}
            >
                <button
                    className={classNames(buttons.primaryButton, buttons.small)}
                    onClick={showCreateWorkflow}
                    data-testid={testIds.workArea.workflows.createWorkflowButton}
                >
                    <PlusIcon /> {t("WorkflowsTable.actions.create.button")}
                </button>
            </TextWithTooltip>
            <ManageWorkflowWrapper
                modalTitle={"ManageWorkflowDialog.title.create"}
                modalVisibility={createWorkflowVisibility}
                onUpdate={props.onUpdate}
                workflowService={workflowService}
                user={props.user}
                onShowModal={setCreateWorkflowVisibility}
                isCreate={true}
                entityType={"WORKFLOW"}
            />
        </>
    );
};

export default CreateWorkflowView;
