import { ChartData, ChartOptions } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { connect, ConnectedProps } from "react-redux";

import { StoreState } from "store";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

interface ChartProps {
    chartData: ChartData;
    horizontalStacking?: boolean;
    verticalStacking?: boolean;
}

type Props = ChartProps & ConnectedProps<typeof connector>;

const DoughnutChartWidget = (props: Props): JSX.Element => {
    const getChartOptions = (textColor: string): ChartOptions => {
        return {
            legend: {
                display: true,
                position: "right",
                align: "start",
                labels: {
                    usePointStyle: true,
                    fontColor: textColor,
                },
            },

            tooltips: {
                enabled: true,
            },
            elements: {
                arc: {
                    borderWidth: 0,
                },
            },
            responsive: true,
            plugins: {
                labels: {
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    render: () => {},
                },
            },
            maintainAspectRatio: false,
        };
    };

    return <Doughnut data={props.chartData} options={getChartOptions(props.theme.textColor)} />;
};
export default connector(DoughnutChartWidget);
