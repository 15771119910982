import { ReportSection, ReportTemplate, ReportType, TemplateTableData } from "domain/reports";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

export interface DefaultReportTemplate {
    own: boolean;
    other: OtherDefaultReportTemplate;
}

export interface OtherDefaultReportTemplate {
    count: number;
    usernames: string[];
}

interface ReportTemplateDto {
    template_name: string;
    report_type: ReportType;
    logo_visible: boolean;
    disclaimer: string;
    sections: ReportSection[];
    version?: number;
    default_report_template?: DefaultReportTemplate;
    disclaimer_color?: string;
}

export function toReportTemplateDto(template: ReportTemplate): ReportTemplateDto {
    return {
        template_name: template.templateName,
        report_type: template.reportType,
        logo_visible: template.logoVisible,
        disclaimer: template.disclaimer,
        sections: template.sections,
        version: template.version,
        disclaimer_color: template.disclaimerColor,
    };
}

export interface TemplateDto extends ReportTemplateDto {
    creation_date: string;
    edited_date: string;
    uuid: string;
    user_name: string;
    edited_by_username: string;
    version: number;
}

export interface TemplatesResponseDto {
    templates: TemplateDto[];
}

export function toTemplate(dto: TemplateDto): TemplateTableData {
    return {
        templateName: dto.template_name,
        reportType: dto.report_type,
        uuid: dto.uuid,
        createdDate: dto.creation_date,
        editedDate: dto.edited_date,
        logoVisible: dto.logo_visible,
        disclaimer: dto.disclaimer,
        sections: dto.sections,
        createdBy: dto.user_name,
        editedBy: dto.edited_by_username,
        version: dto.version,
        defaultReportTemplate: dto.default_report_template ?? { own: false, other: { count: 0, usernames: [] } },
        disclaimerColor: dto.disclaimer_color,
    };
}
const PATH_API_TEMPLATES = "/api/report-templates";

class ReportTemplateService {
    public createReportTemplate(reportTemplate: ReportTemplate, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(PATH_API_TEMPLATES, "POST", toReportTemplateDto(reportTemplate), {
            abortController: abortController,
            apiType: ApiType.OLIVER,
            refreshSession: true,
            emptyResponse: true,
        });
    }

    public updateReportTemplate(reportTemplate: ReportTemplate, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(
            PATH_API_TEMPLATES + "/" + reportTemplate.uuid,
            "POST",
            toReportTemplateDto(reportTemplate),
            {
                abortController: abortController,
                apiType: ApiType.OLIVER,
                refreshSession: true,
                emptyResponse: true,
            }
        );
    }

    public fetchTemplates(abortController: AbortController): Promise<TemplateTableData[]> {
        return apiGatewayService
            .invokeApi(PATH_API_TEMPLATES, "GET", null, {
                abortController: abortController,
                refreshSession: true,
                apiType: ApiType.OLIVER,
            })
            .then((dto: TemplatesResponseDto) => dto.templates.map(toTemplate));
    }

    public fetchTemplate(uuid: string, abortController: AbortController): Promise<TemplateTableData> {
        return apiGatewayService
            .invokeApi(`${PATH_API_TEMPLATES}/${uuid}`, "GET", null, {
                abortController: abortController,
                refreshSession: true,
                apiType: ApiType.OLIVER,
            })
            .then((dto: TemplateDto) => toTemplate(dto));
    }

    public deleteTemplate(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(`${PATH_API_TEMPLATES}/${uuid}`, "DELETE", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            emptyResponse: true,
        });
    }

    public setDefault(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(`${PATH_API_TEMPLATES}/default/${uuid}`, "POST", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            emptyResponse: true,
        });
    }
}

export const reportTemplateService = new ReportTemplateService();
