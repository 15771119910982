import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import CustomQuestionsForm from "./CustomQuestionsForm";
import ShowAllConditionAssessmentQuestionsForm from "./ShowAllConditionAssessmentQuestionsForm";
import Delete from "components/icons/Delete";
import DragDropCell from "components/icons/DragDropCell";
import Edit from "components/icons/Edit";
import style from "components/licenses/license-configuration/bms/bms-config.scss";
import {
    ConditionAssessmentQuestions,
    Question,
} from "components/licenses/license-configuration/bms/BmsCommonInterfaces";
import { selectLanguageForConditionalQuestions } from "components/licenses/license-configuration/bms/bmsUtils";
import questionsData from "components/licenses/license-configuration/bms/UiConfiguration.json";
import Modal from "components/modal/Modal";
import { ICON_SIZE_SMALL } from "domain/globalConstants";
import * as LanguageRepository from "services/language/languageRepository";
import { StoreState } from "store";

interface Props {
    currentQuestions: ConditionAssessmentQuestions;
    onQuestionsChange: (updatedQuestions: ConditionAssessmentQuestions) => void;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

type QuestionsData = {
    [key: string]: Question[];
};

const ConditionAssessmentQuestionsForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const languages = Object.keys(questionsData.questions);
    const selectedLanguage = selectLanguageForConditionalQuestions(LanguageRepository.getLanguage().locale);
    const [questions, setQuestions] = useState(props.currentQuestions || {});
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragging, setDragging] = useState<boolean>(false);
    const [showAllQuestionsVisible, setShowAllQuestionsVisible] = useState(false);
    const [customQuestionsVisible, setCustomQuestionsVisible] = useState(false);
    const [editQuestionModalVisible, setEditQuestionModalVisible] = useState(false);

    const questionIds = Array.from(
        new Set([
            ...(questions[selectedLanguage]?.map((question) => question.id) || []),
            ...languages.flatMap(
                (lang) => (questionsData.questions as QuestionsData)[lang]?.map((question) => question.id) || []
            ),
        ])
    );

    const NEW_QUESTION = {
        id: "Q" + (questionIds.length + 1),
        category: "",
        label: "",
        notes: "",
        isCustomQuestion: true,
        answers: [
            { id: "A1", label: "", value: "A1", positiveAnswer: false },
            { id: "A2", label: "", value: "A2", positiveAnswer: true },
        ],
    };
    const [editQuestion, setEditQuestion] = useState<Question>(NEW_QUESTION);

    const handleDragStart = (index: number) => {
        setDraggedIndex(index);
        setDragging(true);
    };

    const handleDragOver = (index: number, event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (draggedIndex !== null && draggedIndex !== index) {
            const updatedQuestions = { ...questions };

            languages.forEach((language) => {
                updatedQuestions[language] = [...questions[language]];
                const [draggedQuestion] = updatedQuestions[language].splice(draggedIndex, 1);
                updatedQuestions[language].splice(index, 0, draggedQuestion);
            });

            setQuestions(updatedQuestions);
            setDraggedIndex(index);
            props.onQuestionsChange(updatedQuestions);
        }
    };

    const handleDrop = () => {
        setDraggedIndex(null);
        setDragging(false);
    };

    const handleDeleteQuestion = (index: string) => {
        const updatedQuestions = { ...questions };

        languages.forEach((language) => {
            if (updatedQuestions[language]) {
                updatedQuestions[language] = updatedQuestions[language].filter((question) => question.id !== index);
            }
        });

        setQuestions(updatedQuestions);
        props.onQuestionsChange(updatedQuestions);
    };

    const hideResult = () => {
        setShowAllQuestionsVisible(false);
    };

    const showQuestions = () => {
        setShowAllQuestionsVisible(true);
    };

    const hideCustomQuestion = () => {
        setCustomQuestionsVisible(false);
    };

    const showCustomQuestion = () => {
        setCustomQuestionsVisible(true);
    };

    const handleQuestionsChange = (updatedQuestions: ConditionAssessmentQuestions) => {
        setQuestions(updatedQuestions);
        props.onQuestionsChange(updatedQuestions);
    };

    const newQuestionHandler = (newQuestion: Question) => {
        setQuestions((prevSelectedQuestions) => {
            const updatedQuestions = { ...prevSelectedQuestions };

            updatedQuestions[selectedLanguage] = [...(updatedQuestions[selectedLanguage] || []), newQuestion];

            languages.forEach((lang) => {
                if (lang !== selectedLanguage) {
                    const langQuestions = (questionsData.questions as Record<string, Question[]>)[lang]?.find(
                        (question) => question.id === newQuestion.id
                    );

                    if (langQuestions && !updatedQuestions[lang]?.some((q) => q.id === newQuestion.id)) {
                        updatedQuestions[lang] = [...(updatedQuestions[lang] || []), langQuestions];
                    } else if (!langQuestions && !updatedQuestions[lang]?.some((q) => q.id === newQuestion.id)) {
                        updatedQuestions[lang] = [...(updatedQuestions[lang] || []), newQuestion];
                    }
                }
            });

            props.onQuestionsChange(updatedQuestions);
            return updatedQuestions;
        });

        hideCustomQuestion();
    };

    const updatedQuestionHandler = (updatedQuestion: Question) => {
        setQuestions((prevQuestions) => {
            const updatedQuestions = { ...prevQuestions };

            const langQuestions = updatedQuestions[selectedLanguage];
            if (langQuestions) {
                const index = langQuestions.findIndex((q) => q.id === updatedQuestion.id);
                if (index !== -1) {
                    updatedQuestions[selectedLanguage][index] = updatedQuestion;
                }
            }

            props.onQuestionsChange(updatedQuestions);
            return updatedQuestions;
        });

        hideEditCustomQuestion();
    };

    const openEditQuestion = (id: string) => {
        const langQuestions = (questions as Record<string, Question[]>)[selectedLanguage]?.find(
            (question) => question.id === id
        );

        if (langQuestions) {
            setEditQuestion(langQuestions);
            setEditQuestionModalVisible(true);
        }
    };

    const hideEditCustomQuestion = () => {
        setEditQuestionModalVisible(false);
    };

    return (
        <>
            <div className={classNames(style.conditionalQuestionTable)}>
                <table>
                    <tbody>
                        {questions &&
                            questions[selectedLanguage] &&
                            questions[selectedLanguage].map((question: Question, index: number) => (
                                <tr
                                    key={question.id + "_lang" + selectedLanguage}
                                    draggable
                                    onDragStart={() => handleDragStart(index)}
                                    onDragOver={(e) => handleDragOver(index, e)}
                                    onDrop={handleDrop}
                                    className={dragging && draggedIndex === index ? style.draggedRow : ""}
                                >
                                    <td className={classNames(style.grab)}>
                                        <DragDropCell color={props.theme.iconFillColor} />
                                    </td>
                                    <td className={classNames(style.questionCategory)}>{question.category}</td>
                                    <td>{question.label}</td>
                                    <td>
                                        {question.isCustomQuestion && (
                                            <span
                                                onClick={() => openEditQuestion(question.id)}
                                                className={classNames(style.actionIcon)}
                                            >
                                                <Edit
                                                    color={props.theme.iconFillColor}
                                                    width={ICON_SIZE_SMALL}
                                                    height={ICON_SIZE_SMALL}
                                                />
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        <span
                                            onClick={() => handleDeleteQuestion(question.id)}
                                            className={classNames(style.actionIcon)}
                                        >
                                            <Delete
                                                color={props.theme.iconFillColor}
                                                width={ICON_SIZE_SMALL}
                                                height={ICON_SIZE_SMALL}
                                            />
                                        </span>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <span>
                    <a onClick={showQuestions}>
                        <u>{t("Configuration.common.showAllLink")}</u>
                    </a>
                </span>
                <Modal
                    isOpen={showAllQuestionsVisible}
                    hideModal={hideResult}
                    modalTitle={t("Configuration.questionsConfigurationForm.title")}
                >
                    <div>
                        <ShowAllConditionAssessmentQuestionsForm
                            currentQuestions={props.currentQuestions}
                            onQuestionsUpdate={handleQuestionsChange}
                        />
                    </div>
                </Modal>
                <span className={classNames(style.addCustomConfigurationButton)}>
                    <span>
                        <a onClick={showCustomQuestion}>
                            <u>{t("Configuration.questionsConfigurationForm.addCustomQuestion")}</u>
                        </a>
                    </span>
                    <Modal
                        isOpen={customQuestionsVisible}
                        hideModal={hideCustomQuestion}
                        modalTitle={t("Configuration.questionsConfigurationForm.addCustomQuestion")}
                    >
                        <div>
                            <CustomQuestionsForm
                                question={NEW_QUESTION}
                                minWidth={600}
                                currentIds={questionIds}
                                questionHandler={newQuestionHandler}
                                editMode={false}
                            />
                        </div>
                    </Modal>

                    <Modal
                        isOpen={editQuestionModalVisible}
                        hideModal={hideEditCustomQuestion}
                        modalTitle={t("Configuration.questionsConfigurationForm.editCustomQuestion")}
                    >
                        <div>
                            <CustomQuestionsForm
                                question={editQuestion}
                                minWidth={600}
                                currentIds={questionIds}
                                questionHandler={updatedQuestionHandler}
                                editMode={true}
                            />
                        </div>
                    </Modal>
                </span>
            </div>
        </>
    );
};

export default connector(ConditionAssessmentQuestionsForm);
