import { TableData } from "./table";
import {
    BBTIConfiguration,
    DownloadServerConfiguration,
    InsuranceConfiguration,
    LeaseConfiguration,
    NtfConfiguration,
    ValidationConfiguration,
} from "components/licenses/license-configuration/bms/BmsCommonInterfaces";

export interface LicenseData extends TableData {
    product: string;
    license: string;
    total: number;
    available: number;
    expirationDate: string;
    type: string;
    licenseType: string;
    isInvalid?: boolean;
    isFeatureLicensePresent: boolean;
    used?: number;
    usesLeft?: number;
    subLicenses?: LicenseData[];
    overallRemaining?: number;
    assigned?: number;
}

export interface Key {
    licenseKey: string;
    identifier: string;
    assigned: number;
    used: number;
    remaining: number;
    startDate: string;
    expirationDate: string;
    usageHoursLimit: number;
    licenseType: string;
    shortUrl: string;
    poolName: string;
}

export interface KeyCursor {
    licenseKey: string;
    tenantIdentifier: string;
}

export interface LicenseKeyCursor {
    identifier: string;
    tenantIdentifier: string;
}

export interface Keys extends TableData {
    licenseKeys: Key[];
    cursor: KeyCursor | null;
}

export interface KeyData {
    licenseKeys: Keys;
}

export interface Licenses extends TableData {
    licenseData: LicenseData[];
    cursor: LicenseKeyCursor | null;
}

export interface LicenseList {
    licenses: Licenses;
}

export interface ConfigureData extends TableData {
    uuid: string;
    product: string;
    created: string;
    modified: string;
}

export interface Entitlement {
    entitlementId: string;
    created: string;
    type: EntitlementType;
    description: string;
    backupUuid: string;
    containerId: string;
    tenantUuid: string;
}

export interface EntitlementCursor {
    lastTenantUuid: string;
    lastEntitlementId: string;
}

export interface Entitlements extends TableData {
    entitlements: Entitlement[];
    cursor: EntitlementCursor | null;
    count: number;
}

export interface EntitlementData {
    entitlements: Entitlements;
}

export enum EntitlementType {
    HL = "HL",
    HL_UPDATE = "HL_UPDATE",
    SL_ADMIN_MODE = "SL_AdminMode",
    SL_CREATE = "SL_CREATE",
    SL_UPDATE = "SL_UPDATE",
}

export enum ContainerType {
    HL = "HL",
    SL_ADMIN_MODE = "SL_AdminMode",
    SL_CREATE = "SL_CREATE",
    SL_UPDATE = "SL_UPDATE",
    HL_UPDATE = "HL_UPDATE",
}

export interface Container {
    containerId: string;
    tenantUuid: string;
    date: string;
    name: string;
    type: ContainerType;
}

export interface ContainerCursor {
    lastTenantUuid: string;
    lastContainerId: string;
}

export interface Containers extends TableData {
    containers: Container[];
    cursor: ContainerCursor | null;
}

export interface ContainerData {
    containers: Containers;
}

export interface Configuration {
    tenantConfiguration: Record<string, unknown>;
    bbtiConfiguration: BBTIConfiguration;
    validationConfiguration: ValidationConfiguration;
    insuranceConfiguration: InsuranceConfiguration;
    ntfConfiguration: NtfConfiguration;
    leaseConfiguration: LeaseConfiguration;
    downloadAppPageConfiguration: DownloadServerConfiguration;
}

export interface ConfigurationDto {
    tenant_configuration: Record<string, unknown>;
    bbti_configuration: BBTIConfiguration;
    validation_configuration: ValidationConfiguration;
    insurance_configuration: InsuranceConfiguration;
    ntf_configuration: NtfConfiguration;
    lease_configuration: LeaseConfiguration;
    download_app_page_configuration: DownloadServerConfiguration;
}

export interface EmsConfiguration {
    emsId: string;
    hlEntitlements: boolean;
    slEntitlements: boolean;
    availableSlActivations: number;
    usedSlActivations: number;
    lastSynced: string | null;
}

export interface EmsConfigurationDto {
    ems_id: string | null;
    hl_entitlements: boolean;
    sl_entitlements: boolean;
    available_sl_activations: number;
    used_sl_activations: number;
    last_synced: string | null;
}

export function toEmsConfiguration(dto: EmsConfigurationDto): EmsConfiguration {
    return {
        // Not ideal to use an empty string but React basically requires it.
        emsId: dto.ems_id ?? "",
        hlEntitlements: dto.hl_entitlements,
        slEntitlements: dto.sl_entitlements,
        availableSlActivations: dto.available_sl_activations,
        usedSlActivations: dto.used_sl_activations,
        lastSynced: dto.last_synced,
    };
}

export function toEmsConfigurationDto(configuration: EmsConfiguration): EmsConfigurationDto {
    // Since toEmsConfiguration changes null to empty string, the reverse is performed here.
    const identifier = configuration.emsId === "" ? null : configuration.emsId;
    return {
        ems_id: identifier,
        hl_entitlements: configuration.hlEntitlements,
        sl_entitlements: configuration.slEntitlements,
        available_sl_activations: configuration.availableSlActivations,
        used_sl_activations: configuration.usedSlActivations,
        last_synced: configuration.lastSynced,
    };
}
export const deliveryTypes = ["NEW_DEAL", "REIMBURSEMENT", "SUPPLEMENTARY", "CORRECTION", "CONVERSION"];
export type DeliveryType = typeof deliveryTypes[number];
export interface LicenseToDeliver {
    licenseId: string;
    amount: number;
    expirationDate: string;
    totalOfLicenses: number;
}

export interface LicensePoolLicense extends LicenseToDeliver {
    used: number;
    license?: string;
    product?: string;
}

export interface PoolSpecificLicenseResponse {
    all_licenses: LicensePoolLicense[];
}
export interface DeliveryHistory extends TableData {
    uuid: string;
    creationDate: string;
    notes: string;
    type: string;
    licenseRateVersion: string;
}

export interface DeliveryHistories {
    deliveryHistories: DeliveryHistory[];
    count: number;
    cursor: string;
}

export interface LicenseDelivery {
    type: DeliveryType;
    caseNumber: string;
    opportunityId: string;
    notes: string;
    licenses: LicenseToDeliver[];
    tenantUuid?: string;
    tokenRateVersion?: string;
}

export interface LicenseDeliveryHistory {
    uuid: string;
    type: DeliveryType;
    caseNumber: string;
    opportunityId: string;
    notes: string;
    licenses: LicenseToDeliver[];
    creationDate: string;
    creatorUuid: string;
    editorUuid: string;
    editDate: string;
    licenseRateVersion?: string;
}

export interface TokenRates {
    name: string;
    uuid: string;
    productToRate: string;
    creationDate: string;
}

export const MIN_DATE = new Date("1970-01-01T00:00:00Z");
export const MAX_DATE = new Date("2091-01-01T00:00:00Z");
