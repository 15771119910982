import classNames from "classnames";
import React, { ChangeEvent } from "react";
import { Placement } from "react-bootstrap/types";
import { connect, ConnectedProps } from "react-redux";

import Info from "components/icons/Info";
import localStyle from "components/licenses/assign-bms-keys/form.scss";
import style from "components/licenses/license-configuration/bms/bms-config.scss";
import Tooltip from "components/tooltip/Tooltip";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    settings: state.reportViewSettings,
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

interface Props {
    labelKey: string;
    radioId: string;
    checked: boolean;
    toolTipContent?: string;
    placement?: Placement;
    onChange: (newValue: boolean) => void;
    name?: string;
}

const BmsRadioButtonComponent = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const selectedPlacement: Placement = props.placement || "right-start";
    return (
        <>
            <div className={classNames(style.radioButton)}>
                <span className={classNames(style.radioButtonLabel)}>{props.labelKey}</span>

                <span className={style.selectedDot}>
                    <input
                        name={props.name}
                        type="radio"
                        id={props.radioId}
                        checked={props.checked}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => props.onChange(event.target.checked)}
                    />
                    <label htmlFor={props.radioId} className={style.radioLabel} />
                </span>
                <div className={style.toolTip}>
                    {props.toolTipContent && (
                        <Tooltip content={props.toolTipContent} placement={selectedPlacement}>
                            <div className={localStyle.info} tabIndex={0}>
                                <Info
                                    borderColor={props.theme.contentBackgroundColor}
                                    color={props.theme.iconFillColor}
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
        </>
    );
};

export default connector(BmsRadioButtonComponent);
