import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./sync.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import DateCell from "components/table/DateCell";
import { AUTH_EMS_SYNCHRONIZE } from "domain/authority";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";

import testIds from "testIds.json";

export default function SyncContainer(props: {
    dataCount: string;
    onSyncClick: () => void;
    syncing: boolean;
    showResult: boolean;
    success: boolean;
    lastSynced: string | null;
    loading: boolean;
}): JSX.Element {
    if (props.loading) {
        return <></>;
    }
    const { t } = useTranslation();
    let result;
    if (props.success) {
        result = (
            <div
                className={classNames(style.resultContainer, style.successMessage)}
                data-testid={testIds.workArea.license.entitlements.successfulSynchronizationResultLabel}
            >
                {t("Common.syncSuccessMessage")}
            </div>
        );
    } else {
        result = (
            <div
                className={classNames(style.resultContainer, style.errorMessage)}
                data-testid={testIds.workArea.license.entitlements.failedSynchronizationResultLabel}
            >
                {t("Common.syncErrorMessage")}
            </div>
        );
    }
    return (
        <div className={style.syncContainer}>
            {props.dataCount + " | " + t("Common.lastSynced") + " "}
            {!props.lastSynced ? t("Common.never") : <DateCell value={props.lastSynced} />}
            {userSessionService.userHasAllAuthorities([AUTH_EMS_SYNCHRONIZE]) && (
                <a
                    href="javascript"
                    onClick={(e) => {
                        usageStatisticsService.sendEvent({
                            category: Category.LICENSE_ENTITLEMENT,
                            action: Action.SYNCHRONIZE_ENTITLEMENTS,
                        });
                        e.preventDefault();
                        props.onSyncClick();
                    }}
                    data-testid={testIds.workArea.license.entitlements.synchronizeLink}
                >
                    {" "}
                    {t("Common.syncNow")}
                </a>
            )}
            <div className={style.loaderContainer}>{props.syncing && <LoadingIndicator small={true} />}</div>
            {!props.syncing && props.showResult && result}
        </div>
    );
}
