import * as React from "react";

const PlusIcon = (props: { color?: string }): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            style={{ marginRight: "10px" }}
        >
            <path
                d="M12.0001 18V6M18 12H6"
                stroke={props.color ? props.color : "#fff"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default PlusIcon;
