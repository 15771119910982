import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import BbtiConfigurationForm from "./BbtiConfigurationForm";
import style from "./bms-config.scss";
import { Brand, ComponentFeatures } from "./BmsCommonInterfaces";
import DownloadServerConfigurationForm from "./DownloadServerConfigurationForm";
import GenericConfigurationForm from "./GenericConfigurationForm";
import InsuranceConfigurationForm from "./InsuranceConfigurationForm";
import LeaseConfigurationForm from "./LeaseConfigurationForm";
import NtfConfigurationForm from "./NtfConfigurationForm";
import ValidationConfigurationForm from "./ValidationConfigurationForm";
import { Configuration } from "domain/licenses";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    configuration: Configuration;
    featuresWithAvailableLicenses?: string[];
    uuid?: string;
    created?: string;
    modified?: string;
    edit?: boolean;
    onEdit?: () => void;
    onSuccess: (values: Configuration) => Promise<void>;
    onClose?: () => void;
}

export default function EditBmsConfigurationForm(props: Props): JSX.Element {
    const { t } = useTranslation();

    const [formValues, setFormValues] = React.useState({
        brand: (props.configuration?.tenantConfiguration?.brand as Brand) || {
            bms_server: "",
            brandId: "",
            image: "",
            appName: "",
            tag_line: "",
            primaryColor: "",
            secondaryColor: "",
            tertiaryColor: "",
            checkContrast: "",
        },
        features: (props.configuration?.tenantConfiguration?.features as string[]) || [],
        platform: (props.configuration?.tenantConfiguration?.platform as string) || "app",
        marketing_name_url: (props.configuration?.tenantConfiguration?.marketing_name_url as string) || "",
        componentFeatures: (props.configuration?.tenantConfiguration?.component_feature as ComponentFeatures) || {},
        bbtiConfiguration: props.configuration?.bbtiConfiguration || {},
        leaseConfiguration: props.configuration?.leaseConfiguration || {},
        validationConfiguration: props.configuration?.validationConfiguration || {},
        insuranceConfiguration: props.configuration?.insuranceConfiguration || {},
        ntfConfiguration: props.configuration?.ntfConfiguration || {},
        downloadAppPageConfiguration: props.configuration.downloadAppPageConfiguration || {},
    });

    const createTab = (localizationKey: string, feature: string): (JSX.Element | undefined)[] => {
        return [
            <Tab
                key={feature}
                disabled={isFeatureDisabled(feature)}
                className={isFeatureDisabled(feature) ? style.disabledTab : ""}
            >
                {localizationKey}
            </Tab>,
        ];
    };
    const listOfFeaturesToRemoveFromIntroCards = ["gathering_imei"];

    const handleSubmit = () => {
        function generateFeatures() {
            const enabledFeatures = [];

            for (const [featureKey, featureValue] of Object.entries(
                formValues.componentFeatures as ComponentFeatures
            )) {
                if (featureValue.enabled) {
                    enabledFeatures.push(featureKey);
                }
            }
            return enabledFeatures;
        }

        function generateComponentFeatures() {
            const enabledComponentsJSON: Record<string, string[]> = {};

            for (const [featureKey, featureValue] of Object.entries(
                formValues.componentFeatures as ComponentFeatures
            )) {
                if (featureValue.enabled) {
                    enabledComponentsJSON[featureKey] = featureValue.components
                        .filter((component: { enabled: boolean; name: string }) => component.enabled)
                        .map((component: { name: string }) => component.name);
                } else {
                    enabledComponentsJSON[featureKey] = [];
                }
            }
            return enabledComponentsJSON;
        }

        function generateIntroCards() {
            return generateFeatures().filter((item) => !listOfFeaturesToRemoveFromIntroCards.includes(item));
        }

        props.onSuccess({
            ...props.configuration,
            tenantConfiguration: {
                ...props.configuration.tenantConfiguration,
                brand: formValues.brand,
                platform: formValues.platform,
                marketing_name_url: formValues.marketing_name_url,
                features: generateFeatures(),
                intro_cards: generateIntroCards(),
                component_features: generateComponentFeatures(),
            },
            bbtiConfiguration: {
                ...props.configuration.bbtiConfiguration,
                imeiMandatory: formValues.bbtiConfiguration.imeiMandatory,
                serialMandatory: formValues.bbtiConfiguration.serialMandatory,
                defaultProvider: formValues.bbtiConfiguration.defaultProvider,
                customer_punch_out: formValues.bbtiConfiguration.customer_punch_out,
                providers: formValues.bbtiConfiguration.providers,
                tests: formValues.bbtiConfiguration.tests.filter((test) => test.enabled),
            },
            leaseConfiguration: {
                ...props.configuration.leaseConfiguration,
                imeiMandatory: formValues.leaseConfiguration.imeiMandatory,
                serialMandatory: formValues.leaseConfiguration.serialMandatory,
                punchOutUrl: formValues.leaseConfiguration.punchOutUrl,
                customer_punch_out: formValues.leaseConfiguration.customer_punch_out,
                provider: formValues.leaseConfiguration.provider,
                apiConfig: formValues.leaseConfiguration.apiConfig,
                tests: formValues.leaseConfiguration.tests?.filter((test) => test.enabled),
            },
            validationConfiguration: {
                ...props.configuration.validationConfiguration,
                punchOutUrl: formValues.validationConfiguration.punchOutUrl,
                customer_punch_out: formValues.validationConfiguration.customer_punch_out,
                imeiMandatory: formValues.validationConfiguration.imeiMandatory,
                serialMandatory: formValues.validationConfiguration.serialMandatory,
                provider: formValues.validationConfiguration.provider,
                apiConfig: formValues.validationConfiguration.apiConfig,
                tests: formValues.validationConfiguration.tests.filter((test) => test.enabled),
            },
            insuranceConfiguration: {
                ...props.configuration.insuranceConfiguration,
                imeiMandatory: formValues.insuranceConfiguration.imeiMandatory,
                serialMandatory: formValues.insuranceConfiguration.serialMandatory,
                pricing: formValues.insuranceConfiguration.pricing,
                provider: formValues.insuranceConfiguration.provider,
                tests: formValues.insuranceConfiguration.tests.filter((test) => test.enabled),
            },
            ntfConfiguration: {
                ...props.configuration.ntfConfiguration,
                imeiMandatory: formValues.ntfConfiguration.imeiMandatory,
                serialMandatory: formValues.ntfConfiguration.serialMandatory,
                providers: formValues.ntfConfiguration.providers,
                thresholds: formValues.ntfConfiguration.thresholds,
                fault_codes: formValues.ntfConfiguration.fault_codes,
                tests: formValues.ntfConfiguration.tests.filter((test) => test.enabled),
            },
            downloadAppPageConfiguration: {
                ...props.configuration.downloadAppPageConfiguration,
                appIcon: formValues.downloadAppPageConfiguration.appIcon,
                headerLogo: formValues.downloadAppPageConfiguration.headerLogo,
                title: formValues.downloadAppPageConfiguration.title,
                androidFirebaseLink: formValues.downloadAppPageConfiguration.androidFirebaseLink,
                huaweiFirebase: formValues.downloadAppPageConfiguration.huaweiFirebase,
                iOSFirebaseLink: formValues.downloadAppPageConfiguration.iOSFirebaseLink,
                licenseFileType: formValues.downloadAppPageConfiguration.licenseFileType,
            },
        });
    };

    function isFeatureDisabled(feature: string) {
        return (
            Object.keys(formValues.componentFeatures).length === 0 ||
            (formValues.componentFeatures[feature] && !formValues.componentFeatures[feature].enabled)
        );
    }

    return (
        <>
            {props.configuration === undefined ? (
                ""
            ) : (
                <div className={style.tabContainer}>
                    <div data-testid={testIds.workArea.license.configure.manageConfigurationDialog.configInput.itself}>
                        <Tabs>
                            <TabList>
                                <Tab>{t("Configuration.viewConfigureDialog.genericTab")}</Tab>
                                {createTab(t("Configuration.viewConfigureDialog.buyBackTradeInTab"), "TradeIn")}
                                {createTab(t("Configuration.viewConfigureDialog.leaseTab"), "Lease")}
                                {createTab(t("Configuration.viewConfigureDialog.validationTab"), "Validation")}
                                {createTab(t("Configuration.viewConfigureDialog.insuranceTab"), "PreSalesInsurance")}
                                {createTab(t("Configuration.viewConfigureDialog.ntfTab"), "FaultCheck")}
                                <Tab>{t("Configuration.viewConfigureDialog.downloadServerTab")}</Tab>
                            </TabList>

                            <div className={style.tabPanel}>
                                <TabPanel>
                                    <GenericConfigurationForm
                                        formValues={formValues}
                                        setFormValues={setFormValues}
                                        featuresWithAvailableLicenses={props.featuresWithAvailableLicenses}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <BbtiConfigurationForm formValues={formValues} setFormValues={setFormValues} />
                                </TabPanel>
                                <TabPanel>
                                    <LeaseConfigurationForm formValues={formValues} setFormValues={setFormValues} />
                                </TabPanel>
                                <TabPanel>
                                    <ValidationConfigurationForm
                                        formValues={formValues}
                                        setFormValues={setFormValues}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <InsuranceConfigurationForm formValues={formValues} setFormValues={setFormValues} />
                                </TabPanel>
                                <TabPanel>
                                    <NtfConfigurationForm formValues={formValues} setFormValues={setFormValues} />
                                </TabPanel>
                                <TabPanel>
                                    <DownloadServerConfigurationForm
                                        formValues={formValues}
                                        setFormValues={setFormValues}
                                    />
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                    <div className={form.buttonContainer}>
                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                            onClick={handleSubmit}
                            data-testid={testIds.workArea.license.configure.manageConfigurationDialog.saveButton}
                        >
                            {t("Configuration.editConfigureDialog.saveButton")}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}
