import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import Checkbox from "./BmsCheckboxComponent";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    settings: state.reportViewSettings,
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

interface Props {
    imeiMandatory: boolean;
    serialMandatory: boolean;
    onChange: (fieldName: string, newValue: boolean) => void;
}

const BmsDeviceIdentifierCheckboxComponent = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();

    return (
        <>
            <Checkbox
                labelKey={t("Configuration.common.imeiMandatory")}
                toolTipContent={t(`Configuration.tooltips.common.imeiMandatory`)}
                checkboxId={"imeiMandatory"}
                checked={props.imeiMandatory}
                onChange={(newValue) => props.onChange("imeiMandatory", newValue)}
            />
            <Checkbox
                labelKey={t("Configuration.common.serialMandatory")}
                toolTipContent={t(`Configuration.tooltips.common.serialMandatory`)}
                checkboxId={"serialMandatory"}
                checked={props.serialMandatory}
                onChange={(newValue) => props.onChange("serialMandatory", newValue)}
            />
        </>
    );
};

export default connector(BmsDeviceIdentifierCheckboxComponent);
