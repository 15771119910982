import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Column } from "react-table";

import style from "./entitlement.scss";
import EntitlementMenuItems from "./EntitlementMenuItems";
import KebabMenu from "components/kebab-menu/KebabMenu";
import { isSubscriptionLicense } from "components/licenses/common";
import SyncContainer from "components/licenses/SyncContainer";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_EMS_CREATE_ENTITLEMENT } from "domain/authority";
import {
    Container,
    EmsConfiguration,
    Entitlement,
    EntitlementCursor,
    Entitlements,
    EntitlementType,
    LicenseData,
} from "domain/licenses";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { applyMode } from "store/theme";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { logger } from "utils/logging";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
    tenantUuid: state.userReducer.user?.tenantUuid,
    region: state.userReducer.user?.region,
});

interface TableState {
    entitlements: Entitlement[];
    cursor: EntitlementCursor | null;
    count?: number;
}

interface Props {
    initialEntitlement: Entitlements;
    requestFailureMessage: string;
    count: number;
    onUpdate: () => void;
    onFetchEmsConfiguration: (emsConfiguration: EmsConfiguration) => void;
    onFetchAvailableLicenses: (availableLicenses: LicenseData[]) => void;
    onFetchContainers: (containers: Container[]) => void;
    containers: Container[];
}

const ENTITLEMENT_TYPE_TO_LOCALIZATION_KEY = new Map([
    [EntitlementType.HL_UPDATE, "Entitlements.type.hlUpdate"],
    [EntitlementType.HL, "Entitlements.type.hlUpdate"],
    [EntitlementType.SL_UPDATE, "Entitlements.type.slUpdate"],
    [EntitlementType.SL_ADMIN_MODE, "Entitlements.type.slUpdate"],
    [EntitlementType.SL_CREATE, "Entitlements.type.slCreate"],
]);

const connector = connect(mapState, { applyMode });
const EntitlementTable = (props: ConnectedProps<typeof connector> & Props): JSX.Element => {
    const { t } = useTranslation();
    const tableContainerRef = React.useRef<HTMLDivElement>(null);

    const containerIdToNameMap: Map<string, string> = new Map();
    const getContainerName = (containerId: string) => {
        let containerName = containerIdToNameMap.get(containerId);
        if (containerName) {
            return containerName;
        }

        for (const container of props.containers) {
            if (container.containerId === containerId) {
                containerName = container.name;
                containerIdToNameMap.set(containerId, containerName);
                return containerName;
            }
        }
        return containerId;
    };

    const columns: Array<Column<Entitlement>> = [
        {
            Header: () => (
                <TextWithTooltip text={t("Entitlements.entitlementTable.entitlementId")} key="entitlement_id" />
            ),
            accessor: "entitlementId",
            Cell: (cellInfo) => (
                <>
                    {userSessionService.userHasAllAuthorities([AUTH_EMS_CREATE_ENTITLEMENT]) && (
                        <KebabMenu>
                            <EntitlementMenuItems
                                entitlementId={cellInfo.row.original.entitlementId}
                                description={cellInfo.row.original.description}
                                onEntitlementEdit={props.onUpdate}
                            />
                        </KebabMenu>
                    )}
                    <div className={style.entitlementCell}>
                        <TextWithTooltip text={cellInfo.value} />
                    </div>
                </>
            ),
            width: deriveColumnWidth(15, tableContainerRef),
        },

        {
            Header: () => <TextWithTooltip text={t("Entitlements.entitlementTable.created")} key="created" />,
            accessor: "created",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
            width: deriveColumnWidth(15, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Entitlements.entitlementTable.type")} key="type" />,
            accessor: "type",
            Cell: ({ cell: { value } }) => (
                <TextWithTooltip text={t(ENTITLEMENT_TYPE_TO_LOCALIZATION_KEY.get(value) ?? value)} />
            ),
            width: deriveColumnWidth(15, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Entitlements.entitlementTable.container")} key="container" />,
            accessor: "containerId",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} content={getContainerName(value)} />,
            width: deriveColumnWidth(15, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Entitlements.entitlementTable.description")} key="description" />,
            accessor: "description",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(38, tableContainerRef),
        },
    ];
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>(props.requestFailureMessage);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [tableState, setTableState] = React.useState<TableState>({
        entitlements: props.initialEntitlement.entitlements,
        cursor: props.initialEntitlement.cursor,
        count: props.count,
    });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [syncing, setSyncing] = React.useState<boolean>(false);
    const [syncSuccess, setSyncSuccess] = React.useState<boolean>(false);
    const [showSyncResult, setShowSyncResult] = React.useState<boolean>(false);
    const [lastSynced, setLastSynced] = React.useState<string | null>(null);
    const [loadMore, setLoadMore] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState("");
    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        setLoadMore(true);

        let containersPromise;
        if (initialLoading) {
            tableState.cursor = null;
            const abortControllerContainers = new AbortController();
            abortControllers.push(abortControllerContainers);
            containersPromise = licenseService.fetchAllContainersRecursively(abortControllerContainers, null, 0);

            if (typeof props.tenantUuid === "undefined") {
                logger.error("Unable to fetch EMS configuration. Tenant UUID is required.");
            } else {
                const abortControllerEmsConfiguration = new AbortController();
                abortControllers.push(abortControllerEmsConfiguration);
                licenseService
                    .fetchEmsConfiguration(props.tenantUuid, abortControllerEmsConfiguration, props.region)
                    .then((data) => {
                        props.onFetchEmsConfiguration(data);
                        setLastSynced(data.lastSynced);
                    })
                    .catch(() => {
                        logger.error("Fetching the EMS configuration has failed.");
                    });
            }

            const abortControllerLicenses = new AbortController();
            abortControllers.push(abortControllerLicenses);
            licenseService
                .fetchAllLicenses({ abortController: abortControllerLicenses, own: true })
                .then((licensesData) => {
                    props.onFetchAvailableLicenses(
                        licensesData.licenses.licenseData.flatMap((data) => {
                            const valid = new Date() < new Date(data.expirationDate);
                            if (
                                !isNaN(parseInt(data.type)) &&
                                data.available > 0 &&
                                valid &&
                                !isSubscriptionLicense(data.type)
                            ) {
                                return data;
                            }
                            return [];
                        })
                    );
                })
                .catch(() => {
                    logger.error("Fetching all the available licenses failed.");
                });
        }

        const abortController = new AbortController();
        abortControllers.push(abortController);
        const entitlementsPromise = licenseService.fetchEntitlements(
            abortController,
            loadMore ? tableState.cursor : null,
            searchQuery
        );

        Promise.all([containersPromise, entitlementsPromise])
            .then(([containers, entitlements]) => {
                if (containers) {
                    props.onFetchContainers(containers);
                }
                setTableState((prevState) => ({
                    entitlements: loadMore
                        ? prevState.entitlements.concat(entitlements.entitlements.entitlements)
                        : entitlements.entitlements.entitlements,
                    count: prevState.entitlements.length - 1,
                    cursor: entitlements.entitlements.cursor,
                }));
                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("Entitlements.entitlementTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        if (props.initialEntitlement.entitlements.length > 0) {
            return;
        }
        setTableState({
            entitlements: [],
            cursor: { lastEntitlementId: "", lastTenantUuid: "" },
            count: 0,
        });
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [props.count, searchQuery]);

    let dataCount = "";
    if (tableState.entitlements.length > 0) {
        dataCount = t("Common.defaultSearchResultHint", { dataCount: tableState.entitlements.length });
    }

    function onSyncClick() {
        setLoadMore(false);
        setSyncing(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        licenseService
            .synchronize(abortController)
            .then(() => {
                setSyncSuccess(true);
                props.onUpdate();
            })
            .catch(() => {
                if (!signal.aborted) {
                    setSyncSuccess(false);
                }
            })
            .finally(() => {
                if (!signal.aborted) {
                    setSyncing(false);
                    setShowSyncResult(true);
                }
            });
    }

    return (
        <>
            <div className={style.aboveTable}>
                <div className={style.recordCount}>
                    <SyncContainer
                        dataCount={dataCount ? dataCount : "0"}
                        onSyncClick={onSyncClick}
                        syncing={syncing}
                        showResult={showSyncResult}
                        success={syncSuccess}
                        lastSynced={lastSynced}
                        loading={loading}
                    />
                </div>
                <div className={classNames(formStyle.search, style.searchPadding)}>
                    <SearchView setSearch={setSearchQuery} searchInProgress={false} />
                </div>
            </div>
            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.ENTITLEMENT_TABLE}
                    data={tableState.entitlements}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    emptyMessage={t("Entitlements.entitlementTable.emptyStateMessage")}
                />
            </div>

            {tableState.cursor != null &&
                tableState.entitlements.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(buttonStyle.primaryButton, buttonStyle.loadMoreButton)}
                        onClick={() => {
                            fetchData(false);
                            usageStatisticsService.sendEvent({
                                label: Label.ENTITLEMENT,
                                action: Action.LOAD_MORE,
                                category: Category.LICENSE,
                            });
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
        </>
    );
};

EntitlementTable.defaultProps = {
    initialEntitlement: {
        entitlements: [],
        cursor: null,
    },
    requestFailureMessage: "",
};

export default connector(EntitlementTable);
