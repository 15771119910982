import React from "react";

function Move(props: { color: string }) {
    return (
        <svg width="32" height="34" viewBox="0 2 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="28" rx="14" fill={props.color} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 8.5625C8.98223 8.5625 8.5625 8.98223 8.5625 9.5V10.25C8.5625 10.5607 8.31066 10.8125 8 10.8125C7.68934 10.8125 7.4375 10.5607 7.4375 10.25V9.5C7.4375 8.36091 8.36091 7.4375 9.5 7.4375H18.5C19.6391 7.4375 20.5625 8.36091 20.5625 9.5V18.5C20.5625 19.6391 19.6391 20.5625 18.5 20.5625H9.5C8.36091 20.5625 7.4375 19.6391 7.4375 18.5V17C7.4375 16.6893 7.68934 16.4375 8 16.4375C8.31066 16.4375 8.5625 16.6893 8.5625 17V18.5C8.5625 19.0178 8.98223 19.4375 9.5 19.4375H18.5C19.0178 19.4375 19.4375 19.0178 19.4375 18.5V9.5C19.4375 8.98223 19.0178 8.5625 18.5 8.5625H9.5ZM12.8523 11.3523C13.0719 11.1326 13.4281 11.1326 13.6477 11.3523L15.8977 13.6023C16.1174 13.8219 16.1174 14.1781 15.8977 14.3977L13.6477 16.6477C13.4281 16.8674 13.0719 16.8674 12.8523 16.6477C12.6326 16.4281 12.6326 16.0719 12.8523 15.8523L14.142 14.5625L8 14.5625C7.68934 14.5625 7.4375 14.3107 7.4375 14C7.4375 13.6893 7.68934 13.4375 8 13.4375L14.142 13.4375L12.8523 12.1477C12.6326 11.9281 12.6326 11.5719 12.8523 11.3523Z"
                fill="white"
            />
        </svg>
    );
}

export default Move;
