import { TFunction } from "i18next";
import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./user-profile.scss";
import CopyToClipboard from "components/copy-to-clipboard/CopyToClipboard";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Heading from "components/typography/heading/Heading";
import { UserDetails } from "domain/user";
import { getPublicApiUrl } from "services/login/endpointRepository";
import { createDateLabel } from "utils/format";

import testIds from "testIds.json";

function createUserObject(label: string, testId: string, value?: string, style?: string) {
    return {
        label,
        testId,
        value,
        style,
    };
}

interface Props {
    user: UserDetails | null;
}

function createUserObjects(t: TFunction, props: Props) {
    // As of now the compiler doesn't understand that this is impossible because
    // this check is already performed earlier and this method is never called
    // if this is null. Still, compilation fails without this check so it must
    // be here.
    if (props.user === null) {
        return [];
    }
    return [
        createUserObject(
            t("UserProfile.username"),
            testIds.header.userProfileDialog.user.usernameLabel,
            props.user.username
        ),
        createUserObject(
            t("Common.nameWithColon"),
            testIds.header.userProfileDialog.user.nameLabel,
            props.user.name !== "" ? props.user.name : t("Common.notProvided"),
            props.user.name === "" ? style.emptyField : ""
        ),
        createUserObject(
            t("Common.emailWithColon"),
            testIds.header.userProfileDialog.user.emailLabel,
            props.user.email
        ),
        createUserObject(
            t("UserProfile.userIdentifier"),
            testIds.header.userProfileDialog.user.uuidLabel,
            props.user.uuid
        ),
        createUserObject(
            t("UserProfile.created"),
            testIds.header.userProfileDialog.user.createdLabel,
            createDateLabel(props.user.created)
        ),
        createUserObject(
            t("UserProfile.modified"),
            testIds.header.userProfileDialog.user.modifiedLabel,
            createDateLabel(props.user.modified)
        ),
    ];
}

export const UserProfile = (props: Props): JSX.Element => {
    if (props.user === null) {
        return <LoadingIndicator />;
    }

    const { t } = useTranslation();

    const userFragments = createUserObjects(t, props).map((each, index) => (
        <React.Fragment key={index}>
            <div className={style.label}>{each.label}</div>
            <div className={each.style} data-testid={each.testId}>
                {each.value}
                {each.label == t("UserProfile.username") ||
                each.label == t("Common.emailWithColon") ||
                each.label == t("UserProfile.userIdentifier")
                    ? each.value != undefined && <CopyToClipboard value={each.value} />
                    : ""}
            </div>
        </React.Fragment>
    ));
    return (
        <>
            <div className={style.userProfile}>{userFragments}</div>
            <Heading tag={"div"} variant="SUBTITLE_1">
                {t("UserProfile.customerDetails")}
            </Heading>
            <div className={style.userProfile}>
                <div className={style.label}>{t("UserProfile.customerName")}</div>
                <div data-testid={testIds.header.userProfileDialog.tenant.nameLabel}>{props.user.tenantName}</div>

                <div className={style.label}>{t("UserProfile.customerIdentifier")}</div>
                <div data-testid={testIds.header.userProfileDialog.tenant.uuidLabel}>
                    {props.user.tenantUuid}
                    {props.user?.erasureClientEndpoint != null && <CopyToClipboard value={props.user.tenantUuid} />}
                </div>

                <div className={style.label}>{t("UserProfile.statusActive")}</div>
                <div data-testid={testIds.header.userProfileDialog.tenant.activeLabel}>
                    {props.user.tenantStatus
                        ? t("Common.yes")
                        : props.user.tenantStatus === false
                        ? t("Common.no")
                        : "-"}
                </div>

                <div className={style.label}>{t("Common.accessExpiry")}:</div>
                <div data-testid={testIds.header.userProfileDialog.tenant.accessExpirationLabel}>
                    {props.user.tenantExpirationDate
                        ? createDateLabel(props.user.tenantExpirationDate)
                        : t("Common.never")}
                </div>
            </div>

            <Heading tag={"div"} variant="SUBTITLE_1">
                {t("UserProfile.erasureClientSettings")}
            </Heading>
            <div className={style.userProfile}>
                <div className={style.label}>{t("UserProfile.erasureClientEndpoint")}</div>
                <div data-testid={testIds.header.userProfileDialog.erasureClient.endpoint}>
                    {props.user.erasureClientEndpoint}
                    {props.user?.erasureClientEndpoint != null && (
                        <CopyToClipboard value={props.user.erasureClientEndpoint} />
                    )}
                </div>
                <div className={style.label}>{t("UserProfile.erasureClientPort")}</div>
                <div data-testid={testIds.header.userProfileDialog.erasureClient.port}>443</div>
            </div>

            <Heading tag={"div"} variant="SUBTITLE_1">
                {t("UserProfile.publicApiSettings")}
            </Heading>
            <div className={style.userProfile}>
                <div className={style.label}>{t("UserProfile.publicApiEndpoint")}</div>
                <div data-testid={testIds.header.userProfileDialog.publicApi.endpointLabel}>
                    {getPublicApiUrl()}
                    <CopyToClipboard value={getPublicApiUrl()} />
                </div>
                <div className={style.label}>{t("UserProfile.publicApiPort")}</div>
                <div data-testid={testIds.header.userProfileDialog.publicApi.portLabel}>443</div>
            </div>

            <Heading tag={"div"} variant="SUBTITLE_1">
                {t("UserProfile.statusSettings")}
            </Heading>
            <div className={style.userProfile}>
                <div className={style.label}>{t("UserProfile.statusActive")}</div>
                <div data-testid={testIds.header.userProfileDialog.status.activeLabel}>
                    {props.user.enabled ? t("Common.yes") : props.user.enabled === false ? t("Common.no") : "-"}
                </div>
                <div className={style.label}>{t("Common.accessExpiry")}:</div>
                <div data-testid={testIds.header.userProfileDialog.status.accessExpirationLabel}>
                    {props.user.userExpirationDate ? createDateLabel(props.user.userExpirationDate) : t("Common.never")}
                </div>
            </div>
        </>
    );
};
