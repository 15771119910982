import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ConfigurationTable from "./license-configuration/bms/BmsConfigurationTable";
import ConfigurationView from "./license-configuration/bms/BmsConfigurationView";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { LICENSES_ROUTE } from "components/router/Routes";

export default function BmsConfigurationView(): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = useState({ count: 0 });

    function onConfigurationAdded() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    return (
        <ApplicationLayout
            viewTitle={t("Configuration.title")}
            view={<ConfigurationTable initialConfiguration={[]} count={state.count} />}
            mainRoute={LICENSES_ROUTE}
            viewActions={<ConfigurationView onConfigurationAdded={onConfigurationAdded} />}
        />
    );
}
