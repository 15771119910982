import { TFunction } from "i18next";
import * as React from "react";
import { Translation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./chart.scss";
import { commonFunctions } from "./CommonFunctions";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { DashboardDataList } from "domain/dashboard";
import { StoreState } from "store";

interface ChartProps {
    displayLegend?: boolean;
    widgetData: DashboardDataList[];
    dataFetched: boolean;
    co2EquivalentsSummaryContainer?: string;
    renderSummary?: JSX.Element;
}

interface ChartState {
    data?: number[];
    businessTravelEmissions: number;
    freightGoodsEmissions: number;
    hotelStaysEmissions: number;
    deliveryTrucksEmissions: number;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

type Props = ChartProps & ConnectedProps<typeof connector>;

class Co2EquivalentsChart extends React.Component<Props, ChartState> {
    private readonly numberFormatOptions = { maximumFractionDigits: 0 };
    private readonly distance = 1000;

    constructor(props: Props) {
        super(props);

        this.state = {
            data: [],
            businessTravelEmissions: 0.302396,
            freightGoodsEmissions: 0.01592,
            hotelStaysEmissions: 44.3830188679245,
            deliveryTrucksEmissions: 0.85049,
        };
    }

    computePreventedEmissions(factor: number): string {
        return (commonFunctions.computeTotalOfCo2EmissionsPrevented(this.props.widgetData) / factor).toLocaleString(
            undefined,
            this.numberFormatOptions
        );
    }

    computePreventedFreightedGoodsEmissions(factor: number): string {
        const tonsOfGoodsPerKilometer =
            commonFunctions.computeTotalOfCo2EmissionsPrevented(this.props.widgetData) / factor;
        // Return the amount of emissions caused by transporting freighted goods 1000 km
        return (tonsOfGoodsPerKilometer / this.distance).toLocaleString(undefined, this.numberFormatOptions);
    }

    renderSummary = (t: TFunction, totalTonnesOfCo2: number, unit: string): JSX.Element => {
        return (
            <div className={style.co2EquivalentsSummaryContainer}>
                <TextBlock>
                    <span className={style.summaryCount}>
                        {t("EsgDashboardView.co2EmissionsPreventedChart.totalOfCo2EmissionsPrevented", {
                            totalEmissionsPrevented: totalTonnesOfCo2.toFixed(),
                            unit: t(unit),
                        })}
                    </span>
                    <br />
                    {t("EsgDashboardView.co2EquivalentsChart.co2EquivalentsSubtitle", {
                        firstMonth: commonFunctions.computeFirstMonth(this.props.widgetData),
                        lastMonth: commonFunctions.computeLastMonth(this.props.widgetData),
                    })}
                </TextBlock>
            </div>
        );
    };

    public render(): JSX.Element {
        if (!this.props.dataFetched) {
            return (
                <div className={style.loaderContainer}>
                    <LoadingIndicator />
                </div>
            );
        }
        const displayedValuesAndUnits = commonFunctions.getDisplayedValueAndUnit(
            commonFunctions.computeTotalOfCo2EmissionsPrevented(this.props.widgetData)
        );

        return (
            <Translation>
                {(t) => (
                    <div className={style.chartContainer}>
                        {this.props.renderSummary ||
                            this.renderSummary(t, displayedValuesAndUnits.total, displayedValuesAndUnits.totalUnit)}

                        <div className={style.co2EquivalentsContainer}>
                            <div className={style.co2EquivalentsRow}>
                                <div className={style.item}>
                                    <div className={style.truckIcon} title={t("AltText.truck")} />
                                    {t("EsgDashboardView.co2EquivalentsChart.truckEquivalentText", {
                                        amount: this.computePreventedEmissions(this.state.deliveryTrucksEmissions),
                                    })}
                                </div>
                                <div className={style.item}>
                                    <div className={style.freightIcon} title={t("AltText.freight")} />
                                    {t("EsgDashboardView.co2EquivalentsChart.freightGoodsEquivalentText", {
                                        amount: this.computePreventedFreightedGoodsEmissions(
                                            this.state.freightGoodsEmissions
                                        ),
                                        distance: this.distance,
                                    })}
                                </div>
                            </div>
                            <div className={style.co2EquivalentsRow}>
                                <div className={style.item}>
                                    <div className={style.hotelIcon} title={t("AltText.hotel")} />
                                    {t("EsgDashboardView.co2EquivalentsChart.hotelsEquivalentText", {
                                        // Hotel stays per night are multiplied by 365 to get the number of stays
                                        // the number of stays during one year
                                        amount: this.computePreventedEmissions(this.state.hotelStaysEmissions),
                                    })}
                                </div>
                                <div className={style.item}>
                                    <div className={style.flightsIcon} title={t("AltText.flight")} />
                                    {t("EsgDashboardView.co2EquivalentsChart.flightsEquivalentText", {
                                        amount: this.computePreventedEmissions(this.state.businessTravelEmissions),
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connector(Co2EquivalentsChart);
