import * as React from "react";

import style from "./star.scss";

export enum Color {
    GREEN,
    GRAY,
    PURPLE,
}

const COLOR_TO_STYLE = new Map([
    [Color.GREEN, style.green],
    [Color.GRAY, style.gray],
    [Color.PURPLE, style.purple],
]);

export default function GrayStar(props: { color: Color; titleText: string }): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.242" height="13.643" viewBox="0 0 14.242 13.643">
            <title>{props.titleText}</title>
            <g id="defaultWorkFlow" transform="translate(1 1)">
                <path
                    id="star"
                    className={COLOR_TO_STYLE.get(props.color)}
                    d="M8.121,2l1.891,3.832,4.23.618L11.182,9.431l.722,4.211L8.121,11.653,4.338,13.643l.722-4.211L2,6.45l4.23-.618Z"
                    transform="translate(-2 -2)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
}
