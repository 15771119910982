import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { NtfConfiguration } from "./BmsCommonInterfaces";
import DeviceIdentifierCheckboxes from "./form-components/BmsDeviceIdentifierCheckboxComponent";
import BmsSectionHeader from "./form-components/BmsSectionHeader";
import NtfGroupedTestsForm from "./ntf/NtfGroupedTestsForm";
import NtfProviderForm from "./ntf/NtfProviderForm";
import NtfThresholdsForm from "./ntf/NtfThresholdsForm";
import { StoreState } from "store";

interface FormValues {
    ntfConfiguration: NtfConfiguration;
    platform: string;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const NtfConfigurationForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();

    const updateFormValues = (updateFn: (formValues: FormValues) => FormValues) => {
        props.setFormValues((previousFormValues) => updateFn({ ...previousFormValues }));
    };

    const handleNtfCheckboxChange = (fieldName: string, isChecked: boolean) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, [fieldName]: isChecked } };
        });
    };

    return (
        <div>
            <BmsSectionHeader labelKey={t("Configuration.common.headers.defaultSettings")} />
            <DeviceIdentifierCheckboxes
                serialMandatory={props.formValues.ntfConfiguration.serialMandatory}
                imeiMandatory={props.formValues.ntfConfiguration.imeiMandatory}
                onChange={handleNtfCheckboxChange}
            />

            <BmsSectionHeader labelKey={t("Configuration.common.headers.provider")} />
            <NtfProviderForm formValues={props.formValues} setFormValues={updateFormValues} />

            <BmsSectionHeader labelKey={t("Configuration.ntfConfigurationForm.headers.thresholds")} />
            <NtfThresholdsForm formValues={props.formValues} setFormValues={updateFormValues} />

            <BmsSectionHeader labelKey={t("Configuration.ntfConfigurationForm.headers.groupedTests")} />
            <NtfGroupedTestsForm formValues={props.formValues} setFormValues={updateFormValues} />
        </div>
    );
};

export default connector(NtfConfigurationForm);
