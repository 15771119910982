import ProgressBar from "@ramonak/react-progress-bar";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import style from "./notification-details.scss";
import CopyToClipboard from "components/copy-to-clipboard/CopyToClipboard";
import StartedGreyIcon from "components/icons/StartedGreyIcon";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Tooltip from "components/tooltip/Tooltip";
import { Notification } from "domain/notification";
import { JobDetail, Status } from "domain/reports";
import { reportViewService } from "services/report/ReportViewService";
import { StoreState } from "store";
import buttonStyle from "styles/buttons.scss";
import { formatTimestamp } from "utils/format";

import FailedRedNotificationIcon from "assets/images/icons/alert.svg";
import PassedGreenNotificationIcon from "assets/images/icons/checkMarkInCircle.svg";
import WarningYellowNotificationIcon from "assets/images/icons/quickIndeterminate.svg";

import testIds from "testIds.json";

const NotificationDetails = (props: {
    jobId: string;
    refreshCount: number;
    jobDetail: Notification;
    setRefreshIcon: (show: boolean) => void;
}): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [jobDetails, setJobDetails] = React.useState<JobDetail>();
    const [showInPercentage, setShowInPercentage] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>();
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const CURRENT_DATE = new Date();
    const REFETCH_JOB_DETAILS_TIMER = 5_000;
    const MAX_REPORT_LIMIT = jobDetails && ["SUMMARY_PDF", "PDF"].includes(jobDetails?.format) ? 1_000 : 10_000;

    const fetchData = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportViewService
            .fetchExportNotificationDetails(JSON.parse(props.jobId).jobUuid, abortController)
            .then((jobDetails: JobDetail) => {
                setJobDetails(jobDetails);
                const existingReports = jobDetails.totalReportsProcessed !== null;
                setShowInPercentage(
                    jobDetails.status === Status.WARNING || jobDetails.status === Status.SUCCEEDED
                        ? 100
                        : existingReports
                        ? Math.floor(
                              ((jobDetails.totalReportsExported + jobDetails.totalFailures) * 100) /
                                  jobDetails.totalReportsProcessed
                          )
                        : 0
                );
                props.setRefreshIcon(
                    jobDetails.status === Status.STARTED &&
                        (jobDetails.totalReportsExported < jobDetails.totalReportsProcessed || !existingReports)
                );
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    props.setRefreshIcon(!abortController.signal.aborted);
                    setRequestFailureMessage(t("Notification.reportNotifications.requestFailed"));
                }
            })
            .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        setLoading(true);
        const abortController = new AbortController();
        fetchData();

        return () => {
            abortController.abort();
        };
    }, [props.refreshCount]);

    React.useEffect(() => {
        if (jobDetails?.status === Status.STARTED) {
            const timer = setTimeout(() => {
                fetchData();
            }, REFETCH_JOB_DETAILS_TIMER);
            return () => {
                clearTimeout(timer);
            };
        }
    });

    const downloadReport = () => {
        if (jobDetails?.presignedUrl) {
            window.location.href = jobDetails?.presignedUrl;
        }
    };

    const isLinkValid = () => {
        if (typeof jobDetails?.linkExpirationDate === "undefined") {
            return false;
        }
        return new Date(jobDetails?.linkExpirationDate) >= CURRENT_DATE;
    };

    const totalReportsExported = jobDetails?.totalReportsExported === null ? 0 : jobDetails?.totalReportsExported;
    const totalReportsFetched = jobDetails?.totalReportsProcessed === null ? 0 : jobDetails?.totalReportsProcessed;

    const generateTotalReportsProcessedCount = () => {
        let failures: number | undefined = 0;
        let exports: number | undefined = 0;
        if (typeof jobDetails?.totalFailures !== "undefined" && jobDetails.totalFailures !== null) {
            failures = jobDetails?.totalFailures;
        }
        if (typeof jobDetails?.totalReportsExported !== "undefined" && jobDetails.totalReportsExported !== null) {
            exports = jobDetails?.totalReportsExported;
        }

        return failures + exports;
    };

    const isAvailable = isLinkValid() && showInPercentage === 100 && jobDetails?.presignedUrl !== undefined;

    const downloadLink = (
        <div className={style.downloadField}>
            {t("Notification.link.downloadLink")}
            <CopyToClipboard value={jobDetails?.presignedUrl ? jobDetails?.presignedUrl : ""} />
        </div>
    );

    const downloadLinkExpired = (
        <div
            data-testid={testIds.workArea.report.reportExportResultsDialog.linkExpirationDateLabel}
            className={style.downloadField}
        >
            {isAvailable ? (
                <>
                    {downloadLink}
                    {t("Notification.reportNotifications.linkExpiration", {
                        date:
                            typeof jobDetails?.linkExpirationDate !== "undefined"
                                ? formatTimestamp(jobDetails?.linkExpirationDate)
                                : "",
                    })}
                </>
            ) : (
                t("Notification.reportNotifications.linkNotAvailable")
            )}
        </div>
    );

    const createDownloadButton = () => {
        return (
            <>
                <Tooltip
                    content={t("Notification.reportNotifications.downloadReport", {
                        filename: jobDetails?.filename,
                    })}
                    placement={"top"}
                >
                    <button
                        data-testid={testIds.workArea.report.reportExportResultsDialog.downloadReportsButton}
                        className={classNames(
                            isAvailable ? buttonStyle.primaryButton : buttonStyle.disabledButton,
                            buttonStyle.loadMoreButton,
                            style.downloadButton
                        )}
                        onClick={downloadReport}
                        disabled={!isAvailable}
                    >
                        {t("Notification.reportNotifications.downloadButton")}
                    </button>
                </Tooltip>
                {downloadLinkExpired}
            </>
        );
    };

    const numberOfFileExported = (
        <div data-testid={testIds.workArea.report.reportExportResultsDialog.numberOfExportFilesLabel}>
            {t("Notification.reportNotifications.numberOfExportFiles", {
                totalReport: generateTotalReportsProcessedCount(),
                totalReportInProcess: totalReportsFetched,
            })}
        </div>
    );

    const failedJobUuid = (
        <div>
            <strong>{t("Notification.reportNotifications.failedJobUuid")}</strong> {jobDetails?.jobUuid}
            <CopyToClipboard value={jobDetails ? jobDetails?.jobUuid : ""} />
        </div>
    );

    const createDetailsDialog = (jobStatus: string | undefined) => {
        if (typeof jobDetails === "undefined" || typeof jobStatus === "undefined") {
            return <></>;
        }
        const detailsMap = new Map<string, string>([
            [
                Status.SUCCEEDED,
                t("Notification.reportNotifications.successDetails", {
                    format: jobDetails.format,
                }),
            ],
            [
                Status.WARNING,
                t("Notification.reportNotifications.warningDetails", {
                    totalReportFail: jobDetails.totalFailures,
                    format: jobDetails.format,
                    totalReportExported: totalReportsExported,
                }),
            ],
            [
                Status.FAILED,
                t("Notification.reportNotifications.failedDetails", {
                    format: jobDetails.format,
                }),
            ],
            [
                Status.STARTED,
                t("Notification.reportNotifications.inProgressDetails", {
                    totalReport: totalReportsFetched,
                    format: jobDetails?.format,
                }),
            ],
        ]);
        const colorsMap = new Map<string, string>([
            [Status.SUCCEEDED, theme.successBorderColor],
            [Status.WARNING, theme.secondaryWarningBorderColor],
            [Status.FAILED, theme.errorWrapperBackgroundColor],
            [Status.STARTED, theme.textColor],
        ]);

        const iconMap = new Map<string, string>([
            [Status.SUCCEEDED, PassedGreenNotificationIcon],
            [Status.WARNING, WarningYellowNotificationIcon],
            [Status.FAILED, FailedRedNotificationIcon],
        ]);

        const statusDetails =
            jobStatus === "FAILED" ? (
                <div className={style.details}>
                    {detailsMap.get(jobStatus)} {failedJobUuid}
                </div>
            ) : (
                <div className={style.details}>{detailsMap.get(jobStatus)}</div>
            );

        const showImage =
            jobStatus === "STARTED" ? (
                <StartedGreyIcon key={1} pathColor={theme.contentBackgroundColor} fillColor={theme.textColor} />
            ) : (
                <img className={style.image} src={iconMap.get(jobStatus)} alt={t("AltText.confirmationIcon")} />
            );
        return (
            <div>
                <div className={style.titleMessage}>{showImage}</div>
                {jobDetails.totalReportsProcessed == MAX_REPORT_LIMIT && jobDetails.totalReports > MAX_REPORT_LIMIT
                    ? t("Notification.reportNotifications.messageWithOverallCount", {
                          maxReportLimit: MAX_REPORT_LIMIT,
                          totalReports: jobDetails.totalReports,
                      })
                    : null}
                {statusDetails}
                <div className={style.numberInPercent}>
                    {t("Notification.reportNotifications.showInPercentage", { percentage: showInPercentage })}
                </div>
                {jobDetails.totalReportsProcessed === null && jobStatus === Status.STARTED
                    ? t("Notification.reportNotifications.fetchingInProgress")
                    : numberOfFileExported}
                <div
                    data-testid={testIds.workArea.report.reportExportResultsDialog.statusInPercentageLabel}
                    className={style.progressBarContainer}
                >
                    <ProgressBar
                        height="3px"
                        bgColor={colorsMap.get(jobStatus)}
                        labelColor={colorsMap.get(jobStatus)}
                        completed={showInPercentage}
                        baseBgColor={theme.contentBorderColor}
                    />
                </div>
                {createDownloadButton()}
            </div>
        );
    };
    return (
        <div
            data-testid={testIds.workArea.report.reportExportResultsDialog.itself}
            className={style.detailContainer}
            data-type={props.jobDetail.type}
        >
            <div>
                {loading ? (
                    <LoadingIndicator />
                ) : requestFailureMessage ? (
                    <div>{requestFailureMessage}</div>
                ) : (
                    createDetailsDialog(jobDetails?.status)
                )}
            </div>
        </div>
    );
};

export default NotificationDetails;
