import React from "react";

const Premium = (): JSX.Element => (
    <svg
        id="premium_feature_icon"
        xmlns="http://www.w3.org/2000/svg"
        width="15.003"
        height="15.003"
        viewBox="0 0 15.003 15.003"
    >
        <path
            id="Path_5288"
            data-name="Path 5288"
            d="M2,15.424H17V17H2ZM2,4.369,6.3,9.011,9.5,2l3.273,7.011L17,4.369v9.476H2Z"
            transform="translate(-2 -2)"
            fill="#5f96bc"
        />
    </svg>
);

export default Premium;
