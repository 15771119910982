import React from "react";

const GeneralNotificationsGreyIcon = (props: { pathColor: string; fillColor: string; width?: string }): JSX.Element => {
    return (
        <svg
            width={props.width ? props.width : "20"}
            height={props.width ? props.width : "20"}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="9.99984" cy="10.0001" r="8.33333" fill={props.fillColor} />
            <path
                d="M10.8332 5.83333C10.8332 6.29357 10.4601 6.66667 9.99984 6.66667C9.5396 6.66667 9.1665 6.29357 9.1665 5.83333C9.1665 5.3731 9.5396 5 9.99984 5C10.4601 5 10.8332 5.3731 10.8332 5.83333Z"
                fill={props.pathColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99984 8.33333C10.4601 8.33333 10.8332 8.70643 10.8332 9.16667V14.1667C10.8332 14.6269 10.4601 15 9.99984 15C9.5396 15 9.1665 14.6269 9.1665 14.1667V9.16667C9.1665 8.70643 9.5396 8.33333 9.99984 8.33333Z"
                fill={props.pathColor}
            />
        </svg>
    );
};

export default GeneralNotificationsGreyIcon;
