import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./videos.scss";
import ErrorMessage from "components/error-message/ErrorMessage";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { LicenseData } from "domain/licenses";
import { LicenseResponse } from "services/licenses/LicenseService";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { SupportVideo } from "services/videos/VideosService";
import { createDateLabel } from "utils/format";

import testIds from "testIds.json";

const MC_ID = 51;

function generateContent(loading: boolean, fetchFailed: boolean, videos: SupportVideo[]): JSX.Element {
    if (loading) {
        return <LoadingIndicator />;
    }
    if (fetchFailed) {
        return <ErrorMessage />;
    }
    return (
        <div className={style.allVideosContainer}>
            {videos.map((each, index) => (
                <div
                    key={index}
                    className={style.singleVideoContainer}
                    data-testid={testIds.workArea.support.supportAndHelp.cards.videos.video.itself}
                >
                    <iframe
                        src={each.url}
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                    ></iframe>
                    <div>
                        <div>{createDateLabel(each.date)}</div>
                        <div>{each.title.en_us}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export function createVideoFilter(licenses: LicenseData[]): (video: SupportVideo) => boolean {
    const licenseIds = new Set<number>(licenses.map((each) => Number(each.type)).filter((each) => !isNaN(each)));
    const internalUser = userSessionService.currentUserDetails()?.tenantType === "INTERNAL" && !hasTenantCookie();
    return (video: SupportVideo) => {
        if (video.licenseIds.length === 0) {
            return true;
        }
        if (video.licenseIds.length === 1 && video.licenseIds[0] === MC_ID) {
            return false;
        }
        if (internalUser) {
            return true;
        }
        if (licenseIds.size === 0 && video.licenseIds.length > 0) {
            return false;
        }
        return video.licenseIds.some((licenseId) => licenseIds.has(licenseId));
    };
}

export function sortVideos(videos: SupportVideo[]): SupportVideo[] {
    // At the moment (2022-01-13) all widely used browsers support stable sort, and they cover 92.72% of all web users.
    // So it's possible that the sort isn't stable, but it's unlikely enough that only date is used here.
    videos.sort((first, second) => second.date.localeCompare(first.date));
    return videos;
}

interface Props {
    licensePromise: Promise<LicenseResponse>;
    fetchVideoInformationList: () => Promise<SupportVideo[]>;
}

const Video = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [videos, setVideos] = React.useState<SupportVideo[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [fetchFailed, setFetchFailed] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        const videoPromise = props.fetchVideoInformationList();
        Promise.all([videoPromise, props.licensePromise])
            .then((promises) => promises[0].filter(createVideoFilter(promises[1].licenses)))
            .then((videos) => setVideos(sortVideos(videos)))
            .catch(() => setFetchFailed(true))
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            <div className={style.heading}>{t("Support.supportAndHelp.videos.introduction")}</div>
            {generateContent(loading, fetchFailed, videos)}
        </>
    );
};
export default Video;
