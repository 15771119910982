import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import workflowStyle from "./all-workflows-table.scss";
import { useOutsideClick } from "components/header/MenuPanel";
import SearchView from "components/search/SearchView";
import { DefaultWorkflowUser } from "services/workflows/WorkflowService";
import formStyle from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    status: React.RefObject<HTMLDivElement>;
    userDetails: DefaultWorkflowUser[];
}

const ActiveWorkflowUserList = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [searchItem, setSearchItem] = React.useState("");
    const handleClickOutside = () => {
        props.status.current?.click();
    };
    const ref = useOutsideClick(handleClickOutside);
    const ListItems = props.userDetails.map((item, index) => {
        return (
            <ul key={index}>
                <div className={workflowStyle.listItem}>{item.name === null ? item.username : item.name}</div>
            </ul>
        );
    });

    const searchResult = props.userDetails.filter((item) => {
        return (
            !item.username.includes(searchItem.split("@")[1]) &&
            (item.username.includes(searchItem.split("@")[0]) ||
                item.name?.includes(searchItem) ||
                item.name?.includes(searchItem.charAt(0).toUpperCase()))
        );
    });

    React.useEffect(() => {
        searchResult;
    }, [searchItem]);
    return (
        <div
            ref={ref}
            className={workflowStyle.searchContainer}
            data-testid={testIds.workArea.workflows.table.defaultList.itself}
        >
            <div className={classNames(formStyle.search, workflowStyle.searchBox)}>
                <SearchView setSearch={setSearchItem} searchInProgress={false} realTimeSearch={true} />
            </div>
            <div className={workflowStyle.itemContainer}>
                {searchItem === "" ? (
                    ListItems
                ) : searchResult.length === 0 ? (
                    <div className={workflowStyle.noResultBox}>{t("WorkflowsTable.searchEmptyStateMessage")}</div>
                ) : (
                    searchResult.map((item, index) => (
                        <ul key={index}>
                            <div className={workflowStyle.listItem}>{item.name || item.username}</div>
                        </ul>
                    ))
                )}
            </div>
        </div>
    );
};

export default ActiveWorkflowUserList;
