import * as React from "react";

const Cross = (props: { color: string }): JSX.Element => {
    return (
        <svg id="clear_icon" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
            <circle id="Ellipse_220" data-name="Ellipse 220" cx="6.5" cy="6.5" r="6.5" fill="silver" />
            <g id="Group_2725" data-name="Group 2725" transform="translate(3.924 3.923)">
                <line
                    id="Line_754"
                    data-name="Line 754"
                    x2="5"
                    y2="5"
                    transform="translate(0.076 0.077)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_755"
                    data-name="Line 755"
                    x1="5"
                    y2="5"
                    transform="translate(0.076 0.077)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth="1"
                />
            </g>
        </svg>
    );
};

export default Cross;
