import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./insurance-view.scss";
import Heading from "components/typography/heading/Heading";
import { DiagnosticReportResponse } from "domain/reports";
import { createDateLabel, currency } from "utils/format";

import checkIcon from "assets/images/icons/checkMarkInCircle.svg";
import crossIcon from "assets/images/icons/cross.svg";

import testIds from "testIds.json";

export interface InsuranceProps {
    diagnosticReportResponse: DiagnosticReportResponse;
}

export default function InsuranceView(props: InsuranceProps): JSX.Element {
    const { t } = useTranslation();
    const diagnosticReportData = props.diagnosticReportResponse;
    const coverageList = diagnosticReportData.insurance.price?.coverage.map((list, index) => (
        <div key={index}>{list}</div>
    ));
    return (
        <>
            {typeof diagnosticReportData.insurance.price !== "undefined" && (
                <>
                    <Heading tag="div" variant="SUBTITLE_1">
                        {t("DiagnosticReportsTable.insurance.insurance")}
                    </Heading>
                    <div className={style.insuranceView}>
                        <div className={style.label}>{t("DiagnosticReportsTable.insurance.date")}</div>
                        <div data-testid={testIds.workArea.report.diagnostic.reportDialog.insurance.price.dateLabel}>
                            {diagnosticReportData.insurance.price.date
                                ? createDateLabel(diagnosticReportData.insurance.price.date)
                                : t("Common.notAvailable")}
                        </div>
                        {typeof diagnosticReportData.insurance.price.price !== "undefined" && (
                            <>
                                <div className={style.label}>{t("DiagnosticReportsTable.insurance.price")}</div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.insurance.price.priceLabel
                                    }
                                >
                                    {currency(
                                        diagnosticReportData.insurance.price.price,
                                        diagnosticReportData.insurance.price.currency
                                    )}
                                </div>
                            </>
                        )}

                        {typeof diagnosticReportData.insurance.price.priceType !== "undefined" && (
                            <>
                                <div className={style.label}>{t("DiagnosticReportsTable.insurance.priceType")}</div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.insurance.price.priceTypeLabel
                                    }
                                >
                                    {diagnosticReportData.insurance.price.priceType}
                                </div>
                            </>
                        )}
                        {typeof diagnosticReportData.insurance.price.eligible !== "undefined" && (
                            <>
                                <div className={style.label}>{t("DiagnosticReportsTable.insurance.eligible")}</div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.insurance.price.eligibleLabel
                                    }
                                >
                                    <img src={diagnosticReportData.insurance.price.eligible ? checkIcon : crossIcon} />
                                </div>
                            </>
                        )}
                        <>
                            <div className={style.label}>{t("DiagnosticReportsTable.insurance.expiration")}</div>
                            <div
                                data-testid={
                                    testIds.workArea.report.diagnostic.reportDialog.insurance.price.expirationLabel
                                }
                            >
                                {diagnosticReportData.insurance.price.expiration
                                    ? createDateLabel(diagnosticReportData.insurance.price.expiration)
                                    : t("Common.notAvailable")}
                            </div>
                        </>
                        {typeof diagnosticReportData.insurance.price.productName !== "undefined" && (
                            <>
                                <div className={style.label}>{t("DiagnosticReportsTable.insurance.productName")}</div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.insurance.price.productNameLabel
                                    }
                                >
                                    {diagnosticReportData.insurance.price.productName}
                                </div>
                            </>
                        )}
                        {typeof diagnosticReportData.insurance.price.productCode !== "undefined" && (
                            <>
                                <div className={style.label}>{t("DiagnosticReportsTable.insurance.productCode")}</div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.insurance.price.productCodeLabel
                                    }
                                >
                                    {diagnosticReportData.insurance.price.productCode}
                                </div>
                            </>
                        )}

                        {typeof diagnosticReportData.insurance.price.coverage !== "undefined" && (
                            <>
                                <div className={style.label}>{t("DiagnosticReportsTable.insurance.coverage")}</div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.insurance.price.coverageLabel
                                    }
                                >
                                    <div>{coverageList}</div>
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
