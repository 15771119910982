import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import ReportTemplatesKebabMenu from "./ReportTemplatesKebabMenu";
import style from "./template-table.scss";
import Star, { Color as StarColor } from "components/icons/Star";
import KebabMenu from "components/kebab-menu/KebabMenu";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import FlexibleModal from "components/modal/FlexibleModal";
import Modal from "components/modal/Modal";
import ReportTemplateForm from "components/reports/report-template/ReportTemplateForm";
import ReportTemplateView from "components/reports/report-template/ReportTemplateView";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_REPORT_TEMPLATE_DELETE } from "domain/authority";
import {
    BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID,
    reportSections,
    ReportTemplate,
    ReportType,
    TemplateTableData,
} from "domain/reports";
import { reportTemplateService } from "services/report/erasure/ReportTemplateService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import buttons from "styles/buttons.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface TableState {
    templateData: TemplateTableData[];
}

interface Result {
    title: string;
    message: string;
    resultVisible: boolean;
}

interface ModalState {
    uuid: string;
    templateName: string;
    modalVisibility: boolean;
}

export interface Props {
    allLoaded: boolean;
    count: number;
    setTemplatesFetched: (fetched: boolean) => void;
    onTemplatesFetched: (doFetch: TemplateTableData[]) => void;
    onTemplateDelete: () => void;
    onTemplateUpdate: () => void;
    onTemplateSetDefault: () => void;
}
const ReportTemplatesTable = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [tableState, setTableState] = React.useState<TableState>({ templateData: [] });
    const [reportTemplate, setReportTemplate] = React.useState<ReportTemplate | undefined | null>(null);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
    const [dynamicSize, setDynamicSize] = React.useState(true);
    const [templateVisibility, setTemplateVisibility] = React.useState<ModalState>({
        uuid: "",
        templateName: "",
        modalVisibility: false,
    });
    const manageDynamicSize = (initial: boolean) => {
        setDynamicSize(initial);
    };

    const [refreshCount, setRefreshCount] = React.useState(0);
    const [editTemplateModalVisible, setEditTemplateModalVisible] = React.useState(false);
    const [editTemplateModalLoading, setEditTemplateModalLoading] = React.useState(false);
    const [result, setResult] = React.useState<Result>({
        title: "",
        message: "",
        resultVisible: false,
    });
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const defaultTemplate: TemplateTableData = {
        uuid: BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID,
        templateName: t("ErasureReport.default"),
        reportType: ReportType.STANDARD,
        logoVisible: false,
        disclaimer: "",
        sections: reportSections.map((each) => each),
        createdDate: "2020-01-01T00:00:00Z",
        editedDate: "",
        createdBy: "Blancco Management Portal",
        editedBy: "",
        defaultReportTemplate: {
            own: false,
            other: {
                count: 0,
                usernames: [],
            },
        },
    };
    const onToggleFullscreen = () => {
        setRefreshCount(refreshCount + 1);
    };

    const fetchData = (abortController: AbortController) => {
        setInitialLoading(true);
        reportTemplateService
            .fetchTemplates(abortController)
            .then((data) => {
                const ownDefaultExists =
                    data.find((template) => template.defaultReportTemplate.own === true) !== undefined;
                defaultTemplate.defaultReportTemplate.own = !ownDefaultExists;
                data.unshift(defaultTemplate);
                setTableState((prevState) => ({
                    ...prevState,
                    templateData: data,
                }));
                props.onTemplatesFetched(data);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("TemplatesTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
                props.setTemplatesFetched(true);
            });
    };

    const onShowModal = (show: boolean) => {
        setEditTemplateModalVisible(show);
    };

    const onShowEditModal = (uuid: string) => {
        setDynamicSize(false);
        onShowModal(true);
        setReportTemplate(tableState.templateData.find((template) => template.uuid === uuid));
    };

    const onViewTemplateEdit = (uuid: string) => {
        hideShowTemplateModal();
        setDynamicSize(false);
        onShowModal(true);
        setReportTemplate(tableState.templateData.find((template) => template.uuid === uuid));
    };

    const showTemplateModal = (uuid: string, templateName: string) => {
        setTemplateVisibility({
            uuid,
            templateName,
            modalVisibility: true,
        });
    };

    const hideShowTemplateModal = () => {
        setDynamicSize(true);
        setTemplateVisibility({
            uuid: "",
            templateName: "",
            modalVisibility: false,
        });
        usageStatisticsService.sendEvent({
            category: Category.REPORT_TEMPLATE,
            action: Action.VIEW_REPORT_TEMPLATE,
        });
    };

    const onTemplateEditSubmitHandler = (template: ReportTemplate) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        setEditTemplateModalLoading(true);
        setDynamicSize(true);
        reportTemplateService
            .updateReportTemplate(template, abortController)
            .then(() => {
                setEditTemplateModalVisible(false);
                setDynamicSize(true);
                setResult({
                    title: t("EditTemplate.templateUpdated.success.title"),
                    message: t("EditTemplate.templateUpdated.success.message", { name: template.templateName }),
                    resultVisible: true,
                });
            })
            .catch((error) => {
                if (!abortController.signal.aborted) {
                    let errorMessage;
                    try {
                        errorMessage =
                            JSON.parse(error.message).errorCode == "CONFLICT"
                                ? t("EditTemplate.templateUpdated.failed.versionMismatchedMessage")
                                : t("EditTemplate.templateUpdated.failed.message");
                    } catch {
                        errorMessage = t("EditTemplate.templateUpdated.failed.permissionChangedMessage");
                    }
                    setEditTemplateModalVisible(false);
                    setDynamicSize(true);
                    setResult({
                        title: t("EditTemplate.templateUpdated.failed.title"),
                        message: errorMessage,
                        resultVisible: true,
                    });
                }
            });
    };

    const hideResultAndRedirectToRoot = () => {
        setResult((prevState) => ({ ...prevState, resultVisible: false }));
        setEditTemplateModalLoading(false);
        props.onTemplateUpdate();
    };

    const columns: Array<Column<TemplateTableData>> = [
        {
            Header: () => <TextWithTooltip text={t("TemplatesTable.name")} key="name" />,
            accessor: "templateName",
            Cell: (cellInfo) => (
                <>
                    {!hasTenantCookie() && userSessionService.userHasAnyAuthority([AUTH_REPORT_TEMPLATE_DELETE]) && (
                        <KebabMenu>
                            <ReportTemplatesKebabMenu
                                uuid={cellInfo.cell.row.original.uuid}
                                name={cellInfo.cell.row.original.templateName}
                                onTemplateDelete={props.onTemplateDelete}
                                onTemplateEdit={onShowEditModal}
                                onTemplateSetDefault={props.onTemplateSetDefault}
                            />
                        </KebabMenu>
                    )}
                    <button
                        className={style.templateNameCell}
                        onClick={() =>
                            showTemplateModal(cellInfo.cell.row.original.uuid, cellInfo.cell.row.original.templateName)
                        }
                    >
                        <TextWithTooltip text={cellInfo.value} />
                    </button>
                </>
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("TemplatesTable.reportType")} key="reportType" />,
            accessor: "reportType",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(12, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("TemplatesTable.createdDate")} key="createdDate" />,
            accessor: "createdDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("TemplatesTable.createdBy")} key="createdBy" />,
            accessor: "createdBy",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(18, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("TemplatesTable.editedDate")} key="editedDate" />,
            accessor: "editedDate",
            Cell: ({ cell: { value } }) =>
                value ? (
                    <DateCell tooltip={true} value={value} />
                ) : (
                    <TextWithTooltip text={t("TemplatesTable.notEdited")} />
                ),
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("TemplatesTable.editedBy")} key="editedBy" />,
            accessor: "editedBy",
            Cell: ({ cell: { value } }) =>
                value ? <TextWithTooltip text={value} /> : <TextWithTooltip text={t("TemplatesTable.notEdited")} />,
            width: deriveColumnWidth(20, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("TemplatesTable.default")} key="defaultReportTemplate.own" />,
            accessor: "defaultReportTemplate",
            id: "defaultReportTemplate.own",
            Cell: ({ cell: { value } }) =>
                value.own === true && (
                    <TextWithTooltip text={t("TemplatesTable.defaultTooltip")}>
                        <Star color={StarColor.GREEN} titleText={t("AltText.yourDefaultReportTemplate")} />
                    </TextWithTooltip>
                ),
            width: deriveColumnWidth(10, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("TemplatesTable.otherDefaults")} key="defaultTemplate.other" />,
            accessor: "defaultReportTemplate",
            id: "defaultReportTemplate.other",
            Cell: ({ cell: { value } }) => {
                const usernames = value.other.usernames;
                const suffix = usernames.length < value.other.count ? ",<br/>..." : "";
                return (
                    usernames.length > 0 && (
                        <TextWithTooltip
                            text={
                                t("TemplatesTable.otherDefaultsTooltip") + "<br/>" + usernames.join(",<br/>") + suffix
                            }
                            multiline={true}
                        >
                            <Star color={StarColor.GRAY} titleText={t("AltText.anotherDefaultReportTemplate")} />
                        </TextWithTooltip>
                    )
                );
            },
            width: deriveColumnWidth(12, tableContainerRef),
        },
    ];
    React.useEffect(() => {
        const abortController = new AbortController();
        fetchData(abortController);

        return () => {
            abortController.abort();
        };
    }, [props.count]);

    React.useEffect(() => {
        templateVisibility.modalVisibility && setDynamicSize(true);
    }, [templateVisibility.modalVisibility]);

    let dataCount = null;
    if (tableState.templateData.length > 0 && props.allLoaded) {
        dataCount = t("Common.defaultSearchResultHint", { dataCount: tableState.templateData.length });
    }

    return (
        <>
            <div className={style.paddingBottom}>{dataCount}</div>
            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.TEMPLATES_TABLE}
                    data={tableState.templateData}
                    columns={columns}
                    loaded={!initialLoading && props.allLoaded}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    emptyMessage={t("TemplatesTable.emptyStateMessage")}
                    data-testId={testIds.workArea.report.erasure.reportTemplate.table}
                />
            </div>
            <FlexibleModal
                key={1}
                isOpen={editTemplateModalVisible}
                hideModal={() => setEditTemplateModalVisible(false)}
                title={t("EditTemplate.title")}
                onToggleFullscreen={onToggleFullscreen}
                dynamicSize={dynamicSize}
                fullscreenIcon={true}
            >
                {editTemplateModalLoading ? (
                    <LoadingIndicator />
                ) : (
                    <ReportTemplateForm
                        templates={tableState.templateData}
                        submitEventHandler={onTemplateEditSubmitHandler}
                        onShowModal={onShowModal}
                        template={reportTemplate}
                        hasDynamicSize={manageDynamicSize}
                    />
                )}
            </FlexibleModal>
            <Modal isOpen={result.resultVisible} hideModal={hideResultAndRedirectToRoot} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                        onClick={hideResultAndRedirectToRoot}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
            <FlexibleModal
                key={1}
                isOpen={templateVisibility.modalVisibility}
                hideModal={hideShowTemplateModal}
                title={t("viewTemplate.title", { templateName: templateVisibility.templateName })}
                onToggleFullscreen={onToggleFullscreen}
                dynamicSize={dynamicSize}
                fullscreenIcon={true}
            >
                <ReportTemplateView
                    uuid={templateVisibility.uuid}
                    onCancel={hideShowTemplateModal}
                    onEdit={onViewTemplateEdit}
                    defaultTemplate={defaultTemplate}
                    hasDynamicSize={manageDynamicSize}
                />
            </FlexibleModal>
        </>
    );
};

export default ReportTemplatesTable;
