// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Hl6GugP9j12XeVXZQb3N5{height:100% !important;background-color:#fff;color:#000}._3Hl6GugP9j12XeVXZQb3N5 select{background-repeat:no-repeat !important;background-position:right center !important}._3Hl6GugP9j12XeVXZQb3N5 h2{font-size:24px !important}", ""]);
// Exports
exports.locals = {
	"editorContainer": "_3Hl6GugP9j12XeVXZQb3N5"
};
module.exports = exports;
