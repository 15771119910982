import {
    AggregatedDocumentCountData,
    AggregatedDocumentCountQuery,
    Context,
    DataAvailability,
    DataSet,
    Interval,
    Matcher,
    OverviewDashboardData,
    TimeRangeType,
    Type,
    Widget,
    WidgetType,
} from "domain/overviewDashboard";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

interface DashboardDto {
    version: number;
    widgets: WidgetDto[];
}

interface WidgetDto {
    slotIndex: number;
    uuid: string;
    name: string;
    description: string;
    type: WidgetType;
    width: number;
    height: number;
    aggregation_query: AggregatedDocumentCountQueryDto;
}

export interface AggregatedDocumentCountQueryDto {
    type: Type;
    interval?: Interval;
    time_range_type?: TimeRangeType;
    start_time: string;
    end_time: string;
    matchers: MatcherDto[];
    maximum_number_of_terms?: number;
    scope_matchers?: MatcherDto[];
}

interface MatcherDto {
    path: string;
    value?: string;
    context: Context;
    legend?: string;
}

function toMatcher(dto: MatcherDto): Matcher {
    return {
        path: dto.path,
        value: dto.value,
        context: dto.context,
        legend: dto.legend,
    };
}

export function toAggregationQuery(dto: AggregatedDocumentCountQueryDto): AggregatedDocumentCountQuery {
    return {
        type: dto.type,
        timeRangeType: dto.time_range_type ?? "STATIC",
        interval: dto.interval,
        startTime: dto.start_time,
        endTime: dto.end_time,
        matchers: dto.matchers.map(toMatcher),
        maximumNumberOfTerms: dto.maximum_number_of_terms,
        scopeMatchers: typeof dto.scope_matchers === "undefined" ? undefined : dto.scope_matchers.map(toMatcher),
    };
}

function toWidget(dto: WidgetDto): Widget {
    return {
        slotIndex: dto.slotIndex,
        uuid: dto.uuid,
        name: dto.name,
        description: dto.description,
        type: dto.type,
        x: 0,
        y: 0,
        width: dto.width,
        height: dto.height,
        aggregationQuery: toAggregationQuery(dto.aggregation_query),
    };
}

function toDashboard(dto: DashboardDto): OverviewDashboardData {
    return {
        version: dto.version,
        widgets: dto.widgets.map(toWidget),
    };
}

interface AggregatedDocumentCountDataDto {
    data_availability: DataAvailability;
    created: string;
    type: Type;
    interval?: Interval;
    time_range_type: string;
    start_time: string;
    end_time: string;
    labels: string[];
    data_sets: DataSet[];
}

function toAggregatedData(dto: AggregatedDocumentCountDataDto) {
    return {
        dataAvailability: dto.data_availability,
        created: dto.created,
        type: dto.type,
        interval: dto.interval,
        timeRangeType: dto.time_range_type as TimeRangeType,
        startTime: dto.start_time,
        endTime: dto.end_time,
        labels: dto.labels,
        dataSets: dto.data_sets,
    };
}

function toMatcherDto(matcher: Matcher): MatcherDto {
    return {
        path: matcher.path,
        value: matcher.value,
        context: matcher.context,
        legend: matcher.legend,
    };
}

function toAggregationQueryDto(query: AggregatedDocumentCountQuery) {
    return {
        type: query?.type,
        interval: query?.interval,
        time_range_type: query.timeRangeType,
        start_time: query?.startTime,
        end_time: query?.endTime,
        matchers: query?.matchers.map(toMatcherDto),
        maximum_number_of_terms: query?.maximumNumberOfTerms,
        scope_matchers: query?.scopeMatchers?.map(toMatcherDto),
    };
}

function toWidgetDto(widget: Widget): WidgetDto {
    return {
        slotIndex: widget.slotIndex,
        uuid: widget.uuid,
        name: widget.name,
        description: widget.description,
        width: widget.width,
        height: widget.height,
        type: widget.type,
        aggregation_query: toAggregationQueryDto(widget.aggregationQuery),
    };
}

function toDashboardDataDto(data: OverviewDashboardData): DashboardDto {
    return {
        version: data.version,
        widgets: data.widgets.map(toWidgetDto),
    };
}

class OverviewDashboardService {
    public fetchDashboardData(abortController: AbortController): Promise<OverviewDashboardData> {
        const identifier = "tenant";
        return apiGatewayService
            .invokeApi("/api/dashboards/" + identifier, "GET", null, { abortController })
            .then((response: DashboardDto) => {
                return toDashboard(response);
            });
    }

    public initializeOverviewDashboard(): Promise<OverviewDashboardData> {
        return apiGatewayService.invokeApi("/api/dashboards/tenant", "POST").then((data: DashboardDto) => {
            return toDashboard(data);
        });
    }

    public fetchAggregatedData(
        aggregationQuery: AggregatedDocumentCountQuery,
        abortController: AbortController
    ): Promise<AggregatedDocumentCountData> {
        return apiGatewayService
            .invokeApi("/api/aggregation/fetch", "POST", toAggregationQueryDto(aggregationQuery), {
                abortController,
                refreshSession: true,
                apiType: ApiType.OLIVER,
                includeCredentials: true,
            })
            .then((result: AggregatedDocumentCountDataDto) => {
                return toAggregatedData(result);
            });
    }

    public saveWidget(dashboardData: OverviewDashboardData, abortController: AbortController): Promise<void> {
        const identifier = "tenant";
        return apiGatewayService.invokeApi("/api/dashboards/" + identifier, "POST", toDashboardDataDto(dashboardData), {
            abortController,
        });
    }
}

export const overviewDashboardService = new OverviewDashboardService();
