import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Column } from "react-table";

import style from "./diagnostic-summary-view.scss";
import Info from "components/icons/Info";
import { Question } from "components/licenses/license-configuration/bms/BmsCommonInterfaces";
import { selectLanguageForConditionalQuestions } from "components/licenses/license-configuration/bms/bmsUtils";
import questionsData from "components/licenses/license-configuration/bms/UiConfiguration.json";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { TOOLTIP_DELAY } from "domain/globalConstants";
import {
    ConditionalQuestions,
    ConditionalQuestionsResults,
    DiagnosticReportResponse,
    DiagnosticSummary,
    TestResult,
} from "domain/reports";
import * as LanguageRepository from "services/language/languageRepository";
import { licenseService } from "services/licenses/LicenseService";
import { StoreState } from "store";
import layoutStyle from "styles/layout.scss";
import { formatTimestamp } from "utils/format";
import { logger } from "utils/logging";
import { RepositoryKey } from "utils/repository";

import checkIcon from "assets/images/icons/checkMarkInCircle.svg";
import crossIcon from "assets/images/icons/cross.svg";
import indeterminateIcon from "assets/images/icons/indeterminate.svg";
import skippedIcon from "assets/images/icons/skipped.svg";

import testIds from "testIds.json";

type QuestionsData = {
    [key: string]: Question[];
};

export function DiagnosticSummaryView(props: {
    diagnosticReportResponse: DiagnosticReportResponse;
    feature: string;
}): JSX.Element {
    const { t } = useTranslation();
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
    const [initialQuestionsLoading, setInitialQuestionsLoading] = React.useState<boolean>(true);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [questionsRequestFailureMessage, setQuestionsRequestFailureMessage] = React.useState<string>("");
    const [diagData, setData] = React.useState<DiagnosticReportResponse>({
        deviceDetail: {},
        diagnosticSummary: [],
        conditionalQuestions: [],
        buybackTradein: {},
        screenAnalysis: { fraudRisk: "", mirrorTest: [] },
        insurance: {},
        ntf: { results: [], otherDetails: { faultFound: false, referenceCode: "", store: "" } },
    });
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const selectedLanguage = selectLanguageForConditionalQuestions(LanguageRepository.getLanguage().locale);
    const [defaultQuestions, setDefaultQuestions] = React.useState<QuestionsData>(questionsData.questions);

    const fetchFeatureQuestions = async (uuid: string, feature: string): Promise<QuestionsData> => {
        if (uuid !== "") {
            const abortController = new AbortController();
            let featureQuestions: QuestionsData;

            try {
                const data = await licenseService.fetchClientConfigurationData(uuid, abortController);
                switch (feature) {
                    case "bbti":
                        featureQuestions = data.bbtiConfiguration.providers[0].questions as QuestionsData;
                        break;
                    case "ntf":
                        featureQuestions = data.ntfConfiguration.providers[0].questions as QuestionsData;
                        break;
                    case "lease":
                        featureQuestions = data.leaseConfiguration.provider.questions as QuestionsData;
                        break;
                    case "insurance":
                        featureQuestions = data.insuranceConfiguration.provider.questions as QuestionsData;
                        break;
                    case "validation":
                        featureQuestions = data.validationConfiguration.provider.questions as QuestionsData;
                        break;
                    default:
                        featureQuestions = questionsData.questions as QuestionsData;
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    logger.error("Failed to load default configuration:", error);
                }
                featureQuestions = questionsData.questions as QuestionsData;
            }

            return featureQuestions;
        } else {
            return questionsData.questions as QuestionsData;
        }
    };

    async function getReportQuestions() {
        try {
            const abortController = new AbortController();
            const configuration = await licenseService.fetchConfigurationData(abortController);
            const qs = await fetchFeatureQuestions(configuration.configuration[0].uuid, props.feature);
            setDefaultQuestions(qs);
            setInitialQuestionsLoading(false);
        } catch {
            setQuestionsRequestFailureMessage(t("DiagnosticReportsTable.diagnosticSummary.requestFailed"));
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        setData(props.diagnosticReportResponse);
        if (!props.diagnosticReportResponse || !props.diagnosticReportResponse.diagnosticSummary) {
            setRequestFailureMessage(t("DiagnosticReportsTable.diagnosticSummary.requestFailed"));
        }

        setInitialLoading(false);

        return () => {
            abortController.abort();
        };
    }, [props.diagnosticReportResponse]);

    React.useEffect(() => {
        const abortController = new AbortController();
        getReportQuestions();
        return () => {
            abortController.abort();
        };
    }, []);

    const getIcon = (result: string): JSX.Element => {
        const icons = new Map<string, string>([
            [TestResult.PASSED, checkIcon],
            [TestResult.FAILED, crossIcon],
            [TestResult.SKIPPED, skippedIcon],
            [TestResult.INDETERMINATE, indeterminateIcon],
        ]);
        const icon = icons.get(result);
        return icon ? (
            <img
                src={icon}
                data-tip={t("DiagnosticReportsTable.diagnosticSummary." + result.toLowerCase())}
                data-delay-show={TOOLTIP_DELAY}
                alt={t("AltText.diagnosticSummary." + result.toLowerCase())}
            />
        ) : (
            <></>
        );
    };

    const getReasonCodeDescription = (reasonCode: string) => {
        const { t } = useTranslation("diagnosticReasonCodes");
        const translationKey = "reasonCodes." + reasonCode;
        const defaultTranslationKey = "reasonCodes.unknown";

        const translatedDescription = t(translationKey);
        const defaultDescription = t(defaultTranslationKey);

        return translatedDescription !== translationKey ? translatedDescription : defaultDescription;
    };

    const getInfo = (row: DiagnosticSummary): JSX.Element => {
        const totalAttempts = row.pass + row.fail + row.skip + row.indeterminate;
        const reasonCodeText =
            row.reasonCode && (row.latestResult == TestResult.FAILED || row.latestResult == TestResult.INDETERMINATE)
                ? t("DiagnosticReportsTable.diagnosticSummary.infoTooltipWithReasonCode", {
                      result: row.latestResult,
                      reasonCode: row.reasonCode,
                      reasonCodeDescription: getReasonCodeDescription(row.reasonCode),
                      latestTestedDate: formatTimestamp(row.lastAttemptDate),
                      totalAttempts,
                      break: "<br />",
                  })
                : t("DiagnosticReportsTable.diagnosticSummary.infoTooltip", {
                      result: row.latestResult,
                      latestTestedDate: formatTimestamp(row.lastAttemptDate),
                      totalAttempts,
                      break: "<br />",
                  });

        return (
            <div className={style.info}>
                <TextWithTooltip text={reasonCodeText} focusable={true}>
                    <Info borderColor={theme.contentBackgroundColor} color={theme.iconFillColor} />
                </TextWithTooltip>
            </div>
        );
    };

    const conditionalQuestionsColumns: Array<Column<ConditionalQuestionsResults>> = [
        {
            accessor: "question",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(85, tableContainerRef),
        },
        {
            accessor: "answer",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(15, tableContainerRef),
        },
    ];

    const columns: Array<Column<DiagnosticSummary>> = [
        {
            Header: [<TextWithTooltip text={t("DiagnosticReportsTable.diagnosticSummary.identifier")} key="1" />],
            accessor: "test",
            Cell: ({ row }) => {
                return (
                    <div className={style.identifer}>
                        <div className={style.icon}>{getIcon(row.original.latestResult)}</div>
                        <div className={style.title}>
                            <TextWithTooltip text={row.original.test} />
                        </div>
                    </div>
                );
            },
            width: deriveColumnWidth(28, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.passed")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.passed")}
                        </div>
                    }
                    key="2"
                />,
            ],
            accessor: "pass",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.failed")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.failed")}
                        </div>
                    }
                    key="3"
                />,
            ],
            accessor: "fail",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.skipped")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.skipped")}
                        </div>
                    }
                    key="4"
                />,
            ],
            accessor: "skip",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.indeterminate")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.indeterminate")}
                        </div>
                    }
                    key="5"
                />,
            ],
            accessor: "indeterminate",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(18, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.info")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.info")}
                        </div>
                    }
                    key="5"
                />,
            ],
            accessor: "latestResult",
            Cell: ({ row }) => getInfo(row.original),
            width: deriveColumnWidth(10, tableContainerRef),
        },
    ];

    function processConditionalQuestionsResults(conditionalQuestions: ConditionalQuestions[]) {
        const questionMap: Map<string, { answer: string; reportDate: string | Date }> = new Map();

        if (conditionalQuestions) {
            const sortedQuestions = conditionalQuestions.sort(
                (a, b) => new Date(b.report_date).getTime() - new Date(a.report_date).getTime()
            );

            sortedQuestions.forEach((entry) => {
                entry.results.forEach((result) => {
                    const { question, answer } = result;
                    const reportDate = new Date(entry.report_date);

                    const selectedQuestionWithLanguage = defaultQuestions[selectedLanguage].find(
                        (questionForLanguage) => questionForLanguage.id === question
                    );

                    const questionLabel = selectedQuestionWithLanguage?.label || question;
                    const selectedAnswerWithLanguage = selectedQuestionWithLanguage?.answers.find(
                        (answerForLanguage) => answerForLanguage.id === answer
                    );

                    const answerLabel = selectedAnswerWithLanguage?.label || answer;

                    const existingEntry = questionMap.get(questionLabel);
                    if (!existingEntry || reportDate > new Date(existingEntry.reportDate)) {
                        questionMap.set(questionLabel, {
                            answer: answerLabel,
                            reportDate: entry.report_date,
                        });
                    }
                });
            });
        }

        return Array.from(questionMap, ([question, { answer }]) => ({ question, answer }));
    }

    return (
        <div>
            <div ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.DIAGNOSTIC_SUMMARY_TABLE}
                    data={diagData.diagnosticSummary}
                    columns={columns}
                    failureMessage={requestFailureMessage}
                    loaded={!initialLoading}
                    tooltips={true}
                    emptyMessage={t("DiagnosticReportsTable.diagnosticSummary.emptyStateMessage")}
                    testId={testIds.workArea.report.erasure.table}
                />
            </div>

            <div className={classNames(style.conditionalQuestionsTable, layoutStyle.tableWrapper)}>
                <h2>
                    <b>Conditional Questions</b>
                </h2>
                <Table
                    tableIdentity={RepositoryKey.CONDITIONAL_SUMMARY_TABLE}
                    data={processConditionalQuestionsResults(diagData.conditionalQuestions)}
                    columns={conditionalQuestionsColumns}
                    failureMessage={questionsRequestFailureMessage}
                    loaded={!initialQuestionsLoading}
                    tooltips={false}
                    header={false}
                    inDialogBox={true}
                    dialogHeight={350}
                    emptyMessage={t("DiagnosticReportsTable.diagnosticSummary.emptyStateMessage")}
                    noAction={true}
                />
            </div>
        </div>
    );
}

DiagnosticSummaryView.defaultProps = {
    initialSummaryData: [],
};
