import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import Button from "components/button/Button";
import Modal from "components/modal/Modal";
import style from "components/reports/export/export-diagnostic-report.scss";
import SingleReportExportDialog, { Values } from "components/reports/SingleReportExportDialog";
import { getLanguage } from "services/language/languageRepository";
import { reportService } from "services/report/erasure/ReportService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import { logger } from "utils/logging";

import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const ExportDiagnosticReport = (props: { reportUuid: string } & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [visible, setVisible] = React.useState<boolean>(false);
    const exportType = "csv";
    const hide = () => {
        setVisible(false);
    };
    const languageCode = getLanguage().code;

    const exportDiagnosticReport = () => {
        usageStatisticsService.sendEvent({
            category: Category.REPORTS,
            action: Action.EXPORT_DIAGNOSTIC_REPORT,
        });
        setVisible(true);
    };

    const submitEventHandler = async ({ filenameColumns, separator }: Values) => {
        try {
            await reportService.exportSingleDiagnosticReport(props.reportUuid, filenameColumns, separator);
            setVisible(false);
        } catch (e) {
            logger.error(e);
            return;
        } finally {
            setVisible(false);
        }
    };

    return (
        <>
            <div className={style.exportButton}>
                <Button
                    variant="TERTIARY"
                    icon="EXPORT"
                    testId={testIds.workArea.report.diagnostic.reportDialog.exportButton}
                    onClick={exportDiagnosticReport}
                >
                    {t("DiagnosticReportsTable.exportReport.title")}
                </Button>
            </div>
            <Modal
                isOpen={visible}
                hideModal={hide}
                modalTitle={t("ErasureReport.exportTypeTitle", { reportType: exportType })}
            >
                <SingleReportExportDialog
                    reportUuid={props.reportUuid}
                    columns={undefined}
                    isAllReportsView={false}
                    handle={submitEventHandler}
                    language={languageCode}
                    templateUuid={""}
                    formatType={exportType}
                    onShowModal={setVisible}
                />
            </Modal>
        </>
    );
};

export default connector(ExportDiagnosticReport);
