import * as React from "react";

const QuestionMark = (props: { color: string }): JSX.Element => {
    return (
        <svg id="quick_checks_icons" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 20 20">
            <circle id="Ellipse_92" data-name="Ellipse 92" cx="10" cy="10" r="10" fill={props.color} />
            <g id="Group_4153" data-name="Group 4153" transform="translate(-264.722 -1267.788)">
                <path
                    id="Path_994"
                    data-name="Path 994"
                    d="M118.4,11.724a2.514,2.514,0,0,1,2.276-1.631,2.442,2.442,0,0,1,2.712,2.175,1.941,1.941,0,0,1-.726,1.829,15.764,15.764,0,0,0-1.259,1.137,3.138,3.138,0,0,0-.387.544,1.3,1.3,0,0,0-.145.346,1.723,1.723,0,0,0-.048.84"
                    transform="translate(153.822 1262.702)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_95"
                    data-name="Ellipse 95"
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(273.722 1281.787)"
                    fill="#fff"
                    stroke="#fff"
                    strokeWidth="0.5"
                />
            </g>
        </svg>
    );
};

export default QuestionMark;
