import { TableState } from "react-table";

import { getObject, RepositoryKey, setObject } from "utils/repository";

const DEFAULT_TABLE_STATE = { columnResizing: { columnWidths: {} }, hiddenColumns: [] };

export function getTableState(key: RepositoryKey): Record<string, unknown> {
    return getObject(key) || DEFAULT_TABLE_STATE;
}

export function setTableState(tableState: TableState, key: RepositoryKey): void {
    setObject(key, tableState);
}

export function getReportUuidsToBeDeleted(): string[] {
    return getObject(RepositoryKey.REPORTS_TO_BE_DELETED) || [];
}

export function setReportUuidsToBeDeleted(selectedUuids: string[]): void {
    setObject(RepositoryKey.REPORTS_TO_BE_DELETED, Array.from(new Set(selectedUuids)));
}
