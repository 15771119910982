import { EditorGeneration, Profile } from "./WorkflowService";
import { ConnectedWorkflowsDto } from "domain/workflows";
import { TemplateTableData } from "domain/workflowTemplates";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

export enum PublishStatuses {
    PUBLISHED = "Published",
    UNPUBLISHED = "Unpublished",
}

const PATH_API_TEMPLATES_V1 = "/api/v1/workflow-templates/fetch";
export interface WorkflowTemplatesDto {
    name: string;
    uuid: string;
    profile: string;
    created: string;
    user_uuid: string;
    user_name: string;
    templates: string;
    description: string;
    own: boolean;
    modified: string;
    modified_by_user_uuid: string;
    modified_by_username: string;
    editor_version: string;
    editor_generation: EditorGeneration;
    published: boolean;
    usage_count: number;
    connected_workflows: ConnectedWorkflowsDto;
}

export interface workflowTemplatesResponseDto {
    workflows: WorkflowTemplatesDto[];
    count: number;
    cursor: string;
}
export function toWorkflowTemplate(dto: WorkflowTemplatesDto): TemplateTableData {
    return {
        name: dto.name,
        uuid: dto.uuid,
        profile: dto.profile.toLowerCase() as Profile,
        created: dto.created,
        userUuid: dto.user_uuid,
        creator: dto.user_name,
        description: dto.description,
        own: dto.own,
        editorVersion: dto.editor_version,
        editorGeneration: dto.editor_generation,
        published: dto.published,
        usageCount: dto.usage_count,
        modified: dto.modified,
        userName: dto.user_name,
        connectedWorkflows: dto.connected_workflows
            ? {
                  parentWorkflows: dto.connected_workflows.parent_workflows,
                  subWorkflows: dto.connected_workflows.sub_workflows,
                  runDiagnoseWorkflows: dto.connected_workflows.run_diagnose_workflows,
                  runProcessFlowWorkflows: dto.connected_workflows.run_process_flow_workflows,
                  collectWorkflows: dto.connected_workflows.collect_workflows,
                  gradingPanelWorkflows: dto.connected_workflows.grading_panel_workflows,
                  remoteWorkflows: dto.connected_workflows.remote_workflow_count,
                  totalConnectedWorkflows:
                      dto.connected_workflows.parent_workflows.length +
                      dto.connected_workflows.sub_workflows.length +
                      dto.connected_workflows.run_diagnose_workflows.length +
                      dto.connected_workflows.run_process_flow_workflows.length +
                      dto.connected_workflows.collect_workflows.length +
                      dto.connected_workflows.grading_panel_workflows.length +
                      dto.connected_workflows.remote_workflow_count,
              }
            : {
                  parentWorkflows: [],
                  subWorkflows: [],
                  runDiagnoseWorkflows: [],
                  runProcessFlowWorkflows: [],
                  collectWorkflows: [],
                  gradingPanelWorkflows: [],
                  remoteWorkflows: 0,
                  totalConnectedWorkflows: 0,
              },
    };
}

export class TemplatesService {
    public async fetchWorkflowTemplates(
        search?: string,
        cursor?: string,
        product?: string,
        publishStatus?: string,
        version?: string,
        from?: string,
        to?: string,
        subWorkflows?: boolean,
        abortController?: AbortController
    ) {
        return apiGatewayService
            .invokeApi(
                PATH_API_TEMPLATES_V1,
                "POST",
                {
                    search: search,
                    cursor: cursor,
                    product: product,
                    published: publishStatus,
                    version: version,
                    from: from,
                    to: to,
                    subWorkflows: subWorkflows,
                },
                {
                    abortController: abortController,
                    refreshSession: true,
                    apiType: ApiType.OLIVER,
                    includeCredentials: true,
                }
            )
            .then((dto: workflowTemplatesResponseDto) => {
                return {
                    count: dto.count,
                    cursor: dto.cursor,
                    workflowTemplatesTableData: dto.workflows.map(toWorkflowTemplate),
                };
            });
    }
}

export const templatesService = new TemplatesService();
