import * as React from "react";

interface Props {
    color: string;
    width?: number;
    height?: number;
}

const Confirmation = (props: Props): JSX.Element => {
    return (
        <svg
            id="Confirmation_Icon_medium"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : "45"}
            height={props.height ? props.height : "45"}
            viewBox="0 0 45 45"
        >
            <g id="Group_4944" data-name="Group 4944" transform="translate(3 -3)">
                <circle
                    id="Ellipse_288"
                    data-name="Ellipse 288"
                    cx="19.5"
                    cy="19.5"
                    r="19.5"
                    transform="translate(0 6)"
                    fill={props.color}
                />
                <path
                    id="check"
                    d="M24.421,6,10.382,20.04,4,13.658"
                    transform="translate(5.5 13.5)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                />
            </g>
            <rect id="Rectangle_2416" data-name="Rectangle 2416" width="45" height="45" fill="none" />
        </svg>
    );
};

export default Confirmation;
