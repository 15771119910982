import * as React from "react";

const Search = (props: { color: string }): JSX.Element => {
    return (
        <svg
            id="search_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="13.561"
            height="13.561"
            viewBox="0 0 13.561 13.561"
        >
            <g id="Ellipse_221" data-name="Ellipse 221" fill="none" stroke={props.color} strokeWidth="1.5">
                <circle cx="5" cy="5" r="5" stroke="none" />
                <circle cx="5" cy="5" r="4.25" fill="none" />
            </g>
            <line
                id="Line_756"
                data-name="Line 756"
                x2="4"
                y2="4"
                transform="translate(8.5 8.5)"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                strokeWidth="1.5"
            />
        </svg>
    );
};

export default Search;
