import { TFunction } from "i18next";

export function createImportFailureReasonLocalizations(t: TFunction): Map<string, string> {
    return new Map([
        ["INDEXING_FAILED", t("Notification.reportImportResultDialog.failureReasons.indexingFailed")],
        ["INSUFFICIENT_LICENSES", t("Notification.reportImportResultDialog.failureReasons.insufficientLicenses")],
        ["PATH_EXTRACTION_FAILED", t("Notification.reportImportResultDialog.failureReasons.pathExtractionFailed")],
        ["TOO_MANY_ENTRIES", t("Notification.reportImportResultDialog.failureReasons.tooManyEntries")],
        ["TOO_OLD", t("Notification.reportImportResultDialog.failureReasons.tooOld")],
        ["UNAUTHORIZED", t("Notification.reportImportResultDialog.failureReasons.unauthorized")],
        ["VALIDATION_FAILED", t("Notification.reportImportResultDialog.failureReasons.validationFailed")],
        ["VERIFICATION_FAILED", t("Notification.reportImportResultDialog.failureReasons.verificationFailed")],
    ]);
}
