import { Namespace, UseTranslationResponse } from "react-i18next";

const CUSTOM_FIELD_PATH_PREFIX = "user_data.fields.";
const REPORT_PREFIX = "report.";

export function extractLastPathPart(path: string): string {
    if (path == null || path.length === 0) {
        throw Error("Path can't be null or empty");
    }
    if (path.startsWith(CUSTOM_FIELD_PATH_PREFIX)) {
        return path.substring(CUSTOM_FIELD_PATH_PREFIX.length).replace("_", " ");
    }
    const pieces = path.split(".");
    if (pieces.length === 0) {
        throw Error("Path doesn't contain any periods: " + path);
    }
    const last = pieces.filter((each) => each !== "").pop();
    if (last == null) {
        throw Error("Invalid path: " + path);
    }
    return last.replace("_", " ");
}

export function capitalize(value: string): string {
    if (value == null || value.length === 0) {
        return value;
    }
    return value.substring(0, 1).toUpperCase() + value.substring(1);
}

export function createTranslatePath(
    createTranslator: (namespace: Namespace) => UseTranslationResponse<string>
): (path: string) => string {
    const reportPaths = "reportPaths";
    const translator = createTranslator(reportPaths);
    return (path: string) => {
        if (path.startsWith(REPORT_PREFIX)) {
            path = path.substring(REPORT_PREFIX.length);
        }
        const translationKey = reportPaths + "." + path.replace(/\./g, "_");
        const translated = translator.t(translationKey, "");
        return translated !== "" ? translated : capitalize(extractLastPathPart(path));
    };
}
