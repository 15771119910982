import * as React from "react";

const UpArrow = (props: { color: string; className?: string }): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8.783"
            height="16"
            viewBox="0 0 8.783 16"
            className={props.className}
        >
            <g id="arrow-up" transform="translate(1.414 1)">
                <line
                    id="Line_816"
                    data-name="Line 816"
                    y1="14"
                    transform="translate(3)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_4235"
                    data-name="Path 4235"
                    d="M10.954,14.977,7.977,12,5,14.977"
                    transform="translate(-5 -12)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};

export default UpArrow;
