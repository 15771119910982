import * as React from "react";

const Resend = (props: { color: string }): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35.818" height="48.818" viewBox="0 0 48.818 48.818">
            <defs>
                <filter id="Path_1173" x="0" y="0" width="47" height="47" filterUnits="userSpaceOnUse">
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="Group_7930" data-name="Group 7930" transform="translate(-2060.5 8632.5)">
                <g transform="matrix(1, 0, 0, 1, 2060.5, -8632.5)" filter="url(#Path_1173)">
                    <path
                        id="Path_1173-2"
                        data-name="Path 1173"
                        d="M171.86,212.13a19,19,0,1,1-19,19,19,19,0,0,1,19-19Z"
                        transform="translate(-148.36 -209.63)"
                        fill={props.color}
                    />
                </g>
                <g id="Send" transform="translate(2075.5 -8620)">
                    <g
                        id="Rectangle_3135"
                        data-name="Rectangle 3135"
                        transform="translate(0 3)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="2"
                    >
                        <rect width="17" height="12" stroke="none" />
                        <rect x="1" y="1" width="15" height="10" fill="none" />
                    </g>
                    <g id="Polygon_10" data-name="Polygon 10" transform="translate(17 11) rotate(180)" fill="none">
                        <path d="M8.5,0,17,8H0Z" stroke="none" />
                        <path
                            d="M 8.5 2.746499061584473 L 5.043154716491699 6 L 11.95684432983398 6 L 8.5 2.746499061584473 M 8.5 0 L 17 8 L 0 8 L 8.5 0 Z"
                            stroke="none"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Resend;
