import React from "react";
import { useTranslation } from "react-i18next";

import style from "./singleValueWidget.scss";

const SingleValueWidget = (props: { value: number | undefined }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={style.singleValue}>
            {props.value !== undefined ? (
                <div className={style.value}>{props.value}</div>
            ) : (
                <div>{t("Common.noDataToDisplay")}</div>
            )}
        </div>
    );
};

export default SingleValueWidget;
