import { DashboardData, EsgData } from "domain/dashboard";
import { ESG_DATA_VERSION } from "domain/globalConstants";
import { apiGatewayService } from "services/api/ApiGatewayService";

class DashboardService {
    public fetchDashboardData(abortController: AbortController): Promise<EsgData> {
        return apiGatewayService.invokeApi("/esg/global/" + ESG_DATA_VERSION, "GET", null, { abortController });
    }

    public fetchCustomerDashboardData(abortController: AbortController): Promise<EsgData> {
        return apiGatewayService.invokeApi("/esg/tenant/" + ESG_DATA_VERSION, "GET", null, { abortController });
    }

    public fetchCalculatorConfiguration(abortController: AbortController): Promise<DashboardData> {
        return apiGatewayService.invokeApi("/esg/calculator-configuration", "GET", null, { abortController });
    }
}

export const dashboardService = new DashboardService();
