import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import style from "./group-condition.scss";
import StaticTable from "components/support/api-guide/StaticTable";
import Heading from "components/typography/heading/Heading";

interface Props {
    tableCells: JSX.Element[][];
    headerButtons: JSX.Element;
    footerButtons?: JSX.Element;
    firstGroup: boolean;
    depth: number;
    logicalOperator: string;
}

export const GroupCondition = (props: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <div
                className={classNames(style.conditionGroupTable)}
                data-nested={props.depth > 1}
                data-depth={props.depth}
            >
                <div>
                    {props.depth > 0 && (
                        <Heading tag="div" variant="SUBTITLE_2" className={style.darkerBorderColor} underline>
                            <div className={style.header}>
                                {props.logicalOperator === "AND"
                                    ? t("AdvancedSearch.groupConditionTitleAnd")
                                    : t("AdvancedSearch.groupConditionTitleOr")}
                                <div className={style.buttonContainer}>{props.headerButtons}</div>
                            </div>
                        </Heading>
                    )}
                </div>
                <StaticTable headers={[]} cells={props.tableCells} tableClass={style.table} />
                {props.footerButtons && <div>{props.footerButtons}</div>}
            </div>
        </>
    );
};
