import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import GenerateApiKeyView from "./generate-api-key/GenerateApiKeyView";
import { MyApiKeysTable } from "./MyApiKeysTable";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { SUPPORT_ROUTE } from "components/router/Routes";
import { AUTH_API_KEY_CREATE_OWN } from "domain/authority";
import { userSessionService } from "services/user/UserSessionService";

export default function MyApiKeysView(): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = useState({ count: 0 });

    function onApiKeyDelete() {
        return setState((prevState) => ({ count: prevState.count - 1 }));
    }

    function onAppend() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    const viewActions = userSessionService.userHasAllAuthorities([AUTH_API_KEY_CREATE_OWN]) ? (
        <GenerateApiKeyView onApiKeyGenerated={onAppend} />
    ) : undefined;

    return (
        <ApplicationLayout
            viewTitle={t("Common.myApiKeys")}
            view={
                <MyApiKeysTable
                    initialApiKeyData={{ api_keys: [], cursor: null }}
                    count={state.count}
                    onApiKeyDelete={onApiKeyDelete}
                    onApiKeyEdit={onAppend}
                />
            }
            mainRoute={SUPPORT_ROUTE}
            viewActions={viewActions}
        />
    );
}
