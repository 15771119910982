import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import Cross from "components/icons/Cross";
import SearchIcon from "components/icons/Search";
import searchView from "components/search/search-view.scss";
import { StoreState } from "store";
import formStyle from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    setSearch: (searchQuery: string) => void;
    search: string;
    searchInProgress: boolean;
    keypressEvent: boolean;
    setSearchOnKeyPress: (setSearchOnKeyPress: string) => void;
    tooltip?: string;
    placeholder?: string;
    inputKey: string;
    inputRef?: React.RefObject<HTMLInputElement>;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const LocalSearch = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const searchRef = React.useRef<HTMLInputElement>(null);
    const searchInputMaxLength = 36;
    const [clearButtonVisibility, setClearButtonVisibility] = React.useState<boolean>(false);
    return (
        <>
            <div className={formStyle.search}>
                <div className={searchView.inputWithButton}>
                    <div className={searchView.searchIcon}>
                        <SearchIcon color={props.theme.iconFillColor} />
                    </div>
                    <input
                        key={props.inputKey}
                        id={props.inputKey + "_search"}
                        type="text"
                        maxLength={searchInputMaxLength}
                        value={props.search}
                        autoComplete="off"
                        placeholder={props.placeholder ? t(props.placeholder) : t("Common.search")}
                        disabled={props.searchInProgress}
                        onChange={(e) => props.setSearch(e.target.value)}
                        data-testid={testIds.common.searchInput.itself}
                        onInput={(e) => {
                            if (e.currentTarget.value.length < 1) {
                                setClearButtonVisibility(false);
                            } else {
                                setClearButtonVisibility(true);
                            }
                        }}
                        ref={props.inputRef ? props.inputRef : searchRef}
                        data-tip={props.tooltip}
                        onKeyPress={(e) => {
                            if (e.key === "Enter" && !props.searchInProgress) {
                                e.preventDefault();
                                if (props.keypressEvent) {
                                    props.setSearchOnKeyPress(props.search);
                                }
                                setClearButtonVisibility(props.search != "" ? true : false);
                            }
                        }}
                    />
                    <div
                        data-testid={testIds.common.searchInput.clearButton}
                        className={classNames(
                            clearButtonVisibility ? searchView.clearButton : searchView.hideClearButton,
                            props.searchInProgress ? searchView.disabledClearButton : ""
                        )}
                        onClick={() => {
                            props.setSearch("");
                            setClearButtonVisibility(false);
                            props.inputRef ? props.inputRef.current?.focus() : searchRef.current?.focus();
                        }}
                    >
                        <Cross color={props.theme.secondaryButtonBackgroundColor} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default connector(LocalSearch);
