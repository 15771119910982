import * as React from "react";

const StarWhiteIcon = (props: { color: string }): JSX.Element => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9996 3.25C12.2903 3.25005 12.5548 3.4181 12.6784 3.68127L14.981 8.58584L20.1144 9.37825C20.3921 9.4211 20.6225 9.61554 20.7114 9.88202C20.8004 10.1485 20.7329 10.4424 20.5367 10.6434L16.8014 14.4695L17.6834 19.8793C17.7296 20.1628 17.6099 20.4478 17.375 20.6132C17.1402 20.7786 16.8316 20.7953 16.5802 20.6563L11.9995 18.1232L7.41877 20.6563C7.16741 20.7953 6.85874 20.7786 6.62392 20.6132C6.38909 20.4478 6.26936 20.1628 6.3156 19.8793L7.19858 14.4652L3.46335 10.6391C3.2671 10.4381 3.19966 10.1443 3.28859 9.87777C3.37753 9.61128 3.60794 9.41685 3.88558 9.37398L9.0181 8.58157L11.3207 3.68105C11.4443 3.41793 11.7089 3.24995 11.9996 3.25ZM11.9992 5.76421L10.2059 9.58081C10.0998 9.80671 9.88822 9.965 9.64155 10.0031L5.55469 10.6341L8.53642 13.6884C8.70276 13.8587 8.7783 14.098 8.73997 14.333L8.04469 18.5961L11.6365 16.6098C11.8624 16.4849 12.1366 16.4849 12.3624 16.6098L15.9544 18.5962L15.2601 14.3372C15.2217 14.1022 15.2973 13.863 15.4636 13.6926L18.4453 10.6384L14.3574 10.0074C14.1107 9.96926 13.8991 9.81088 13.793 9.58487L11.9992 5.76421Z"
            fill={props.color}
        />
    </svg>
);

export default StarWhiteIcon;
