import classNames from "classnames";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { object, string, StringSchema } from "yup";

import style from "./add-license-delivery.scss";
import { isAmountAvailable, isExpirationDateValid } from "components/licenses/common";
import DeliveryFormContent, {
    createDefaultSelectedLicense,
    DeliveryForm,
    isConfirmationDialogVisible,
    License,
    MAX_CASE_NUMBER_LENGTH,
    MAX_OPPORTUNITY_ID_LENGTH,
    validateParameters,
} from "components/licenses/delivery-history/DeliveryFormContent";
import LicenseSelectionForm from "components/licenses/delivery-history/LicenseSelectionForm";
import { LicenseToDeliver } from "domain/licenses";
import { CombinedTier, LicensingModel } from "domain/tenants";
import { extractLatestLicenseRateUuid, ProductToRateList } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { getMaxDate, hasOnlyNumbers } from "utils/commonFunctions";
import { formatDateWithoutTime, formatExpirationDate, formatUtcDateString, MONTHS } from "utils/format";

import testIds from "testIds.json";

const DEFAULT_SELECT_DELIVERY_TYPE = "delivery_type";

interface Props {
    submitEventHandler: (values: DeliveryForm, selectedLicenses: LicenseToDeliver[]) => Promise<void>;
    availableLicenses: License[];
    ownedLicenses: License[];
    preselectedFromBundle: boolean;
    licensingModel: LicensingModel;
    rateVersions: ProductToRateList[];
    tenantTier: CombinedTier;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const AddLicenseDeliveryForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [selectedLicenses, setSelectedLicenses] = React.useState<License[]>(
        props.ownedLicenses.length > 0 ? props.ownedLicenses : [createDefaultSelectedLicense(t)]
    );
    const [hiddenForm, setHiddenForm] = React.useState(false);
    const [formState, setFormState] = React.useState<DeliveryForm>();
    const submitHandler: FormikConfig<DeliveryForm>["onSubmit"] = async () => {
        // do nothing
    };
    const DEFAULT_SELECTION_VALUE = "DEFAULT";

    const lastDate = () => {
        const maxDate = getMaxDate();
        return maxDate.getDate().toString() + " " + MONTHS[maxDate.getMonth()] + " " + maxDate.getFullYear();
    };

    const isAddButtonDisabled = () => {
        return (
            selectedLicenses.length < 1 ||
            selectedLicenses.some(
                (item) =>
                    isNaN(item.licensesToAdd) ||
                    item.totalOfLicenses < 0 ||
                    !isExpirationDateValid(
                        item.expirationDate,
                        item.parentLicenseExpirationDate ? new Date(item.parentLicenseExpirationDate) : lastDate()
                    ) ||
                    item.licenseType === "default" ||
                    !isAmountAvailable(item)
            )
        );
    };

    const createConfirmationDialog = () => {
        const createdLicenses: License[] = [];
        const removedLicenses: License[] = [];
        selectedLicenses.forEach((each) => {
            if (each.licensesToAdd > 0) {
                createdLicenses.push(each);
            } else {
                removedLicenses.push(each);
            }
        });
        return (
            <div>
                {createdLicenses.length > 0 ? (
                    <div className={form.resultContainer}>
                        {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.adding")}
                        <ul>
                            {createdLicenses.map((each) => {
                                return (
                                    <li key={each.licenseType}>
                                        {t("DeliveryHistory.addLicenseDelivery.license", {
                                            amount: each.licensesToAdd,
                                            productName: each.productName,
                                            expirationDate: formatDateWithoutTime(each.expirationDate),
                                        })}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : (
                    ""
                )}
                {removedLicenses.length > 0 ? (
                    <div className={form.resultContainer}>
                        {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.removing")}
                        <ul>
                            {removedLicenses.map((each) => {
                                return (
                                    <li key={each.licenseType}>
                                        {t("DeliveryHistory.addLicenseDelivery.license", {
                                            amount: Math.abs(each.licensesToAdd),
                                            productName: each.productName,
                                            expirationDate: formatDateWithoutTime(each.expirationDate),
                                        })}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : (
                    ""
                )}
                <div className={form.resultContainer}>
                    {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.confirmation")}
                </div>
                <div className={style.float}>
                    <button
                        type={"button"}
                        className={classNames(
                            buttons.primaryButton,
                            buttons.secondaryButton,
                            buttons.medium,
                            form.submitButton
                        )}
                        onClick={() => {
                            setHiddenForm(false);
                        }}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.keepEditing")}
                    </button>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                        onClick={() => {
                            usageStatisticsService.sendEvent({
                                category: Category.LICENSE_DELIVERY,
                                action: Action.ADD_LICENSE_DELIVERY,
                            });

                            const licensesToDeliver = selectedLicenses.map((each) => {
                                return {
                                    licenseId: each.licenseType,
                                    expirationDate: formatUtcDateString(
                                        formatExpirationDate(each.expirationDate),
                                        23,
                                        59,
                                        59
                                    ),
                                    amount: each.licensesToAdd,
                                    totalOfLicenses: each.totalOfLicenses,
                                };
                            });
                            formState && props.submitEventHandler(formState, licensesToDeliver);
                        }}
                    >
                        {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.confirm")}
                    </button>
                </div>
            </div>
        );
    };
    return (
        <Formik
            initialValues={{
                deliveryType: "",
                caseNumber: "",
                opportunityId: "",
                notes: "",
                amount: null,
                expirationDate: "",
                bundle: DEFAULT_SELECTION_VALUE,
                tenantType: DEFAULT_SELECTION_VALUE,
                tokenRateVersion: extractLatestLicenseRateUuid(props.rateVersions),
            }}
            onSubmit={submitHandler}
            validationSchema={object().shape(
                {
                    deliveryType: string()
                        .required(t("DeliveryHistory.addLicenseDelivery.validation.deliveryType"))
                        .test(
                            "Default option selected",
                            t("DeliveryHistory.addLicenseDelivery.validation.deliveryType"),
                            (value) => value !== DEFAULT_SELECT_DELIVERY_TYPE
                        ),
                    caseNumber: string().when(
                        "opportunityId",
                        (opportunityId: string, schema: StringSchema<string>) => {
                            if (validateParameters(undefined, opportunityId)) {
                                return schema
                                    .required(t("DeliveryHistory.addLicenseDelivery.validation.caseNumber"))
                                    .typeError(t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType"))
                                    .max(
                                        MAX_CASE_NUMBER_LENGTH,
                                        t("DeliveryHistory.addLicenseDelivery.validation.caseNumberLength", {
                                            length: MAX_CASE_NUMBER_LENGTH,
                                        })
                                    )
                                    .test(
                                        "Has only numbers",
                                        t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType"),
                                        (value) => hasOnlyNumbers(value)
                                    );
                            }
                        }
                    ),
                    opportunityId: string().when("caseNumber", (caseNumber: string, schema: StringSchema<string>) => {
                        if (validateParameters(caseNumber, undefined))
                            return schema
                                .max(
                                    MAX_OPPORTUNITY_ID_LENGTH,
                                    t("DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength", {
                                        length: MAX_OPPORTUNITY_ID_LENGTH,
                                    })
                                )
                                .required(t("DeliveryHistory.addLicenseDelivery.validation.opportunityId"));
                    }),
                },
                // Case number and opportunity ID are depending on each other now, for adding validation to the input boxes.
                // The array below contains the two parameters, and it avoids UI from breaking due to cyclic dependency error.
                // Generally fields are topographically sorted, so they can be resolved in the correct order,
                // i.e. field case number resolves before field opportunity ID if field opportunity ID depends on it.
                [["caseNumber", "opportunityId"]]
            )}
            validateOnChange={true}
        >
            {({ values, errors, handleChange }: FormikProps<DeliveryForm>) => {
                return (
                    <Form>
                        {!hiddenForm ? (
                            <>
                                <DeliveryFormContent
                                    availableLicenses={props.availableLicenses}
                                    ownedLicenses={props.ownedLicenses}
                                    selectedLicenses={selectedLicenses}
                                    setSelectedLicenses={(licenses) => setSelectedLicenses(licenses)}
                                    inTenantCreation={false}
                                    preselectedFromBundle={props.preselectedFromBundle}
                                    deliveryForm={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                    licensingModel={props.licensingModel}
                                    rateVersions={props.rateVersions}
                                    selectedTier={props.tenantTier}
                                />
                                <LicenseSelectionForm
                                    availableLicenses={props.availableLicenses}
                                    ownedLicenses={props.ownedLicenses}
                                    globalAmount={values.amount}
                                    globalExpirationDate={values.expirationDate}
                                    selectedLicenses={selectedLicenses}
                                    setSelectedLicenses={(licenses) => setSelectedLicenses(licenses)}
                                    newDeal={false}
                                />

                                <div className={style.gridRows}>
                                    <button
                                        type="submit"
                                        className={
                                            isAddButtonDisabled()
                                                ? classNames(buttons.disabledButton, form.submitButton)
                                                : classNames(buttons.primaryButton, buttons.medium, form.submitButton)
                                        }
                                        disabled={isAddButtonDisabled()}
                                        data-testid={
                                            testIds.workArea.license.deliveryHistory.createDeliveryDialog.addButton
                                        }
                                        onClick={() => {
                                            if (
                                                isConfirmationDialogVisible(
                                                    values.deliveryType,
                                                    values.opportunityId,
                                                    values.caseNumber
                                                )
                                            ) {
                                                setFormState(values);
                                                setHiddenForm(true);
                                            } else {
                                                setHiddenForm(false);
                                            }
                                        }}
                                    >
                                        {t("DeliveryHistory.addLicenseDelivery.addButton")}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>{createConfirmationDialog()}</>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default connector(AddLicenseDeliveryForm);
