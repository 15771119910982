import classNames from "classnames";
import * as React from "react";
import { FileDrop } from "react-file-drop";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Confirmation from "components/icons/Confirmation";
import DragDrop from "components/icons/DragDrop";
import Warning from "components/icons/Warning";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import style from "components/settings/import-logo/import-logo.scss";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { TenantService } from "services/tenants/TenantService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

enum DialogState {
    SELECTING_FILE,
    LOADING_FILE,
    LOADING_FILES,
    LOADING_FILE_FAILED,
    IMPORTING_LOGO,
    IMPORTING_LOGO_SUCCEEDED,
    IMPORTING_LOGO_FAILED,
}

interface Props {
    fileList?: File[];
    tenantService: TenantService;
}

const FILE_SIZE_IN_KB = 200;

const ImportLogo: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const [dialogState, setDialogState] = React.useState(DialogState.SELECTING_FILE);
    const selectedFileListReference = React.useRef<File[] | undefined>(props.fileList);
    const [logo, setLogo] = React.useState<string | undefined>(undefined);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [error, setError] = React.useState<{ field: string; message: string } | string | undefined>(undefined);
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    const dispatch = () => {
        if (selectedFileListReference.current === undefined || selectedFileListReference.current?.length === 0) {
            setDialogState(DialogState.SELECTING_FILE);
        } else if (selectedFileListReference.current?.length === 1) {
            setDialogState(DialogState.LOADING_FILE);
        } else {
            setDialogState(DialogState.LOADING_FILES);
        }
    };

    const reset = () => {
        selectedFileListReference.current = undefined;
        setLogo(undefined);
        setError(undefined);
        setDialogState(DialogState.SELECTING_FILE);
    };

    const addUserStatistics = () => {
        usageStatisticsService.sendEvent({
            category: Category.TENANT,
            action: Action.UPLOAD_CUSTOMER_LOGO,
        });
    };

    const handleFileDrop = (fileList: FileList, event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        addUserStatistics();
        if (fileList.length > 0) {
            selectedFileListReference.current = Array.from(fileList);
        }
        dispatch();
    };

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files !== null) {
            selectedFileListReference.current = Array.from(files);
        }
        dispatch();
    };

    const onTargetClick = () => {
        addUserStatistics();
        fileInputRef.current?.click();
    };

    React.useEffect(() => {
        dispatch();
    }, []);

    React.useEffect(() => {
        if (DialogState.SELECTING_FILE === dialogState) {
            setError(undefined);
        }
        if (DialogState.LOADING_FILE === dialogState) {
            if (selectedFileListReference.current === undefined) {
                setError(t("Common.noFileSelected"));
                return;
            }
            const selectedFile: File = selectedFileListReference.current[0];
            const reader = new FileReader();
            reader.readAsDataURL(selectedFileListReference.current[0]);
            reader.onload = () => {
                try {
                    if (typeof reader.result === "string") {
                        if (
                            selectedFile.type === "image/png" &&
                            Math.round(selectedFile.size / 1024) <= FILE_SIZE_IN_KB
                        ) {
                            setLogo(reader.result);
                            setDialogState(DialogState.IMPORTING_LOGO);
                        } else {
                            throw Error("Invalid PNG file");
                        }
                    }
                } catch (e) {
                    setError(t("Settings.loadFile.errorMessage"));
                    setDialogState(DialogState.LOADING_FILE_FAILED);
                }
            };
        }
        if (DialogState.IMPORTING_LOGO === dialogState) {
            if (logo === undefined) {
                setError(t("Settings.uploadFile.noLogoAvailable"));
                return;
            }

            const abortController = new AbortController();
            abortControllers.push(abortController);
            props.tenantService
                .uploadLogo(logo, abortController)
                .then(() => {
                    setDialogState(DialogState.IMPORTING_LOGO_SUCCEEDED);
                })
                .catch(() => {
                    try {
                        setError(t("Common.failedToLoadFile"));
                    } catch (e) {
                        logger.error(e);
                    }
                    setDialogState(DialogState.IMPORTING_LOGO_FAILED);
                });
        }
    }, [dialogState]);

    const uploadInstructions = (
        <div className={style.introductionLabel}>
            {t("Settings.selectFile.introductionLabel.addLogo")}{" "}
            {t("Settings.selectFile.introductionLabel.check.imageFile")}{" "}
            <b>{t("Settings.selectFile.introductionLabel.check.type")}</b>
            {", "}
            <b>{t("Settings.selectFile.introductionLabel.check.size")}</b>{" "}
            {t("Settings.selectFile.introductionLabel.check.and")}{" "}
            <b>{t("Settings.selectFile.introductionLabel.check.widthHeight")}</b>
        </div>
    );

    const uploadErrorMessage = (
        <div className={style.introductionLabel}>
            {t("Settings.uploadFile.error.message")} {t("Settings.uploadFile.error.check.uploadMesage")}{" "}
            {t("Settings.uploadFile.error.check.noBiggerThan")} <b>{t("Settings.uploadFile.error.check.inFileSize")}</b>{" "}
            {t("Settings.uploadFile.error.check.dimensions")} <b>{t("Settings.uploadFile.error.check.widthHeight")}</b>{" "}
            {t("Settings.uploadFile.error.check.imageFile")} <b>{t("Settings.uploadFile.error.check.type")}</b>
        </div>
    );
    let content = <></>;
    switch (dialogState) {
        case DialogState.SELECTING_FILE:
            content = (
                <div className={style.selectFileWrapper}>
                    {uploadInstructions}
                    <FileDrop onDrop={handleFileDrop}>
                        <DragDrop />
                        <div className={style.content}>
                            <div data-testid={testIds.header.settingsDialog.logo.dragDropArea}>
                                {t("Settings.selectFile.dragDropMessage")}
                            </div>
                            <div>{t("Common.lowerCaseOr")}</div>
                            <div>
                                <input
                                    onChange={onFileInputChange}
                                    ref={fileInputRef}
                                    type="file"
                                    className={style.hidden}
                                />
                                <button
                                    onClick={onTargetClick}
                                    className={classNames(style.selectFile, buttons.textButton)}
                                    type="button"
                                >
                                    {t("Settings.selectFile.chooseFileMessage")}
                                </button>
                            </div>
                        </div>
                    </FileDrop>
                </div>
            );
            break;
        case DialogState.LOADING_FILE:
            content = (
                <div className={style.centeredWrapper}>
                    <div className={style.loadingIndicator}>
                        <LoadingIndicator />
                    </div>
                    <div>{t("Settings.selectFile.loadingMessage")}</div>
                </div>
            );
            break;
        case DialogState.LOADING_FILES:
            content = (
                <div className={style.leftAlignedWrapper}>
                    <div className={style.introductionLabel}>{t("Settings.loadFiles.notSupported")}</div>
                    <div className={style.failureMessage}>
                        <Warning color={theme.chartNegativeColor} />
                        <div className={style.message}>{t("Settings.loadFile.errorMessage")}</div>
                        <button
                            data-testid={testIds.header.settingsDialog.logo.uploadAnotherLink}
                            onClick={() => reset()}
                            className={classNames(style.uploadAnotherFile, buttons.textButton)}
                        >
                            {t("Settings.tryAgain")}
                        </button>
                    </div>
                </div>
            );
            break;
        case DialogState.LOADING_FILE_FAILED:
            content = (
                <div className={style.leftAlignedWrapper}>
                    <div className={style.introductionLabel}>
                        <div>{(typeof error === "string" && uploadErrorMessage) || t("Common.failedToLoadFile")}</div>
                    </div>
                    <div className={style.failureMessage}>
                        <Warning color={theme.chartNegativeColor} />
                        <div className={style.message}>{t("Common.failedToLoadFile")}</div>
                        <button
                            data-testid={testIds.header.settingsDialog.logo.uploadAnotherLink}
                            onClick={() => reset()}
                            className={classNames(style.uploadAnotherFile, buttons.textButton)}
                        >
                            {t("Settings.tryAgain")}
                        </button>
                    </div>
                </div>
            );
            break;
        case DialogState.IMPORTING_LOGO:
            content = (
                <div className={style.centeredWrapper}>
                    <div className={style.loadingIndicator}>
                        <LoadingIndicator />
                    </div>
                    <div>{t("Settings.selectFile.loadingMessage")}</div>
                </div>
            );
            break;
        case DialogState.IMPORTING_LOGO_SUCCEEDED:
            content = (
                <div className={style.leftAlignedWrapper}>
                    {uploadInstructions}
                    <div className={classNames(style.successMessage)}>
                        <Confirmation color={theme.chartPositiveColor} />
                        <div className={style.message}>{t("Settings.uploadFile.successMessage")}</div>
                        <button
                            onClick={() => reset()}
                            className={classNames(style.uploadAnotherFile, buttons.textButton)}
                            data-testid={testIds.header.settingsDialog.logo.uploadAnotherLink}
                        >
                            {t("Common.uploadAnotherFile")}
                        </button>
                    </div>
                </div>
            );
            break;
        case DialogState.IMPORTING_LOGO_FAILED:
            content = (
                <div className={style.leftAlignedWrapper}>
                    {uploadErrorMessage}
                    <div className={style.failureMessage}>
                        <Warning color={theme.chartNegativeColor} />
                        <div className={style.message}>{t("Settings.uploadFile.uploadFailed")}</div>
                        <button
                            data-testid={testIds.header.settingsDialog.logo.uploadAnotherLink}
                            onClick={() => reset()}
                            className={classNames(style.uploadAnotherFile, buttons.textButton)}
                        >
                            {t("Settings.tryAgain")}
                        </button>
                    </div>
                </div>
            );
            break;
    }

    return content;
};

export default ImportLogo;
