import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./view-tenant-details.scss";
import CopyToClipboard from "components/copy-to-clipboard/CopyToClipboard";
import Heading from "components/typography/heading/Heading";
import { Tenant } from "domain/tenants";
import { createDateLabel } from "utils/format";

import testIds from "testIds.json";

export interface FormValues {
    statistics: boolean;
}

export const ViewTenantDetails = (props: { activeTenant: Tenant }): JSX.Element => {
    const { t } = useTranslation();

    return (
        <>
            <Heading tag="div" variant="SUBTITLE_1" className={style.standardModalTitle}>
                {t("CustomerProfile.genericDetail")}
            </Heading>
            <div className={style.tenantProfile}>
                <div className={style.label}>{t("CustomerProfile.customerIdentifier")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.uuidLabel}>
                    {props.activeTenant.uuid}
                    <CopyToClipboard value={props.activeTenant.uuid} />
                </div>

                <div className={style.label}>{t("CustomerProfile.region")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.regionLabel}>
                    {props.activeTenant.region}
                </div>

                <div className={style.label}>{t("CustomerProfile.country")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.countryLabel}>
                    {props.activeTenant.countryCode}
                </div>

                <div className={style.label}>{t("CustomerProfile.createdDate")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.createdDateLabel}>
                    {createDateLabel(props.activeTenant.createdTimestamp)}
                </div>

                <div className={style.label}>{t("CustomerProfile.createdBy")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.createdByLabel}>
                    {props.activeTenant.createdBy}
                </div>

                <div className={style.label}>{t("CustomerProfile.notes")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.notesLabel}>
                    {props.activeTenant.notes !== "" ? (
                        props.activeTenant.notes
                    ) : (
                        <div className={style.emptyField}>{t("CustomerProfile.noNotesAvailable")}</div>
                    )}
                </div>
            </div>
            <Heading tag="div" variant="SUBTITLE_1" className={style.standardModalTitle}>
                {t("CustomerProfile.contactDetails")}
            </Heading>
            <div className={style.tenantProfile}>
                <div className={style.label}>{t("CustomerProfile.mainContactName")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.contact.mainContactNameLabel}>
                    {props.activeTenant.contactName !== "" ? (
                        props.activeTenant.contactName
                    ) : (
                        <div className={style.emptyField}>{t("Common.notProvided")}</div>
                    )}
                </div>

                <div className={style.label}>{t("CustomerProfile.email")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.contact.emailLabel}>
                    {props.activeTenant.contactEmail !== "" ? (
                        props.activeTenant.contactEmail
                    ) : (
                        <div className={style.emptyField}>{t("Common.notProvided")}</div>
                    )}
                    {props.activeTenant.contactEmail !== "" && (
                        <CopyToClipboard value={props.activeTenant.contactEmail} />
                    )}
                </div>
            </div>
            <Heading tag="div" variant="SUBTITLE_1" className={style.standardModalTitle}>
                {t("CustomerProfile.statusSettings")}
            </Heading>
            <div className={style.tenantProfile}>
                <div className={style.label}>{t("CustomerProfile.active")}</div>

                <div>
                    {props.activeTenant.status == true ? <div>{t("Common.yes")}</div> : <div>{t("Common.no")}</div>}
                </div>
                <div className={style.label}>{t("Common.accessExpiry")}:</div>
                <div>
                    {props.activeTenant.expirationDate == "" || props.activeTenant.expirationDate == undefined
                        ? t("Common.never")
                        : createDateLabel(props.activeTenant.expirationDate)}
                </div>
            </div>
        </>
    );
};
