import classNames from "classnames";
import { ErrorMessage, Field, Formik, FormikConfig, Form as FormikForm, FormikProps } from "formik";
import * as React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { object, string } from "yup";

import style from "./containers.scss";
import Edit from "components/icons/Edit";
import kebabStyle from "components/kebab-menu/kebab-menu.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import TextWithTooltip from "components/table/TextWithTooltip";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import { switchTheme } from "store/theme";
import user from "store/user";
import buttons from "styles/buttons.scss";
import buttonsStyle from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
    tenantUuid: state.userReducer.user ? state.userReducer.user.tenantUuid : "",
});

interface Container {
    name: string;
}

interface Result {
    title: string;
    message: string;
}

const connector = connect(mapState, { switchTheme, user });

const ContainerMenuItems = (
    props: ConnectedProps<typeof connector> & {
        containerId: string;
        name: string;
        onUpdate: (isSync: boolean) => void;
    }
): JSX.Element => {
    const { t } = useTranslation();

    const [editContainerVisible, setEditContainerVisible] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [resultVisible, setResultVisible] = React.useState(false);
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });

    const submitHandler: FormikConfig<Container>["onSubmit"] = (values) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        licenseService
            .editContainer(props.containerId, values.name, abortController)
            .then(() => {
                setEditContainerVisible(false);
                showResult({
                    title: t("Common.resultSuccessTitle"),
                    message: t("Common.resultSuccessMessage"),
                });
            })
            .catch(() => {
                if (!signal.aborted) {
                    setEditContainerVisible(false);
                    showResult({
                        title: t("Common.resultErrorTitle"),
                        message: t("Common.resultErrorMessage"),
                    });
                }
            });
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const hideResultDialog = () => {
        setResultVisible(false);
        props.onUpdate(false);
    };

    React.useEffect(() => {
        return () => abortControllers.filter((a) => !a.signal.aborted).forEach((a) => a.abort());
    }, []);

    return (
        <div className={style.containerIcon}>
            <Menu className={kebabStyle.kebabMenu}>
                <ul>
                    <li>
                        <TextWithTooltip text={t("Containers.edit.title")}>
                            <MenuItemButton
                                onClick={() => {
                                    setEditContainerVisible(true);
                                    usageStatisticsService.sendEvent({
                                        category: Category.LICENSE,
                                        action: Action.EDIT_CONTAINER,
                                    });
                                }}
                            >
                                <Edit color={props.theme.iconFillColor} />
                            </MenuItemButton>
                        </TextWithTooltip>
                    </li>
                </ul>
            </Menu>

            <Modal
                isOpen={editContainerVisible}
                hideModal={() => setEditContainerVisible(false)}
                modalTitle={t("Containers.edit.title")}
            >
                <div>
                    <Formik
                        initialValues={{ name: props.name }}
                        onSubmit={submitHandler}
                        validationSchema={object().shape({
                            name: string()
                                .optional()
                                .required(t("Containers.edit.emptyFieldMessage"))
                                .nullable()
                                .max(64),
                        })}
                    >
                        {({
                            values,
                            errors,
                            isSubmitting,
                            handleBlur,
                            handleChange,
                            isValid,
                            dirty,
                        }: FormikProps<Container>) => {
                            if (isSubmitting) {
                                return <LoadingIndicator />;
                            }
                            return (
                                <FormikForm>
                                    <div className={form.formFields}>
                                        <label htmlFor="name" className={form.label}>
                                            {t("Common.nameWithColon")}
                                        </label>
                                        <Field
                                            id="name"
                                            className={classNames(form.input, form.fixedWidthInput, {
                                                [form.inputError]: errors.name,
                                            })}
                                            type="string"
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            data-testid={
                                                testIds.workArea.license.containers.editContainerDialog.nameInput.itself
                                            }
                                        />
                                        <div
                                            className={form.error}
                                            data-testid={
                                                testIds.workArea.license.containers.editContainerDialog.nameInput
                                                    .errorLabel
                                            }
                                        >
                                            <ErrorMessage name="name" />
                                        </div>
                                    </div>
                                    <div className={classNames(form.buttonContainer)}>
                                        <button
                                            className={classNames(
                                                buttons.secondaryButton,
                                                buttons.medium,
                                                form.submitButton
                                            )}
                                            type="reset"
                                            onClick={() => setEditContainerVisible(false)}
                                            data-testid={testIds.common.dialog.closeButton}
                                        >
                                            {t("Common.cancel")}
                                        </button>
                                        <button
                                            className={classNames(
                                                !(isValid && dirty)
                                                    ? buttonsStyle.disabledButton
                                                    : buttons.primaryButton,
                                                buttons.medium,
                                                form.submitButton
                                            )}
                                            type="submit"
                                            data-testid={testIds.common.confirmationDialog.confirmButton}
                                            disabled={!(isValid && dirty)}
                                        >
                                            {t("Common.save")}
                                        </button>
                                    </div>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                </div>
            </Modal>
            <Modal isOpen={resultVisible} hideModal={hideResultDialog} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={form.okButtonContainer}>
                    <button
                        className={classNames(buttonsStyle.primaryButton, buttonsStyle.medium, form.submitButton)}
                        onClick={hideResultDialog}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default connector(ContainerMenuItems);
