import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { v4 as uuid } from "uuid";

import style from "./manageWidgetDialog.scss";
import { getReportPaths, getSuggestedValues } from "components/dashboard/overview/CommonReportPaths";
import BarChartIcon from "components/icons/BarChartIcon";
import { DeleteIcon } from "components/icons/DeleteIcon";
import DoughnutChartIcon from "components/icons/DoughnutChartIcon";
import Info from "components/icons/Info";
import LineChartIcon from "components/icons/LineChartIcon";
import SingleValueChartIcon from "components/icons/SingleValueChartIcon";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import StaticTable from "components/support/api-guide/StaticTable";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import {
    Context,
    contexts,
    Interval,
    intervals,
    Matcher,
    OverviewDashboardData,
    TimeRangeType,
    timeRangeTypes,
    Type,
    types,
    Widget,
    WidgetType,
    widgetTypes,
} from "domain/overviewDashboard";
import { overviewDashboardService } from "services/dashboard/OverviewDashboardService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { daysAfterToday } from "utils/format";

import testIds from "testIds.json";

interface Props {
    dashboardIndex: number;
    onDashboardUpdated: (updatedCount: number) => void;
    widgetUuid?: string;
    title: string;
    visible: boolean;
    onShowModal: (doHide: boolean) => void;
    dashboardData: OverviewDashboardData;
    slotIndex?: number;
}

interface Coloring {
    borderColor: string;
    fillColor: string;
}

interface Property {
    index: number;
    matcher: Matcher;
}
export interface Result {
    title: string;
    message: string;
}
const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

export function fetchSelectedWidget(
    dashboardData: OverviewDashboardData,
    widgetUuid?: string,
    slotIndex?: number
): Widget {
    const defaultWidget: Widget = {
        slotIndex: slotIndex ? slotIndex : 0,
        uuid: uuid(),
        name: "",
        description: "",
        x: 0,
        y: 0,
        width: 1,
        height: 1,
        type: "BAR_CHART",
        aggregationQuery: {
            type: "TIME_AGGREGATED_DOCUMENT_COUNT",
            timeRangeType: "STATIC",
            startTime: daysAfterToday(0),
            endTime: daysAfterToday(1),
            matchers: [
                {
                    context: "ERASURE",
                    path: "",
                },
            ],
            scopeMatchers: [
                {
                    context: "ERASURE",
                    path: "",
                    value: "",
                },
            ],
        },
    };
    return dashboardData.widgets.find((widget) => widget.uuid === widgetUuid) ?? defaultWidget;
}

const ManageWidgetDialog = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const DESCRIPTION_MAX_LENGTH = 512;
    const NAME_MAX_LENGTH = 128;
    const LEGEND_LABEL_MAX_LENGTH = 128;
    const MAXIMUM_NUMBER_OF_TERMS = 20;
    const MAXIMUM_NUMBER_OF_SCOPE_MATCHERS = 5;
    const GLOBAL_PROPERTY = "globalproperty";
    const CHART_PROPERTY = "chartproperty";

    const { t } = useTranslation();
    const initialColors: Coloring = {
        borderColor: props.theme.iconFillColor,
        fillColor: props.theme.inputBackgroundColor,
    };
    const selectedChartColors = {
        borderColor: props.theme.inputBackgroundColor,
        fillColor: props.theme.iconFillColor,
    };
    const disabledChartColors = {
        borderColor: props.theme.disabledButtonBackgroundColor,
        fillColor: props.theme.disabledButtonForegroundColor,
    };
    const widget = fetchSelectedWidget(props.dashboardData, props.widgetUuid, props.slotIndex);
    const [widgetState, setWidgetState] = React.useState({
        slotIndex: widget.slotIndex,
        widgetName: widget.name,
        description: widget.description,
        type: widget.type,
        timeRangeType: widget.aggregationQuery.timeRangeType,
        startDate: widget.aggregationQuery.startTime,
        endDate: widget.aggregationQuery.endTime,
        pathType: "Overview.manageWidgetDialog.customPathDropDown",
        aggregationType: widget.aggregationQuery.interval ?? undefined,
        queryType: widget.aggregationQuery.type,
        maximumNumberOfTerms: widget.aggregationQuery.maximumNumberOfTerms,
    });
    const matchersLimit = new Map<WidgetType, number>([
        ["BAR_CHART", 1],
        ["DOUGHNUT_CHART", 1],
        ["SINGLE_VALUE_CHART", 1],
        ["LINE_CHART", 5],
    ]);
    const [widgetMatchers, setWidgetMatchers] = React.useState<Property[]>(
        typeof widget === "undefined"
            ? [
                  {
                      index: 0,
                      matcher: {
                          context: "ERASURE",
                          path: "",
                          value: "",
                          legend: "",
                      },
                  },
              ]
            : widget.aggregationQuery.matchers.map((matcher, index) => {
                  return {
                      index: index,
                      matcher: matcher,
                  };
              })
    );
    const [scopeMatchers, setScopeMatchers] = React.useState<Property[]>(
        typeof widget === "undefined" ||
            typeof widget.aggregationQuery.scopeMatchers === "undefined" ||
            widget.aggregationQuery.scopeMatchers.length < 1
            ? [
                  {
                      index: 0,
                      matcher: {
                          context: "ERASURE",
                          path: "",
                          value: "",
                      },
                  },
              ]
            : widget.aggregationQuery.scopeMatchers.map((matcher, index) => {
                  return {
                      index: index,
                      matcher: matcher,
                  };
              })
    );
    const [barChartColors, setBarChartColors] = React.useState<Coloring>(generateColoringStyle("BAR_CHART"));
    const [lineChartColors, setLineChartColors] = React.useState<Coloring>(generateColoringStyle("LINE_CHART"));
    const [singleValueChartColors, setSingleValueChartColors] = React.useState<Coloring>(
        widgetState.queryType === "TERM_AGGREGATED_DOCUMENT_COUNT"
            ? disabledChartColors
            : generateColoringStyle("SINGLE_VALUE_CHART")
    );
    const [doughnutChartColors, setDoughnutChartColors] = React.useState<Coloring>(
        generateColoringStyle("DOUGHNUT_CHART")
    );
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [result, setResult] = React.useState({
        title: props.title,
        message: t("Overview.manageWidgetDialog.loadingMessage"),
    });
    const [finalized, setFinalized] = React.useState(false);
    const widgetTypeToColorSetter = new Map<WidgetType, (colors: Coloring) => void>([
        ["BAR_CHART", setBarChartColors],
        ["LINE_CHART", setLineChartColors],
        ["DOUGHNUT_CHART", setDoughnutChartColors],
        ["SINGLE_VALUE_CHART", setSingleValueChartColors],
    ]);

    const [loading, setLoading] = React.useState(false);
    const [changed, setChanged] = React.useState<boolean>(false);

    const aggregationTypes = new Map<Interval, string>([
        ["DAY", t("Overview.manageWidgetDialog.aggregationType.day")],
        ["ALL", t("Overview.manageWidgetDialog.aggregationType.all")],
        ["MONTH", t("Overview.manageWidgetDialog.aggregationType.month")],
        ["YEAR", t("Overview.manageWidgetDialog.aggregationType.year")],
    ]);
    const contextTypes = new Map<Context, string>([
        ["ERASURE", t("Overview.manageWidgetDialog.context.erasure")],
        ["DIAGNOSTIC", t("Overview.manageWidgetDialog.context.diagnostic")],
    ]);

    const queryTypes = new Map<Type, string>([
        ["TIME_AGGREGATED_DOCUMENT_COUNT", t("Overview.manageWidgetDialog.timeAggregatedDocumentCount")],
        ["TERM_AGGREGATED_DOCUMENT_COUNT", t("Overview.manageWidgetDialog.termAggregatedDocumentCount")],
    ]);

    const timeRangeTypeTranslations = new Map<TimeRangeType, string>([
        ["STATIC", t("Overview.manageWidgetDialog.timeRangeType.static")],
        ["YESTERDAY", t("Overview.manageWidgetDialog.timeRangeType.yesterday")],
        ["LAST_7_DAYS", t("Overview.manageWidgetDialog.timeRangeType.last7days")],
        ["LAST_30_DAYS", t("Overview.manageWidgetDialog.timeRangeType.last30days")],
        ["LAST_WEEK", t("Overview.manageWidgetDialog.timeRangeType.lastWeek")],
        ["LAST_MONTH", t("Overview.manageWidgetDialog.timeRangeType.lastMonth")],
        ["CURRENT_WEEK", t("Overview.manageWidgetDialog.timeRangeType.currentWeek")],
        ["CURRENT_MONTH", t("Overview.manageWidgetDialog.timeRangeType.currentMonth")],
    ]);
    const [validationError, setValidationError] = React.useState<{ [key: number]: string }>({});
    const [globalPropertyValidationError, setGlobalPropertyValidationError] = React.useState<{ [key: number]: string }>(
        {}
    );

    function deduceAggregationTypeOptions() {
        if (widgetState.type === "SINGLE_VALUE_CHART") {
            return (
                <>
                    <option key={"ALL"} value={"ALL"}>
                        {aggregationTypes.get("ALL")}
                    </option>
                </>
            );
        } else {
            return intervals.map((interval) => (
                <option key={interval} value={interval}>
                    {aggregationTypes.get(interval)}
                </option>
            ));
        }
    }

    function generateColoringStyle(type: WidgetType): Coloring {
        if (widgetState.type === type) {
            return selectedChartColors;
        }
        if (widgetMatchers.length > (matchersLimit.get(type) ?? 1)) {
            return disabledChartColors;
        } else {
            return initialColors;
        }
    }

    const resetColors = (widgetType: WidgetType, colors: Coloring) => {
        widgetTypes.forEach((each) => {
            if (each !== widgetType) {
                const setter = widgetTypeToColorSetter.get(each);
                if (setter != null) {
                    if (each === "SINGLE_VALUE_CHART" && widgetState.queryType === "TERM_AGGREGATED_DOCUMENT_COUNT") {
                        setter(disabledChartColors);
                    } else {
                        setter(colors);
                    }
                } else {
                    if (each != "CREATE_CHART") {
                        throw new Error("This should be impossible: " + each);
                    }
                }
            }
        });
    };

    const widgetTypeIcons = new Map<WidgetType, JSX.Element>([
        [
            "BAR_CHART",
            <BarChartIcon
                key={"BAR_CHART"}
                borderColor={barChartColors.borderColor}
                fillColor={barChartColors.fillColor}
            />,
        ],
        [
            "SINGLE_VALUE_CHART",
            <SingleValueChartIcon
                key={"SINGLE_VALUE_CHART"}
                borderColor={singleValueChartColors.borderColor}
                fillColor={singleValueChartColors.fillColor}
            />,
        ],
        [
            "DOUGHNUT_CHART",
            <DoughnutChartIcon
                key={"DOUGHNUT_CHART"}
                borderColor={doughnutChartColors.borderColor}
                fillColor={doughnutChartColors.fillColor}
            />,
        ],
        [
            "LINE_CHART",
            <LineChartIcon
                key={"LINE_CHART"}
                borderColor={lineChartColors.borderColor}
                fillColor={lineChartColors.fillColor}
            />,
        ],
    ]);

    const createWidgetTypeInput = (widgetType: WidgetType): JSX.Element => {
        const replaceString = widgetType.toLowerCase().replace("_", " ");
        const element = (
            <span key={widgetType}>
                <input
                    type={"radio"}
                    name={widgetType}
                    id={widgetType}
                    onChange={() => {
                        handleChartTypeChange(widgetType);
                    }}
                    checked={widgetState.type === widgetType}
                    hidden={true}
                />
                <label htmlFor={widgetType}>{widgetTypeIcons.get(widgetType)}</label>
                {widgetState.type === widgetType ? (
                    <title>{t("AltText.charts." + replaceString.split(" ")[0]) + " " + t("AltText.selected")}</title>
                ) : (
                    <title>{t("AltText.charts." + replaceString.split(" ")[0]) + " " + t("AltText.unselected")}</title>
                )}
            </span>
        );
        return isChartTypeChangeAllowed(widgetType) ? (
            element
        ) : (
            <Tooltip
                key={element.key}
                content={
                    widgetType === "SINGLE_VALUE_CHART"
                        ? t("Overview.manageWidgetDialog.disabledSingleValueChart")
                        : t("Overview.manageWidgetDialog.disabledChartTypeSelection")
                }
            >
                {element}
            </Tooltip>
        );
    };

    function isChartTypeChangeAllowed(widgetType: WidgetType): boolean {
        if (widgetState.queryType === "TERM_AGGREGATED_DOCUMENT_COUNT" && widgetType === "SINGLE_VALUE_CHART") {
            return false;
        }
        return widgetMatchers.length <= (matchersLimit.get(widgetType) ?? 1);
    }

    const handleChartTypeChange = (widgetType: WidgetType) => {
        if (!isChartTypeChangeAllowed(widgetType)) {
            return;
        }
        setWidgetState((prevState) => ({ ...prevState, type: widgetType }));
        const setter = widgetTypeToColorSetter.get(widgetType);
        if (setter != null) {
            setter(selectedChartColors);
        }
        resetColors(widgetType, initialColors);

        if (widgetType === "SINGLE_VALUE_CHART") {
            setWidgetState((prevState) => ({ ...prevState, aggregationType: "ALL" }));
        }
    };
    const handleAggregationTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        // If a function is passed to setState, the parameter will be evaluated within that function.
        // This happens after the event has been nullified, resulting in failure when extracting the value from the event.
        // So value of the event is stored in a separate constant.
        const value = event.target.value;
        setWidgetState((prevState) => ({
            ...prevState,
            aggregationType: value as Interval,
        }));
    };
    const handleQueryTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        const type = value as Type;
        setWidgetState((prevState) => ({
            ...prevState,
            queryType: type,
        }));
        if (type === "TERM_AGGREGATED_DOCUMENT_COUNT") {
            setWidgetMatchers(
                widgetMatchers.map((property) => {
                    property.matcher.value = undefined;
                    return property;
                })
            );
            setWidgetState((prevState) => ({
                ...prevState,
                aggregationType: undefined,
            }));
            setSingleValueChartColors(disabledChartColors);
        }
        if (type === "TIME_AGGREGATED_DOCUMENT_COUNT") {
            setWidgetState((prevState) => ({
                ...prevState,
                maximumNumberOfTerms: undefined,
            }));
            if (widgetState.type === "SINGLE_VALUE_CHART") {
                setSingleValueChartColors(selectedChartColors);
            } else {
                setSingleValueChartColors(initialColors);
            }
        }
    };
    const handlePathTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setWidgetState((prevState) => ({ ...prevState, pathType: value }));
    };
    const handleTimeRangeTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setWidgetState((prevState) => ({ ...prevState, timeRangeType: value as TimeRangeType }));
    };

    const addNewProperty = (
        properties: Property[],
        setProperties: (doSet: Property[]) => void,
        isScopeMatcher: boolean
    ) => {
        const matchers: Property[] = properties.concat({
            index: properties.length,
            matcher: {
                context: "ERASURE",
                path: "",
                legend: "",
            },
        });
        setProperties(matchers);
        if (!isScopeMatcher && matchers.length > 1) {
            resetColors(widgetState.type, disabledChartColors);
        }
    };

    const deleteProperty = (
        properties: Property[],
        setProperties: (doSet: Property[]) => void,
        isScopeMatcher: boolean,
        key: number
    ) => {
        const matchers: Property[] = properties.filter((matcher) => matcher.index !== key);
        setProperties(matchers);
        if (!isScopeMatcher && matchers.length <= 1) {
            resetColors(widgetState.type, initialColors);
        }
    };

    function hasInvalidLength(maximumLength: number, value?: string): boolean {
        return (value?.length ?? 0) > maximumLength;
    }

    function hasValidDates(startDate: string, endDate: string): boolean {
        return new Date(startDate) < new Date(endDate);
    }

    function hasEmptyFields(): boolean {
        let invalid = false;

        const attributes: string[] = [widgetState.widgetName, widgetState.startDate, widgetState.endDate];
        if (hasInvalidLength(NAME_MAX_LENGTH, widgetState.widgetName)) {
            return true;
        }
        if (!hasValidDates(widgetState.startDate, widgetState.endDate)) {
            return true;
        }
        attributes.map((attribute) => {
            if (attribute.trim() === "") {
                invalid = true;
            }
        });
        if (widgetState.queryType === "TERM_AGGREGATED_DOCUMENT_COUNT") {
            // maximumNumberOfTerms can be null in case the value is deleted inside the input field.
            // And it can be undefined when changing from Time aggregation query to Term aggregation query.
            if (widgetState.maximumNumberOfTerms === undefined || widgetState.maximumNumberOfTerms === null) {
                return true;
            } else {
                if (
                    isNaN(Number(widgetState.maximumNumberOfTerms.toString())) ||
                    widgetState.maximumNumberOfTerms > MAXIMUM_NUMBER_OF_TERMS
                ) {
                    return true;
                }
            }
        }
        if (widgetMatchers.length == 0) {
            return true;
        } else {
            widgetMatchers.map((property) => {
                if (hasInvalidLength(LEGEND_LABEL_MAX_LENGTH, property.matcher.legend)) {
                    invalid = true;
                }
                if (widgetState.queryType === "TIME_AGGREGATED_DOCUMENT_COUNT") {
                    if (typeof property.matcher.value === "undefined") {
                        invalid = true;
                    } else {
                        if (property.matcher.value.trim() === "") {
                            invalid = true;
                        }
                    }
                }

                if (typeof property.matcher.value !== "undefined" && property.matcher.value.trim() === "") {
                    invalid = true;
                }
                if (property.matcher.path.trim() === "") {
                    invalid = true;
                }
            });
        }

        return invalid;
    }

    function persistWidget() {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        props.onShowModal(false);
        setFinalized(true);
        setLoading(true);
        overviewDashboardService
            .saveWidget(props.dashboardData, abortController)
            .then(() => {
                setResult({
                    title: t("Overview.manageWidgetDialog.successDialogTitle"),
                    message: t("Overview.manageWidgetDialog.successDialogDescription", {
                        chartName: widgetState.widgetName,
                    }),
                });
            })
            .catch(() => {
                setResult({
                    title: t("Overview.manageWidgetDialog.errorDialogTitle"),
                    message: t("Overview.manageWidgetDialog.errorDialogDescription"),
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleSaveWidget() {
        const newWidget: Widget = {
            slotIndex: widgetState.slotIndex,
            name: widgetState.widgetName,
            type: widgetState.type,
            uuid: widget.uuid,
            width: 1,
            height: 10,
            description: widgetState.description,
            aggregationQuery: {
                type: widgetState.queryType,
                interval: widgetState.aggregationType,
                timeRangeType: widgetState.timeRangeType,
                startTime: widgetState.startDate,
                endTime: widgetState.endDate,
                matchers: widgetMatchers.map((property) => {
                    return property.matcher;
                }),
                maximumNumberOfTerms: widgetState.maximumNumberOfTerms,
                scopeMatchers: scopeMatchers.map((property) => property.matcher),
            },
        };
        if (
            newWidget.aggregationQuery.type === "TIME_AGGREGATED_DOCUMENT_COUNT" &&
            newWidget.aggregationQuery.interval === undefined
        ) {
            newWidget.aggregationQuery.interval = "MONTH";
        }
        if (typeof props.widgetUuid === "undefined") {
            props.dashboardData.widgets.push(newWidget);
        } else {
            props.dashboardData.widgets.map((widget) => {
                if (widget.uuid === props.widgetUuid) {
                    widget.name = newWidget.name;
                    widget.description = newWidget.description;
                    widget.aggregationQuery = newWidget.aggregationQuery;
                    widget.type = newWidget.type;
                }
            });
        }
        persistWidget();
    }

    const wrapInDisabledButtonTooltip = (button: JSX.Element): JSX.Element => {
        // The button is disabled when there are empty fields, which causes the tool-tip to not render.
        // Wrapping the button with a span element means that the tool-tip belongs to the wrapper, not to the button.
        return (
            <Tooltip content={t("Overview.manageWidgetDialog.validationError")} offset={[0, 15]}>
                <span>{button}</span>
            </Tooltip>
        );
    };

    const renderSaveButton = (): JSX.Element => {
        const disabled = hasEmptyFields();
        const button: JSX.Element = (
            <button
                className={disabled || changed ? buttons.disabledButton : buttons.primaryButton}
                onClick={() => (disabled ? null : handleSaveWidget())}
                data-testid={testIds.workArea.dashboard.overview.manageWidgetDialog.saveButton}
                disabled={disabled}
            >
                {t("Common.save")}
            </button>
        );
        return disabled ? wrapInDisabledButtonTooltip(button) : button;
    };

    const handleHideModal = () => {
        props.onShowModal(false);
        props.onDashboardUpdated(props.dashboardIndex + 1);
    };

    const handleHideFinalModal = () => {
        setFinalized(false);
        props.onDashboardUpdated(props.dashboardIndex + 1);
    };

    const createLengthErrorMessage = (value: string, maximumLength: number, message: string): JSX.Element => {
        return hasInvalidLength(maximumLength, value) ? <span className={form.error}>{message}</span> : <></>;
    };

    const isLegendLabelDisabled = (): boolean => {
        return widgetState.type === "DOUGHNUT_CHART" || widgetState.type === "SINGLE_VALUE_CHART";
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const isTimeAggregationQuery = () => {
        return widgetState.queryType === "TIME_AGGREGATED_DOCUMENT_COUNT";
    };

    function createMaximumNumberOfTermsField() {
        return (
            <div className={classNames(form.formFields, style.flexDisplay)}>
                <label htmlFor={"maximumNumberOfTerms"} className={classNames(form.label, style.padding)}>
                    {t("Overview.manageWidgetDialog.maximumNumberOfTerms")}
                </label>
                <input
                    autoFocus
                    id="maximumNumberOfTerms"
                    type="number"
                    required={true}
                    className={classNames(form.input, form.fixedWidthInput)}
                    onChange={(event) => {
                        const value = event.target.value;
                        setWidgetState((prevState) => ({ ...prevState, maximumNumberOfTerms: parseInt(value) }));
                    }}
                    value={widgetState.maximumNumberOfTerms}
                    min={1}
                    max={MAXIMUM_NUMBER_OF_TERMS}
                />
            </div>
        );
    }

    function createAggregationTypeField() {
        return (
            <div className={form.formFields}>
                <label htmlFor={"aggregation"} className={form.label}>
                    {t("Overview.manageWidgetDialog.aggregation")}
                </label>
                <select
                    id={"aggregation"}
                    defaultValue={widgetState.type === "SINGLE_VALUE_CHART" ? "ALL" : widgetState.aggregationType}
                    className={classNames(form.select, form.fixedWidthInput)}
                    onChange={handleAggregationTypeChange}
                >
                    {deduceAggregationTypeOptions()}
                </select>
            </div>
        );
    }

    function createValueForMatcherField(
        properties: Property[],
        property: Property,
        setProperties: (doSet: Property[]) => void,
        isScopeMatcher: boolean,
        propertyType: string
    ) {
        const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>, propertyType: string) => {
            let newValue = event.target.value;
            if (property.matcher.context === "ERASURE" && property.matcher.path === "report.product_id") {
                if (/\D/.test(newValue)) {
                    propertyType == GLOBAL_PROPERTY
                        ? setGlobalPropertyValidationError((prevErrors) => ({
                              ...prevErrors,
                              [index]: t("Overview.manageWidgetDialog.valueValidationError"),
                          }))
                        : setValidationError((prevErrors) => ({
                              ...prevErrors,
                              [index]: t("Overview.manageWidgetDialog.valueValidationError"),
                          }));
                    newValue = "";
                    setChanged(true);
                } else {
                    propertyType == GLOBAL_PROPERTY
                        ? setGlobalPropertyValidationError((prevErrors) => ({
                              ...prevErrors,
                              [index]: "",
                          }))
                        : setValidationError((prevErrors) => ({
                              ...prevErrors,
                              [index]: "",
                          }));
                    setChanged(false);
                }
            }

            setProperties(
                properties.map((element) => {
                    if (element.index === property.index) {
                        element.matcher.value = newValue;
                    }
                    return element;
                })
            );
        };
        return isTimeAggregationQuery() || isScopeMatcher ? (
            <div>
                <input
                    id="value"
                    type="text"
                    required={true}
                    className={classNames(form.input, style.inputWidth)}
                    onChange={(event) =>
                        handleChange(
                            property.index,
                            event,
                            isScopeMatcher ? (propertyType = GLOBAL_PROPERTY) : (propertyType = CHART_PROPERTY)
                        )
                    }
                    defaultValue={property.matcher.value}
                    list={"dataValues" + property.index}
                    autoComplete="off"
                />
                <datalist id={"dataValues" + property.index}>
                    {getSuggestedValues(property.matcher.context, property.matcher.path).map((value, index) => (
                        <option key={index} value={value}>
                            {value}
                        </option>
                    ))}
                </datalist>

                {propertyType == GLOBAL_PROPERTY
                    ? globalPropertyValidationError[property.index] && (
                          <p className={form.error}>{globalPropertyValidationError[property.index]}</p>
                      )
                    : validationError[property.index] && (
                          <p className={form.error}>{validationError[property.index]}</p>
                      )}
            </div>
        ) : (
            <></>
        );
    }
    const renderTimeRangeSelection = widgetState.timeRangeType === "STATIC";

    function createAddPropertyButton(
        properties: Property[],
        setProperties: (doSet: Property[]) => void,
        isScopeMatcher: boolean
    ) {
        let hidden: boolean;
        if (!isScopeMatcher) {
            if (isTimeAggregationQuery()) {
                hidden = properties.length >= (matchersLimit.get(widgetState.type) ?? 1);
            } else {
                // If the query is a term aggregation, we want to allow only one matcher for any chart type.
                // This means that the "Add new property" button is displayed only if there is no matcher.
                hidden = properties.length >= 1;
            }
        } else {
            hidden = properties.length >= MAXIMUM_NUMBER_OF_SCOPE_MATCHERS;
        }

        return (
            <button
                className={classNames(style.link, buttons.textButton)}
                onClick={() => addNewProperty(properties, setProperties, isScopeMatcher)}
                data-testid={testIds.workArea.dashboard.overview.manageWidgetDialog.addNewPropertyButton}
                hidden={hidden}
            >
                {t("Overview.manageWidgetDialog.addNewProperty")}
            </button>
        );
    }

    const hideTermAggregationQuerySelection = () => {
        return widgetState.type === "SINGLE_VALUE_CHART" || widgetMatchers.length > 1;
    };

    const createDeleteIcon = (
        properties: Property[],
        setProperties: (doSet: Property[]) => void,
        property: Property,
        testId: string
    ) => {
        return (
            <div
                className={style.gridItem}
                onClick={() => deleteProperty(properties, setProperties, false, property.index)}
                data-testid={testId}
            >
                <DeleteIcon color={props.theme.iconFillColor} linecolor={props.theme.contentBackgroundColor} />
            </div>
        );
    };
    const createPropertySection = (
        properties: Property[],
        setProperties: (doSet: Property[]) => void,
        isScopeMatcher: boolean,
        propertyType: string
    ) => {
        return (
            <div>
                <Heading tag="div" variant="SUBTITLE_1" className={style.titleContainer}>
                    {isScopeMatcher ? (
                        <div className={style.flexDisplay}>
                            {t("Overview.manageWidgetDialog.globalProperties.label")}
                            <Tooltip content={t("Overview.manageWidgetDialog.globalProperties.info")}>
                                <div className={style.info} tabIndex={0}>
                                    <Info
                                        borderColor={props.theme.contentBackgroundColor}
                                        color={props.theme.iconFillColor}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    ) : (
                        t("Overview.manageWidgetDialog.property")
                    )}
                </Heading>
                <StaticTable
                    headers={[
                        {
                            value: t("Overview.manageWidgetDialog.reportType"),
                        },
                        {
                            value: t("Overview.manageWidgetDialog.pathType"),
                        },
                        {
                            value: t("Overview.manageWidgetDialog.customPathLabel"),
                        },
                        {
                            value:
                                isScopeMatcher || isTimeAggregationQuery()
                                    ? t("Overview.manageWidgetDialog.value")
                                    : "",
                        },
                        {
                            value: isScopeMatcher ? "" : t("Overview.manageWidgetDialog.legendLabel"),
                        },
                    ]}
                    cells={properties.map((property) => {
                        const handleCustomPathChange = (
                            index: number,
                            event: React.ChangeEvent<HTMLInputElement>,
                            propertyType: string
                        ) => {
                            const newValue = event.target.value;
                            const chartValueField = property.matcher.value;
                            const isNumber = !isNaN(Number(chartValueField));
                            if (
                                property.matcher.context === "ERASURE" &&
                                newValue === "report.product_id" &&
                                !isNumber
                            ) {
                                propertyType == GLOBAL_PROPERTY
                                    ? setGlobalPropertyValidationError((prevErrors) => ({
                                          ...prevErrors,
                                          [index]: t("Overview.manageWidgetDialog.valueValidationError"),
                                      }))
                                    : setValidationError((prevErrors) => ({
                                          ...prevErrors,
                                          [index]: t("Overview.manageWidgetDialog.valueValidationError"),
                                      }));
                                setChanged(true);
                            } else {
                                propertyType == GLOBAL_PROPERTY
                                    ? setGlobalPropertyValidationError((prevErrors) => ({
                                          ...prevErrors,
                                          [index]: "",
                                      }))
                                    : setValidationError((prevErrors) => ({
                                          ...prevErrors,
                                          [index]: "",
                                      }));
                                setChanged(false);
                            }
                            setProperties(
                                properties.map((element) => {
                                    if (element.index === property.index) {
                                        element.matcher.path = newValue;
                                    }
                                    return element;
                                })
                            );
                        };
                        return [
                            <select
                                id={"reportType"}
                                className={classNames(form.select, style.inputWidth)}
                                onChange={(event) => {
                                    setProperties(
                                        properties.map((element) => {
                                            if (element.index === property.index) {
                                                element.matcher.context = event.target.value as Context;
                                                // When the context is changed, it wouldn't match the previous path and value anymore.
                                                // In this case, both are emptied.
                                                //value becomes undefined in case change in done under Term aggregation query.
                                                element.matcher.path = "";
                                                element.matcher.value = undefined;
                                            }
                                            return element;
                                        })
                                    );
                                }}
                                defaultValue={property.matcher.context}
                                key={"reportType"}
                            >
                                {contexts.map((context) => (
                                    <option key={context} value={context}>
                                        {contextTypes.get(context)}
                                    </option>
                                ))}
                            </select>,
                            <select
                                id={"pathType"}
                                className={classNames(form.select, style.inputWidth)}
                                defaultValue={widgetState.pathType}
                                onChange={handlePathTypeChange}
                                key={"pathType"}
                            >
                                <option key={"pathType"} value={widgetState.pathType}>
                                    {t(widgetState.pathType)}
                                </option>
                            </select>,
                            <div key={"customPath"}>
                                <input
                                    id="customPath"
                                    type="text"
                                    required={true}
                                    className={form.input}
                                    onChange={(event) =>
                                        handleCustomPathChange(
                                            property.index,
                                            event,
                                            isScopeMatcher
                                                ? (propertyType = GLOBAL_PROPERTY)
                                                : (propertyType = CHART_PROPERTY)
                                        )
                                    }
                                    value={property.matcher.path}
                                    list={"customPath" + property.index}
                                    autoComplete="off"
                                />
                                <datalist id={"customPath" + property.index}>
                                    {getReportPaths(property.matcher.context).map((path, index) => (
                                        <option key={index} value={path.path}>
                                            {t(path.translation)}
                                        </option>
                                    ))}
                                </datalist>
                            </div>,
                            createValueForMatcherField(
                                properties,
                                property,
                                setProperties,
                                isScopeMatcher,
                                propertyType
                            ),
                            <div className={style.gridContainer} key={"legendLabel"}>
                                {isScopeMatcher ? (
                                    <></>
                                ) : (
                                    <div className={style.charactersCounterContainer}>
                                        <input
                                            id="legendLabel"
                                            type="text"
                                            className={classNames(form.input, {
                                                [form.inputError]: hasInvalidLength(
                                                    LEGEND_LABEL_MAX_LENGTH,
                                                    property.matcher.legend
                                                ),
                                                [style.disabledInput]: isLegendLabelDisabled(),
                                            })}
                                            onChange={(event) => {
                                                setWidgetMatchers(
                                                    widgetMatchers.map((element) => {
                                                        if (element.index === property.index) {
                                                            element.matcher.legend = event.target.value;
                                                        }
                                                        return element;
                                                    })
                                                );
                                            }}
                                            value={property.matcher.legend}
                                            disabled={isLegendLabelDisabled()}
                                        />
                                        {createLengthErrorMessage(
                                            property.matcher.legend ?? "",
                                            LEGEND_LABEL_MAX_LENGTH,
                                            t("Overview.manageWidgetDialog.legendLabelValidation")
                                        )}
                                    </div>
                                )}
                                {createDeleteIcon(
                                    properties,
                                    setProperties,
                                    property,
                                    testIds.workArea.dashboard.overview.manageWidgetDialog.deletePropertyButton
                                )}
                            </div>,
                        ];
                    })}
                />
                {createAddPropertyButton(properties, setProperties, isScopeMatcher)}
            </div>
        );
    };

    return (
        <>
            <Modal isOpen={props.visible} modalTitle={props.title} hideModal={handleHideModal}>
                <div className={classNames(form.formFields, style.flexDisplay)}>
                    <label htmlFor={"widgetName"} className={form.label}>
                        {t("Overview.manageWidgetDialog.widgetName")}
                    </label>
                    <span className={style.charactersCounterContainer}>
                        <input
                            autoFocus
                            id="widgetName"
                            type="text"
                            required={true}
                            className={classNames(form.input, form.fixedWidthInput, {
                                [form.inputError]: hasInvalidLength(NAME_MAX_LENGTH, widgetState.widgetName),
                            })}
                            onChange={(event) => {
                                const value = event.target.value;
                                setWidgetState((prevState) => ({ ...prevState, widgetName: value }));
                            }}
                            value={widgetState.widgetName}
                        />
                        {createLengthErrorMessage(
                            widgetState.widgetName,
                            NAME_MAX_LENGTH,
                            t("Overview.manageWidgetDialog.nameValidation")
                        )}
                    </span>
                </div>

                <div className={classNames(form.formFields, style.flexDisplay)}>
                    <div>
                        <span className={form.optional}>{t("Overview.manageWidgetDialog.optional")}</span>
                        <label htmlFor={"description"} className={form.label}>
                            {t("Overview.manageWidgetDialog.description")}
                        </label>
                    </div>
                    <div className={classNames(style.flexDisplay, style.gridContainer)}>
                        <div className={style.charactersCounterContainer}>
                            <textarea
                                id={"notes"}
                                className={classNames(form.input, style.descriptionTextArea)}
                                maxLength={DESCRIPTION_MAX_LENGTH}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setWidgetState((prevState) => ({
                                        ...prevState,
                                        description: value,
                                    }));
                                }}
                                value={widgetState.description}
                            />
                            <span className={classNames(form.optional)}>
                                {t("Overview.manageWidgetDialog.charactersRemaining", {
                                    remainingCharacters: (
                                        DESCRIPTION_MAX_LENGTH - widgetState.description.length
                                    ).toString(),
                                    maximumNumberOfCharacters: DESCRIPTION_MAX_LENGTH.toString(),
                                })}
                            </span>
                        </div>
                        <Tooltip content={t("Overview.manageWidgetDialog.descriptionTooltip")}>
                            <div className={classNames(style.info, style.gridItem)} />
                        </Tooltip>
                    </div>
                </div>
                <div className={classNames(form.formFields, style.flexDisplay, style.chartTypeField)}>
                    <label htmlFor={"widgetType"} className={form.label}>
                        {t("Overview.manageWidgetDialog.widgetType")}
                    </label>
                    <span className={classNames(style.flexDisplay, style.chartTypeButtons)}>
                        {widgetTypes.map(createWidgetTypeInput)}
                    </span>
                </div>
                <div className={classNames(form.formFields, style.flexDisplay)}>
                    <label htmlFor={"timeRangeType"} className={form.label}>
                        {t("Overview.manageWidgetDialog.timeRange")}
                    </label>
                    <select
                        id={"timeRangeType"}
                        className={classNames(form.select, form.fixedWidthInput)}
                        onChange={handleTimeRangeTypeChange}
                        defaultValue={widgetState.timeRangeType}
                    >
                        {timeRangeTypes.map((type) => (
                            <option key={type} value={type}>
                                {timeRangeTypeTranslations.get(type)}
                            </option>
                        ))}
                    </select>
                </div>
                {renderTimeRangeSelection && (
                    <div className={form.formFields}>
                        <label htmlFor={"timeRange"} className={form.label} />
                        <span>
                            <input
                                id={"startDate"}
                                className={classNames(form.input, style.dateContainer, {
                                    [form.inputError]: !hasValidDates(widgetState.startDate, widgetState.endDate),
                                })}
                                type={"date"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setWidgetState((prevState) => ({
                                        ...prevState,
                                        startDate: value,
                                    }));
                                }}
                                value={widgetState.startDate}
                            />
                            <input
                                id={"endDate"}
                                className={classNames(form.input, style.dateContainer, {
                                    [form.inputError]: !hasValidDates(widgetState.startDate, widgetState.endDate),
                                })}
                                type={"date"}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setWidgetState((prevState) => ({
                                        ...prevState,
                                        endDate: value,
                                    }));
                                }}
                                value={widgetState.endDate}
                            />
                        </span>
                        {!hasValidDates(widgetState.startDate, widgetState.endDate) ? (
                            <div className={form.error}>{t("Overview.manageWidgetDialog.invalidDate")}</div>
                        ) : null}
                    </div>
                )}
                <div className={form.formFields}>
                    <label htmlFor={"queryType"} className={form.label}>
                        {t("Overview.manageWidgetDialog.queryType")}
                    </label>
                    <select
                        id={"queryType"}
                        className={classNames(form.select, form.fixedWidthInput)}
                        onChange={handleQueryTypeChange}
                        defaultValue={widgetState.queryType}
                    >
                        {hideTermAggregationQuerySelection()
                            ? types
                                  .filter((type) => type !== "TERM_AGGREGATED_DOCUMENT_COUNT")
                                  .map((type) => (
                                      <option key={type} value={type}>
                                          {queryTypes.get(type)}
                                      </option>
                                  ))
                            : types.map((type) => (
                                  <option key={type} value={type}>
                                      {queryTypes.get(type)}
                                  </option>
                              ))}
                    </select>
                </div>
                {isTimeAggregationQuery() ? createAggregationTypeField() : createMaximumNumberOfTermsField()}
                {createPropertySection(scopeMatchers, setScopeMatchers, true, GLOBAL_PROPERTY)}
                {createPropertySection(widgetMatchers, setWidgetMatchers, false, CHART_PROPERTY)}
                <div className={style.buttonsContainer}>
                    <button
                        className={buttons.secondaryButton}
                        data-testid={testIds.common.dialog.closeButton}
                        onClick={handleHideModal}
                    >
                        {t("Common.cancel")}
                    </button>
                    {renderSaveButton()}
                </div>
            </Modal>
            <Modal isOpen={finalized} hideModal={handleHideFinalModal} modalTitle={result.title}>
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <div>{result.message}</div>
                        <div className={style.buttonsContainer}>
                            <button
                                className={buttons.primaryButton}
                                data-testid={testIds.common.dialog.closeButton}
                                onClick={handleHideFinalModal}
                            >
                                {t("Common.ok")}
                            </button>
                        </div>
                    </>
                )}
            </Modal>
        </>
    );
};

export default connector(ManageWidgetDialog);
