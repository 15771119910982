import Tippy, { TippyProps } from "@tippyjs/react";
import * as React from "react";

import style from "./tooltip.scss";

interface Props {
    maxWidth: number;
}

export default class Tooltip extends React.Component<TippyProps & Props> {
    static defaultProps = {
        interactive: true,
        placement: "top",
        offset: [0, 0],
        maxWidth: 350,
        duration: 0,
    };

    render(): JSX.Element {
        return (
            <Tippy
                content={<div className={style.tooltip}>{this.props.content}</div>}
                maxWidth={this.props.maxWidth}
                interactive={this.props.interactive}
                placement={this.props.placement}
                offset={this.props.offset}
                duration={this.props.duration}
            >
                {this.props.children}
            </Tippy>
        );
    }
}
