import * as React from "react";
import { useTranslation } from "react-i18next";

import LicensesTable from "./LicensesTable";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { LICENSES_ROUTE } from "components/router/Routes";

export default function AllLicensesView(): JSX.Element {
    const { t } = useTranslation();

    return (
        <ApplicationLayout
            viewTitle={t("Licenses.licenses")}
            view={<LicensesTable own={false} />}
            mainRoute={LICENSES_ROUTE}
        />
    );
}
