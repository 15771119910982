import * as React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./templates.scss";
import Edit from "components/icons/Edit";
import Export from "components/icons/Export";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_WORKFLOW_TEMPLATE_EDIT, AUTH_WORKFLOW_TEMPLATE_EXPORT } from "domain/authority";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);
const TemplatesKebabMenu = (props: ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    return (
        <div className={style.templatesIcon}>
            <Menu className={style.kebabMenu}>
                <ul>
                    {userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_TEMPLATE_EDIT]) && (
                        <li>
                            <TextWithTooltip text={t("WorkflowTemplates.template.tooltipEdit")}>
                                <MenuItemButton
                                    onClick={() => {
                                        // TODO: Implement Edit Logic
                                    }}
                                    data-testid={testIds.workArea.workflowTemplates.table.kebabMenu.editButton}
                                >
                                    <Edit color={props.theme.iconFillColor} />
                                </MenuItemButton>
                            </TextWithTooltip>
                        </li>
                    )}
                    {userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_TEMPLATE_EXPORT]) && (
                        <li>
                            <TextWithTooltip text={t("WorkflowTemplates.template.tooltipExport")}>
                                <MenuItemButton
                                    onClick={() => {
                                        //TODO : Export logic goes here
                                    }}
                                    data-testid={testIds.workArea.workflowTemplates.table.kebabMenu.exportButton}
                                >
                                    <Export color={props.theme.iconFillColor} />
                                </MenuItemButton>
                            </TextWithTooltip>
                        </li>
                    )}
                </ul>
            </Menu>
        </div>
    );
};

export default connector(TemplatesKebabMenu);
