import React from "react";

import style from "./page.scss";

import defaultLogo from "assets/images/logo/blanccoDSLogoPlaceholder.svg";

import testIds from "testIds.json";

interface Props {
    logo: string;
}

const Header = (props: Props): JSX.Element => {
    const showDefaultHeaderImage = (e: React.ChangeEvent<HTMLImageElement>) => {
        e.target.onerror = null;
        e.target.src = defaultLogo;
    };

    return (
        <div className={style.header}>
            <div className={style.logo}>
                <img
                    src={props.logo}
                    onError={showDefaultHeaderImage}
                    alt="Download the App"
                    data-testid={testIds.workArea.downloadPage.headerLogo}
                />
            </div>
        </div>
    );
};

export default Header;
