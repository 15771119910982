import classNames from "classnames";
import React from "react";

import style from "./button-container.scss";
import utils from "styles/utils.scss";

interface Props {
    children: React.ReactNode;
    alignment?: Alignment;
    spacingTop?: Spacing;
    noSpacingTop?: boolean;
    gap?: Gap;
}

type Alignment = "LEFT" | "RIGHT" | "CENTER";
type Spacing = "SMALL" | "MEDIUM" | "LARGE";
type Gap = "SMALL" | "MEDIUM";

export const ButtonContainerDivider = () => <div className={style.buttonContainerDivider}></div>;

export const ButtonContainer = (props: Props) => {
    const buttonContainerClasses = classNames(style.buttonContainer, {
        [utils.justifyLeft]: props.alignment === "LEFT",
        [utils.justifyRight]: props.alignment === "RIGHT" || props.alignment == undefined,
        [utils.justifyCenter]: props.alignment === "CENTER",
        [utils.spacingTopSm]: props.spacingTop === "SMALL" || (props.spacingTop == undefined && !props.noSpacingTop),
        [utils.spacingTopMd]: props.spacingTop === "MEDIUM",
        [utils.spacingTopLg]: props.spacingTop === "LARGE",
        [utils.noSpacingTop]: props.noSpacingTop,
        [style.gapMd]: props.gap === "MEDIUM",
    });

    return <div className={classNames(buttonContainerClasses)}>{props.children}</div>;
};
