import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ManageRoleView from "./manage-role/ManageRoleView";
import UserRolesTable from "./UserRolesTable";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { USERS_ROUTE } from "components/router/Routes";
import { AUTH_ROLE_CREATE } from "domain/authority";
import { userSessionService } from "services/user/UserSessionService";

export default function UserRolesView(): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = useState({ count: 0 });
    function onRoleAdded() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    function onRoleEdit() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    function onRoleDelete() {
        return setState((prevState) => ({ count: prevState.count - 1 }));
    }

    const viewActions = userSessionService.userHasAllAuthorities([AUTH_ROLE_CREATE]) ? (
        <ManageRoleView onRoleAdded={onRoleAdded} />
    ) : undefined;

    return (
        <ApplicationLayout
            viewTitle={t("UserRoles.title")}
            view={
                <UserRolesTable
                    count={state.count}
                    initialRoleData={{
                        roleUuid: "",
                        name: "",
                        description: "",
                        authority: [],
                        defaultMigrationRole: false,
                    }}
                    onRoleEdit={onRoleEdit}
                    onRoleDelete={onRoleDelete}
                />
            }
            mainRoute={USERS_ROUTE}
            viewActions={viewActions}
        />
    );
}
