import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { ALL_BMS_REPORTS_ROUTE, ALL_REPORTS_ROUTE } from "components/router/Routes";
import { hasAnyBmsLicenses, hasOnlyBms } from "services/licenses/licenseRepository";
import { licenseService } from "services/licenses/LicenseService";
import { setHasBmsLicenses } from "store/license";

const ReportsView = (): JSX.Element => {
    const dispatch = useDispatch();

    useEffect(() => {
        const abortController = new AbortController();
        licenseService
            .fetchAllLicenses({ abortController })
            .then(() => {
                dispatch(setHasBmsLicenses(hasAnyBmsLicenses()));
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    console.error("Licenses not found");
                }
            });
    }, []);

    return hasOnlyBms() ? <Redirect to={ALL_BMS_REPORTS_ROUTE.path} /> : <Redirect to={ALL_REPORTS_ROUTE.path} />;
};

export default ReportsView;
