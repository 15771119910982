// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2lO9N8XqzPlF4CDZL4GSO3{margin-bottom:20px}", ""]);
// Exports
exports.locals = {
	"breakpointSm": "580px",
	"breakpointMd": "768px",
	"breakpointLg": "992px",
	"breakpointXl": "1200px",
	"breakpointXxl": "1600px",
	"textBlock": "_2lO9N8XqzPlF4CDZL4GSO3"
};
module.exports = exports;
