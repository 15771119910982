import * as React from "react";

interface Props {
    color: string;
}

const Update = (props: Props): JSX.Element => {
    return (
        <svg width="35.818" height="47.32" viewBox="0 0 48.32 48.32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2591_20608)">
                <rect x="3" y="1" width="40" height="40" rx="20" fill={props.color} />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23 12.75C18.4437 12.75 14.75 16.4437 14.75 21C14.75 25.5563 18.4437 29.25 23 29.25C27.5563 29.25 31.25 25.5563 31.25 21C31.25 16.4437 27.5563 12.75 23 12.75ZM13.25 21C13.25 15.6152 17.6152 11.25 23 11.25C28.3848 11.25 32.75 15.6152 32.75 21C32.75 26.3848 28.3848 30.75 23 30.75C17.6152 30.75 13.25 26.3848 13.25 21ZM23 15.25C23.1989 15.25 23.3897 15.329 23.5303 15.4697L27.5303 19.4697C27.8232 19.7626 27.8232 20.2374 27.5303 20.5303C27.2374 20.8232 26.7626 20.8232 26.4697 20.5303L23.75 17.8107V26C23.75 26.4142 23.4142 26.75 23 26.75C22.5858 26.75 22.25 26.4142 22.25 26V17.8107L19.5303 20.5303C19.2374 20.8232 18.7626 20.8232 18.4697 20.5303C18.1768 20.2374 18.1768 19.7626 18.4697 19.4697L22.4697 15.4697C22.6103 15.329 22.8011 15.25 23 15.25Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2591_20608"
                    x="0"
                    y="0"
                    width="46"
                    height="46"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2591_20608" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2591_20608" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default Update;
