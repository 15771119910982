import * as React from "react";

const Info = (props: { borderColor: string; color: string }): JSX.Element => {
    return (
        <svg
            id="InfoLightMode"
            xmlns="http://www.w3.org/2000/svg"
            width="18.724"
            height="18.724"
            viewBox="0 0 18.724 18.724"
        >
            <circle
                id="Ellipse_8"
                data-name="Ellipse 8"
                cx="8.5"
                cy="8.5"
                r="8.5"
                transform="translate(1.028 1)"
                fill={props.color}
                // stroke={props.borderColor}
            />
            <g id="information">
                <path
                    id="Path_178"
                    data-name="Path 178"
                    d="M9.362,0a9.362,9.362,0,1,0,9.362,9.362A9.372,9.372,0,0,0,9.362,0Zm0,17.021a7.66,7.66,0,1,1,7.66-7.66A7.668,7.668,0,0,1,9.362,17.021Z"
                    fill="none"
                />
                <path
                    id="Path_179"
                    data-name="Path 179"
                    d="M146.137,70a1.135,1.135,0,1,0,1.135,1.135A1.136,1.136,0,0,0,146.137,70Z"
                    transform="translate(-136.775 -66.028)"
                    fill={props.borderColor}
                />
                <path
                    id="Path_180"
                    data-name="Path 180"
                    d="M150.851,140a.851.851,0,0,0-.851.851v5.106a.851.851,0,0,0,1.7,0v-5.106A.851.851,0,0,0,150.851,140Z"
                    transform="translate(-141.489 -132.057)"
                    fill={props.borderColor}
                />
            </g>
        </svg>
    );
};
export default Info;
