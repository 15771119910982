import { combineReducers, configureStore } from "@reduxjs/toolkit";

import languageReducer from "./language";
import licensesReducer from "./license";
import reportViewSettings from "./reportViewSettings";
import tenantDetailsReducer from "./tenantDetails";
import themeReducer from "./theme";
import userReducer from "./user";

export const rootReducer = combineReducers({
    userReducer,
    languageReducer,
    themeReducer,
    licensesReducer,
    tenantDetailsReducer,
    reportViewSettings,
});

export type createStore = ReturnType<typeof rootReducer>;

export type StoreState = ReturnType<typeof rootReducer>;

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === "development",
});
