import classNames from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, BrowserRouter as Router, Switch } from "react-router-dom";

import i18n from "components/i18n";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import {
    ALL_BMS_REPORTS_ROUTE,
    DELIVERYHISTORY_LICENSES_ROUTE,
    enableBmsReportChildRoutes,
    enableCustomReportViewRoutes,
    enableLicenseChildRoutes,
    enableLicenseExtractionChildRoutes,
    LICENSES_ALL_LICENSES_ROUTE,
    LICENSES_BMS_LICENSES_ROUTE,
    LICENSES_MY_LICENSES_ROUTE,
    ROUTES,
    SUPPORT_AND_HELP_ROUTE,
} from "components/router/Routes";
import { SecuredRoute } from "components/router/SecuredRoute";
import { AUTH_LICENSE_VIEW, AUTH_LICENSE_VIEW_OWN } from "domain/authority";
import { apiGatewayService } from "services/api/ApiGatewayService";
import * as LicenseRepository from "services/licenses/licenseRepository";
import { licenseService } from "services/licenses/LicenseService";
import { reportViewService } from "services/report/ReportViewService";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { setHasBmsLicenses } from "store/license";
import { ThemeName } from "store/theme";
import { setUser } from "store/user";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import layoutStyles from "styles/layout.scss";
import style from "styles/style.scss";
import { logger } from "utils/logging";
import { getObject, RepositoryKey, setObject } from "utils/repository";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    authenticated: state.userReducer.user !== null,
    themeName: state.userReducer.user !== null ? state.themeReducer.themeName : ThemeName.DEFAULT,
    user: state.userReducer.user,
    hasBmsLicenses: state.licensesReducer.hasBmsLicenses,
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
});
const connector = connect(mapState, { setUser, setHasBmsLicenses });

function setBodyClass(themeName: ThemeName, hasBmsLicenses: boolean) {
    const classList = document.body.classList;
    for (const themeClass of Object.values(ThemeName)) {
        classList.remove(themeClass);
    }
    classList.add(themeName);
    enableLicenseChildRoutes(hasBmsLicenses);
    enableBmsReportChildRoutes(hasBmsLicenses);
}

/**
 * This component is complex and its lifecycle can be a surprise because it's not normal: it always exists and that's
 * one reason for its complexity. This entire webapp isn't usually refreshed on browser level and as a consequence this
 * component exists during initial page load, login, all contained pages and components, logout, login again, etc.
 */
const BlanccoCommonCloud = (props: ConnectedProps<typeof connector>) => {
    // Routes component requires that the logged in user has a role so check it
    // here. This issue was introduced when user roles were added and it's only
    // an issue for those users that are logged in during BCC update in which
    // roles are introduced. Unfortunately that's probably nearly all users but
    // eventually this check won't be necessary anymore. That's when you can be
    // confident that all users have a defined role and authorities in
    // localStorage value for "user.details".
    if (!userSessionService.userHasRole()) {
        userSessionService.logout().then(() => {
            window.location.replace("/login");
        });
    }

    // Note that this isn't necessarily true when loading login page. If we landed on login page after logout, this
    // will be false because it was left that way at the end of previous login.
    const [loading, setLoading] = useState<boolean>(true);
    const [isLanguageLoaded, setIsLanguageLoaded] = useState<boolean>(false);

    i18n.on("loaded", () => setIsLanguageLoaded(true));
    const RELOAD_BMP = 3_600_000;
    const [initialLoading, setInitialLoading] = React.useState(true);
    const [popupVisibility, setPopupVisibility] = React.useState(false);
    const [html, setHtml] = React.useState("");
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const { t } = useTranslation();

    const reloadOnCodeUpdate = () => {
        const localVersion = getObject(RepositoryKey.LOCAL_BMP_VERSION) ?? "";

        const url = process.env.GLOBAL_URL;
        if (typeof url === "undefined") {
            return;
        }
        return apiGatewayService
            .fetch(url + "/index.html")
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error("Error occurred while calling BMP.");
                }
                return response.text();
            })
            .then((html) => {
                // A unique hash is generated on each build. This hash is part of the file
                // embedded in the index.html inside the <script> tag. The content of
                // index.html is stored to local storage and then compared after each RELOAD_BMP
                // milliseconds with the newly fetched filename. If they differ, BMP gets reloaded.
                if (localVersion == "" || initialLoading) {
                    setObject(RepositoryKey.LOCAL_BMP_VERSION, html);
                } else {
                    if (localVersion !== html) {
                        setHtml(html);
                        setPopupVisibility(true);
                    }
                }
            })
            .catch((error) => {
                logger.error("Error occurred while checking for updates", error);
            });
    };

    useEffect(() => {
        if (!props.authenticated && loading && isLanguageLoaded) {
            const user = userSessionService.currentUserDetails();
            i18n.off("loaded");
            if (user != null) {
                props.setUser(user);
            } else {
                // This needs to be here because otherwise login page would never be shown.
                setLoading(false);
            }
        }
        if (props.authenticated) {
            const hasFeatureLicense = props.user?.featureLicenses.includes("FEATURE_CUSTOM_REPORT_VIEWS");
            if (hasFeatureLicense && userSessionService.userHasAllAuthorities(["AUTH_CUSTOM_REPORT_VIEW_VIEW"])) {
                // This is a fix of a sort. Before this invocation existed, Switch component below was created
                // before custom view routes were registered with enableCustomReportViewRoutes invocation below. That in
                // turn resulted in blank page when ever you tried to visit any custom views after you had just logged
                // in. This solution isn't perfect but it fixes the issue. The following sequence of events is possible
                // and at least for now accepted: loading becomes false, Switch component is initialized, useEffect
                // function is executed and as a consequence loading is set back to true, initialized Switch component
                // is dumped, custom view fetch finishes, loading becomes false again, and Switch component is created
                // again. All this achieves the goal: Switch component is initialized after custom views have been
                // fetched and the matching routes have been registered with enableCustomReportViewRoutes.
                setLoading(true);
                reportViewService
                    .refreshPaths()
                    .then(() => reportViewService.fetchViews())
                    .then((response) => {
                        enableCustomReportViewRoutes(response.reportViews);
                    })
                    .catch((err) => {
                        logger.error("Failed to fetch report views: ", err);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                setLoading(false);
            }

            const setEmsIdAbortController = new AbortController();
            abortControllers.push(setEmsIdAbortController);
            const fetchAllLicensesAbortController = new AbortController();
            abortControllers.push(fetchAllLicensesAbortController);
            const tenantUuid = props.tenantDetails ? props.tenantDetails.uuid : props.user?.tenantUuid;
            const region = props.tenantDetails ? props.tenantDetails.region : props.user?.region;

            if (tenantUuid == null) {
                LicenseRepository.setHasEmsId(false);
            } else {
                licenseService
                    .fetchEmsConfigurationExistence(tenantUuid, setEmsIdAbortController, region)
                    .then((exists) => {
                        LicenseRepository.setHasEmsId(exists);
                    })
                    .catch(() => {
                        if (!setEmsIdAbortController.signal.aborted) {
                            LicenseRepository.setHasEmsId(false);
                        }
                    });
                if (userSessionService.userHasAllAuthorities([AUTH_LICENSE_VIEW])) {
                    licenseService.checkBmsLicenses(false, fetchAllLicensesAbortController, tenantUuid);
                } else {
                    if (userSessionService.userHasAllAuthorities([AUTH_LICENSE_VIEW_OWN])) {
                        licenseService.checkBmsLicenses(true, fetchAllLicensesAbortController, tenantUuid);
                    }
                }
                const bmsLicenses = LicenseRepository.hasAnyBmsLicenses();

                props.setHasBmsLicenses(bmsLicenses);
            }

            // eslint-disable-next-line no-console
            console.log("BMP Version: " + process.env.BMP_VERSION);
        }
        if (initialLoading) {
            reloadOnCodeUpdate();
            setInitialLoading(false);
        }
        const reloadTimer = setInterval(reloadOnCodeUpdate, RELOAD_BMP);

        return () => {
            clearInterval(reloadTimer);
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [isLanguageLoaded, props.authenticated, props.tenantDetails]);

    setBodyClass(props.themeName, props.hasBmsLicenses);
    return (
        <>
            <div className={style.applicationContainer}>
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    <Router>
                        <Switch>
                            {ROUTES.map((route, index) => {
                                if (
                                    route.childRoutes.includes(LICENSES_ALL_LICENSES_ROUTE) ||
                                    route.path.includes(LICENSES_BMS_LICENSES_ROUTE.path) ||
                                    route.path.includes(ALL_BMS_REPORTS_ROUTE.path) ||
                                    route.path.includes(LICENSES_MY_LICENSES_ROUTE.path)
                                ) {
                                    if (props.hasBmsLicenses) {
                                        enableLicenseChildRoutes(props.hasBmsLicenses);
                                        enableLicenseExtractionChildRoutes(props.hasBmsLicenses);
                                        enableBmsReportChildRoutes(props.hasBmsLicenses);
                                    }
                                }
                                if (!route.isValidUser(props.user)) {
                                    return null;
                                }
                                if (!route.isAccessibleTo(props.tenantDetails)) {
                                    return null;
                                }
                                return (
                                    <SecuredRoute
                                        path={route.path}
                                        key={index}
                                        component={route.component}
                                        secured={route.secured}
                                        authenticated={props.authenticated}
                                    />
                                );
                            })}
                            <Redirect
                                from="/"
                                to={
                                    hasTenantCookie() === true
                                        ? props.user?.default_views &&
                                          props.user?.default_views.tenant_access_default_view
                                            ? props.user?.default_views.tenant_access_default_view
                                            : DELIVERYHISTORY_LICENSES_ROUTE.path
                                        : props.user?.default_views && props.user?.default_views.default_view
                                        ? props.user?.default_views.default_view
                                        : SUPPORT_AND_HELP_ROUTE.path
                                }
                            />
                        </Switch>
                    </Router>
                )}
            </div>
            <Modal
                isOpen={popupVisibility}
                hideModal={() => setPopupVisibility(false)}
                modalTitle={t("UpdatePopup.title")}
            >
                {t("UpdatePopup.content")}

                <div className={layoutStyles.buttonContainer}>
                    <button
                        type={"submit"}
                        onClick={() => setPopupVisibility(false)}
                        data-testid={testIds.common.confirmationDialog.undoButton}
                        className={classNames(buttons.secondaryButton, buttons.small, form.submitButton)}
                    >
                        {t("UpdatePopup.skipButton")}
                    </button>
                    <button
                        type={"submit"}
                        onClick={() => {
                            setObject(RepositoryKey.LOCAL_BMP_VERSION, html);
                            window.location.reload();
                        }}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                        className={classNames(buttons.primaryButton, buttons.small, form.submitButton)}
                    >
                        {t("UpdatePopup.refreshButton")}
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default connector(BlanccoCommonCloud);
