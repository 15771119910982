import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./notification.scss";
import Exit from "components/icons/Exit";
import NotificationIcon from "components/icons/NotificationIcon";
import { createLicenseTypes, getDeliveryTypes } from "components/licenses/common";
import {
    ALL_WORKFLOWS_ROUTE,
    DELIVERYHISTORY_LICENSES_ROUTE,
    LICENSES_ALL_LICENSES_ROUTE,
    RouteDefinition,
    SUPPORT_ROUTE,
} from "components/router/Routes";
import Heading from "components/typography/heading/Heading";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { Notification, NotificationType } from "domain/notification";
import {
    ReportDeletionNotificationData,
    toReportDeletionNotificationData,
} from "services/report/ReportDeletionService";
import { ImportNotificationData, toImportNotificationData } from "services/report/ReportImportService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { TenantDeletionNotificationData, tenantService } from "services/tenants/TenantService";
import { StoreState } from "store";
import { formatTimestamp } from "utils/format";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const forwardToRoute = (route: RouteDefinition) => {
    window.location.replace(route.path);
};

const LICENSE_TYPES = createLicenseTypes(true);

const getLicenseType = (notificationData: string) => {
    const license = LICENSE_TYPES.find((license) => license.productId === notificationData);
    return license != null ? license.productName : notificationData;
};

const getImportNotificationTypes = (t: TFunction) => {
    const reportImportNotifications: Map<string, { title: string; message: string }> = new Map();
    reportImportNotifications.set("REPORT_IMPORT_IN_PROGRESS", {
        title: t("Notification.title.reportImport.inProgress.title"),
        message: t("Notification.title.reportImport.inProgress.message"),
    });
    reportImportNotifications.set("REPORT_IMPORT_DONE", {
        title: t("Notification.title.reportImport.done.title"),
        message: t("Notification.title.reportImport.done.message"),
    });
    reportImportNotifications.set("REPORT_IMPORT_DONE_WITH_FAILURES", {
        title: t("Notification.title.reportImport.doneWithFailures.title"),
        message: t("Notification.title.reportImport.doneWithFailures.message"),
    });
    reportImportNotifications.set("REPORT_IMPORT_FAILED", {
        title: t("Notification.title.reportImport.failed.title"),
        message: t("Notification.title.reportImport.failed.message"),
    });
    return reportImportNotifications;
};

const getEditReportNotificationTypes = (t: TFunction, notificationData: string) => {
    const reportEditNotifications: Map<NotificationType, string> = new Map();
    let uuid;
    if (notificationData.includes("report_uuid")) {
        uuid = JSON.parse(notificationData).report_uuid;
    }
    reportEditNotifications.set("REPORT_EDIT_IN_PROGRESS", t("Notification.title.reportEdit.inProgress", { uuid }));
    reportEditNotifications.set("REPORT_EDIT_SUCCEEDED", t("Notification.title.reportEdit.done", { uuid }));
    reportEditNotifications.set("REPORT_EDIT_FAILED", t("Notification.title.reportEdit.failed", { uuid }));
    return reportEditNotifications;
};

const getExportNotificationTypes = (t: TFunction) => {
    const reportExportNotifications: Map<NotificationType, string> = new Map();
    reportExportNotifications.set("REPORT_EXPORT_STARTED", t("Notification.title.reportExport.reportExportInProgress"));
    reportExportNotifications.set("REPORT_EXPORT_FAILED", t("Notification.title.reportExport.reportExportFailed"));
    reportExportNotifications.set("REPORT_EXPORT_SUCCEEDED", t("Notification.title.reportExport.reportExportSuccess"));
    reportExportNotifications.set(
        "REPORT_EXPORT_WARNING",
        t("Notification.title.reportExport.reportExportSuccessWithException")
    );
    return reportExportNotifications;
};

const getFeatureUpdateNotificationTypes = (t: TFunction) => {
    const featureUpdateNotifications: Map<string, { title: string; message: string }> = new Map();
    featureUpdateNotifications.set("FEATURE_UPDATE", {
        title: t("Notification.title.featureUpdate.title"),
        message: t("Notification.title.featureUpdate.message"),
    });
    return featureUpdateNotifications;
};

const getLicenseAlertNotificationType = (notificationData: string, t: TFunction) => {
    const licenseAlertTriggeredNotifications: Map<NotificationType, { message?: string }> = new Map();

    let notificationMessage;
    if (notificationData.includes("amount_threshold")) {
        notificationMessage = t("Notification.title.alertTriggered.succeeded.amount_threshold_message", {
            amount_threshold: JSON.parse(notificationData).amount_threshold,
            product_name: JSON.parse(notificationData).product_name,
        });
    }
    if (notificationData.includes("expiration_threshold")) {
        notificationMessage = t("Notification.title.alertTriggered.succeeded.expiration_threshold_message", {
            expiration_threshold: JSON.parse(notificationData).expiration_threshold,
            product_name: JSON.parse(notificationData).product_name,
        });
    }
    licenseAlertTriggeredNotifications.set("ALERT_TRIGGERED", {
        message: notificationMessage,
    });
    return licenseAlertTriggeredNotifications;
};

const getReportDeletionNotificationTypes = (t: TFunction, notificationData: string) => {
    const reportDeletionNotifications: Map<NotificationType, string> = new Map();
    let count;
    if (notificationData.includes("total_reports")) {
        count = JSON.parse(notificationData).total_reports;
    }
    reportDeletionNotifications.set(
        "REPORT_DELETION_STARTED",
        t("Notification.title.reportDeletion.reportDeletionInProgress", { count })
    );
    reportDeletionNotifications.set(
        "REPORT_DELETION_FAILED",
        t("Notification.title.reportDeletion.reportDeletionFailed")
    );
    reportDeletionNotifications.set(
        "REPORT_DELETION_SUCCEEDED",
        t("Notification.title.reportDeletion.reportDeletionSuccess", { count })
    );
    reportDeletionNotifications.set(
        "REPORT_DELETION_WARNING",
        t("Notification.title.reportDeletion.reportDeletionSuccessWithException")
    );
    return reportDeletionNotifications;
};

const getTenantDeletionNotificationType = (notificationData: string, t: TFunction) => {
    const tenantDeletionNotifications: Map<NotificationType, { title: string; message?: string }> = new Map();

    let customerName;
    if (notificationData.includes("tenant_name")) {
        customerName = JSON.parse(notificationData).tenant_name;
    }
    tenantDeletionNotifications.set("TENANT_DELETION_IN_PROGRESS", {
        title: t("Notification.title.tenantDeletion.inProgress.title", {
            customerName: customerName,
        }),
        message: t("Notification.title.tenantDeletion.inProgress.message"),
    });
    tenantDeletionNotifications.set("TENANT_DELETION_SUCCEEDED", {
        title: t("Notification.title.tenantDeletion.succeeded.title", { customerName: customerName }),
    });
    tenantDeletionNotifications.set("TENANT_DELETION_FAILED", {
        title: t("Notification.title.tenantDeletion.failed.title", { customerName: customerName }),
        message: t("Notification.title.tenantDeletion.failed.message"),
    });
    return tenantDeletionNotifications;
};

const deriveNotificationTitle = (notificationType: NotificationType, notificationData: string, t: TFunction) => {
    const LICENSE_DELIVERY = getDeliveryTypes();
    if (notificationType === "NEW_LICENSE_DELIVERY") {
        const deliveryType = LICENSE_DELIVERY.get(notificationData);
        return (
            <Heading tag="div" variant="SUBTITLE_2">
                {t("Notification.title.newLicenseDelivery", { type: deliveryType })}
            </Heading>
        );
    } else if (notificationType === "LICENSE_RAN_OUT") {
        return (
            <Heading tag="div" variant="SUBTITLE_2">
                {t("Notification.title.licenseRanOut", { license: getLicenseType(notificationData) })}
            </Heading>
        );
    } else if (notificationType === "LICENSE_EXPIRED") {
        return (
            <Heading tag="div" variant="SUBTITLE_2">
                {t("Notification.title.licenseExpired", { license: getLicenseType(notificationData) })}
            </Heading>
        );
    } else if (notificationType === "TEST") {
        return (
            <Heading tag="div" variant="SUBTITLE_2">
                {t("Notification.title.test")}
            </Heading>
        );
    } else if (notificationType === "ALERT_TRIGGERED") {
        return (
            <Heading tag="div" variant="SUBTITLE_2">
                {notificationData.includes("tenant_name") ? JSON.parse(notificationData).tenant_name : ""}
            </Heading>
        );
    }

    const featureUpdateNotifications = getFeatureUpdateNotificationTypes(t);
    const reportEditNotifications = getEditReportNotificationTypes(t, notificationData);
    const reportImportNotifications = getImportNotificationTypes(t);
    const reportExportNotifications = getExportNotificationTypes(t);
    const reportDeletionNotifications = getReportDeletionNotificationTypes(t, notificationData);
    const tenantDeletionNotifications = getTenantDeletionNotificationType(notificationData, t);

    return reportImportNotifications.has(notificationType) ? (
        <>
            <Heading tag="div" variant="SUBTITLE_2">
                {reportImportNotifications.get(notificationType)?.title}
            </Heading>
            {reportImportNotifications.get(notificationType)?.message && (
                <TextBlock disableBottomSpacing={true}>
                    {reportImportNotifications.get(notificationType)?.message}
                </TextBlock>
            )}
        </>
    ) : reportExportNotifications.has(notificationType) ? (
        <>
            <Heading tag="div" variant="SUBTITLE_2">
                {reportExportNotifications.get(notificationType)}
            </Heading>
        </>
    ) : reportDeletionNotifications.has(notificationType) ? (
        <>
            <Heading tag="div" variant="SUBTITLE_2">
                {reportDeletionNotifications.get(notificationType)}
            </Heading>
        </>
    ) : reportEditNotifications.has(notificationType) ? (
        <>
            <Heading tag="div" variant="SUBTITLE_2">
                {reportEditNotifications.get(notificationType)}
            </Heading>
        </>
    ) : tenantDeletionNotifications.has(notificationType) ? (
        <>
            <Heading tag="div" variant="SUBTITLE_2">
                {tenantDeletionNotifications.get(notificationType)?.title}
            </Heading>
            {tenantDeletionNotifications.get(notificationType)?.message && (
                <TextBlock disableBottomSpacing={true}>
                    {tenantDeletionNotifications.get(notificationType)?.message}
                </TextBlock>
            )}
        </>
    ) : featureUpdateNotifications.has(notificationType) ? (
        <>
            <Heading tag="div" variant="SUBTITLE_2">
                {featureUpdateNotifications.get(notificationType)?.title}
            </Heading>
            <TextBlock disableBottomSpacing={true}>
                {featureUpdateNotifications.get(notificationType)?.message}
            </TextBlock>
        </>
    ) : (
        <Heading tag="div" variant="SUBTITLE_2">
            {notificationType}
        </Heading>
    );
};

const deriveLinkBlock = (route: RouteDefinition, linkKey: string) => {
    return (
        <button
            className={style.link}
            data-testid={testIds.header.notificationMenu.notification.navigationLink}
            onClick={() => forwardToRoute(route)}
        >
            {linkKey}
        </button>
    );
};

const deriveNotificationLink = (notificationType: NotificationType, t: TFunction) => {
    if (notificationType == "NEW_LICENSE_DELIVERY") {
        return deriveLinkBlock(DELIVERYHISTORY_LICENSES_ROUTE, t("Notification.link.viewDeliveries"));
    } else if (notificationType == "LICENSE_RAN_OUT" || notificationType == "LICENSE_EXPIRED") {
        return deriveLinkBlock(LICENSES_ALL_LICENSES_ROUTE, t("Notification.link.viewLicenses"));
    } else if (notificationType == "TEST") {
        return deriveLinkBlock(SUPPORT_ROUTE, t("Notification.link.viewSettings"));
    }
    return <div />;
};

const NotificationBlock = (
    props: {
        notification: Notification;
        key: number;
        onDelete: (uuid: string) => void;
        showImportReportDialog: (jobData: ImportNotificationData) => void;
        showExportReport: (jobId: string) => void;
        showDeleteReport: (jobId: ReportDeletionNotificationData) => void;
        showDeleteTenant?: (tenatName: TenantDeletionNotificationData) => void;
        showLicenseAlertDialog?: (uuid: string) => void;
    } & ConnectedProps<typeof connector>
): JSX.Element => {
    const { t } = useTranslation();
    const featureUpdateNotifications = getFeatureUpdateNotificationTypes(t);
    const reportImportNotifications = getImportNotificationTypes(t);
    const reportExportNotifications = getExportNotificationTypes(t);
    const reportDeletionNotifications = getReportDeletionNotificationTypes(t, props.notification.data);
    const tenantDeletionNotifications = getTenantDeletionNotificationType(props.notification.data, t);
    const licenseAlertNotificationType = getLicenseAlertNotificationType(props.notification.data, t);

    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    const deleteTenant = (updateTenantUuid: string) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        if (updateTenantUuid != undefined) {
            tenantService.tenantDeleteStatusUpdate(updateTenantUuid, abortController);
        }
    };

    let tenantUuid;
    if (props.notification.data.includes("tenant_uuid")) {
        tenantUuid = JSON.parse(props.notification.data).tenant_uuid;
    }

    let tenantName;
    if (props.notification.data.includes("tenant_name")) {
        tenantName = JSON.parse(props.notification.data).tenant_name;
    }
    const subject = encodeURIComponent(
        "Blancco Management Portal - Unexpected Error while deleting tenant : " + tenantName
    );
    const body = encodeURIComponent(
        "Hi there\n\n" +
            "I've experienced a persistent issue with deleting tenant: " +
            tenantName +
            "\nWould you be able to assist?\n\n"
    );
    const mailToLink = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;
    return (
        <>
            <div
                key={props.key}
                className={style.menuItem}
                data-testid={testIds.header.notificationMenu.notification.itself}
                data-type={props.notification.type}
            >
                <div className={style.reportImportNotificationIcon}>
                    <NotificationIcon
                        notificationType={props.notification.type}
                        testid={testIds.header.notificationMenu.notification.statusIcon}
                    />
                </div>

                <div className={style.notification}>
                    <div className={style.reportImportNotification}>
                        {deriveNotificationTitle(props.notification.type, props.notification.data, t)}
                    </div>
                    {reportImportNotifications.has(props.notification.type) ? (
                        <button
                            className={style.link}
                            data-testid={testIds.header.notificationMenu.notification.navigationLink}
                            onClick={() => {
                                usageStatisticsService.sendEvent({
                                    category: Category.REPORT_IMPORT,
                                    action: Action.SEE_DETAILS,
                                    label: props.notification.type,
                                });
                                props.showImportReportDialog(
                                    toImportNotificationData(JSON.parse(props.notification.data))
                                );
                            }}
                        >
                            {t("Notification.link.seeDetails")}
                        </button>
                    ) : reportExportNotifications.has(props.notification.type) ? (
                        <button
                            className={style.link}
                            data-testid={testIds.header.notificationMenu.notification.navigationLink}
                            onClick={() => props.showExportReport(props.notification.data)}
                        >
                            <div>{t("Notification.link.seeDetails")}</div>
                        </button>
                    ) : reportDeletionNotifications.has(props.notification.type) ? (
                        <div>
                            <button
                                className={style.link}
                                data-testid={testIds.header.notificationMenu.notification.navigationLink}
                                onClick={() =>
                                    props.showDeleteReport(
                                        toReportDeletionNotificationData(JSON.parse(props.notification.data))
                                    )
                                }
                            >
                                {t("Notification.link.seeDetails")}
                            </button>
                        </div>
                    ) : tenantDeletionNotifications.has(props.notification.type) ? (
                        <div>
                            {props.notification.type == "TENANT_DELETION_FAILED"
                                ? (deleteTenant(tenantUuid),
                                  (
                                      <a href={mailToLink} className={style.link}>
                                          {t("DeleteTenant.supportLink")}
                                      </a>
                                  ))
                                : ""}
                        </div>
                    ) : featureUpdateNotifications.has(props.notification.type) ? (
                        <button
                            className={style.link}
                            data-testid={testIds.header.notificationMenu.notification.navigationLink}
                            onClick={() => {
                                forwardToRoute(ALL_WORKFLOWS_ROUTE);
                            }}
                        >
                            <div>{t("Notification.link.viewWorkflows")}</div>
                        </button>
                    ) : licenseAlertNotificationType.has(props.notification.type) ? (
                        <>
                            <TextBlock disableBottomSpacing={true}>
                                {licenseAlertNotificationType.get(props.notification.type)?.message}
                            </TextBlock>
                            <button
                                className={style.link}
                                data-testid={testIds.header.notificationMenu.notification.itself}
                                onClick={() => {
                                    usageStatisticsService.sendEvent({
                                        category: Category.LICENSE_ALERTS,
                                        action: Action.VIEW_LICENSE_ALERTS,
                                        label: props.notification.type,
                                    });
                                    if (props.notification.data.includes("alert_uuid")) {
                                        const alert_uuid = JSON.parse(props.notification.data).alert_uuid;
                                        props.showLicenseAlertDialog ? props.showLicenseAlertDialog(alert_uuid) : "";
                                    }
                                }}
                            >
                                <div>{t("Notification.link.seeDetails")}</div>
                            </button>
                        </>
                    ) : (
                        deriveNotificationLink(props.notification.type, t)
                    )}
                    <div className={style.timestamp}>{formatTimestamp(props.notification.creationDate)}</div>
                </div>

                <div className={style.removeButtonContainer}>
                    <button
                        className={style.removeButton}
                        data-testid={testIds.header.notificationMenu.notification.deleteButton}
                        onClick={() => props.onDelete(props.notification.uuid)}
                    >
                        <Exit color={props.theme.iconFillColor} />
                    </button>
                </div>
            </div>
        </>
    );
};

export default connector(NotificationBlock);
