// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"whiteColor": "#fff",
	"activeBackgroundColor": "#476c86",
	"activeForegroundColor": "#476c86",
	"primaryButtonBackgroundColor": "#476c86",
	"secondaryButtonForegroundColor": "#476c86",
	"bannerBackgroundColor": "rgba(217,228,236,.5)",
	"linkTextColor": "#476c86",
	"primaryButtonBackgroundHoverColor": "#5f96bc",
	"secondaryButtonBackgroundHoverColor": "#f7f7f7",
	"destructiveButtonBackgroundHoverColor": "#f46464",
	"primaryButtonBackgroundActiveColor": "#2c447b",
	"secondaryButtonBackgroundActiveColor": "#f0f0f0",
	"destructiveButtonBackgroundColor": "#f09191",
	"chartNegativeColor": "#f09191",
	"chartPositiveColor": "#5fe3a1",
	"chartNeutralColor": "#cecece",
	"errorBackgroundColor": "#db2828",
	"errorBorderColor": "#db2828",
	"errorTextColor": "#db2828",
	"errorWrapperBackgroundColor": "#f2c5c5",
	"warningBackgroundColor": "#e47530",
	"warningBorderColor": "#e47530",
	"warningTextColor": "#e47530",
	"secondaryWarningBackgroundColor": "#ffc915",
	"secondaryWarningBorderColor": "#ffc915",
	"secondaryWarningTextColor": "#ffc915",
	"successBorderColor": "#5fe3a1",
	"successTextColor": "#5fe3a1",
	"pageBackgroundColor": "#f7f7f7",
	"wrapperBackgroundColor": "#dfe4f2",
	"wrapperBorderColor": "#a1b0d5",
	"modelBoxBackgroundColor": "#f0f0f0",
	"contentBackgroundColor": "#fff",
	"secondaryButtonBackgroundColor": "#fff",
	"textColor": "#4c4c4c",
	"primaryButtonForegroundColor": "#fff",
	"bannerForegroundColor": "#4c4c4c",
	"chartOutlineColor": "#222",
	"modalOverlayColor": "rgba(76,76,76,.15)",
	"contentDividerColor": "#cecece",
	"contentBorderColor": "#f0f0f0",
	"nestedContentBorderColor": "#cecece",
	"disabledButtonBackgroundColor": "#cecece",
	"disabledButtonForegroundColor": "#fff",
	"inputHintColor": "#cecece",
	"inputBackgroundColor": "#fff",
	"hoverBackgroundColor": "#f7f7f7",
	"toggleCheckedBackgroundColor": "#91b9f0",
	"toggleUncheckedBackgroundColor": "#cecece",
	"toggleForegroundColor": "#fff",
	"mapForegroundColor": "#f0f0f0",
	"mapBackgroundColor": "#fff",
	"emptyStateEllipseBackgroundColor": "#d4d4d4",
	"emptyStateBiggestCircleBackgroundColor": "#ebebeb",
	"emptyStateSmallestCircleBackgroundColor": "#000",
	"emptyStatePersonColor": "#2f2e41",
	"emptyStatePersonShirtColor": "#91b9f0",
	"emptyStatePersonSleeveColor": "#476c86",
	"emptyStateEllipseBackgroundOpacity": ".298",
	"emptyStateBiggestCircleBackgroundOpacity": ".25",
	"emptyStateSmallestCircleBackgroundOpacityFirst": ".048",
	"emptyStateSmallestCircleBackgroundOpacitySecond": ".05",
	"emptyStateSmallestCircleBackgroundOpacityThird": ".048",
	"chartFirstSet": "#3b5dab",
	"chartSecondSet": "#ab446c",
	"chartThirdSet": "#253a6b",
	"chartForthSet": "#5f96bc",
	"chartFifthSet": "#a56d35",
	"chartSixthSet": "#7a314d",
	"chartSeventhSet": "#466c86",
	"chartEighthSet": "#ca6b0c",
	"chartNinthSet": "#2490cb",
	"chartTenthSet": "#c34578",
	"chartEleventhSet": "#446fb1",
	"chartTwelfthSet": "#e47530",
	"optional": "#99a4ae",
	"disabledTextColor": "#99a4ae",
	"colorPickerBlack": "#000",
	"iconFillColor": "#476c86",
	"sortIconRegularColor": "#cecece",
	"sortIconHoverColor": "#476c86",
	"sortIconActiveColor": "#4c4c4c",
	"calendarRangeBackgroundColor": "#dfe4f2",
	"selectedTabColor": "#476c86",
	"basicTierBannerColor": "rgba(188,132,74,.0980392157)",
	"basicTierBannerBorderColor": "#9d6f3f",
	"premiumTierBannerColor": "rgba(107,149,184,.0980392157)",
	"premiumTierBannerBorderColor": "#6b95b8",
	"diamondTierBannerColor": "rgba(181,78,119,.0980392157)",
	"diamondTierBannerBorderColor": "#b54e77",
	"chartSuccessColor": "#009e57",
	"chartFailColor": "#db3737",
	"textFieldColor": "#cecece",
	"notVerified": "#708add",
	"focusOutlineColorInput": "#3b5dab",
	"focusOutlineColorButton": "#d661e5",
	"tooltipTextColor": "#fff",
	"tooltipBackgroundColor": "#4c4c4c",
	"chipUserIconColor": "#111",
	"successIconColor": "#0f892e",
	"errorIconColor": "#db2828",
	"localBannerBackgroundColor": "#f7f9fc",
	"localBannerBorderColor": "#cecece",
	"errorBannerBackgroundColor": "#fdf4f4",
	"localBannerTextColor": "#4c4c4c",
	"successBadgeBackgroundColor": "#e5f2e9",
	"successBadgeTextColor": "#0f892e",
	"warningBadgeBackgroundColor": "#ffecad",
	"warningBadgeTextColor": "#715907",
	"errorBadgeBackgroundColor": "#fbe9e9",
	"errorBadgeTextColor": "#db2828",
	"infoBadgeBackgroundColor": "#d8dfee",
	"infoBadgeTextColor": "#111",
	"infoIconBackgroundColor": "#606060",
	"neutralBadgeBackgroundColor": "#f0f0f0",
	"neutralBadgeTextColor": "#4c4c4c",
	"strokeColor": "#e4e9f4",
	"textHighlightForegroundColor": "linear-gradient(0deg, rgba(255, 201, 21, 0.3) 0%, rgba(255, 201, 21, 0.3) 100%)",
	"textHighlightBackgroundColor": "#fff",
	"skeletonLoaderBackgroundColor": "#f0f0f0",
	"firstLevelTierBackgroundColor": "#fff6ee",
	"secondLevelTierBackgroundColor": "#f2faff",
	"thirdLevelTierBackgroundColor": "#fff8fb",
	"defaultStarColor": "#007b22",
	"userDefaultStarColor": "#cecece",
	"addOnsCheckmarkColor": "#5fe3a1",
	"warningBannerBackgroundColor": "#fffbec",
	"warningBannerBorderColor": "#fff1c1",
	"warningBannerTextColor": "#111",
	"warningIconBackgroundColor": "#ffc915",
	"warningIconExclamationColor": "#111",
	"hoverColor": "rgba(71,108,134,.06)",
	"conditionToggleButtonBackgroundColor": "#f0f0f0",
	"conditionToggleButtonBorderColor": "#cecece",
	"conditionTableBackgroundColor": "#f7f7f7",
	"conditionTableBorderColor": "#cecece",
	"conditionTableNestedBackgroundColor": "#f0f0f0"
};
module.exports = exports;
