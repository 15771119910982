import classNames from "classnames";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { exportFile } from "components/export/common";
import ExportIcon from "components/icons/ExportIcon";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { createLicenseTypes } from "components/licenses/common";
import LicenseEventsTable from "components/licenses/license-history/LicenseEventsTable";
import { LICENSES_ROUTE } from "components/router/Routes";
import { events, FilterState, LicenseEventQuery, TableState } from "domain/licenseEvent";
import { licenseEventsService } from "services/licenses/LicenseEventService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import { formatDate } from "utils/format";

import testIds from "testIds.json";

export default function LicenseEventsView(): JSX.Element {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const productIdList = createLicenseTypes(true).map((each) => each.productId);
    const [state, setTableState] = React.useState<TableState>({
        licenseEvents: [],
        scrollPosition: 0,
    });
    const [filterState, setFilterState] = React.useState<FilterState>({
        quickSearchFilter: "",
        eventTypes: events,
        productIds: productIdList,
        startDate: formatDate(moment().add(-30, "days").startOf("day")),
        endDate: formatDate(moment().endOf("day")),
    });
    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    const [exportFailed, setExportFailed] = React.useState<boolean>(false);
    const [filtersMissing, setFiltersMissing] = React.useState(false);

    const exportButton = (
        <div>
            <button
                data-tip={t("LicenseEvent.export.tooltip")}
                className={classNames(buttons.primaryButton, buttons.small)}
                onClick={() => {
                    usageStatisticsService.sendEvent({
                        category: Category.LICENSE_EVENTS,
                        action: Action.EXPORT_LICENSE_EVENTS,
                    });
                    const searchQuery: LicenseEventQuery = {
                        search: filterState.quickSearchFilter,
                        actions: filterState.eventTypes,
                        productIds: filterState.productIds,
                        from: filterState.startDate,
                        to: filterState.endDate,
                        cursor: state.cursor,
                    };
                    const abortController = new AbortController();
                    abortControllers.push(abortController);
                    licenseEventsService
                        .exportLicenseEvents(searchQuery, abortController)
                        .then((result) => {
                            result.blob().then((blob) => {
                                const url = URL.createObjectURL(
                                    new Blob([blob], {
                                        type: "text/csv; ; charset=utf-8",
                                    })
                                );
                                exportFile(url, "license_history.csv");
                            });
                        })
                        .catch(() => {
                            if (!abortController.signal.aborted) {
                                setExportFailed(true);
                                if (filterState.eventTypes.length == 0 || filterState.productIds.length == 0) {
                                    setFiltersMissing(true);
                                }
                            }
                        });
                }}
                data-testId={testIds.workArea.license.licenseEvents.exportButton}
            >
                <ExportIcon color={theme.primaryButtonForegroundColor} />
                {t("LicenseEvent.export.title")}
            </button>
        </div>
    );

    return (
        <ApplicationLayout
            viewTitle={t("LicenseEvent.title")}
            view={
                <LicenseEventsTable
                    filterState={filterState}
                    setFilterState={setFilterState}
                    exportFailed={exportFailed}
                    setExportFailed={setExportFailed}
                    filtersMissing={filtersMissing}
                    setFiltersMissing={setFiltersMissing}
                    tableState={state}
                    setTableState={setTableState}
                />
            }
            mainRoute={LICENSES_ROUTE}
            viewActions={exportButton}
        />
    );
}
