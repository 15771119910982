import React from "react";
import { useTranslation } from "react-i18next";

import style from "./copy-to-clipboard.scss";
import PlainCopy from "components/icons/PlainCopy";
import Tooltip from "components/tooltip/Tooltip";

interface Props {
    value: string;
    testId?: string;
}

const CopyToClipboard = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [copied, setCopied] = React.useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.value);
        setCopied(true);
    };
    return (
        <Tooltip content={copied ? t("Common.copied") : t("Common.copyToClipboard")}>
            <button
                onClick={copyToClipboard}
                className={style.plainCopyIcon}
                data-testid={props.testId}
                onMouseLeave={() => {
                    setCopied(false);
                }}
                type="button"
            >
                <PlainCopy />
            </button>
        </Tooltip>
    );
};

export default CopyToClipboard;
