import { ChartData, ChartOptions } from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";
import { connect, ConnectedProps } from "react-redux";

import { StoreState } from "store";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

interface ChartProps {
    chartData: ChartData;
    displayLegend?: boolean;
    horizontalStacking?: boolean;
    verticalStacking?: boolean;
}

type Props = ChartProps & ConnectedProps<typeof connector>;

const LineChartWidget = (props: Props): JSX.Element => {
    const getChartOptions = (gridLineColor: string, textColor: string): ChartOptions => {
        return {
            legend: {
                display: props.displayLegend,
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    fontColor: textColor,
                },
            },
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            fontColor: textColor,
                        },
                    },
                ],
                yAxes: [
                    {
                        display: true,
                        gridLines: {
                            color: gridLineColor,
                            zeroLineColor: gridLineColor,
                        },
                        ticks: {
                            beginAtZero: true,
                            fontColor: textColor,
                            maxTicksLimit: 6,
                        },
                        stacked: props.verticalStacking,
                    },
                ],
            },
            tooltips: {
                enabled: true,
            },
            elements: {
                line: {
                    tension: 0,
                },
                point: {
                    radius: 2,
                },
            },
            responsive: true,
            plugins: {
                labels: {
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    render: () => {},
                },
            },
            maintainAspectRatio: false,
        };
    };

    return (
        <Line data={props.chartData} options={getChartOptions(props.theme.contentBorderColor, props.theme.textColor)} />
    );
};
export default connector(LineChartWidget);
