import * as React from "react";

import style from "./open-in-new-tab.scss";

const OpenInNewTab = (props: { size: number }): JSX.Element => {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.25 7C3.25 4.92893 4.92893 3.25 7 3.25H10C10.4142 3.25 10.75 3.58579 10.75 4C10.75 4.41421 10.4142 4.75 10 4.75H7C5.75736 4.75 4.75 5.75736 4.75 7V17C4.75 18.2426 5.75736 19.25 7 19.25H17C18.2426 19.25 19.25 18.2426 19.25 17V14C19.25 13.5858 19.5858 13.25 20 13.25C20.4142 13.25 20.75 13.5858 20.75 14V17C20.75 19.0711 19.0711 20.75 17 20.75H7C4.92893 20.75 3.25 19.0711 3.25 17V7ZM15.25 4C15.25 3.58579 15.5858 3.25 16 3.25H20C20.4142 3.25 20.75 3.58579 20.75 4V8C20.75 8.41421 20.4142 8.75 20 8.75C19.5858 8.75 19.25 8.41421 19.25 8V5.81066L11.5303 13.5303C11.2374 13.8232 10.7626 13.8232 10.4697 13.5303C10.1768 13.2374 10.1768 12.7626 10.4697 12.4697L18.1893 4.75H16C15.5858 4.75 15.25 4.41421 15.25 4Z"
                className={style.shape}
            />
        </svg>
    );
};

export default OpenInNewTab;
