import * as React from "react";

const AddNewIcon = (props: { className?: string; color: string }): JSX.Element => {
    return (
        <svg
            id="icon_add_new_View"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            className={props.className}
        >
            <line
                id="Line_1531"
                data-name="Line 1531"
                x2="8"
                transform="translate(5 9)"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                strokeWidth="1.5"
            />
            <line
                id="Line_1532"
                data-name="Line 1532"
                y2="8"
                transform="translate(9 5)"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                strokeWidth="1.5"
            />
            <g id="Ellipse_512" data-name="Ellipse 512" fill="none" stroke={props.color} strokeWidth="1.5">
                <circle cx="9" cy="9" r="9" stroke="none" />
                <circle cx="9" cy="9" r="8.25" fill="none" />
            </g>
        </svg>
    );
};

export default AddNewIcon;
