import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ManageUserGroupView from "./manage-user-group/ManageUserGroupView";
import UserGroupsTable from "./UserGroupsTable";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { USERS_ROUTE } from "components/router/Routes";
import { AUTH_USER_GROUP_CREATE } from "domain/authority";
import { userSessionService } from "services/user/UserSessionService";

export default function UserGroupsView(): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = useState({ count: 0 });
    function onGroupAdded() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }
    const viewActions =
        userSessionService.hasFeatureLicense("FEATURE_USER_GROUPS") &&
        userSessionService.userHasAllAuthorities([AUTH_USER_GROUP_CREATE]) ? (
            <ManageUserGroupView onGroupAdded={onGroupAdded} />
        ) : undefined;
    function onGroupDelete() {
        return setState((prevState) => ({ count: prevState.count - 1 }));
    }

    function onGroupEdit() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    return (
        <ApplicationLayout
            viewTitle={t("UserGroups.title")}
            view={
                <UserGroupsTable
                    count={state.count}
                    initialGroupData={{
                        uuid: "",
                        name: "",
                        roleUuid: "",
                        totalUserCount: "",
                        creatorName: "",
                        created: "",
                        backgroundColor: "",
                        textColor: "",
                        edited: "",
                        tenantUuid: "",
                        creatorUuid: "",
                        roleName: "",
                    }}
                    onGroupDelete={onGroupDelete}
                    onGroupEdit={onGroupEdit}
                />
            }
            mainRoute={USERS_ROUTE}
            viewActions={viewActions}
        />
    );
}
