import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect } from "react-router-dom";

import { BLANCCO_IMPACT_DASHBOARD_ROUTE, CUSTOM_DASHBOARD_OVERVIEW } from "components/router/Routes";
import { StoreState } from "store";

const connector = connect((state: StoreState) => ({
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
}));

const DashboardView = (props: ConnectedProps<typeof connector>): JSX.Element => (
    <Redirect
        to={
            CUSTOM_DASHBOARD_OVERVIEW.isAccessibleTo(props.tenantDetails)
                ? CUSTOM_DASHBOARD_OVERVIEW.path
                : BLANCCO_IMPACT_DASHBOARD_ROUTE.path
        }
    />
);

export default connector(DashboardView);
