import React from "react";
import { useTranslation } from "react-i18next";

import { ConditionAssessmentQuestions, LeaseConfiguration, Test } from "./BmsCommonInterfaces";
import ConditionAssessmentQuestionsForm from "./diagnostics/ConditionAssessmentQuestionsForm";
import DiagnosticsTestsForm from "./diagnostics/DiagnosticsTestsForm";
import DeviceIdentifierCheckboxes from "./form-components/BmsDeviceIdentifierCheckboxComponent";
import BmsSectionHeader from "./form-components/BmsSectionHeader";
import InputComponent from "./form-components/BmsTextInputComponent";

interface FormValues {
    leaseConfiguration: LeaseConfiguration;
    platform: string;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const LeaseConfigurationForm: React.FC<Props> = ({ formValues, setFormValues }) => {
    const { t } = useTranslation();
    const updateFormValues = (updateFn: (formValues: FormValues) => FormValues) => {
        setFormValues((prevFormValues) => updateFn({ ...prevFormValues }));
    };
    const handleCheckboxChange = (fieldName: string, isChecked: boolean) => {
        updateFormValues((prevFormValues) => {
            const { leaseConfiguration } = prevFormValues;

            return { ...prevFormValues, leaseConfiguration: { ...leaseConfiguration, [fieldName]: isChecked } };
        });
    };
    const handleInputChange = (fieldName: string, value: string) => {
        updateFormValues((prevFormValues) => {
            const { leaseConfiguration } = prevFormValues;
            const updatedLeaseConfig = { ...leaseConfiguration, [fieldName]: value };

            if (fieldName === "punchOutUrl") {
                updatedLeaseConfig.customer_punch_out = value;
            }

            return { ...prevFormValues, leaseConfiguration: updatedLeaseConfig };
        });
    };
    const handleApiInputChange = (fieldName: string, value: string) => {
        updateFormValues((prevFormValues) => {
            const { leaseConfiguration } = prevFormValues;
            const updatedApiConfig = { ...leaseConfiguration.apiConfig, [fieldName]: value };
            const updatedLeaseConfig = { ...leaseConfiguration, apiConfig: updatedApiConfig };

            return { ...prevFormValues, leaseConfiguration: updatedLeaseConfig };
        });
    };

    const handleProviderQuestionsChange = (questions: ConditionAssessmentQuestions) => {
        updateFormValues((previousFormValues) => {
            const { leaseConfiguration } = previousFormValues;
            const updatedProvider = { ...leaseConfiguration.provider };

            updatedProvider.questions = questions;
            const updatedConfig = { ...leaseConfiguration, provider: updatedProvider };

            return { ...previousFormValues, leaseConfiguration: updatedConfig };
        });
    };

    const handleTestsChange = (tests: Test[]) => {
        updateFormValues((previousFormValues) => {
            const { leaseConfiguration } = previousFormValues;

            return { ...previousFormValues, leaseConfiguration: { ...leaseConfiguration, tests: tests } };
        });
    };

    return (
        <div>
            <BmsSectionHeader labelKey={t("Configuration.common.headers.defaultSettings")} />
            <DeviceIdentifierCheckboxes
                serialMandatory={formValues.leaseConfiguration.serialMandatory}
                imeiMandatory={formValues.leaseConfiguration.imeiMandatory}
                onChange={handleCheckboxChange}
            />
            <InputComponent
                labelKey={t("Configuration.common.punchOutUrl")}
                toolTipContent={t(`Configuration.tooltips.common.punchOutUrl`)}
                inputId={"punchOutUrl"}
                value={formValues.leaseConfiguration.punchOutUrl}
                onChange={(newValue) => handleInputChange("punchOutUrl", newValue)}
            ></InputComponent>

            <BmsSectionHeader labelKey={t("Configuration.leaseConfigurationForm.headers.apiConfiguration")} />
            <InputComponent
                labelKey={t("Configuration.leaseConfigurationForm.endpoint")}
                toolTipContent={t(`Configuration.tooltips.common.endpoint`)}
                inputId={"endpoint"}
                value={formValues.leaseConfiguration.apiConfig?.endpoint}
                onChange={(newValue) => handleApiInputChange("endpoint", newValue)}
            />
            <InputComponent
                labelKey={t("Configuration.common.apiKeyHeaderName")}
                toolTipContent={t(`Configuration.tooltips.common.apiKeyHeaderName`)}
                inputId={"apiKeyHeaderName"}
                value={formValues.leaseConfiguration.apiConfig?.apiKeyHeaderName}
                onChange={(newValue) => handleApiInputChange("apiKeyHeaderName", newValue)}
            />
            <InputComponent
                labelKey={t("Configuration.common.apiKeyHeaderValue")}
                toolTipContent={t(`Configuration.tooltips.common.apiKeyHeaderValue`)}
                inputId={"apiKeyHeaderValue"}
                value={formValues.leaseConfiguration.apiConfig?.apiKeyHeaderValue}
                onChange={(newValue) => handleApiInputChange("apiKeyHeaderValue", newValue)}
            />

            <BmsSectionHeader
                labelKey={t("Configuration.common.headers.conditionAssessmentQuestions")}
                toolTipContent={t("Configuration.tooltips.common.conditionAssessmentQuestions")}
            />
            <div>
                <ConditionAssessmentQuestionsForm
                    currentQuestions={formValues.leaseConfiguration.provider?.questions}
                    onQuestionsChange={(questions) => handleProviderQuestionsChange(questions)}
                />
            </div>

            <BmsSectionHeader
                labelKey={t("Configuration.common.headers.diagnosticsTests")}
                toolTipContent={t("Configuration.tooltips.common.diagnosticsTests")}
            />
            <div>
                <DiagnosticsTestsForm
                    currentEnabledTests={formValues.leaseConfiguration.tests}
                    onTestChange={handleTestsChange}
                    platform={formValues.platform}
                />
            </div>
        </div>
    );
};

export default LeaseConfigurationForm;
