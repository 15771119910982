import classNames from "classnames";
import * as React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";

import ManageWorkflowWrapper from "../manage-workflow-dialog/ManageWorkflowWrapper";
import header from "components/header/header.scss";
import ToggleablePanel from "components/header/ToggleablePanel";
import PlusIcon from "components/icons/PlusIcon";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import userMenu from "components/user-menu/user-menu.scss";
import style from "components/workflows/workflow-templates/workflow-templates.scss";
import { UserDetails } from "domain/user";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { workflowService } from "services/workflows/WorkflowService";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    user: UserDetails | null;
    onUpdate: () => void;
}

const ManageWorkflowTemplateView: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const [createWorkflowVisibility, setCreateWorkflowVisibility] = React.useState(false);

    const showCreateTemplate = () => {
        usageStatisticsService.sendEvent({
            category: Category.WORKFLOW_TEMPLATE,
            action: Action.ADD_WORKFLOW_TEMPLATE,
        });
        setCreateWorkflowVisibility(true);
    };

    const showCreateSubWorkflow = () => {
        // TODO Create sub workflow
    };

    const menuItems: JSX.Element[] = [
        <MenuItemButton
            key="1"
            tabIndex={0}
            onClick={showCreateTemplate}
            data-testid={testIds.workArea.workflowTemplates.createMenu.templateLink}
        >
            {t("WorkflowTemplates.actions.createTemplate")}
        </MenuItemButton>,
        <MenuItemButton
            key="2"
            tabIndex={0}
            onClick={showCreateSubWorkflow}
            data-testid={testIds.workArea.workflowTemplates.createMenu.subWorkflowLink}
        >
            {t("WorkflowTemplates.actions.createSubWorkflow")}
        </MenuItemButton>,
    ];

    return (
        <>
            <div className={header.innerMenu}>
                <div className={style.createButton}>
                    <ToggleablePanel
                        title={""}
                        icon={
                            <button
                                className={classNames(buttons.primaryButton, buttons.small)}
                                data-testid={testIds.workArea.workflowTemplates.createMenu.button}
                            >
                                <PlusIcon /> {t("WorkflowTemplates.actions.create.button")}
                            </button>
                        }
                        testId={"testIds.workArea.workflowTemplates.createMenu"}
                        menuType={"HEADER"}
                        buttonClass={header.headerMenuButton}
                        hideChevron={true}
                    >
                        <Menu className={classNames(userMenu.menu, header.headerMenuButton)}>
                            <ul>
                                {menuItems.map((item, key) => (
                                    <li key={key} className={userMenu.item}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </Menu>
                    </ToggleablePanel>
                </div>
            </div>
            <ManageWorkflowWrapper
                modalTitle={"ManageWorkflowDialog.title.createTemplate"}
                modalVisibility={createWorkflowVisibility}
                onUpdate={props.onUpdate}
                workflowService={workflowService}
                onShowModal={() => setCreateWorkflowVisibility(false)}
                isCreate={true}
                entityType={"TEMPLATE"}
            />
        </>
    );
};

export default ManageWorkflowTemplateView;
