import React from "react";

const RefreshButtonIcon = (props: { color: string }): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.45" height="15" viewBox="0 0 16.45 15">
        <g id="Icon_Refresh" transform="translate(1.06)">
            <g id="refresh-cw" transform="translate(-1 -1.002)">
                <path
                    id="Path_2244"
                    data-name="Path 2244"
                    d="M20.908,4V7.908H17"
                    transform="translate(-5.578 -0.348)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_2245"
                    data-name="Path 2245"
                    d="M1,17.908V14H4.908"
                    transform="translate(0 -3.834)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_2246"
                    data-name="Path 2246"
                    d="M2.635,6.909A5.862,5.862,0,0,1,12.308,4.72L15.33,7.56M1,10.166l3.022,2.84A5.862,5.862,0,0,0,13.7,10.817"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
            <rect id="Rectangle_2394" data-name="Rectangle 2394" width="15" height="15" fill="none" />
        </g>
    </svg>
);

export default RefreshButtonIcon;
