import classNames from "classnames";
import * as React from "react";

import style from "components/typography/heading/heading.scss";
import utils from "styles/utils.scss";

interface Props {
    /** Creates corresponding HTML element. */
    tag: Tag;
    /** Adds styling to the element. E.g. use H1 variant with DIV tag to create a div element that looks like H1. */
    variant?: Variant;
    children: React.ReactNode;
    testId?: string;
    className?: string;
    disableTopSpacing?: boolean;
    disableBottomSpacing?: boolean;
    underline?: boolean;
}

type Tag = "div" | "h1" | "h2";
type Variant = "H1" | "H2" | "SUBTITLE_1" | "SUBTITLE_2";

const VARIANT_TO_STYLE: Record<Variant, string> = {
    H1: style["h1"],
    H2: style["h2"],
    SUBTITLE_1: style["subtitle1"],
    SUBTITLE_2: style["subtitle2"],
};

const Heading = (props: Props) => {
    const headingStyles = () => {
        return classNames(
            props.variant && VARIANT_TO_STYLE[props.variant],
            {
                [utils.noSpacingTop]: props.disableTopSpacing,
                [utils.noSpacingBottom]: props.disableBottomSpacing,
                [style.underline]: props.underline,
            },
            props.className
        );
    };

    return React.createElement(
        props.tag,
        {
            className: headingStyles(),
            "data-testid": props.testId,
        },
        props.children
    );
};

export default Heading;
