import * as React from "react";

const LeftArrow = (props: { color: string }): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="12.303" viewBox="0 0 13.5 12.303">
            <g id="left-arrow" data-name="left-arrow" transform="translate(0.75 0.151)">
                <rect
                    id="Rectangle_2372"
                    data-name="Rectangle 2372"
                    width="12"
                    height="12"
                    transform="translate(0)"
                    fill="none"
                />
                <g id="Group_6700" data-name="Group 6700" transform="translate(0 0.909)">
                    <path
                        id="Path_5031"
                        data-name="Path 5031"
                        d="M16.049,17.793l-5.284-5.258L15.81,7.611"
                        transform="translate(-10.765 -7.611)"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <line
                        id="Line_1527"
                        data-name="Line 1527"
                        x2="11.536"
                        transform="translate(0.464 4.97)"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                </g>
            </g>
        </svg>
    );
};

export default LeftArrow;
