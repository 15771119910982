import * as React from "react";

const Email = (props: { color: string }): JSX.Element => {
    return (
        <svg id="Send" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <rect id="Rectangle_3134" data-name="Rectangle 3134" width="20" height="20" fill="#9a4242" opacity="0" />
            <g
                id="Rectangle_3135"
                data-name="Rectangle 3135"
                transform="translate(0 3)"
                fill="none"
                stroke={props.color}
                strokeWidth="1.5"
            >
                <rect width="20" height="14" stroke="none" />
                <rect x="0.75" y="0.75" width="18.5" height="12.5" fill="none" />
            </g>
            <g id="Polygon_10" data-name="Polygon 10" transform="translate(20 14) rotate(180)" fill="none">
                <path d="M10,0,20,11H0Z" stroke="none" />
                <path
                    d="M 10 2.229910850524902 L 3.390829086303711 9.5 L 16.60917282104492 9.5 L 10 2.229910850524902 M 10 0 L 20 11 L 0 11 L 10 0 Z"
                    stroke="none"
                    fill={props.color}
                />
            </g>
        </svg>
    );
};

export default Email;
