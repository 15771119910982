import * as React from "react";

const SingleValueChartIcon = (props: { borderColor: string; fillColor: string }): JSX.Element => {
    return (
        <svg id="chart_123" xmlns="http://www.w3.org/2000/svg" width="72" height="48" viewBox="0 0 72 48">
            <g
                id="Rectangle_1754"
                data-name="Rectangle 1754"
                fill={props.fillColor}
                stroke={props.borderColor}
                strokeWidth="1"
            >
                <rect width="72" height="48" rx="6" stroke="none" />
                <rect x="0.5" y="0.5" width="71" height="47" rx="5.5" fill="none" />
            </g>
            <g id="Group_3528" data-name="Group 3528" transform="translate(-136.304 -71.34)">
                <path
                    id="Path_2258"
                    data-name="Path 2258"
                    d="M239.744,94.978a17.347,17.347,0,0,1,1.923,1.658,5.385,5.385,0,0,1,1.064,1.46,3.4,3.4,0,0,1,.32,1.432,3.152,3.152,0,0,1-.269,1.3,2.824,2.824,0,0,1-.833,1.055,4.121,4.121,0,0,1-1.423.707,8.15,8.15,0,0,1-4.078-.009,4.684,4.684,0,0,1-1.449-.707,4.128,4.128,0,0,1-.949-.97,4.183,4.183,0,0,1-.538-1.093l-.513.094a4.747,4.747,0,0,0,.551,1.14,4.471,4.471,0,0,0,1.026,1.084,5.035,5.035,0,0,0,1.654.81,8.913,8.913,0,0,0,4.527.019,4.779,4.779,0,0,0,1.6-.782,3.248,3.248,0,0,0,.961-1.178,3.357,3.357,0,0,0,.32-1.451,3.5,3.5,0,0,0-.4-1.621,6.521,6.521,0,0,0-1.193-1.573,17.46,17.46,0,0,0-1.946-1.832l-.134-.1a17.315,17.315,0,0,0,1.868-1.654,6.33,6.33,0,0,0,1.121-1.535,3.514,3.514,0,0,0,.374-1.581,3.381,3.381,0,0,0-.3-1.416,3.149,3.149,0,0,0-.9-1.149,4.431,4.431,0,0,0-1.506-.763,8.084,8.084,0,0,0-4.254.019,4.661,4.661,0,0,0-1.554.79,4.29,4.29,0,0,0-.964,1.057,4.693,4.693,0,0,0-.518,1.112l.482.092a4.151,4.151,0,0,1,.506-1.066,3.967,3.967,0,0,1,.892-.947,4.34,4.34,0,0,1,1.361-.689,7.392,7.392,0,0,1,3.832-.009,3.823,3.823,0,0,1,1.337.689,2.745,2.745,0,0,1,.783,1.029,3.177,3.177,0,0,1,.253,1.268,3.42,3.42,0,0,1-.3,1.4,5.258,5.258,0,0,1-1,1.424,15.8,15.8,0,0,1-1.611,1.461c-.064.052-.621.516-.688.568Z"
                    transform="translate(-54.204 1.291)"
                    fill={props.fillColor}
                    stroke={props.borderColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_2259"
                    data-name="Path 2259"
                    d="M159.039,91.73l-3.75,2.687-.2-.448,3.95-2.811h.439v17.191h-.439Z"
                    transform="translate(0.215 -3.818)"
                    fill={props.fillColor}
                    stroke={props.borderColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_2260"
                    data-name="Path 2260"
                    d="M186.172,106.1l3.553-3.414q1.556-1.5,2.624-2.651a19.059,19.059,0,0,0,1.742-2.131,7.387,7.387,0,0,0,.964-1.877,5.943,5.943,0,0,0,.29-1.841,5.538,5.538,0,0,0-.244-1.67,3.717,3.717,0,0,0-.755-1.356,3.484,3.484,0,0,0-1.289-.908,5.281,5.281,0,0,0-3.693.012,3.922,3.922,0,0,0-1.312.908,4.923,4.923,0,0,0-.859,1.247,6.507,6.507,0,0,0-.488,1.4l-.464-.121a7.442,7.442,0,0,1,.5-1.465,5.419,5.419,0,0,1,.929-1.392,4.228,4.228,0,0,1,1.5-1.041,5.786,5.786,0,0,1,4.1-.024,4,4,0,0,1,1.451,1,4.217,4.217,0,0,1,.871,1.513,5.873,5.873,0,0,1,.29,1.865,6.061,6.061,0,0,1-.36,2.082,8.76,8.76,0,0,1-1.08,2.021,19.86,19.86,0,0,1-1.8,2.179Q191.56,101.6,190.1,103l-3.2,3.1H196l-.163.509h-9.661Z"
                    transform="translate(-21.293 -2.077)"
                    fill={props.fillColor}
                    stroke={props.borderColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
            </g>
        </svg>
    );
};

export default SingleValueChartIcon;
