import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./tenant-details.scss";
import CopyToClipboard from "components/copy-to-clipboard/CopyToClipboard";
import Heading from "components/typography/heading/Heading";
import { Tenant } from "domain/tenants";

import testIds from "testIds.json";

export interface FormValues {
    statistics: boolean;
}

export const TenantDetails = ({ activeTenant }: { activeTenant: Tenant | undefined }): JSX.Element => {
    const { t } = useTranslation();
    let content = <></>;
    if (activeTenant != undefined) {
        content = (
            <>
                <Heading tag={"div"} variant="SUBTITLE_1" className={style.standardModalTitle}>
                    {t("CustomerProfile.erasureClientSettings")}
                </Heading>
                <div className={style.tenantProfile}>
                    <div className={style.label}>{t("CustomerProfile.endpoint")}</div>
                    <div data-testid={testIds.workArea.tenant.viewTenantDialog.erasureClient.endpointLabel}>
                        {activeTenant.erasureClientEndpoint}
                        <CopyToClipboard value={activeTenant.erasureClientEndpoint} />
                    </div>

                    <div className={style.label}>{t("CustomerProfile.port")}</div>
                    <div data-testid={testIds.workArea.tenant.viewTenantDialog.erasureClient.portLabel}>443</div>
                </div>

                <Heading tag={"div"} variant="SUBTITLE_1" className={style.standardModalTitle}>
                    {t("UserProfile.publicApiSettings")}
                </Heading>
                <div className={style.tenantProfile}>
                    <div className={style.label}>{t("UserProfile.publicApiEndpoint")}</div>
                    <div data-testid={testIds.workArea.tenant.viewTenantDialog.publicApi.endpointLabel}>
                        {activeTenant.publicApiEndpoint}
                        <CopyToClipboard value={activeTenant.publicApiEndpoint} />
                    </div>

                    <div className={style.label}>{t("UserProfile.publicApiPort")}</div>
                    <div data-testid={testIds.workArea.tenant.viewTenantDialog.publicApi.portLabel}>443</div>
                </div>
            </>
        );
    } else {
        content = <div className={style.errorContainer}>{t("CustomerProfile.refreshRequestFailed")}</div>;
    }

    return content;
};
