import classNames from "classnames";
import * as React from "react";
import { Button, Wrapper, WrapperState } from "react-aria-menubutton";
import { connect, ConnectedProps } from "react-redux";
import ReactTooltip from "react-tooltip";

import style from "./kebab-menu.scss";
import KebabIcon from "components/icons/KebabIcon";
import { StoreState } from "store";

import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const KebabMenu = (
    props: { children: React.ReactElement; className?: string } & ConnectedProps<typeof connector>
): JSX.Element => {
    const [iconVisibility, setIconVisibility] = React.useState<boolean>(false);
    const onMenuToggle = (state: WrapperState) => {
        setIconVisibility(state.isOpen);
    };
    React.useEffect(() => {
        if (iconVisibility) {
            // This is for rebinding all tooltips - fixes displaying the tooltips for Access Tenant icon and Edit Tenant icon
            // Those two tooltips only exist after they've become visible and by default ReactTooltip doesn't notice that
            // they've appeared and thus there's no tooltip without rebinding
            ReactTooltip.rebuild();
        }
    }, [iconVisibility]);

    return (
        <Wrapper onMenuToggle={onMenuToggle} className={classNames(style.kebabIconContainer, props.className)}>
            <Button className={style.kebabMenuButton} data-testid={testIds.common.primaryView.table.kebabMenu.button}>
                <KebabIcon color={props.theme.iconFillColor} />
            </Button>
            {props.children}
        </Wrapper>
    );
};
export default connector(KebabMenu);
