import React from "react";
import { useTranslation } from "react-i18next";

import style from "./createChartWidget.scss";
import ManageWidgetDialog from "components/dashboard/overview/ManageWidgetDialog";
import { OverviewDashboardData } from "domain/overviewDashboard";

import testIds from "testIds.json";

interface Props {
    overviewDashboardData: OverviewDashboardData;
    onDashboardUpdated: (updatedDashboardIndex: number) => void;
    updatedDashboardIndex: number;
    slotIndex: number;
}

const CreateChartWidget = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [manageWidgetModalVisibility, setManageWidgetModalVisibility] = React.useState(false);
    return (
        <div data-testid={testIds.workArea.dashboard.overview.widgetSlot.addChartLink}>
            <div>{t("Overview.createChartWidget.message")}</div>
            <button className={style.addChart} onClick={() => setManageWidgetModalVisibility(true)}>
                {t("Overview.createChartWidget.addChart")}
            </button>
            <ManageWidgetDialog
                title={t("Overview.manageWidgetDialog.createWidgetTitle")}
                visible={manageWidgetModalVisibility}
                dashboardData={props.overviewDashboardData}
                onShowModal={setManageWidgetModalVisibility}
                dashboardIndex={props.updatedDashboardIndex}
                onDashboardUpdated={props.onDashboardUpdated}
                slotIndex={props.slotIndex}
            />
        </div>
    );
};

export default CreateChartWidget;
