import * as React from "react";

const Print = (props: { color: string }): JSX.Element => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.25 5C6.25 3.48122 7.48122 2.25 9 2.25H15C16.5188 2.25 17.75 3.48122 17.75 5V6.25H19C20.5188 6.25 21.75 7.48122 21.75 9V14C21.75 15.5188 20.5188 16.75 19 16.75H17.75V19C17.75 20.5188 16.5188 21.75 15 21.75H9C7.48122 21.75 6.25 20.5188 6.25 19V16.75H5C3.48122 16.75 2.25 15.5188 2.25 14V9C2.25 7.48122 3.48122 6.25 5 6.25H6.25V5ZM7.75 6.25H16.25V5C16.25 4.30964 15.6904 3.75 15 3.75H9C8.30964 3.75 7.75 4.30964 7.75 5V6.25ZM6.25 15.25V13.75H6C5.58579 13.75 5.25 13.4142 5.25 13C5.25 12.5858 5.58579 12.25 6 12.25H18C18.4142 12.25 18.75 12.5858 18.75 13C18.75 13.4142 18.4142 13.75 18 13.75H17.75V15.25H19C19.6904 15.25 20.25 14.6904 20.25 14V9C20.25 8.30964 19.6904 7.75 19 7.75H5C4.30964 7.75 3.75 8.30964 3.75 9V14C3.75 14.6904 4.30964 15.25 5 15.25H6.25ZM16.25 13.75H7.75V19C7.75 19.6904 8.30964 20.25 9 20.25H15C15.6904 20.25 16.25 19.6904 16.25 19V13.75ZM5.25 10C5.25 9.58579 5.58579 9.25 6 9.25H8C8.41421 9.25 8.75 9.58579 8.75 10C8.75 10.4142 8.41421 10.75 8 10.75H6C5.58579 10.75 5.25 10.4142 5.25 10ZM9.25 17C9.25 16.5858 9.58579 16.25 10 16.25H14C14.4142 16.25 14.75 16.5858 14.75 17C14.75 17.4142 14.4142 17.75 14 17.75H10C9.58579 17.75 9.25 17.4142 9.25 17Z"
                fill={props.color}
            />
        </svg>
    );
};

export default Print;
