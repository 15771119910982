import { TableData } from "./table";

export interface UserGroup extends TableData {
    backgroundColor: string;
    textColor: string;
    uuid: string;
    name: string;
    roleUuid: string | null;
    roleName: string | null;
    totalUserCount: string;
    creatorUuid: string;
    creatorName: string;
    created: string;
    edited: string;
    tenantUuid: string;
}

export interface AllUserGroup {
    uuid: string;
    name: string;
}

export interface GroupsTable {
    cursor: string;
    groups: UserGroup[];
}

export interface AllUserGroups {
    cursor: string;
    groups: AllUserGroup[];
}

export interface UserGroupsDataDto {
    name: string;
    uuid: string;
    total_user_count: string;
    role_uuid?: string;
    created: string;
    edited: string;
    tenant_uuid: string;
    background_color: string;
    text_color: string;
    creator_uuid: string;
    creator_name: string;
    role_name?: string;
}

export interface AllUserGroupsDataDto {
    name: string;
    group_uuid: string;
}

export interface UserGroupsResponseDto {
    user_groups: UserGroupsDataDto[];
    cursor: string;
}

export interface AllUserGroupsResponseDto {
    user_groups: AllUserGroupsDataDto[];
    cursor: string;
}

export function toUserGroupData(dto: UserGroupsDataDto): UserGroup {
    return {
        name: dto.name,
        uuid: dto.uuid,
        created: dto.created,
        creatorName: dto.creator_name,
        totalUserCount: dto.total_user_count,
        roleUuid: dto.role_uuid ?? null,
        edited: dto.edited,
        backgroundColor: dto.background_color,
        textColor: dto.text_color,
        tenantUuid: dto.tenant_uuid,
        creatorUuid: dto.creator_uuid,
        roleName: dto.role_name ?? null,
    };
}

export function toAllUserGroupData(dto: AllUserGroupsDataDto): AllUserGroup {
    return {
        uuid: dto.group_uuid,
        name: dto.name,
    };
}
