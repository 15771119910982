import classNames from "classnames";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "components/licenses/delivery-history/add-license-delivery.scss";
import { StoreState } from "store";
import form from "styles/form.scss";
import { formatDatePickerValue } from "utils/format";

import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

interface Props {
    amount: number | null;
    expirationDate: string;
    onDateChange: (newDate: string) => void;
    dateString: string;
    onAmountChange: (newAmount: string) => void;
    showAmountType: boolean;
}

const GlobalExpirationDateAndAmount = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={classNames(form.formFields)}>
            {props.showAmountType && (
                <label className={classNames(style.globalLabel)}>
                    {t("DeliveryHistory.addLicenseDelivery.amounts")}
                    <input
                        className={classNames(form.input, style.inputMargin, style.fixedWidth)}
                        type={"number"}
                        value={props.amount ?? ""}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => props.onAmountChange(event.target.value)}
                        data-testid={
                            testIds.workArea.license.deliveryHistory.createDeliveryDialog.globalAssignedAmountInput
                                .itself
                        }
                    />
                </label>
            )}
            <label className={classNames(style.globalLabel)}>
                {t("DeliveryHistory.addLicenseDelivery.expirationDates")}
                <input
                    id={"expirationDate"}
                    type="date"
                    className={classNames(form.input, style.inputMargin, style.inputWidth, style.customDateInput)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => props.onDateChange(event.target.value)}
                    max={formatDatePickerValue(props.dateString)}
                    value={formatDatePickerValue(props.expirationDate)}
                    data-testid={
                        testIds.workArea.license.deliveryHistory.createDeliveryDialog.globalExpirationDateInput.itself
                    }
                />
            </label>
        </div>
    );
};
export default connector(GlobalExpirationDateAndAmount);
