import * as React from "react";
import { useTranslation } from "react-i18next";

import ExportReportPathsView from "./ExportReportPathsView";
import ReportPathsTable from "./ReportPathsTable";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { SUPPORT_ROUTE } from "components/router/Routes";

export default function ReportPathsView(): JSX.Element {
    const { t } = useTranslation();

    const [exportCsvUrl, setExportCsvUrl] = React.useState<string>("");

    return (
        <ApplicationLayout
            viewTitle={t("ReportPaths.title")}
            view={<ReportPathsTable onCsvUrlUpdate={(url) => setExportCsvUrl(url)} />}
            mainRoute={SUPPORT_ROUTE}
            viewActions={<ExportReportPathsView exportCsvUrl={exportCsvUrl} />}
        />
    );
}
