import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import ManageWorkflowTemplateView from "./ManageWorkflowTemplateView";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { WORKFLOWS_ROUTE } from "components/router/Routes";
import { WorkflowTemplate } from "components/workflows/workflow-templates/WorkflowTemplate";
import { AUTH_WORKFLOW_TEMPLATE_CREATE } from "domain/authority";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    user: state.userReducer.user,
});
const connector = connect(mapState);

const WorkflowTemplatesView = (props: ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();

    function updateState() {
        //TODO BCC-3665 : update state after create new workflow template
    }
    const viewActions = userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_TEMPLATE_CREATE]) ? (
        <>
            <ManageWorkflowTemplateView user={props.user} onUpdate={updateState} />
        </>
    ) : undefined;

    const layoutProps = {
        viewTitle: t("WorkflowTemplates.name"),
        view: <WorkflowTemplate />,
        mainRoute: WORKFLOWS_ROUTE,
        viewActions,
        enableRefresh: true,
        onHandleRefresh: updateState,
    };
    return <ApplicationLayout {...layoutProps} />;
};
export default connector(WorkflowTemplatesView);
