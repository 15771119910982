import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import style from "./my-device-health.scss";
import DateCell from "components/table/DateCell";
import Heading from "components/typography/heading/Heading";
import { DiagnosticReportResponse, NtfHistory, NtfResult } from "domain/reports";

import checkIcon from "assets/images/icons/checkMarkInCircle.svg";
import crossIcon from "assets/images/icons/cross.svg";

import testIds from "testIds.json";

export interface NtfProps {
    diagnosticReportResponse: DiagnosticReportResponse;
}

const FAULT_CODE_TO_TRANSLATION_MAP = new Map<string, string>([
    ["fault_check__fault_code_all_tests", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.allTests"],
    [
        "fault_check__fault_code_automated_tests",
        "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.automatedTests",
    ],
    ["fault_check__fault_code_camera", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.camera"],
    ["fault_check__fault_code_ports", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.ports"],
    ["fault_check__fault_code_screen", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.screen"],
    ["fault_check__fault_code_audio", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.audio"],
    ["fault_check__fault_code_device", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.device"],
    ["fault_check__fault_code_sensors", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.sensors"],
    ["fault_check__fault_code_others", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.others"],
]);

export default function MyDeviceHealthView(props: NtfProps): JSX.Element {
    const { t } = useTranslation();

    const reportResponse = props.diagnosticReportResponse;
    const results = reportResponse.ntf.results;
    const otherDetails = reportResponse.ntf.otherDetails;
    const codeType =
        otherDetails.faultFound == true
            ? t("DiagnosticReportsTable.myDeviceHealth.returnAuthorization")
            : t("DiagnosticReportsTable.myDeviceHealth.referenceCode");
    const faultFoundIconAndText = (
        <>
            <img src={crossIcon} alt={"AltText.diagnosticSummary.failed"} />{" "}
            {t("DiagnosticReportsTable.myDeviceHealth.faultFound")}
        </>
    );
    const faultNotFoundIconAndText = (
        <>
            <img src={checkIcon} alt={"AltText.diagnosticSummary.passed"} />{" "}
            {t("DiagnosticReportsTable.myDeviceHealth.faultNotFound")}
        </>
    );
    return (
        <>
            <div className={style.childContainer}>
                {results.map((result: NtfResult, index: number) => (
                    <div
                        key={index}
                        id={result.faultCode}
                        data-testid={
                            testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.categoryResults.itself
                        }
                    >
                        <Heading
                            tag="div"
                            variant="SUBTITLE_1"
                            data-testid={
                                testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.categoryResults
                                    .headerLabel
                            }
                        >
                            {t(FAULT_CODE_TO_TRANSLATION_MAP.get(result.faultCode) || result.faultCode)}
                        </Heading>
                        {result.history.map((history: NtfHistory, historyIndex: number) => (
                            <div key={historyIndex} className={style.historyContainer}>
                                <div className={style.reportDataContainer}>
                                    <div>{t("DiagnosticReportsTable.myDeviceHealth.timeAndDate")}</div>
                                    <div
                                        data-testid={
                                            testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth
                                                .categoryResults.dateLabel
                                        }
                                    >
                                        <DateCell value={history.reportDate} tooltip={true} timeFirst={true} />
                                    </div>
                                </div>
                                <div className={style.reportDataContainer}>
                                    <div>{t("DiagnosticReportsTable.myDeviceHealth.result")}</div>
                                    <div
                                        data-testid={
                                            testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth
                                                .categoryResults.resultIcon
                                        }
                                    >
                                        {history.faultFound ? faultFoundIconAndText : faultNotFoundIconAndText}
                                    </div>
                                </div>
                                {history.faultFound && (
                                    <div className={style.reportDataContainer}>
                                        <div>{t("DiagnosticReportsTable.myDeviceHealth.faultDescription")}</div>
                                        <div
                                            data-testid={
                                                testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth
                                                    .categoryResults.descriptionLabel
                                            }
                                        >
                                            {history.description}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div
                className={classNames(style.childContainer, style.rightContainer)}
                id={"rightContainer"}
                data-testid={testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails.itself}
            >
                <Heading
                    tag="div"
                    variant="SUBTITLE_1"
                    data-testid={
                        testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails.headerLabel
                    }
                >
                    {t("DiagnosticReportsTable.myDeviceHealth.otherDetails")}
                </Heading>

                <div>
                    <div className={style.reportDataContainer}>
                        <div>{t("DiagnosticReportsTable.myDeviceHealth.codeLabel")}</div>
                        <div
                            data-testid={
                                testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails.codeLabel
                            }
                        >
                            {otherDetails.referenceCode}
                        </div>
                    </div>
                    <div className={style.reportDataContainer}>
                        <div>{t("DiagnosticReportsTable.myDeviceHealth.codeTypeLabel")}</div>
                        <div
                            data-testid={
                                testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails
                                    .codeTypeLabel
                            }
                        >
                            {" "}
                            {codeType}
                        </div>
                    </div>
                    <div className={style.reportDataContainer}>
                        <div>{t("DiagnosticReportsTable.myDeviceHealth.storeLabel")}</div>
                        <div
                            data-testid={
                                testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails.storeLabel
                            }
                        >
                            {" "}
                            {otherDetails.store}
                        </div>
                    </div>
                    <div className={style.reportDataContainer}>
                        <div>{t("DiagnosticReportsTable.myDeviceHealth.overallResultLabel")}</div>
                        <div
                            data-testid={
                                testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails
                                    .overallResultLabel
                            }
                        >
                            {otherDetails.faultFound ? faultFoundIconAndText : faultNotFoundIconAndText}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
